
import { CaseReasonAddUpdateComponent } from "./customer-care/case-reason/case-reason-add-update/case-reason-add-update.component";
import { CasesAddUpdateComponent } from "./customer-care/cases/cases-add-update/cases-add-update.component";
import { AddRoleComponent } from "./hr/add-role/add-role.component";
import { AddUserComponent } from "./hr/add-user/add-user.component";
import { StaffComponent } from "./hr/staff/staff.component";
import { ProfileDetailComponent } from "./manage-profile/profile-detail/profile-detail.component";
import { AddCityComponent } from "./masters/add-city/add-city.component";
import { AddCountryComponent } from "./masters/add-country/add-country.component";
import { AddFranchiesComponent } from "./masters/add-franchies/add-franchies.component";
import { AddStateComponent } from "./masters/add-state/add-state.component";
import { PinAreaAddUpdateComponent } from "./masters/pin-area/pin-area-add-update/pin-area-add-update.component";
import { PincodeAddUpdateComponent } from "./masters/pincode/pincode-add-update/pincode-add-update.component";
import { SubscriberDetailsComponent } from "./masters/subscriber-details/subscriber-details.component";
import { SubscriberLeasedLineDetailsComponent } from "./masters/subscriber-leased-line-details/subscriber-leased-line-details.component";
import { SubscriberLeasedLineComponent } from "./masters/subscriber-leased-line/subscriber-leased-line.component";
import { IpPoolConfigDetailComponent } from "./network-management/ip-pool-config/ip-pool-config-detail/ip-pool-config-detail.component";
import { AddNetworkDevicesComponent } from "./network-management/network-device/add-network-devices/add-network-devices.component";
import { AddNotificationGroupComponent } from "./operations/add-notification-group/add-notification-group.component";
import { AddNotificationComponent } from "./operations/add-notification/add-notification.component";
import { BroadcastComponent } from "./operations/broadcast/broadcast.component";
import { AddChargeComponent } from "./plan_management/add-charge/add-charge.component";
import { AddDiscountComponent } from "./plan_management/add-discount/add-discount.component";
import { AddPackagesComponent } from "./plan_management/add-packages/add-packages.component";
import { AddPolicyComponent } from "./plan_management/add-policy/add-policy.component";
import { AddServiceComponent } from "./plan_management/add-service/add-service.component";
import { AddTaxComponent } from "./plan_management/add-tax/add-tax.component";
import { PriceBookDetailsComponent } from "./plan_management/price-book/price-book-details/price-book-details.component";
import { AddAccountingProfileComponent } from "./radius/add-accounting-profile/add-accounting-profile.component";
import { AddClientsComponent } from "./radius/add-clients/add-clients.component";
import { AddPoliciesComponent } from "./radius/add-policies/add-policies.component";
import { AddClientGroupComponent } from "./radius/client-grp/add-client-group/add-client-group.component";
import { AddBillingTemplateComponent } from "./operations/add-billing-template/add-billing-template.component";
import { AddUpdateTeamsComponent } from "./hr/teams/add-update-teams/add-update-teams.component";
import { AddValueAddedeServiceComponent } from "./plan_management/vas/vas-add-update/add-value-addede-service.component";
import { ServiceAreaAddUpdateComponent } from "./network-management/service-area/service-area-add-update/service-area-add-update.component";
import { SmsProvidersComponent } from "./configuration/sms-providers/sms-providers.component";
import { DunningListComponent } from "./plan_management/dunning/dunning-list/dunning-list.component";
// import { ErrorComponent } from "./error/error.component";
import { OtherUpdatesComponent } from "./masters/subscriber-details/other-updates/other-updates.component";
import { DunningAddUpdateComponent } from "./plan_management/dunning/dunning-add-update/dunning-add-update.component";
import { QosPolicyDetailComponent } from "./plan_management/qos-policy-detail/qos-policy-detail.component";


export const auditLogComponentList=[
 {  
    MODULE_NAME:"City",
    COMPONENT_NAME:AddCityComponent,
    URL:"/masters/city/add_city"
 },
 {
    MODULE_NAME:"State",
    COMPONENT_NAME:AddStateComponent,
    URL:"/masters/state/add_state"

 },
 {
    MODULE_NAME:"Country",
    COMPONENT_NAME:AddCountryComponent,
    URL:"/masters/country/add_country"
 },
 {
    MODULE_NAME:"Partner",
    COMPONENT_NAME:AddFranchiesComponent,
    URL:"/masters/franchies/add_franchies"
 },
 {
   MODULE_NAME:"IP Pool For Partner",
   COMPONENT_NAME:AddFranchiesComponent,
   URL:"/masters/franchies/add_franchies"
 },
 {
   MODULE_NAME:"Partner Configuration",
   COMPONENT_NAME:AddFranchiesComponent,
   URL:"/masters/franchies/add_franchies"
 },
 {
    MODULE_NAME:"Customer",
    COMPONENT_NAME:SubscriberDetailsComponent,
    URL:"/masters/subscriber/details"  
 },
 {
    MODULE_NAME:"Clients",
    COMPONENT_NAME:AddClientsComponent,
    URL:"/radius/clients/add-clients"
 },
 {
    MODULE_NAME:"ClientGroup",
    COMPONENT_NAME:AddClientGroupComponent,
    URL:"/radius/clients-group/add-group" /**QP*/
 },
 {
    MODULE_NAME:"AccountingProfile",
    COMPONENT_NAME:AddAccountingProfileComponent,
    URL:"/radius/accounting-profile/add-accounting-profile"
 },
 {
    MODULE_NAME:"RadiusProfile",
    COMPONENT_NAME:AddPoliciesComponent,
    URL:"/radius/policies/add-policies"
 },
 {
    MODULE_NAME:"PostpaidPlan",
    COMPONENT_NAME:AddPackagesComponent,
    URL:"/plan-management/packages/add-packages"
 },
 {
    MODULE_NAME:"Tax",
    COMPONENT_NAME:AddTaxComponent,
    URL:"/plan-management/tax/update-tax"
 },
 {
    MODULE_NAME:"Charge",
    COMPONENT_NAME:AddChargeComponent,
    URL:"/plan-management/charge/add-charge"
 },
 {
    MODULE_NAME:"Discount",
    COMPONENT_NAME:AddDiscountComponent,
    URL:"/plan-management/discount/add-discount"
 },
 {
    MODULE_NAME:"PlanService",
    COMPONENT_NAME:AddServiceComponent,
    URL:"/plan-management/service/add-service"
 },
 {
   MODULE_NAME:"ServiceArea",
   COMPONENT_NAME:ServiceAreaAddUpdateComponent,
   URL:"/network-management/serviceArea/add-serviceArea"
   //AddServiceComponent
},
 {
    MODULE_NAME:"QOSPolicy",
    COMPONENT_NAME:AddPolicyComponent,
    URL:"/plan-management/qos-policy/add-qos-policy"/**QP*/
 },
 {
   MODULE_NAME:"QOSPolicyParam",
   COMPONENT_NAME:QosPolicyDetailComponent,
   URL:"/plan-management/qos-policy-detail/update"/**QP*/
},
 {
    MODULE_NAME:"PriceBook",
    COMPONENT_NAME:PriceBookDetailsComponent,
    URL:"/plan-management/price_book/price_book_details"
 },
 {
    MODULE_NAME:"Area",
    COMPONENT_NAME:PinAreaAddUpdateComponent ,
    URL:"/masters/area/add-area"
 },
 {
    MODULE_NAME:"NetworkDevices",
    COMPONENT_NAME:AddNetworkDevicesComponent,
    URL:"/network-management/network/add_network"
 },
 {
    MODULE_NAME:"IPPool",
    COMPONENT_NAME:IpPoolConfigDetailComponent,
    URL:"/network-management/ip_pool_list/detail"
 },
 {
    MODULE_NAME:"Case",
    COMPONENT_NAME:CasesAddUpdateComponent,
    URL:"/customer-care/cases/add-cases" /**QP*/
 },
 {
    MODULE_NAME:"CaseReason",
    COMPONENT_NAME:CaseReasonAddUpdateComponent,
    URL:"/customer-care/caseReason/add-caseReason"
 },{
    MODULE_NAME:"StaffUser",
    COMPONENT_NAME:AddUserComponent,
    URL:"/hr/add-user"
 },
 { 
   /**Warning:two different compoent with Same Name. */
    MODULE_NAME:"XsltManagement",
    COMPONENT_NAME:AddBillingTemplateComponent,
    URL:"/operations/billingtemplate/add-billingtemplate"
 },
  { 
   /**Warning:two different compoent with Same Name. */
    MODULE_NAME:"Billing Template",
    COMPONENT_NAME:AddBillingTemplateComponent,
    URL:"/operations/billingtemplate/add-billingtemplate"
 },
 {
   MODULE_NAME:"Role",
   COMPONENT_NAME:AddRoleComponent,
   URL:"/hr/add-role"/**QP*/
 },
 {
   MODULE_NAME:"SMS Provider",
   COMPONENT_NAME:SmsProvidersComponent,
   URL:"/configuration/add-sms"
 },
 {
   MODULE_NAME:"Teams",
   COMPONENT_NAME:AddUpdateTeamsComponent,
   URL:"/hr/add-team" /**QP*/
 },
 {
   MODULE_NAME:"Notification",
   COMPONENT_NAME:AddNotificationComponent,
   URL:"/operations/notification/add-notification"
 },
 {
  MODULE_NAME:"Notification Group",
  COMPONENT_NAME:AddNotificationGroupComponent,
  URL:"/operations/notification-group/notification-group-add"
},
 {
   MODULE_NAME:"Profile Management",
   COMPONENT_NAME:ProfileDetailComponent,
   URL:""/**QP*/
 },
 {
   MODULE_NAME:"VAS",
   COMPONENT_NAME:AddValueAddedeServiceComponent,
   URL:"/plan-management/value-added-service/add"
 },
 {
   MODULE_NAME:"Order Place",
   COMPONENT_NAME:SubscriberDetailsComponent,
   URL:"/masters/subscriber/details"
 },
 {
   MODULE_NAME:"Purchase Order", 
   COMPONENT_NAME:SubscriberDetailsComponent ,
   URL:"/masters/subscriber/details"
 },
 {
   MODULE_NAME:"Customer Address",
   COMPONENT_NAME:SubscriberDetailsComponent ,
   URL:"/masters/subscriber/details"
 },
 
 {
   MODULE_NAME:"Pincode",
   COMPONENT_NAME:PincodeAddUpdateComponent,
   URL:"/masters/pincode/add_pincode"/**QP*/
 },
  {
   MODULE_NAME:"NETWORK-DEVICE-SLOT",
   COMPONENT_NAME:AddNetworkDevicesComponent,
   URL:"/network-management/network/add_network"
 },
 
 {
   MODULE_NAME:"Subscriber Lease Line",
   COMPONENT_NAME:SubscriberLeasedLineDetailsComponent,
   URL:"/masters/suscriberLeased-details"
 },
{
   MODULE_NAME:"Order Payment",
   COMPONENT_NAME:SubscriberDetailsComponent,
   URL:"/masters/subscriber/details"
 },
 { //Purchase Tab
   MODULE_NAME:"Purchase Order",
   COMPONENT_NAME:SubscriberDetailsComponent,
   URL:"/masters/subscriber/details"
 },
 {
    //Purchase and Payment Show
   MODULE_NAME:"Order Place",
   COMPONENT_NAME:SubscriberDetailsComponent,
   URL:"/masters/subscriber/details"
 },
 {
  MODULE_NAME:"Dunning Rules",
  COMPONENT_NAME:DunningListComponent,
  URL:"/plan-management/dunning/add"
},
{
  MODULE_NAME:"DunningRules Action",
  COMPONENT_NAME:DunningAddUpdateComponent,
  URL:"/plan-management/dunning/add"
},
 //  {
//     /***No Need this Component:Vijay */
//     MODULE_NAME:"Broadcast",
//     COMPONENT_NAME:BroadcastComponent
//  },

//  { /**Not In Use */
//    MODULE_NAME:"Recent Renewal Report",
//    COMPONENT_NAME:null
//  },
//  {
//    MODULE_NAME:"Charge Details Report",
//    COMPONENT_NAME:null
//  },
// {
//    MODULE_NAME:"Purchase History Report",
//    COMPONENT_NAME:null
//  }, 
/**Component ??? */
//  {  
//    MODULE_NAME:"System",
//    COMPONENT_NAME:null
//  },
//  {
//     MODULE_NAME:"AuthResponse",
//     COMPONENT_NAME:null
//  },
//  {
//     MODULE_NAME:"DbcdrProcessing",
//     COMPONENT_NAME:null
//  },




]