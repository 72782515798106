import { AfterViewInit, Component,EventEmitter,Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { ItemStockModal } from '../modal/item-stock-modal';
import { SearchModal } from '../modal/search-modal';
import { StockAreaModal } from '../modal/stock-area-modal';
import { InventoryService } from '../service/inventory.service';
import * as alertify from 'alertifyjs';
import { ColumnModel } from 'n-neo-table-lib';
import { ResponseCode } from '../../../../../../src/app/core/constant/response-code';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-inventory-allocation',
  templateUrl: './inventory-allocation.component.html',
  styleUrls: ['./inventory-allocation.component.scss']
})
export class InventoryAllocationComponent implements OnInit, AfterViewInit{

  fieldsList = [
    {key: 'assetId', value: 'Asset Id'},
    {key: 'itemName', value: 'Item Name'},
    {key: 'shortDescription', value: 'Properties'},
    {key: 'serialNumber', value: 'Serial No.'}
  ];
  searchByListConst = [{ value:'Item Name', key: 'itemName'},{ value:'Asset Id', key: 'assetId'},{ value:'Serial No', key: 'serialNo'}];
  searchBy: string;
  placeHolderText: string = '';
  itemList: Array<ItemStockModal>;
  stockAreaList: Array<StockAreaModal>;
  searchData = new SearchModal();
  selectedItems = new Array<ItemStockModal>();
  isAnyRecordSelected: boolean = false;
  totalSelectedRecords: number = 0;
  //Neo Table Settings
  inventoryNeoGridSettings: any = {};
  dataSource = new MatTableDataSource([]);
  allColumns: any[] = [];
  allFinalColumns: ColumnModel[] = [];
  serverSideFilter: boolean = false;
  tableKeyEnum = TableKeyEnum;
  releaseButton : boolean;
  usedQtyError:boolean = false;
  zerousedQtyError:boolean = false;
  @Input() isCaseInventoryHistory: boolean;
  @Input() isInventorySelectionUpdate: boolean;
  @Output() onSaveInventory = new EventEmitter<any>();
  @Input() ticketId : number;
  @Input() ticket : string;
  @Input() isInventorySelection : boolean = false;
  @Input() caseUpdate : boolean = false;
  @Input() custId: number;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('isSelected', { static: false }) isSelected: TemplateRef<any>;
  @ViewChild('usedQty', { static: false }) usedQty: TemplateRef<any>;
  @ViewChild('release', { static: false }) release: TemplateRef<any>;
  @ViewChild('price',{ static: false }) priceReff: TemplateRef<any>;
  @ViewChild('remarks',{ static: false }) remarksReff: TemplateRef<any>;
  constructor(private service: InventoryService, private toasterService: ToastrService,) { }

  ngOnInit() {
    this.searchBy = this.searchByListConst[1].key;
    this.setGridSetting();
    if(!this.isInventorySelection && !this.isCaseInventoryHistory){
      this.getStaffWiseInventory();
    }
    if(this.isCaseInventoryHistory){
      this.getCaseInventoryHistory(this.ticket);
    }
    if(this.isInventorySelectionUpdate){
      this.onInventoryAllocate();
    }
    this.service.returnCustId().subscribe((res) => {
      this.onSaveStock(res);
    })
  }

  getStaffWiseInventory(){
    let obj = {
      staffId: localStorage.getItem('userId'),
      isNative: false
    }
    this.service.getStaffWiseInventory(obj).subscribe((res)=>{
      this.selectedItems = res.dataList;
      this.refreshDataSource(res.dataList);
    })
  }

  onInventoryAllocate(){
    let obj = {
      reserveStatus: this.custId ? "Consumed" : "Free",
      custId: this.custId
    }
    this.service.getAutoCompleteStock(obj).subscribe((res)=>{
      this.selectedItems = res.dataList;
      if(this.isInventorySelectionUpdate){
      this.selectedItems.map(ls => ls.isDisable = true);
      this.selectedItems.map(ls => ls.returnStock = true);
      }
      //this.selectedItems.map((ls) => ls.isDisable = true);
      this.refreshDataSource(res.dataList);
    })
  }

  getCaseInventoryHistory(ticket){
    this.service.getCaseInventoryHistory(ticket).subscribe((res)=>{
      let data : any = res.data;
      this.selectedItems = data;
      this.refreshDataSource(this.selectedItems);
    })
  }

  onAllocateSelection(){
    if(!this.isInventorySelection){
      let reqObj = {
        consumptionItemDetails : this.dataSource.filteredData.filter((ls) => ls.isSelected),
        ticket : this.ticket,
        ticketId :  this.ticketId,
      }
      this.usedQtyError = false;
      reqObj.consumptionItemDetails.forEach((ls)=>{

      if(ls.stock<ls.weight){
        this.usedQtyError =true;
      }
      if(!ls.weight || ls.weight == 0){
        this.zerousedQtyError =true;
      }
      })
      if(this.zerousedQtyError){
        return this.toasterService.warning("Please Select Quantity","WARNING");
      }
      if(this.usedQtyError){
        return this.toasterService.warning("UsedQty is not Grater than Qty","WARNING");
      }
      this.service.onAllocateSeleced(reqObj).subscribe((res)=>{
        if(res.responseCode == ResponseCode.OK){
          this.toasterService.success("Inventory Allocated SuccessFully.","SUCCESS");
          this.onSaveInventory.emit();
        }
        else{
          this.toasterService.error(res.responseMessage);
        }
      })
    }
    else{
      let stockIDs = [];
      stockIDs = this.dataSource.filteredData.filter((ls) => ls.isSelected);
      this.setReservedStock(stockIDs);
    }
  }

  onSaveStock(custId?: number){
    
    let stockIDs = [];
    stockIDs = this.dataSource.filteredData.filter((ls) => ls.isSelected);
    if(stockIDs){
      let obj = {
        stockIds : stockIDs.map((ls) => ls.stockId),
        reserveStatus: "Consumed",
        custId: custId
      }
      this.service.setReservedStock(obj).subscribe((res)=>{
       
      });
    }
  }

  setReservedStock(stockIDs){
    if(stockIDs.length == 0){
      return this.toasterService.warning("Please Select Stock.","WARNING");
    }
    let obj = {
      stockIds : stockIDs.map((ls) => ls.stockId),
      custId: this.custId,
      reserveStatus: this.custId ? "Consumed" : "Reserved"
    }
    if(stockIDs && stockIDs.length == 1 && stockIDs[0].returnStock)
      obj['onlyReturn'] = true;
    this.service.setReservedStock(obj).subscribe((res)=>{
      if(res.responseCode = ResponseCode.OK){
        stockIDs.forEach((ls)=>{
          this.dataSource.filteredData.forEach((res,i)=>{
            if(ls.stockId == res.stockId){
              res.isDisable = true;
              if(ls.returnStock){
                this.dataSource.filteredData.splice(i,1);
                this.refreshDataSource(this.dataSource.filteredData);
              }
              else{
                if(this.isInventorySelectionUpdate){
                  res.returnStock = true; 
                 }
              }
            }
          })
        });
        this.selectedItems = this.dataSource.filteredData;
      }
    })
  }

  onRelease(data,index){
    this.selectedItems[index].isDisable = true;
    let stockIDs = [];
    stockIDs.push(data);
    this.setReservedStock(stockIDs);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'isSelected') {
        col.customControls = [this.isSelected];
      }
      if (col.colKey == 'usedQty') {
        col.customControls = [this.usedQty];
      }
      if (col.colKey == 'release') {
        col.customControls = [this.release];
      }
      if(col.colKey == 'price'){
        col.customControls = [this.priceReff];
      }
      if (col.colKey == 'remarks') {
        col.customControls = [this.remarksReff];
      }

    });
    }

  setGridSetting(){
    this.allColumns = [
      {
        colKey: 'isSelected',
        default: 'Select',
        rowKey: 'isSelected',
        width: '27px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '15px',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'itemName',
        default: 'Item Name',
        rowKey: 'itemName',
        width: '150px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'shortDescription',
        default: 'Properties',
        rowKey: 'shortDescription',
        width: '150px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'productCode',
        default: 'Item Code',
        rowKey: 'productCode',
        width: '60px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'uom',
        default: 'UoM',
        rowKey: 'uom',
        width: '30px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'stock',
        default: 'Qty.',
        rowKey: 'stock',
        width: '30px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'assetId',
        default: 'Asset Id',
        rowKey: 'assetId',
        width: '40px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'serialNumber',
        default: 'Serial Number',
        rowKey: 'serialNumber',
        width: '40px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'price',
        default: 'Price',
        rowKey: 'price',
        width: '50px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'grnNumber',
        default: 'GRN Number',
        rowKey: 'grnNumber',
        width: '100px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'productTypes',
        default: 'Product Types',
        rowKey: 'productTypes',
        width: '100px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'release',
        default: 'Release',
        rowKey: 'release',
        width: '100px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'usedQty',
        default: 'Used Qty',
        rowKey: 'usedQty',
        width: '10px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'remarks',
        default: 'Remarks',
        rowKey: 'remarks',
        width: '100px',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
     
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
       // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
      
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    if(this.isCaseInventoryHistory){
      this.allColumns = this.allColumns.filter((ls) => ls.colKey != 'isSelected' && ls.colKey != 'release' && ls.colKey != 'actionCol');
    }
    if(this.caseUpdate){
      this.allColumns = this.allColumns.filter((ls) => ls.colKey != 'release' && ls.colKey != 'actionCol');
    }
    this.inventoryNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter: true,
      showPagination: false,
      tableId: this.tableKeyEnum.INVENTORY_STOCK_LISt,
      serverSideSortPagination: false,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: false,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Confirm',
      deleteMsgBoxDetail: 'Are you sure to delete this Item ?',
    };
  }

  onSearchDDL(event){
    let payload: any = {};
    payload.stockAreaId = this.searchData.stockArea.id;
    payload[this.searchBy] = event;
    payload.reserveStatus = "Free";
    this.service.getAutoCompleteStock(payload).subscribe((res) => {
      this.itemList = new Array<ItemStockModal>();
      this.itemList = res.dataList;
      this.itemList = this.itemList.map((o) => {
        let abc = Object.values(o);
        let demo;
        abc.forEach((ls)=>{
          if(ls)
            demo = demo + ` ${ls}`
        });

        o.search_label = demo

        return o;
      }); 
    })
  }

  onSelectSearchBy(event){
    this.itemList = undefined;
  }

  getStockAreaList(){
    if(!this.stockAreaList || this.stockAreaList.length == 0){
      this.service.getStockAreaList().subscribe((res) => {
        this.stockAreaList = new Array<StockAreaModal>();
        this.stockAreaList = res.dataList;
      })
    }
  }

  onSelectStock(event){
    
    if(this.selectedItems && this.selectedItems.length > 0){
      if(this.selectedItems.find((ls)=> ls.stockId == event.stockId)){
        return this.toasterService.info('Selected Item Is Already In List');
      }
    }
    event.price = Number(event.price);
    event.price = event.price.toFixed(2);
    this.selectedItems.push(event);
    this.refreshDataSource(this.selectedItems);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onDeleteInventory(i:number, event){
    this.commonDeleteMsgBox(this.inventoryNeoGridSettings, i, this.afterDeleteConfirm.bind(this));    // alertify.confirm(this.inventoryNeoGridSettings.deleteMsgBoxTitle,"Are you sure to delete this Item?",
    // function(){
    //   if (event) {
    //     this.selectedItems.splice(i,1);
    //     this.refreshDataSource(this.selectedItems);
    //    }},
    // )

  }

  afterDeleteConfirm(i){
    this.selectedItems.splice(i.event,1);
    this.refreshDataSource(this.selectedItems);
    this.getTotalSelectedRecords();
  }

  commonDeleteMsgBox(settings,event,callback): void {
    

    let self = this;
    let msgDetail = this.fillTemplate(settings.deleteMsgBoxDetail, event);
    alertify
      .confirm(
        settings.deleteMsgBoxTitle,
        msgDetail,
        function() {
          if (callback) {
            //callback(event);
            callback({ deleteClicked: true,event:event });
          }
        },
        function() {
          if (callback) {
            //callback(event);
            callback({ deleteClicked: false,event:event });
          }
        }
      )
      .setting('labels', { ok: 'Yes', cancel: 'No' })
      .set('defaultFocus', 'cancel');
  }

  fillTemplate(templateString, templateVars) {
    var parsed = templateString;
    if (templateVars) {
      Object.keys(templateVars).forEach((key) => {
        const value = templateVars[key];
        parsed = parsed.replace('${' + key + '}', value);
      });
    }
    return parsed;
  }

  onSelectOrDeseletItem(event, i){
    this.selectedItems[i].isSelected = event.isSelected;
    this.getTotalSelectedRecords();  
    if (this.dataSource.filteredData) {
      this.isAnyRecordSelected = this.dataSource.filteredData.some((value) => value.isSelected && !value.isDisable);
    }
    return false;
  }

  getTotalSelectedRecords(){
    this.totalSelectedRecords = this.dataSource.filteredData.filter((ls) => ls.isSelected).length;
  }
}
