import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class OtherCommunicationService {

  constructor(private http: HttpClient) { }

  
  getEvents() {
    const get_url = baseApiUrl + 'notification/getAllEvent';
    return this.http.get<any>(get_url);
  }
  

  getCommunicationBySearch(params){

    const get_url = baseApiUrl + 'sms/filterByEventAndDate';
    return this.http.post<any>(get_url, params, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    )

  }

}
