import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import * as alertify from 'alertifyjs';
import { AdjustPaymentDetailsService } from './chnage-other-details.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-adjust-payment-details',
  templateUrl: './adjust-payment-details.component.html',
  styleUrls: ['./adjust-payment-details.component.scss']
})
export class AdjustPaymentDetailsComponent implements OnInit {
  @Input() custId : any;
  @Input() bal : any;
  selectForUpdate : any;

  adjustPaymentDetailsForm = new FormGroup({
    amount : new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+\.?[0-9]*$/)]),
    paymentDate : new FormControl('', [Validators.required]),
    paymentType : new FormControl(null, [Validators.required]),
    remarks : new FormControl('', [Validators.required])
  });

  constructor(
    private customModalService : CustomModalService,
    private adjustPaymentDetailsService : AdjustPaymentDetailsService,
    private tosterservice:ToastrService,
    private datepipe:DatePipe
  ) { }

  ngOnInit() {
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  onClickSaveForm() {
    if (this.adjustPaymentDetailsForm.valid === true) {
      let self = this;
      alertify.confirm("Adjust Details","Are You Sure You want to Update Adjust Payment?",
      function() {
        self.updateAdjustPaymentDetailsOnDb();
      },
      function() {
  
      });
    } else {
      this.adjustPaymentDetailsForm.markAllAsTouched();
    }
  }

  updateAdjustPaymentDetailsOnDb() {
    if (this.adjustPaymentDetailsForm.valid === true) {
      let formatedInputData:any = this.adjustPaymentDetailsForm.value;
      formatedInputData.paymentDate=this.datepipe.transform(formatedInputData.paymentDate,'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
      this.adjustPaymentDetailsService.updateAdjustAoynebtDetailsDetails(formatedInputData, this.custId).subscribe(result => {
          if (result.responseCode !== 200 && result.responseMessage) {
            this.tosterservice.error(result.responseMessage);
          } else {
            this.tosterservice.success('Adjust Payment Detail Update successfully');
            this.customModalService.close('adjust-payment-details',result);
          }
        },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }

}
