import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal/custom-modal.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { ChangePlanService } from '../change-plan/change-plan.service';
import { RecordPaymentService } from '../record-payment/record-payment.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PaymentModeService } from '../../../../services/payment_mode/payment_mode.service';
import { ApplyChargeService } from '../apply-charge/apply-charge.service';
import { ResponseCode } from '../../../../core/constant/response-code';
import * as moment from 'moment';

@Component({
  selector: 'app-purchase-addon-plan',
  templateUrl: './purchase-addon-plan.component.html',
  styleUrls: ['./purchase-addon-plan.component.scss']
})
export class PurchaseAddonPlanComponent implements OnInit {

  @Input() custId: any;
  @Input() ui_id: string;
  @Input() service: string;
  @Input() partnerId: number;
  @Input() bal:number;
  
  form: FormGroup;
  showFields: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  refundOptionFlag: boolean = false;
  public planPurchaseTypeList: any;
  selectedDefaultRefundOption: any
  defaultRefundOptForNoACL: any;
  subscriberCurrentPlanData: any;
  submitted: boolean = false;
  req_message = 'This field is required.';
  showCurrentPlanDetails: boolean = true;
  showExpiredPlanDetails: boolean = true;
  CurrentPlan: any
  purchaseType: string;
  public planList: any;
  activatetionDate: boolean;
  addonStart: boolean;
  selectedPlan: any;
  displayActivateDate: any;
  displayExpiryDate: any;
  taxDetailsByPlanData: number;
  planPrice: number = 0;
  isPriceTaxMin: boolean = false;
  isPriceTaxMax: boolean = false;
  displayMinPrice: number;
  displayMaxPrice: number;
  minWithTax: any;
  maxWithTax: any;
  purchaseaddonprice: any;
  oldPurchasePrice: any;
  isOverhead: boolean = false;
  finalpaybal: number;
  Partner_type: any
  mindate: Date;
  checkeligiblity: any;
  isvalideligiblity: Boolean;
  isaddon: boolean;
  endDate: string;
  purchaseEndDate: Date;
  addonStartDate: any;
  isChecked: boolean = false;
  isIncludingTax: boolean = false;
  collectorFlag:boolean = true;
  amountValid: boolean;
  paymentDateValid: boolean;
  vasValueList:[]
  public paymentModeList: any[] = [
    {
        text: '',
        value: '',
    },
  ];
  selected_payment_mode_value: any
  selected_tds_deducted: any;
  public tdsPendingPaymentsList: any[] = [
    {
        display_text: '',
        id: '',
    },
  ];
  paymentModeValid: boolean;
  referenceNoValid: boolean;
  chequeNoValid: boolean;
  bankNameValid: boolean;
  branchValid: boolean;
  chequeDateValid: boolean;
  tdsAmountValid: boolean;
  remarksValid: boolean;
  collectorList: [];
  vasList: [];
  currentVasDTO: [];
  expiryDateForSelectedVAS: any;
  selectedVasType: string;
  iptvDeviceList: [];
  constructor(
    private paymentModeService: PaymentModeService,
    private customModalService: CustomModalService,
    private recordPaymentService: RecordPaymentService,
    private tosterservice: ToastrService,
    private datePipe: DatePipe,
    private changePlanService: ChangePlanService,
    public loginService: LoginService,
    private applyChargeService: ApplyChargeService,
    private datepipe: DatePipe,
  )
  { 
    this.loginService = loginService
  }

  ngOnInit() {
    this.setValidation();
    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER, AclConstants.OPERATION_CUSTOMER_REFUND_OPTION, AclConstants.OPERATION_CUSTOMER_ALL)) {
      this.refundOptionFlag = true;
    }
    else {
        this.refundOptionFlag = false;
    }
    this.Partner_type = localStorage.getItem('Partner_Type');
    if (this.ui_id === 'purchase-addon-plan') {
      this.purchaseAddon();
      if (this.purchaseaddonprice) {
          this.purchaseaddonprice = 0
      }
    }
    //this.getSubscriberCurrentPlan();
    this.getPlanPurchaseType();
    this.getPaymentModeListFromGenericApi();
    this.getPaymentCollectorList(this.custId);
    this.getStartDateEndDate();
  }

  getStartDateEndDate() {
    let newdate = new Date();
    var currentDate = this.datepipe.transform(newdate, 'yyyy-MM-dd');
    this.form.get('recordPaymentDTO').get('payment_date').setValue(currentDate);
  }

  getPaymentCollectorList(custid)
  {
    this.applyChargeService.getPaymentCollectorList(custid).subscribe(
        result => {
        this.collectorList = result.dataList;
        }
    )
  }

  getPaymentModeListFromGenericApi() {
    this.paymentModeService.getPaymentModeListFromGenericApi().subscribe(result => {
        this.paymentModeList = result.dataList;
    });
  }

  setValidation()
  {
    this.form = new FormGroup({
        planId: new FormControl(null, [Validators.required]),
        advRenewal: new FormControl(null),
        isIncludeTax: new FormControl(false),
        isPriceOverride: new FormControl(false,Validators.required),
        sellPrice: new FormControl(''),
        planPurchaseType: new FormControl('', [Validators.required]),
        skip: new FormControl(),
        remarks: new FormControl('', [Validators.required]),
        isPaymentReceived: new FormControl('no', [Validators.required]),
        addonStartDate: new FormControl(new Date()),
        refundOption: new FormControl(null),
        planType: new FormControl(null),
        isBalanceUsed: new FormControl(false),
        balanceAmount: new FormControl(null),
        purchaseType: new FormControl('',[Validators.required]),
        vasId: new FormControl(''),
        iptvDeviceList: new FormControl(''),
        recordPaymentDTO: new FormGroup({
            amount: new FormControl(''),
            payment_date: new FormControl(''),
            payment_mode: new FormControl(''),
            referenceNo: new FormControl(''),
            chequeNo: new FormControl(''),
            bankName: new FormControl(''),
            chequeDate: new FormControl(''),
            branch: new FormControl(''),
            tdsDeducted: new FormControl(false),
            tdsAmount: new FormControl(''),
            remarks: new FormControl(''),
            credit_doc_id: new FormControl(''),
        })
    });
  }

  purchaseAddon() {
    if (this.ui_id === 'purchase-addon-plan') {
        this.mindate = new Date(Date.now());
        this.changePlanService.checkeligibility(this.custId).subscribe((res) => {
            this.checkeligiblity = res.data;
            if (this.checkeligiblity.isConvertToAddon === true) {
                if (this.checkeligiblity.isValidActivePlan === true) {
                    this.isvalideligiblity = true;
                }
            } else {
                this.form.controls.addonStartDate.setValidators(Validators.required);
                this.isaddon = false;
            }
        });
    }
  }


  getSubscriberCurrentPlan() 
  {
    this.changePlanService.getSubscriberCurrentPlan(this.custId).subscribe((result) => {
        this.selectedDefaultRefundOption = result.data.defaultRefundOptForActive;
        this.defaultRefundOptForNoACL = result.data.defaultRefundOptForNoACL;

        if (result && result.data) {
            this.subscriberCurrentPlanData = result.data;
            if (result.data.currentPlanDTO === null && result.data.expiredPlanDTO === null) {

                this.showCurrentPlanDetails = false;
                this.showExpiredPlanDetails = false;

            }
            if (result.data.currentPlanDTO === null) {
                this.CurrentPlan = this.subscriberCurrentPlanData.expiredPlanDTO
                this.purchaseType = 'New';

                if (this.planPurchaseTypeList && this.planPurchaseTypeList.length > 0) {
                    let newPlan = this.planPurchaseTypeList.find((ls) => {
                        if (ls.value.toLowerCase() === 'new') {
                            return ls;
                        }
                    });
                    this.form.get('planPurchaseType').patchValue(this.purchaseType);
                    this.onChangePurchaseType(newPlan);
                }

            }
            else {

                this.CurrentPlan = Object.assign({}, this.subscriberCurrentPlanData.currentPlanDTO)
                this.purchaseType = 'Renew';
                if (this.planPurchaseTypeList && this.planPurchaseTypeList.length > 0) {
                    let renew = this.planPurchaseTypeList.find((ls) => {
                        if (ls.value.toLowerCase() === 'renew') {
                            return ls;
                        }
                    });

                    this.form.get('planPurchaseType').patchValue(this.purchaseType);
                    this.onChangePurchaseType(renew);
                }
            }
        }
        this.getPlanPurchaseType();
    });
  }

  getPlanPurchaseType() 
  {
    this.changePlanService.getPlanPurchaseType().subscribe((result) => {
        this.planList = [];
        this.activatetionDate = false;
        this.addonStart = true;
        this.planPurchaseTypeList = result.dataList.filter((item: any) => item.value == 'Volume Booster' || item.value == 'VAS Add-on');
    });
  }

  ClearField() 
  {
    this.form.reset();
    this.selectedPlan = [];
    this.displayActivateDate = null;
    this.displayExpiryDate = null;
    this.taxDetailsByPlanData = null;
    this.planPrice = null;
    this.form.get('isPaymentReceived').setValue('no');

  }

  onChangePurchaseType(purchaseTypeItem) 
  {

    if (purchaseTypeItem) 
    {

        if(purchaseTypeItem.value == "Volume Booster")
        {
            this.showFields = true;
        }
        else if(purchaseTypeItem.value == "VAS Add-on")
        {
            this.showFields = false;
        }

        this.purchaseType = purchaseTypeItem.value;
        if(this.purchaseType == 'VAS Add-on'){
            this.form.get('purchaseType').setValidators([Validators.required]);
            this.form.get('vasId').setValidators([Validators.required]);
            this.form.get('planId').clearValidators();
            this.form.get('planId').updateValueAndValidity();
        }else{
            this.form.get('purchaseType').clearValidators();
            this.form.get('purchaseType').updateValueAndValidity();
            this.form.get('vasId').clearValidators();
            this.form.get('vasId').updateValueAndValidity();
        }
        if (purchaseTypeItem.value !== 'Renew') 
        {
          if (purchaseTypeItem.value == 'Volume Booster' && this.ui_id == 'purchase-addon-plan') 
          {
            this.form.get('planId').setValidators([Validators.required]);
              this.getPurchaseAddonePlan();
          }
        }
    }else{
        this.form.get('purchaseType').clearValidators();
        this.form.get('purchaseType').updateValueAndValidity();
    }
  }


  getPurchaseAddonePlan() 
  {
    let serviceType: number = 1;
    let purchaseAddonPlan: any = {
        'partnerId': this.partnerId,
        'planGroup': 'Volume Booster',
        'serviceType': serviceType,
    };

    this.changePlanService.getPurchaseAddonePlanService(purchaseAddonPlan).subscribe(result => {
        this.planList = [];
        result.dataList.forEach(item => {
            let itemData: any = {};
            itemData.planName = item.displayName;
            itemData.partnerCommType = item.partnerCommType
            itemData.planId = item.id;
            itemData.dataQuota = item.quota;
            itemData.validity = item.validity;
            itemData.timeQuota = item.quotatime;
            itemData.quotaType = item.quotatype;
            itemData.offerprice = item.offerprice;
            itemData.quotaUnit = item.quotaUnit;
            itemData.quotaunittime = item.quotaunittime;
            itemData.endDate = item.endDate;
            itemData.minPrice = item.minPrice;
            itemData.maxPrice = item.maxPrice;
            itemData.minPriceTax = item.minPriceTax
            itemData.maxPriceTax = item.maxPriceTax
            itemData.taxamount = item.taxamount;
            itemData.taxId = item.taxId;
            itemData.quotaFormate = item.quotaFormate;//New Key:
            this.planList.push(itemData);
        });
    });
  }

  get f() {
      return this.form.controls;
  }

  onChangePlan(planItem) 
  {
    this.selectedPlan = [];
    this.form.get('isPaymentReceived').setValue('no');
    this.form.get('balanceAmount').reset();
    this.form.get('sellPrice').setValue(null);
    this.selectedPlan = planItem;

    if (this.selectedPlan && this.selectedPlan.minPrice !== null && this.selectedPlan.minPrice !== '') {
        this.isPriceTaxMin = false;
        if (this.isPriceTaxMin === false) {
            // this.selectedPlan.minPrice = this.selectedPlan.minPrice + this.selectedPlan.minPriceTax;
            // this.minWithTax = this.selectedPlan.minPrice;
            this.displayMinPrice = 0;
            this.displayMinPrice = this.selectedPlan.minPrice + this.selectedPlan.minPriceTax;
            this.isPriceTaxMin = true;
        }
        else {
            // this.minWithTax=null;
            this.isPriceTaxMin = false;
            return
        }
    }
    else {
        this.selectedPlan.minPrice = null;
        this.selectedPlan.minPriceTax = null;
        this.displayMinPrice = null;
        this.maxWithTax = null;
    }
    if (this.selectedPlan.maxPrice !== null && this.selectedPlan.maxPrice !== '') {
        this.isPriceTaxMax = false;
        if (this.isPriceTaxMax === false) {
            // this.selectedPlan.maxPrice = this.selectedPlan.maxPrice + this.selectedPlan.maxPriceTax;
            // this.maxWithTax = this.selectedPlan.maxPrice;
            this.displayMaxPrice = 0;
            this.displayMaxPrice = this.selectedPlan.maxPrice + this.selectedPlan.maxPriceTax;
            this.isPriceTaxMax = true;
        }
        else {
            this.maxWithTax = null;
            this.isPriceTaxMax = false;
            return
        }
    }
    else {
        this.selectedPlan.maxPrice = null;
        this.selectedPlan.maxPriceTax = null;
        this.displayMaxPrice = null;
        this.maxWithTax = null;
    }

    this.planPrice = this.selectedPlan.price;
    if (this.ui_id === 'purchase-addon-plan') {

        this.purchaseaddonprice = this.selectedPlan.offerprice;
        this.oldPurchasePrice = this.selectedPlan.offerprice;
    }

    if (planItem) 
    {
      let advRenewal = this.form.get('advRenewal').value;
      let purchaseType = this.form.get('planPurchaseType').value;
      let data: any = {
          // "chargeId": 0,
          // "locationId": this.presentAddressStateChange.state_id,
          'custId': this.custId,
          'planId': this.selectedPlan.planId
      };
      this.taxDetailsByPlanData = planItem.taxAmount;
      if (planItem.taxAmount === null) {
          this.taxDetailsByPlanData = 0;
      }
      if (purchaseType === 'New' || purchaseType === 'Upgrade' || purchaseType === 'Volume Booster') {
        if (this.ui_id === 'purchase-addon-plan') {
            this.selectedPlan.offerprice = this.selectedPlan.offerprice + this.selectedPlan.taxamount;
        }
      }
    }
    if (this.ui_id === 'purchase-addon-plan') {
        if (!this.isOverhead) {
            this.finalpaybal = this.purchaseaddonprice + this.selectedPlan.taxamount;
        }
        if (this.isOverhead) {
            this.finalpaybal = this.purchaseaddonprice;
        }
    }
  }

  checkOverHead(event: any) 
  {
    if (event === 'true') {
        this.form.get('isPriceOverride').setValue(true);
        this.isOverhead = true;
        this.purchaseaddonprice = 0;
        this.form.get('sellPrice').setValidators([Validators.required]);
    } else {
        this.purchaseaddonprice = this.oldPurchasePrice;
        this.form.get('isPriceOverride').setValue(false);
        this.isOverhead = false;
        this.taxDetailsByPlanData = this.selectedPlan.taxAmount;
        this.planPrice = this.selectedPlan.price;
        this.form.get('sellPrice').clearValidators();
        this.form.get('sellPrice').updateValueAndValidity();
    }
  }

  onAddonSelect(event) {
    this.purchaseEndDate = new Date(event.value);
    this.addonStartDate = new Date(event.value);
    this.purchaseEndDate.setDate(this.purchaseEndDate.getDate() + this.selectedPlan.validity);
    this.endDate = this.datePipe.transform(this.purchaseEndDate, 'dd-MM-yyyy');
  }

  checkIncludingTax(event: any) {

    let sellPrice = this.form.get('sellPrice').value;

    if (event === 'true') {
        this.form.get('isIncludeTax').setValue(true);
        this.isIncludingTax = true;
    } else {
        this.form.get('isIncludeTax').setValue(false);
        this.isIncludingTax = false;
    }

    if (sellPrice !== '') {
        this.onOverridePrice(sellPrice);
    }
  }

  onOverridePrice(amount) {
    this.form.get('isBalanceUsed').setValue(false);
    if (amount === '') {
        this.form.get('sellPrice').setErrors({ 'incorrect': true });
        this.tosterservice.error('Please Enter Override Price!');
        return;
    }

    this.form.get('sellPrice').setErrors(null);

    let event = Number(amount);
    if (this.isOverhead === true) {
        this.taxDetailsByPlanData = 0;
        this.planPrice = 0;
        if (this.isIncludingTax === false) 
        {
          if (this.ui_id === 'purchase-addon-plan') 
          {
              let obj = {
                  amount: Number(event),
                  taxId: this.selectedPlan.taxId
              };
              this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
                  event = Number(event) + res.TaxAmount;

                  if (event || this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                      if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {

                          this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                          this.form.get('sellPrice').setErrors({ 'incorrect': true });
                          event = 0;
                          return;
                      } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {

                          this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                          this.form.get('sellPrice').setErrors({ 'incorrect': true });
                          event = 0;
                          return;
                      } else {
                          this.purchaseaddonprice = Number(event);
                      }
                  } else {
                      this.purchaseaddonprice = Number(event);

                  }

              });
          }
        } 
        else 
        {
          if (this.ui_id === 'purchase-addon-plan') {

              if (this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                  if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
                      this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                      event = 0;
                      this.form.get('sellPrice').setErrors({ 'incorrect': true });
                      return;
                  } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
                      this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                      event = 0;
                      this.form.get('sellPrice').setErrors({ 'incorrect': true });
                      return;
                  } else {
                      this.purchaseaddonprice = Number(event);
                  }
              } else {
                  this.purchaseaddonprice = Number(event);
              }
          }
        }
    }
  }

  keypress(event: any) 
  {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
        event.preventDefault();
    }
  }

  onPaymentTypeChange(event) {
    //this.form.get('isBalanceUsed').reset();
    if (event === 'YES') {
        this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('amount').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_date').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_mode').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('referenceNo').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
    } else {

        this.form.get('recordPaymentDTO').get('amount').clearValidators();
        this.form.get('recordPaymentDTO').get('amount').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_date').clearValidators();
        this.form.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_mode').clearValidators();
        this.form.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('referenceNo').clearValidators();
        this.form.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
    }
  }

  checkamount()
  {
    let oldfinalpay;
    if(this.ui_id !== 'change-plan')
    {
      if (!this.isOverhead) {
        oldfinalpay = this.purchaseaddonprice + this.selectedPlan.taxamount;
      }
      if (this.isOverhead) {
        oldfinalpay = this.purchaseaddonprice;
      }    
    }
    let amount = this.form.get('recordPaymentDTO').get('amount').value;
    if(this.bal >= this.finalpaybal && this.form.get('isBalanceUsed').value == true)
    {
      if(amount == oldfinalpay || amount == 0)
      {
          this.collectorFlag = false;
          this.form.get('balanceAmount').setValue(this.bal);
      }
      else
      {
          this.collectorFlag = true;
          this.form.get('balanceAmount').setValue(oldfinalpay);
      }
    }
    else
    {
      this.collectorFlag = true;
    }
  }

  onChangePaymentMode(el) 
  {
    if (el) {
        let payment_mode_value = el.value;
        this.selected_payment_mode_value = payment_mode_value;
        if (this.selected_payment_mode_value === 'TDS') {
            this.recordPaymentService.getTdsPendingPayments(this.custId).subscribe(result => {
                this.tdsPendingPaymentsList = result.dataList;
            });
        }
    } else {
        this.selected_payment_mode_value = '';
    }
  }

  onChangeTdsDeducted(el) 
  {
    let is_tds_deducted = el;
    this.selected_tds_deducted = is_tds_deducted;
  }

  closeModal(id: string) 
  {
    this.customModalService.close(id, { close_from: 'close_button' });
    this.form.reset();
    this.selectedPlan = null;
    this.endDate = null;
    this.req_message = null;
  }

  onClickUpdatePaymentForm() 
  {
    if ((this.form.get('recordPaymentDTO').valid === false && this.ui_id !== 'purchase-addon-plan') || (this.form.get('recordPaymentDTO').valid === false && this.ui_id === 'purchase-addon-plan')) {
        this.tosterservice.error('Please Fill All payment Details');
    }
    if (this.isOverhead === true) {
        if (this.form.invalid) {
            return;
        }
        this.form.get('sellPrice').setValidators(Validators.required);
        this.form.get('sellPrice').updateValueAndValidity();
        if (this.form.get('sellPrice').valid === false) {
            this.tosterservice.error('Please Enter Override Price!');
            return;
        }
    } else {
        this.form.get('sellPrice').clearValidators;
        this.form.get('sellPrice').updateValueAndValidity();
        this.form.get('isPriceOverride').setValue(false);
        this.form.get('isPriceOverride').updateValueAndValidity();
    }
    if (this.form.valid === true) {
        for (const prop in this.form.controls) {
            this.form.value[prop] = this.form.controls[prop].value;
          }
        let formAllValue = Object.assign({}, this.form.value);
        if (formAllValue.isPaymentReceived && formAllValue.isPaymentReceived == true) {
            if (formAllValue.recordPaymentDTO.payment_mode !== 'tds' && formAllValue.recordPaymentDTO.tdsDeducted === true) {
                if (formAllValue.recordPaymentDTO.tdsAmount.length === 0) {
                    this.tdsAmountValid = false;
                    this.submitted = true;
                    this.form.markAllAsTouched();
                    return false;
                } else {
                    this.tdsAmountValid = true;
                }
            }

            if (formAllValue.recordPaymentDTO.amount.length === 0) {
                this.amountValid = false;
            } else {
                this.amountValid = true;
            }

            if (formAllValue.recordPaymentDTO.payment_date.length === 0) {
                this.paymentDateValid = false;
            } else {
                this.paymentDateValid = true;
            }

            if (formAllValue.recordPaymentDTO.remarks.length === 0) {
                this.remarksValid = false;
            } else {
                this.remarksValid = true;
            }

            if (formAllValue.recordPaymentDTO.referenceNo.length === 0) {
                this.referenceNoValid = false;
            } else {
                this.referenceNoValid = true;
            }

            let payment_mode_id = formAllValue.recordPaymentDTO.payment_mode;

            if (_.isEmpty(payment_mode_id) === false) {
                this.paymentModeValid = true;
                let selected_payment_mode = _.find(this.paymentModeList, { value: payment_mode_id });
                let payment_mode = selected_payment_mode.value;
                if (payment_mode === 'cheque') {
                    if (formAllValue.recordPaymentDTO.chequeNo.length === 0) {
                        this.chequeNoValid = false;
                    } else {
                        this.chequeNoValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.bankName.length === 0) {
                        this.bankNameValid = false;
                    } else {
                        this.bankNameValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.chequeDate.length === 0) {
                        this.chequeDateValid = false;
                    } else {
                        this.chequeDateValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.branch.length === 0) {
                        this.branchValid = false;
                    } else {
                        this.branchValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.chequeNo.length === 0 ||
                        formAllValue.recordPaymentDTO.bankName.length === 0 ||
                        formAllValue.recordPaymentDTO.chequeDate.length === 0 ||
                        formAllValue.recordPaymentDTO.branch.length === 0 ||
                        formAllValue.recordPaymentDTO.remarks.length === 0
                    ) {
                        this.submitted = true;
                        this.form.markAllAsTouched();
                        return false;
                    } else {
                        this.askQuestion();
                    }
                } else {
                    if (formAllValue.recordPaymentDTO.remarks.length === 0 ||
                        formAllValue.recordPaymentDTO.amount.length === 0 ||
                        formAllValue.recordPaymentDTO.payment_date.length === 0) {
                        this.submitted = true;
                        this.form.markAllAsTouched();
                        return false;
                    } else {
                        this.askQuestion();
                    }
                }
            } else {
                this.paymentModeValid = false;
                this.form.markAllAsTouched();
                return false;
            }
        } else {
            this.askQuestion();
        }
    } else {

        let addonStartDate = this.form.get('addonStartDate').value;
        if (this.ui_id != 'purchase-addon-plan') {
            this.form.get('addonStartDate').clearValidators();
            this.form.get('addonStartDate').updateValueAndValidity();
            //this.onClickUpdatePaymentForm();
            this.submitted = true;
            this.form.markAllAsTouched();
        } else {
            this.submitted = true;
            this.form.markAllAsTouched();
        }

    }
  }

  askQuestion() 
  {
    let self = this;
    this.submitted = false;
    let title: string;
    if (this.ui_id == 'purchase-addon-plan') {
        title = 'Purchase add on plan';
    } else {
        title = 'Change Plan';
    }
    alertify.confirm(title, 'Are You Sure You want to ' + title + ' ?', function () {
        self.insertChangePlanOnDb();
    },
        function () {

        });
  }

  insertChangePlanOnDb() 
  {
    let apiInputData = Object.assign({}, this.form.value);
    if (apiInputData.skip == true) {
        apiInputData.skip = false;
    } else {
        apiInputData.skip = true;
    }

    let recordPaymentDTO: any = null;
    let advRenewal = apiInputData.advRenewal;
    if (advRenewal == null && (this.purchaseType === 'Upgrade' || this.purchaseType === 'Volume Booster' || this.purchaseType === 'New')) {
        advRenewal = false;
    }
    let formatedData: any = {};
    if (this.ui_id != 'purchase-addon-plan') {
        formatedData = {
            'custId': this.custId,
            'isRefund': apiInputData.skip,
            'isAdvRenewal': advRenewal,
            'planId': apiInputData.planId,
            'purchaseType': apiInputData.planPurchaseType,
            'remarks': apiInputData.remarks,
            'sellPrice': apiInputData.sellPrice,
            'isIncludeTax': apiInputData.isIncludeTax ? apiInputData.isIncludeTax : false,
            'isPriceOverride': apiInputData.isPriceOverride,
            'refundOption': apiInputData.refundOption ? apiInputData.refundOption : null,
            //'collectorId':apiInputData.collectorId?apiInputData.collectorId: null,
        };
    } else {
        apiInputData.addonStartDate = this.datePipe.transform(this.addonStartDate, 'yyyy-MM-dd');
        formatedData = {
            'custId': this.custId,
            'isRefund': apiInputData.skip,
            'isAdvRenewal': true,
            'planId': apiInputData.planId,
            'purchaseType': apiInputData.planPurchaseType,
            'remarks': apiInputData.remarks,
            'addonStartDate': apiInputData.addonStartDate,
            'isPaymentReceived': apiInputData.isPaymentReceived,
            'sellPrice': apiInputData.sellPrice,
            'isIncludeTax': apiInputData.isIncludeTax ? apiInputData.isIncludeTax : false,
            'isPriceOverride': apiInputData.isPriceOverride,
            'vasId': apiInputData.vasId,
            'iptvDetailsId': apiInputData && apiInputData.iptvDeviceList ? apiInputData.iptvDeviceList.id : undefined
            //'collectorId':apiInputData.collectorId?apiInputData.collectorId: null,
        };
    }
    if (apiInputData.advRenewal === '') {
        formatedData.advRenewal = false;
    }
    if (apiInputData.isPaymentReceived && apiInputData.isPaymentReceived == 'yes') {
        formatedData.isPaymentReceived = true;

        recordPaymentDTO = {};
        recordPaymentDTO.custId = this.custId;
        recordPaymentDTO.paymentAmount = apiInputData.recordPaymentDTO.amount;
        recordPaymentDTO.paymentDate = this.datePipe.transform(apiInputData.recordPaymentDTO.payment_date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
        recordPaymentDTO.referenceNo = apiInputData.recordPaymentDTO.referenceNo;
        recordPaymentDTO.paymentMode = apiInputData.recordPaymentDTO.payment_mode;
        // recordPaymentDTO.isBalanceUsed = apiInputData.isBalanceUsed;
        // if(recordPaymentDTO.isBalanceUsed == true){
        //     recordPaymentDTO.balanceAmount = apiInputData.balanceAmount;
        // }

        let selectedPaymentModeData = _.find(this.paymentModeList, { value: apiInputData.recordPaymentDTO.payment_mode });
        //if paymentMode  cheque
        if (selectedPaymentModeData.value === 'cheque') {
            recordPaymentDTO.bankName = apiInputData.recordPaymentDTO.bankName;
            recordPaymentDTO.branch = apiInputData.recordPaymentDTO.branch;
            recordPaymentDTO.chequeDate = apiInputData.recordPaymentDTO.chequeDate;
            recordPaymentDTO.chequeNo = apiInputData.recordPaymentDTO.chequeNo;
        }

        // if paymentMode  TDS
        if (selectedPaymentModeData.value === 'TDS') {
            recordPaymentDTO.credit_doc_id = apiInputData.recordPaymentDTO.credit_doc_id;
        }

        recordPaymentDTO.isTdsDeducted = apiInputData.recordPaymentDTO.tdsDeducted;
        // if isTdsDeducted true
        if (recordPaymentDTO.isTdsDeducted === true) {
            recordPaymentDTO.tdsAmount = apiInputData.recordPaymentDTO.tdsAmount;
        }
        recordPaymentDTO.remarks = apiInputData.remarks;
    } else {
        formatedData.isPaymentReceived = false;
    }
    formatedData.remarks = apiInputData.remarks
    formatedData.recordPaymentDTO = recordPaymentDTO;
    this.changePlanService.changePlan(formatedData).subscribe(
        (result) => {
            if (result.responseCode !== ResponseCode.OK) {
                this.tosterservice.error(result.responseMessage);
            } else {
                if (this.ui_id == 'purchase-addon-plan') {
                    this.tosterservice.success('purchase add on plan Save Successfully.');
                    this.customModalService.close('purchase-addon-plan', result);
                    this.form.reset();
                    this.checkeligiblity = null;
                    this.selectedPlan = null;
                    this.endDate = null;

                } else {
                    this.tosterservice.success('Change Plan Save Successfully.');
                    this.customModalService.close('change-plan', result);
                    this.form.reset();
                }
            }
        }, err => {
            this.tosterservice.error(err.error.status + ' ' + err.error.ERROR);
        }
    );
  }

  getVASList(){
    if(!this.vasList || this.vasList.length == 0){
        this.changePlanService.getVASList().subscribe((res) => {
            this.vasList = res.dataList;
        })
    }
  }

  onChangeVAS(event){
    this.selectedPlan = [];
    this.currentVasDTO = [];
    this.form.get('vasId').clearValidators();
    this.form.get('vasId').updateValueAndValidity();
    this.form.get('vasId').setValue(undefined);
    this.form.get('iptvDeviceList').setValue(undefined);
    if(event){
        this.form.get('planId').clearValidators();
        this.form.get('planId').updateValueAndValidity();
        this.selectedVasType = event.value; 
        if(this.selectedVasType == 'IPTV'){
            this.form.get('iptvDeviceList').setValidators([Validators.required]);
        }else{
            this.form.get('iptvDeviceList').clearValidators();
            this.form.get('iptvDeviceList').updateValueAndValidity();
        }
        let obj = {
            id: this.custId,
            type: event.value
        }
        this.changePlanService.getSubscriberCurrentPlanData(obj).subscribe((res) => {
            if(res.responseCode != ResponseCode.OK){
                this.form.get('purchaseType').setValue(undefined);
                return this.tosterservice.error(res.responseMessage);
            }
            if(this.selectedVasType != 'IPTV' && res.data && res.data.currentPlanDTO && res.data.currentPlanDTO.length > 0 && !res.data.currentPlanDTO[0].isCancellable){
                this.tosterservice.error("Your current "+ event.text +" is not cancellable");
                this.form.get('purchaseType').setValue(undefined);
            }
            else{
                this.vasValueList = res.data.customPlanDtoList;
                this.currentVasDTO = res.data;
                this.form.get('vasId').setValidators([Validators.required]);
            }
            
        }),(error)=>{
            this.tosterservice.error(error.message);
        }
    }else{
        this.selectedVasType = undefined;
        this.form.get('iptvDeviceList').clearValidators();
        this.form.get('iptvDeviceList').updateValueAndValidity();
        this.form.get('planId').setValidators([Validators.required]);
    }
  }
  onVasValueListChange(event){
    this.selectedPlan = [];
    this.expiryDateForSelectedVAS = '-'
    if(event){
        this.selectedPlan = event;
        this.selectedPlan.taxamount = this.selectedPlan.taxAmount;
        this.purchaseaddonprice = this.selectedPlan.price ? this.selectedPlan.price : 0;
        let vasExpiryDate = moment(event.expiryDate).format('DD/MM/YY h:mm:ss');
        let currentPlanExpiryDate = moment(this.currentVasDTO['customerPlansModel'].expiryDate).format('DD/MM/YY h:mm:ss');
       if(vasExpiryDate > currentPlanExpiryDate){
            if(event.isExpiryExceeds){
                this.expiryDateForSelectedVAS = currentPlanExpiryDate;
            }else{
                this.form.get('vasId').setValue(undefined);
                this.tosterservice.error("Your Current VAS Is Not Expirable");
            }
        }else{
            this.expiryDateForSelectedVAS = vasExpiryDate;
        }
    }
  }

  checkForSetupBox(){
    if(!this.iptvDeviceList || this.iptvDeviceList.length == 0){
        this.changePlanService.checkForSetupBox(this.custId).subscribe((res) => {
            this.iptvDeviceList = res.dataList;
        })   
    }
  }

  onIptvDeviceChange(event){
    let selectedIptv: any;
    if(event){
        // this.form.get('iptvDeviceList').setValue(event.id);
        this.currentVasDTO['currentPlanDTO'].forEach((ls) => {
            if(event.id == ls.iptvDetailsId){
                selectedIptv = ls;
            }
        })
    }else{
        // this.form.get('iptvDeviceList').setValue(undefined);
    }
    if(selectedIptv){
        this.expiryDateForSelectedVAS = undefined;
        if(!selectedIptv.isCancellable || !selectedIptv.isExpiryExceeds){
            this.tosterservice.error("Your Current VAS Is Not Expirable");
        }else{
            let vasExpiryDate = moment(event.expiryDate).format('DD/MM/YY h:mm:ss');
            let currentPlanExpiryDate = moment(selectedIptv.expiryDate).format('DD/MM/YY h:mm:ss');
            if(vasExpiryDate > currentPlanExpiryDate){
                this.expiryDateForSelectedVAS = currentPlanExpiryDate;
            }else{
                this.expiryDateForSelectedVAS = vasExpiryDate;
            }
        }
    }
    // let vasExpiryDate = moment(event.expiryDate).format('DD/MM/YY h:mm:ss');
    // let currentPlanExpiryDate = moment(this.currentVasDTO['customerPlansModel'].expiryDate).format('DD/MM/YY h:mm:ss');
    // if(vasExpiryDate > currentPlanExpiryDate){
    //     if(event.isExpiryExceeds){
    //         this.expiryDateForSelectedVAS = currentPlanExpiryDate;
    //     }else{
    //         this.form.get('vasId').setValue(undefined);
    //         this.tosterservice.error("Your Current VAS Is Not Expirable",'ERROR');
    //     }
    // }else{
    //     this.expiryDateForSelectedVAS = vasExpiryDate;
    // }
  }
}
