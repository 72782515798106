import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.scss']
})
export class SystemConfigurationComponent implements OnInit {

  configurationForm : FormGroup;

  constructor(
  ) { }

  ngOnInit() {
    this.configurationForm = new FormGroup({
      engineid : new FormControl('',[Validators.required]),
      enginelastrun : new FormControl('', [Validators.required]),
      pdfpath : new FormControl('', [Validators.required]),
      mailfrom : new FormControl('', [Validators.required]),
      mailheader : new FormControl('', [Validators.required]),
      maildata : new FormControl('', [Validators.required]),
      smsheader : new FormControl('', [Validators.required]),
      smsdata : new FormControl('', [Validators.required]),
      smppip : new FormControl('', [Validators.required]),
      smppport : new FormControl('', [Validators.required]),
      partnerpdfpath : new FormControl('', [Validators.required]),
      partnerroleid : new FormControl('', [Validators.required]),
      partnermanagerroleid : new FormControl('', [Validators.required]),
      partneroperatorroleid : new FormControl('', [Validators.required]),
      trialpdfpath : new FormControl('', [Validators.required]),
      paymentpdfpath : new FormControl('', [Validators.required]),
      paymentmailheader : new FormControl('', [Validators.required]),
      paymentmaildata : new FormControl('', [Validators.required]),
      inmode : new FormControl('', [Validators.required]),
      enable_discount_approval : new FormControl('', [Validators.required]),
      discount_approval_threshold_per : new FormControl('', [Validators.required]),
      discount_approval_threshold_val : new FormControl('', [Validators.required])
    });
  }

  getSystemConfgiurationById(system_configuration_id :any) {

  }

  insertSystemConfiguration() {

  }

  updateSystemConfiguration(system_configuration_id: any) {

  }

}
