import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { DatePipe } from '@angular/common';
import { RecordPaymentService } from './record-payment.service';
import { PaymentModeService } from '../../../../services/payment_mode/payment_mode.service';

import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-record-payment',
  templateUrl: './record-payment.component.html',
  styleUrls: ['./record-payment.component.scss']
})
export class RecordPaymentComponent implements OnInit {

  @Input() bal:any;
  @Input() custId : any;
  req_message = "This field is required.";
  selectForUpdate : any;
  recordPayment : any;
  selectedValue : any;
  selected_payment_mode_value:any;
  selected_tds_deducted:any;
  submitted:any = null;
  tdsAmountValid:boolean;
  chequeNoValid:boolean;
  bankNameValid:boolean;
  chequeDateValid:boolean;
  branchValid:boolean;
  chplList:[];
  // plList:[];
  // chList:any[];
  settlementId:any[] = [];
  DisAmount:number=0;
  public paymentModeList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  public tdsPendingPaymentsList: any[] = [
    {
      display_text: '',
      id: '',
    },
  ];

  insertRecordPaymentForm = new FormGroup({
    amount : new FormControl('',[Validators.required]),
    collection_date : new FormControl('',[Validators.required]),
    payment_mode : new FormControl(null,[Validators.required]),
    referenceNo : new FormControl('',[Validators.required]),
    chequeNo : new FormControl(''),
    bankName : new FormControl(''),
    chequeDate : new FormControl(''),
    branch : new FormControl(''),
    tdsDeducted : new FormControl(false),
    tdsAmount : new FormControl(''),
    remarks : new FormControl('',[Validators.required]),
    credit_doc_id : new FormControl(''),
    // chId: new FormControl(null),
    // plId: new FormControl(null),
    settlementId: new FormControl([])
  })

  constructor(
    private customModalService : CustomModalService,
    private cdRef : ChangeDetectorRef,
    private paymentModeService:PaymentModeService,
    private recordPaymentService:RecordPaymentService,
    private tosterservice : ToastrService,
    private datepipe:DatePipe
  ) { }

  ngOnInit() {
    this.getPaymentModeListFromGenericApi();
    this.getChargePlanList()
  }
  
  getChargePlanList(){
    this.paymentModeService.getChargeOrPlanListByCustId(this.custId).subscribe(result => {
      this.chplList = result.dataList
    });
  }

  onChangeChPlList(event){
    if(event){
      let length = event.length;
      let DisAmount:number = 0
      if(length > 0){
        for(let i=0;i<length;i++){
          DisAmount += event[i].dueAmount;
          this.DisAmount = Math.abs(DisAmount)
        }
        this.insertRecordPaymentForm.get('amount').setValue(this.DisAmount);
      }else{
        this.DisAmount = 0;
        this.insertRecordPaymentForm.get('amount').setValue(this.DisAmount);
      }
    }
    else{
      this.settlementId = null;
    }
  }

  getPaymentModeListFromGenericApi() {
    this.paymentModeService.getPaymentModeListFromGenericApi().subscribe(result => {
      this.paymentModeList = result.dataList;
    });
  }

  onChangePaymentMode(el) {
    if (el) {
      let payment_mode_value = el.value;
      this.selected_payment_mode_value = payment_mode_value;
      if(payment_mode_value === 'cheque'){
        this.insertRecordPaymentForm.get('chequeNo').setValidators(Validators.required);
        this.insertRecordPaymentForm.get('chequeNo').updateValueAndValidity();
        this.insertRecordPaymentForm.get('bankName').setValidators(Validators.required);
        this.insertRecordPaymentForm.get('bankName').updateValueAndValidity();
        this.insertRecordPaymentForm.get('chequeDate').setValidators(Validators.required);
        this.insertRecordPaymentForm.get('chequeDate').updateValueAndValidity();
        this.insertRecordPaymentForm.get('branch').setValidators(Validators.required);
        this.insertRecordPaymentForm.get('branch').updateValueAndValidity();
      }
      else{
        this.insertRecordPaymentForm.get('chequeNo').reset();
        this.insertRecordPaymentForm.get('chequeNo').updateValueAndValidity();
        this.insertRecordPaymentForm.get('bankName').reset();
        this.insertRecordPaymentForm.get('bankName').updateValueAndValidity();
        this.insertRecordPaymentForm.get('chequeDate').reset();
        this.insertRecordPaymentForm.get('chequeDate').updateValueAndValidity();
        this.insertRecordPaymentForm.get('branch').reset();
        this.insertRecordPaymentForm.get('branch').updateValueAndValidity();
      }
      if (this.selected_payment_mode_value === 'TDS') {
        this.recordPaymentService.getTdsPendingPayments(this.custId).subscribe(result => {
          this.tdsPendingPaymentsList = result.dataList;
        });
      }
    } else {
      this.selected_payment_mode_value = '';
    }
  }

  onChangeTdsDeducted(el) {
    let is_tds_deducted = el;
    this.selected_tds_deducted = is_tds_deducted;
  }

  onClickSaveRecordPaymentForm() {
    if (this.insertRecordPaymentForm.valid === true) {
      let formValue = Object.assign({}, this.insertRecordPaymentForm.value);
      let payment_mode_id = formValue.payment_mode;
      let selected_payment_mode = _.find(this.paymentModeList,{value:payment_mode_id});
      let payment_mode = selected_payment_mode.value;
      if (payment_mode === '') {
        this.submitted = true;
        this.insertRecordPaymentForm.markAllAsTouched();
        return false;
      } else {


        if (payment_mode !== "tds" && formValue.tdsDeducted === true) {
          if (formValue.tdsAmount.length === 0)  {
            this.tdsAmountValid = false;
            this.submitted = true;
            this.insertRecordPaymentForm.markAllAsTouched();
            return false;
          } else {
            this.tdsAmountValid = true;
          }
        }

         if (payment_mode === "cheque") {
          if (formValue.chequeNo.length === 0)  {
              this.chequeNoValid = false;
          } else {
            this.chequeNoValid = true;
          }
          if (formValue.bankName.length === 0)  {
              this.bankNameValid = false;
          } else {
              this.bankNameValid = true;
          }
          if (formValue.chequeDate.length === 0)  {
              this.chequeDateValid = false;
          } else {
              this.chequeDateValid = true;
          }
          if (formValue.branch.length === 0)  {
              this.branchValid = false;
          } else {
              this.branchValid = true;
          }
          if (formValue.chequeNo.length === 0 || formValue.bankName.length === 0 || formValue.chequeDate.length === 0 || formValue.branch.length === 0)  {
            this.submitted = true;
            this.insertRecordPaymentForm.markAllAsTouched();
            return  false;
          }
        }

          this.submitted = false;
          let self = this;
          alertify.confirm("Record Payment Details","Are You Sure You want to Record Payment Details ?",
            function() {
              self.saveApiCall();
            },
            function() {

          });

      }
    } else {
      this.submitted = true;
      this.insertRecordPaymentForm.markAllAsTouched();
      this.tosterservice.error("Please Fill All Required Details!");
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertRecordPaymentForm.value);

    let formatedData:any = {};
    formatedData.custId = this.custId;
    formatedData.paymentAmount =  apiInputData.amount;
    formatedData.collectionDate =  this.datepipe.transform(apiInputData.collection_date,'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
    formatedData.referenceNo =  apiInputData.referenceNo;
    formatedData.paymentMode =  apiInputData.payment_mode;
    formatedData.settlementId = apiInputData.settlementId

    let selectedPaymentModeData = _.find(this.paymentModeList,{value:apiInputData.payment_mode});
    //if paymentMode  cheque
    if (selectedPaymentModeData.value === 'cheque') {
      formatedData.bankName =  apiInputData.bankName;
      formatedData.branch =  apiInputData.branch;
      formatedData.chequeDate =  apiInputData.chequeDate;
      formatedData.chequeNo =  apiInputData.chequeNo;
    }

    // if paymentMode  TDS
    if (selectedPaymentModeData.value === 'TDS') {
      formatedData.credit_doc_id =  apiInputData.credit_doc_id;
    }

    formatedData.isTdsDeducted =  apiInputData.tdsDeducted;
    // if isTdsDeducted true
    if (formatedData.isTdsDeducted === true) {
      formatedData.tdsAmount =  apiInputData.tdsAmount;
    }
    formatedData.remarks =  apiInputData.remarks;

    this.recordPaymentService.updateRecordPayment(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Record Payment Save Successfully.');
          this.customModalService.close('record-payment',result);
        }
      },err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
