import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { id } from '@swimlane/ngx-datatable/release/utils';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class SubscriberDetailsService {
  constructor(private http: HttpClient) {

  }
  disconnectUser(reqObj) {
    const post_url = BASE_API_URL + 'userDisconnect/ByName';
    return this.http.post<any>(post_url, reqObj, { headers: httpOptions.headers });
  }
  getSubscriberStatus(username) {
    const get_url = BASE_API_URL + 'liveuser/check/' + username;
    return this.http.get<any>(get_url, { headers: httpOptions.headers });
  }

  getSubscriberBasicDetails(subscriber_id): Observable<any> {
    
    const get_url = BASE_API_URL + 'subscriber/getBasicCustomerDetails/' + subscriber_id;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  Refreshonu(obj) {

    const post_url = BASE_API_URL + 'NetworkDevice/refreshOnuDevice';
    return this.http.post<any>(post_url, obj);
  }
  getCustomerPasswordDetails(customerId): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getCustomerPasswordDetails/' + customerId;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getVoiceProvisionDetails(custId): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getVoiceProvisionDetails/' + custId;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  saveVoiceProvisionDetails(reqObj) {
    const post_url = BASE_API_URL + 'subscriber/updateVoiceProvisionDetails';
    return this.http.post<any>(post_url, reqObj, { headers: httpOptions.headers });
  }

  MobileVerification(id) {
    let data = { userid : id }
    const post_url = BASE_API_URL + 'subscriber/genrateMobileOtp';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers });
  }
  OTPverifiedService(userId, Otp) {
    let data = { userid:userId, mobileotp: Otp }
    const post_url = BASE_API_URL + 'subscriber/verifyMobileOtp';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers });
  }

  getAadhaarOTP(id){
    let obj = {
      id_number :id.toString()
    }
    const post_url = BASE_API_URL + 'subscriber/generateAadhaarOTP';
      return this.http.post<any>(post_url,obj)
  }
  verifyOtpAadhaar(data){
    const post_url = BASE_API_URL + 'subscriber/verifyAadhaarOTP';
      return this.http.post<any>(post_url,data)
  }
  // Refreshactiveplan(obj) {
  //   const post_url = BASE_API_URL + 'NetworkDevice/refreshOnuDevice';
  //   return this.http.post<any>(post_url, obj);
  // }

  updateUserName(data): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/updateSubscriberUsername';
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
