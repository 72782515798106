import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { FranchiesService } from '../../franchies/franchies.service';
import { CommissionByPartner } from '../model/commission-by-partner';
import { SearchService } from '../../../../services/search.service';
import { LoginService } from '../../../../pages/login/login.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
import { saveAs } from 'file-saver/FileSaver';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-commission-by-partner',
  templateUrl: './commission-by-partner.component.html',
  styleUrls: ['./commission-by-partner.component.scss']
})
export class CommissionByPartnerComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('commval', { static: false }) commvalWrapperRef: TemplateRef<any>;

  @Input()
  franchise_id: any
  partnerId: any;
  dataList: CommissionByPartner;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  START_DATE: any;
  END_DATE: any;
  subscriptions: Subscription[] = [];
  loginservice: any;
  dataSource = new MatTableDataSource([]);
  CommissionForm = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl(new Date(), Validators.required)
  });
  commissionByPartnerNeoGridSettings: any = {};
  commisionDataForgrid: any = { content: [] };
  partnerFlag = localStorage.getItem('partnerFlag');
  constructor(
    private franchise: FranchiesService,
    public searchService: SearchService,
    private loaginService: LoginService,
    private datepipe: DatePipe,
    private aroute: ActivatedRoute,
    private tosterservice: ToastrService,

  ) {
    super(searchService)
    this.loginservice = loaginService
  }

  ngOnInit() {

    this.setDefaultPageSize(this.tableKeyEnum.COMMISSION_BY_PARTNER)
    this.setGridSetting();
    //this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
    this.getStartDateEndDate();

  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.commisionDataForgrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.commisionDataForgrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.setdata(config);
  }
  setdata(gridSetting: any) {
    let apiInputData = this.CommissionForm.value;
    // this.franchise.getCommissionByPartnerId(this.franchise_id).subscribe((res:any)=>{
    //   this.dataList = res.partnercommision;
    //   ////console.log("DataList",this.dataList);

    //   this.refreshDataSource(this.dataList);
    // });
    let fetchSettingData = this.commissionByPartnerNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    // let currentDate = new Date();
    // let currentDateTime = currentDate.getTime();
    // let date = currentDateTime - (90 * 24 * 60 * 60 * 1000);
    // let oldDate = new Date(date);
    // let oldDate1 = this.datepipe.transform(oldDate, "yyyy-MM-dd");
    // let startDate1 = this.datepipe.transform(apiInputData.startDate, "yyyy-MM-dd");
    // let endDate1 = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    // if (oldDate1 > startDate1 || oldDate1 > endDate1) {
    //   this.tosterservice.error("Search is valid till 90 days");
    // }
    let ddmmyyyyStartDate;
    let ddmmyyyyEndDate;
    if (apiInputData.startDate) {
      ddmmyyyyStartDate = this.datepipe.transform(apiInputData.startDate, "yyyy-MM-dd");

    }
    if (apiInputData.endDate) {
      ddmmyyyyEndDate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd')
    }
    let createStartDate = new Date(ddmmyyyyStartDate);
    let createEndDate = new Date(ddmmyyyyEndDate);
    
    if (createStartDate > createEndDate) {
      this.tosterservice.error("Start date should be less then End date.");
    } else {
      params.partner_id = this.franchise_id;
      params.START_DATE = this.datepipe.transform(apiInputData.startDate, "yyyy-MM-dd");
      params.END_DATE = this.datepipe.transform(apiInputData.endDate, "yyyy-MM-dd");
      this.franchise.getCommissionByPartnerId(params).subscribe(result => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.commisionDataForgrid = [];
          this.refreshDataSource(this.commisionDataForgrid);
          this.tosterservice.error(result.responseMessage);
        } else {
          if (result.dataList === null) {
            this.commisionDataForgrid = [];
            //this.tosterservice.error("No Record Found");
            this.totalRecords = result.totalRecords ? result.totalRecords : 0;
            this.commissionByPartnerNeoGridSettings.pageTotalLength = this.totalRecords === 0 ? 0 : this.totalRecords;

          } else {
            this.commisionDataForgrid = result.dataList;
            this.refreshDataSource(this.commisionDataForgrid);
            this.totalRecords = result.totalRecords ? result.totalRecords : 0;
            this.commissionByPartnerNeoGridSettings.pageTotalLength = this.totalRecords === 0 ? 0 : this.totalRecords;
          }
          this.refreshDataSource(this.commisionDataForgrid);
        }

      });
      //this.refreshDataSource(this.commisionDataForgrid);
      // this.START_DATE="";
      // this.END_DATE="";
    }
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'commVal') {
        col.customControls = [this.commvalWrapperRef];
      }
    });
  }
  onSearch() {
    this.getListWithParams();
  }

  getStartDateEndDate() {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var startDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.CommissionForm.patchValue({ "startDate": startDate, "endDate": endDate });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      // {
      //   colKey: 'id',
      //   default: 'Id',
      //   rowKey: 'id',
      //   width: '10%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      {
        colKey: 'billDate',
        default: 'Bill Date',
        rowKey: 'billDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'userName',
        default: 'User Name',
        rowKey: 'userName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'customerName',
        default: 'Customer Name',
        rowKey: 'customerName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'commType',
        default: 'Commission Type',
        rowKey: 'commType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },

      {
        colKey: 'commVal',
        default: 'Commission Value',
        rowKey: 'commVal',
        width: '10%',
        customControls: [this.commvalWrapperRef],
        customValue: (commVal) => {
          return commVal;
        },
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },

    ];

    this.commissionByPartnerNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.COMMISSION_BY_PARTNER,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      tableHeight:300,
      headerSticky:true,
      verticalScroll:true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
    };
    if (this.commissionByPartnerNeoGridSettings.serverSideSortPagination && this.commissionByPartnerNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.commissionByPartnerNeoGridSettings.perPage);
    }
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.setdata(config);
  }

  onExportToExcel() {
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();
    let params: any = {};
    let apiInputData = this.CommissionForm.value;
    let createStartdate = this.datepipe.transform(apiInputData.startDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    let ddmmyyyyStartDate = new Date(createStartdate);
    let ddmmyyyyEndDate = new Date(createEnddate);
    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.tosterservice.error("Start date should be less then End date.");
    } else {
      params.partner_id = this.franchise_id;
      params.START_DATE = this.datepipe.transform(apiInputData.startDate, "yyyy-MM-dd");
      params.END_DATE = this.datepipe.transform(apiInputData.endDate, "yyyy-MM-dd");
      this.franchise.excelServiceData(params).subscribe(blob => {
        saveAs(blob, "[partner commission " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
      })
    }
  }
}
