
export class RequestData<T> {
    requestToken : string;
    authToken : string;
    id : number
    data : T;
    constructor(){
    }
    
}
