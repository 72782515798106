import { Component, Input, OnInit } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import { ReleaseIpAddressService } from './release-ip-address.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-release-ip-address',
  templateUrl: './release-ip-address.component.html',
  styleUrls: ['./release-ip-address.component.scss'],
  providers: [DatePipe]
})
export class ReleaseIpAddressComponent implements OnInit {

  @Input() custId: any;
  submitted: any = null;
  pool_name: any = "";
  purchase: any = null;
  activation_date: any = "";
  end_date: any = "";
  isChecked: boolean = false;
  ip_address: any = "";
  selectPurchaseList: any[] = [
    {
      display_text: '',
      currentChargeId: '',
    }
  ];

  insertReleaseIpForm = new FormGroup({
    purchase_id: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required]),
    isHibernate: new FormControl(false),
  })

  constructor(
    private customModalService: CustomModalService,
    private releaseIpAddressService: ReleaseIpAddressService,
    public datepipe: DatePipe,
    private tosterservice: ToastrService

  ) { }

  ngOnInit() {
    this.getSubscriberAllocateIp();
  }

  getSubscriberAllocateIp() {
    this.releaseIpAddressService.getSubscriberAllocateIp(this.custId).subscribe(result => {
      if (result.dataList && result.dataList.length) {
        result.dataList.forEach(el => {
          el.display_text = el.chargeName + ", " + this.datepipe.transform(el.activationDate, 'dd-MM-yyyy') + " to " + this.datepipe.transform(el.expiryDate.slice(0, 10), 'dd-MM-yyyy');
        });
      }
      this.selectPurchaseList = result.dataList;
    });
  }

  onClickSaveReleaseIpForm() {
    if (this.insertReleaseIpForm.valid === true) {
      let self = this;
      this.submitted = false;
      alertify.confirm("Replace IP Address", "Are You Sure You want to Replace IP Address ?",
        function () {
          self.saveApiCall();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertReleaseIpForm.markAllAsTouched();
    }
  }

  onPurchaseListChange(el) {
    if (el) {
      this.activation_date = el.activationDate;
      this.end_date = this.datepipe.transform(el.expiryDate.slice(0, 10), 'dd/MM/yyyy')
      this.pool_name = el.poolName;
      this.ip_address = el.ipAddress;
    } else {
      this.activation_date = "";
      this.end_date = "";
      this.pool_name = "";
      this.ip_address = "";
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertReleaseIpForm.value);
    let selectedChargeData = _.find(this.selectPurchaseList, { currentChargeId: apiInputData.purchase_id });
    
    let custId = parseInt(this.custId);
    let formatedData: any = {
      "allocatedId": selectedChargeData.currentAllocatedId,
      "poolDetailsId": selectedChargeData.currentPoolDetailsId,
      "poolId": selectedChargeData.currentPoolId,
      "remarks": apiInputData.remarks,
      "isHibernate": apiInputData.isHibernate,
      "chargeId":selectedChargeData.currentChargeId //New ChargeId
      
    };
    
    this.releaseIpAddressService.saveReleaseIpByCustId(custId, formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Release Ip Save Successfully.');
          this.customModalService.close('release-ip-address', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }
  checkIncludingTax(event: any) {
    if (event === 'true') {
      this.insertReleaseIpForm.get('isHibernate').setValue(true);

    } else {
      this.insertReleaseIpForm.get('isHibernate').setValue(false);
    }

  }
  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }
}
