import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NetworkSessionService } from './network-session.service';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../../../../../services/search.service';
import { OldAbstractBaseListComponent } from '../../../../../components/base/old-abstract-base-list-component';

@Component({
  selector: 'app-network-session',
  templateUrl: './network-session.component.html',
  styleUrls: ['./network-session.component.scss']
})
export class NetworkSessionComponent extends OldAbstractBaseListComponent implements OnInit {

  
  @Input() custId: any;
  mind:any;
  firstDay: Date;
  gridSetting: any;
  date: Date;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  networkSessionNeoGridSettings: any = {};
  subscriptions: Subscription[] = [];
  networkSessionForm = new FormGroup({
    createDate: new FormControl(),
    endDate: new FormControl(new Date())
  })
  createDate: any;
  endDate: any;
  networkAndSessionTableData: any;
  maxDate: any;
  minDate: any;
  currentMonthDate: any;

  constructor(
    private networkSessionService: NetworkSessionService,
    private tosterservice: ToastrService,
    private searhService: SearchService

  ) {
    super(searhService)
    this.date = new Date();
    this.firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.networkSessionForm.get('createDate').patchValue(this.firstDay);

  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.NETWORK_SESSION_LIST)
    this.setGridSetting();
    this.setDateFromDefault();
    //  this.getNetworkAndSessionData(); /** Extra Call Remove */
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.gridSetting = config;
    this.getNetworkAndSessionData();
  }


  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'acctsessionid',
        default: 'Session ID',
        rowKey: 'acctsessionid',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'createdate',
        default: 'Session Start Time',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'endSessionTime',
        default: 'Session End Time',
        rowKey: 'endSessionTime',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usage_data',
        default: 'Usage-Volume',
        rowKey: 'usage_data',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usage_time',
        default: 'Usage-Time',
        rowKey: 'usage_time',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedipaddress',
        default: 'IP Address',
        rowKey: 'framedipaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'nasipaddress',
        default: 'NAS IP',
        rowKey: 'nasipaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'callingstationid',
        default: 'MAC ID',
        rowKey: 'callingstationid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedinterfaceid',
        default: 'FramedInterface Id',
        rowKey: 'framedinterfaceid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedipv6prefix',
        default: 'Framed IPv6 Prefix',
        rowKey: 'framedipv6prefix',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'acctterminatecause',
        default: 'Termination cause',
        rowKey: 'acctterminatecause',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'nasportid',
        default: 'Nas port',
        rowKey: 'nasportid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }

      // {
      //   colKey: 'acctinputoctets',
      //   default: 'Upload Data',
      //   rowKey: 'acctinputoctets',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'acctoutputoctets',
      //   default: 'Download Data',
      //   rowKey: 'acctoutputoctets',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'Total_Data',
      //   default: 'Total Data',
      //   rowKey: 'Total_Data',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: true,
      // }
    ];

    this.networkSessionNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      serverSideSortPagination: true,
      showPagination: true,
      tableId: this.tableKeyEnum.NETWORK_SESSION_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber: this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
    if (this.networkSessionNeoGridSettings.serverSideSortPagination && this.networkSessionNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.networkSessionNeoGridSettings.perPage);
    }
  }

  setDateFromDefault(event?) {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.currentMonthDate = new Date();
    const Mindate = new Date(2022, 0, 1);
    this.mind = Mindate;

    if (event && event.target) {
      this.minDate = new Date(event.target.value.getFullYear(), event.target.value.getMonth(), 1);
      this.maxDate = new Date(event.target.value.getFullYear(), event.target.value.getMonth() + 1, 0);
      this.networkSessionForm.get('endDate').reset(this.maxDate);
      this.networkSessionForm.get('endDate').updateValueAndValidity();
    }
    else {
      this.minDate = new Date(createStartdate.getFullYear(), createStartdate.getMonth(), 1);
      this.maxDate = new Date(createStartdate.getFullYear(), createStartdate.getMonth() + 1, 0);
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));


    }
  }
  searchNetworkSession() {
    this.getListWithParams();
  }
  getNetworkAndSessionData() {
    let fetchSettingData = this.networkSessionNeoGridSettings.serverSideSortPagination ? this.gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.createDate = new DatePipe('en-US').transform(this.networkSessionForm.get('createDate').value, 'yyyy-MM-dd')
    this.endDate = new DatePipe('en-US').transform(this.networkSessionForm.get('endDate').value, 'yyyy-MM-dd');
    let formatedInputData: any = {};
    formatedInputData = Object.assign(params);
    formatedInputData.createDate = this.createDate;
    formatedInputData.endDate = this.endDate;
    formatedInputData.custId = this.custId;
    let ddmmyyyyStartDate = new Date(this.createDate);
    let ddmmyyyyEndDate = new Date(this.endDate);
    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.tosterservice.error("Start date should be less then End date.");
    } else {
      this.networkSessionService.getNetworkSessionDataByDate(formatedInputData).subscribe(result => {
        this.networkAndSessionTableData = result.dataList;
        if (result.responseCode !== 200) {
          this.tosterservice.error(result.responseMessage);
        } else {
          if (this.networkAndSessionTableData) {
            this.networkAndSessionTableData.forEach((data) => {
              data["Total_Data"] = Number(data.acctoutputoctets) + Number(data.acctinputoctets);
            });
            ////console.log(this.networkAndSessionTableData);

            this.refreshDataSource(this.networkAndSessionTableData);
            this.totalRecords = result ? result.totalRecords : 0;
            this.networkSessionNeoGridSettings.pageTotalLength = this.totalRecords;
          }
        }

      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        })
    }
  }

}
