import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { extend } from 'hammerjs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../../../../../../src/app/services/base-service';
import { List } from '../model/paymentcollection.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';
@Injectable({
  providedIn: 'root'
})
export class PaymentCollectionService  extends BaseService<List>{
  constructor(http: HttpClient) {
    super(http);
   }

  getModuleName(): string {
    return 'report/purchaseSettlementReport';
  }

  validate(): boolean {
    return false;
  }

  paymentCollectonGetData(params: any): Observable<any> {
    const post_url = BASE_API_URL + 'report/purchaseSettlementReport';
    return this.http.post<any>(post_url, params);
  }

  excelServiceData(data) {
    const post_url = BASE_API_URL + "report/purchaseSettlementReport/excel";
    return this.http.post<any>(post_url, data, { observe: 'body', responseType: 'blob' as 'json' });
  }

  getCollectorListByPartner(partnerId?): Observable<any> {
    let get_url
    if(partnerId){
      get_url = BASE_API_URL + 'userByPartnerAndRole/'+ partnerId + '/' + 14;
    }else{
      get_url = BASE_API_URL + 'userByRole/'+ 14;
    }
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
