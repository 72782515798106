import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { BaseService } from '../../../services/base-service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';
const baseApiUrl = '';  

@Injectable({
  providedIn: 'root'
})

export class NPackagesService extends BaseService<any> {

  constructor(http: HttpClient) {
    super(http);
  }

  getModuleName(): string {
    return 'postPaidPlan';
  }
  validate(): boolean {
    return false;
  }

  getVASByType(name){
    const get_url = BASE_API_URL + 'VAS/getVASByType/'+ name;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers});
  }

  getQosPolicyByType():Observable<any>{
    return this.http.get<any>(BASE_API_URL+'qosPolicy/getQosPolicyListByType/Broadband');
  }
  convertToTopUp(){
    const get_url = BASE_API_URL + 'convertVolToTopup' ;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers });
  }

  getPackagesData(packages_id): Observable<any> {
    const get_url = BASE_API_URL + 'postpaidplan/' + packages_id;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  checkForSetUpBox(id){
    const get_url=baseApiUrl+'subscriber/checkForSetUpBox/' + id;
    return this.http.get<any>(get_url);
  }
  
}