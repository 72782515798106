import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class LedgerService {
  constructor(private http: HttpClient) {}

  getLedgerData(ledger_data: any): Observable<any> {
    const post_url = baseApiUrl + 'customerLedgers';
    return this.http.post<any>(post_url, ledger_data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
  }

  excelServiceData(value) {
   const get_url = baseApiUrl + "customerLedgers/excel";
   return this.http.post<any>(get_url,value,{ observe: 'body', responseType: 'blob' as 'json' });
 }
}