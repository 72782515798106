export class Customer{
    id:number;
    custname:string;
    cusemail:string;
    cusphone:number;
    billname:string;
    alternatemobile:string;
    billaddress:string;
    techconname:string;
    techconphone:number;
    smsenable:boolean = true;  
    emailenable:boolean = true;
    serviceareaid:number;
    leaslinedContactDetail : Array<any>;
    salesrepid: number;
}