import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class PoliciesService {
  dynaauthpolicies:boolean
  constructor(private http: HttpClient) {

  }

  transformPoliciesData(data) {
    let formatedData = [];
    if(this.dynaauthpolicies){
      data.dynaAuthProfile.forEach(function(item:any){
        let itemData:any = {};
        itemData.policies_id = item.id;
        itemData.policies_name = item.name;
        itemData.status = item.status;
        itemData.updatedate = item.updatedate;
        itemData.dynaAuthType = item.dynaAuthType
        formatedData.push(itemData);
      });
    }else{
      data.radiusprofileList.forEach(function(item:any){
        let itemData:any = {};
        itemData.policies_id = item.id;
        itemData.policies_name = item.name;
        itemData.status = item.status;
        itemData.updatedate = item.updatedate;
        formatedData.push(itemData);
      });
    }
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getPoliciesDataWithPageing(params: HttpParams): Observable<any> {
    let post_url = ''
    if(this.dynaauthpolicies){
      post_url = BASE_API_URL + 'dynaAuthProfile/list';
    }else{
      post_url = BASE_API_URL + 'radiusprofile/list';
    }

    return this.http.post<any>(post_url,params,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformPoliciesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPoliciesById(id : any) : Observable<any> {
    let get_url = ""
    if(this.dynaauthpolicies){
      get_url = BASE_API_URL + 'dynaAuthProfile/' + id;
    }else{
      get_url = BASE_API_URL + 'radiusprofile/' + id;
    }
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          let data : any
          if(this.dynaauthpolicies){
            data = res.dynaAuthProfile
          }else{
            data = res.radiusprofile;
          }
          let formData = {
            policies_id: data.id,
            policies_name: data.name,
            status: data.status,
            dynaAuthType:data.dynaAuthType
          };
          return formData;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getPolicies(type) : Observable<any> {
    const get_url = BASE_API_URL + 'radiusprofile/all/' + type;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformPoliciesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPoliciesByName(policies_name) : Observable<any> {
    const get_url = BASE_API_URL + 'radiusprofile';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformPoliciesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertPolicies(policies_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'radiusprofile';
    return this.http.post<any>(post_url, policies_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updatePolicies(policies_data: any,policies_id:any): Observable<any> {
    let update_url = ''
    if(this.dynaauthpolicies){
      update_url = BASE_API_URL +"dynaAuthProfile/"+policies_id;
    }else{
      update_url = BASE_API_URL +"radiusprofile/"+policies_id;
    }
    return this.http.put<any>(update_url, policies_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deletePolicies(policies_id: any): Observable<any> {
    let delete_url = ''
    if(this.dynaauthpolicies){
      delete_url = BASE_API_URL +"dynaAuthProfile/"+policies_id;
    }else{
      delete_url = BASE_API_URL +"radiusprofile/"+policies_id;
    }
    return this.http.delete(delete_url, httpOptions);
  }
  commonStatus(): Observable<any> {
    const get_url = BASE_API_URL + "commonList/commonStatus";
    return this.http.get(get_url);
  }

}
