const neoGridDefaultSettings = {
  showAdvanceSearch: false,
  showFooter: false,
  showPagination: false,
  serverSideSortPagination: false,
  pageTotalLength: 0,
  tableId: '',
  pageNumber:0,
  tableHeight:null,
  perPage: 10,
  pageSizeOptions: [5, 10, 15, 20, 50, 100],
  sort: true,
  defaultSortCol: '',
  defaultSortDirection: 'asc',
  showFilter: true,
  serverSideFilter: false,
  columns: [],
  deleteMsgBoxDetail: '',
  deleteMsgBoxTitle: 'Are you sure you want to delete?',
  enableExcelExport: false,
  excelExportUrl: '',
  enablePdfExport: false,
  pdfExportUrl: '',
  enableMultiDelete: false,
  showMultiDeleteConfirmBox: true,
};
export { neoGridDefaultSettings };
