import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class TaxService {
  constructor(private http: HttpClient) {

  }

  transformTaxDataWithPageing(data) {
    let formatedData = [];
    data.taxlist.forEach(item => {
      let itemData: any = this.transformTaxDataById(item);
      formatedData.push(itemData);
    });
    return { content: formatedData,pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getTaxDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'taxes/list';
    return this.http.post<any>(post_url,params,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformTaxDataWithPageing(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformTaxTierGroupsData(api_data) {
    let formatedData: any = [];
    for (const key in api_data.data) {
      if (api_data.data.hasOwnProperty(key)) {
        let display_field = api_data.data[key];
        let value_field = key;
        formatedData.push({
          tax_tier_group_value: value_field,
          tax_tier_group_name: display_field
        });
      }
    }
    return formatedData;
  }

  getTaxTierGroupsData(): Observable<any> {
    const get_url = baseApiUrl + 'gettaxtiergroups';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformTaxTierGroupsData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  transformTaxDataById(item) {
    let itemData: any = {};
    itemData.tax_id = item.id;
    itemData.tax_name = item.name;
    itemData.tax_desc = item.desc;
    itemData.tax_type = item.taxtype;
    itemData.status = item.status;
    itemData.updatedate = item.updatedate;
    if (item.slabList && item.slabList.length) {
      let formatedSlabList: any = [];
      item.slabList.forEach(slab_item => {
        let newSlabItemData: any = {
          name: slab_item.name,
          rate: slab_item.rate,
          range_from: slab_item.rangeFrom,
          range_to: slab_item.rangeUpTo
        };
        if (slab_item.id) {
          newSlabItemData.id = slab_item.id;
        }
        formatedSlabList.push(newSlabItemData)
      });
      itemData.slab_list = formatedSlabList;
    }

    if (item.tieredList && item.tieredList.length) {
      let formatedTieredList: any = [];
      item.tieredList.forEach(tier_item => {
        let newTierItemData: any = {
          name: tier_item.name,
          rate: tier_item.rate,
          tier_group: tier_item.taxGroup
        };
        if (tier_item.id) {
          newTierItemData.id = tier_item.id;
        }
        formatedTieredList.push(newTierItemData)
      });
      itemData.tier_list = formatedTieredList;
    }
    return itemData;
  }

  getTaxDataById(tax_id): Observable<any> {
    const get_url = baseApiUrl + 'taxes/'+tax_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformTaxDataById(res.taxData);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  transformTaxList(data) {
    let formatedData = [];
    data.taxlist.forEach(function(item: any) {
      let itemData: any = {};
      itemData.tax_id = item.id;
      itemData.tax_name = item.name;
      itemData.status = item.status;
      formatedData.push(itemData);
    });
    return { content: formatedData };
  }

  getTaxList(): Observable<any> {
    const get_url = baseApiUrl + 'taxes/tieredTax';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformTaxList(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertTax(tax_data: any): Observable<any> {
    const post_url = baseApiUrl + 'taxes';
    return this.http.post<any>(post_url, tax_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updateTax(tax_data: any, tax_id: any): Observable<any> {
    const update_url = baseApiUrl + 'taxes/' + tax_id
    return this.http.put<any>(update_url, tax_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  deleteTax(tax_id: any): Observable<any> {
    const delete_url = baseApiUrl + 'taxes/' + tax_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
