import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from '../city/city.service';
import { CountryService } from '../country/country.service';
import { StateService } from '../state/state.service';
import _ from 'underscore';
import { MatDialog ,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../core/constant/response-code';
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
export interface DialogPassData {
  id: number;
}

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent implements OnInit {
  saveBtn:boolean=false;
  cityDataForGrid: any = { content: [] };
  cityGridSettings: any = {};
  isDisabled:boolean=false;
  public sub: any = null;
  public city_id: any = null;
  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public stateList: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  status = [
    { value: 'Active', name: "Active" },
    { value: 'InActive', name: "In Active" }
  ]
  selectForUpdate: any;
  show_update: any = false;
  req_message = "This field is required.";
  insertCityForm = new FormGroup({
    country_id: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    city_name: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
  });

  constructor(
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    public dialog:MatDialog,
    private sharingdataService:DataSharingServiceService,
    public dialogRef: MatDialogRef<AddCityComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    private tosterservice: ToastrService
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.city_id = id;
      this.show_update = true;
      this.isDisabled=false; 
      this.getCityData();
    }
   
  //  if(this.data !== null){
  //     this.city_id=this.data.id;
  //     this.show_update = false;
  //     this.isDisabled=true;
  //     this.getCityData();
  //   }


    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.city_id = params['id'];
    //       this.show_update = false;
    //       this.getCityData();
    //     }
    //   });
    this.countryService.getCountryList().subscribe((result) => {
      this.countryList = result.content;
    });
  }

  validateForm() {
    if (!this.insertCityForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertCityForm.controls).forEach(field => {
        const control = this.insertCityForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
     
      if (this.city_id === null) {
        this.insertCityOnDb();
      } else {
        this.updateCityOnDb();
      }
    }
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getCityData() {
    let city_id = this.city_id;
    this.cityService.getCityListById(city_id).subscribe(
      result => {
        this.setUpdateValueInForm(result);
      });
  }

  onCountryChange($event) {
    if ($event) {
      this.getStateListByCountryId($event.country_id);
    } else {
      this.stateList = [];
      this.insertCityForm.get('state_id').patchValue([]);
    }
  }

  onCountryClear() {
    this.stateList = [];
    this.insertCityForm.get('state_id').patchValue([]);
  }

  getStateListByCountryId(country_id: any) {
    this.stateService.getStateListByCountryId(country_id).subscribe((result) => {
      this.stateList = result.content;
    });
  }

  getStateListByCountryIdAndSetState(country_id: any, state_id: any) {
    this.stateService.getStateListByCountryId(country_id).subscribe((result) => {
      this.stateList = result.content;
      this.insertCityForm.patchValue({ state_id: state_id });
    });
  }

  transformInsertData(apiInputData) {
    let formatedInputData: any = { statePojo: { countryPojo: {} } };
    let country_id = apiInputData.country_id;
    formatedInputData.countryId = country_id;
    formatedInputData.name = apiInputData.city_name;
    formatedInputData.statePojo.id = apiInputData.state_id;
    formatedInputData.statePojo.countryPojo.id = country_id;
    formatedInputData.status = apiInputData.status;
    return formatedInputData;
  }

  insertCityOnDb() {
    if (this.insertCityForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertCityForm.value);
      let formatedInputData = this.transformInsertData(apiInputData);
      this.cityService.insertCity(formatedInputData).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status === ResponseCode.OK){
            let data: any = {};
            let cityData: any = result.city;
            data.city_id = cityData.id;
            data.city_name = cityData.name;
            data.status = cityData.status;
            data.state_id = cityData.statePojo.id;
            data.state_name = cityData.statePojo.name;
            data.country_id = cityData.statePojo.countryPojo.id;
            data.country_name = cityData.statePojo.countryPojo.name;
            this.cdRef.detectChanges();
            this.backToViewCity();
            this.tosterservice.success('City has been created successfully');
          }
        }, err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  updateCityOnDb() {
    if (this.insertCityForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertCityForm.value);
      let city_id = this.selectForUpdate.city_id;
      let formatedInputData: any = this.transformInsertData(apiInputData);
      formatedInputData.id = city_id;
      this.cityService.updateCity(formatedInputData, city_id).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status === ResponseCode.OK){
            this.cityDataForGrid.content.forEach((item, index) => {
              let data: any = result.city;
              if (item.city_id === city_id) {
                this.cityDataForGrid.content[index].city_name = data.name;
                this.cityDataForGrid.content[index].status = data.status;
                this.cityDataForGrid.content[index].state_id = data.statePojo.id;
                this.cityDataForGrid.content[index].state_name = data.statePojo.name;
                this.cityDataForGrid.content[index].country_id = data.statePojo.countryPojo.id;
                this.cityDataForGrid.content[index].country_name = data.statePojo.countryPojo.name;
              }
            });
            this.cdRef.detectChanges();
            this.show_update = false;
            this.backToViewCity();
            this.tosterservice.success('City Update successfully');
          }
     
        }, err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  setUpdateValueInForm(cityData) {
    this.show_update = true;
    this.selectForUpdate = cityData;
    let cityFormData = {
      city_name: cityData.city_name,
      status: cityData.status,
      country_id: cityData.country_id,
      state_id: cityData.state_id,
    };
    this.insertCityForm.patchValue(cityFormData);
    this.getStateListByCountryIdAndSetState(cityFormData.country_id, cityFormData.state_id);
  }

  backToViewCity() {
    
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/masters/city']);
    }
  }

  closeDialog(){
   this.dialogRef.close();
  }

}
