import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import * as alertify from 'alertifyjs';
import { ChangeOtherDetailsService } from './chnage-other-details.service';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../../../../src/app/services/common_list_service/common-list-services.service';
@Component({
  selector: 'app-change-other-details',
  templateUrl: './change-other-details.component.html',
  styleUrls: ['./change-other-details.component.scss']
})
export class ChangeOtherDetailsComponent implements OnInit {
  @Input() custId: any;
  selectForUpdate: any;
  EnableAutoticketing=[
    {label:"Yes",value:"true"},
    {label:"No",value:"false"}
  ]
  selectedOption:boolean
  changeOtherDetails: any;
  AddressType:any;
  
  changeOtherDetailsForm = new FormGroup({
    onlinerenewalflag: new FormControl('', [Validators.required]),
    voipenableflag: new FormControl('', [Validators.required]),
    smsflag: new FormControl('', [Validators.required]),
    emailflag: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required]),
    isAutoTicketAllowed:new FormControl('',[Validators.required]),
    //skyproid:new FormControl(''),
    billingAddressType:new FormControl('',[Validators.required])
  });

  constructor(
    private customModalService: CustomModalService,
    private chnageOtherDetailsService: ChangeOtherDetailsService,
    private tosterservice: ToastrService,
    private commonListService: CommonListServicesService,

  ) { }

  ngOnInit() {
    this.getChangeOtherDetails();
    this.getAddressTypeList();
  }

  setUpdateValueInForm(chnageOtherDetails_data) {
    this.selectForUpdate = chnageOtherDetails_data;
    this.changeOtherDetailsForm.patchValue(chnageOtherDetails_data);
    this.changeOtherDetailsForm.get('isAutoTicketAllowed').setValue(String(chnageOtherDetails_data.isAutoTicketAllowed))
  }
  changeSMS(event) {
    if (event.target.checked) {
      this.changeOtherDetailsForm.get('smsflag').setValue(true);
    } else {
      this.changeOtherDetailsForm.get('smsflag').setValue(false);
    }
  }
  changeEmail(event) {
    if (event.target.checked) {
      this.changeOtherDetailsForm.get('emailflag').setValue(true);
    } else {
      this.changeOtherDetailsForm.get('emailflag').setValue(false);
    }
  }
  enableAutoticketing(){
     const valuetosend=this.selectedOption
    this.changeOtherDetailsForm.get('EnableAutoticketing').setValue(valuetosend)
    
  }

  getChangeOtherDetails() {
    this.chnageOtherDetailsService.getChamgeOtherDetails(this.custId).subscribe(result => {
      this.changeOtherDetails = result.data;
      this.setUpdateValueInForm(this.changeOtherDetails);
    });
  }

  onClickSaveForm() {
    if (this.changeOtherDetailsForm.valid === true) {
      let self = this;
      alertify.confirm("Contect Details", "Are You Sure You want to Change Other Details ?",
        function () {
          self.updateChangeOtherDetailsOnDb();
        },
        function () {

        });
    } else {
      this.changeOtherDetailsForm.markAllAsTouched();
    }
  }

  updateChangeOtherDetailsOnDb() {
    if (this.changeOtherDetailsForm.valid === true) {
      let formatedInputData: any = this.changeOtherDetailsForm.value;
      formatedInputData.custId = Number(this.custId);
      formatedInputData.isAutoTicketAllowed=this.changeOtherDetailsForm.get('isAutoTicketAllowed').value
      this.chnageOtherDetailsService.updateChangeOtherDetailsDetails(formatedInputData).subscribe(result => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Change Other Detail Update successfully');
          this.customModalService.close('change-other-details', result);
        }
      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

  getAddressTypeList() {
    this.commonListService.getGenericRemarkType('addressType').subscribe((res) => {
      this.AddressType = res.dataList;
    });
  }
}
