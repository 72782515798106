import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../services/base-service';
import { Property } from '../../model/Property';
import { Observable } from 'rxjs';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { RequestData } from '../../../../core/modal/base-save-update-modal';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { SearchData } from '../../../../core/modal/search-data';
import { HttpHeaders } from '@angular/common/http';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})
export class leasedpropertyservice extends BaseService<Property> {

  leasedId: any;
  propertyId:any;
  constructor(
    public http: HttpClient
  ) {
    super(http)
  }
  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "leasedlinesubscriber";
  }

  delete(modal: any): Observable<ResponseData<any>> {
    let reqObj: RequestData<any> = new RequestData<any>();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    ////console.log('save called !');
    // return null;
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/deleteProperty', reqObj.data);
  }
  
  
 
}
