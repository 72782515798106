import { Component, OnInit, TemplateRef, ViewChild ,Input} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs-compat';
import { StaffService } from '../../../../../../src/app/pages/hr/staff/staff.service';
import { AppConfig } from '../../../../../../src/app/app.config';
import { OldAbstractBaseListComponent } from '../../../../../../src/app/components/base/old-abstract-base-list-component';
import { TableKeyEnum } from '../../../../../../src/app/core/enum/table-key.enum';
import { SearchService } from '../../../../../../src/app/services/search.service';
import { FranchiesService } from '../../franchies/franchies.service';
import { SubscriberService } from '../../subscriber/subscriber.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-partner-role',
  templateUrl: './partner-role.component.html',
  styleUrls: ['./partner-role.component.scss']
})
export class PartnerRoleComponent extends OldAbstractBaseListComponent implements OnInit {
  
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  
  @Input()  franchise_id: any
  //@Output() docId: any = new EventEmitter();
  roleDataForGrid: any = [];
  partnerRoleGridSettings: any = {};
  // AclConstants = AclConstants;
  // AclClassConstants = AclClassConstants;
  show_update: any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  subscriptions: Subscription[] = [];
  openOtherDetails: boolean;
  gridConfig: any;
  roleList:[]=[];
  setvalue:any;
  constructor(
    private searchService: SearchService,
    private subscriberService: SubscriberService,
    private franchiesService:FranchiesService,
    private staffService:StaffService,
    private tosterservice:ToastrService,
    appConfig: AppConfig
  ) { 
    super(searchService)
  }

  ngOnInit() {
    
    this.setDefaultPageSize(this.tableKey.PARTNER_ACL_ROLE_CONFIGURATION)
    this.setGridSetting();
    this.getListWithParams();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
    this.getAllRole();
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
  }

  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    ////console.log("data ==> ",config);
    this.getRoleForPartner(config);
  }
 
  transformData(param){
    param.filters=[{
      "filterColumn": "partnerId", 
      "filterValue": this.franchise_id
    }]   
    return param
  }

  getRoleForPartner(gridSetting: any) {

    let fetchSettingData = this.partnerRoleGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    let paramsData=this.transformData(params)
    this.franchiesService.getpartnerRoleListing(paramsData).subscribe(result => {
      if (result.responseCode === 200) {
        if(result.dataList && result.dataList.length>0){
          this.refreshDataSource(result.dataList);
          this.totalRecords = result.totalRecords ? result.totalRecords : 0;
          this.partnerRoleGridSettings.pageTotalLength = this.totalRecords;
        }else{
          this.refreshDataSource([]);
        }
        
      } else {
        this.tosterservice.error(result.responseMessage);
      }
    })
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '2%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'roleName',
        default: 'Role Name',
        rowKey: 'roleName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'last Modified Date',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'last Modified Name',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'actionCol',
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];

    this.partnerRoleGridSettings = {
      tableId: this.tableKey.PARTNER_ACL_ROLE_CONFIGURATION,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      showFilter: false,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
    
    };
    if (this.partnerRoleGridSettings.serverSideSortPagination && this.partnerRoleGridSettings.perPage) {
      this.setPaginationPerPage(this.partnerRoleGridSettings.perPage);
    }
  }

   applyFilter(filterObj) {
    let filteredRoleForGrid = JSON.parse(JSON.stringify(this.roleDataForGrid));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredRoleForGrid = this.searchService.getFilteredData(filterObj.filters, this.roleDataForGrid);
    }
    this.refreshDataSource(filteredRoleForGrid);
  }



 //**Hide the Code For Update Role

 // onEditClicksRole(event) {
    // let obj={
  //   "id":event.id ,
  //   "partnerId":event.partner_id,
  //   "roleId":event.roleId
  // }

  //   this.franchiesService.updateRoleofPartner(obj).subscribe((result) => {
  //     if(result.responseCode === 200){
  //       this.tosterservice.success(" Role Updated Successfully")
  //     }
  //   });
  //   this.getListWithParams()
  // }

  
  onDeleteClicksRoleList(delete_obj) {
   
    this.franchiesService.deleteRoleofPartner(delete_obj).subscribe((result) => {
      if(result.responseCode === 200){
        this.tosterservice.success("Role Deleted Successfully");
        this.getListWithParams()  
      }
    },
      (error)=>{
        this.tosterservice.error(error.responseMessage)
      });

  
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getRoleForPartner(config);
  }
 
  //add user for Drop down
  getAllRole(){
      this.staffService.getAllRoleDataForAdmin().subscribe(result => {
        this.roleList = result.dataList;
      })
  }

  saveOnClick(objValue){
     let data={
      partnerId:Number(this.franchise_id),
      roleId:objValue.id
    };
    this.franchiesService.aclPartnerConfig(data).subscribe((res)=>{
      if(res && res.responseCode==200){
        this.tosterservice.success("Role Added Successfully");
        this.setvalue=null;
        this.getListWithParams();
      }else{
        this.tosterservice.error(res.responseMessage);
        this.roleList=null;
      }
    }),(error) => {
      this.tosterservice.error(error.err.responseMessage);
      this.roleList=null;
    }
  }

  refreshdataSourceRole(data){
    if(data){
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

}


