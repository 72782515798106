import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() page: number;
  @Input() count: number;
  @Input() perPage: number;
  @Input() loading: boolean;
  @Input() pagesToShow: number;

  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  getMin(): number {
    return ((this.perPage * this.page) - this.perPage) + 1;
  }

  getMax(): number {
    let max = this.perPage * this.page;
    if (max > this.count) {
      max = this.count;
    }
    return max;
  }

  onPage(n: number): void {
    this.goPage.emit(n);
  }

  onPrev(): void {
    this.goPrev.emit(true);
  }

  onNext(next: boolean): void {
    this.goNext.emit(next);
  }

  totalPages(): number {
    return Math.ceil(this.count / this.perPage) || 0;
  }

  lastPage(): boolean {
    return this.perPage * this.page >= this.count;
  }

  getPages(): number[] {
    let pagination_setting = this.getPager(this.count,this.page,this.perPage);
    return pagination_setting.pages;
  }


  range(start: number, stop: number, step: number) {
   if (stop == null) {
     stop = start || 0;
     start = 0;
   }
   if (!step) {
     step = stop < start ? -1 : 1;
   }
   var length = Math.max(Math.ceil((stop - start) / step), 0);
   var range = Array(length);
   for (var idx = 0; idx < length; idx++, start += step) {
     range[idx] = start;
   }
   return range;
 }

 getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
      let startPage: number, endPage: number;
      if (totalPages <= 5) {
          startPage = 1;
          endPage = totalPages;
      } else {
          if (currentPage <= 3) {
              startPage = 1;
              endPage = 5;
          } else if (currentPage + 1 >= totalPages) {
              startPage = totalPages - 4;
              endPage = totalPages;
          } else {
              startPage = currentPage - 2;
              endPage = currentPage+2;
          }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      //let pages = _.range(startPage, endPage + 1);
      let pages = this.range(startPage,endPage+1,1);

      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
  }

}
