import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';

import { ChangePlanService } from './change-plan.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { RecordPaymentService } from '../record-payment/record-payment.service';
import { PaymentModeService } from '../../../../services/payment_mode/payment_mode.service';
import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
// import {LoadingService} from '../../../../components/loading/loading.service';

import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { ApplyChargeService } from '../apply-charge/apply-charge.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../../../../../src/app/auth/token-storage-service';
import { ActivePlanListService } from '../subscriber-tables/active-plan/active-plan.service';


@Component({
    selector: 'app-change-plan',
    templateUrl: './change-plan.component.html',
    styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent implements OnInit {

    @Input() custId: any;
    @Input() ui_id: string;
    @Input() service: string;
    @Input() partnerId: number;
    @Input() bal:number;
    private ngbRef: NgbModalRef;

    AclClassConstants = AclClassConstants;
    AclConstants = AclConstants;
    refundOptionFlag: boolean = false;

    taxDetailsByPlanData: number;
    req_message = 'This field is required.';
    amount: number;
    addonStart: boolean;
    selectedDefaultRefundOption: any
    defaultRefundOptForNoACL: any;
    activatetionDate: boolean;
    // submitted: any = null;
    submitted: boolean = false;
    isOverhead: boolean = false;
    isIncludingTax: boolean = false;
    subscriberCurrentPlanData: any;
    selectedPlan: any;
    minWithTax: any;
    maxWithTax: any;
    subbmitted: boolean = false;
    displayActivateDate: any;
    displayExpiryDate: any;
    showCurrentPlanDetails: boolean = true;
    showExpiredPlanDetails: boolean = true;
    showAdvRenewal: boolean = false;
    amountValid: boolean;
    paymentDateValid: boolean;
    tdsAmountValid: boolean;
    chequeNoValid: boolean;
    bankNameValid: boolean;
    chequeDateValid: boolean;
    branchValid: boolean;
    finalpaybal: number;
    checkeligiblity: any;
    remarksValid: boolean;
    purchaseaddonprice: any;
    oldPurchasePrice: any;
    paymentModeValid: boolean;
    referenceNoValid: boolean;
    refundShow: boolean = false;
    finalPayableAmount: number = 0;
    planPrice: number = 0;
    advanceRenewFlag: any;
    purchaseType: string;
    purchaseEndDate: Date;
    isaddon: boolean;
    CurrentPlan: any
    isChecked: boolean = false;
    // addonStartDate:Date;
    addonStartDate: any;
    finalAmount: number;
    endDate: string;
    mindate: Date;
    public planList: any;
    isvalideligiblity: Boolean;
    isPriceTaxMin: boolean = false;
    isPriceTaxMax: boolean = false;
    fullAmount: any
    fullAmountWithTax: any
    proratedAmount: any
    proratedAmountWithTax: any
    public planPurchaseTypeList: any;
    selected_payment_mode_value: any
    selected_tds_deducted: any;
    Partner_type: any
    refundOption: any
    planTypeList:any
    planGroup:any;
    plandataType:any;
    collectorList: [];
    collectorFlag:boolean = true;
    customerStatus:string;
    submitBtnDisable:boolean=false;
    activePlanTableData: any;
    public tdsPendingPaymentsList: any[] = [
        {
            display_text: '',
            id: '',
        },
    ];
    public paymentModeList: any[] = [
        {
            text: '',
            value: '',
        },
    ];

    custStatusList: any[] = [{ 'text': 'TDS', 'value': 'TDS' },
    { 'text': 'Cash', 'value': 'Cash' },
    { 'text': 'Wallet', 'value': 'Wallet' }
    ]

    public advRenewalList: any[] = [
        {
            text: 'Yes',
            value: true,
        },
        {
            text: 'No',
            value: false,
        },
    ];

    changePlanForm: FormGroup;

    constructor(
        private ngbModalService: NgbModal,
        private customModalService: CustomModalService,
        private cdRef: ChangeDetectorRef,
        private changePlanService: ChangePlanService,
        private commonService: CommonListServicesService,
        private paymentModeService: PaymentModeService,
        private recordPaymentService: RecordPaymentService,
        private planService: PlanServices,
        private tosterservice: ToastrService,
        private datePipe: DatePipe,
        private datepipe: DatePipe,
        public loginService: LoginService,
        private applyChargeService: ApplyChargeService,
        private tokenservice:TokenStorageService,
        // private activePlanListService:ActivePlanListService

    ) {
        this.loginService = loginService
      }

    ngOnInit() {
        this.setValidation();
        if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER, AclConstants.OPERATION_CUSTOMER_REFUND_OPTION, AclConstants.OPERATION_CUSTOMER_ALL)) {
            this.refundOptionFlag = true;
        }
        else {
            this.refundOptionFlag = false;
        }


        this.commonService.getGenericCommonList('refundOptionType').subscribe((res) => {
            this.refundOption = res.dataList;
        });
        this.getSubscriberCurrentPlan();
        this.getStartDateEndDate();
        this.Partner_type = localStorage.getItem('Partner_Type');
        this.getPaymentModeListFromGenericApi();
        if (this.ui_id === 'purchase-addon-plan') {
            this.purchaseAddon();
            if (this.purchaseaddonprice) {
                this.purchaseaddonprice = 0
            }
        }
        this.getPlanTypeList();
        this.getPaymentCollectorList(this.custId);
        
    }

    getPaymentCollectorList(custid){
        this.applyChargeService.getPaymentCollectorList(custid).subscribe(
          result => {
            this.collectorList = result.dataList;
          }
        )
      }

    oncheckUseBalance(event){
    let oldfinalpay;
    if(this.ui_id === 'change-plan'){
        oldfinalpay = this.planPrice + this.taxDetailsByPlanData;
    }
    else{
        if (!this.isOverhead) {
            oldfinalpay = this.purchaseaddonprice + this.selectedPlan.taxamount;
        }
        if (this.isOverhead) {
            oldfinalpay = this.purchaseaddonprice;
        }
    }
    let amount = this.changePlanForm.get('recordPaymentDTO').get('amount').value;
    if(event && event.target.checked == true){
      if(this.bal >= oldfinalpay && amount >= oldfinalpay){
        this.collectorFlag = false;
        this.finalpaybal = 0;
        this.changePlanForm.get('balanceAmount').setValue(oldfinalpay);
      }else{
        this.collectorFlag = true
        this.finalpaybal = oldfinalpay - this.bal;
        this.changePlanForm.get('balanceAmount').setValue(this.bal);
      }
    }else{
      this.changePlanForm.get('balanceAmount').reset();
      this.collectorFlag = true
      if(this.ui_id === 'change-plan'){
        this.finalpaybal = this.planPrice + this.taxDetailsByPlanData;
      }
      else{
        if (!this.isOverhead) {
            this.finalpaybal = this.purchaseaddonprice + this.selectedPlan.taxamount;
        }
        if (this.isOverhead) {
            this.finalpaybal = this.purchaseaddonprice;
        }
      }
    }
    //console.log('-->',this.changePlanForm);
    }

    checkamount(){
    let oldfinalpay;
    if(this.ui_id === 'change-plan'){
        oldfinalpay = this.planPrice + this.taxDetailsByPlanData;
      }
      else{
        if (!this.isOverhead) {
            oldfinalpay = this.purchaseaddonprice + this.selectedPlan.taxamount;
        }
        if (this.isOverhead) {
            oldfinalpay = this.purchaseaddonprice;
        }
      }
    let amount = this.changePlanForm.get('recordPaymentDTO').get('amount').value;
    if(this.bal >= this.finalpaybal && this.changePlanForm.get('isBalanceUsed').value == true){
        if(amount == oldfinalpay || amount == 0){
            this.collectorFlag = false;
            this.changePlanForm.get('balanceAmount').setValue(this.bal);
            //this.changePlanForm.get('collectorId').reset();
        }else{
            this.collectorFlag = true;
            this.changePlanForm.get('balanceAmount').setValue(oldfinalpay);
        }
    }else{
        this.collectorFlag = true;
      }
      //console.log(',,', this.changePlanForm)
    }

    getPlanTypeList(){
        this.commonService.getGenericCommonList('planTypeForNew').subscribe((res)=>{
          this.planTypeList = res.dataList
          .filter((ls) => {
            if (ls.value === 'Renew' || ls.value === 'Registration') {
              return ls;
            }
          });
        })
        let plan = this.planTypeList[1];
        if(plan.value){
            this.plandataType = plan.value;
        }
        this.onChangePlanType(plan)
      }

    setValidation(){
        this.changePlanForm = new FormGroup({
            planId: new FormControl(null, [Validators.required]),
            advRenewal: new FormControl(null),
            isIncludeTax: new FormControl(false),
            isPriceOverride: new FormControl(false,Validators.required),
            sellPrice: new FormControl(''),
            planPurchaseType: new FormControl('', [Validators.required]),
            skip: new FormControl(),
            remarks: new FormControl('', [Validators.required]),
            isPaymentReceived: new FormControl('no', [Validators.required]),
            addonStartDate: new FormControl(new Date()),
            refundOption: new FormControl(null),
            planType: new FormControl(null),
            //collectorId: new FormControl(null),
            isBalanceUsed: new FormControl(false),
            balanceAmount: new FormControl(null),
            recordPaymentDTO: new FormGroup({
                amount: new FormControl(''),
                payment_date: new FormControl(''),
                payment_mode: new FormControl(''),
                referenceNo: new FormControl(''),
                chequeNo: new FormControl(''),
                bankName: new FormControl(''),
                chequeDate: new FormControl(''),
                branch: new FormControl(''),
                tdsDeducted: new FormControl(false),
                tdsAmount: new FormControl(''),
                remarks: new FormControl(''),
                credit_doc_id: new FormControl(''),
            })
        });
    }

    purchaseAddon() {
        if (this.ui_id === 'purchase-addon-plan') {
            this.mindate = new Date(Date.now());
            this.changePlanService.checkeligibility(this.custId).subscribe((res) => {
                this.checkeligiblity = res.data;
                if (this.checkeligiblity.isConvertToAddon === true) {
                    if (this.checkeligiblity.isValidActivePlan === true) {
                        ////console.log;
                        this.isvalideligiblity = true;
                    }
                } else {
                    this.changePlanForm.controls.addonStartDate.setValidators(Validators.required);
                    this.isaddon = false;
                }
            });
        }

    }

    getPaymentModeListFromGenericApi() {
        this.paymentModeService.getPaymentModeListFromGenericApi().subscribe(result => {
            this.paymentModeList = result.dataList;
        });
    }

    onChangeTdsDeducted(el) {
        let is_tds_deducted = el;
        this.selected_tds_deducted = is_tds_deducted;
    }

    // paymentReceivedTrue(event) {
    //   if (event.target.value === 'on') {
    //     this.changePlanForm.controls.isPaymentReceived.setValue(true);
    //   }
    // }
    //
    // paymentReceivedFalse(event) {
    //   if (event.target.value === 'on') {
    //     this.changePlanForm.controls.isPaymentReceived.setValue(false);
    //   }
    // }

    onChangePaymentMode(el) {
        if (el) {
            let payment_mode_value = el.value;
            this.selected_payment_mode_value = payment_mode_value;
            if (this.selected_payment_mode_value === 'TDS') {
                this.recordPaymentService.getTdsPendingPayments(this.custId).subscribe(result => {
                    this.tdsPendingPaymentsList = result.dataList;
                });
            }
        } else {
            this.selected_payment_mode_value = '';
        }
    }
    onRefundOptionChange(value) {
        if (value.length > 0) {
            value = value[0]
        }
        if (value) {
            if (this.subscriberCurrentPlanData.currentPlanDTO != null) {
                if (value.value === 'Prorated Without Tax') {
                    this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount = this.subscriberCurrentPlanData.currentPlanDTO.proratedAmount;
                } else if (value.value === 'Prorated With Tax') {
                    this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount = this.subscriberCurrentPlanData.currentPlanDTO.proratedAmountWithTax
                } else if (value.value === 'None') {
                    this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount = "0";
                }
                else if (value.value === 'Full Without Tax') {
                    this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount = this.subscriberCurrentPlanData.currentPlanDTO.fullAmount;
                }
                else if (value.value === 'Full With Tax') {
                    this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount = this.subscriberCurrentPlanData.currentPlanDTO.fullAmountWithTax
                }
            }
        }
        else {
            this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount = '0';
        }
    }

    onChangePlanType(event){
        this.changePlanForm.get('planId').reset();
        this.planGroup = null;
        if(event && event.value){
            this.planGroup = event.value
            this.getSubscriberCurrentPlan()
        }
    }

    getSubscriberCurrentPlan() {
        //this.getPlanPurchaseType();
        let subscriber_id = this.custId;
        this.changePlanService.getSubscriberCurrentPlan(subscriber_id).subscribe((result) => {
            this.selectedDefaultRefundOption = result.data.defaultRefundOptForActive;
            this.defaultRefundOptForNoACL = result.data.defaultRefundOptForNoACL;

            if (result && result.data) {
                this.subscriberCurrentPlanData = result.data;
                if (result.data.currentPlanDTO === null && result.data.expiredPlanDTO === null) {

                    this.showCurrentPlanDetails = false;
                    this.showExpiredPlanDetails = false;

                }
                if (result.data.currentPlanDTO === null) {
                    this.CurrentPlan = this.subscriberCurrentPlanData.expiredPlanDTO
                    this.purchaseType = 'New';

                    if (this.planPurchaseTypeList && this.planPurchaseTypeList.length > 0) {
                        let newPlan = this.planPurchaseTypeList.find((ls) => {
                            if (ls.value.toLowerCase() === 'new') {
                                return ls;
                            }
                        });
                        this.changePlanForm.get('planPurchaseType').patchValue(this.purchaseType);
                        this.onChangePurchaseType(newPlan);
                    }

                }
                else {

                    this.CurrentPlan = Object.assign({}, this.subscriberCurrentPlanData.currentPlanDTO)
                    this.purchaseType = 'Renew';
                    if (this.planPurchaseTypeList && this.planPurchaseTypeList.length > 0) {
                        let renew = this.planPurchaseTypeList.find((ls) => {
                            if (ls.value.toLowerCase() === 'renew') {
                                return ls;
                            }
                        });

                        this.changePlanForm.get('planPurchaseType').patchValue(this.purchaseType);
                        this.onChangePurchaseType(renew);
                    }
                    this.changePlanForm.get('advRenewal').patchValue(true);
                    if (this.advRenewalList) {
                        let adv = this.advRenewalList.find((ls) => {
                            if (ls.value === 'true') {
                                return ls;
                            }
                        });
                        this.onChangeAdvRenewal(adv);
                    }

                }
                if (this.ui_id !== 'purchase-addon-plan') {
                    if(this.planGroup == 'Registration'){
                        this.planList = this.subscriberCurrentPlanData.registrationPlanDtoList;
                    }
                    else if(this.planGroup == 'Renew' || this.planGroup == null){
                        this.planList = this.subscriberCurrentPlanData.normalPlanDtoList;
                    } 
                    if (this.planList && this.planList.length > 0) {
                        let plan = this.planList.find((ls) => {

                            if (this.subscriberCurrentPlanData.currentPlanDTO) {
                                if (ls.planId === this.subscriberCurrentPlanData.currentPlanDTO.custCurrentPlanId) {
                                    return ls;
                                }
                            }
                            else if (this.subscriberCurrentPlanData.expiredPlanDTO) {
                                if (ls.planId === this.subscriberCurrentPlanData.expiredPlanDTO.custCurrentPlanId) {
                                    return ls;
                                }
                            }
                            else {
                                return null
                            }


                        });
                        ////console.log("plan::",plan)
                        if (this.subscriberCurrentPlanData.currentPlanDTO) {

                            this.changePlanForm.get('planId').patchValue(plan ? this.subscriberCurrentPlanData.currentPlanDTO.custCurrentPlanId : null);
                            ////console.log("plan::",plan)
                            if (plan) {
                                this.onChangePlan(plan);
                            }
                        }
                        if (this.subscriberCurrentPlanData.expiredPlanDTO) {
                            this.changePlanForm.get('planId').patchValue(plan ? this.subscriberCurrentPlanData.expiredPlanDTO.custCurrentPlanId : null);
                            ////console.log("plan::",plan)
                            if (plan) {
                                this.onChangePlan(plan);
                            }
                        }


                    }
                }


            }

            this.getPlanPurchaseType();

        });
    }

    onPaymentTypeChange(event) {
        //this.changePlanForm.get('isBalanceUsed').reset();
        if (event === 'YES') {
            this.changePlanForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
            this.changePlanForm.get('recordPaymentDTO').get('amount').updateValueAndValidity();
            this.changePlanForm.get('recordPaymentDTO').get('payment_date').setValidators(Validators.required);
            this.changePlanForm.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
            this.changePlanForm.get('recordPaymentDTO').get('payment_mode').setValidators(Validators.required);
            this.changePlanForm.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
            this.changePlanForm.get('recordPaymentDTO').get('referenceNo').setValidators(Validators.required);
            this.changePlanForm.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
            // this.changePlanForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
            // this.changePlanForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
            // this.changePlanForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
            // this.changePlanForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        } else {

            this.changePlanForm.get('recordPaymentDTO').get('amount').clearValidators();
            this.changePlanForm.get('recordPaymentDTO').get('amount').updateValueAndValidity();
            this.changePlanForm.get('recordPaymentDTO').get('payment_date').clearValidators();
            this.changePlanForm.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
            this.changePlanForm.get('recordPaymentDTO').get('payment_mode').clearValidators();
            this.changePlanForm.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
            this.changePlanForm.get('recordPaymentDTO').get('referenceNo').clearValidators();
            this.changePlanForm.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
        }

    }

    refundCal() {
        if (this.purchaseType !== 'New') {
            this.finalAmount = ((this.planPrice + this.taxDetailsByPlanData) - this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount);
            if (this.finalAmount < 0) {
                this.finalAmount = 0;
            }
            this.finalpaybal = this.finalAmount;
            this.finalpaybal = Number(this.finalpaybal.toFixed(2));
            return this.finalAmount.toFixed(2);
        }
    }

    getPlanPurchaseType() {
        this.changePlanService.getPlanPurchaseType().subscribe((result) => {

            this.planPurchaseTypeList = result.dataList.filter((item: any) => {

                if (this.ui_id == 'purchase-addon-plan') {
                    this.planList = [];
                    this.activatetionDate = false;
                    this.addonStart = true;
                    return item.value == 'Volume Booster';
                } else if (this.subscriberCurrentPlanData && this.subscriberCurrentPlanData.expiredPlanDTO !== null && this.ui_id === 'change-plan') {
                    if (item.value === 'New') {
                        return item.value == 'New';
                    }
                } else if (this.subscriberCurrentPlanData && this.subscriberCurrentPlanData.currentPlanDTO !== null && this.ui_id === 'change-plan') {
                    if (item.value !== 'New' && item.value !== 'Volume Booster') {
                        return item;
                    }
                } else if (this.subscriberCurrentPlanData && this.subscriberCurrentPlanData.currentPlanDTO === null && this.subscriberCurrentPlanData.expiredPlanDTO === null && this.ui_id === 'change-plan') {
                    if (item.value === 'New') {
                        return item;
                    }
                } else {
                    this.activatetionDate = true;
                    return item;
                }
            });

            //this.showAdvRenewal = false;
            // if(this.showCurrentPlanDetails===false){
            //   this.planPurchaseTypeList = result.dataList.filter((item: any) => {
            //     if (item.value==='New') {
            //       return item.value == 'New';
            //     }
            //   });
            // }
            // else{
            //   this.planPurchaseTypeList = result.dataList.filter((item: any) => {
            //     if (item.value!=='New') {
            //       return item;
            //     }
            //   });
            // }

            // if (this.showCurrentPlanDetails === true) {
            //   this.planPurchaseTypeList = result.dataList;
            // } else {
            //   this.planPurchaseTypeList = result.dataList.filter((item: any) => {

            //     if (this.ui_id == 'purchase-addon-plan') {

            //       this.planList = [];
            //       this.activatetionDate = false;
            //       this.addonStart = true;
            //       return item.value == 'Volume Booster';
            //     } else if (this.ui_id == 'change-plan') {
            //       this.activatetionDate = true;
            //       return item;
            //     }
            //   });
            //   this.showAdvRenewal = false;
            // }
        });
    }

    onChangeAdvRenewal(advRenewalItem) {
        ////console.log('advRenewalItem-->', advRenewalItem);
        this.changePlanForm.get('skip').setValue(false);
        this.changePlanForm.get('skip').updateValueAndValidity();
        // this.changePlanForm.get('isBalanceUsed').setValue(false);
        // this.changePlanForm.get('isBalanceUsed').updateValueAndValidity();
        this.changePlanForm.get('isPaymentReceived').setValue('no');
        this.changePlanForm.get('isPaymentReceived').updateValueAndValidity();
        if (advRenewalItem) {
            if (this.selectedPlan) {
                if (advRenewalItem.value === true) {
                    this.advanceRenewFlag = true;

                    // this.displayActivateDate = this.selectedPlan.activationDate;
                    // this.displayExpiryDate = this.selectedPlan.expiryDate;

                    this.displayActivateDate = this.selectedPlan.renewalActivationDate;
                    this.displayExpiryDate = this.selectedPlan.renewalExpiryDate;


                    this.refundShow = false;
                } else if (advRenewalItem.value === false) {



                    if (this.refundOptionFlag) {
                        let selectedRefundOption = this.refundOption.filter(res => res.value === this.selectedDefaultRefundOption);

                        this.changePlanForm.get('refundOption').setValue(this.selectedDefaultRefundOption)
                        
                        this.onRefundOptionChange(selectedRefundOption[0]);
                        this.advanceRenewFlag = false;
                        this.refundShow = true;
                        // this.displayActivateDate = this.selectedPlan.renewalActivationDate;
                        // this.displayExpiryDate = this.selectedPlan.renewalExpiryDate;

                        this.displayActivateDate = this.selectedPlan.activationDate;
                        this.displayExpiryDate = this.selectedPlan.expiryDate;
                    }
                    else {
                        let selectedRefundOption = this.refundOption.filter(res => res.value === this.defaultRefundOptForNoACL);
                        
                        this.changePlanForm.get('refundOption').setValue(this.defaultRefundOptForNoACL)
                        
                        this.onRefundOptionChange(selectedRefundOption[0]);
                        this.changePlanForm.controls.refundOption.disable();
                        this.advanceRenewFlag = false;
                        this.refundShow = true;
                        // this.displayActivateDate = this.selectedPlan.renewalActivationDate;
                        // this.displayExpiryDate = this.selectedPlan.renewalExpiryDate;

                        this.displayActivateDate = this.selectedPlan.activationDate;
                        this.displayExpiryDate = this.selectedPlan.expiryDate;
                    }




                }
            }
        } else {
            // if(this.changePlanForm.value.skip === null || this.changePlanForm.value.skip === false ){
            //   ////console.log(this.selectedPlan)
            //   this.finalPayableAmount = this.planPrice ;
            // }
            this.displayActivateDate = '';
            this.displayExpiryDate = '';
        }
    }

    onSkipCheck() {
        this.changePlanForm.get('isBalanceUsed').setValue(false);
        if (this.changePlanForm.value.skip === true) {
            this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
            // this.finalPayableAmount= this.planPrice;
            // ////console.log("skip",this.finalPayableAmount)
        } else {
            // this.finalPayableAmount= this.planPrice - this.subscriberCurrentPlanData.currentPlanDTO.refundableAmount ;
            // ////console.log("skip else",this.finalPayableAmount)
        }
    }

    displayMinPrice: number;
    displayMaxPrice: number;
    setFinalPaybel() {
        if (this.ui_id === 'change-plan') {
            if (this.advanceRenewFlag === true && (this.changePlanForm.value.skip === null || this.changePlanForm.value.skip === false)) {
                this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
            }
            if (this.advanceRenewFlag === false && this.changePlanForm.value.skip === true) {
                this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
            }
            if (this.purchaseType === 'Upgrade') {
                this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
            }
            if (this.purchaseType === 'New') {
                this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
            }
            if (this.purchaseType !== 'Upgrade' && this.advanceRenewFlag === false && (this.changePlanForm.value.skip === null || this.changePlanForm.value.skip === false)) {
                this.refundCal();
            }
        }
        if (this.ui_id === 'purchase-addon-plan') {
            if (this.isOverhead) {
                this.purchaseaddonprice;
            }
        }
    }
    onChangePlan(planItem) {
        this.changePlanForm.get('isPaymentReceived').setValue('no');
        this.changePlanForm.get('balanceAmount').reset();
        this.changePlanForm.get('sellPrice').setValue(null);
        this.selectedPlan = planItem;

        //console.log("onchangePlan",planItem)
        if (this.selectedPlan && this.selectedPlan.minPrice !== null && this.selectedPlan.minPrice !== '') {
            this.isPriceTaxMin = false;
            if (this.isPriceTaxMin === false) {
                // this.selectedPlan.minPrice = this.selectedPlan.minPrice + this.selectedPlan.minPriceTax;
                // this.minWithTax = this.selectedPlan.minPrice;
                this.displayMinPrice = 0;
                this.displayMinPrice = this.selectedPlan.minPrice + this.selectedPlan.minPriceTax;
                this.isPriceTaxMin = true;
            }
            else {
                // this.minWithTax=null;
                this.isPriceTaxMin = false;
                return
            }
        }
        else {
            this.selectedPlan.minPrice = null;
            this.selectedPlan.minPriceTax = null;
            this.displayMinPrice = null;
            this.maxWithTax = null;
        }
        if (this.selectedPlan.maxPrice !== null && this.selectedPlan.maxPrice !== '') {
            this.isPriceTaxMax = false;
            if (this.isPriceTaxMax === false) {
                // this.selectedPlan.maxPrice = this.selectedPlan.maxPrice + this.selectedPlan.maxPriceTax;
                // this.maxWithTax = this.selectedPlan.maxPrice;
                this.displayMaxPrice = 0;
                this.displayMaxPrice = this.selectedPlan.maxPrice + this.selectedPlan.maxPriceTax;
                this.isPriceTaxMax = true;
            }
            else {
                this.maxWithTax = null;
                this.isPriceTaxMax = false;
                return
            }
        }
        else {
            this.selectedPlan.maxPrice = null;
            this.selectedPlan.maxPriceTax = null;
            this.displayMaxPrice = null;
            this.maxWithTax = null;
        }

        this.planPrice = this.selectedPlan.price;
        if (this.ui_id === 'purchase-addon-plan') {

            ////console.log('pruchase::', this.selectedPlan);
            this.purchaseaddonprice = this.selectedPlan.offerprice;
            this.oldPurchasePrice = this.selectedPlan.offerprice;
        }

        if (planItem) {

            let advRenewal = this.changePlanForm.get('advRenewal').value;
            let purchaseType = this.changePlanForm.get('planPurchaseType').value;
            let data: any = {
                // "chargeId": 0,
                // "locationId": this.presentAddressStateChange.state_id,
                'custId': this.custId,
                'planId': this.selectedPlan.planId
            };
            this.taxDetailsByPlanData = planItem.taxAmount;
            if (planItem.taxAmount === null) {
                this.taxDetailsByPlanData = 0;
            }
            // this.planService.getTaxDetailsByPlan(data).subscribe((result) => {
            //   this.taxDetailsByPlanData = result.TotalAmount;

            // });
            // if (this.taxDetailsByPlanData && this.taxDetailsByPlanData) {
            //   this.planPrice = this.planPrice + this.taxDetailsByPlanData
            // }

            if (purchaseType === 'New' || purchaseType === 'Upgrade' || purchaseType === 'Volume Booster') {
                this.onChangeAdvRenewal({ value: false });
                if (this.ui_id === 'purchase-addon-plan') {
                    this.selectedPlan.offerprice = this.selectedPlan.offerprice + this.selectedPlan.taxamount;
                }
            } else {
                if (advRenewal === true || advRenewal === false) {
                    this.onChangeAdvRenewal({ value: advRenewal });
                } else {
                    this.onChangeAdvRenewal('');
                }
            }
        } else {
            this.onChangeAdvRenewal('');
        }
        // setTimeout(function(){
        // this.setFinalPaybel();
        if (this.ui_id === 'change-plan') {
            this.finalpaybal = this.planPrice + this.taxDetailsByPlanData;
        }
        if (this.ui_id === 'purchase-addon-plan') {
            if (!this.isOverhead) {
                this.finalpaybal = this.purchaseaddonprice + this.selectedPlan.taxamount;
            }
            if (this.isOverhead) {
                this.finalpaybal = this.purchaseaddonprice;
            }
        }
    }

    // getByPlanAmmount() {
    //   let planId = this.changePlanForm.get("planId").value;
    //   let apiPayload: any = {
    //     "custId": this.custId, "planId": planId,
    //   }
    //   this.changePlanService.getAmountService(apiPayload).subscribe(res => {
    //     this.setPriceWithFinalPayBill(res.TotalAmount);
    //   });
    // }
    // setPriceWithFinalPayBill(txtAmount) {
    //   let planId = this.changePlanForm.get("planId").value;
    //   this.planList.forEach(item => {
    //     if (item.planId == planId) {
    //       let textAmount = +txtAmount.toFixed(2);
    //       this.selectedPlan.price = textAmount + item.offerprice;
    //     }
    //   });
    // }
    getStartDateEndDate() {
        let newdate = new Date();
        var currentDate = this.datepipe.transform(newdate, 'yyyy-MM-dd');
        this.changePlanForm.get('recordPaymentDTO').get('payment_date').setValue(currentDate);
    }
    onChangePurchaseType(purchaseTypeItem) {

        if (purchaseTypeItem) {

            this.purchaseType = purchaseTypeItem.value;
            if (purchaseTypeItem.value === 'Renew') {
                this.changePlanForm.get('advRenewal').setValidators([Validators.required]);
            }
            if (purchaseTypeItem.value !== 'Renew') {
                this.changePlanForm.get('advRenewal').clearValidators();
                this.changePlanForm.get('advRenewal').updateValueAndValidity();

                this.showAdvRenewal = false;
                this.onChangeAdvRenewal({ value: false });
                if (purchaseTypeItem.value == 'Volume Booster' &&
                    this.ui_id == 'purchase-addon-plan') {
                    this.getPurchaseAddonePlan();
                }
            } else {

                this.showAdvRenewal = true;
                this.onChangeAdvRenewal({ text: 'Yes', value: true });
            }
        } else {
            this.onChangeAdvRenewal({ text: 'No', value: false });
            this.showAdvRenewal = false;
        }
    }

    getPurchaseAddonePlan() {
        let serviceType: number = 1;
        let purchaseAddonPlan: any = {
            'partnerId': this.partnerId,
            'planGroup': 'Volume Booster',
            'serviceType': serviceType,
        };

        this.changePlanService.getPurchaseAddonePlanService(purchaseAddonPlan).subscribe(result => {
            this.planList = [];
            result.dataList.forEach(item => {
                let itemData: any = {};
                itemData.planName = item.displayName;
                itemData.partnerCommType = item.partnerCommType
                itemData.planId = item.id;
                itemData.dataQuota = item.quota;
                itemData.validity = item.validity;
                itemData.timeQuota = item.quotatime;
                itemData.quotaType = item.quotatype;
                itemData.offerprice = item.offerprice;
                itemData.quotaUnit = item.quotaUnit;
                itemData.quotaunittime = item.quotaunittime;
                itemData.endDate = item.endDate;
                itemData.minPrice = item.minPrice;
                itemData.maxPrice = item.maxPrice;
                itemData.minPriceTax = item.minPriceTax
                itemData.maxPriceTax = item.maxPriceTax
                itemData.taxamount = item.taxamount;
                itemData.taxId = item.taxId;
                itemData.quotaFormate = item.quotaFormate;//New Key:
                this.planList.push(itemData);
            });
        });
    }

    get f() {
        return this.changePlanForm.controls;
    }

    onAddonSelect(event) {
        this.purchaseEndDate = new Date(event.value);
        this.addonStartDate = new Date(event.value);
        this.purchaseEndDate.setDate(this.purchaseEndDate.getDate() + this.selectedPlan.validity);
        this.endDate = this.datePipe.transform(this.purchaseEndDate, 'dd-MM-yyyy');
    }

    onClickUpdatePaymentForm() {
        if ((this.changePlanForm.get('recordPaymentDTO').valid === false && this.ui_id !== 'purchase-addon-plan') || (this.changePlanForm.get('recordPaymentDTO').valid === false && this.ui_id === 'purchase-addon-plan')) {
            this.tosterservice.error('Please Fill All payment Details');
        }
        if (this.isOverhead === true) {
            ////console.log('aaa =>', this.changePlanForm);
            //this.onOverridePrice(this.changePlanForm.get('sellPrice').value);
            if (this.changePlanForm.invalid) {
                return;
            }
            // if (Number(this.changePlanForm.get('sellPrice').value) && this.selectedPlan.minPrice !== null && this.selectedPlan.maxPrice !== null) {
            //     if (Number(this.changePlanForm.get('sellPrice').value) < this.selectedPlan.minPrice && Number(this.changePlanForm.get('sellPrice').value) > this.selectedPlan.maxPrice) {
            //         this.tosterservice.error('Override price Sholud be between ' + this.selectedPlan.minPrice + ' - ' + this.selectedPlan.maxPrice);
            //         return;
            //     }
            //     if (Number(this.changePlanForm.get('sellPrice').value) > this.selectedPlan.maxPrice) {
            //         this.tosterservice.error('Override price Sholud be less than ' + this.selectedPlan.maxPrice);
            //         return;
            //     }
            //     if (Number(this.changePlanForm.get('sellPrice').value) < this.selectedPlan.minPrice) {
            //         this.tosterservice.error('Override price Sholud be greater than ' + this.selectedPlan.minPrice);
            //         return;
            //     }
            // }
            this.changePlanForm.get('sellPrice').setValidators(Validators.required);
            this.changePlanForm.get('sellPrice').updateValueAndValidity();
            if (this.changePlanForm.get('sellPrice').valid === false) {
                this.tosterservice.error('Please Enter Override Price!');
                return;
            }


        } else {
            this.changePlanForm.get('sellPrice').clearValidators;
            this.changePlanForm.get('sellPrice').updateValueAndValidity();
            this.changePlanForm.get('isPriceOverride').setValue(false);
            this.changePlanForm.get('isPriceOverride').updateValueAndValidity();

        }

        if (this.changePlanForm.valid === true) {
            for (const prop in this.changePlanForm.controls) {
                this.changePlanForm.value[prop] = this.changePlanForm.controls[prop].value;
              }
            let formAllValue = Object.assign({}, this.changePlanForm.value);
            if (formAllValue.isPaymentReceived && formAllValue.isPaymentReceived == true) {
                if (formAllValue.recordPaymentDTO.payment_mode !== 'tds' && formAllValue.recordPaymentDTO.tdsDeducted === true) {
                    if (formAllValue.recordPaymentDTO.tdsAmount.length === 0) {
                        this.tdsAmountValid = false;
                        this.submitted = true;
                        this.changePlanForm.markAllAsTouched();
                        return false;
                    } else {
                        this.tdsAmountValid = true;
                    }
                }

                if (formAllValue.recordPaymentDTO.amount.length === 0) {
                    this.amountValid = false;
                } else {
                    this.amountValid = true;
                }

                if (formAllValue.recordPaymentDTO.payment_date.length === 0) {
                    this.paymentDateValid = false;
                } else {
                    this.paymentDateValid = true;
                }

                if (formAllValue.recordPaymentDTO.remarks.length === 0) {
                    this.remarksValid = false;
                } else {
                    this.remarksValid = true;
                }

                if (formAllValue.recordPaymentDTO.referenceNo.length === 0) {
                    this.referenceNoValid = false;
                } else {
                    this.referenceNoValid = true;
                }

                let payment_mode_id = formAllValue.recordPaymentDTO.payment_mode;

                if (_.isEmpty(payment_mode_id) === false) {
                    this.paymentModeValid = true;
                    let selected_payment_mode = _.find(this.paymentModeList, { value: payment_mode_id });
                    let payment_mode = selected_payment_mode.value;
                    if (payment_mode === 'cheque') {
                        if (formAllValue.recordPaymentDTO.chequeNo.length === 0) {
                            this.chequeNoValid = false;
                        } else {
                            this.chequeNoValid = true;
                        }
                        if (formAllValue.recordPaymentDTO.bankName.length === 0) {
                            this.bankNameValid = false;
                        } else {
                            this.bankNameValid = true;
                        }
                        if (formAllValue.recordPaymentDTO.chequeDate.length === 0) {
                            this.chequeDateValid = false;
                        } else {
                            this.chequeDateValid = true;
                        }
                        if (formAllValue.recordPaymentDTO.branch.length === 0) {
                            this.branchValid = false;
                        } else {
                            this.branchValid = true;
                        }
                        if (formAllValue.recordPaymentDTO.chequeNo.length === 0 ||
                            formAllValue.recordPaymentDTO.bankName.length === 0 ||
                            formAllValue.recordPaymentDTO.chequeDate.length === 0 ||
                            formAllValue.recordPaymentDTO.branch.length === 0 ||
                            formAllValue.recordPaymentDTO.remarks.length === 0
                        ) {
                            this.submitted = true;
                            this.changePlanForm.markAllAsTouched();
                            return false;
                        } else {
                            this.askQuestion();
                        }
                    } else {
                        if (formAllValue.recordPaymentDTO.remarks.length === 0 ||
                            formAllValue.recordPaymentDTO.amount.length === 0 ||
                            formAllValue.recordPaymentDTO.payment_date.length === 0) {
                            this.submitted = true;
                            this.changePlanForm.markAllAsTouched();
                            return false;
                        } else {
                            this.askQuestion();
                        }
                    }
                } else {
                    this.paymentModeValid = false;
                    this.changePlanForm.markAllAsTouched();
                    return false;
                }
            } else {
                this.askQuestion();
            }
        } else {

            let addonStartDate = this.changePlanForm.get('addonStartDate').value;
            if (this.ui_id != 'purchase-addon-plan') {
                this.changePlanForm.get('addonStartDate').clearValidators();
                this.changePlanForm.get('addonStartDate').updateValueAndValidity();
                //this.onClickUpdatePaymentForm();
                this.submitted = true;
                this.changePlanForm.markAllAsTouched();
            } else {
                this.submitted = true;
                this.changePlanForm.markAllAsTouched();
            }

        }
    }

    askQuestion() {
        let self = this;
        this.submitted = false;
        let title: string;
        if (this.ui_id == 'purchase-addon-plan') {
            title = 'Purchase add on plan';
        } else {
            title = 'Change Plan';
        }
        alertify.confirm(title, 'Are You Sure You want to ' + title + ' ?', function () {
            self.insertChangePlanOnDb();
        },
            function () {

            });
    }

    insertChangePlanOnDb() {
        let apiInputData = Object.assign({}, this.changePlanForm.value);
        if (apiInputData.skip == true) {
            apiInputData.skip = false;
        } else {
            apiInputData.skip = true;
        }

        let recordPaymentDTO: any = null;
        let advRenewal = apiInputData.advRenewal;
        if (advRenewal == null && (this.purchaseType === 'Upgrade' || this.purchaseType === 'Volume Booster' || this.purchaseType === 'New')) {
            advRenewal = false;
        }
        ////console.log('data: ', apiInputData);
        let formatedData: any = {};
        if (this.ui_id != 'purchase-addon-plan') {
            formatedData = {
                'custId': this.custId,
                'isRefund': apiInputData.skip,
                'isAdvRenewal': advRenewal,
                'planId': apiInputData.planId,
                'purchaseType': apiInputData.planPurchaseType,
                'remarks': apiInputData.remarks,
                'sellPrice': apiInputData.sellPrice,
                'isIncludeTax': apiInputData.isIncludeTax ? apiInputData.isIncludeTax : false,
                'isPriceOverride': apiInputData.isPriceOverride,
                'refundOption': apiInputData.refundOption ? apiInputData.refundOption : null,
                //'collectorId':apiInputData.collectorId?apiInputData.collectorId: null,
            };
        } else {
            apiInputData.addonStartDate = this.datePipe.transform(this.addonStartDate, 'yyyy-MM-dd');
            formatedData = {
                'custId': this.custId,
                'isRefund': apiInputData.skip,
                'isAdvRenewal': advRenewal,
                'planId': apiInputData.planId,
                'purchaseType': apiInputData.planPurchaseType,
                'remarks': apiInputData.remarks,
                'addonStartDate': apiInputData.addonStartDate,
                'isPaymentReceived': apiInputData.isPaymentReceived,
                'sellPrice': apiInputData.sellPrice,
                'isIncludeTax': apiInputData.isIncludeTax ? apiInputData.isIncludeTax : false,
                'isPriceOverride': apiInputData.isPriceOverride,
                //'collectorId':apiInputData.collectorId?apiInputData.collectorId: null,
            };
        }
        if (apiInputData.advRenewal === '') {
            formatedData.advRenewal = false;
        }
        if (apiInputData.isPaymentReceived && apiInputData.isPaymentReceived == 'yes') {
            formatedData.isPaymentReceived = true;

            recordPaymentDTO = {};
            recordPaymentDTO.custId = this.custId;
            recordPaymentDTO.paymentAmount = apiInputData.recordPaymentDTO.amount;
            recordPaymentDTO.paymentDate = this.datePipe.transform(apiInputData.recordPaymentDTO.payment_date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
            recordPaymentDTO.referenceNo = apiInputData.recordPaymentDTO.referenceNo;
            recordPaymentDTO.paymentMode = apiInputData.recordPaymentDTO.payment_mode;
            // recordPaymentDTO.isBalanceUsed = apiInputData.isBalanceUsed;
            // if(recordPaymentDTO.isBalanceUsed == true){
            //     recordPaymentDTO.balanceAmount = apiInputData.balanceAmount;
            // }

            let selectedPaymentModeData = _.find(this.paymentModeList, { value: apiInputData.recordPaymentDTO.payment_mode });
            //if paymentMode  cheque
            if (selectedPaymentModeData.value === 'cheque') {
                recordPaymentDTO.bankName = apiInputData.recordPaymentDTO.bankName;
                recordPaymentDTO.branch = apiInputData.recordPaymentDTO.branch;
                recordPaymentDTO.chequeDate = apiInputData.recordPaymentDTO.chequeDate;
                recordPaymentDTO.chequeNo = apiInputData.recordPaymentDTO.chequeNo;
            }

            // if paymentMode  TDS
            if (selectedPaymentModeData.value === 'TDS') {
                recordPaymentDTO.credit_doc_id = apiInputData.recordPaymentDTO.credit_doc_id;
            }

            recordPaymentDTO.isTdsDeducted = apiInputData.recordPaymentDTO.tdsDeducted;
            // if isTdsDeducted true
            if (recordPaymentDTO.isTdsDeducted === true) {
                recordPaymentDTO.tdsAmount = apiInputData.recordPaymentDTO.tdsAmount;
            }
            recordPaymentDTO.remarks = apiInputData.remarks;
        } else {
            formatedData.isPaymentReceived = false;
        }
        formatedData.remarks = apiInputData.remarks
        formatedData.recordPaymentDTO = recordPaymentDTO;
        this.changePlanService.changePlan(formatedData).subscribe(
            (result) => {
                if (result.responseCode !== 200 && result.responseMessage) {
                    this.tosterservice.error(result.responseMessage);
                } else {
                    if (this.ui_id == 'purchase-addon-plan') {
                        this.tosterservice.success('purchase add on plan Save Successfully.');
                        this.customModalService.close('purchase-addon-plan', result);
                        this.changePlanForm.reset();
                        this.checkeligiblity = null;
                        this.selectedPlan = null;
                        this.endDate = null;

                    } else {
                        this.tosterservice.success('Change Plan Save Successfully.');
                        this.customModalService.close('change-plan', result);
                        this.changePlanForm.reset();
                    }
                }
            }, err => {
                this.tosterservice.error(err.error.status + ' ' + err.error.ERROR);
            }
        );
    }

    checkIncludingTax(event: any) {

        let sellPrice = this.changePlanForm.get('sellPrice').value;

        if (event === 'true') {
            this.changePlanForm.get('isIncludeTax').setValue(true);
            this.isIncludingTax = true;
            //this.changePlanForm.get('sellPrice').setValue(null)
            // this.insertPackagesForm.get('taxId').setValidators([Validators.required]);
        } else {
            this.changePlanForm.get('isIncludeTax').setValue(false);
            this.isIncludingTax = false;
            // // this.changePlanForm.get('sellPrice').setValue(null)
            // this.insertPackagesForm.get('taxId').clearValidators();
            // this.insertPackagesForm.get('taxId').updateValueAndValidity();
        }

        if (sellPrice !== '') {
            this.onOverridePrice(sellPrice);
        }
    }

    checkOverHead(event: any) {

        if (event === 'true') {
            ////console.log('over head::', event);
            this.changePlanForm.get('isPriceOverride').setValue(true);
            this.isOverhead = true;
            this.purchaseaddonprice = 0;
            this.changePlanForm.get('sellPrice').setValidators([Validators.required]);
        } else {
            this.purchaseaddonprice = this.oldPurchasePrice;
            this.changePlanForm.get('isPriceOverride').setValue(false);
            this.isOverhead = false;
            this.taxDetailsByPlanData = this.selectedPlan.taxAmount;
            this.planPrice = this.selectedPlan.price;
            this.changePlanForm.get('sellPrice').clearValidators();
            this.changePlanForm.get('sellPrice').updateValueAndValidity();
        }
    }

    onOverridePrice(amount) {
        this.changePlanForm.get('isBalanceUsed').setValue(false);
        if (amount === '') {
            this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
            this.tosterservice.error('Please Enter Override Price!');
            return;
        }

        this.changePlanForm.get('sellPrice').setErrors(null);

        let event = Number(amount);
        if (this.isOverhead === true) {
            this.taxDetailsByPlanData = 0;
            this.planPrice = 0;


            // if(event < this.selectedPlan.minPrice && event > this.selectedPlan.maxPrice){
            //   this.tosterservice.error("Override price Sholud be between " + this.selectedPlan.minPrice + " - " + this.selectedPlan.maxPrice )
            //   event = 0;
            //   return;
            // }


            // if(event > this.selectedPlan.maxPrice){
            //   this.tosterservice.error("Override price Sholud be less than " + this.selectedPlan.maxPrice  )
            //   event = 0;
            //   return;
            // }


            // if(event < this.selectedPlan.minPrice){
            //   this.tosterservice.error("Override price Sholud be greater than " + this.selectedPlan.minPrice  )
            //   event = 0;
            //   return;
            // }

            if (this.isIncludingTax === false) {

                if (this.ui_id === 'purchase-addon-plan') {
                    let obj = {
                        amount: Number(event),
                        taxId: this.selectedPlan.taxId
                    };
                    this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
                        event = Number(event) + res.TaxAmount;

                        if (event || this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                            if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {

                                this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                                this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                                event = 0;
                                return;
                            } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {

                                this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                                this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                                event = 0;
                                return;
                            } else {
                                this.purchaseaddonprice = Number(event);
                            }
                        } else {
                            this.purchaseaddonprice = Number(event);

                        }

                    });
                }
                if (this.ui_id === 'change-plan') {
                    let obj = {
                        amount: Number(event),
                        taxId: this.selectedPlan.planTaxId
                    };
                    this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
                        event = Number(event) + res.TaxAmount;
                        if (this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                            if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
                                this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                                event = 0;
                                this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                                ////console.log('aa=>', this.changePlanForm);
                                return;
                            } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
                                this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                                event = 0;
                                this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                                return;
                            } else {
                                this.planPrice = Number(event);
                            }
                        } else {
                            this.planPrice = Number(event);
                        }

                    });
                }
            } else {
                if (this.ui_id === 'change-plan') {
                    if (event || this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                        if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
                            this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                            event = 0;
                            this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                            return;
                        } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
                            this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                            event = 0;
                            this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                            return;
                        } else {
                            this.planPrice = Number(event);
                        }
                    } else {
                        this.planPrice = Number(event);
                    }
                    // this.planPrice = Number(event)
                }
                if (this.ui_id === 'purchase-addon-plan') {

                    if (this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                        if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
                            this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                            event = 0;
                            this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                            return;
                        } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
                            this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                            event = 0;
                            this.changePlanForm.get('sellPrice').setErrors({ 'incorrect': true });
                            return;
                        } else {
                            this.purchaseaddonprice = Number(event);
                        }
                    } else {
                        this.purchaseaddonprice = Number(event);
                    }
                }
            }

        }
    }

    closeModal(id: string) {
        this.customModalService.close(id, { close_from: 'close_button' });
        this.changePlanForm.reset();
        this.selectedPlan = null;
        this.endDate = null;
        this.req_message = null;
    }

    keypress(event: any) {
        const pattern = /[0-9\.]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    ClearField() {
        this.changePlanForm.reset();
        this.selectedPlan = [];
        this.displayActivateDate = null;
        this.displayExpiryDate = null;
        this.taxDetailsByPlanData = null;
        this.planPrice = null;
        this.changePlanForm.get('isPaymentReceived').setValue('no');

    }

    ClearFieldPlanType(){
        this.changePlanForm.get('planId').reset();
        this.selectedPlan = [];
        this.displayActivateDate = null;
        this.displayExpiryDate = null;
        this.taxDetailsByPlanData = null;
        this.planPrice = null;
        this.changePlanForm.get('isPaymentReceived').setValue('no');
    }

    openVasPopUpPlan(content)
    {
      this.ngbRef = this.ngbModalService.open(content, { size: 'xs'});
    }

    closeVasModal() {
        this.ngbModalService.dismissAll();
    }
}
