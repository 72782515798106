import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CircuitServiceService } from '../service/circuit-service.service';

@Component({
  selector: 'app-update-circuitid',
  templateUrl: './update-circuitid.component.html',
  styleUrls: ['./update-circuitid.component.scss']
})
export class UpdateCircuitidComponent implements OnInit {
  @Input() eventId: number;
  @Output() onCancel = new EventEmitter<any>();
  circuitId: number;
  submitted: boolean = false;
  form: FormGroup = new FormGroup({
    circuitId: new FormControl(null,Validators.required)
  })

  constructor(private ngbModal: NgbModal,
    private toster: ToastrService,
    private circuitService: CircuitServiceService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getCircuitDetail();
  }

  get f(){
    return this.form.controls;
  }

  getCircuitDetail(){
    this.circuitService.getCircuitDetail(this.eventId).subscribe((res)=>{
      if(res.responseCode == 200){
        this.circuitId =  res.data.circuitid;
      }else{
        this.toster.error(res.responseMessage);
      }
    })
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

  onSubmit(){
    this.submitted = false;
    if(this.form.valid){
      let data = {
        circuit_id:null,
        id: this.eventId
      }
      data.circuit_id = this.form.get('circuitId').value;
      this.circuitService.updateCircuitId(data).subscribe((res)=> {
        if(res.responseCode == 200){
          this.toster.success('Circuit Id Updated Successfully.')
          this.onCancel.emit(null);
          this.closeModal();
        }else{
          this.toster.error(res.responseMessage);
        }
      })
    }else{
      this.toster.error('Please fill all the details!');
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
