import { ChangeDetectorRef, Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import _ from 'underscore';
import * as alertify from 'alertifyjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NetworkDevicesService } from '../services/network-devices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { SlotService } from '../services/add-slot.service';
import { ServiceAreaService } from '../../service-area/service/service-area.service';
import { Slot } from '../model/slot.model';
import { NetworkDevices } from '../model/network-detail.model';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { ResponseCode } from '../../../../core/constant/response-code';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../data-sharing-service.service';

export interface DialogPassData{
id:number;
}

@Component({
  selector: 'app-add-network-devices',
  templateUrl: './add-network-devices.component.html',
  styleUrls: ['./add-network-devices.component.scss']
})
export class AddNetworkDevicesComponent implements OnInit {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('popUpWrapper', { static: false }) popUpWrapperRef: TemplateRef<any>;
  @ViewChild('portWrapper', { static: false }) portWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  saveBtn:boolean=false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  slotDataForGrid: Slot[];
  slotGridSettings: any = {};
  public sub: any = null;
  public network_devices_id: any = null;
  networkDevicesData: NetworkDevices;
  show_update: any = false;
  selectForUpdate: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  commonYN :any[] = [];
  selectedSnmpEnable:string
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  req_message = "This field is required.";
  popUpOn: boolean = false;
  isDisabled:boolean=false;

  hardwareSpec:any[]=[];
  selectedOltPortDetailsList: Slot;

  insertNetworkDevicesForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    devicetype: new FormControl(null, [Validators.required]),
    hardwareSpecId: new FormControl(null),
    snmpEnabled: new FormControl(null),
    modelNo: new FormControl(null),
    serialNumber: new FormControl(null),
    ipAddress:new FormControl(null),
    communityString:new FormControl(null),
    serviceareaname: new FormControl(null, [Validators.required]),
    status: new FormControl(null, [Validators.required]),
    servicearea: new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null)
    })
  });

  areaList: any[] = [
    { id: 1, name: "Paldi" },
    { id: 2, name: "Satellite" },
    { id: 3, name: "Bopal" }
  ];

  optionValue;
  serviceAreaValue;
  serviceAreaId: any;

  serviceAreaList: any[] = [
    { id: '', name: '' }
  ]

  deviceType: any[] = [
    { value: "OLT" },
    { value: "ONU" }
  ]

  constructor(
    private networkDevicesService: NetworkDevicesService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private slotService: SlotService,
    private serviceAreaService: ServiceAreaService,
    private tosterservice: ToastrService,
    loginService: LoginService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddNetworkDevicesComponent>,
    private sharingdataService:DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {
    this.loginService = loginService;
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      { colKey: 'index', default: 'ID', rowKey: 'id', width: '5%', isFilterAllow: false, isNumber: true },
      { colKey: 'name', default: 'Slot Name', rowKey: 'name', width: '30%', isNumber: false, isFilterAllow: false, enableEdit: true, enableDelete: true, enableSort: true },
      {
        colKey: 'no_of_port',
        default: 'No. Of Port',
        rowKey: 'no_of_port',
        width: '30%',
        customControls: [this.portWrapperRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '20%',
        customControls: [this.statusWrapperRef],
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'port',
        default: 'Port',
        rowKey: 'port',
        width: '5%',
        customControls: [this.popUpWrapperRef],
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '5%',
        isStickyEnd: true
      }
    ];

    this.slotGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Slot Detail ?',
      deleteMsgBoxDetail: '"Slot Details Name :${name} " '
    };
  }

  ngOnInit() {
    this.getHardwareSpec();
    this.setGridSetting();
    this.networkDevicesService.getSNMPFlag().subscribe((res) => {
      this.commonYN = res.dataList;
    });
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.isDisabled=false;
      this.network_devices_id = id;
      this.show_update = true;
      this.getNetworkDevicesDataById();
     
      this.optionValue='OLT';
      this.getSlotData(); 
    }

    // if(this.data && this.data.id){
    //   this.isDisabled=true;
    //   this.network_devices_id = this.data.id;
    //   this.show_update = true;
    //   this.getNetworkDevicesDataById();
    //   this.optionValue='OLT';
    //   //this.getSlotData(); 
    //   this.insertNetworkDevicesForm.disable();
    // }

    
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.network_devices_id = params['id'];
    //       this.show_update = true;
    //       this.getNetworkDevicesDataById();
    //     }
    //   });
    if (this.show_update === true) {
      this.getSlotData();
    }
    this.getServiceArea();
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }
  onInfoClick(el) {
    this.popUpOn = true;
    this.slotService.getSlotDataById(el).subscribe(
      selectedSlot => {
        this.selectedOltPortDetailsList = selectedSlot.data.oltPortDetailsList;
      }
    );
  }
  SnmpEnable(event){
    this.selectedSnmpEnable = event.value;
  if(this.insertNetworkDevicesForm.controls.snmpEnabled.value === 'Yes'){
    this.insertNetworkDevicesForm.get('hardwareSpecId').setValidators([Validators.required]);
    this.insertNetworkDevicesForm.get('hardwareSpecId').updateValueAndValidity();
    this.insertNetworkDevicesForm.get('ipAddress').setValidators([Validators.required,Validators.pattern('^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$')]);
    this.insertNetworkDevicesForm.get('ipAddress').updateValueAndValidity();
    this.insertNetworkDevicesForm.get('communitystring').setValidators([Validators.required]);
    this.insertNetworkDevicesForm.updateValueAndValidity();
  }
  else{
    this.insertNetworkDevicesForm.get('hardwareSpecId').clearValidators();
    this.insertNetworkDevicesForm.get('hardwareSpecId').updateValueAndValidity();
    this.insertNetworkDevicesForm.get('ipAddress').clearValidators();
    this.insertNetworkDevicesForm.get('ipAddress').updateValueAndValidity();
    this.insertNetworkDevicesForm.get('hardwareSpecId').reset();
    this.insertNetworkDevicesForm.get('ipAddress').reset();
    this.insertNetworkDevicesForm.get('communitystring').clearValidators();
    this.insertNetworkDevicesForm.get('communitystring').reset();
    this.insertNetworkDevicesForm.updateValueAndValidity();
  }
  }
  OLTdevice(){
    if(this.optionValue ==='OLT'){

      this.insertNetworkDevicesForm.get('snmpEnabled').setValidators([Validators.required]);
      this.insertNetworkDevicesForm.get('snmpEnabled').updateValueAndValidity();
    }else{
      this.insertNetworkDevicesForm.get('hardwareSpecId').clearValidators();
      this.insertNetworkDevicesForm.get('hardwareSpecId').updateValueAndValidity();
      this.insertNetworkDevicesForm.get('ipAddress').clearValidators();
      this.insertNetworkDevicesForm.get('ipAddress').updateValueAndValidity();
      this.insertNetworkDevicesForm.get('snmpEnabled').clearValidators();
      this.insertNetworkDevicesForm.get('snmpEnabled').updateValueAndValidity();
      this.insertNetworkDevicesForm.get('hardwareSpecId').reset();
      this.insertNetworkDevicesForm.get('ipAddress').reset();
      this.insertNetworkDevicesForm.get('snmpEnabled').reset();
    }
  }

  getServiceArea() {
    this.serviceAreaService.http.get('serviceArea/byStatus/active').subscribe(
      (result: any) => {
        this.serviceAreaList = result.dataList;
      }
    )
  }

  getNetworkByName() {
    let network_name = this.insertNetworkDevicesForm.get('name').value;
    ////console.log(this.insertNetworkDevicesForm.get('name').value);
    this.networkDevicesService.getNetworkDeviceByName(network_name).subscribe(
      name => {
        this.show_update = true;
        let data = _.find(name.dataList, { name: network_name });
        let id = data.id;
        this.router.navigate(['/network-management/network/add_network'], { queryParams: { id: id } });
      }
    )
  }

  getNetworkById(id: number) {

    this.networkDevicesService.getNetworkDevicesData(id).subscribe(
      result => {
        this.show_update = true;
        let data = result.data;
        let id = data.id;
        this.router.navigate(['/network-management/network/add_network', id]);
      }
    )
  }
  getHardwareSpec(){
    this.networkDevicesService.getHardwareSpec().subscribe(res=>{
      this.hardwareSpec=res.dataList;
    })
  }

  validateForm() {
    if (!this.insertNetworkDevicesForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertNetworkDevicesForm.controls).forEach(field => {
        const control = this.insertNetworkDevicesForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.network_devices_id === null) {
        this.insertNetworkDevicesOnDb();
      } else {
        this.updateNetworkDevicesOnDb();
      }
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }

    this.dataSource.connect().subscribe((data) => {
      this.filteredData = data;
    });
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey === 'port') {
        col.customControls = [this.popUpWrapperRef];
      }
      if (col.colKey === 'no_of_port') {
        col.customControls = [this.portWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    //this.setUpdateValueInForm(event);
    this.setUpdateValueInFormSlotDetails(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    if (event.oltPortDetailsList.length > 0) {
      this.tosterservice.error("This Slot is Already in used..");
    }
    else if (event.oltPortDetailsList.length === 0) {
      let self = this;
      if (event) {
        self.delete(event);
      }

    }

  }

  getSlotData() {
    let network_devices_id = this.network_devices_id;
    this.slotService.getSlotDataByNetworkDeviceId(network_devices_id).subscribe(
      result => {
        this.slotDataForGrid = result.dataList;
        this.refreshDataSource(this.slotDataForGrid);
      }
    );
  }

  getNetworkDevicesDataById() {
    let network_devices_id = this.network_devices_id;
    this.networkDevicesService.getNetworkDevicesData(network_devices_id).subscribe(
      result => {
        if(result.data.snmpEnabled === 'Yes'){
          
          this.insertNetworkDevicesForm.get('hardwareSpecId').setValidators([Validators.required]);
          this.insertNetworkDevicesForm.get('hardwareSpecId').updateValueAndValidity();
          this.insertNetworkDevicesForm.get('ipAddress').setValidators([Validators.required,Validators.pattern('^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$')]);
          this.insertNetworkDevicesForm.get('ipAddress').updateValueAndValidity();
        }
        else{
          this.insertNetworkDevicesForm.get('hardwareSpecId').clearValidators();
          this.insertNetworkDevicesForm.get('hardwareSpecId').updateValueAndValidity();
          this.insertNetworkDevicesForm.get('ipAddress').clearValidators();
          this.insertNetworkDevicesForm.get('ipAddress').updateValueAndValidity();
          this.insertNetworkDevicesForm.get('hardwareSpecId').reset();
          this.insertNetworkDevicesForm.get('ipAddress').reset();
        }
        this.networkDevicesData = result.data;
        this.serviceAreaId = result.data.servicearea.id;
        
        this.setUpdateValueInForm(this.networkDevicesData);
      }
    )
  }

  getServiceAreaId(el) {
    if(el){
    this.serviceAreaService.http.get('serviceArea/byStatus/active').subscribe(
      (result: any) => {
        let data = _.find(result.dataList, { name: this.serviceAreaValue });
        this.serviceAreaId = data.id;
      }
    );
    }
  }

  setUpdateValueInForm(network_devices_data) {
    this.show_update = true;
    this.selectForUpdate = network_devices_data;
    // this.serviceAreaId = network_devices_data.servicearea.id;
    this.insertNetworkDevicesForm.patchValue(network_devices_data);
    this.insertNetworkDevicesForm.get('serviceareaname').setValue(network_devices_data.servicearea.name);
    this.serviceAreaId = network_devices_data.servicearea.id;
  }

  transforInsertData(formData: any) {
    let formatedInputData: any = {};
    return formatedInputData;
  }

  insertNetworkDevicesOnDb() {
    ////console.log(this.insertNetworkDevicesForm);
    if (this.insertNetworkDevicesForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertNetworkDevicesForm.value);
      let formatedInputData: any = this.insertNetworkDevicesForm.value;
      formatedInputData.servicearea.name = this.insertNetworkDevicesForm.get('serviceareaname').value
      formatedInputData.servicearea.id = this.serviceAreaId;
      formatedInputData.serviceareaname = undefined;
      ////console.log("id", this.serviceAreaId)
      this.networkDevicesService.insertNetworkDevices(formatedInputData).subscribe(result => {
        if (result.responseCode === ResponseCode.OK) {
          this.saveBtn =false;
          this.cdRef.detectChanges();
          
          this.tosterservice.success('Network Devices has been created successfully');
          if(this.insertNetworkDevicesForm.controls.snmpEnabled.value === 'Yes' || this.optionValue === 'ONU'){
            
            this.onBack();
          }
          else{
            this.getNetworkById(result.data.id);
          }
        
        }
        else {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        }
      },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateNetworkDevicesOnDb() {
    if (this.insertNetworkDevicesForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertNetworkDevicesForm.value);
      let formatedInputData: any = this.insertNetworkDevicesForm.value;
      formatedInputData.id = this.network_devices_id;
      formatedInputData.servicearea.name = this.insertNetworkDevicesForm.get('serviceareaname').value
      formatedInputData.servicearea.id = this.serviceAreaId;
      formatedInputData.serviceareaname = undefined;
      this.networkDevicesService.updateNetworkDevices(formatedInputData).subscribe(result => {
        if (result.responseCode === ResponseCode.OK) {
          this.saveBtn =false;
          this.cdRef.detectChanges();
          this.show_update = false;
          this.tosterservice.success('Network Devices Update successfully');
          this.onBack();
        }
        else {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        }

      },
        err => {
          this.saveBtn =false;
          alertify.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  delete(selected_slot_data) {
    let self = this;
    this.slotService.getSlotDataById(selected_slot_data.id).subscribe((result) => {
      this.slotService.deleteSlot(result.data).subscribe((data: any) => {
        if (data.responseCode === ResponseCode.OK) {
          this.tosterservice.success('Slot Details is deleted successfully');
          this.show_update = true;
          this.slotDataForGrid.forEach(function (item, index) {
            ////console.log("item::", item.id);
            self.getSlotData();
            if (item.id === data.data.slot_id) {
              self.slotDataForGrid.splice(index, 1);
            }
          });
        }
        else {
          this.tosterservice.error(data.responseMessage);
        }

      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    })
  }

  onBack() {
    //this.router.navigate(['/network-management/network']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/network-management/network']);
    }
  }

  onAddSlotClick(rowData) {
    this.router.navigate(['/network-management/network/add_network/add_slot', this.insertNetworkDevicesForm.value.id]);
  }

  setUpdateValueInFormSlotDetails(rowData) {
    this.router.navigate(['/network-management/network/add_network/add_slot', rowData.networkId, rowData.id]);
  }
  closeDialog(){
   this.dialogRef.close();
  }
}
