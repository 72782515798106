import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import { PricebookService } from '../price-book.service';
import _ from 'underscore';
import { PackagesService } from '../../../plan_management/packages/packages.service';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchService } from '../../../../services/search.service';
import * as alertify from 'alertifyjs';
import { constants } from 'os';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort } from '@angular/material';
import { PriceBookDetailsComponent } from '../price-book-details/price-book-details.component';
import { ThrowStmt } from '@angular/compiler';
import {ChangePlanService} from '../../../masters/subscriber-details/change-plan/change-plan.service';
@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(PriceBookDetailsComponent, { static: false }) pricebookREf: PriceBookDetailsComponent
  @Output() priceBookPlan: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() istokenAmount;
  @Input() planListAll;
  @Input() pricebookType;
  @Input() planListSelected;

  @Input() planListallocated = [{
    offerprice: '',
    partnerofficeprice: '',
    // revsharen : '',
    // registration : '',
    // renewal : '',
    postpaidPlan: {
      name: '',
      id: '',
      tokenPrice: '',
      maxPrice: '',
      minPrice: ''
    }
  }];
  @Input() taxCalculationOnPartnerPrice: boolean;
  page: any = 0;
  perPage: any = 5;
  planList: any = [
    {
      packages_id: '',
      name: '',
      offerprice: '',
      tokenPrice: '',
      maxPrice: '',
      minPrice: ''
    }
  ];
  selectedPlan = new Array<any>();

  viewPlanList: any = [];
  changeRecordList: any = [];
  custFlag: any[] = [];
  selectForUpdate;
  customselectForUpdateArray: any = [];
  insertAddPlanForm;
  isFirstChecked: boolean = false;
  search: string = '';
  isCustAllow: boolean = false;
  allowCustomer: boolean = false;
  listOfPlan: any = [];
  EditParnerFlag: boolean = false;
  submitted = false;
  saveUsername: boolean;
  editAddData: boolean = false;
  formGroup: FormGroup;
  old_plan_list_form_value: any = [];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  partnerPrice: any;
  rowPartnerValue:any;
  saveDisabled:boolean=false;
  newPlanList: any[]=[];
  constructor(private customModalService: CustomModalService,
    private packagesService: PackagesService,
    private fb: FormBuilder,
    private tosterservice: ToastrService,
    private modalservice: NgbModal,
    private ChangePlanService : ChangePlanService
  ) {

    // this.selection.changed.subscribe((item) => {
    //   if(item.added[0].partnerPrice === null && this.istokenAmount === false){
    //   this.tosterservice.error("Please fill partner price");

    //   return 
    //   }
    // });
  }

  ngOnInit() {
    // var flag:boolean=false;
    this.setValidation();
    

    if (this.planListAll) {
      let list: Array<any> = Object.assign([], this.planListAll)
      if (this.planListSelected && this.planListSelected.length === 0) {
        this.planListAll.forEach((res) => {
          this.setFormCheckBox(res);
        });
        this.planListAll.forEach(element => {
          element.partnerofficeprice = element.offerprice
        });
        this.dataSource = new MatTableDataSource(this.planListAll);
        this.newPlanList = this.planListAll;
      }
      else {
        this.planListSelected.forEach(res => {
          let index = list.findIndex(element => res.packages_id === element.packages_id);
          list.splice(index, 1);
        });
        this.dataSource = new MatTableDataSource(list);
        this.newPlanList = list;
        this.planListAll.forEach((res) => {
          this.setFormCheckBox(res);
        });

      }
      this.planListAll.forEach(element => {
        //element.partnertaxamountwith = Number(element.offerprice) + Number(element.taxamount);
        element.partnertaxamountwith = Number(element.offerprice) ;

      });
      // this.dataSource = new MatTableDataSource(this.planListAll);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort; 
    }

    this.insertAddPlanForm = this.fb.group({
      plan: this.fb.array([])
    });
    
    for (let data of this.planListallocated) {
      let formateData = {
        offerprice: data.offerprice,
        partnerofficeprice: data.partnerofficeprice,
        tokenPrice: data.postpaidPlan.tokenPrice,
        minPrice: data.postpaidPlan.minPrice,
        maxPrice: data.postpaidPlan.maxPrice,
        // revsharen : data.revsharen,
        // registration : data.registration,
        // renewal : data.renewal,
        name: data.postpaidPlan.name,
        packages_id: data.postpaidPlan.id
      }
      this.listOfPlan.push(formateData);
    }

    // this.insertAddPlanForm.valueChanges.subscribe(selectedValue => {
    //   let formValue = this.insertAddPlanForm.get('plan').value;
    //   let newFormatedData = [];
    //   formValue.forEach(element => {
    //     let isItemDataExist = _.find(this.old_plan_list_form_value,{packages_id:element.packages_id});
    //     if (_.isEmpty(isItemDataExist) == false) {
    //         newFormatedData.push(element);
    //     } else {
    //         newFormatedData.push(element);
    //     }
    //   });
    //
    //   if (this.old_plan_list_form_value && this.old_plan_list_form_value.length) {
    //     this.old_plan_list_form_value.forEach(element => {
    //         let isExist = _.find(newFormatedData,{packages_id:element.packages_id});
    //         if (isExist == false) {
    //           newFormatedData.push(isExist);
    //         }
    //     });
    //     this.old_plan_list_form_value = newFormatedData;
    //   } else {
    //     this.old_plan_list_form_value = newFormatedData;
    //   }
    //
    // });

  }

  setValidation() {
    this.formGroup = this.fb.group({

    });
  }

  setFormCheckBox(res) {
    this.formGroup.addControl('row_' + res.packages_id, this.fb.control(false));
    this.formGroup.addControl('row_allowCustomer_' + res.packages_id, this.fb.control(false));
    this.formGroup.addControl('row_partnerPrice_' + res.packages_id, this.fb.control(Number(res.offerprice)));
    //+ Number(res.taxamount) removed
    this.disabledComponent(res);
  }
  disabledComponent(res) {
    this.formGroup.get('row_partnerPrice_' + res.packages_id).disable();
    this.formGroup.addControl('row_partnerPrice_' + res.packages_id, this.fb.control(false));
  }
  // isTest(event){
  //   let rowId = 'row_' + event.packages_id;
  //   this.formGroup.get(rowId).setValue(false);
  //   // document.getElementById(rowId).setAttribute('checked', 'false');
  //   // ////console.log('document.getElementById(rowId).setAttribute =>',document.getElementById(rowId))
  // }

  ischeck(event,eventname,i) {
    let rowId = 'row_' + event.packages_id;
    let rowpartner = 'row_partnerPrice_' + event.packages_id;
    let partnerofficeprice = this.formGroup.get(rowpartner).value;
    let check = this.formGroup.get(rowId).value

    this.rowPartnerValue='row_partnerPrice_' + event.packages_id;
    if (check) {
      this.formGroup.get('row_partnerPrice_' + event.packages_id).enable();
    }
    if (partnerofficeprice === '' && this.istokenAmount === false && check) {
      let rowId = 'row_' + event.packages_id;
      // this.formGroup.get(rowId).setValue(false); /** remove Vijay */
      this.formGroup.get(rowpartner).setValidators(Validators.required);
      this.formGroup.get(rowpartner).markAsTouched();
      this.formGroup.get(rowpartner).updateValueAndValidity();
      ////console.log('aaa =>', this.formGroup.get(rowpartner));
      this.tosterservice.error("Partner Price can't be empty!");

      // ////console.log('packeges =[>', rowId);
      // let el : any = document.getElementById(rowId);
      // el && el.firstChild && el.firstChild.firstElementChild.setAttribute('checked', 'false');
      return;
    }
    else if (partnerofficeprice > (event.offerprice+event.taxamount)) {
      
      let rowId = 'row_' + event.packages_id;
      this.formGroup.get(rowId).setValue(false);
      this.formGroup.get(rowpartner).setValidators(Validators.required);
      this.formGroup.get(rowpartner).markAsTouched();
      this.formGroup.get(rowpartner).updateValueAndValidity();
      this.tosterservice.error("Partner Price can't be greater than Offer Price");
      return;
    }
    else {
      if (this.pricebookType === "Token Amount" && event.tokenPrice === null) {
        let rowId = 'row_' + event.packages_id;
        this.formGroup.get(rowId).setValue(false);
        this.tosterservice.error(event.displayName + " doesn't have token Price");
        return;
      }
      let rowId = 'row_' + event.packages_id;
      this.formGroup.get(rowId).setValue(check);

      if (check) {
        this.selectedPlan.push(event);
      }
      else {
        let index = this.selectedPlan.findIndex(res => res.packages_id === event.packages_id);
        this.selectedPlan.splice(index, 1);
        this.formGroup.get('row_allowCustomer_' + event.packages_id).setValue(false);
        //this.formGroup.get(rowpartner).setValue('');
        this.formGroup.get(rowpartner).setValue(event.offerprice);
        this.formGroup.get(rowpartner).disable();
        this.formGroup.get(rowpartner).clearValidators();
        this.formGroup.get(rowpartner).updateValueAndValidity();
        this.newPlanList[i].partnertaxamountwith = event.offerprice + event.taxamount;
        this.dataSource = new MatTableDataSource(this.newPlanList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
      }
    }
  }
  

  validateFeild(row) {
   let rowpartner = 'row_partnerPrice_' + row.packages_id;
    let partnerofficeprice = this.formGroup.get(rowpartner).value;
    if (partnerofficeprice === "") {
      this.formGroup.get(rowpartner).setValidators(Validators.required);
      this.formGroup.get(rowpartner).markAsTouched();
      this.formGroup.get(rowpartner).updateValueAndValidity();
      this.tosterservice.error("Partner Price can't be empty!");
      
    }else if (partnerofficeprice == 0) {
      // let rowpartner = 'row_partnerPrice_' + row.packages_id;
      this.formGroup.get(rowpartner).setValue(null);
      this.formGroup.get(rowpartner).setValidators(Validators.required);
      this.formGroup.get(rowpartner).markAsTouched();
      this.formGroup.get(rowpartner).updateValueAndValidity();
      this.tosterservice.error("Partner Price can't Zero");
      
    } else if (partnerofficeprice > (row.offerprice + row.taxamount )) {
      //+ row.taxamount removed
      // let rowpartner = 'row_partnerPrice_' + row.packages_id;
      this.formGroup.get(rowpartner).setValue(null);
      this.formGroup.get(rowpartner).setValidators(Validators.required);
      this.formGroup.get(rowpartner).markAsTouched();
      this.formGroup.get(rowpartner).updateValueAndValidity();
      this.tosterservice.error("Partner Price can't be greater than Offer Price with tax");
      
    } 
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  get displayedColumns() {
    if (this.istokenAmount) {
      return ["checkbox", "planname", "Allow Customer", "offerprice", "offerpricetax", "packages_id"];
    }
    else {
      return ["checkbox", "planname", "Allow Customer", "offerprice", "offerpricetax","partnerprice", "packages_id"];
      // "partnerpricetax","partner_commission",
    }
  }

  private addCheckboxes() {
    this.id.forEach(() => this.insertAddPlanForm.plan.get('packages_id').push(new FormControl(false)));
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  isCustomerAllow(event, row) {
    let rowId = 'row_' + row.packages_id;
    let check = this.formGroup.get(rowId).value;
    if (!check) {
      event.preventDefault();
      this.tosterservice.error("Please Select Plan");
    }
  }
  resetSearch() {
    this.search = '';
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  // registration(event, Item : FormGroup) {
  //   if(event.target.checked === true) {
  //     Item.get('registration').setValue('Yes');
  //   }
  // }
  // renewal(event, Item : FormGroup) {
  //   if(event.target.checked === true) {
  //     Item.get('renewal').setValue('Yes');
  //   }
  // }
  // revsharen(event, Item : FormGroup) {
  //   if(event.target.checked === true) {
  //     Item.get('revsharen').setValue('Yes');
  //   }
  // }

  charts_selected: any = [];
  checkbox_selected: any = [];
  id: any = [];
  selectedData(event) {
    // ////console.log("event::::",event)
    this.saveUsername = event.target.value;
    if (event.target.checked === true) {
      this.id.push(event.target.value);
      let findById = _.find(this.insertAddPlanForm.get('plan').value, { packages_id: event.target.value });
      this.checkbox_selected.push(findById);
    } else if (event.target.checked === false) {
      const index = this.id.indexOf(event.target.value);
      if (index > -1) {
        this.id.splice(index, 1);
      }
      if (this.old_plan_list_form_value && this.old_plan_list_form_value.length) {
        let oldPlanListIndex = _.findIndex(this.old_plan_list_form_value, { packages_id: event.target.value });
        this.old_plan_list_form_value.splice(oldPlanListIndex, 1);
      }
    }
  }

  onAddPlanItems(row?): FormGroup {
    return this.insertAddPlanForm.get('plan').push(
      new FormGroup({
        packages_id: new FormControl(row ? row.packages_id : ''),
        name: new FormControl(row ? row.name : ''),
        offerprice: new FormControl(row ? row.offerprice : ''),
        minPrice: new FormControl(row ? row.minPrice : ''),
        tokenPrice: new FormControl(row ? row.tokenPrice : ''),
        onAddPlanItems: new FormControl(row ? row.onAddPlanItems : false),
        maxPrice: new FormControl(row ? row.maxPrice : ''),
        partnerofficeprice: new FormControl({ value: row ? row.partnerofficeprice : '', disabled: this.istokenAmount }, [Validators.required]),
        // revsharen : new FormControl(row ? row.revsharen : true),
        // registration : new FormControl(row ? row.registration : true),
        // renewal : new FormControl(row ? row.renewal : true)
      })
    );
  }

  onChangePartnerPrice(el,i){
    
    if(this.taxCalculationOnPartnerPrice){
      let index = this.newPlanList.findIndex(res=>res.packages_id === el.packages_id)
      if(this.formGroup.get('row_' + el.packages_id).value ){
        if(this.f['row_partnerPrice_' + el.packages_id].value && el.taxId){
          let obj = {
            amount: Number(this.f['row_partnerPrice_' + el.packages_id].value),
            taxId: el.taxId
          };
          // this.ChangePlanService.getReverseTaxByAmount(obj).subscribe((res) => {
          //   if(this.newPlanList[index]){
          //     this.newPlanList[index].partnertaxamountwith = Number(res.ReverseTaxAmount)
          //   }
          // }); Removed
        }else if(el.taxId == null){
          this.newPlanList[index].partnertaxamountwith = Number(this.f['row_partnerPrice_' + el.packages_id].value)
        }
      }
      this.dataSource = new MatTableDataSource(this.newPlanList); 
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort; 
    }
  }

  get planItems() {
    return (this.insertAddPlanForm.get('plan') as FormArray).controls;
  }

  setUpdateValueInForm(addPlan) {
    this.insertAddPlanForm.get('plan').clear();
    this.selectForUpdate = addPlan;
    for (let data of addPlan) {
      this.onAddPlanItems(data);
    }
    this.insertAddPlanForm.patchValue(addPlan);
  }

  manageDataStatusOnPageChange() {
    let formValue = this.insertAddPlanForm.get('plan').value;
    let packages_id = _.pluck(formValue, "packages_id");
    let selectedData = [];
    packages_id.forEach(element => {
      if (this.id.indexOf(element.toString()) !== -1) {
        let data = _.find(formValue, { packages_id: element });
        selectedData.push(data);
      }
    });
    if (this.old_plan_list_form_value && this.old_plan_list_form_value.length && selectedData && selectedData.length) {
      selectedData.forEach(element => {
        let findByIndex = _.findIndex(this.old_plan_list_form_value, { packages_id: element.packages_id });
        if (findByIndex == -1) {
          this.old_plan_list_form_value.push(element);
        } else {
          this.old_plan_list_form_value[findByIndex] = element;
        }
      });
    } else if (selectedData && selectedData.length) {
      this.old_plan_list_form_value.push(...selectedData);
    }
  }

  goToPage(page_number: number): void {
    this.page = page_number;
    this.manageDataStatusOnPageChange();
    this.getGridSettingAndEmitAndFetchDataFromApi();
  }

  onNext(): void {
    this.page++;
    this.manageDataStatusOnPageChange();
    this.getGridSettingAndEmitAndFetchDataFromApi();
  }

  onPrev(): void {
    this.page--;
    this.manageDataStatusOnPageChange();
    this.getGridSettingAndEmitAndFetchDataFromApi();
  }

  getGridSettingAndEmitAndFetchDataFromApi() {
    let pageMinusOne = this.page - 1;
    if (pageMinusOne * this.perPage) {
      this.changeRecordList.push(this.insertAddPlanForm.get('plan').value);
    }
    let skip = pageMinusOne * this.perPage;
    let pageData = this.planList.slice(skip, skip + this.perPage);
    //this.viewPlanList =  this.planList.slice(skip,skip+this.perPage);
    if (this.old_plan_list_form_value && this.old_plan_list_form_value.length) {
      let newFormatedData = [];
      pageData.forEach(element => {
        let isItemDataExist = _.find(this.old_plan_list_form_value, { packages_id: element.packages_id });
        if (_.isEmpty(isItemDataExist) == false) {
          newFormatedData.push(isItemDataExist);
        } else {
          newFormatedData.push(element);
        }
      });
      this.viewPlanList = newFormatedData;
    } else {
      this.viewPlanList = pageData;
    }
    this.setUpdateValueInForm(this.viewPlanList);
  }

  setCheckboxIfSelected(data) {
    if (this.id && this.id.length) {
      if (this.id.indexOf(data.packages_id.toString()) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addPlan() {
    this.manageDataStatusOnPageChange();
    if (this.id && this.id.length) {
      //let formValus = this.insertAddPlanForm.value;
      let formValus = this.old_plan_list_form_value;
      let isAnyInValid = false;
      let emitList = [];
      for (let data of this.id) {
        let currentItem = _.find(formValus, { packages_id: parseInt(data) });
        if (currentItem) {
          if (this.istokenAmount) {
            let findById = _.find(formValus, { packages_id: parseInt(data) });
            if (this.pricebookType === 'Token Amount') {
              if (findById.tokenPrice === null) {
                this.tosterservice.error(findById.name + " doesn't have Token Price");
                return
              }
              else {
                emitList.push(findById);
              }
            }
            else {
              emitList.push(findById);
            }


            if (emitList.length != 0) {
              // this.submitted = true;
              // this.priceBookPlan.emit(this.charts_selected);
              //this.charts_selected = [];
            } else {
              isAnyInValid = true;
              this.tosterservice.error("Please Select atlist one Plan");
            }
          }
          if (currentItem && currentItem.partnerofficeprice && currentItem.partnerofficeprice > 0) {
            if (currentItem.partnerofficeprice && currentItem.partnerofficeprice > currentItem.offerprice) {
              isAnyInValid = true;
              //alertify.error("Please Enter Valid Partner Offer Price.");
            } else {
              let findById = _.find(formValus, { packages_id: parseInt(data) });

              emitList.push(findById);

              if (emitList.length != 0) {
                // this.submitted = true;
                // this.priceBookPlan.emit(this.charts_selected);
                //this.charts_selected = [];
              } else {
                isAnyInValid = true;
                this.tosterservice.error("Please Select atlist one Plan");
              }
            }
          } else {
            isAnyInValid = true;
          }
        }
      }
      if (this.istokenAmount) {
        isAnyInValid = false;
      }
      if (isAnyInValid === false) {
        emitList.forEach(element => {
          this.priceBookPlan.emit([element]);
        });
        this.customModalService.close('addplan');
      } else {
        this.tosterservice.error("Please Enter Partner Offer Price In Selected Plans.");
      }
    } else {
      this.tosterservice.error("Please Select atlist one Plan");
    }
  }

  closeModal(id: string) {
    this.modalservice.dismissAll();
    // this.customModalService.close(id);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();

    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
      ////console.log();

    }
  }
  onCancel(data) {
    this.customModalService.close(data);
  }
  onSubmit() {
    
    if(this.formGroup.valid){
   
      this.selectedPlan.forEach(res => {
        res.postpaidPlan = {};
        res.postpaidPlan.taxamount = res.taxamount;
          let rowId = 'row_partnerPrice_' + res.packages_id;
          let partnerofficeprice = this.formGroup.get(rowId).value;
          res.partnerPrice = Number(partnerofficeprice);
          res.allowCustomer = this.formGroup.get('row_allowCustomer_' + res.packages_id).value;
         
          if(res.offerprice < res.partnerPrice)
          {
            let rowpartner = 'row_partnerPrice_' + res.packages_id;
            this.formGroup.get(rowpartner).setValue(res.offerprice); /**vijay */
            this.formGroup.get(rowpartner).setValidators(Validators.required);
            this.formGroup.get(rowpartner).markAsTouched();
            this.formGroup.get(rowpartner).updateValueAndValidity();
            //this.tosterservice.error("Partner Price can't be greater than Offer Price");
            return 
          }
          else{
             ////console.log("PriceBook-->",this.selectedPlan);
             let rowpartner = 'row_partnerPrice_' + res.packages_id;
             this.formGroup.get(rowpartner).clearValidators()
             this.formGroup.get(rowpartner).updateValueAndValidity();
           }

                    
           
        }
        );
       
       if(this.selectedPlan.length > 0){
        
        console.log("Test1-add plan selected",this.selectedPlan)
        this.priceBookPlan.emit(this.selectedPlan);
        //"partnerPrice" going value
        this.modalservice.dismissAll();
        this.tosterservice.success("Selected Plan Added Successfuly");
       }else{
        this.tosterservice.error("Please Select atlist one Plan");
       }
       
     }
     else{
     // this.saveDisabled=true;
     }
   
  }
  get f() {
    return this.formGroup.controls;
  }
}
   