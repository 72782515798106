import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../../../src/app/components/base/old-abstract-base-list-component';
import { TableKeyEnum } from '../../../../../src/app/core/enum/table-key.enum';
import { SearchService } from '../../../../../src/app/services/search.service';
import { NetworktreeService } from '../network-tree/service/networktree.service'

@Component({
  selector: 'app-view-frame',
  templateUrl: './view-frame.component.html',
  styleUrls: ['./view-frame.component.scss']
})
export class ViewFrameComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;

  frameListArray:any;
  networkframeGridSettings:any={};
  tableKeyEnum = TableKeyEnum;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  commonYN :any[] = [];
  selectedSnmpEnable:string
  dataSource = new MatTableDataSource([]);
  uuid:any;
  subscriptions: Subscription[] = [];
  constructor(private route: ActivatedRoute,
    private toastr:ToastrService,
    private networktreeService:NetworktreeService,
    private router: Router,
    private searchService: SearchService) {
    super(searchService);
   }
  
 
  ngOnInit() {
    this.setDefaultPageSize(this.tableKey.NETWORK_DEVICE_FRAME__LIST)
  let id = this.route.snapshot.paramMap.get('id');
   //let frame = this.route.snapshot.paramMap.get('id');
  
   if (id) {
     this.uuid=id;
     }else{
       this.uuid=null;
     }

  
  const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
    // if (obj && obj.filters) {
    this.applyFilter(obj);
    // }else
  });
  this.setGridSetting();
  this.initPaginationSort();
  
  this.subscriptions.push(filterSubscription);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'oltframe',
        default: 'frame / slot',
        rowKey: 'oltframe',
        customControls: [this.actionBtnRef],
        width: '20%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      // {
      //   colKey: 'oltPort',
      //   default: 'olt Port',
      //   rowKey: 'oltPort',
      //   width: '20%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // }
      
    ];

    this.networkframeGridSettings = {
      showFilter:true,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.NETWORK_DEVICE_FRAME__LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Slot Detail ?',
      deleteMsgBoxDetail: '"Slot Details Name :${name} " '
    };
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      // if (col.colKey == 'actionCol') {
      //    col.customControls = [this.actionBtnRef];
      // }
      if (col.colKey == 'oltframe') {
        col.customControls = [this.actionBtnRef];
     }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getNetworkDeviceFrameData(gridSetting: any) {
    let fetchSettingData = this.networkframeGridSettings.serverSideSortPagination ? gridSetting :null;
     //let params = this.getGridSettingInfo(fetchSettingData);
    let paramsValue: any = {paginationRequestDTO:{},uuid:this.uuid};
    //let apiInpuData = this.transFormPaginationData();
    let params = this.getGridSettingInfo(fetchSettingData);
    
    paramsValue.paginationRequestDTO.page = params.page;
    paramsValue.paginationRequestDTO.pageSize = params.pageSize;
    paramsValue.paginationRequestDTO.sortBy = params.sortBy;
    paramsValue.paginationRequestDTO.sortOrder = params.sortOrder;
    paramsValue.uuid = this.uuid;
    paramsValue.paginationRequestDTO.filters=params.filters;
    console.log("final object",paramsValue);
           
    this.networktreeService.getframeList(paramsValue).subscribe(result => {

      if(result.responseCode===200){
        
        if(result.dataList){
            if(result.dataList){
              this.frameListArray = result.dataList;
              this.refreshDataSource(this.frameListArray);
              this.totalRecords = result.totalRecords;
              this.networkframeGridSettings.pageTotalLength = this.totalRecords;
              this.networkframeGridSettings.perPage=result.pageRecords;
            }else{
              this.toastr.error("Data Not Found")
            }
        }else{
          this.toastr.error("Data Not Found");
        }
        
      }
      else{
       this.toastr.error("Data Not Found");
      }
      
    });
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getNetworkDeviceFrameData(config);
  }

  applyFilter(filterObj) {
  let filteredDataForGrid = JSON.parse(JSON.stringify(this.frameListArray));
     if (filterObj && filterObj.filters) {
       filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.frameListArray);
     }
    this.refreshDataSource(filteredDataForGrid);
  }

  setPagination(event){
  
   let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getNetworkDeviceFrameData(config);
   }

  // onPageChange(event) {
  //   let paginationData = new PaginationData();
  //   paginationData = event;
  //   this.onPaginationChange(paginationData);
  // }

   onEdit(rowData){
    //console.log("RowData",rowData);
   //this.router.navigate(['/network-management/slot',this.uuid,rowData.oltframe]); /**old */
    this.router.navigate(['/network-management/port',this.uuid,rowData.oltframe]);
    
   }
  
  
  // transFormPaginationData() {
  //   let paramsValue: any = {paginationRequestDTO:{},deviceid:""};
  //   if (this.isfetchOnPaginaionData === true) {
  //     let fetchSettingData = this.valueAddedNeoGridSettings.serverSideSortPagination ? this.gridSettingData : null;
  //     let params = this.getGridSettingInfo(fetchSettingData);
  //     paramsValue.paginationRequestDTO.page = params.page;
  //     paramsValue.paginationRequestDTO.pageSize = params.pageSize;
  //     paramsValue.deviceid = this.oltDevice;
  //   } else if (this.isfetchOnPaginaionData === false) {
  //     let fetchSettingData = this.valueAddedNeoGridSettings.serverSideSortPagination ? this.gridSettingData : null;
  //     let params = this.getGridSettingInfo(fetchSettingData);
  //     params.page = 1;
  //     params.pageSize = 10;
  //     paramsValue.paginationRequestDTO.page = params.page;
  //     paramsValue.paginationRequestDTO.pageSize = params.pageSize;
  //     paramsValue.deviceid = this.oltDevice;
  //   }
  //   return paramsValue;
  // }

  onBackTo(){
 // network-management/network-tree
 // this.router.navigate
    this.router.navigate(['/network-management/network-tree']);
    // this.router.navigate(['/network-management/network/add_network/add_slot', rowData.networkId, rowData.id]);
  }
  // onEditFrame(){
  // }

  onEditSlot(rowData){
  this.router.navigate(['/network-management/port',this.uuid,rowData.oltframe,rowData.oltSlot]);
  }

}
