import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';

import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { LoginService } from '../../../login/login.service';
import { SearchService } from '../../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { ValueAddedeServiceModel } from '../model/ValueAddedeService';
import { ValueAddedeServiceService } from '../service/value-addede.service';
import { FormGroup } from '@angular/forms';
import { MenuItems } from '../../../../layout/menuItems';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { IBaseService } from '../../../../services/ibase-service';
import * as alertify from 'alertifyjs';
import { VasProviderServiceService } from '../../../configuration/vas-provider/service/vas-provider.service';
import { SearchData } from '../../../../core/modal/search-data';

@Component({
  selector: 'app-value-added-service',
  templateUrl: './value-added-service.component.html',
  styleUrls: ['./value-added-service.component.scss']
})
export class ValueAddedServiceComponent extends AbstractBaseListComponent<ValueAddedeServiceModel> implements OnInit, OnDestroy {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('valueAdded', { static: false }) valueaddedReff: TemplateRef<any>;
  @ViewChild('offerPriceWrapper', { static: false }) offerPriceWrapperRef: TemplateRef<any>;
  @ViewChild('offerPriceIncWrapper', { static: false }) offerPriceIncWrapperRef: TemplateRef<any>;
  @ViewChild('partnerPriceWrapper', { static: false }) partnerPriceWrapperRef: TemplateRef<any>;
  @ViewChild('taxAmtpriceWrapper', { static: false }) taxAmtWrapperRef: TemplateRef<any>;
  @ViewChild('partnerPriceIncWrapper', { static: false }) partnerPriceIncWrapperRef: TemplateRef<any>;
  @ViewChild('partnerTaxAmtpriceWrapper', { static: false }) partnerTaxAmtWrapperRef: TemplateRef<any>;
  @ViewChild('validity', { static: false }) validityWrapperRef: TemplateRef<any>;
  dataGrid: ValueAddedeServiceModel[] = [];
  // CaseResolutionReason: any = { content: [] };
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  searchSettings = [];
  neoTableSetting:any={};
  public entity: ValueAddedeServiceModel = new ValueAddedeServiceModel();
  public entity_id: any = null;
  form: FormGroup;
  menuItems: any =[];
  menuItemService:any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  vasTypeList: [];
  filterObj: SearchData[];
  obj = {
    filterColumn: 'type',
    filterValue:  null,
    filterOperator: 'equalto',
    filterCondition: 'and',
    filterDataType: 'string'
  }

  constructor(
    private entityService: ValueAddedeServiceService,
    private searchService: SearchService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    public loginService: LoginService,
    menuConstants: MenuItems,
    private cdRef: ChangeDetectorRef,
    private genericDataSharingService: ValueAddedeServiceService,
    private vasProviderService: VasProviderServiceService
    ) {
    super(toastrService,searchService);
  }
  // NeoGridSettings: any = {};
  // AclClassConstants = AclClassConstants;
  // AclConstants = AclConstants;
  // ValueAddedeServiceGrid: any;
  // tableKeyEnum = TableKeyEnum;
  // allColumns: any;
  // dataSource = new MatTableDataSource([]);
  // searchSettings: any[] = [];
  // subscriptions: Subscription[] = [];
  // filteredValueAddedeServiceGrid: any = [];
  // typeList: any[] = [];
  // public loginService: LoginService;
  // constructor(
  //   private valueAddedeservice: ValueAddedeService,
  //   public searchService: SearchService,
  //   loginService: LoginService,
  //   private router: Router,
  //   private toaster: ToastrService
  // ) {
  //   super(toaster,searchService);
  //   // super(searchService);
  //   // this.loginService = loginService;
  // }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.VALUE_ADDED_SERVICE);
    this.init();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
    this.getVASTypeList();
  }

  getVASTypeList(){
    this.vasProviderService.getTypeList().subscribe(res =>{
      this.vasTypeList = res.dataList
    })
  }

  onChangeVASType(event){
    if(event &&  event.value!=null){
      this.paginationData.pageNumber = 0
      this.obj.filterValue = event.value;
      //this.filterObj.push(this.obj)
      //this.searchService.setServerFilterObj(this.obj)
       this.onFilterChange(this.obj);  
    }
    else{
        this.onFilterChange(null);
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.valueaddedReff];
      }
      if (col.colKey == 'offerPrice') {
        col.customControls = [this.offerPriceWrapperRef];
      }if (col.colKey == 'offerpriceintax') {
        col.customControls = [this.offerPriceIncWrapperRef];
      }
      if (col.colKey == 'partnerPrice') {
        col.customControls = [this.partnerPriceWrapperRef];
      }
      if (col.colKey == 'taxAmount') {
        col.customControls = [this.taxAmtWrapperRef];
      }
      if (col.colKey == 'partnerPriceTaxAmount') {
        col.customControls = [this.partnerTaxAmtWrapperRef];
      }
      if (col.colKey == 'partnerPriceInTax') {
        col.customControls = [this.partnerPriceIncWrapperRef];
      }
      if (col.colKey == 'validity') {
        col.customControls = [this.validityWrapperRef];
      }
    });
  }
  getService(): IBaseService<ValueAddedeServiceModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
     
       {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'type',
        default: 'Type',
        rowKey: 'type',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'validity',
        default: 'Validity',
        rowKey: 'validity',
        width: '14%',
        isNumber: false,
        customControls: [this.validityWrapperRef],
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'offerPrice',
        default: 'Offer Price (Without Tax)',
        rowKey: 'offerPrice',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'taxAmount',
        default: 'Tax Amount',
        rowKey: 'taxAmount',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'offerpriceintax',
        default: 'Offer Price (With Tax)',
        rowKey: 'offerpriceintax',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'partnerPrice',
        default: 'Partner Price (Without Tax)',
        rowKey: 'partnerPrice',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'partnerPriceTaxAmount',
        default: 'Partner Tax Amount',
        rowKey: 'partnerPriceTaxAmount',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'partnerPriceInTax',
        default: 'Partner Price (With Tax)',
        rowKey: 'partnerPriceInTax',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'Last Modified By',
        rowKey: 'lastModifiedByName',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false, //change
        isFilterAllow: false,//change
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '3%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return this.allColumns ? this.allColumns : [];
  }
  getTableSetting() {
    let VasNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.VALUE_ADDED_SERVICE,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Service Area ?',
      deleteMsgBoxDetail: 'Service Area Name :${name} ',
    };
    return VasNeoGridSettings;
  }

  getTableSearchSetting() {
    
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Type',
        value_field: 'type',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
    ];
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
    //this.onFilterChange(this.obj)
  }
  
  onEditClick(event) {
    // let index = event.RowData;
    console.log('event',event);
    let index = event;
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
    let self = this;
    alertify.confirm("Are you sure to delete this VAS","VAS:"+event.name,
    function(){
      if (event) {
         self.delete(event);
       }},
    function(){
    })
  }

  setList(list: Array<ValueAddedeServiceModel>) {
    this.dataGrid = list;
    this.dataGrid.forEach(res => {
      res.offerpriceintax = res.offerPrice + res.taxAmount;
      res.partnerPriceInTax =  res.partnerPrice + res.partnerTaxPrice
    });
    console.log("DATA",this.dataGrid)
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }

  onAddClick() {
    this.router.navigate(['plan-management/value-added-service/add']);
  }
  setUpdateValueInForm(rowData) {
    this.router.navigate(['plan-management/value-added-service/add',rowData.id]);
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  // getListWithParams() {
  //   if (Array.isArray(this.searchData)) {
  //     if (this.searchData.length > 0) {
  //       if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
  //         this.paginationData.pageNumber = 1;
  //       }
  //     }
  //   }
  //   let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
  //   this.getListValueAddedeService(config);
  // }
  // refreshDataSource(data) {
  //   if (data) {
  //     this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  //   }
  // }
  // applyFilter(filterObj) {
  //   let filteredDataForGrid = JSON.parse(JSON.stringify(this.ValueAddedeServiceGrid));
  //   if (filterObj && filterObj.filters) {
  //     // Client Side Filter
  //     filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.ValueAddedeServiceGrid);
  //   }
  //   this.refreshDataSource(filteredDataForGrid);
  // }
  // getListValueAddedeService(gridSetting: any) {

  //   let fetchSettingData = this.NeoGridSettings.serverSideSortPagination ? gridSetting : null;
  //   let params = this.getGridSettingInfo(fetchSettingData);
  //   this.valueAddedeservice.getDataValueAddedService(params).subscribe((result) => {
  //     this.ValueAddedeServiceGrid = result.dataList;
  //     ////console.log(this.ValueAddedeServiceGrid)
  //     this.filteredValueAddedeServiceGrid = JSON.parse(JSON.stringify(this.ValueAddedeServiceGrid));
  //     this.refreshDataSource(this.ValueAddedeServiceGrid);
  //     this.totalRecords = result.totalRecords;
  //     this.NeoGridSettings.pageTotalLength = this.totalRecords;
  //   });
  // }
  // onAddPriceBookClick() {
  //   this.router.navigate(['plan-management/value-added-service/add']);
  // }
  // onEdit(event) {
  //   this.setUpdateValueInForm(event);
  // }
  // setUpdateValueInForm(rowData) {
  //   this.router.navigate(['/plan-management/value-added-service/add', rowData.id]);
  // }
  // onDelete(event) {
  //   this.commonDeleteMsgBox(this.NeoGridSettings, event, this.delete.bind(this));
  // }
  // delete(Objevent) {
  //   let self = this;
  //   if (Objevent.deleteClicked) {
  //     let data = Objevent.event;
  //     // let apiInputData = this.transFormApiInputData(data);
  //     this.valueAddedeservice.deleteValueAddedService(data).subscribe(res => {
  //       if (res.responseCode != 200) {
  //         this.toastrService.error(res.responseMessage);
  //       } else {
  //         this.toastrService.success("Data has been Successfully deleted");
  //         self.getListWithParams();
  //       }
  //     })
  //   }
  // }
  // transFormApiInputData(data) {
  //   // ////console.log(data);
  //   let formatedData: any = {}
  //   formatedData.id = data.id;
  //   formatedData.name = data.name;
  //   formatedData.type = data.type;
  //   formatedData.value = data.value;
  //   formatedData.satus = data.status;
  //   return formatedData;
  // }

  // setGridSetting() {
  //   let self = this;
  //   this.allColumns = [
  //     {
  //       colKey: 'index',
  //       default: 'Id',
  //       rowKey: 'id',
  //       width: '3%',
  //       isFilterAllow: false,
  //       isNumber: true,
  //     },
  //     {
  //       colKey: 'name',
  //       default: 'Name',
  //       rowKey: 'name',
  //       width: '14%',
  //       isNumber: false,
  //       isFilterAllow: true,
  //       enableEdit: true,
  //       enableDelete: true,
  //       enableSort: true,
  //     },
  //     {
  //       colKey: 'type',
  //       default: 'Type',
  //       rowKey: 'type',
  //       width: '14%',
  //       isNumber: false,
  //       isFilterAllow: true,
  //       enableEdit: true,
  //       enableDelete: true,
  //       enableSort: true,
  //     },
  //     {
  //       colKey: 'value',
  //       default: 'Value',
  //       rowKey: 'value',
  //       width: '14%',
  //       isNumber: false,
  //       isFilterAllow: true,
  //       enableEdit: true,
  //       enableDelete: true,
  //       enableSort: true,
  //     },
  //     {
  //       colKey: 'status',
  //       default: 'Status',
  //       rowKey: 'status',
  //       width: '14%',
  //       isNumber: false,
  //       isFilterAllow: true,
  //       enableEdit: true,
  //       enableDelete: true,
  //       enableSort: true,
  //     },
  //     {
  //       colKey: 'actionCol',
  //       default: 'Action',
  //       rowKey: 'id',
  //       //enableEdit: true,
  //       //enableDelete: true,
  //       customControls: [this.actionBtnRef],
  //       enableEdit: false,
  //       enableDelete: false,
  //       isFilterAllow: false,
  //       enableSort: false,
  //       isExpandable: false,
  //       isNumber: false,
  //       width: '1%',
  //       isStickyEnd: true,
  //     },
  //   ];
  //   this.allColumns.forEach(element => {
  //     element.isFilterAllow = false;
  //   });
  //   this.searchSettings = [
  //     {
  //       display_field: 'Status',
  //       value_field: 'status',
  //       control_setting: [
  //         {
  //           control_type: SearchControlTypeEnum.SELECT,
  //           display_style: '',
  //         },
  //       ],
  //       data_type: SearchDataTypeEnum.BOOLEAN,
  //       select_items: [
  //         {
  //           display_field: 'All',
  //           value_field: ['Active', 'InActive'],
  //         },
  //         {
  //           display_field: 'Active',
  //           value_field: 'Active',
  //         },
  //         {
  //           display_field: 'InActive',
  //           value_field: 'InActive',
  //         },
  //       ],
  //     },
  //     {
  //       display_field: 'Name',
  //       value_field: 'name',
  //       control_setting: [
  //         {
  //           control_type: SearchControlTypeEnum.TEXT,
  //           display_style: '',
  //         },
  //       ],
  //       data_type: SearchDataTypeEnum.STRING,
  //     },
  //   ];

  //   this.NeoGridSettings = {
  //     showAdvanceSearch: false,
  //     showFooter: false,
  //     showPagination: true,
  //     tableId: this.tableKeyEnum.VALUE_ADDED_SERVICE,
  //     perPage: this.paginationData.rowsPerPage,
  //     pageNumber:this.paginationData.pageNumber - 1,
  //     pageSizeOptions: [5, 10, 15, 20, 50, 100],
  //     serverSideSortPagination: true,
  //     serverSideFilter: true,
  //     pageTotalLength: 0,
  //     sort: true,
  //     defaultSortCol: '',
  //     defaultSortDirection: 'asc',
  //     columns: this.allColumns,
  //     deleteMsgBoxTitle: 'Are you sure to delete this Service ?',
  //     deleteMsgBoxDetail: 'Service Name :${name} ',
  //   };
  //   if (this.NeoGridSettings.serverSideSortPagination && this.NeoGridSettings.perPage) {
  //     this.setPaginationPerPage(this.NeoGridSettings.perPage);
  //   }
  // }
  
  // setPagination(event) {
  //   let config = { page: event, order: this.orderingData, search: this.searchData };
  //   this.getListValueAddedeService(config);
  // }
}

