import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ChargeService {
  constructor(private http: HttpClient) {

  }

  transformChargeData(data) {
    ////console.log("data",data)
    let formatedData = [];
    data.chargelist.forEach(function(item:any){
      let itemData:any = {};
      itemData.charge_id = item.id;
      itemData.charge_name = item.name;
      itemData.charge_desc = item.desc;
      itemData.price = item.price;
      itemData.actual_price = item.actualprice;
      itemData.tax_id = item.taxid;
      itemData.discount_id = item.discountid;
      itemData.dbr = item.dbr;
      itemData.charge_type = item.chargetype;
      itemData.category = item.chargecategorytext;
      itemData.actual_price = item.actualprice;
      itemData.tax = item.tax;
      itemData.isIncludeTax=item.isIncludeTax;
      itemData.taxamount = item.taxamount;
      itemData.updatedate = item.updatedate;  
      formatedData.push(itemData);
    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getChargeDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'charge/list';
    return this.http.post<any>(post_url, params,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformChargeData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getChargeById(charge_id): Observable<any> {
    const get_url = BASE_API_URL + 'charge/'+ charge_id;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers })
  }

  getChargeData(): Observable<any> {
    const get_url = BASE_API_URL + 'charge';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformChargeData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertCharge(charge_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'charge';
    return this.http.post<any>(post_url, charge_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateCharge(charge_data: any,charge_id:any): Observable<any> {
    const update_url = BASE_API_URL +"charge/"+charge_id;
    return this.http.put<any>(update_url, charge_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteCharge(charge_id: any): Observable<any> {
    const delete_url = BASE_API_URL+"charge/" + charge_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
