import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { FuturePlansListService } from './future-plans.service';
import _ from 'underscore';
import { CustomModalService } from '../../../../../components/custom-modal';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../../login/login.service';
import { AclConstants } from '../../../../aclOperationConstants';
import { AclClassConstants } from '../../../../aclClassConstants';
import { CancelPlanService } from '../cancel-plan/cancel-plan.service';
import { MatAccordion } from '@angular/material/expansion';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from '../../../../../../../src/app/components/loading/loading.service';
import * as alertify from 'alertifyjs';
@Component({
  selector: 'app-future-plans',
  templateUrl: './future-plans.component.html',
  styleUrls: ['./future-plans.component.scss']
})
export class FuturePlansComponent implements OnInit {

  @ViewChild('totalQuota', { static: false }) totalQuotaWrapperRef: TemplateRef<any>;
  @ViewChild('usedQuota', { static: false }) usedQuotaWrapperRef: TemplateRef<any>;
  @ViewChild('actionWrapper', { static: false }) actionWrapperRef: TemplateRef<any>;
  @ViewChild('validity', { static: false }) validityWrapperRef: TemplateRef<any>;
  @ViewChild('vasBtn', { static: false }) vasWrapperRef: TemplateRef<any>;
  @ViewChild('vasDetBtn', { static: false }) vasDetailWrapperRef: TemplateRef<any>;


  @Input() custId: any;
  planId: any;
  planType: any = "future";
  activateForm: FormGroup;
  refundOptionFlag: boolean = false;
  planMapId: string;

  custplanId: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  activatePlan: any[] = [];
  activatePlandataSource = new MatTableDataSource([]);
  activateFuturePlandataSource = new MatTableDataSource([]);
  dataSource = new MatTableDataSource([]);
  futurePlanNeoGridSettings = {};
  activatePlanNeoGridSettings = {};
  activatePlanFutureNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  openCancelPlanPopup: boolean;
  openactivePlanPopup: boolean;
  futurePlanTableData: any;
  refundOption: any
  futurerefundOption: any
  activerefundOption: any = null
  remark: string;
  submitted = false
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  btnsaveDisable:boolean=false;
  activePlanVASRefund:boolean = false;  
  futurePlanVASRefund:boolean = false;
  constructor(
    private futurePlansService: FuturePlansListService,
    private customModalService: CustomModalService,
    private cancelPlanService: CancelPlanService,
    private toster: ToastrService,
    loginservice: LoginService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public loadingService:LoadingService
  ) {
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.submitted = false;
    // this.getReversalType();
    this.setValidatin();
    this.setGridSetting();
    this.getFuturePlansData();
    this.cancelPlanService.getRefundOption().subscribe((res) => {
      this.refundOption = res.dataList;
    });
    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER, AclConstants.OPERATION_CUSTOMER_REFUND_OPTION, AclConstants.OPERATION_CUSTOMER_ALL)) {
      this.refundOptionFlag = true;
    }
    else {
      this.refundOptionFlag = false;
    }
  }
  setValidatin() {
    this.activateForm = this.fb.group({
      custId: [null],
      planId: [null],
      remark: [null, Validators.required],
      futurePlanRefundOption: [null],
      activePlanRefundOption: [null],
    })
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'volTotalQuota') {
        col.customControls = [this.totalQuotaWrapperRef];
      }
      if (col.colKey === 'volUsedQuota') {
        col.customControls = [this.usedQuotaWrapperRef];
      }
      if (col.colKey === 'action') {
        col.customControls = [this.actionWrapperRef];
      }
      if (col.colKey === 'validity') {
        col.customControls = [this.validityWrapperRef];
      }
      if (col.colKey === 'vas') {
        col.customControls = [this.vasWrapperRef];
      }
    });
    this.activatePlan.forEach((col)=>{
      if (col.colKey === 'vasDetail') {
        col.customControls = [this.vasDetailWrapperRef];
      }
    })
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      // {
      //   colKey: 'service',
      //   default: 'Service',
      //   rowKey: 'service',
      //   width: '10%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      {
        colKey: 'planName',
        default: 'Plan Name',
        rowKey: 'planName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'plangroup',
        default: 'Plan Type',
        rowKey: 'plangroup',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validityType',
        default: 'Validity Type ',
        rowKey: 'validityType',
        width: '10%',
        isNumber: false,

        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validity',
        default: 'Validity',
        rowKey: 'validity',
        width: '7%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.validityWrapperRef],
        customValue: (validity: any) => {
          if (validity != 0) {
            let findvalidity = _.find(this.futurePlanTableData, { validity: validity });
            ////console.log(findvalidity);
            return findvalidity.validity;
          } else {
            return 0;
          }


        },
      },
      {
        colKey: 'startDate',
        default: 'Activation Date',
        rowKey: 'startDate',
        width: '11%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'expiryDate',
        default: 'Expiry Date',
        rowKey: 'expiryDate',
        width: '12%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'vas',
        default: 'Associated VAS DETAILS',
        rowKey: 'vas',
        width: '10%',
        isNumber: false,
        customControls: [this.vasWrapperRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'volTotalQuota',
      //   default: 'Total Quota',
      //   rowKey: 'volTotalQuota',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   customControls: [this.totalQuotaWrapperRef],
      //   customValue:(volTotalQuota) => {
      //     if (volTotalQuota != null) {
      //       let findByTotalQouta = _.find(this.futurePlanTableData, {volTotalQuota:volTotalQuota});
      //       return findByTotalQouta.volTotalQuota + " " + findByTotalQouta.volQuotaUnit;
      //     } else {
      //       return "-"
      //     }
      //   },
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'volUsedQuota',
      //   default: 'USED QUOTA',
      //   rowKey: 'volUsedQuota',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   customControls: [this.usedQuotaWrapperRef],
      //   customValue:(volUsedQuota) => {
      //     if (volUsedQuota != null) {
      //       let findByUsedQouta = _.find(this.futurePlanTableData, {volUsedQuota:volUsedQuota});
      //       return findByUsedQouta.volUsedQuota + " " + findByUsedQouta.volQuotaUnit;
      //     } else {
      //       return "-"
      //     }
      //   },
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'qosPolicyName',
      //   default: 'QOS DETAILS',
      //   rowKey: 'qosPolicyName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'action',
        default: 'Action',
        rowKey: 'action',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }
    ];
    this.activatePlan = [
      {
        colKey: 'planName',
        default: 'Plan Name',
        rowKey: 'planName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usedDays',
        default: 'Used Days',
        rowKey: 'usedDays',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'fullAmount',
        default: 'Full Amount Without Tax',
        rowKey: 'fullAmount',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'fullAmofullAmountWithTaxunt',
        default: 'Full Amount With Tax',
        rowKey: 'fullAmountWithTax',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'proratedAmount',
        default: 'Prorated Amount With Tax',
        rowKey: 'proratedAmount',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'proratedAmountWithTax',
        default: 'Prorated Amount With Tax',
        rowKey: 'proratedAmountWithTax',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'vasDetail',
        default: 'Vas Detail',
        rowKey: 'vasDetail',
        width: '5%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.vasDetailWrapperRef],
      },
      // {
      //   colKey: 'vas',
      //   default: 'Associated VAS DETAILS',
      //   rowKey: 'vas',
      //   width: '10%',
      //   isNumber: false,
      //   customControls: [this.vasWrapperRef],
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },

    ];
    this.futurePlanNeoGridSettings = {
      showFilter: false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.FUTURE_PLAN_LIST,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      tableHeight: 300,
      headerSticky: true,
      verticalScroll: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
    this.activatePlanNeoGridSettings = {
      showFilter: false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.ACTIVATE_PLAN,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.activatePlan
    };
    this.activatePlanFutureNeoGridSettings = {
      showFilter: false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.ACTIVATE_PLAN_FUTURE,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.activatePlan
    };
  }


  getReversalType() {

    this.cancelPlanService.getRefundOption().subscribe((res) => {
      this.refundOption = res.dataList;

      


      if (!this.refundOptionFlag) {
        let selectedreversalOption = this.refundOption;

        this.activateForm.get('activePlanRefundOption').setValue(selectedreversalOption[0].value)
        
        // this.onChangeReversalType(selectedreversalOption[0]);  
        this.activateForm.controls.reversal_type.disable();
        // this.refundOption = selectedreversalOption[0].value;
      }

    });
  }

  openModal(id: string, planmapid: any, planId: any) {
    
    if (id === 'cancel-plan') {
      
      this.openCancelPlanPopup = true;
      setTimeout(() => {
        this.customModalService.open(id);
      }, 500);
      let findId = _.find(this.futurePlanTableData, { planmapid: planmapid, planId: planId });
      this.planId = findId.planmapid;
      this.custplanId = findId.planId;
    } else {
      this.openCancelPlanPopup = false;
      this.getFuturePlansData();
    }
    if (id === 'active-plan') {
      let findId = _.find(this.futurePlanTableData, { planmapid: planmapid, planId: planId });
      this.openactivePlanPopup = true;
      this.futurePlansService.activatePlanFuture(this.custId, findId.planmapid).subscribe((res) => {
        ////console.log("active pop up ==>",res);
        if(this.refundOptionFlag)
        {
          if (res.data.activePlanList && res.data.activePlanList.length > 0) {


            this.activateForm.get('activePlanRefundOption').setValue(res.data.defaultRefundOptForActive)
            this.activateForm.get('activePlanRefundOption').setValidators([Validators.required]);
            this.activateForm.get('activePlanRefundOption').updateValueAndValidity();
            res.data.activePlanList.forEach(element => {
              element.usedDays = element.usedDays ? element.usedDays : '0'
            });
            this.activatePlandataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res.data.activePlanList)));
          }
          else {
            this.activateForm.get('activePlanRefundOption').clearValidators();
            this.activateForm.get('activePlanRefundOption').updateValueAndValidity();
            this.activatePlandataSource = null;
          }
          if (res.data.futurePlanList && res.data.futurePlanList.length > 0) {
            this.activateForm.get('futurePlanRefundOption').setValue(res.data.defaultRefundOptForFuture)
            this.activateForm.get('futurePlanRefundOption').setValidators([Validators.required]);
            this.activateForm.get('futurePlanRefundOption').updateValueAndValidity();
            res.data.futurePlanList.forEach(element => {
              element.usedDays = element.usedDays ? element.usedDays : '0'
            });
            this.activateFuturePlandataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res.data.futurePlanList)));
          }
          else {
            this.activateForm.get('activePlanRefundOption').clearValidators();
            this.activateForm.get('activePlanRefundOption').updateValueAndValidity();
            this.activateFuturePlandataSource = null;
          }
        }
        else{
          if (!this.refundOptionFlag && res.data.activePlanList && res.data.activePlanList.length > 0) {


            this.activateForm.get('activePlanRefundOption').setValue(res.data.defaultRefundOptForNoACL)
            this.activateForm.controls.activePlanRefundOption.disable();
            this.activateForm.get('activePlanRefundOption').setValidators([Validators.required]);
            this.activateForm.get('activePlanRefundOption').updateValueAndValidity();
            res.data.activePlanList.forEach(element => {
              element.usedDays = element.usedDays ? element.usedDays : '0'
            });
            this.activatePlandataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res.data.activePlanList)));
          }
          else {
            this.activateForm.get('activePlanRefundOption').clearValidators();
            this.activateForm.get('activePlanRefundOption').updateValueAndValidity();
            this.activatePlandataSource = null;
          }
          if (!this.refundOptionFlag && res.data.futurePlanList && res.data.futurePlanList.length > 0) {
            this.activateForm.get('futurePlanRefundOption').setValue(res.data.defaultRefundOptForNoACL)
            this.activateForm.controls.futurePlanRefundOption.disable();
            this.activateForm.get('futurePlanRefundOption').setValidators([Validators.required]);
            this.activateForm.get('futurePlanRefundOption').updateValueAndValidity();
            res.data.futurePlanList.forEach(element => {
              element.usedDays = element.usedDays ? element.usedDays : '0'
            });
            this.activateFuturePlandataSource = new MatTableDataSource(JSON.parse(JSON.stringify(res.data.futurePlanList)));
          }
          else {
            this.activateForm.get('activePlanRefundOption').clearValidators();
            this.activateForm.get('activePlanRefundOption').updateValueAndValidity();
            this.activateFuturePlandataSource = null;
          }
        }


      });
      setTimeout(() => {
        this.customModalService.open(id);
      }, 500);

      this.planId = findId.planmapid;
      this.custplanId = findId.planId;

    }
    else {
      this.customModalService.close(id);
      this.getFuturePlansData();
    }

  }


  closeCancelPlanPopup(event) {
    this.openCancelPlanPopup = false;
    this.getFuturePlansData();
  }
  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getFuturePlansData() {
    this.futurePlansService.getFuturePlansList(this.custId).subscribe(result => {
      this.futurePlanTableData = result.dataList;
      this.refreshDataSource(this.futurePlanTableData);
    });
  }
  onFuturePlanActive() {
    this.loadingService.show();
    this.submitted = true
    let obj = {
      custId: this.custId,
      planId: this.planId,
      remark: this.remark,
      futurePlanRefundOption: this.futurerefundOption,
      activePlanRefundOption: this.activerefundOption,
      activePlanVASRefund:this.activePlanVASRefund,
      futurePlanVASRefund:this.futurePlanVASRefund
    }
    this.activateForm.patchValue(obj)
    ////console.log("form ==>",this.activateForm)
    if (this.activateForm.valid === true) {
      // this.btnsaveDisable=true;
      // this.apiCall(obj)
      let self = this;
      alertify.confirm("Activate Plan","Are You Sure You want to Activate Plan ?",
      function() {
        this.btnsaveDisable=true;
         self.apiCall(obj);
      },
      function() {
        this.btnsaveDisable=false;
        this.loadingService.hide();
      });

    }
    else {
      this.toster.error("Please Enter All Fields!");
      this.loadingService.hide();
      this.btnsaveDisable=false;
    }

  }
  apiCall(obj){
    this.loadingService.show();
    this.futurePlansService.activatePlan(obj).subscribe((res) => {
      if (res.responseCode === 200) {
        this.loadingService.hide();
        this.btnsaveDisable=false;
        this.toster.success("Plan has been activated successfully");
        this.customModalService.close('active-plan')
        this.remark = "";
        this.getFuturePlansData()

      } else {
        this.loadingService.hide();
        this.toster.error(res.responseMessage);
        this.btnsaveDisable=false;
      }
    });

  }
  onFuturePlanPopupClose() {
    this.openactivePlanPopup = false;
    // this.getFuturePlansData();
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }

}
