import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class BillingTemplateService {
  constructor(private http: HttpClient) {

  }

  transformDiscountData(data) {
    ////console.log("datat",data);
    let formatedData = [];
    data.billRunlist.forEach(function(item:any){
      let itemData:any = {};
      itemData.billing_id = item.id;
      itemData.templatename = item.templatename;
      itemData.invoiceNumFormat=item.invoiceNumFormat;
      itemData.templatetype = item.templatetype;
      itemData.status = item.status;
      itemData.jrxmlfile = item.jrxmlfile;
      formatedData.push(itemData);
    });
    return {content:formatedData};
  }

  getBillingTemplateDataWithPageing(fetch_setting_data): Observable<any> {
    let page = fetch_setting_data.page;
    let size = fetch_setting_data.size;
    let params = new HttpParams().set("page", page).set("size", size);
    const get_url = BASE_API_URL + 'billTemplete';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers, params: params }).pipe(
        map((res: any) => {
          return this.transformDiscountData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getBillingTemplateData(billing_id): Observable<any> {
    const get_url = BASE_API_URL + 'billTemplete';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformDiscountData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  insertBillingTemplate(billing_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'billTemplete';
    return this.http.post<any>(post_url, billing_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateBillingTemplate(billing_data: any,billing_id:any): Observable<any> {
    const update_url = BASE_API_URL +"billTemplete/"+ billing_id;
    return this.http.put<any>(update_url, billing_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteBillingTemplate(billing_id: any): Observable<any> {
    const delete_url = BASE_API_URL+"billTemplete/" + billing_id;
    return this.http.delete(delete_url, httpOptions);
  }

  getBillingTemplateForPartner(): Observable<any> {
    const get_url = BASE_API_URL + 'billTemplete/getTemplateByType/Billing';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCreditnoteTemplateForPartner(): Observable<any> {
    const get_url = BASE_API_URL + 'billTemplete/getTemplateByType/Credit Note';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPaymentReceiptTemplateForPartner(): Observable<any> {
    const get_url = BASE_API_URL + 'billTemplete/getTemplateByType/Payment';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
