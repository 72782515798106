import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IBaseService } from './ibase-service';
import { ResponseData } from '../core/modal/base-save-update-response';
import { RequestData } from '../core/modal/base-save-update-modal';
import { GetAllRequest } from '../core/modal/get-all-request';
import { PaginationData } from '../core/modal/pagination-data';
import { OrderingData } from '../core/modal/ordering-data';
import { SearchData } from '../core/modal/search-data';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export abstract class BaseService<T> implements IBaseService<T> {
  constructor(public http: HttpClient) {}

  save(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    // reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    ////console.log('save called !');
    // return null;
    return this.http.post<ResponseData<T>>(baseApiUrl + this.getModuleName() + '/save', reqObj.data);
  }

  update(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    // reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    ////console.log('Update Caleed!');
    return this.http.post<ResponseData<T>>(baseApiUrl + this.getModuleName() + '/update', reqObj.data);
    // return null;
  }

  delete(modal: T): Observable<ResponseData<T>> {
    let reqObj: RequestData<T> = new RequestData<T>();
    // reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    ////console.log('Delete Called!');
    // if (environment.moduleName == 'textile') {
    //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/delete", reqObj.data);
    // } else {
    return this.http.post<ResponseData<T>>(baseApiUrl + this.getModuleName() + '/delete', reqObj.data);
    // }
    // return null;
  }

  getById(id: number): Observable<ResponseData<T>> {
    ////console.log('Get by id Called!');
    // if (environment.moduleName == 'textile') {
    //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/" + id);
    // } else {
    return this.http.get<ResponseData<T>>(baseApiUrl + this.getModuleName() + '/' + id, {
      headers: httpOptions.headers,
    });
    // }
    return null;
  }
  getAll(
    paginationData: PaginationData,
    orderingData: OrderingData,
    searchData?: SearchData[],
    id?:number
  ): Observable<ResponseData<T>> {
    let searchDataObj = searchData && searchData.length > 0 ? JSON.stringify(searchData) : null;
    let params:any = {}; //new HttpParams();
    if (paginationData) {
       params.page = paginationData.pageNumber.toString();
       params.pageSize = paginationData.rowsPerPage.toString();
       //params.set('page', paginationData.pageNumber.toString());
       //params.set('pageSize', paginationData.rowsPerPage.toString());
    }
    if (orderingData) {
      params.sortBy = orderingData.column;
      params.sortOrder = (orderingData.descending ? 1 : 0) + '';
       // params.set('sortBy', orderingData.column);
       // params.set('sortOrder', (orderingData.descending ? 1 : 0) + '');
    }
    // ////console.log('searchData', searchData);
    if (searchDataObj) {
       params.filters = JSON.parse(searchDataObj);
       // params.append('search', searchDataObj);
    }
  
    
    if(id){
    params.id = id
    }
    // api/v1/anyModuleUrl/getAllByPagination?page=1&pageSize=1&sortBy="id"&sortOrder="1"

    // return this.http.get<ResponseData<T>>(baseApiUrl + this.getModuleName(), { headers: httpOptions.headers });

    return this.http.post<ResponseData<T>>(baseApiUrl + this.getModuleName(),params,{
      headers: httpOptions.headers
    });
    return null;
  }

  getRequestToken(): string {
    return 'xyz1234';
  }
  abstract validate(): boolean;
  abstract getModuleName(): string;
  // abstract  getId(): number;
}
