import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../../../../services/base-service';
import { invoice } from '../model/invoice.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService extends BaseService<invoice>{
  constructor(http: HttpClient) {
    super(http);
   }

  getModuleName(): string {
    return 'report/invoiceReport';
  }

  validate(): boolean {
    return false;
  }

  paymentCollectonGetData(params: any): Observable<any> {
    const post_url = BASE_API_URL + 'report/invoiceReport';
    return this.http.post<any>(post_url, params);
  }

  excelServiceData(data) {
    const post_url = BASE_API_URL + "report/invoiceReport/excel";
    return this.http.post<any>(post_url, data, { observe: 'body', responseType: 'blob' as 'json' });
  }

  getCollectorListByPartner(partnerId?): Observable<any> {
    let get_url
    if(partnerId){
      get_url = BASE_API_URL + 'userByPartnerAndRole/'+ partnerId + '/' + 14;
    }else{
      get_url = BASE_API_URL + 'userByRole/'+ 14;
    }
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getinvoiceDetailList(id): Observable<any> {
    const get_url = BASE_API_URL + 'purchaseSettlement/getAllByUuid/' + id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
