import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class TestPaymentService {
  
    constructor(private http: HttpClient) {}
  
    insertPlaceOrder(placeOrder: any): Observable<any> {
      const post_url = BASE_API_URL + 'order/place';
      return this.http.post<any>(post_url, placeOrder, httpOptions).pipe(
        map(data => data),
        catchError((error: any) => {
            return throwError(error);
        })
      );
   }

   getCustomerlogo(){
    const get_url = BASE_API_URL + 'getLogoPath';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers})
   }
   getPurchaseDetailStatusByTransactionId(txnId): Observable<any> {
      const get_url = BASE_API_URL + 'purchaseDetails/txnStatus/' + txnId;
      return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }

    getAllPurchaseDetails(): Observable<any> {
      const get_url = BASE_API_URL + 'purchaseDetails/all';
      return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }
}
