import { ChangeDetectorRef, Component, OnInit,Inject, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscountService } from '../discount/discount.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { PackagesService } from '../packages/packages.service';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../data-sharing-service.service';

export interface DialogPassData{
  id:number;
}

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.scss']
})
export class AddDiscountComponent implements OnInit {
  saveBtn: boolean = false;
  public sub: any = null;
  public discount_id: any = null;
  show_update: any = false;
  selectForUpdate: any;
  isDisabled:boolean = false;
  planList: any = [
    {
      packages_id: '',
      name: ''
    }
  ];
  error_message = "This field is required.";
  insertDiscountForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    desc: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    discoundMappingList: new FormArray([], [Validators.required]),
    discoundPlanMappingList: new FormArray([], [Validators.required])
  });
  constructor(
    private discountService: DiscountService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private planService: PackagesService,
    private tosterservice: ToastrService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddDiscountComponent>,
    private sharingdataService: DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.discount_id = id;
       this.isDisabled=false;
      this.show_update = true;
      this.getDiscountData();
    }

    // if(this.data && this.data.id){
      
    //   this.isDisabled = true;
    //   this.discount_id = this.data.id;
    //   this.isDisabled=true;
    //   this.show_update = true;
    //   this.getDiscountData();
    //   this.insertDiscountForm.disable(); 
    // }
    this.planService.getPackagesList().subscribe(result => this.planList = result.content);
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.discount_id = params['id'];
    //       this.show_update = false;
    //       this.getDiscountData();
    //     }
    //   });
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }
  validateForm() {
    if (!this.insertDiscountForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertDiscountForm.controls).forEach(field => {
        const control = this.insertDiscountForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.discount_id === null) {
        this.insertDiscountOnDb();
      } else {
        this.updateDiscountOnDb();
      }
    }
  }

  getDiscountData() {
    let discount_id = this.discount_id;
    this.discountService.getDiscountData(discount_id).subscribe(
      result => {
        this.setUpdateValueInForm(result.discountList);
      });
  }

  setUpdateValueInForm(discount_Data) {
    this.show_update = true;
    this.selectForUpdate = discount_Data;
    for (let mapping of discount_Data.discoundMappingList) {
      this.onAddMappingItems(mapping);
    }
    for (let plan of discount_Data.discoundPlanMappingList) {
      this.onAddPlanItems(plan);
    }
    this.insertDiscountForm.patchValue(discount_Data);
  }

  onAddMappingItems(row?) {
    (<FormArray>this.insertDiscountForm.get('discoundMappingList')).push(
      new FormGroup({
        id: new FormControl(row ? row.id : ''),
        validFrom: new FormControl(row ? row.validFrom : ''),
        validUpto: new FormControl(row ? row.validUpto : ''),
        discountType: new FormControl(row ? row.discountType : ''),
        amount: new FormControl(row ? row.amount : '')
      })
    );
  }

  get mappingItems() {
    return (this.insertDiscountForm.get('discoundMappingList') as FormArray).controls;
  }

  onMappingDelete(index: number) {
    // alertify.confirm("Discount Mapping", "Are you sure you want to delete this record ?",
    //   function () {
    //     (<FormArray>this.insertDiscountForm.get('discoundMappingList')).removeAt(index);
    //   },
    //   function () {
    //   }
    // );
    let self=this;
    alertify.confirm("Discount Mapping", "Are you sure you want to delete this record?",
      function () {
        (<FormArray>self.insertDiscountForm.get('discoundMappingList')).removeAt(index);
      },
      function () {
      });
  }

  onAddPlanItems(row?) {
    (<FormArray>this.insertDiscountForm.get('discoundPlanMappingList')).push(
      new FormGroup({
        id: new FormControl(row ? row.id : ''),
        planId: new FormControl(row ? row.planId : '')
      })
    );
  }

  get planItems() {
    return (this.insertDiscountForm.get('discoundPlanMappingList') as FormArray).controls;
  }

  onPlanDelete(index: number) {
      let self =this;
      alertify.confirm("Plan Mapping","Are you sure you want to delete this record ?",
      function(){
        (<FormArray>self.insertDiscountForm.get('discoundPlanMappingList')).removeAt(index);
      },
      function(){
      });
  }

  insertDiscountOnDb() {
    if (this.insertDiscountForm.valid === true) {
      this.saveBtn = true;
      let apiInputData = Object.assign({}, this.insertDiscountForm.value);
      let formatedInputData: any = this.insertDiscountForm.value;
      this.discountService.insertDiscount(formatedInputData).subscribe(result => {
        if (result.status !== 200) {
          this.saveBtn = false;
          this.tosterservice.error(result.ERROR);
        }
        else {
          this.saveBtn = false;
          this.cdRef.detectChanges();
          this.onBackToDiscount();
          this.tosterservice.success('Discount has been created successfully');
        }
      },
        err => {
          this.saveBtn = false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateDiscountOnDb() {
    if (this.insertDiscountForm.valid === true) {
      this.saveBtn = true;
      let formatedInputData: any = this.insertDiscountForm.value;
      this.discountService.updateDiscount(formatedInputData, this.discount_id).subscribe(result => {
        if (result.status !== 200) {
          this.saveBtn = false;
          this.tosterservice.error(result.ERROR);
        }
        else {
          this.saveBtn = false;
          this.cdRef.detectChanges();
          this.show_update = false;
          this.onBackToDiscount();
          this.tosterservice.success('Discount Update successfully');
        }

      },
        err => {
          this.saveBtn = false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  onBackToDiscount() {
    //this.router.navigate(['plan-management/discount']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['plan-management/discount']);
    }
  }
  
  closeDialog(){
    this.dialogRef.close();
  }
}
