import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { ChargeService } from './charge.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TaxService } from './../../plan_management/tax/tax.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchService } from '../../../services/search.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { CommonChargecategoryService } from '../../../services/common_chargecategory/common-chargecategory.service'; 
import { debug } from 'console';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss']
})
export class ChargeComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('chargeTypeWrapper', { static: false }) chargeTypeWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('price', { static: false }) priceRef: TemplateRef<any>;
  @ViewChild('chargeName', { static: false }) chargeNameReff: TemplateRef<any>;


  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  chargeDataForGrid: any ;
  chargeGridSettings: any = {};
  commonChargeCategory: any[]=[];
  show_update: any = false;
  selectForUpdate: any;
  public taxList: any[] = [{
    tax_name: '',
    tax_id: ''
  }];
  insertChargeForm = new FormGroup({
    charge_name: new FormControl('', [Validators.required]),
    charge_desc: new FormControl('', [Validators.required]),
    charge_type: new FormControl('', [Validators.required]),
    price: new FormControl('', [Validators.required]),
    actual_price: new FormControl(''),
    tax_id: new FormControl('')
  });

  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  chargeNeoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredServiceDataForGrid: any = [];
  public loginService: LoginService;

  constructor(
    private chargeService: ChargeService,
    private taxService: TaxService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private commonChargeCategoryService:CommonChargecategoryService,
    private tosterservice:ToastrService,
    loginService: LoginService
  ) {
    super(searchService);
    this.loginService = loginService;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.CHARGE_LIST)
    this.chargeCategoryLoad();
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'charge_name',
        default: 'Charge Name',
        rowKey: 'charge_name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'category',
        default: 'Charge Category',
        rowKey: 'category',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'charge_type',
      //   default: 'Charge Type',
      //   rowKey: 'charge_type',
      //   width: '20%',
      //   isNumber: false,
      //   customControls: [this.chargeTypeWrapperRef],
      //   customValue: (charge_type) => {
      //     if (charge_type === 'ADVANCE') {
      //       return 'Advance';
      //     } else if (charge_type === 'CUSTOMER_DIRECT') {
      //       return 'Customer Direct';
      //     } else if (charge_type === 'NON_RECURRING') {
      //       return 'Non Recurring';
      //     } else if (charge_type === 'RECURRING') {
      //       return 'Recurring';
      //     } else if (charge_type === 'REFUNDABLE') {
      //       return 'Refundable';
      //     }
      //   },
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'price',
        default: 'Office Price',
        rowKey: 'price',
        width: '10%',
        customControls: [this.priceRef],
        customValue:(price) => {
          return price;
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Charge Name',
        value_field: 'charge_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Charge Type',
        value_field: 'charge_type',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Price',
        value_field: 'price',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.NUMBER,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.NUMBER,
      }
    ];

    this.chargeNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CHARGE_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Charges ?',
      deleteMsgBoxDetail: 'Charges Name :${charge_name} ',
    };
    if (this.chargeNeoGridSettings.serverSideSortPagination && this.chargeNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.chargeNeoGridSettings.perPage);
    }
  }

  chargeCategoryLoad(){
      this.commonChargeCategoryService.getCommonListChargeCategory().subscribe(
      (res) => {this.commonChargeCategory = res.dataList;
      ////console.log("commonChargeCategory-->"+ JSON.stringify(this.commonChargeCategory))
   })
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'charge_type') {
        col.customControls = [this.chargeTypeWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'price') {
        col.customControls = [this.priceRef];
      }
      if (col.colKey == 'charge_name') {
        col.customControls = [this.chargeNameReff];
      }
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.chargeDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.chargeDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  refreshDataSource(data) {
    if (data) {
      ////console.log("Refresh Data Resource",data)
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    if (event) {
      this.commonDeleteMsgBox(this.chargeNeoGridSettings, event, this.delete.bind(this));
    }
  }

  getChargeData(gridSetting: any) {
    let fetchSettingData = this.chargeNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.chargeService.getChargeDataWithPageing(params).subscribe(result => {
      this.chargeDataForGrid = result;
      this.chargeDataForGrid.content.forEach(res => {
          res.price = res.price + res.taxamount;
       });
      this.refreshDataSource(this.chargeDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.chargeNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }



  delete(objEvent) {
    let self = this;
    let selected_charge_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.chargeService.deleteCharge(selected_charge_data.charge_id).subscribe((result) => {
        if(result.status!== 200){
          this.tosterservice.error(result.ERROR);
        }
        else{
          this.tosterservice.success('Charge is deleted successfully');
          this.show_update = false;
          self.chargeDataForGrid.content.forEach(function (item, index) {
            if (item.charge_id === selected_charge_data.charge_id) {
              self.chargeDataForGrid.content.splice(index, 1);
            }
          });
          self.refreshDataSource(self.chargeDataForGrid.content);
        }
 
      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  setUpdateValueInForm(rowData) {
    
    console.log("row-->",rowData)
    this.router.navigate(['plan-management/charge/add-charge',rowData.charge_id]);
  }

  onAddChargeClick() {
    this.router.navigate(['plan-management/charge/add-charge']);
  }

  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getChargeData(config);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getChargeData(config);
      }
}
