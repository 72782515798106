import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import {log} from 'util';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class FranchiesService {
  constructor(private http: HttpClient) {

  }
  transformFranchiesData(data) {
    
    if(data.dataList){
      data['partnerlist'] = data.dataList;
    }
    
    let formatedData = [];
    if(Array.isArray(data.partnerlist)){
      data.partnerlist.forEach(function(item:any){
        let itemData:any = {};
        itemData.franchies_id = item.id;
        itemData.name = item.name;
        itemData.status = item.status;
        itemData.commission_type = item.commtype;
        itemData.commission_value = item.commrelvalue;
        itemData.credit_limit = item.credit_limit;
        itemData.commission_day = item.commdueday;
        itemData.address_type = item.addresstype;
        itemData.tax_id = item.taxid;
        itemData.address1 = item.address1;
        itemData.address2 = item.address2;
        // itemData.country_id = item.country;
        // itemData.state_id = item.state;
        // itemData.city_id = item.city;
        itemData.pincode = item.pincodeName;
        itemData.mobile = item.mobile;
        itemData.email = item.email;
        itemData.price_book = item.pricebookId;
        itemData.pricebookname = item.pricebookname;
        itemData.serviceAreaIds = item.serviceAreaIds;
        itemData.updatedate = item.updatedate
        itemData.parent_franchies_id = item.parentpartnerid;
        itemData.billingTemplate=item.billingTemplate;
        itemData.creditNoteTemplate=item.creditNoteTemplate;
        itemData.receiptTemplate=item.receiptTemplate;
        itemData.tdsTax = item.tdsTax;
        itemData.tdsTax = item.tdsTax;
        itemData.notifiGroupName=item.notifiGroupName;
        itemData.notifiGroupId = item.notifiGroupId;
        itemData.merchantId=item.merchantId;
        itemData.dunningRuleId=item.dunningRuleId;
        itemData.dunningRuleName=item.dunningRuleName;
        itemData.isIpPoolForPartner=item.isIpPoolForPartner;
        itemData.taxCalculation=item.taxCalculation;
        
        itemData.outcomeBalance=item.outcomeBalance;//New key
        
        formatedData.push(itemData);
      });
    }
    else{
      let itemData:any = {};
      itemData.franchies_id = data.partnerlist.id;
      itemData.name = data.partnerlist.name;
      itemData.status = data.partnerlist.status;
      itemData.commission_type = data.partnerlist.commtype;
      itemData.commission_value = data.partnerlist.commrelvalue;
      itemData.credit_limit = data.partnerlist.credit_limit;
      itemData.commission_day = data.partnerlist.commdueday;
      itemData.address_type = data.partnerlist.addresstype;
      itemData.tax_id = data.partnerlist.taxid;
      itemData.address1 = data.partnerlist.address1;
      itemData.address2 = data.partnerlist.address2;
      // itemData.country_id = data.partnerlist.country;
      // itemData.state_id = data.partnerlist.state;
      // itemData.city_id = data.partnerlist.city;
      itemData.pincode = data.partnerlist.pincodeName;
      itemData.mobile = data.partnerlist.mobile;
      itemData.email = data.partnerlist.email;
      itemData.outcomeBalance = data.partnerlist.outcomeBalance;
      itemData.price_book = data.partnerlist.pricebookId;
      itemData.serviceAreaIds = data.partnerlist.serviceAreaIds;
      itemData.parent_franchies_id = data.partnerlist.parentpartnerid;
      itemData.billingTemplate=data.partnerlist.billingTemplate;
      itemData.creditNoteTemplate=data.partnerlist.creditNoteTemplate;
      itemData.receiptTemplate=data.partnerlist.receiptTemplate;
      itemData.tdsTax = data.partnerlist.tdsTax;
      itemData.notifiGroupId = data.partnerlist.notifiGroupId;
      itemData.notifiGroupName=data.partnerlist.notifiGroupName;
      itemData.merchantId=data.partnerlist.merchantId;
      itemData.dunningRuleId=data.partnerlist.dunningRuleId;
      itemData.isIpPoolForPartner=data.partnerlist.isIpPoolForPartner;
      itemData.taxCalculation=data.partnerlist.taxCalculation;

      formatedData.push(itemData);
    }

    return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getFranchiesDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'partner/list';

    return this.http.post<any>(post_url,params,
        { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformFranchiesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }
getFranchiseBalanceById(id){
  const get_url = BASE_API_URL + 'partner/outstandAmount/' + id;
  return this.http.get<any>(get_url);
}
getCommissionByPartnerId(params):Observable<any>{
  const get_url = BASE_API_URL + 'partnerCommisionDtl';
  return this.http.post<any>(get_url,params,
    { headers: httpOptions.headers }).pipe(
      map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
    );
}
savePgConfig(data){
  const post_url = BASE_API_URL + 'partnerPGConfig/save'
  return this.http.post<any>(post_url,data);
}
savePartnerConfig(data){
  const post_url = BASE_API_URL + 'partnerConfig/save'
  return this.http.post<any>(post_url,data);
}
updatePgConfig(data){
  const post_url = BASE_API_URL + 'partnerPGConfig/update'
  return this.http.post<any>(post_url,data); 
}

updatePartnerConfig(data){
  const post_url = BASE_API_URL + 'partnerConfig/update'
  return this.http.post<any>(post_url,data); 
}

DeletePgConfig(data){
  const post_url = BASE_API_URL + 'partnerPGConfig/delete'
  return this.http.post<any>(post_url,data); 
}

DeletePartnerConfig(data){
  const post_url = BASE_API_URL + 'partnerConfig/delete'
  return this.http.post<any>(post_url,data); 
}

getPgByPartner(id){ 
  const get_url = BASE_API_URL + 'partnerPGConfig/getPartnerPGConfigByPartnerId/' + id;
  return this.http.get<any>(get_url);
}

getconfigByPartner(id){ 
  const get_url = BASE_API_URL + 'partnerConfig/getConfigurationByPartner/' + id;
  return this.http.get<any>(get_url);
} 

getconfiglist(){ 
  const get_url = BASE_API_URL + 'partnerConfig/getConfigurationNameList';
  return this.http.get<any>(get_url);
} 

getconfigValue(url){ 
  const get_url = BASE_API_URL + url;
  return this.http.get<any>(get_url);
} 

getPartnerPaymentDtl(reqObj){
  const post_url = BASE_API_URL + 'partnerPaymentDtl';
  if(reqObj){
    return this.http.post<any>(post_url,reqObj);
  }
  
}
saveAddBalance(data){
  const post_url = BASE_API_URL + 'partner/addBalance';
  return this.http.post<any>(post_url, data);
}
  reverseBalance(data){
    const post_url = BASE_API_URL + 'partner/reverseBalance';
    return this.http.post<any>(post_url, data);
  }
  getFranchiesDataById(franchies_id): Observable<any> {
    const get_url = BASE_API_URL + 'partner/' + franchies_id;
  //  const get_url = BASE_API_URL + 'partner';
    return  this.http.get<any>(get_url,
        { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          
          return this.transformFranchiesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }

  transformFranchiesList(data) {
    let formatedData = [];
    data.partnerlist.forEach(function(item:any){
      if (item.status == "Y") {
        let itemData:any = {};
        itemData.franchies_id = item.id;
        itemData.name = item.name;
        itemData.status = item.status;
        itemData.commission_type = item.commtype;
        itemData.commission_value = item.commrelvalue;
        itemData.credit_limit = item.commrelvalue;
        itemData.commission_day = item.commdueday;
        itemData.address_type = item.addresstype;
        itemData.tax_id = item.taxid;
        itemData.address1 = item.address1;
        itemData.address2 = item.address2;
        itemData.country_id = item.country;
        itemData.state_id = item.state;
        itemData.city_id = item.city;
        itemData.pincode = item.pincode;
        itemData.mobile = item.mobile;
        itemData.email = item.email;
        itemData.area_id = item.area;
        itemData.parent_franchies_id = item.parent_franchies;
        itemData.billingTemplate=item.billingTemplate;
        itemData.creditNoteTemplate=item.creditNoteTemplate;
        itemData.receiptTemplate=item.receiptTemplate;
        itemData.tdsTax = item.tdsTax;
        itemData.notifiGroupId = item.notifiGroupId;
        itemData.notifiGroupName=item.notifiGroupName;
        formatedData.push(itemData);
      }
    });
    return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  public getFranchiesList(): Observable<any> {
    const get_url = BASE_API_URL + 'partner/all';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformFranchiesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }

  getManagablePartnerlistService(): Observable<any> {
    const get_url = BASE_API_URL + "getManageablePartnerList";
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
      map((res: any) => {
        return this.transformFranchiesData(res);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
  );
}

  insertFranchies(franchies_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'partner';
    return this.http.post<any>(post_url, franchies_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateFranchies(franchies_data: any,franchies_id:any): Observable<any> {

    const update_url = BASE_API_URL +"partner/"+franchies_id;
    return this.http.put<any>(update_url, franchies_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteFranchies(franchies_id: any): Observable<any> {
    const delete_url = BASE_API_URL+"partner/" + franchies_id;
    return this.http.delete(delete_url, httpOptions);
  }

  checkUniqueEmail(data): Observable<any> {
    const get_url = BASE_API_URL + 'checkPartnerUniqueEmail';
    
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  checkUniqueMobile(data): Observable<any> {
    const get_url = BASE_API_URL + 'checkPartnerUniqueMobile';
    
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  saveParnertConfig(data):Observable<any>{
    const post_url = BASE_API_URL + 'partnerConfig/save';
    return this.http.post<any>(post_url,data);
  }
  updateParnertConfig(data):Observable<any>{
    const post_url = BASE_API_URL + 'partnerConfig/update';
    return this.http.post<any>(post_url,data);
  }

  getCommonlist(type:any):Observable<any>{
    const get_url = BASE_API_URL + 'commonList/generic/'+type;
    return this.http.get<any>(get_url);
  }
  getAllConfig(params: HttpParams,partnerId:number):Observable<any>{
    const post_url = BASE_API_URL + 'partnerConfig/getConfigurationByPartner/'+partnerId;
    return this.http.get<any>(post_url,
        ).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }
  deletePartnerConfig(data):Observable<any>{
    const post_url=BASE_API_URL+'partnerConfig/delete';
    return this.http.post<any>(post_url,data);
  }
  getNotificationGroupService():Observable<any>{
    const get_url=BASE_API_URL+'notificationGroup/all';
    return this.http.get<any>(get_url);
  }

  excelServiceData(data) {
    const post_url = BASE_API_URL + "partnerCommission/excel/partner";
    return this.http.post<any>(post_url,data,{ observe: 'body', responseType: 'blob' as 'json' });
  }

  excelServiceDataPartnerPayment(Id) {
    const post_url = BASE_API_URL + "partnerPayment/excel/"+Id;
    return this.http.post<any>(post_url,Id,{ observe: 'body', responseType: 'blob' as 'json' });
  }

  aclPartnerConfig(data){
    console.log("aclPartnerConfig",data)
    const post_url = BASE_API_URL + 'partnerRoleMapping/save'
    return this.http.post<any>(post_url,data); 
  }

  //pagination with Post Method
  getpartnerRoleListing(data):Observable<any>{
    const post_url = BASE_API_URL + 'partnerRoleMapping';
    return this.http.post<any>(post_url,data);
  }
  
  getRoleListofPartner(data:any): Observable<any> {
    const post_url = BASE_API_URL + 'partnerRoleMapping';
    return this.http.post<any>(post_url,data)
  }

  //delete Role
  deleteRoleofPartner(data){
    const delete_url = BASE_API_URL + 'partnerRoleMapping/delete';
    return this.http.post<any>(delete_url,data)
  }
  //update Role
  updateRoleofPartner(data):Observable<any>{
    const post_url = BASE_API_URL + 'partnerRoleMapping/update';
      return this.http.post<any>(post_url,data);
  }
}
