import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base-service';
import { Customer } from '../../model/Customer';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class SubscriberLeasedServiceService extends BaseService<Customer> {

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  private filterSubject = new Subject<any>();


  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "leasedlinesubscriber";
  }
  numberOnly(event): boolean {
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    
    return true;
  }
  getFilterOptions(): Observable<any> {
    return this.filterSubject.asObservable();
  }
  getAdvanceFilterSearchData(obj){
    obj['page']=1,
    obj['pageSize']=10
    const post_url = baseApiUrl + 'leasedlinesubscriber';
    return this.http.post<any>(post_url,obj, { headers: httpOptions.headers });
  }

}
