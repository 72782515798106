import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base-service';
import { ServiceAreaModel } from '../model/service-area-model';

const BASE_API_URL = '';
@Injectable({
  providedIn: 'root'
})
export class ServiceAreaService  extends BaseService<ServiceAreaModel> {
  
  constructor(http: HttpClient) {
    super(http);
  }
  
  getModuleName(): string {
    return 'serviceArea';
  }

  validate(): boolean {
    return false;
  }

  getStaff(id) {
    const get_url = BASE_API_URL+'staffuserByRole/' + id;
    return this.http.get<any>(get_url);
  }
}
