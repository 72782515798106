import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';

import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { CaseReasonModel } from '../model/case-reason-model';
import { CaseReasonService } from '../service/case-reason.service';
import {OrderingData} from '@neoboss/core/modal/ordering-data';
import {PaginationData} from '@neoboss/core/modal/pagination-data';
import {SearchData} from '@neoboss/core/modal/search-data';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { elementAt } from 'rxjs/operators';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
@Component({
  selector: 'app-case-reason-list',
  templateUrl: './case-reason-list.component.html',
  styleUrls: ['./case-reason-list.component.scss']
})
export class CaseReasonListComponent extends AbstractBaseListComponent<CaseReasonModel> implements OnInit, OnDestroy {
  dataGrid: CaseReasonModel[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  caseReasonType = [];
  filters = new Array<filters>()
  filtersObj = new filters();
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  searchSettings = [];
  caseReasonneoTableSetting:any={};
  public entity: CaseReasonModel = new CaseReasonModel();
  public entity_id: any = null;
  form: FormGroup;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('caseReason', { static: false }) caseReasonReff: TemplateRef<any>;
  @ViewChild('status', { static: false }) statusRef: TemplateRef<any>;
  //@ViewChild('bucket', { static: false }) bucketRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;

  constructor(private entityService : CaseReasonService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private commonListService: CommonListServicesService,
    loginService: LoginService,
    private searchService: SearchService) {
      super(toastrService, searchService);
      this.loginService = loginService;
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.CASE_REASON);
    this.init();
    //this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
    this.getCaseReasonType();
  }

  getService(): IBaseService<CaseReasonModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'caseReasonType',
        default: 'Case Reason Type',
        rowKey: 'caseReasonType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'bucket',
      //   default: 'bucket',
      //   rowKey: 'bucket',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'tatConsideration',
        default: 'Tat Consideration',
        rowKey: 'tatConsideration',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    //Sthis.getTableSetting();
    this.getTableSearchSetting();
    return this.allColumns ? this.allColumns : [];
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.caseReasonReff];
      }
      if(col.colKey=='status'){
        col.customControls=[this.statusRef]
      }
      // if(col.colKey=='bucket'){
      //   col.customControls=[this.bucketRef]
      // }
    });
  }

  getTableSetting() {
    const caseReasonneoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CASE_REASON,
      perPage: this.paginationData.rowsPerPage ,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Case Reason ?',
      deleteMsgBoxDetail: 'Case Reason Name :${name} ',
    };
    this.caseReasonneoTableSetting = caseReasonneoTableSetting;
    return caseReasonneoTableSetting;
  }

  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Tat Consideration',
        value_field: 'tatConsideration',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  // onEditClick(event) {

  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  setList(list: Array<CaseReasonModel>) {
    this.dataGrid = list;
    this.caseReasonneoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    if(filterObj && filterObj.length>0)
    {
      let filters = filterObj ? JSON.parse(JSON.stringify(filterObj)) : null;
      if (this.caseReasonneoTableSetting.serverSideFilter) {
        this.onFilterChange(filters);
      }
    }
    else
    {
      let filters = null ? JSON.parse(JSON.stringify(null)) : null;
      if (this.caseReasonneoTableSetting.serverSideFilter) {
        this.onFilterChange(filters);
      }
    }

  }

  onFilterChange(filterObj, obj?) {
    if (this.searchService) {

      let serverFilterObj: SearchData[] = this.searchService.getServerFilterObj();
      if (filterObj) {
        //this.filters.forEach(res=> filterObj.push(res))
        let searchData = new SearchData();
        searchData = filterObj;
        if (!Array.isArray(searchData) && searchData.filterColumn) {
          if(serverFilterObj && serverFilterObj.length>0){
            if (searchData.filterValue == '') {
              let temp: any = Object.assign([], serverFilterObj.filter(res => res.filterColumn !== 'any'))
              serverFilterObj = []
              temp.forEach(res => serverFilterObj.push(res))
              this.searchService.serverFilterObj = serverFilterObj;
              this.filters = Object.assign([], serverFilterObj)
              this.paginationData.pageNumber = this.neoTableSetting.pageNumber + 1
            } else {
              let temp: any = Object.assign([], serverFilterObj.filter(res => res.filterColumn !== 'any'))
              serverFilterObj = []
              temp.forEach(res => serverFilterObj.push(res))
              this.searchService.serverFilterObj = serverFilterObj;
              this.filters = Object.assign([], serverFilterObj)
              this.paginationData.pageNumber = this.neoTableSetting.pageNumber + 1
            }
          }
          if(!serverFilterObj) serverFilterObj = []
          if(searchData.filterValue !== ""){
            serverFilterObj.push(searchData)
            this.searchService.serverFilterObj = serverFilterObj;
            this.filters = Object.assign([], serverFilterObj)
            this.paginationData.pageNumber = this.neoTableSetting.pageNumber + 1
          } 
         

        } else {
          serverFilterObj = this.searchService.setServerFilterObj(filterObj);
          this.filters = Object.assign([], serverFilterObj);
        }
      } else {
        serverFilterObj = serverFilterObj.filter(res =>
          res.filterColumn == 'any'
        );
        if(serverFilterObj[0].filterValue !== ""){
          this.searchService.serverFilterObj = serverFilterObj;
          this.filters = Object.assign([], serverFilterObj);
          this.paginationData.pageNumber = this.neoTableSetting.pageNumber + 1
        }
      }
      this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
      this.getList(this.paginationData, this.orderingData, this.searchData);
      return serverFilterObj;
    } else {
      return null;
    }
  }
/*
  onNeoFilterChange(filterObj) {
    let searchData = new SearchData();
    searchData = filterObj;
    if (searchData.filterColumn) {
      let serverFilterObj = this.searchService.manageServerFilterObj([searchData]);
      ////console.log('Server final filtre obj', serverFilterObj);
    }
  }*/

  onAddClick() {
    this.router.navigate(['/customer-care/caseReason/add-caseReason']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/customer-care/caseReason/add-caseReason',rowData.reasonId]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  setDataFromLocalStorage() {
    this.entity = new CaseReasonModel();
   
    let caseReasonType = localStorage.getItem('caseReasonType');
   
    if (caseReasonType && caseReasonType != 'undefined') {
      this.entity.caseReasonType = caseReasonType
    }

  }

  getCaseReasonType(){
    this.commonListService.getGenericCommonList('caseReasonType').subscribe((res) => {
      this.caseReasonType = res.dataList
    });
  }


  setFilterObj(value, filterName?) {
    if (!this.filters || this.filters.length == 0)
      this.filters = new Array<filters>();
    else if (!value && filterName == 'caseReasonType')
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'caseReasonType')

    if (!this.searchService.serverFilterObj || this.searchService.serverFilterObj.length == 0)
      this.searchService.serverFilterObj = new Array<SearchData>();
    else if (!value && filterName == 'caseReasonType')
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'caseReasonType')
   
    if (this.entity && this.entity.caseReasonType) {
      localStorage.setItem('caseReasonType', this.entity.caseReasonType);
    }
    else {
      localStorage.removeItem('caseReasonType')
    }
  
    this.filtersObj = new filters();
    if (this.entity && this.entity.caseReasonType) {
      this.filtersObj.filterColumn = 'caseReasonType';
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = this.entity.caseReasonType;
      this.filters.push(this.filtersObj);
    }
    
    this.searchService.removeCaseSpecificStatusColumn();
    this.onFilterChange(this.filters);
  }
}

export class filters {
  filterColumn: string
  filterCondition: string
  filterDataType: string
  filterOperator: string
  filterValue: string
}