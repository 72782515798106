import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../services/ibase-service';
import { Property } from '../model/Property';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PropertyServiceService } from './service/property-service.service';
import { ActivatedRoute } from '@angular/router';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { CircuitServiceService } from '../circuit-details/service/circuit-service.service';
import { Circuit } from '../model/Circuit';
import { GenericContactDetailsComponent } from '../generic-contact-details/generic-contact-details.component';
import { SubscriberLeasedLineDetailsComponent } from '../subscriber-leased-line-details/subscriber-leased-line-details.component';
import { SubscriberLeasedServiceService } from '../subscriber-leased-line/service/subscriber-leased-service.service';
import { DataSharingServiceService } from '../../../../../src/app/pages/data-sharing-service.service';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { AddSubscriberDetailsService } from '../add-subscriber-details/add-subscriber-details.service';
import _ from 'underscore';
import { ServiceAreaServices } from '../../../services/service_area/service_area.service';
import { longitudeValidator } from '../add-subscriber-details/other-details/other-details.component';
import { latitudeValidator } from '../add-subscriber-details/other-details/other-details.component';
import { OpticalPowerList } from '../model/OpticalPowerList';
declare let $;
@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss']
})
export class PropertyDetailComponent extends AbstractBaseAddUpdateComponent<Property> implements OnInit {
  @ViewChild(SubscriberLeasedLineDetailsComponent, { static: false }) subscriberLeasedLineDetails: SubscriberLeasedLineDetailsComponent;
  @ViewChild(GenericContactDetailsComponent, { static: false })
  private genericContactDetails: GenericContactDetailsComponent;
  property = new Property();
  propertyForm: FormGroup;
  pid: any;
  isNameChecked: boolean = false;
  isPhoneChecked: boolean = false;
  neoTableSetting: any = {};
  allColumns: any[] = [];
  tablekeyEnum: any = TableKeyEnum;
  propertyId: number;
  propertyIds: any;
  connectionTypeList:any
  routerTypeList:any;
  bridgeTypeList:any
  opticalPower: { isMandatory: boolean; displayname:boolean }
  editPropertyId: any;
  qosPolicyTypeData: any;
  contactlist: any[] = [];
  req_message = 'This field is required';
  searchPincodeLink: string = '';
  addressMapArray:any;
  addressMapArray2:any;
  addressMapArray3:any;
  buildingList = [];
  parentId: number;
  parentpin: number
  areaAllList = [];
  default_pincode_value:string;
  serviceAreaList = [];
  propertyTypeList = [];
  getOpticalpowerlist:Array<OpticalPowerList>;
  dynamicrequiredArray: any;
  latitude:any;
  longitude:any;
  url:any;
  gis_code1:any;
  gis_code2:any;
  feasibilityDistance:any;
  unit:any;
  feasibilityDistanArray:any[]=[
    {
      text:'Km',
      value:'Km',
      
    },
    {
      text:'Mtr',
      value:'Mtr',
    }
  ];
  selectedValue:any=2;
  constructor(
    private toaster: ToastrService,
    private router: Router,
    public propertyservice: PropertyServiceService,
    public circuitService: CircuitServiceService,
    private activate: ActivatedRoute,
    private genericDataSharingService: DataSharingServiceService,
 

    private subscriberLeasedService: SubscriberLeasedServiceService,
    private tokenservice: TokenStorageService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private serviceAreaServices: ServiceAreaServices
  ) {
    super(toaster, router)
  }

  ngOnInit() {
    this.init();
    this.getAddressMapping();
    this.getPincode();
    this.getConnectiontypeList();
    this.getRequiredAllField();
    this.getRouterTypeList();
    this.getBridgeTypeList();
    this.getServiceAreaList();
    this.getpropertyTypeList();
    this.propertyForm.get('connectionType').patchValue(this.property.connectionType);
    
    this.getOpticalpowerList();
  }
  init() {
    this.propertyId = +this.activate.snapshot.paramMap.get('propertyId');
    let id = +this.activate.snapshot.paramMap.get('id');
    if (id) {
      ////console.log("id", id);
      this.isUpdateComponent = true;
      let catModal = this.getNewModal();
      catModal.id = id;
      this.propertyId = id;
      this.propertyIds = id;
      this.getById(id);
      this.setData(this.getNewModal());
      this.setValidation();
      this.setId();
      return;
    }
    this.authRole();
    this.setData(this.getNewModal());
    this.setValidation();
    this.setId();
    this.property.unit = this.feasibilityDistanArray[1].value;
    this.propertyForm.get('unit').setValue(this.property.unit);
  }
  setId() {
    this.property.subscriberLeasedLine = this.propertyId;
    localStorage.setItem("propertyId", this.propertyIds);
  }
  getService(): IBaseService<Property> {
    return this.propertyservice;
  }
  setData(data: Property) {
    //console.log('data5-->',data);
    if (data.techconname) {
      this.isNameChecked = true;
    }
    if (data.techconphone) {
      this.isPhoneChecked = true;
    }
    this.property = data;
    let temp:any={};
    if(this.property.propertyContactDetails){

      this.genericContactDetails.refreshDataSource(this.property.propertyContactDetails);
    }
    if(data && data.id){
      this.default_pincode_value = this.property.pincode.toString();
      let adata = {
        country:data.country,
        state:data.state,
        pincodeId:data.pincodeId,
        pincode:data.pincode,
        city:data.city,
        opticalPower:data.opticalPower,
        district:data.district,
        taluka:data.taluka,
        counstituency:data.constituency,
        countryId:data.countryId,
        stateId:data.stateId,
        connectionType:data.connectionType,
        routerType:data.routerType,
        approvedBy:data.approvedBy,
        reason:data.reason,
        bridgeType:data.bridgeType,
        cityId:data.cityId,
        districtId:data.districtId,
        talukaId:data.talukaId,
        counstituencyId:data.constituencyId
      }
      setTimeout(() => {
        this.propertyForm.patchValue(adata);
      }, 800);
      if(this.isUpdateComponent && this.property.feasibilityDistance != null){
        this.propertyForm.get('unit').setValidators([Validators.required]);
        this.propertyForm.get('unit').updateValueAndValidity();
      }
    }
  }
  getData(): Property {
    // console.log('property',this.property)
    return this.property;
  }
  getModuleHomeUrl() {
    return 'masters/subscriberLeased-property-detail/' + this.responseData.data.id
  }
  getActiveRoute() {
    this.activate;
  }
  getNewModal() {
    return new Property();
  }
  getFormGroup(): FormGroup {
    return this.propertyForm;
  }
  get f() {
    return this.propertyForm.controls;
  }
  onEditUrl() {
    this.editPropertyId = this.responseData.data.id;
    localStorage.setItem("editpropertyId", this.editPropertyId);
    this.router.navigate(['masters/subscriberLeased-property-detail/', this.responseData.data.id])
  }
  setValidation() {
    this.propertyForm = new FormGroup({
      propertyName: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      //technicalContactName: new FormControl('',),
      //technicalContactPhone: new FormControl(''),
      serviceareaId: new FormControl('',[Validators.required]),
    
      pincode: new FormControl(null),
      pincodeId: new FormControl(null),
      area: new FormControl(null),
      // temppincode: new FormControl(''),
      Country: new FormControl(null),
      State: new FormControl(null),
      City: new FormControl(null),
      District: new FormControl(null),
      Taluka: new FormControl(null),
      connectionType:new FormControl(''),
      routerType:new FormControl(''),
      approvedBy:new FormControl(''),
      reason:new FormControl(''),
      bridgeType:new FormControl(''),
      Constituency: new FormControl(null),
      block: new FormControl(null),
      building: new FormControl(null),
      countryId: new FormControl(null),
      stateId: new FormControl(null),
      cityId: new FormControl(null),
      districtId: new FormControl(null),
      talukaId: new FormControl(null),
      constituencyId: new FormControl(null),
      propertyType: new FormControl(null),
      landmark: new FormControl(null),
      opticalPower:new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      url: new FormControl(null,[Validators.minLength(1),Validators.maxLength(50)]),
      gis_code1: new FormControl(null,[Validators.minLength(1),Validators.maxLength(25)]),
      gis_code2: new FormControl(null,[Validators.minLength(1),Validators.maxLength(25)]),
      feasibilityDistance: new FormControl(null,Validators.maxLength(5)),
      unit: new FormControl(null)
    })
  }
  getId(): number {
    this.propertyservice.getByproperty(this.property.id);
    return this.property.id;
  }
  formSubmit() {
    if(this.genericContactDetails.TechnicalContactList)
    ////console.log('aaaa =>', this.genericContactDetails.TechnicalContactList);
    this.property.propertyContactDetails = this.genericContactDetails.TechnicalContactList;
    if (this.validateForm()) {
      //console.log('form',this.propertyForm)
      this.property.city = this.propertyForm.get('City').value;
      this.property.cityId = this.propertyForm.get('cityId').value;
      this.property.country = this.propertyForm.get('Country').value;
      this.property.countryId = this.propertyForm.get('countryId').value;
      this.property.connectionType=this.propertyForm.get('connectionType').value;
      this.property.approvedBy=this.propertyForm.get('approvedBy').value
      this.property.bridgeType=this.propertyForm.get('bridgeType').value
      this.property.reason=this.propertyForm.get('reason').value
      this.property.routerType=this.propertyForm.get('routerType').value
      this.property.state = this.propertyForm.get('State').value;
      this.property.stateId = this.propertyForm.get('stateId').value;
      this.property.taluka = this.propertyForm.get('Taluka').value;
     this.property.opticalPower=this.propertyForm.get('opticalPower').value
      this.property.talukaId = this.propertyForm.get('talukaId').value;
      this.property.district = this.propertyForm.get('District').value;
      this.property.districtId = this.propertyForm.get('districtId').value;
      this.property.constituency = this.propertyForm.get('Constituency').value;
      this.property.constituencyId = this.propertyForm.get('constituencyId').value;
      this.property.pincode = this.propertyForm.get('pincode').value;
      this.property.pincodeId = this.propertyForm.get('pincodeId').value;
      this.property.addressType = 'present';
      this.onSubmit();
    }else{
      this.toaster.error("Please Fill All Required Details!");
    }
  }

  onBack() {
    let id = localStorage.getItem('leasedId');
    this.router.navigate(['masters/suscriberLeased-details/', id]);
  }
  save(modal: Property): void {
    this.propertyservice.save(modal)
      .subscribe(
        (response) => {
          if (response.responseCode !== 200) {
            this.onResponseError(response.responseMessage);
            return;
          }
          //this.setData(response.data);
          this.responseData = response;
          this.onSavedCallback.emit(response.data);
          this.onSaveSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }
  delete(modal: Circuit) {
    this.circuitService
      .delete(modal)
      .subscribe(
        (response) => {
          if (response.responseCode != 200) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.toaster.success("Circuit deleted successfull.")
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31  && (charCode < 45 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // checkToSameAsNameNumber(eventData)
  // {
  //   if (eventData.target.checked) {
  //     let lieshedLine = localStorage.getItem("leasedId");
  //     this.propertyservice.getSubscriberById(lieshedLine).subscribe(res => {
  //       let data = res.data;
  //       this.leaslinedContactDetail.get('technicalContactName').setValue(data.techconname);
  //     })
  //   } else {
  //     this.propertyForm.get('technicalContactName').setValue('');
  //   }




  // }




  checkToSameAsName(eventData) {
  
    if (eventData.target.checked) {
      this.contactlist = [];
      let lieshedLine = localStorage.getItem("leasedId");
      this.propertyservice.getSubscriberById(lieshedLine).subscribe(res => {
        
        let data = res.data;
        //this.propertyForm.get('technicalContactName').setValue(data.techconname);
        let temp: any = {};
        //this.contactlist = this.customer.leaslinedContactDetail;
        if (res.data && res.data.leaslinedContactDetail.length > 0) {
          //myObj.map(({ id }) => id);
          // 
          
          res.data.leaslinedContactDetail.forEach((result) => {
            let temp = {
              techconname: null,
              techconphone: null,
              altnumber:null,

              };
            temp.techconname = result.techconname;
            temp.techconphone = result.techconphone;
            temp.altnumber=result.altnumber
            this.contactlist.push(temp)
          })
          // this.genericContactDetails.TechnicalContactList.forEach((res)=>{
          //   this.contactlist.push(res)
          // })
          this.genericContactDetails.refreshDataSource(this.contactlist);
        }
      })
    } else {
      //this.propertyForm.get('technicalContactName').setValue('');
     let temp = []
       this.genericContactDetails.refreshDataSource(temp);
    }
  }

  checkSameAsPhone(eventData) {
    if (eventData.target.checked) {
      let lieshedLine = localStorage.getItem("leasedId");
      this.propertyservice.getSubscriberById(lieshedLine).subscribe(res => {
        let data = res.data;
        //this.propertyForm.get('technicalContactPhone').setValue(data.techconphone);
      })
    } else {
      //this.propertyForm.get('technicalContactPhone').setValue('');
    }
  }

getGenericDataService(){
return this.genericDataSharingService
}

getAddressMapping(){
  let temp = this.tokenservice.getAddressLevel()
  this.addressMapArray = temp.filter(res=> res.name.toLowerCase()!='pincode' && res.name.toLowerCase()!='area' && res.name.toLowerCase()!='building')
  this.addressMapArray2 = temp.filter(res=> (res.name.toLowerCase() == 'area' ))
  this.addressMapArray3 = temp.filter(res=> (res.name.toLowerCase() == 'building' ))
  let tempn = temp.filter(res=> (res.name.toLowerCase() == 'pincode' ))
  this.parentpin = tempn[0].levelNo
  if(this.addressMapArray.find(res=> res.name.toLowerCase()=='district')){
    // this.propertyForm.get('District').setValidators([Validators.required]);
    this.propertyForm.get('District').updateValueAndValidity();
  }
  if(this.addressMapArray.find(res=> res.name.toLowerCase()=='taluka')){
    // this.propertyForm.get('Taluka').setValidators([Validators.required]);
    this.propertyForm.get('Taluka').updateValueAndValidity();
  }
  if(this.addressMapArray.find(res=> res.name.toLowerCase()=='constituency')){
    // this.propertyForm.get('Constituency').setValidators([Validators.required]);
    this.propertyForm.get('Constituency').updateValueAndValidity();
  }
}
onPincodeChange(elItem) {

  this.buildingList = [];
  let el;
  let call_from_autocomplete;
  if (_.isArray(elItem.event) == true) {
    el = _.find(elItem.event, (ev => ev.parentId === this.property.pincodeId));
  } else {
      this.propertyForm.get('area').reset();
      this.propertyForm.get('building').reset();
    el = elItem.event;
    call_from_autocomplete = elItem.call_from_autocomplete;
  }
  if (el ) {
    this.propertyForm.controls.pincodeId.setValue(el.addressDetails.pincodeId);
    this.propertyForm.controls.pincode.setValue(el.addressDetails.pincode);
    this.propertyForm.patchValue({ Country: el.addressDetails.country});
    this.propertyForm.patchValue({ State: el.addressDetails.state});
    this.propertyForm.patchValue({ City: el.addressDetails.city});
    this.propertyForm.patchValue({ District: el.addressDetails.district});
    this.propertyForm.patchValue({ Taluka: el.addressDetails.taluka});
    this.propertyForm.patchValue({ Constituency: el.addressDetails.constituency});
    this.propertyForm.patchValue({ countryId: el.addressDetails.countryId});
    this.propertyForm.patchValue({ stateId: el.addressDetails.stateId});
    this.propertyForm.patchValue({ cityId: el.addressDetails.cityId});
    this.propertyForm.patchValue({ districtId: el.addressDetails.districtId});
    this.propertyForm.patchValue({ talukaId: el.addressDetails.talukaId});
    this.propertyForm.patchValue({ constituencyId: el.addressDetails.constituencyId});
    for (const prop in this.propertyForm.controls) {
      this.propertyForm.value[prop] = this.propertyForm.controls[prop].value;
    }
    this.parentId = el.parentId;
    this.addSubscriberDetailsService.getDetailsByPincode(el.parentId).subscribe(res=>{
      this.areaAllList = res.dataList;
      let area
      if(this.property.areaId)
      {
        this.propertyForm.patchValue({ areaId: this.property.areaId});
        area = this.areaAllList.filter(res=>res.name == this.property.area)
        if(area && area.length == 1){
         
          this.buildingList = area[0].buildings
        }
      }
      if(area && area.length == 1){
        let build:any
        build = area[0].buildings.filter(res=>res.name == this.property.building)
        if(build.length == 1){
          this.propertyForm.patchValue({ building: build[0].id });
        }
      }
      else{
        this.propertyForm.patchValue({ building: null});
      }
    })
  }
  else if(el == null){
    this.propertyForm.get('Country').reset();
    this.propertyForm.get('countryId').reset();
    this.propertyForm.get('State').reset();
    this.propertyForm.get('stateId').reset();
    this.propertyForm.get('Taluka').reset();
    this.propertyForm.get('talukaId').reset();
    this.propertyForm.get('District').reset();
    this.propertyForm.get('districtId').reset();
    this.propertyForm.get('Constituency').reset();
    this.propertyForm.get('constituencyId').reset();
    this.propertyForm.get('City').reset();
    this.propertyForm.get('cityId').reset();
    this.propertyForm.get('area').reset();
    this.propertyForm.get('building').reset();
    this.areaAllList = [];
  }
  if(elItem.event == null){
    this.propertyForm.get('pincodeId').reset();
    this.propertyForm.get('pincode').reset();
  }
}

onChangeArea(event){
  this.buildingList = [];
  this.propertyForm.get('building').reset();
  this.propertyForm.get('building').updateValueAndValidity();
  if(event && event.buildings){
    this.buildingList = event.buildings
  }
}

getPincode() {
  this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
}

getServiceAreaList() {
  this.serviceAreaServices.getServiceAreaList().subscribe((result) => {
    this.serviceAreaList = result.dataList;
  });
}

getpropertyTypeList(){
  this.addSubscriberDetailsService.getCommonListPropertyType().subscribe((res)=>{
    this.propertyTypeList = res.dataList;
  })
}

getRequiredAllField() {
  
  this.propertyservice.getRequiredAllField('leasedline property').subscribe(result => {
     console.log("res===>",result)
     
    if (result.responseCode === 200) {
      this.dynamicrequiredArray = result.data;
      this.latitude = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_latitude'));
      this.longitude = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_longitude'));
      this.url = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_url'));
      this.opticalPower=this.dynamicrequiredArray.find((e=>e.fieldname==='lp_opticalPower')) 
      this.gis_code1 = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_gis_code1'));
      this.gis_code2= this.dynamicrequiredArray.find((e => e.fieldname === 'lp_gis_code2'));
      this.feasibilityDistance = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_feasibilityDistance'));
      //this.unit = this.dynamicrequiredArray.find((e => e.fieldname === 'unit'));

      if (this.latitude && this.latitude !== null && this.latitude.isMandatory === true) {
        this.propertyForm.get('latitude').setValidators([Validators.required, latitudeValidator()])
        this.propertyForm.get('latitude').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('latitude').clearValidators()
        this.propertyForm.get('latitude').updateValueAndValidity();
      }
      if (this.opticalPower && this.opticalPower !== null && this.opticalPower.isMandatory === true) {
        this.propertyForm.get('opticalPower').setValidators([Validators.required])
        this.propertyForm.get('opticalPower').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('opticalPower').clearValidators()
        this.propertyForm.get('opticalPower').updateValueAndValidity();
      }

      if (this.longitude && this.longitude !== null && this.longitude.isMandatory === true) {
        this.propertyForm.get('longitude').setValidators([Validators.required, longitudeValidator()])
        this.propertyForm.get('longitude').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('longitude').clearValidators()
        this.propertyForm.get('longitude').updateValueAndValidity();
      }

      if (this.url && this.url !== null && this.url.isMandatory === true) {
        this.propertyForm.get('url').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(50)])
        this.propertyForm.get('url').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('url').clearValidators()
        this.propertyForm.get('url').setValidators([Validators.minLength(1),Validators.maxLength(50)])
        this.propertyForm.get('url').updateValueAndValidity();
      }

      if (this.gis_code1 && this.gis_code1 !== null  && this.gis_code1.isMandatory === true) {
        this.propertyForm.get('gis_code1').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(25)])
        this.propertyForm.get('gis_code1').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('gis_code1').clearValidators();
        this.propertyForm.get('gis_code1').setValidators([Validators.minLength(1),Validators.maxLength(25)])
        this.propertyForm.get('gis_code1').updateValueAndValidity();
      }

      if (this.gis_code2 && this.gis_code2 !== null && this.gis_code2.isMandatory === true) {
        this.propertyForm.get('gis_code2').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(25)])
        this.propertyForm.get('gis_code2').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('gis_code2').clearValidators()
        this.propertyForm.get('gis_code2').setValidators([Validators.minLength(1),Validators.maxLength(25)])
        this.propertyForm.get('gis_code2').updateValueAndValidity();
      }

      if (this.feasibilityDistance && this.feasibilityDistance !== null && this.feasibilityDistance.isMandatory === true) {
        this.propertyForm.get('feasibilityDistance').setValidators([Validators.required,Validators.maxLength(5)]);
        this.propertyForm.get('feasibilityDistance').updateValueAndValidity();
        // this.propertyForm.get('unit').setValidators([Validators.required]);
        // this.propertyForm.get('unit').updateValueAndValidity();
      }
      else {
        this.propertyForm.get('feasibilityDistance').clearValidators();
        this.propertyForm.get('feasibilityDistance').setValidators([Validators.maxLength(5)]);
        this.propertyForm.get('feasibilityDistance').updateValueAndValidity();
        // this.propertyForm.get('unit').clearValidators();
        // this.propertyForm.get('unit').updateValueAndValidity();
      }

      // if (this.unit && this.unit !== null && this.unit.isMandatory === true) {
      //   this.propertyForm.get('unit').setValidators([Validators.required]);
      //   this.propertyForm.get('unit').updateValueAndValidity();
      // }
      // else {
      //   this.propertyForm.get('unit').clearValidators();
      //   this.propertyForm.get('unit').updateValueAndValidity();
      // }

    }
  })
}
 
onBlurFesDis(event){
  if(event && (event.target.value!= '')){
    this.propertyForm.get('unit').setValidators([Validators.required]);
    this.propertyForm.get('unit').updateValueAndValidity();
  }else{
    this.propertyForm.get('unit').clearValidators();
    this.propertyForm.get('unit').updateValueAndValidity();
  }
}

getConnectiontypeList(){
this.addSubscriberDetailsService.getConnectiontypeList('strconntype').subscribe((res)=>
this.connectionTypeList=res.dataList)
}
getRouterTypeList() {
  this.addSubscriberDetailsService.getRoutertypeList('routerType').subscribe((res) => {
    this.routerTypeList = res.dataList;
  });
}
getBridgeTypeList() {
  this.addSubscriberDetailsService.getRoutertypeList('bridgeType').subscribe((res) => {
    this.bridgeTypeList = res.dataList;
  });
}
connectionChange(event){
  this.propertyForm.get('routerType').reset();
  this.propertyForm.get('bridgeType').reset();

if (event.value == 'Router') {
  this.propertyForm.get('routerType').setValidators(Validators.required);
  this.propertyForm.get('routerType').updateValueAndValidity();
}
else if (event.value !== 'Router') {
  this.propertyForm.get('routerType').clearValidators();
  this.propertyForm.get('routerType').updateValueAndValidity();
}
if ((event.value == 'Free' || event === 'Free')) {
  this.propertyForm.get('approvedBy').setValidators(Validators.required);
  this.propertyForm.get('reason').setValidators(Validators.required);
  this.propertyForm.get('approvedBy').updateValueAndValidity();
  this.propertyForm.get('reason').updateValueAndValidity();
}
else if (event.value !== 'Free' || event !== 'Free') {
  this.propertyForm.get('approvedBy').reset();
  this.propertyForm.get('approvedBy').clearValidators();
  this.propertyForm.get('reason').reset();
  this.propertyForm.get('reason').clearValidators();
  this.propertyForm.get('approvedBy').updateValueAndValidity();
  this.propertyForm.get('reason').updateValueAndValidity();
}

if(event.value == 'Bridge/Converter') {
  this.propertyForm.get('bridgeType').setValidators(Validators.required);
  this.propertyForm.get('bridgeType').updateValueAndValidity();
} else{
  this.propertyForm.get('bridgeType').clearValidators();
  this.propertyForm.get('bridgeType').updateValueAndValidity();
}
}


getOpticalpowerList(){
  this.addSubscriberDetailsService.getOpticalpowerList().subscribe((res)=>{
    this.getOpticalpowerlist = res.dataList;
  })
}
 onCLearConnectionType() {
  this.propertyForm.get('routerType').reset();
    this.propertyForm.get('routerType').clearValidators();
    this.propertyForm.get('approvedBy').reset();
    this.propertyForm.get('approvedBy').clearValidators();
    this.propertyForm.get('reason').reset();
    this.propertyForm.get('reason').clearValidators();
    this.propertyForm.get('bridgeType').reset();
    this.propertyForm.get('bridgeType').clearValidators();
    this.propertyForm.get('routerType').updateValueAndValidity();
    this.propertyForm.get('approvedBy').updateValueAndValidity();
    this.propertyForm.get('reason').updateValueAndValidity();
  }
 }


