import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class CommunicationReportService {

  constructor(private http: HttpClient) { }

  getCommunication(commData): Observable<any> {
 
    const post_url = BASE_API_URL + 'report/communicationReport';
    return this.http.post<any>(post_url, commData)
  
  }

  getcommunicationfor():Observable<any>{
    const get_url=BASE_API_URL+'commonList/generic/communicationFor';
    return this.http.get<any>(get_url);
  }

  getcommunicationchannel():Observable<any>{
    const get_url=BASE_API_URL+'commonList/generic/communicationChannel';
    return this.http.get<any>(get_url);
  }

  getcommunicationstatus():Observable<any>{
    const get_url=BASE_API_URL+'commonList/generic/communicationStatus';
    return this.http.get<any>(get_url);
  }


  excelCommData(data) {
    const post_url = BASE_API_URL + "report/communicationReport/excel  ";
    return this.http.post<any>(post_url, data, { observe: 'body', responseType: 'blob' as 'json' });
  }

  PartnerlistService(): Observable<any> {
    const get_url = BASE_API_URL + "partner/all";
    return this.http.get<any>(get_url);
  }

  Customersbypartnerlist(): Observable<any> {
    const get_url = BASE_API_URL + "customers/byPartner";
    return this.http.get<any>(get_url);
  }

  staffuserByPartner(partnerid):Observable<any>{
    const get_url = BASE_API_URL + "staffuserByPartner/"+ partnerid;
    return this.http.get<any>(get_url);
  }
  
}
