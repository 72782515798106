import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../../../../services/base-service';
import { vasprovidermodel } from '../model/vasprovidermodel';

const baseApiUrl = '';
  const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class VasproviderserviceService extends BaseService<vasprovidermodel> {

  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return 'vasProviderConfig';
  }

  saveVASProvider(data){
    const post_url = baseApiUrl + 'vasProviderConfig/save'
    return this.http.post<any>(post_url,data);
  }

  updateVASProvider(data){
    const post_url = baseApiUrl + 'vasProviderConfig/update'
    return this.http.post<any>(post_url,data); 
  }

  getVASProviderByType(name){
    const get_url = baseApiUrl + 'vasProvider/getVASProviderByType/'+ name;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers});
  }

  constructor(http: HttpClient) {
    super(http);
  }
}
