import {Aclsaveoperationlist} from '@neoboss/pages/generic-component/acl/acl-gerneric-component/model/aclsaveoperationlist';

export class AclSave {

    aclEntryPojoList: Array<Aclsaveoperationlist>;
    id:number;
    rolename: string;
    status: string;
    sysRole:boolean;
    isAllOperation:boolean;
}
