import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class CustomerDocumentService {
  constructor(private http: HttpClient) {

  }

  getCustomerDocumentByCustId(custId:any): Observable<any> {
    const get_url = BASE_API_URL + 'custDoc/getDocsByCustomer/' + custId;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getCustomerDocumentById(Id:any): Observable<any> {
    const get_url = BASE_API_URL + 'custDoc/' + Id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getCustomerDocumentPDF(): Observable<any> {
    const get_url = BASE_API_URL + 'custDoc/pdf';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getCustomerDocumentExcel(): Observable<any> {
    const get_url = BASE_API_URL + 'custDoc/excel';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertCustomerDocument(data : any) : Observable<any> {
    const post_url = BASE_API_URL + 'custDoc/save';
    return this.http.post<any>(post_url,data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  
  updateCustomerDocument(data : any) : Observable<any> {
    const post_url = BASE_API_URL + 'custDoc/update';
    return this.http.post<any>(post_url,data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  
  deleteCustomerDocument(data : any) : Observable<any> {
    const post_url = BASE_API_URL + 'custDoc/delete';
    return this.http.post<any>(post_url,data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  saveCustomerDocument(data:any): Observable<any> {
    const post_url = BASE_API_URL+'custDoc/uploadDoc';
    return this.http.post<any>(post_url,data).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }



}
