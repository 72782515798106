import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { Slot } from '../model/slot.model';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class SlotService {
  constructor(private http: HttpClient) {}

  transformSlotData(data) {
    let formatedData = [];
    data.locationList.forEach(function(item:any){
      let itemData:any = {};
      formatedData.push(itemData);
    });
    return {content:formatedData};
  }

  getslotDataWithPageing(fetch_setting_data, network_devices_id : any): Observable<any> {
    let page = fetch_setting_data.page;
    let size = fetch_setting_data.size;
    let params = new HttpParams().set("page", page).set("size", size);
    const get_url = baseApiUrl + '';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers, params: params }).pipe(
        map((res: any) => {
          return this.transformSlotData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getSlotData(): Observable<any> {
    const get_url = baseApiUrl + 'OLTSlot';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map(res => {
          res as Slot[]
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getSlotDataById(slot_id): Observable<any> {
    const get_url = baseApiUrl + 'OLTSlot/' + slot_id;
    return this.http.get(get_url).pipe(map(res => res as Slot[]));
  }
  
  getSlotDataByNetworkDeviceId(network_id): Observable<any> {
    const get_url = baseApiUrl + 'OLTSlot/byNetworkId/' + network_id;
    return this.http.get(get_url).pipe(map(res => res as Slot[]));
  }

  insertSlot(slot_data: any): Observable<any> {
    const post_url = baseApiUrl + 'OLTSlot/save';
    ////console.log('Slot ::- ' , post_url)
    return this.http.post<any>(post_url, slot_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updatSlot(slot_data: any): Observable<any> {
    const update_url = baseApiUrl + 'OLTSlot/update'
    return this.http.post<any>(update_url, slot_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  deleteSlot(slot_id: any): Observable<any> {
    const delete_url = baseApiUrl + 'OLTSlot/delete';
    return this.http.post(delete_url,slot_id,httpOptions);
  }

}
