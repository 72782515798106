import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { textChangeRangeIsUnchanged } from 'typescript';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { ServiceAreaService } from '../../../network-management/service-area/service/service-area.service';
import { ServiceService } from './service.service';
import { StaffAvailableModel } from './staff-available-model';

@Component({
  selector: 'app-staff-available',
  templateUrl: './staff-available.component.html',
  styleUrls: ['./staff-available.component.scss']
})
export class StaffAvailableComponent extends AbstractBaseListComponent<StaffAvailableModel> implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  dataGrid: StaffAvailableModel[] = [];
  isUpdateComponent:boolean = false;
  tableKeyEnum = TableKeyEnum;
  // config for neo-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  public entity: StaffAvailableModel = new StaffAvailableModel();
  public entity_id: any = null;
  form: FormGroup;
  staffId: Number;

  constructor(
    private entityService : ServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private ngbModalService:NgbModal,
    public datePipe:DatePipe,
    loginService: LoginService) {
      super(toastrService,searchService);
      this.loginService = loginService;
    }

  ngOnInit() {

 
    this.setDefaultPageSize(this.tableKeyEnum.STAFF_AVAILABE);
    let id = this.route.snapshot.paramMap.get('event');
    if(id){
      
      this.entity.staffId =  Number(id);
      this.entityService.staffId = Number(id);
      this.staffId = Number(id);
    }
    this.init()

    // let param 
    // param['page'] = this.paginationData?this.paginationData.pageNumber.toString():'1'
    // param['pageSize'] =this.paginationData?this.paginationData.rowsPerPage.toString():'10'
    // param['sortBy'] = 'id'
    // param['sortOrder'] = 0
    // param['id']= this.entity.staffId
    //  this.entityService.getStaffAvailability(param).subscribe((res)=>{
    //   //console.log(res);
      
    // })
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
     
    });
  }

  getService():IBaseService<any>{
    return this.entityService
  }

  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'fromDateStr',
        default: 'From Date',
        rowKey: 'fromDateStr',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'toDateStr',
        default: 'To Date',
        rowKey: 'toDateStr',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'remark',
        default: 'Remark',
        rowKey: 'remark',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'Last Modified By',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns = allColumns;
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return allColumns ? allColumns : [];
  }

  getTableSetting() {
    let staffAvailablelistsetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter:false,
      showPagination: true,
      tableId: this.tableKeyEnum.STAFF_AVAILABE,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Service Area ?',
      deleteMsgBoxDetail: 'Service Area Name :${name} ',
    };
    return staffAvailablelistsetting;
  }

  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  // onEditClick(event) {
  //   ////console.log(event.RowData)
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    ////console.log(event);
    
  }

  onDeleteClick(event) {
    
  }
  
  backToViewList(){
    this.router.navigate(['/hr/staff'])
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  setList(list: Array<StaffAvailableModel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  onEdit(event,staff){
    this.isUpdateComponent = true;
    this.entity = event;
    this.ngbModalService.open(staff, { size: 'lg' });
  }
  onDelete(event){
    
    this.entityService.deleteStaffAvailable(event).subscribe((res)=>{
      if(res.responseCode!== 200){
        this.toastrService.error(res.responseMessage)
      }
      else{
        this.toastrService.success('Deleted Successfully');
        this.init();
      }
    })
  }
  OnUpdate(event){
    if( this.entity && this.entity.fromDate && this.entity.toDate){
      this.entity.fromDate  =  this.datePipe.transform(this.entity.fromDate,'yyy-MM-dd');
      this.entity.toDate  =  this.datePipe.transform(this.entity.toDate,'yyy-MM-dd');
    }
    else{
      this.toastrService.error('Please Enter From Or To date');
      return
    }
    if(this.entity.fromDate > this.entity.toDate ){
      this.toastrService.error('Start date should be less then End date.');
      return
    }
   if(!this.entity.remark){
    this.toastrService.error('Please enter remark');
    return
   }
    this.entityService.editStaffAvailable(this.entity).subscribe((res)=>{
      if(res.responseCode !== 200){
        this.toastrService.error(res.responseMessage)
      }
      else{
        this.toastrService.success('Updated Successfully');
        this.cancelmodal();
        this.init();
       
      }
    })

  }
  onAddClick(staff) {
    this.entity = new StaffAvailableModel();
    this.entity.staffId = Number(this.staffId)
    this.isUpdateComponent = false;
    this.ngbModalService.open(staff, { size: 'lg' });
  }


  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  cancelmodal(){
    this.ngbModalService.dismissAll();
  }
  Onsave(){
    if( this.entity && this.entity.fromDate && this.entity.toDate){
      this.entity.fromDate  =  this.datePipe.transform(this.entity.fromDate,'yyy-MM-dd');
      this.entity.toDate  =  this.datePipe.transform(this.entity.toDate,'yyy-MM-dd');
    }
    else{
      this.toastrService.error('Please Enter From Or To date');
      return
    }
    if(this.entity.fromDate > this.entity.toDate ){
      this.toastrService.error('Start date should be less then End date.');
      return
    }
   if(!this.entity.remark){
    this.toastrService.error('Please enter remark');
    return
   }
    this.entityService.saveStaffAvailable(this.entity).subscribe((res)=>{
      if(res.responseCode !== 200){
        this.toastrService.error(res.responseMessage);
      }
      else{
        this.toastrService.success('Saved Successfully');
       this.init();
       this.cancelmodal();
      }

    })
  }
}
