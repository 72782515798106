import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomModalService } from '../../../../components/custom-modal';
import { ApplyChargeService } from '../apply-charge/apply-charge.service';
import { RecordPaymentService } from '../record-payment/record-payment.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { PurchaseIpService } from './purchase-ip.service';
import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ChangePlanService } from '../change-plan/change-plan.service';
import { AddBalance } from '../../add-franchies/model/add-balance';
@Component({
  selector: 'app-purchase-ip',
  templateUrl: './purchase-ip.component.html',
  styleUrls: ['./purchase-ip.component.scss']
})
export class PurchaseIpComponent implements OnInit {
  @Input() custId: any;
  @Input() ui_id: string;
  @Input() bal:number;
  @ViewChild('txtActivationDate', { static: true }) txtActivationDate: ElementRef;
  @ViewChild('txtEndDate', { static: true }) txtEndDate: ElementRef;
  submitted: any = null;
  paymentModeList: any[] = [];
  paymentDate: any = null;
  paymentMode: any = null;
  refrenceNO: any = ""
  chargeAmount: any = "";
  selected_tds_deducted: any;
  taxAmount: any = "";
  finalAmount: number;
  selected_payment_mode_value: any = '';
  tdsPendingPaymentsList: any;
  tdsAmountValid: boolean;
  maxamount:any;
  minamount:any;
  isIncludeTaxflag:boolean=false;
  selectedtaxid:any;
  isOverhead: boolean = false;
  isPriceOverrideCharge:boolean=false;
  isPriceOverrideHideshow:boolean=false;
  hideshowField:boolean=false;
  oldtaxamount:any;
  oldprice:any;
  collectorList: []
  collectorFlag:boolean = true;
  chargeList: any[] = [
    {
      name: '',
      id: '',
    }
  ];

  validityTypeList: any[] = [
    {
      text: 'Month',
      value: 'Month',
    },
    {
      text: 'Year',
      value: 'Year',
    },
    {
      text: 'Day',
      value: 'Day',
    }
  ];
  insertPurchaseIpForm = new FormGroup({
    charge_id: new FormControl(null, [Validators.required]),
    validity_type: new FormControl(null, [Validators.required]),
    validity: new FormControl(null, [Validators.required]),
    activationDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl({ value: null, disabled: true }, [Validators.required]),
    remarks: new FormControl('', [Validators.required]),
    isUnlimited: new FormControl(false),
    isPaymentReceived: new FormControl('no', [Validators.required]),
    //collectorId: new FormControl(null),
    isBalanceUsed: new FormControl(false),
    balanceAmount:new FormControl(null),
    recordPaymentDTO: new FormGroup({
      amount: new FormControl(''),
      payment_date: new FormControl(''),
      payment_mode: new FormControl(''),
      referenceNo: new FormControl(''),
      chequeNo: new FormControl(''),
      bankName: new FormControl(''),
      chequeDate: new FormControl(''),
      branch: new FormControl(''),
      tdsDeducted: new FormControl(false),
      tdsAmount: new FormControl(''),
      remarks: new FormControl(''),
      credit_doc_id: new FormControl(''),
    }),
    overridePrice:new FormControl(null),
    isOverride :new FormControl(false),
    isTaxInclude:new FormControl(false),

  })
  constructor(
    private customModalService: CustomModalService,
    private applyChargeService: ApplyChargeService,
    private purchaseIpService: PurchaseIpService,
    private planService: PlanServices,
    private tosterservice: ToastrService,
    private datepipe: DatePipe,
    private recordPaymentService: RecordPaymentService,
    private changePlanService:ChangePlanService
  ) {

  }

  ngOnInit() {
    this.getStartDateEndDate();
    this.getChargeByCategoryIp();
    this.getPaymentModeListFromGenericApi();
    this.getPaymentCollectorList(this.custId);
  }
  getPaymentCollectorList(custid){
    this.applyChargeService.getPaymentCollectorList(custid).subscribe(
      result => {
        this.collectorList = result.dataList;
      }
    )
  }

  checkamount(){
    let oldfinalpay = this.chargeAmount + this.taxAmount;
    let amount = this.insertPurchaseIpForm.get('recordPaymentDTO').get('amount').value;
    if(this.bal >= amount && this.insertPurchaseIpForm.get('isBalanceUsed').value == true){
      if(amount == oldfinalpay || amount == 0){
        this.collectorFlag = false;
        //this.insertPurchaseIpForm.get('collectorId').reset();
        this.insertPurchaseIpForm.get('balanceAmount').setValue(this.bal);
      }else{
          this.collectorFlag = true;
          this.insertPurchaseIpForm.get('balanceAmount').setValue(amount);
      }
    }else{
        this.collectorFlag = true;
      }
  }

  oncheckUseBalance(event){
    let oldfinalpay = this.chargeAmount + this.taxAmount;
    let amount = this.insertPurchaseIpForm.get('recordPaymentDTO').get('amount').value;
    if(event && event.target.checked == true){
      if(this.bal >= oldfinalpay && amount >= oldfinalpay){
        this.collectorFlag = false;
        this.finalAmount = 0;
        this.insertPurchaseIpForm.get('balanceAmount').setValue(oldfinalpay);
      }else{
        this.collectorFlag = true
        this.finalAmount = oldfinalpay - this.bal;
        this.insertPurchaseIpForm.get('balanceAmount').setValue(this.bal);
      }
    }else{
      this.collectorFlag = true
      this.finalAmount = oldfinalpay
      this.insertPurchaseIpForm.get('balanceAmount').reset();
    }
  }

  getStartDateEndDate() {
    let newdate = new Date();
    var currentDate = this.datepipe.transform(newdate, 'yyyy-MM-dd');
    this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_date').setValue(currentDate);
    this.insertPurchaseIpForm.patchValue({ "activationDate": currentDate});
  }
  onChangeTdsDeducted(el) {
    let is_tds_deducted = el;
    this.selected_tds_deducted = is_tds_deducted;
  }
  getPaymentModeListFromGenericApi() {
    this.applyChargeService.getPaymentModeListFromGenericApi().subscribe(result => {
      this.paymentModeList = result.dataList;
    });
  }
  getChargeByCategoryIp() {
    if (this.ui_id === 'value-added-charge') {
      this.purchaseIpService.getChargeVAS().subscribe(
        result => {
          this.chargeList = result.chargelist;
        }
      )
    }
    if (this.ui_id === 'purchase-ip') {
      this.purchaseIpService.getChargeByCategoryIp().subscribe(
        result => {
          this.chargeList = result.chargelist;
        }
      )
    }

  }
  onChargeChange(el) {
   this.insertPurchaseIpForm.get('isPaymentReceived').setValue('no');
   this.insertPurchaseIpForm.get('balanceAmount').reset();
   this.minamount= el.minamount;
   this.maxamount=el.maxamount;
   this.selectedtaxid=el.taxid;
   this.oldtaxamount=el.taxamount;
   this.oldprice=el.price;
   

   if(el.isPriceOverride===true){
    this.hideshowField=true;
     this.isOverhead=true;
     this.isPriceOverrideHideshow=true;
     this.insertPurchaseIpForm.get('overridePrice').setValidators([Validators.required])
    }else{
      this.hideshowField=false;
      this.isOverhead=false;
      this.isPriceOverrideHideshow=false;
      this.insertPurchaseIpForm.get('overridePrice').clearValidators();
      this.insertPurchaseIpForm.get('overridePrice').updateValueAndValidity();
    }

    if(el.isIncludeTax===true){
      this.isIncludeTaxflag=true;
    }else{
      this.isIncludeTaxflag=true;
    }
   
   if (el) {
      let data: any = {
        "chargeId": el.id,
        "custId": parseInt(this.custId)
      };
      this.planService.getTaxDetailsByCharge(data).subscribe((res) => {
        this.taxAmount = +res.TotalAmount;
        this.chargeAmount = +el.actualprice;
        this.finalAmount = Number(this.chargeAmount) + Number(this.taxAmount);
        ////console.log(this.taxAmount);
      });
      
    } else {
      this.taxAmount = "";
      this.chargeAmount = "";
    }
  }

  onIsUnlimited(el) {
    ////console.log(el);
    let isChecked = el.target.checked;
    const validity_ctrl = this.insertPurchaseIpForm.get('validity');
    if (isChecked === true) {
      validity_ctrl.disable();
      this.insertPurchaseIpForm.patchValue({ validity: 0, endDate: null });
    } else if (isChecked === false) {
      validity_ctrl.enable();
      this.insertPurchaseIpForm.patchValue({ validity: null, endDate: null });
    }
    this.updateEndDate()
  }
  onChangePaymentMode(el) {
    if (el) {
      let payment_mode_value = el.value;
      this.selected_payment_mode_value = payment_mode_value;
      if (this.selected_payment_mode_value === 'TDS') {
        this.recordPaymentService.getTdsPendingPayments(this.custId).subscribe(result => {
          this.tdsPendingPaymentsList = result.dataList;
        });
      }
    } else {
      this.selected_payment_mode_value = '';
    }
  }
  onPaymentTypeChange(event) {
    //this.insertPurchaseIpForm.get('isBalanceUsed').reset();
    if (event === 'YES') {
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('amount').updateValueAndValidity();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_date').setValidators(Validators.required);
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_mode').setValidators(Validators.required);
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('referenceNo').setValidators(Validators.required);
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
    } else {
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('amount').clearValidators();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('amount').updateValueAndValidity();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_date').clearValidators();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_mode').clearValidators();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('referenceNo').clearValidators();
      this.insertPurchaseIpForm.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
    }

  }
  onChangeValidityType(el) {
    this.updateEndDate();
  }

  onValidityChange() {
    this.updateEndDate();
  }

  updateEndDate() {
    let formData = Object.assign({}, this.insertPurchaseIpForm.value);
    if (formData.activationDate) {
      let activationDate = new Date(this.datepipe.transform(formData.activationDate));
      let validity = formData.validity;
      let validity_type = formData.validity_type;
      if (validity_type && validity && activationDate) {
        let endDate = null;
        if (validity_type === 'Month') {
          endDate = activationDate.setMonth(activationDate.getMonth() + parseInt(validity));
        }
        else if(validity_type === 'Day'){
          endDate = activationDate.setDate(activationDate.getDate() + parseInt(validity));
        }
         else {
          let totalMonth = parseInt(validity) * 12;
          endDate = activationDate.setMonth(activationDate.getMonth() + totalMonth);
        }
        // this.insertPurchaseIpForm.get('endDate').setValue(endDate);
        this.insertPurchaseIpForm.patchValue({ endDate: new Date(endDate) });
      }
    }
  }

  onChangeActivationDate(el) {
    this.updateEndDate();
    ////console.log(el);
  }

  onClickSavePurchaseIpForm() {
    if (this.insertPurchaseIpForm.valid === true) {
      this.submitted = false;
      let self = this;
      if (this.ui_id === 'value-added-charge') {
        alertify.confirm("Purchase Value Added Charge", "Are You Sure You want to Purchase Value Added Charge ?",
          function () {
            self.saveApiCall();
          },
          function () {

          });
      }
      if (this.ui_id === 'purchase-ip') {
        alertify.confirm("Purchase IP Address", "Are You Sure You want to Purchase IP Address ?",
          function () {
            self.saveApiCall();
          },
          function () {

          });
      }

    } else {
      this.submitted = true;
      this.insertPurchaseIpForm.markAllAsTouched();
    }
  }

  getFormatedData(dateText) {
    let dateArray = dateText.split("/");
    let date = dateArray[1];
    if (date < 10) {
      date = "0" + date;
    }
    let month = _.first(dateArray);
    if (month < 10) {
      month = "0" + month;
    }
    let formatedDate = _.last(dateArray) + "-" + date + "-" + month;
    return formatedDate;
  }
  saveApiCall() {
    let apiInputData = this.insertPurchaseIpForm.value;
       
    let formatedData = this.transformApiIinputData(apiInputData);
    // formatedData.custId = parseInt(this.custId);
    // formatedData.charge_id =  apiInputData.charge_id;
    // let selectedChargeData = _.find(this.chargeList,{id:apiInputData.charge_id});
    // formatedData.charge_name =  selectedChargeData.name;
    // formatedData.validity_type =  this.validity_type;
    // formatedData.validity =  apiInputData.validity;
    // formatedData.startdate =  this.getFormatedData(this.txtActivationDate.nativeElement.value);
    // formatedData.isUnlimited = apiInputData.isUnlimited;
    // if (apiInputData.isUnlimited === true) {
    //  formatedData.enddate = null
    // } else {
    //  formatedData.enddate =  this.getFormatedData(this.txtEndDate.nativeElement.value);
    // }
    // formatedData.remarks =  apiInputData.remarks;
    // formatedData.taxamount =  0;


    this.purchaseIpService.savePurchaseIp(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          if (this.ui_id === 'purchase-ip') {
            this.tosterservice.success('Purchase Ip Address Details Save Successfully.');
            this.customModalService.close('purchase-ip', result);
          }
          if (this.ui_id === 'value-added-charge') {
            this.tosterservice.success('Purchase Value Added Charge Save Successfully.');
            this.customModalService.close('value-added-charge', result);
          }
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }
  transformApiIinputData(data) {
    let apiInputData = data;
    let recordPaymentDTO: any = {};
    let formatedData: any = {};
    formatedData.custId = parseInt(this.custId);
    formatedData.charge_id = apiInputData.charge_id;
    let selectedChargeData = _.find(this.chargeList, { id: apiInputData.charge_id });
    formatedData.charge_name = selectedChargeData.name;
    formatedData.startdate = this.getFormatedData(this.txtActivationDate.nativeElement.value);
    formatedData.isUnlimited = apiInputData.isUnlimited;
    if (apiInputData.isUnlimited === true) {
      formatedData.enddate = null
    } else {
      formatedData.enddate = this.getFormatedData(this.txtEndDate.nativeElement.value);
    }
    formatedData.remarks = apiInputData.remarks;
    formatedData.taxamount = 0;
    formatedData.isPaymentReceived = false;

    formatedData.overridePrice=apiInputData.overridePrice;
    formatedData.isOverride=apiInputData.isOverride;
    formatedData.isTaxInclude=apiInputData.isTaxInclude;
    //formatedData.collectorId=apiInputData.collectorId
    // recordPaymentDTO.isBalanceUsed = apiInputData.isBalanceUsed;
    // if(recordPaymentDTO.isBalanceUsed == true){
    //   recordPaymentDTO.balanceAmount = apiInputData.balanceAmount;
    // }
    if (apiInputData.isPaymentReceived && apiInputData.isPaymentReceived === 'yes') {
      formatedData.isPaymentReceived = true;
      recordPaymentDTO.paymentAmount = apiInputData.recordPaymentDTO.amount;
      recordPaymentDTO.paymentDate = this.datepipe.transform(apiInputData.recordPaymentDTO.payment_date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
      recordPaymentDTO.referenceNo = apiInputData.recordPaymentDTO.referenceNo;
      recordPaymentDTO.paymentMode = apiInputData.recordPaymentDTO.payment_mode;
      recordPaymentDTO.isTdsDeducted = apiInputData.recordPaymentDTO.tdsDeducted;
      recordPaymentDTO.remarks = apiInputData.remarks;
      recordPaymentDTO.custId = this.custId;
      if (apiInputData.recordPaymentDTO.tdsDeducted === true) {
        recordPaymentDTO.tdsAmount = apiInputData.recordPaymentDTO.tdsAmount;
      }
      if (recordPaymentDTO.paymentMode === 'cheque') {
        recordPaymentDTO.bankName = apiInputData.recordPaymentDTO.bankName;
        recordPaymentDTO.branch = apiInputData.recordPaymentDTO.branch;
        recordPaymentDTO.chequeDate = apiInputData.recordPaymentDTO.chequeDate;
        recordPaymentDTO.chequeNo = apiInputData.recordPaymentDTO.chequeNo;
      }
      formatedData.recordPaymentDTO = recordPaymentDTO;
    }
    return formatedData;
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.submitted = false;
    this.insertPurchaseIpForm.reset();
  }
  ClearField(){
    this.insertPurchaseIpForm.reset();
    this.insertPurchaseIpForm.get('isPaymentReceived').setValue('no');
    this.isOverhead=false;
    this.isPriceOverrideHideshow=false;
    
    this.chargeAmount="";
    this.taxAmount="";
  }


  isIncludeTaxChange(e){
    

    if(e==="true"){
     this.isIncludeTaxflag=true;
     this.insertPurchaseIpForm.get('overridePrice').updateValueAndValidity();
     let currentValue= this.insertPurchaseIpForm.get('overridePrice').value;
     
     if((this.minamount !== null) && (this.maxamount !==null) && currentValue !== null && this.isIncludeTaxflag===true){
         this.OnCheckAPICallTaxCalculation();
         }
         else
         {
         this.tosterservice.error("Select Override Price");
         this.insertPurchaseIpForm.get('overridePrice').setErrors({'incorrect': true});
       }
     }else{
       let currentValue= this.insertPurchaseIpForm.get('overridePrice').value;
        if((this.minamount !== null) && (this.maxamount !==null) && currentValue !== null &&  this.isIncludeTaxflag===false){
         this.onOverridePrice();
       } else{
         
        this.tosterservice.error("Select Override Price");
        this.insertPurchaseIpForm.get('overridePrice').setValidators([Validators.required])
       }
              
     }
   }


   onOverridePriceBlur(){
    //this.insertPurchaseIpForm.get('isBalanceUsed').reset();
    if(this.insertPurchaseIpForm.get('overridePrice').value !==null && this.isIncludeTaxflag===false){
      this.onOverridePrice();
    }else{
      this.OnCheckAPICallTaxCalculation();
    }
   }

    OnCheckAPICallTaxCalculation(){
      
      let AmountValue=this.insertPurchaseIpForm.get('overridePrice').value;
      let obj = {
          amount: AmountValue,
          taxId: this.selectedtaxid,
      };
      this.applyChargeService.getTaxByAmountOnChecked(obj).subscribe((res)=>{
        if(res.status===200){
          
          this.chargeAmount=res.ReverseTaxAmount;
          this.taxAmount= Number(AmountValue)-res.ReverseTaxAmount;
          this.finalAmount= Number(this.chargeAmount)+Number(this.taxAmount);

          if(this.finalAmount < this.minamount && this.minamount !==null && this.insertPurchaseIpForm.get('overridePrice').value != null){
            this.tosterservice.error("Price Override should not less than Min Amount  "  +this.minamount);
            this.insertPurchaseIpForm.get('overridePrice').setErrors({'incorrect': true});
            return;
         }else if(this.finalAmount > this.maxamount && this.maxamount !==null && this.insertPurchaseIpForm.get('overridePrice').value != null)
         {
          this.tosterservice.error("Price Override should not be greater than Max Amount  "+ this.maxamount);
          this.insertPurchaseIpForm.get('overridePrice').setErrors({'incorrect': true});
          return;
         }
        }        
          })

    }


  onOverridePrice() {
    
    let AmountValue=this.insertPurchaseIpForm.get('overridePrice').value;
    let obj = {
        amount: AmountValue,
        taxId: this.selectedtaxid,
    };

  this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
  if(res.status===200){
    let latestFinalAmount=this.insertPurchaseIpForm.get('overridePrice').value;
    
       
    if(this.isIncludeTaxflag===false){
      this.chargeAmount=latestFinalAmount;
      this.taxAmount=Number(res.TaxAmount); 
      this.finalAmount= Number(latestFinalAmount) +Number(res.TaxAmount);
    }
     
   
    if( this.finalAmount <= this.minamount && this.minamount !==null && this.insertPurchaseIpForm.get('overridePrice').value != null){
        this.tosterservice.error("Price Override should not less than Min Amount  "  +this.minamount);
        this.insertPurchaseIpForm.get('overridePrice').setErrors({'incorrect': true});
        return;
     }else if( this.finalAmount >= this.maxamount && this.maxamount !==null && this.insertPurchaseIpForm.get('overridePrice').value != null)
     {
      this.tosterservice.error("Price Override should not be greater than Max Amount  "+ this.maxamount);
      this.insertPurchaseIpForm.get('overridePrice').setErrors({'incorrect': true});
      return;
     }
    }
  });
 }

checkOverHead(event: any) {
  
  if (event === 'true') {
     this.isOverhead=true;
     this.isPriceOverrideHideshow=true;
     this.insertPurchaseIpForm.get('isOverride').setValue(true);
     this.insertPurchaseIpForm.get('overridePrice').setValidators(Validators.required)
     } else { 
     this.isOverhead=false;
     this.isPriceOverrideHideshow=false;
     this.insertPurchaseIpForm.get('isOverride').setValue(false);
     this.insertPurchaseIpForm.get('overridePrice').reset();
     this.taxAmount =this.oldtaxamount;
     this.chargeAmount=this.oldprice;
     this.finalAmount=this.chargeAmount+this.taxAmount;
     this.insertPurchaseIpForm.get('overridePrice').clearValidators();
  }
}

}
