import { Injectable } from '@angular/core';
import { userInfo } from 'os';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class DataSharingServiceService {
sharingData =new Subject();
employeename:any;
lastAuditUrl:string;
filterStatusName: string;
constructor() { }

/**Default*/
public newUser = new BehaviorSubject<any>({
  // employename:, 
  // employeeRefId: 'test@com',
  // g: 'M'
});

 setInfo(user: any) {
  this.newUser.next(user);
 }

 setSubscriberStatus(status){
  this.filterStatusName = status;
 }
 resetSubscriberStatus(){
  this.filterStatusName = undefined;
 }

 getInfo() {
    return this.newUser.asObservable();
  }

}
