import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { CustomerPasswordService } from './customer-password.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-cpe-password',
  templateUrl: './cpe-password.component.html',
  styleUrls: ['./cpe-password.component.scss']
})
export class CpePasswordComponent implements OnInit {
  @Input() custId: any;
  remarks: any = "";
  customerUpdatePasswordForm = new FormGroup({
    newpassword: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required])
  })
  customerDetails: any;
  customerName: any;

  constructor(
    private customModalService: CustomModalService,
    private customerPasswordService: CustomerPasswordService,
    private tosterservice: ToastrService

  ) { }

  ngOnInit() {
    this.getBasicDetails();
  }

  getBasicDetails() {
    this.customerPasswordService.getBasicCustDetails(this.custId).subscribe(result => {
      this.customerDetails = result.data;
      this.customerName = this.customerDetails.username;
    })
  }
  submitted: boolean;
  onClickSaveForm() {
    if (this.customerUpdatePasswordForm.valid === true) {
      let self = this;
      alertify.confirm("Change CPE Password", "Are You Sure You want to Change CPE Password ?",
        function () {
          self.updateCPEPasswordOnDb();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.customerUpdatePasswordForm.markAllAsTouched();
    }
  }

  updateCPEPasswordOnDb() {
    let apiInputData = Object.assign({}, this.customerUpdatePasswordForm.value);
    apiInputData.custId = this.custId;
    this.customerPasswordService.updateCustPassword(apiInputData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('CPE Password update Successfully.');
          this.customModalService.close('cpe-password', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id);
    this.submitted = false;
  }

}
