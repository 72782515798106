import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../services/base-service';
import { Property } from '../../model/Property';
import { Observable } from 'rxjs';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { RequestData } from '../../../../core/modal/base-save-update-modal';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { SearchData } from '../../../../core/modal/search-data';
import { HttpHeaders } from '@angular/common/http';
import { propDocumentUploadModel } from '../../model/propertyDocupload';
import { filters } from '../property-document-list.component';
import { SearchFilterConditionEnum } from '../../../../core/enum/searchType.enum';

const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class PropertyDocService extends BaseService<propDocumentUploadModel> {

  leasedId: any;
  propertyId:any;
  searchData: any;
  filters = new Array<filters>() //new
  filtersObj = new filters(); //new
  constructor(
    public http: HttpClient
  ) {
    super(http)
  }

  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "leasedlinesubscriber";
  }
  setId(id) {
    this.leasedId=id;
  }
  getByproperty(id){
    this.propertyId=id;
    ////console.log("properties",id);
  }
  save(modal: any): Observable<ResponseData<any>> {
    let reqObj: RequestData<any> = new RequestData<any>();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/uploadPropertyDoc', reqObj.data);
  }

  update(modal: any): Observable<ResponseData<any>> {
    //let reqObj: RequestData<any> = new RequestData<any>();
    // reqObj.requestToken = this.getRequestToken();
    //reqObj.data = modal;
    
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/updatePropertyDoc', modal);
  }

  getAll(
    paginationData: PaginationData,
    orderingData: OrderingData,
    searchData?: SearchData[],
    status?: any,
    filterBy?: any
  ): Observable<ResponseData<propDocumentUploadModel>> {
    
    let params: any = {}; 
    let data:any[0]=[
      {
        filterColumn:"leasedlineId",
        filterCondition:"equalto",
        filterDataType:null,
        filterOperator:null,
        filterValue:localStorage.getItem('leasedId')
      }
    ];
    if(searchData){
      let temp = searchData.findIndex((res)=> res.filterColumn == 'leasedlineId')
      if(temp == -1){
        searchData[searchData.length] =  {
          filterColumn:"leasedlineId",
          filterCondition:SearchFilterConditionEnum.IS_EQUAL_TO,
          filterDataType:null,
          filterOperator:null,
          filterValue:localStorage.getItem('leasedId')
        };
      }
    }
    if(searchData &&  searchData.length > 0){
      searchData = searchData.filter((res)=> res.filterColumn != 'any')
    }
    if(searchData &&  searchData.length > 0){
      const unique = [];
      searchData = searchData.filter(element => {
        const isDuplicate = unique.includes(element.filterColumn);
        if (!isDuplicate) {
          unique.push(element.filterColumn);
          return true;
        }
        return false;
      });
    }
    this.searchData = searchData
    let searchDataObj = searchData && searchData.length > 0 ? JSON.stringify(searchData) : JSON.stringify(data);
    
    params.id=this.leasedId;
    if (paginationData) {
      params.page = paginationData.pageNumber.toString();
      params.pageSize = paginationData.rowsPerPage.toString();
    }
    if (orderingData) {
      params.sortBy = orderingData.column;
      params.sortOrder = (orderingData.descending ? 1 : 0) + '';
   
    }
      console.log("Search Data--",searchDataObj)
    if (searchDataObj) {
       params.filters = JSON.parse(searchDataObj); //old code
      // params.append('search', searchDataObj);
    }
    if (status) {
      params.status = status;
    }
    if (filterBy) {
      params.filterBy = filterBy;
    }
    
    console.log("params",params)
    
    
      return this.http.post<ResponseData<propDocumentUploadModel>>(baseApiUrl + this.getModuleName()+'/propertydoc', params, {
        headers: httpOptions.headers
      });
    
    return null;
  }

  getById(id: number): Observable<ResponseData<propDocumentUploadModel>> {
    return this.http.get<ResponseData<propDocumentUploadModel>>(baseApiUrl + this.getModuleName() + '/getPropertyById/' + id, {
      headers: httpOptions.headers,
    });
    return null;
  }

  getSubscriberById(id){
    return this.http.get<any>(baseApiUrl+'leasedlinesubscriber/'+id);
  }
  
  delete(modal: any): Observable<ResponseData<any>> {
   let data = {
        "propertyId": modal.propertyId,
        "docIdList":
         [modal.id]
      }
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/deletePropertyDoc', data);
  }
}
