import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class AreaService {
  constructor(private http: HttpClient) {}

  transformAreaData(data) {
    let formatedData = [];
    data.locationList.forEach(function(item:any){
      let itemData:any = {};
      itemData.area_id = item.id;
      itemData.area_name = item.name;
      itemData.area_status = item.status;
      formatedData.push(itemData);
    });
    return {content:formatedData};
  }

  getAreaDataWithPageing(fetch_setting_data): Observable<any> {
    let page = fetch_setting_data.page;
    let size = fetch_setting_data.size;
    let params = new HttpParams().set("page", page).set("size", size);
    const get_url = baseApiUrl + 'location';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers, params: params }).pipe(
        map((res: any) => {
          return this.transformAreaData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getAreaDataById(area_id): Observable<any> {
    const get_url = baseApiUrl + 'location';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformAreaData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  transformAreaList(data) {
    let formatedData = [];
    data.locationList.forEach(function(item:any) {
      if (item.status == "Y") {
        let itemData:any = {};
        itemData.area_id = item.id;
        itemData.area_name = item.name;
        itemData.area_status = item.status;
        formatedData.push(itemData);
      }
    });
    return {content:formatedData};
  }

  getAreaList(): Observable<any> {
    const get_url = baseApiUrl + 'location';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformAreaList(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertArea(area_data: any): Observable<any> {
    const post_url = baseApiUrl + 'location';
    return this.http.post<any>(post_url, area_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updateArea(area_data: any,area_id: any): Observable<any> {
    const update_url = baseApiUrl + 'location/' + area_id;
    return this.http.put<any>(update_url, area_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  deleteArea(area_id: any): Observable<any> {
    const delete_url = baseApiUrl + 'location/' + area_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
