import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "../../../../services/base-service";
import { VasProviderModel } from "../model/vas-provider-model";
const BASE_API_URL = '';

@Injectable({
  providedIn : 'root'
})
  export class VasProviderServiceService extends BaseService<VasProviderModel> {
    
    constructor(http: HttpClient) {
      super(http);
    }
    getModuleName(): string {
      return 'vasProvider';
    }
    validate(): boolean {
      return false;
    }
    getTypeList():Observable<any> {
        let get_url = BASE_API_URL + "commonList/VAS";
        return this.http.get<any>(get_url);
      }
      
  }
  