import { Component, OnInit } from '@angular/core';
import { truncate } from 'fs';
import { SearchIpServiceService } from './service/search-ip-service.service';
@Component({
  selector: 'app-search-ip',
  templateUrl: './search-ip.component.html',
  styleUrls: ['./search-ip.component.scss']
})
export class SearchIpComponent implements OnInit {


  ipaddress: string;
  iplist: any;
  isIPfound: boolean;
  constructor(public serviceIp: SearchIpServiceService) {

  }
  ngOnInit() {
  }

  searchIp() {
    let data:any={};
    data.ipaddress=this.ipaddress.trim()
    this.serviceIp.searchIpaddress(data).subscribe(result => {
      if (result && result.dataList) {
        this.iplist = result.dataList;
        this.isIPfound = true;
      } else {
        this.iplist = null;
        this.isIPfound = false;
      }

    })
  }

}
