export class BaseSetupModel {
    id:number;
    name:string;
    address:string;
    techconname:string;
    techconphone:string;
    subscriberLeasedLine:number;
    contentDetailList : Array<any>;
    // no_of_circuits:number;
    countryId:number;
    country:string;
    stateId:number;
    state:string;
    cityId:number;
    city:string;
    talukaId:number;
    taluka:string;
    districtId:number;
    district:string;
    constituencyId:number;
    constituency:string;
    pincodeId:number;
    pincode:number;
    approvedBy:string;
    reason:string;
    rentAmount:number;
    landmark:string;
    area:String;
    propertyType:string;
    serviceareaid:number;
    areaId:number;
    addressType:string;
    latitude:string;
    longitude:string;
    feasibilityDistance: string;
    url: string;
    unit:any;
    contactperson:string;
    contactnumber:number;
    remarks:string;
    baseType:string;
    status:String;
    installationDate:string;
    basebroughtby:string;
    deviceName:string;
    ip:string;
    modelNo:string;
    modelId:string;
    modeName:string;
    fduType:string;
    noOfPort:number;
    rackSize:number;
    addressList:Array<any>;
}
