import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

export class ChangeStatus {
  changedStatus : string;
  currentStatus : string;
  remarks : string;
}


@Injectable({
  providedIn: 'root'
})
export class BlockSubscriberService {

  constructor(private http: HttpClient) {}

  getChnageStatus(custId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/customers/subscriberstatus/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as ChangeStatus[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertChangestatus(changeStatus_data : any) : Observable<any> {
    const post_url = baseApiUrl + 'subscriber/changeStatus';
    return this.http.post<any>(post_url, changeStatus_data, httpOptions)
    .pipe(map(res => res),
    catchError((error: any) => {
      return throwError(error);
    })
    );
  }
}
