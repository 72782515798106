import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { IBaseService } from '../../../services/ibase-service';
import { SearchService } from '../../../services/search.service';
import { PolicyModel } from '../model/policy-model';
import { QosPolicyService } from '../../../services/qos-policy/qos-policy.service';
import { OrderingData } from '../../../core/modal/ordering-data';
import { PaginationData } from '../../../core/modal/pagination-data';
import { SearchData } from '../../../core/modal/search-data';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent extends AbstractBaseListComponent<PolicyModel> implements OnInit, OnDestroy {
  policyDataForGrid: PolicyModel[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  searchSettings = [];
  //policyNeoGridSettings: any = {};
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('policyName', { static: false }) policyNameReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;

  constructor(
    private policyService: QosPolicyService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private tosterservice : ToastrService,
    loginService: LoginService
  ) {
    super(toastrService, searchService);
    this.loginService = loginService;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.QOS_POLICY)
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.policyNameReff];
      }
    });
  }

  getService(): IBaseService<PolicyModel> {
    return this.policyService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'qosPolicyType',
        default: 'qos Policy Type',
        rowKey: 'qosPolicyType',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      
      {
        colKey: 'description',
        default: 'Description',
        rowKey: 'description',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }

  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      serverSideSortPagination: true,
      tableId: this.tableKeyEnum.QOS_POLICY,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      // defaultSortCol: 'name',
      // defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Policy ?',
      deleteMsgBoxDetail: 'Policy Name :${name} ',
    };
  }

  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Description',
        value_field: 'description',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  // onEditClick(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }


  onEditClick(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.neoTableSetting,event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  excelDownload() {}

  pdfDownload() {}

  setList(list: Array<PolicyModel>) {
    this.policyDataForGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.policyDataForGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.policyDataForGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.policyDataForGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }

  // onNeoFilterChange(filterObj) {
  //   let searchData = new SearchData();
  //   searchData = filterObj;
  //   if (searchData.filterColumn) {
  //     let serverFilterObj = this.searchService.setServerFilterObj([searchData]);
  //     ////console.log('Server final filtre obj', serverFilterObj);
  //   }
  // }

  onAddClick() {
    this.router.navigate(['/plan-management/qos-policy/add-qos-policy']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/plan-management/qos-policy/add-qos-policy',rowData.id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
