import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  constructor(private http: HttpClient) {

  }

  transformClientData(data) {
    let formatedData = [];
    data.clientsList.forEach(function(item: any) {
      let itemData: any = {};
      itemData.client_id = item.id;
      itemData.client_ip = item.clientip;
      itemData.clientGroupName=item.clientGroupName;
      itemData.shared_secret_key = item.sharedkey;
      itemData.timeout = item.timeout;
      itemData.ip_type = item.iptype;
      itemData.client_group_id = item.clientgroupid;
      itemData.create_date = item.createdate;
      itemData.updatedate = item.updatedate;
    
      formatedData.push(itemData);
    });
    return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getClientDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'clients/list';
    return this.http.post<any>(post_url,params,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformClientData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCountryListById(client_id) : Observable<any> {
    const get_url = BASE_API_URL + 'clients/' + client_id;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res.client;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformClientGroupData(data) {
    let formatedData = [];
    data.clientgrouplist.forEach(function(item: any) {
      let itemData: any = {};
      itemData.client_group_id = item.id;
      itemData.client_group_name = item.name;
      itemData.status = item.status;
      itemData.update_date = item.updatedate;
      itemData.create_date = item.createdate;
      formatedData.push(itemData);
    });
    return { content: formatedData };
  }

  getClientGroup(): Observable<any> {
    const get_url = BASE_API_URL + 'clientgroup/all';
    return this.http.get<any>(get_url)
  }


  insertClient(client_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'clients';
    return this.http.post<any>(post_url, client_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateClient(client_data: any, client_id: any): Observable<any> {
    const update_url = BASE_API_URL + "clients/" + client_id;
    return this.http.put<any>(update_url, client_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteClient(client_id: any): Observable<any> {
    const delete_url = BASE_API_URL + "clients/" + client_id;
    return this.http.delete(delete_url, httpOptions);
  }

  getdiscPolicyList(type) : Observable<any> {
    const get_url = BASE_API_URL + 'clients/dynaAuthPolicies/' + type;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
