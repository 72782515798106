import { ChangeDetectorRef, Component, OnInit ,Optional,Inject} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingTemplateService } from '../billing_template/billing_template.service';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { MatDialog ,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';

export interface DialogPassData {
  id: number;
}


@Component({
  selector: 'app-add-billing-template',
  templateUrl: './add-billing-template.component.html',
  styleUrls: ['./add-billing-template.component.scss']
})
export class AddBillingTemplateComponent implements OnInit {
  saveBtn:boolean=false;
  public sub: any = null;
  public billing_id: any = null;
  show_update: any = false;
  submitted: boolean = false;
  templateType:any[]=[];
  templateTypePattern:string = "^([a]|[^a]){uniquenumber}([a]|[^a])|({uniquenumber})|{uniquenumber}([a]|[^a])+$";
  selectForUpdate: any;
  commonStatus: any[] = [];
  req_message = "This field is required.";
  isDisabled:boolean=false;

  insertBillingTemplateForm = new FormGroup({
    templatename: new FormControl('', [Validators.required]),
    templatetype: new FormControl('', [Validators.required]),
    status: new FormControl('Active', [Validators.required]),
    jrxmlfile: new FormControl('', [Validators.required]),
    invoiceNumFormat: new FormControl('', [Validators.required]),
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private billingTemplateService: BillingTemplateService,
    private cdRef: ChangeDetectorRef,
    private tosterservice: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddBillingTemplateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData
  ) { }

  ngOnInit() {

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.billing_id = id;
      this.show_update = true;
      this.isDisabled=false;
      this.getBillingTemplateData();
    }

    // if(this.data && this.data.id){
    //   this.billing_id = this.data.id;
    //   this.show_update = false;
    //   this.isDisabled=true;
    //   this.getBillingTemplateData();
    // }

    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.billing_id = params['id'];
    //       this.show_update = false;
    //       this.getBillingTemplateData();
    //     }
    //   });
    this.getTemplateType();
    this.billingTemplateService.commonStatus().subscribe(res => {
      this.commonStatus = res.dataList;
    })
  }


  validateForm() {
    if (!this.insertBillingTemplateForm.valid) {
      this.submitted = true;
      this.tosterservice.error("Please fill all the details!");
      this.insertBillingTemplateForm.markAllAsTouched();
    } else {
      if (this.billing_id === null) {
        this.insertBillingTemplateOnDb();
      } else {
        this.updateBillingTemplateOnDb();
      }
    }
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getBillingTemplateData() {
    let billing_id = this.billing_id;
    this.billingTemplateService.getBillingTemplateData(billing_id).subscribe(result => {
      this.setUpdateValueInForm(result);
    });
  }

  setUpdateValueInForm(billing_Data) {
    this.show_update = true;
    this.selectForUpdate = billing_Data;
    ////console.log("billing_Data",billing_Data);
    this.insertBillingTemplateForm.patchValue(billing_Data);
  }

  insertBillingTemplateOnDb() {
    if (this.insertBillingTemplateForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertBillingTemplateForm.value);
      let formatedInputData: any = this.insertBillingTemplateForm.value;
      this.billingTemplateService.insertBillingTemplate(formatedInputData).subscribe(result => {
        ////console.log(result);
        if (result.responseCode !== 200 && result.responseMessage) {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        } else {
          this.saveBtn =false;
          this.cdRef.detectChanges();
          this.onBackToBillingTemplate();
          this.tosterservice.success('Billing Template has been created successfully');
        }
      },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateBillingTemplateOnDb() {
    if (this.insertBillingTemplateForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertBillingTemplateForm.value);
      let billing_id = this.selectForUpdate.billing_id;
      let formatedInputData: any = this.insertBillingTemplateForm.value;
      this.billingTemplateService.updateBillingTemplate(formatedInputData, this.billing_id).subscribe(result => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        } else {
          this.saveBtn =false;
          this.cdRef.detectChanges();
          this.show_update = false;
          this.onBackToBillingTemplate();
          this.tosterservice.success('Billing Template Update successfully');
        }
      },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  onBackToBillingTemplate() {
   // this.router.navigate(['operations/billingtemplate']); /**Old Code */
   
   if(this.genericDataSharingService.lastAuditUrl == this.router.url){
    this.router.navigate(['/operations/audit']);
    this.genericDataSharingService.lastAuditUrl = undefined;
    }else{
    this.router.navigate(['operations/billingtemplate']);
    }

  }
  getTemplateType(){
    this.billingTemplateService.commomTemplatetype().subscribe(res=>{
        this.templateType=res.dataList;
    });
  }
  
  closeDialog(){
    this.dialogRef.close();
   }

}
