import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../../components/custom-modal';
import { CancelPlanService } from './cancel-plan.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../../login/login.service';
import { AclClassConstants } from '../../../../aclClassConstants';
import { AclConstants } from '../../../../aclOperationConstants';
import { LoadingService } from '../../../../../../../src/app/components/loading/loading.service';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss']
})
export class CancelPlanComponent implements OnInit {
  @Input() planId: any;
  @Input() custId: any;
  @Input() planType: any;
  @Input() custplanId: any;

  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  refundOptionFlag: boolean = false;
  refundOption:any
  totalTaxAmount:number;
  submitted:any = null;
  insertCancelPlanForm = new FormGroup({
    refundOption: new FormControl('',[Validators.required]),
    PlanVASRefund: new FormControl(false),
    remarks: new FormControl('',[Validators.required])
  })

  cancelPlanDetails:any = {};
  chargeAmount: any = "";
  finalAmount: any = "";

  reverseChargeData: any = {};
  calculatedReverseCharge: any = {};
  custChargeDetailsList: any[] = [
    {
      display_text: '',
      id: '',
    },
  ];
  reversalTypeCommonList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  btnSaveBtn:boolean=false;
  refundVAS: boolean = false;
  constructor(
    private customModalService: CustomModalService,
    private cancelPlanService : CancelPlanService,
    private tosterservice : ToastrService,
    public loginService: LoginService,
    public loadingService:LoadingService
    ) { this.loginService = loginService}

  ngOnInit() {
    ////console.log(this.planId);
    this.getCancelPlanList();
    this.cancelPlanService.getRefundOption().subscribe((res)=>{
      
    this.refundOption = res.dataList;
    });

    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_REFUND_OPTION,AclConstants.OPERATION_CUSTOMER_ALL))
    {
      this.refundOptionFlag = true;
    }
    else{
      this.refundOptionFlag = false;
    }
  }

  getCancelPlanList() {
    this.getTaxDetailByPlan();
    ////console.log("innnn")
    this.cancelPlanService.getCancelPlanList(this.planId).subscribe(result => {
      // defaultRefundOptForNoACL
      
      this.cancelPlanDetails = result.data;

        this.getTaxDetailByPlan();
        this.cancelPlanDetails.fullAmount = this.cancelPlanDetails.fullAmount;
        this.cancelPlanDetails.proratedAmount = this.cancelPlanDetails.proratedAmount;
        this.cancelPlanDetails.fullAmountWithTax = this.cancelPlanDetails.fullAmountWithTax;
        this.cancelPlanDetails.proratedAmountWithTax = this.cancelPlanDetails.proratedAmountWithTax;

       
        if(this.refundOptionFlag)
        {
          let selectedRefundOption =  this.refundOption.filter(res=>res.value === result.data.defaultRefundOptForActive);
          
          this.insertCancelPlanForm.get('refundOption').setValue(result.data.defaultRefundOptForActive)
          
          this.onChangeRefundOption(selectedRefundOption[0]);
        }
        else
        {
          let selectedRefundOption =  this.refundOption.filter(res=>res.value === result.data.defaultRefundOptForNoACL);
          
          this.insertCancelPlanForm.get('refundOption').setValue(result.data.defaultRefundOptForNoACL)
          this.onChangeRefundOption(selectedRefundOption[0]);
          this.insertCancelPlanForm.controls.refundOption.disable();
        }

    });
  }

  transformCustChargeDetailsList(result) {
      result.forEach(element => {
        element.display_text = element.actualprice+"-"+element.chargeName;
      });
      return result;
  }

  onChangeCheck(){
    this.finalAmount = 0;
    this.insertCancelPlanForm.get('refundOption').reset();
  }

  onChangeRefundOption(value) {
    if(!this.refundVAS){
      if (value) {
        if (value.value === 'Prorated Without Tax') {
          this.finalAmount = this.cancelPlanDetails.proratedAmount;
        } else if (value.value === 'Prorated With Tax') {
          this.finalAmount =  this.cancelPlanDetails.proratedAmountWithTax
        }else if(value.value ==='None'){
          this.finalAmount="0";
        }
        else if(value.value ==='Full Without Tax'){
          this.finalAmount = this.cancelPlanDetails.fullAmount;
        }
        else if(value.value ==='Full With Tax'){
          this.finalAmount =  this.cancelPlanDetails.fullAmountWithTax
        }
      } else {
        this.finalAmount = '0';
      }
    }else{
      if (value) {
        if (value.value === 'Prorated Without Tax') {
          this.finalAmount = this.cancelPlanDetails.proratedAmountWithVAS;
        } else if (value.value === 'Prorated With Tax') {
          this.finalAmount =  this.cancelPlanDetails.proratedAmountWithVASWithTax
        }else if(value.value ==='None'){
          this.finalAmount="0";
        }
        else if(value.value ==='Full Without Tax'){
          this.finalAmount = this.cancelPlanDetails.fullAmountWithVAS;
        }
        else if(value.value ==='Full With Tax'){
          this.finalAmount =  this.cancelPlanDetails.fullAmountWithVASWithTax
        }
      } else {
        this.finalAmount = '0';
      }
    }
  }

  onClickSaveCancelPlanForm() {
    //this.loginService.sh
    if (this.insertCancelPlanForm.valid === true) {

      for (const prop in this.insertCancelPlanForm.controls) {
        this.insertCancelPlanForm.value[prop] = this.insertCancelPlanForm.controls[prop].value;
      }
      this.submitted = false;
      let self = this;
      alertify.confirm("Cancel Plan","Are You Sure You want to Cancel Plan ?",
      function() {
        this.btnSaveBtn=true;
         self.saveApiCall();
      },
      function() {
        this.btnSaveBtn=false;
      });
    } else {
      this.submitted = true;
      this.btnSaveBtn=false;
      this.insertCancelPlanForm.markAllAsTouched();
    }
  }

  getTaxDetailByPlan() {
    let data = {
      "chargeId": 0,
      "custId": Number(this.custId),
      "locationId": 0,
      "planId": this.custplanId
    }
    // this.cancelPlanService.getTaxDetailByPlan(data).subscribe((result) => {
    //   if (result) {
    //     this.totalTaxAmount = result.TotalAmount;
    //   }
    // });
  }

  saveApiCall() {
    
    
    this.loadingService.show();
    this.btnSaveBtn=true;
    let apiInputData = Object.assign({}, this.insertCancelPlanForm.value);
    let formatedData:any = {};
    formatedData.custId = parseInt(this.custId);
    formatedData.planId = this.planId;
    formatedData.refundOption = apiInputData.refundOption
    formatedData.remarks =  apiInputData.remarks;
    formatedData.refundVAS = apiInputData.PlanVASRefund

    if(this.planType === 'active'){
      formatedData.isActive= true;
      formatedData.isFuture= false;
    }else if(this.planType === 'future'){
      formatedData.isActive= false;
      formatedData.isFuture= true;
    }
    
    this.cancelPlanService.insertCancelPlanDetail(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
          this.loadingService.hide();
          this.btnSaveBtn=false;
        } else {
          
          this.loadingService.hide();
          this.tosterservice.success('Cancel Plan Successfully.');
          this.btnSaveBtn=false;
          this.customModalService.close('cancel-plan',result);
        }
      },err => {
        this.btnSaveBtn=false;
        this.loadingService.hide();
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }
}
