import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const baseApiUrl='';
@Injectable({
  providedIn: 'root'
})
export class NetworkSearchService {

  constructor(
    private http:HttpClient
  ) { }


  searchNetworkdata(data):Observable<any>{
    const post_url = baseApiUrl + "NetworkDevice/snmp/networkdeviceSearch";
    return this.http.post<any>(post_url, data);
  }
  getOltData():Observable<any>{
    const get_url = baseApiUrl + "NetworkDevice/snmp/getOltDevices";
    return this.http.get<any>(get_url);
    
  }

  getstatus(){
    const get_url = baseApiUrl + "commonList/generic/deviceOnuStatus";
    return this.http.get<any>(get_url);
  }

  excelServiceData(data) {
    const post_url = baseApiUrl + "NetworkDevice/snmp/excel/networkdeviceSearch";
    return this.http.post<any>(post_url, data,{ observe: 'body', responseType: 'blob' as 'json' });
  }
}
