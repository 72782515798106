import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
@Component({
  selector: 'app-ip-pool-configuration',
  templateUrl: './ip-pool-configuration.component.html',
  styleUrls: ['./ip-pool-configuration.component.scss']
})
export class IpPoolConfigurationComponent implements OnInit {

  allColumns: any[] = [];
  tableKeyEnum = TableKeyEnum;
  ippoolenabled: boolean = false;
  ippoolConfigurationConfig: any = {};
  poolId: any = null;

  ippoolEnavled = new FormGroup({
    ippool: new FormControl(null)
  })

  constructor() { }

  ngOnInit() {
    this.setGridSetting();
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'id',
        default: 'Id',
        rowKey: 'id',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'poolid',
        default: 'Pool ID',
        rowKey: 'poolid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'Pool Name',
        default: 'poolname',
        rowKey: 'Pool Name',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },


    ];

    this.ippoolConfigurationConfig = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.IP_POOL_CONFIGURATION,
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
    };
  }
  enabledIpPool(event) {
    if (event.target.checked) {
      this.ippoolEnavled.get('ippool').disable();
    } else {
      this.ippoolEnavled.get('ippool').enable();
    }
  }
}
