import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { ChangeVoiceDetailsService } from './change-voice-details.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-voice-details',
  templateUrl: './change-voice-details.component.html',
  styleUrls: ['./change-voice-details.component.scss']
})
export class ChangeVoiceDetailsComponent implements OnInit {

  @Input() custId : any;
  selectForUpdate: any;
  submitted:boolean = false;

  voiceServiceTypeList:any =[{
    id : '',
    text :'',
    value : '',
    type :'',
    status :'',
    subTypeList :''
  }];
  intercomGroupList:any = [{
    id : '',
    text :'',
    value : '',
    type :'',
    status :'',
    subTypeList :''
  }]
  insertVoiceDetailsForm = new FormGroup({
    id : new FormControl({value :'', disable : true}),
    voicesrvtype : new FormControl('', [Validators.required]),
    didno : new FormControl(''),
    childdidno : new FormControl(''),
    intercomno : new FormControl(''),
    intercomgrp : new FormControl(''),
    remarks : new FormControl('', [Validators.required]),
  });
childDIDChek:boolean;
  constructor(
    private  toster: ToastrService,
    private customModalService: CustomModalService,
    private changeVoiceDetailsService :ChangeVoiceDetailsService,
    private cdRef : ChangeDetectorRef,
    private tosterservice : ToastrService

    ) { }

  ngOnInit() {
    this.changeVoiceDetailsService.getChangeVoiceDetail(this.custId).subscribe(
      result => {
        this.voiceServiceTypeList = result.data.commonListVoiceService
        ////console.log("voice List::",this.voiceServiceTypeList)
        this.intercomGroupList = result.data.commonListInercomGroup
        this.setUpdateValueInForm(result.data);
        ////console.log(result.data);
      }
    );
    this.onvoiceChange();
  }

  setUpdateValueInForm(changeVoice_data) {
if(changeVoice_data.voicesrvtype){
  this.insertVoiceDetailsForm.get('voicesrvtype').disable();
}
else{
  this.insertVoiceDetailsForm.get('voicesrvtype').enable();
}
    this.selectForUpdate = changeVoice_data;
    this.onvoiceChange(this.selectForUpdate.voicesrvtype)
    this.insertVoiceDetailsForm.patchValue(changeVoice_data);
  }

  onvoiceChange(event?) {
////console.log("event::",event)
      if (event && (event.value === 'PhoneLine' || event ==='PhoneLine')) {
        this.insertVoiceDetailsForm.get('intercomno').disable();
        this.insertVoiceDetailsForm.get('intercomno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomno').reset();
        this.insertVoiceDetailsForm.get('intercomno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomgrp').reset();
        this.insertVoiceDetailsForm.get('intercomgrp').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomgrp').disable();
        this.insertVoiceDetailsForm.get('intercomgrp').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').reset();
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').disable();
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').enable();
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').setValidators(Validators.required);
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();

      } else if (event && (event.value === 'ShipTrunk' || event === 'ShipTrunk')) {
        this.insertVoiceDetailsForm.get('intercomno').reset();
        this.insertVoiceDetailsForm.get('intercomno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomno').disable();
        this.insertVoiceDetailsForm.get('intercomno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomgrp').reset();
        this.insertVoiceDetailsForm.get('intercomgrp').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomgrp').disable();
        this.insertVoiceDetailsForm.get('intercomgrp').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').enable();
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').enable();
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').setValidators(Validators.required);
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').setValidators(Validators.required);
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();
        
      } else if (event && (event.value === 'Intercom' || event === 'Intercom')) {
        this.insertVoiceDetailsForm.get('intercomno').enable();
        this.insertVoiceDetailsForm.get('intercomno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomgrp').enable();
        this.insertVoiceDetailsForm.get('intercomgrp').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomno').setValidators(Validators.required);
        this.insertVoiceDetailsForm.get('intercomno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('intercomgrp').setValidators(Validators.required);
        this.insertVoiceDetailsForm.get('intercomgrp').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').reset();
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').disable();
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').reset();
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').disable();
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('childdidno').reset();
        this.insertVoiceDetailsForm.get('childdidno').updateValueAndValidity();
        this.insertVoiceDetailsForm.get('didno').reset();
        this.insertVoiceDetailsForm.get('didno').updateValueAndValidity();
      }
  }

  onClickSaveForm() {
    this.submitted = true
    if(this.insertVoiceDetailsForm.get('voicesrvtype').value === 'ShipTrunk'){
      let childdid = [];
      if(this.insertVoiceDetailsForm.get('childdidno').value){
        childdid =  this.insertVoiceDetailsForm.get('childdidno').value.split(',');
        // childdid.forEach(element => {
        //   ////console.log("element",element);
        //   ////console.log("didno",this.insertVoiceDetailsForm.get('didno').value)
        //   if(element === this.insertVoiceDetailsForm.get('didno').value)
        //   {
        //     this.childDIDChek = true;
        //     return;
        //   }
        //   else 
        //   {
        //     this.childDIDChek = false;
        //     return;
        //   }
        // });
    
        this.childDIDChek = childdid.includes(this.insertVoiceDetailsForm.get('didno').value)
          
        
      }
   
    }
    if(this.childDIDChek === true  && this.insertVoiceDetailsForm.get('voicesrvtype').value === 'ShipTrunk'){
    if (this.insertVoiceDetailsForm.valid === true) {
      let self = this;
      alertify.confirm("Change Voice Details","Are You Sure You want to Change Voice Details ?",
      function() {
        self.insertChangeVoiceDetailOnDb();
      },
      function() {
  
      });
    } else {
      this.insertVoiceDetailsForm.markAllAsTouched();
    }

  }
  else if(this.childDIDChek === false  && this.insertVoiceDetailsForm.get('voicesrvtype').value === 'ShipTrunk'){
    this.toster.error("Child DID number must contain DIDNO")
  }
    else{
      if (this.insertVoiceDetailsForm.valid === true) {
        let self = this;
        alertify.confirm("Change Voice Details","Are You Sure You want to Change Voice Details ?",
        function() {
          self.insertChangeVoiceDetailOnDb();
        },
        function() {
    
        });
      } else {
        this.insertVoiceDetailsForm.markAllAsTouched();
      }
    }
  }

  
keypress(event: any) {
  const pattern = /[0-9\.]/;
  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
  event.preventDefault();
  }
  }

  insertChangeVoiceDetailOnDb() {
   
  
      if (this.insertVoiceDetailsForm.valid === true ) {
        let formatedInputData:any = this.insertVoiceDetailsForm.value;
        formatedInputData.voicesrvtype = this.insertVoiceDetailsForm.get('voicesrvtype').value
        ////console.log("check",formatedInputData)
        this.changeVoiceDetailsService.insertChangeVoiceDetails(formatedInputData).subscribe(result => {
            this.cdRef.detectChanges();
            if (result.responseCode !== 200 && result.responseMessage) {
              this.tosterservice.error(result.responseMessage);
            } else {
              this.tosterservice.success('Change Voice Detail Update successfully');
              this.customModalService.close('change-voice-details',result);
            }
          },
          err => {
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }

  

  }

  closeModal(id:string){
    this.customModalService.close(id);
  }
}
