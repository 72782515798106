import { Component, OnInit ,TemplateRef,ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { DateAdapter, MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { AuditlogService } from './auditlog.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CityComponent } from '../../masters/city/city.component';
import { StateComponent } from '../../masters/state/state.component';
import { AddStateComponent } from '../../masters/add-state/add-state.component';
import { AddCityComponent } from '../../masters/add-city/add-city.component';

import { OldAbstractBaseListComponent } from '../../../../app/components/base/old-abstract-base-list-component';
import { SearchService } from '../../../../app/services/search.service';
import { saveAs } from 'file-saver/FileSaver';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { Router } from '@angular/router';
import { log } from 'console';
import { RecentRenewalService } from '../../reports/recent-renewal.service';
import { SearchAutocompleteComponent } from '../../../components/search-autocomplete/search-autocomplete.component';
import { CommunicationReportService } from '../../reports/communication-report/communication-report.service';


@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent extends OldAbstractBaseListComponent implements OnInit {
  //auditForm:FormGroup;
   @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
   @ViewChild('auditWrapper',{static:false}) auditWrapperRef:TemplateRef<any>; 
   @ViewChild(SearchAutocompleteComponent, { static: false }) autoCompleteRef: SearchAutocompleteComponent;

  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  // subscriberNeoGridSettings: any = {};
  auditLogNeoGridSettings:any={};
  searchSettings = [];
  datachidTransfer:any;
  AuditDataList:any;
  AuditForDataList:any;
  partner:any;
  partnerList:any;
  operationArrayList:any;
  usersList:any;
  Localpartnerflag:any
  Localpartnerid:any
  public subscriberList: string;
  autoField:any =['name', 'username']
  custId: any;
  currentMonthDate:any;
  maxDate:any;
  minDate:any;
  defaultDate:any;
  auditlogResponse:any;
  isPartnerSelected:boolean=true;
  dialogValue: string;
  sendValue: string;
  excelInputData:any;
  selectedCalculation:any;

  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  logdata:any;

  auditForm = this.fb.group({
    partner: new FormControl(null),
    auditFor:new FormControl(null,),
    module:new FormControl(null,),
    operation:new FormControl(null,),
    customerId:new FormControl(null,),
    fromDate:new FormControl(new Date()?new Date():null,),
    toDate:new FormControl(new Date()?new Date():null,),
    // dateMonthwise:new FormControl('',),
  })

  constructor( private fb:FormBuilder,
               public datepipe:DatePipe,
               public auditlog:AuditlogService,
               public dialog: MatDialog,
               private toaster:ToastrService,
               private searchService: SearchService,
               private sharingdataService:DataSharingServiceService,
               private router:Router,
               private recentRenewalService:RecentRenewalService,
               private communicationReportService:CommunicationReportService,
               ) {
                super(searchService)
                //this.dateAdapter.setLocale('IN')
                this.Localpartnerid = Number(localStorage.getItem('partnerId'));
                this.Localpartnerflag = localStorage.getItem('partnerFlag');
            //**Start  */
             

                }

  ngOnInit() {
    
   
    this.getPartnerList();
    this.setDefaultPageSize(this.tableKeyEnum.AUDIT_LOG)
    this.setGridSetting();
    this.setDateFromDefault();
    this.getAuditFor();
    this.getAuditModuleData();
    this.setDate();
     //setTimeout(() => {
      //  this.getListWithParams();
     //}, 200);
    this.getUserList();
     
    // this.sharingdataService.getInfo().subscribe(info => {
    //   this.logdata =  info;
    
    //   //console.log("getInfo Audit",this.logdata)
    //   // this.auditForm.get('module').setValue(this.logdata.module);
    //   // this.auditForm.get('auditFor').setValue(this.logdata.auditFor);
    //   // this.auditForm.get('operation').setValue(this.logdata.operation);
    //   // this.auditForm.get('fromDate').setValue(this.datachidTransfer.fromDate);
    //   // this.auditForm.get('toDate').setValue(this.datachidTransfer.toDate);
    //   })
  }

  // createAuditForm(){
  //  this.auditForm = this.fb.group({
  //     auditFor:new FormControl(null,),
  //     module:new FormControl(null,),
  //     operation:new FormControl(null,),
  //     userWise:new FormControl(null,),
  //     fromDate:new FormControl(new Date()?new Date():null,),
  //     toDate:new FormControl(new Date()?new Date():null,),
  //     // dateMonthwise:new FormControl('',),
  //   });
  // }

  public getListWithParams() {
    
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.onSubmit(config);
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.onSubmit(config);
  }

  
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.auditWrapperRef];
      }
    });
  }

  getPartnerList(){
    let partnerId = localStorage.getItem('partnerId')
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.recentRenewalService.getPartnerlistService().subscribe(result => {
        this.partnerList = result.partnerlist;
      })
    }
    else{
      this.recentRenewalService.getManagablePartnerlistService().subscribe(result => {
        this.partnerList = result.dataList;
        // if (partnerId) {
        //   this.auditForm.get('partner').setValue(partnerId);
        //   this.auditForm.get('partner').disable();
        // }
      })
    }
    setTimeout(() => {
      if(this.partnerList){
        let  temp = this.setAutoSelectDropDownList(this.partnerList)
        this.auditForm.get('partnerId').setValue(temp.id)   
      }
    }, 250);
      // this.recentRenewalService.getPartnerlistService().subscribe((res)=>
      // {
      //   this.partnerList = res.partnerlist;
        
      //   // if (this.Localpartnerflag !== "true") {
      //   //   this.partner = this.partnerList[0].id;
      //   //   this.auditForm.get('partner').setValue(this.partner);
      //   //   this.auditForm.get('partner').updateValueAndValidity();
      //   // }
      // })
  }

  onSubmit(gridSetting:any) {
    // Object.keys(this.auditForm.controls).forEach(field => {
    //   const control = this.auditForm.get(field);
    //   if (control instanceof FormControl) {
    //       control.markAsTouched({ onlySelf: true });
    //   }
    // });
    // if(!this.auditForm.valid){
    //   this.toaster.error("Please fill all the details!");
    //   return
    // }
    if (this.Localpartnerid !== null && this.Localpartnerflag === "true") {
      this.partner = this.Localpartnerid;
      this.auditForm.get('partner').setValue(this.partner);
      this.auditForm.get('partner').updateValueAndValidity();
      this.auditForm.get('partner').disable();
    }
    let formatedInputData: any = {};
    let apiInputData = this.auditForm.value;
    let fetchSettingData = this.auditLogNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    let information= null
    let createStartdate = this.datepipe.transform(apiInputData.fromDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.toDate, 'yyyy-MM-dd');
    this.sharingdataService.getInfo().subscribe(res=>{
     if(Object.keys(res).length){
        information =res;
      }
      else{
        information = null
      }
    })
    if(information){

      formatedInputData = {}
      formatedInputData = Object.assign(information);
      formatedInputData.fromDate=createStartdate;
      formatedInputData.toDate=createEnddate;
      if(apiInputData.auditFor == null){
        formatedInputData.auditFor = 'Partner'
        formatedInputData.partnerId = this.partner
      }
      if(formatedInputData.auditFor != 'Partner'){
        formatedInputData.auditFor=apiInputData.auditFor;
      }
      formatedInputData.module=apiInputData.module;
      formatedInputData.operation=apiInputData.operation;
      formatedInputData.page = params.page;
      formatedInputData.pageSize = params.pageSize
      if( formatedInputData.auditFor == 'Customer'){
        formatedInputData.customerId=this.custId; /**Changed:userWise to customerId */
      }
      else if(formatedInputData.auditFor == 'Employee'){
        formatedInputData.customerId=apiInputData.customerId;
      }
      // if(apiInputData.auditFor == null && typeof(this.partner)=='object'){
      //   formatedInputData.partnerId = this.partner[0].id
      // }
      if(formatedInputData.customerId == null){
        formatedInputData.partnerId = this.partner
      }
      this.datachidTransfer=formatedInputData;   
      this.excelInputData=formatedInputData; /**Excel:Download */
    }
    else{

      formatedInputData = {}
      formatedInputData = Object.assign(params);
      formatedInputData.fromDate=createStartdate;
      formatedInputData.toDate=createEnddate;
      if(apiInputData.auditFor == null ){
        formatedInputData.auditFor = 'Partner'
        formatedInputData.partnerId = this.partner
      }
      if(formatedInputData.auditFor != 'Partner'){
        formatedInputData.auditFor=apiInputData.auditFor;
      }
      formatedInputData.module=apiInputData.module;
      formatedInputData.operation=apiInputData.operation;
      if( formatedInputData.auditFor == 'Customer'){
        formatedInputData.customerId=this.custId; /**Changed:userWise to customerId */
      }
      else if(formatedInputData.auditFor == 'Employee'){
        formatedInputData.customerId=apiInputData.customerId;
      }
      // if(apiInputData.auditFor == null && typeof(this.partner)=='object'){
      //   formatedInputData.partnerId = this.partner[0].id
      // }
      if(formatedInputData.customerId == null ){
        formatedInputData.partnerId = this.partner
      }
      this.datachidTransfer=formatedInputData;   
      this.excelInputData=formatedInputData;
      }
      let ddmmyyyyStartDate = new Date(this.auditForm.get('fromDate').value);
      let ddmmyyyyEndDate = new Date(this.auditForm.get('toDate').value);
      if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
        this.toaster.error("Start date should be less then End date.");
        return
      } 
      
    this.auditlog.getGridSearchData(formatedInputData).subscribe((res)=>
    {

      if(res.responseCode===200){
          this.auditlogResponse = res.dataList;
          //console.log("A Log -->",this.auditlogResponse);
        if(this.auditlogResponse.length===0){
          this.toaster.error("No Record Found");
          this.totalRecords = res ? res.totalRecords : 0;
          this.auditLogNeoGridSettings.pageTotalLength = this.totalRecords === 0 ? 0 : 0;
          this.auditLogNeoGridSettings.perPage=0;
          this.dataSource= new MatTableDataSource(JSON.parse(JSON.stringify([])));
        }
        else{
          this.totalRecords = res ? res.totalRecords : 0;
          this.auditLogNeoGridSettings.pageTotalLength = this.totalRecords === 0 ? 0 : this.totalRecords;
          this.dataSource= new MatTableDataSource(JSON.parse(JSON.stringify(this.auditlogResponse)));
        }
        }
      else{
        this.toaster.error(res.responseMessage);
        this.totalRecords = res ? res.totalRecords : 0;
        this.auditLogNeoGridSettings.pageTotalLength = this.totalRecords === 0 ? 0 : 0;
        this.auditLogNeoGridSettings.perPage=0;
        this.dataSource= new MatTableDataSource(JSON.parse(JSON.stringify([])));
      }
      })
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  setDate() {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var fromDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
    var toDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.auditForm.patchValue({ "fromDate": fromDate, "toDate": toDate });
  }

  setDateFromDefault(event?){
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.currentMonthDate = new Date();
    if(event && event.target)
    {
      this.auditForm.get('toDate').reset();
      this.auditForm.get('toDate').updateValueAndValidity();
      this.minDate = new Date(event.target.value.getFullYear(), event.target.value.getMonth(), 1);
      this.maxDate = new Date(event.target.value.getFullYear(), event.target.value.getMonth() +1, 0);
      this.auditForm.get('toDate').reset(this.maxDate);
      this.auditForm.get('toDate').updateValueAndValidity();
    }
    else{
      this.minDate = new Date(createStartdate.getFullYear(), createStartdate.getMonth(), 1);
      this.maxDate = new Date(createStartdate.getFullYear(), createStartdate.getMonth() +1, 0);
    }
  }

  getCustomerByPartner(event){
    this.auditForm.get('auditFor').reset();
    this.subscriberList = null;
    this.auditForm.get('customerId').reset();
    this.custId = null;
    if (event && event.id) {
      // this.autoCompleteRef.autocomplete.clearModel();
      // this.autoCompleteRef.dataList = [];
      this.auditForm.get('auditFor').enable();
      this.auditForm.get('auditFor').updateValueAndValidity();
      this.isPartnerSelected=false;
    } else {
      this.isPartnerSelected=true;
      this.autoCompleteRef.autocomplete.clearModel();
      this.autoCompleteRef.dataList = [];
      this.getUserList();
    }
    //this.getAuditModuleData()
  }

  public getUserList() {
    this.subscriberList = 'subscriber/searchNew?s=';
  }

  public setUserList(eventcust){
    if(eventcust){
      this.custId = eventcust.event.id;
    }else{
      this.custId = null;
    }
    
  }
  
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'auditDate',
        default: 'Date',
        rowKey: 'auditDate',
        width: '7%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'userName',
        default: 'User Name',
        rowKey: 'userName',
        sortKey: 'userName',
        width: '8%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'employeeName',
        default: 'Employee Name',
        rowKey: 'employeeName',
        sortKey: 'employeeName',
        width: '8%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'partnerName',
        default: 'partner Name',
        rowKey: 'partnerName',
        sortKey: 'partnerName',
        width: '8%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,

      },
      {
        colKey: 'module',
        default: 'Module Name',
        rowKey: 'module',
        sortKey: 'module',
        width: '9%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
       {  
        colKey: 'operation',
        default: 'Operation',
        rowKey: 'operation',
        width: '9%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'ipAddress',
        default: 'IP Address',
        rowKey: 'ipAddress',
        sortKey: 'ipAddress',
        width: '8%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'remark',
        default: 'Remark',
        rowKey: 'remark',
        sortKey: 'remark',
        width: '25%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      // {
      //   colKey: 'status',
      //   default: 'View Log',
      //   rowKey: 'status',
      //   sortKey: 'status',
      //   width: '6%',
      //   customControls: [this.auditWrapperRef],
      //   customValue: (status) => {
      //     if (status === 'View') {
      //       return 'View';
      //     } 
      //   },
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: false,
      // },
    ];
    this.allColumns.forEach((res)=>{
      res.isFilterAllow = false
    })

     
    this.auditLogNeoGridSettings = {
      showFilter:false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.AUDIT_LOG,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageTotalLength: 0,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      // sort: false,
      // defaultSortCol: '',
      // defaultSortDirection: 'asc',
      // // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this subscriber ?',
      deleteMsgBoxDetail: 'Subscriber Name : ${name} ',
    };
    //return this.auditLogNeoGridSettings;
     if (this.auditLogNeoGridSettings.serverSideSortPagination && this.auditLogNeoGridSettings.perPage) {
        this.setPaginationPerPage(this.auditLogNeoGridSettings.perPage);
     }
     
  }

  /**Module List  */
  getAuditModuleData(){
    //Obj.aclOperationsList;
    this.operationArrayList=null;
    this.auditlog.getAuditOperation().subscribe((res)=>{
       this.AuditDataList=res.dataList.filter(res=>(!res.dispname.includes('Report')) && (res.dispname!='Free Class' && res.dispname!='Subscriber CDRs' && res.dispname!='Broadcast' && res.dispname!='Network Search' && res.dispname!='Network Status' && res.dispname!='Order Place' && res.dispname!='Dash-Board Count' && res.dispname!='Audit' && res.dispname!='Connected Subscriber' && res.dispname!='Network-Device Tree'  ));
    })
  }

  /** Clear All Other Field:If Clear */
  ClearAllField(){
  //this.AuditDataList=null;
  this.usersList=null;
  this.auditForm.get('customerId').reset();
  this.auditForm.get('customerId').updateValueAndValidity();
  this.auditForm.get('auditFor').disable();
  this.auditForm.get('auditFor').updateValueAndValidity();
 }

 ClearAllFieldAudit(){
  //this.AuditDataList=null;
  this.usersList=null;
  this.auditForm.get('customerId').reset();
  this.auditForm.get('customerId').updateValueAndValidity();
 }

 ClearAllFieldForModule(){
  this.operationArrayList=null;
  
  this.auditForm.get('operation').reset();
  this.auditForm.get('operation').updateValueAndValidity();

  }

  /**Operation List from Module List Obj*/
  showOperationDataByID(Obj){
    this.auditForm.get('operation').reset();
    this.auditForm.get('operation').updateValueAndValidity(); 
    this.operationArrayList=null;
    this.operationArrayList =Obj.aclOperationsList;
  }

   /*** Get Audit For */
  getAuditFor(){
    this.auditlog.getAuditForDataList().subscribe((res)=>{
      this.AuditForDataList=res.dataList.filter(res=>res.text != 'Partner');
    })
  }

  /*** User List By Audit For Select */
  showUserDataByID(objAuditFor){

    /**Reset the Value */
    this.usersList=null;
    this.custId = null;
    this.auditForm.get('customerId').reset();
    this.auditForm.get('customerId').updateValueAndValidity();  

    // User List 
    if(objAuditFor.value != 'Customer'){
      this.communicationReportService.staffuserByPartner(this.partner).subscribe((res)=>{
        this.usersList=res.staffUserlist;
      })
      this.getListWithParams()
      // this.auditlog.getAuditUserList(objAuditFor.value).subscribe((res)=>{
      //   this.usersList=res.dataList;
      // })
    }
    else if(objAuditFor.value == 'Customer'){
      this.subscriberList = "customers/byPartner?pid=" + this.partner + "&s="
      this.getListWithParams()
    }
  }

  resetAuditControl(){
      this.auditForm.reset();
      this.dataSource= new MatTableDataSource(JSON.parse(JSON.stringify([])));
      let fdate = new Date();
      let currentDate=new Date()
      this.defaultDate=new Date(fdate.setMonth(fdate.getMonth() - 1));
      // this.partner = this.partnerList[0].id;
      // this.auditForm.get('partner').setValue(this.partner);
      // this.auditForm.get('partner').updateValueAndValidity();
      this.auditForm.get('auditFor').disable();
      this.auditForm.get('auditFor').updateValueAndValidity();
      // this.auditForm.get('toDate').patchValue(currentDate);
      // this.auditForm.get('fromDate').patchValue(this.defaultDate);
      this.setDate();
      this.setDateFromDefault();
      if (this.Localpartnerid !== null && this.Localpartnerflag === "true") {
        this.partner = this.Localpartnerid;
        this.auditForm.get('partner').setValue(this.partner);
        this.auditForm.get('partner').updateValueAndValidity();
        this.auditForm.get('partner').disable();
      }
      // this.getListWithParams();
 }

  onExportToExcel() {
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();
  this.auditlog.excelServiceData(this.excelInputData).subscribe(blob => {
    saveAs(blob, "[audit log " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
  })
}
 
}
