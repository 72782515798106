import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { PricebookService } from './price-book.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-price-book',
  templateUrl: './price-book.component.html',
  styleUrls: ['./price-book.component.scss']
})
export class PriceBookComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('partner', { static: false }) patnerWrapperRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('partnrAssociated', { static: false }) partnrAssociatedRef: TemplateRef<any>;
  @ViewChild('priceBook', { static: false }) priceBookReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  associatePartnerlist: any[] = [];

  priceBookDataForGrid: any;
  priceBookNeoGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredPriceBookDataForGrid: any = [];
  constructor(
    private ngbModalService: NgbModal,
    private priceBookService: PricebookService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private searchService: SearchService,
    loginService: LoginService,
    private tosterservice:ToastrService
  ) {
    super(searchService);
    this.loginService = loginService;
  }
  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.PRICE_BOOK_LIST)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setGridSetting();
    this.initPaginationSort();
    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      // if (col.colKey == 'noPartnerAssociate') {
      //   col.customControls = [this.partnrAssociatedRef];
      // }
      if (col.colKey == 'noPartnerAssociate') {
        col.customControls = [this.patnerWrapperRef];
      }
      if (col.colKey == 'bookname') {
        col.customControls = [this.priceBookReff];
      }
    });
  }

  getPriceBookData(gridSetting: any) {
    let fetchSettingData = this.priceBookNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.priceBookService.getPricebookList(params).subscribe((result) => {
      this.priceBookDataForGrid = result.dataList;
      this.filteredPriceBookDataForGrid = JSON.parse(JSON.stringify(this.priceBookDataForGrid));
      this.refreshDataSource(this.priceBookDataForGrid);
      this.totalRecords = result.totalRecords;
      this.priceBookNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getPriceBookData(config);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.priceBookDataForGrid));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.priceBookDataForGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'Id',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'bookname',
        default: 'Price Book Name',
        rowKey: 'bookname',
        width: '14%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'noPartnerAssociate',
        default: 'Associate Partner Count',
        rowKey: 'noPartnerAssociate',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'noPartnerAssociate',
      //   default: 'Associated Partners(Count)',
      //   rowKey: 'noPartnerAssociate',
      //   width: '10%',
      //   customControls: [this.statusWrapperRef],
      //   customValue: (noPartnerAssociate) => {
      //     if (noPartnerAssociate != 0) {
      //       let noPartner = _.find(this.priceBookDataForGrid, { noPartnerAssociate: noPartnerAssociate });
      //       return noPartner.noPartnerAssociate;
      //     } else {
      //       return 0;
      //     }
      //   },
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'description',
      //   default: 'Description',
      //   rowKey: 'description',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'validFromString',
      //   default: 'Valid From',
      //   rowKey: 'validFromString',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'validToString',
      //   default: 'Valid To',
      //   rowKey: 'validToString',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'Active') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        //enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Active', 'InActive'],
          },
          {
            display_field: 'Active',
            value_field: 'Active',
          },
          {
            display_field: 'InActive',
            value_field: 'InActive',
          },
        ],
      },
      {
        display_field: 'Price Book',
        value_field: 'bookname',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];

    this.priceBookNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PRICE_BOOK_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this PriceBook ?',
      deleteMsgBoxDetail: 'PriceBook Name :${bookname} ',
    };
    if (this.priceBookNeoGridSettings.serverSideSortPagination && this.priceBookNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.priceBookNeoGridSettings.perPage);
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    if (event) {
      this.commonDeleteMsgBox(this.priceBookNeoGridSettings, event, this.delete.bind(this));
    }
  }
  onViewPartner(data, view, parnersCount) {
    if (parnersCount === 0) {
      this.tosterservice.error("Partner not associated");
    } else {
      this.associatePartnerlist = data;
      this.ngbModalService.open(view, { size: 'md' });
    }
  }

  delete(objEvent) {
    let self = this;
    let selected_network_devices_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.priceBookService.getPricebookDataById(selected_network_devices_data.id).subscribe((result: any) => {
        this.priceBookService.deletePricebook(result.data).subscribe(
          (data: any) => {
            if(data.responseCode !== 200){
              this.tosterservice.error(data.responseMessage);
            }
            else{
              this.tosterservice.success('Price Book  is deleted successfully');
              self.priceBookDataForGrid.forEach(function (item, index) {
                if (item.id === data.data.id) {
                  self.priceBookDataForGrid.splice(index, 1);
                }
              });
              self.refreshDataSource(this.priceBookDataForGrid);
            }
       
          }
        );

      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR + err.responseMessage);
      });
    }
  }

  onAddPriceBookClick() {
    this.router.navigate(['/plan-management/price_book/price_book_details']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/plan-management/price_book/price_book_details',rowData.id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getPriceBookData(config);
      }
}
