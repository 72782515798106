import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonListService } from '../../../../../src/app/services/common_list/common_list.service';
import { PropertyServiceService } from '../property-detail/service/property-service.service';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyDocumentListComponent } from '../property-document-list/property-document-list.component';


@Component({
  selector: 'app-property-document-upload',
  templateUrl: './property-document-upload.component.html',
  styleUrls: ['./property-document-upload.component.scss']
})
export class PropertyDocumentUploadComponent  implements OnInit {
 @ViewChild(PropertyDocumentListComponent,{ static: false }) propertyDocListComp: PropertyDocumentListComponent
 @Output() getlist: any = new EventEmitter();

 public documentStatusList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  documentTypeList: any[] = [];
  documentUploadTypeList: any[] = [];
  obj: any;
  dataid: any;
  leaselineid: any;
  propertyDetailsList: any[] = [];
  circuitDetailsList: any[] = [];
  dataCircuitid: any;
  submitted: any = null;
  insertCustomerDocumentForm = new FormGroup({
    property: new FormControl('', [Validators.required]),
    circuit: new FormControl('',),
    docType: new FormControl('',  [Validators.required]),
    docStatus: new FormControl('', [Validators.required]),
    remark: new FormControl('', [Validators.required]),
    filename: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
 
  constructor(
    private commonListService: CommonListService,
    private activeRoute: ActivatedRoute,
    private propertyService: PropertyServiceService,
    private commonservice: CommonListServicesService,
    private toasterservice: ToastrService,
    private ngbModalService:NgbModal
  ) { 
    
  }

  ngOnInit() {
    let id = this.activeRoute.snapshot.paramMap.get('id');
    this.leaselineid = id;
    this.getListOfProperty(id);
    this.getDocTypeList();
    this.getDocStatusList();
    this.getDocumentList();
  }

  getDocStatusList() {
    this.commonListService.getDocStatusList().subscribe((result) => {
      this.documentStatusList = result.dataList;
    });
  }
  getDocTypeList() {
    this.commonListService.getDocStatusList().subscribe((result) => {
      this.documentTypeList = result.dataList;
    });
  }

  getListOfProperty(id) {
    if (id) {
      this.dataid = Number(id)
    }
    this.propertyService.propertyList(this.dataid).subscribe(result => {
      if (result.responseCode === 200) {
        this.propertyDetailsList = result.dataList;
      }
    })
  }

  getDocumentList() {
    this.commonservice.getGenericCommonList('propertyDocType').subscribe((res) => {
      this.documentUploadTypeList = res.dataList;
    });
  }

  propertyBycircuit(obj) {
    this.insertCustomerDocumentForm.get('circuit').reset();
    this.circuitDetailsList=null;
    if (obj) {
      this.dataCircuitid = Number(obj.id)
    }
    this.propertyService.circuitList(this.dataCircuitid).subscribe(result => {
      if (result.responseCode === 200) {
        this.circuitDetailsList = result.dataList;
      }
    })
  }

  validateForm() {
    
    console.log(this.insertCustomerDocumentForm.value)
    if (!this.insertCustomerDocumentForm.valid) {
      Object.keys(this.insertCustomerDocumentForm.controls).forEach(field => {
        let control = this.insertCustomerDocumentForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      let formData: any = new FormData();
      let newFormData = Object.assign({}, this.insertCustomerDocumentForm.value);
      
      let docData: any = [{
        property: newFormData.property,
        circuit: newFormData.circuit,
        leasedline:Number(this.leaselineid),
        docType: newFormData.docType,
        docStatus: newFormData.docStatus,
        remark: newFormData.remark,
        filename: newFormData.filename.split('\\').pop()
      }];
      
      if (newFormData.fileSource.size <= 10000000) {
        formData.append("file", newFormData.fileSource);
        formData.append("propertyDocList", JSON.stringify(docData));
        
        this.propertyService.saveDocument(formData).subscribe(res => {
          if (res.responseCode !== 200 && res.responseMessage) {
            this.toasterservice.error(res.responseMessage);
          } else {
            this.toasterservice.success("Document Save Successfully");
            this.insertCustomerDocumentForm.reset();
            this.getlist.next(res.data); //refresh the Doc List
            this.close();
           }
        },
          err => {
            this.toasterservice.error(err.error.status + " " + err.error.ERROR);
          })
       } else {
        this.toasterservice.error("Attached  file size greater than 10MB.Please try again");
      }

    }
  }
  
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.insertCustomerDocumentForm.patchValue({
        fileSource: file
      });
    }
  }
  
  close(){
    this.ngbModalService.dismissAll();
  }

}
