import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Circuit } from '../model/Circuit';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../services/ibase-service';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { CircuitDetailsComponent } from '../circuit-details/circuit-details.component';
import { CircuitServiceService } from '../circuit-details/service/circuit-service.service';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
declare var $;
@Component({
  selector: 'app-add-update-circuit',
  templateUrl: './add-update-circuit.component.html',
  styleUrls: ['./add-update-circuit.component.scss']
})
export class AddUpdateCircuitComponent extends AbstractBaseAddUpdateComponent<Circuit> implements OnInit {
  @ViewChild(CircuitDetailsComponent, { static: false }) circuitREff: CircuitDetailsComponent;
  circuit = new Circuit();
  circuitDetail: FormGroup;
  @Input() propertyId;
  connectvityType: any[] = [];
  cicuitdList: any;
  @Input() editPropertyId;
  @Input() circuitId: any;
  @Input() circuitdata:any;
  deviceTypes: any[] = [];
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private activate: ActivatedRoute,
    private circuitService: CircuitServiceService,
    private genericDataSharingService: DataSharingServiceService,
  ) {
    super(toaster, router)
  }
  ngOnInit() {

    this.init();
    this.ConnectvityType();
    this.circuidId();
    this.deviceType();
    if(this.circuitdata){
      ////console.log("updat");
    }
  }
  init() {
    let id = +this.circuit;
    if (id) {
      this.getById(id);
      this.setData(this.getNewModal());
      this.setValidation();
      localStorage.removeItem('circuitId');
      this.setPropertyId();
      return;
    }
    this.setData(this.getNewModal());
    this.setValidation();
    this.setPropertyId();
  }
  setPropertyId() {
    ////console.log("setproperty");
    if (this.propertyId) {
      this.circuit.propertyLine = +localStorage.getItem('propertyId');
    }
    if (this.editPropertyId) {
      this.circuit.propertyLine = +localStorage.getItem("editpropertyId");
    }
  }
  getService(): IBaseService<Circuit> {
    return this.circuitService;
  }
  setData(data: Circuit) {
    this.circuit = data;
  }
  getData(): Circuit {
    return this.circuit;
  }
  getModuleHomeUrl() {
    $("#quickCaseModal").modal('hide');
  }
  navigateUrl(id) {
    this.router.navigate(['masters/subscriberLeased-property-detail/', id]);
  }
  getActiveRoute() {
    return this.activate;
  }
  getNewModal() {
    return new Circuit();
  }
  getFormGroup(): FormGroup {
    return this.circuitDetail;
  }
  setValidation() {
    this.circuitDetail = new FormGroup({
      circuitId: new FormControl('', Validators.required),
      circuitidentity: new FormControl(''),
      bandwith_policyName: new FormControl(''),
      static_Ippool: new FormControl(''),
      connectvityType: new FormControl(''),
      connectvityValuetype: new FormControl(''),
      typeDevice: new FormControl(''),
      otherValue: new FormControl(''),
    })
  }
  circuidId() {
    this.circuitService.generateCircuitid('Internet').subscribe(res => {
      this.circuit.circuitid = res.data.circuit_id;
      this.circuit.circuitidentity=res.data.circuit_id;
    })
  }
  getId(): number {
    return this.circuit.id;
  }
  get f() {
    return this.circuitDetail.controls;
  }
  formSubmit() {
    if (this.validateForm()) {
      this.onSubmit();
    }
  }
  ConnectvityType() {
    this.circuitService.getConnectvityType().subscribe(response => {
      this.connectvityType = response.dataList;
    })
  }
  deviceType() {
    this.circuitService.getDeviceType().subscribe(response => {
      this.deviceTypes = response.dataList;
    })
  }



getGenericDataService(){
return this.genericDataSharingService
}

}
