import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as alertify from 'alertifyjs';
import { statSync } from 'fs';
import { ToastrService } from 'ngx-toastr';
import _ from 'underscore';
import { StaffService } from '../staff/staff.service';
import { HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogPassData{
 id:number; 
}

import { RecentRenewalService } from '../../reports/recent-renewal.service';
import { log } from 'console';
import { CommonStatusService } from '../../../services/common_status/common-status.service';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { ServiceAreaServices } from '../../../services/service_area/service_area.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  saveBtn: boolean = false;
  public sub: any = null;
  Partner: any
  staffDataForGrid: any = { content: [] };
  public staff_id: any = null;
  show_update: any = false;
  selectForUpdate: any;
  error_message = "This field is required.";
  isDisabled:boolean=false;
  teams: any[] = [];
  roleList: any[] = [];
  partnerList: any[] = [
    { id: '', name: '' }
  ];
  statusList: any
  userForm: FormGroup;
  phoneValueExist: boolean = false;
  errorShow: boolean = false;
  errorEmailShow: boolean = false;
  invUsernameApi: boolean = false;
  invUserNameMessage = '';
  responsemsg: any;
  responsemail: any;
  isUnavailable:boolean;
  defaultCollector:boolean = false;
  isManageable:boolean = false;
  childPartnerList:[];
  serviceAreaList:[];
  isPartnerFlag:any;
  lspartnerId:any;
  constructor(
    private router: Router,
    private recentRemewalService: RecentRenewalService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private staffService: StaffService,
    private tosterservice: ToastrService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddUserComponent>,
    private sharingdataService: DataSharingServiceService,
    private serviceAriaService: ServiceAreaServices,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,

    public CommonStatus: CommonStatusService,
  ) {
    this.isPartnerFlag= localStorage.getItem('partnerFlag')
    this.lspartnerId =localStorage.getItem('partnerId')
    
   }

  ngOnInit() {
    this.userForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('',[Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,Validators.email]),
      roleIds: new FormControl([], [Validators.required]),
      serviceAreaIdList: new FormControl([]),
      status: new FormControl(null, [Validators.required]),
      partnerid: new FormControl(null),
      phone: new FormControl('', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      empCode:new FormControl(''),
      partnerIdList:new FormControl([])
    });
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.staff_id = id;
      this.show_update = true;
      this.isDisabled=false;
      this.getStaffData();
    }
    // if(this.data && this.data.id){
    //   this.staff_id = this.data.id;
    //   this.show_update = true;
    //   this.isDisabled=true;
    //   this.getStaffData();
      
    // }
    this.getStatusList();
    //this.getserviceAreaList();
    // this.userForm = new FormGroup({
    //   username: new FormControl('', [Validators.required]),
    //   password: new FormControl('', [Validators.required]),
    //   firstname: new FormControl('', [Validators.required]),
    //   lastname: new FormControl('', [Validators.required]),
    //   email: new FormControl('', [Validators.required, Validators.email]),
    //   roleIds: new FormControl(null, [Validators.required]),
    //   serviceAreaIdList: new FormControl([]),
    //   teamIds: new FormControl(null),
    //   status: new FormControl(null, [Validators.required]),
    //   partnerid: new FormControl(null),
    //   phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    // });
    this.getPartnerList();
    // this.staffService.getAllRoleData().subscribe(result => {
    //   this.roleList = result.dataList;
    //   console.log("role-on Init",this.roleList)
    // })
    

   //or change

    this.staffService.getTeamsData().subscribe(result => {
      this.teams = result.dataList;
    });
  }

  getStatusList(){
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.statusList = res.dataList;
    });
  }

  getPartnerList() {
    let partnerId = localStorage.getItem('partnerId')
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.recentRemewalService.getPartnerlistService().subscribe(result => {
        this.partnerList = result.partnerlist;
      })
    }
    else{
      this.recentRemewalService.getManagablePartnerlistService().subscribe(result => {
        this.partnerList = result.dataList;
        //  if (partnerId) {
             this.Partner = Number(partnerId);
             this.getserviceAreaList(this.Partner);
             let data=[this.Partner]
             this.staffService.getAllRoleDataForPartner(data).subscribe(result => {
               this.roleList = result.dataList;        
             })
        //     this.userForm.get('partnerid').setValue(this.Partner)
        //     this.userForm.get('partnerid').disable();
        //  }
      })
    }
    // if (!this.show_update) {
    //   setTimeout(() => {
    //     if (isPartner === 'true') {
         
    //       }
    //     }
    //   }, 500);
    // }

  }

  getChildPartnerList(id) {
    if(this.lspartnerId == 1){
        this.recentRemewalService.getChildPartnerlistService(id).subscribe(result => {
        this.childPartnerList = result.dataList;
      })
    }
  }
  

  getserviceAreaList(id?){
    if(id){
      this.serviceAriaService.getPlanServiceArea(id).subscribe((res) => {
        this.serviceAreaList = res.dataList;
      })
    }else{
      this.serviceAriaService.getServiceAreaList().subscribe((res)=>{
        this.serviceAreaList = res.dataList;
      })
    }
  }

  // onPartnerChange(event) {
  //   this.userForm.get('serviceAreaIdList').reset();
  //   if (event && event.id) {
  //     this.userForm.get('partnerid').setValue(event.id)
  //     this.staffService.getAllRoleData(event.id).subscribe(result => {
  //       this.roleList = result.dataList;
  //     })
  //     this.userForm.get('roleIds').setValue(null)
  //     this.getserviceAreaList(event.id);
  //   }
  //   else {
  //     this.staffService.getAllRoleData().subscribe(result => {
  //       this.roleList = result.dataList;
  //     });
  //   }
  // }

  onPartnerChange(event) {
    this.serviceAreaList= [];
    this.childPartnerList = [];
    this.roleList = [];
    this.userForm.get('serviceAreaIdList').reset();
    this.userForm.get('partnerIdList').reset();
    this.userForm.get('roleIds').reset();
    if (event && event.id) {
      this.getChildPartnerList(event.id)
      this.userForm.get('partnerid').setValue(event.id)
      // this.staffService.getAllRoleData(event.id).subscribe(result => {
      //   this.roleList = result.dataList;
      //   console.log("onPartner Change1",this.roleList);
        
      // })
     
      let data=[event.id]
      this.staffService.getAllRoleDataForPartner(data).subscribe(result => {
        this.roleList = result.dataList;        
      })
      // this.userForm.get('roleIds').setValue(null)
      this.getserviceAreaList(event.id);
    }
    else {
      // this.staffService.getAllRoleDataForAdmin().subscribe(result => {
      //   this.roleList = result.dataList;
      //   console.log("onPartner Change2",this.roleList);
      // });
      //this.getserviceAreaList();
    }
  }
  
  validateForm() {

    if (this.show_update) {
      this.userForm.get('password').clearValidators();
      this.userForm.get('password').updateValueAndValidity();
    }
    if (!this.userForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.userForm.controls).forEach(field => {
        const control = this.userForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.staff_id === null) {
        this.insertStaffOnDb();
      } else {
        this.updateStaffOnDb();
      }
    }
  }

  getStaffData() {
    let staff_id = this.staff_id;
    this.staffService.getStaffById(staff_id).subscribe(
      result => {
        this.setUpdateValueInForm(result.Staff);
        this.isUnavailable = result.Staff.isUnavailable;
        this.defaultCollector = result.Staff.defaultCollector;
        this.isManageable =  result.Staff.isManageable;
      });
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.type === 'email' && ctrlValue.which === 32) {
      ctrlValue.preventDefault();
    }
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  setUpdateValueInForm(staff_Data) {
    this.show_update = true;
    this.selectForUpdate = staff_Data;
    if(staff_Data){
      this.getserviceAreaList(staff_Data.partnerid);
      this.getChildPartnerList(staff_Data.partnerid);  
      if(this.isPartnerFlag ==="true"){
        let data=[Number(this.lspartnerId)]
        this.staffService.getAllRoleDataForPartner(data).subscribe(result => {
        this.roleList = result.dataList;
      })
      }else{
        let data=[Number(staff_Data.partnerid)]
        this.staffService.getAllRoleDataForPartner(data).subscribe(result => {
          this.roleList = result.dataList;
        })
      }
    }

    this.userForm.patchValue(staff_Data);
  }

  insertStaffOnDb() {
    // this.checkUniqueUsername_onSave();
    setTimeout(() => {
      if (!this.errorShow === true && !this.errorEmailShow === true && !this.invUsernameApi === true) {
        if (this.userForm.valid === true) {
          this.saveBtn = true;
          let formatedInputData: any = this.userForm.value;
          formatedInputData ['isUnavailable'] = this.isUnavailable
          formatedInputData ['defaultCollector'] = this.defaultCollector
          formatedInputData ['isManageable'] = this.isManageable
          this.staffService.insertStaff(formatedInputData).subscribe(result => {
            if (result.status !== 200) {
              this.saveBtn = false;
              this.tosterservice.error(result.ERROR)
              //  this.phoneValueExist=true;
            }
            else {
              this.saveBtn = false;
              //  this.phoneValueExist=false;
              this.cdRef.detectChanges();
              this.onBackToStaff();
              this.tosterservice.success('User has been created successfully');
            }
          },
            err => {
              this.saveBtn = false;
              this.tosterservice.error(err.error.status + " " + err.error.ERROR);
            });
        }
      }
    }, 500);
  }

  updateStaffOnDb() {
    setTimeout(() => {
      if (!this.errorShow === true && !this.errorEmailShow === true && !this.invUsernameApi === true) {
        if (this.userForm.valid === true) {
          this.saveBtn = true;
          ////console.log("update", this.userForm);
          let apiInputData = Object.assign({}, this.userForm.value);
          let staff_id = this.staff_id;
          let formatedInputData: any = this.userForm.value;
          formatedInputData ['isUnavailable'] = this.isUnavailable
          formatedInputData ['defaultCollector'] = this.defaultCollector
          formatedInputData ['isManageable'] = this.isManageable
          formatedInputData.id = staff_id;
          this.staffService.updateStaff(formatedInputData, staff_id).subscribe(result => {
            if (result.status !== 200) {
              this.saveBtn = false;
              this.tosterservice.error(result.ERROR);
            }
            else {
              this.saveBtn = false;
              this.cdRef.detectChanges();
              this.show_update = false;
              this.onBackToStaff();
              ////console.log(result);
              this.tosterservice.success('User Update successfully');
            }

          },
            err => {
              this.saveBtn = false;
              this.tosterservice.error(err.error.status + " " + err.error.ERROR);
            });
        }
      }
    }, 500);
  }
  clearMsgUnique() {
    if (this.invUsernameApi === true &&
      this.userForm.get('username').valid === false) {
      this.invUserNameMessage = '';
    }
    if (this.invUsernameApi === true &&
      this.userForm.get('username').valid === false) {
      this.invUserNameMessage = '';
    }
  }

  onBackToStaff() {
    //this.router.navigate(['hr/staff']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['hr/staff']);
    }
  }

  phoneNumberExist(event) {

    let phonevalue = event.target.value;
    if (phonevalue.length < 10 || phonevalue.length > 10) {
      this.tosterservice.error("Mobile number must be 10 digit" + "you have enter" + phonevalue.length);

    }
    else {
      if (this.staff_id != null) {
        this.staff_id = this.staff_id;
      }
      if (phonevalue !== null && phonevalue !== undefined && phonevalue !== "") {
        let dataObj = {
          "id": this.staff_id ? this.staff_id : null,
          "phone": phonevalue
        }
        this.staffService.checkUniqueMobile(dataObj).subscribe(
          (result) => {
            if (result.status === 200) {
              // this.errorShow = false;
            }
          }, err => {
            this.tosterservice.error("Phone Number Already Exist");
            // this.errorShow = true;
            this.responsemsg = err.error.ERROR;
          }
        )
      }
    }

  }


  duplicateEmailExist(event) {

    let emailValue = event.target.value;
    if (this.staff_id != null) {
      this.staff_id = this.staff_id;
    }
    if (emailValue !== "" && emailValue !== undefined) {
      if (emailValue !== null) {
        var dataObj = {
          "id": this.staff_id ? this.staff_id : null,
          "email": emailValue
        }

        this.staffService.checkUniqueEmail(dataObj).subscribe(
          (result) => {
            if (result.status === 200) {
              this.errorEmailShow = false;
            }
          }, err => {
            this.tosterservice.error(err.error.ERROR);
            this.errorEmailShow = true;
            this.responsemail = err.error.ERROR;
          }
        )
      }
    }

  }

  duplicateUserNameExist(event) {

    let userValue = event.target.value;
    if (this.staff_id != null) {
      this.staff_id = this.staff_id;
    }
    if (userValue !== "" && userValue !== undefined) {
      if (userValue !== null) {
        var dataObj = {
          "id": this.staff_id ? this.staff_id : null,
          "userName": userValue
        }
        this.staffService.checkUniqueUsername(dataObj).subscribe(
          (result) => {
            if (result.status === 200) {
              this.invUsernameApi = false;
            }
          }, err => {
            this.tosterservice.error(err.error.ERROR);
            this.invUsernameApi = true;
            this.invUserNameMessage = err.error.ERROR;
          }
        )
      }
    }
  }
  closeDialog(){
    this.dialogRef.close();
  }
  isUnavilable(event){
    if(event){
      this.isUnavailable = event.checked
    }
  }

  isDefaultCollector(event){
    if(event){
      this.defaultCollector = event.checked
    }
  }

  isMultiPartner(event){
    this.userForm.get('partnerIdList').reset();
    if(event){
      this.isManageable = event.checked
    }
  }
}
