import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomModalService } from '../../../../components/custom-modal';
import { PricebookService } from '../price-book.service';
import * as alertify from 'alertifyjs';
import { LoginService } from './../../../login/login.service';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../../core/constant/response-code';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';
import { PackagesService } from '../../packages/packages.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { runInThisContext } from 'vm';
import { DataSharingServiceService } from '../../../../pages/data-sharing-service.service';
import {ChangePlanService} from '../../../masters/subscriber-details/change-plan/change-plan.service';
import { Console } from 'console';

@Component({
  selector: 'app-price-book-details',
  templateUrl: './price-book-details.component.html',
  styleUrls: ['./price-book-details.component.scss']
})
export class PriceBookDetailsComponent implements OnInit {
  saveBtn: boolean = false;
  planListAll: any;
  planList = [
    {
      packages_id: '',
      name: '',
      offerprice: '',
      partnerofficeprice: '',
      partnertax:'',
      taxId:''
      // revenueSharing: '',
      // registration: '',
      // renewal: ''
    }
  ];
  closeResult = '';
  public sub: any = null;
  public price_book_id: any = null;
  isDisabled:boolean=false;
  show_update: boolean = false;
  selectForUpdate: any;
  priceBookData: any;
  planCount: any = 0;
  showUpdate: boolean = false;
  planListallocated: any = [];
  planListSelected: any = [];
  openAddPlanModel: boolean = false;
  isPriceBookType: boolean = true;
  AclClassConstants = AclClassConstants;
  priceValid: boolean;
  AclConstants = AclConstants;
  commonStatus: any;
  EditFlag: boolean = false;
  req_message = "This Field is Required"
  public loginService: LoginService;
  pricebookType: any
  pricebookTypeList: any = [];
  isDisableflag:boolean=false;
  taxCalculationOnPartnerPrice:Boolean = false;
  tempflag:boolean=true;
  constructor(private customModalService: CustomModalService,
    private router: Router,
    private route: ActivatedRoute,
    private priceBookService: PricebookService,
    private tosterservice: ToastrService,
    private datepipe: DatePipe,
    //loginService: LoginService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<PriceBookDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    private packagesService: PackagesService,
    public modalService: NgbModal,
    private sharingdataService: DataSharingServiceService,
    private ChangePlanService : ChangePlanService,
    loginService: LoginService
  ) {
    this.loginService = loginService;
  }
  insertPriceBookForm = new FormGroup({
    bookname: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    pricebookType: new FormControl(null, [Validators.required]),
    description: new FormControl('', [Validators.required]),
    // validfrom: new FormControl(null,[Validators.required]),
    // validto: new FormControl(null,[Validators.required]),
    isDeleted: new FormControl(false),
    priceBookPlanDetailList: new FormArray([]),
    calculteTaxOnPartnerPrice: new FormControl(false)
  });

  istokenAmount: boolean = false;

  onPricebookTypeChange(event) {
    this.pricebookType = event.value
    ////console.log()
    if (event.value === 'Token Amount' || event.value === 'Revenue Share') {
      this.istokenAmount = true;
    } else {
      this.istokenAmount = false;
    }
  }

  ngOnInit() {
    this.getPlanList();
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.insertPriceBookForm.get('calculteTaxOnPartnerPrice').disable();
      this.price_book_id = id;
      this.show_update = true;
      ////console.log("show update");
      this.getPriceBookDataById();
    }
    // if(this.data && this.data.id){
      
    //   this.isDisabled=true;
    //   this.price_book_id = this.data.id;
    //   this.show_update = false;
    //   this.getPriceBookDataById();
    //   this.insertPriceBookForm.disable();
    // }
    // this.sub = this.route
    // .queryParams
    // .subscribe(params => {
    //   let id = params['id']
    //   if (id) {
    //     this.price_book_id = params['id'];
    //     this.show_update = true;
    //     this.getPriceBookDataById();
    //   }
    // });
    this.priceBookService.commonStatus().subscribe(res => {
      this.commonStatus = res.dataList;
    })

    this.priceBookService.getPricebookTypeList().subscribe(res => {
      this.pricebookTypeList = res.dataList;
    })
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }
  getPlanList() {
    this.packagesService.getPackagesList().subscribe(result => {
      let planList = result.content;
      this.planListAll = result.content;
      // if (this.planListallocated.length != 0) {
      //   let packages_ids =  _.pluck(this.listOfPlan,"packages_id");
      //   let filterPlanList = _.filter(planList,function(item) {
      //     if (packages_ids.indexOf(item.packages_id) === -1) {
      //       return item;
      //     }
      //   });
      //   ////console.log("filter::",filterPlanList)
      //   this.planList = filterPlanList;
      //     this.goToPage(1);
      // } else {
      //   ////console.log("filter22::",planList)
      //   this.planList = planList;
      //   this.goToPage(1);
      // }
    })
  }

  getPriceBookDataById() {
    this.priceBookService.getPricebookDataById(this.price_book_id).subscribe(
      result => {
        this.priceBookData = result.data;
      
        this.insertPriceBookForm.get('calculteTaxOnPartnerPrice').setValue(this.priceBookData.calculteTaxOnPartnerPrice);
        this.taxCalculationOnPartnerPrice = this.priceBookData.calculteTaxOnPartnerPrice;
        if (this.priceBookData.pricebookType === 'Token Amount' || this.priceBookData.pricebookType === 'Revenue Share') {
          this.istokenAmount = true;
        } else {
          this.istokenAmount = false;
        }
        this.insertPriceBookForm.get('pricebookType').disable();/**rm Vijay */
        this.setUpdateValueInForm(this.priceBookData);
      }
    );
  }

  numberOnly(event,Item:FormGroup): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {

      return false;
    }
    if(this.taxCalculationOnPartnerPrice){
      setTimeout(() => {    
        let obj = {
          amount: Item.value.partnerPriceWithTax,
          taxId: Item.value.postpaidPlan.taxId
        };
        if(typeof(obj.taxId)=='object'){
          if(obj.taxId.value!=null){
            obj.taxId = Item.value.postpaidPlan.taxId.value
            // this.ChangePlanService.getReverseTaxByAmount(obj).subscribe((res) => {
            //   Item.get('partnerPriceTax').setValue( Item.get('partnerPriceWithTax').value - Number(res.ReverseTaxAmount));
            // });
          } 
        }
        else{
          if(obj.taxId){
            // this.ChangePlanService.getReverseTaxByAmount(obj).subscribe((res) => {
            //   Item.get('partnerPriceTax').setValue( Item.get('partnerPriceWithTax').value - Number(res.ReverseTaxAmount));
            // });
          }
        }
      }, 100);
    }
    return true;
  }

  onAddPlanItems(row?) {
    
    let postPaidPlanAmount = row && row.postpaidPlan ? row.postpaidPlan.taxamount : '';
    (<FormArray>this.insertPriceBookForm.get('priceBookPlanDetailList')).push(
      new FormGroup({
        id: new FormControl(''),
        postpaidPlan: new FormGroup({
          id: new FormControl(row ? row.id : ''),
          name: new FormControl({ value: row ? row.name : '', disabled: true }),
          taxamount: new FormControl(postPaidPlanAmount),
          taxId: new FormControl({value: row ? row.taxId : null})
        }),
        offerprice: new FormControl(row ? row.offerprice : ''),
        allowCustomer: new FormControl(row ? row.allowCustomer : false),
        //  partnerofficeprice : new FormControl(row ? row.partnerofficeprice :''),
        partnerofficeprice: new FormControl({ value: row ? row.partnerofficeprice : '', disabled: this.istokenAmount }, [Validators.required, Validators.minLength(0), Validators.maxLength(10)]),
        // revsharen: new FormControl(row ? row.revsharen : true),
        // registration: new FormControl(row ? row.registration : true),
        // renewal: new FormControl(row ? row.renewal : true),
        partnerPriceWithTax: new FormControl({value:row?row.partnerofficeprice + row.partnerPriceTax  :'', disabled: this.istokenAmount}, [Validators.required, Validators.minLength(0), Validators.maxLength(10)]),
       // partnerPriceTax: new FormControl(row?row.partnerPriceTax:''),removed
        isEdit: new FormControl(false),
        isDeleted: new FormControl(false),
      })
    );
    console.log('form array =>',this.insertPriceBookForm.get('priceBookPlanDetailList'));
  }

  get planItems() {

    let plan = (this.insertPriceBookForm.get('priceBookPlanDetailList') as FormArray).controls;
    return plan;
  }

  validateForm() {
    
    if (!this.insertPriceBookForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertPriceBookForm.controls).forEach(field => {
        const control = this.insertPriceBookForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {

      if (this.price_book_id === null) {
        let planList = this.insertPriceBookForm.get('priceBookPlanDetailList');
        if (planList.value.length === 0) {
          this.tosterservice.error("Please select atleast one plan");
          return;
        }
        else {
          this.insertNewPriceBook();
        }

      } else {
        
        let planList = this.insertPriceBookForm.get('priceBookPlanDetailList');
         
        if (planList.value.length === 0) {
          this.tosterservice.error("Please select atleast one plan");
          return;
        } else {
          this.updatePriceBook();
        }
      }
    }
  }
  // registration(event, Item: FormGroup) {
  //   if (event.target.checked === true) {
  //     Item.get('registration').setValue('Yes');
  //   } else if (event.target.checked === false) {
  //     Item.get('registration').setValue(false);
  //   }
  // }
  // renewal(event, Item: FormGroup) {
  //   if (event.target.checked === true) {
  //     Item.get('renewal').setValue('Yes');
  //   } else if (event.target.checked === false) {
  //     Item.get('renewal').setValue(false);
  //   }
  // }
  // revsharen(event, Item: FormGroup) {
  //   if (event.target.checked === true) {
  //     Item.get('revsharen').setValue('Yes');
  //   } else if (event.target.checked === false) {
  //     Item.get('revsharen').setValue(false);
  //   }
  // }

  setUpdateValueInForm(addPriceBookData) {
    console.log('getById =>',addPriceBookData);
    this.showUpdate = true;

    for (let data of addPriceBookData.priceBookPlanDetailList) {
      // if (data.revsharen === 'Yes') {
      //   data.revsharen = true;
      // } else {
      //   data.revsharen = false;
      // }
      // if (data.registration === 'Yes') {
      //   data.registration = true;
      // } else {
      //   data.registration = false;
      // }
      // if (data.renewal === 'Yes') {
      //   data.renewal = true;
      // } else {
      //   data.renewal = false;
      // }
      ////console.log("data",data)
      
      let formateData = {

        offerprice: data.offerprice,
        partnerofficeprice: data.partnerofficeprice,
        allowCustomer: data.allowCustomer,
        // revsharen: data.revsharen,
        // registration: data.registration,
        // renewal: data.renewal,
        name: data.postpaidPlan.name,
        packages_id: data.postpaidPlan.id,
        taxamount: data.postpaidPlan.taxamount,
        partnerPriceWithTax:data.partnerofficeprice,
        //partnerPriceTax: data.partnerPriceTax,
        taxId: data.postpaidPlan.taxId

      };
      this.planListSelected.push(formateData)
      this.onAddPlanItems(data);
    } 
    this.insertPriceBookForm.patchValue(addPriceBookData);
    this.planListallocated.push();
   
   
  }

  setValueInForm(addPlan) {
    
    for (let data of addPlan) {
      let postpaidPlan = {
        taxamount: data.taxamount
      }
     
      let formateData = {
        offerprice: data.offerprice,
       // partnerofficeprice: data.partnertaxamountwith,//old code  //partnerPrice is child different key partnerPrice
       //partnerofficeprice: data.partnerofficeprice ? data.partnerofficeprice: data.partnerPrice, old workcode -update 
       partnerofficeprice: data.partnerPrice,
       allowCustomer: data.allowCustomer,
        // revsharen: data.revsharen,
        // registration: data.registration,
        // renewal: data.renewal,
        name: data.name,
        id: data.packages_id,
        taxamount: data.taxamount,
        taxId:data.taxId,
        partnerPriceWithTax:data.partnerPrice,
       // partnerPriceTax: data.partnerPrice - data.partnertaxamountwith,
        postpaidPlan: postpaidPlan
      };
      console.log("formatedData-->",formateData)
      
      this.onAddPlanItems(formateData);
      this.planListallocated.push(data);
      this.planListSelected.push(data);
      
    }
    this.insertPriceBookForm.patchValue(addPlan);
  }

  receivePlan(data) {
    
    this.planList = data;
    this.setValueInForm(this.planList);
    if(data.length != 0){
      this.insertPriceBookForm.get('pricebookType').disable(); 
      }
    else{
      this.insertPriceBookForm.get('pricebookType').enable();
    }
}

  insertNewPriceBook() {
    if (this.insertPriceBookForm.valid === true) {
      let formatedInputData = this.insertPriceBookForm.getRawValue();

      // let createdStartDate = this.datepipe.transform(this.insertPriceBookForm.value.validfrom, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
      // let createdEndate = this.datepipe.transform(this.insertPriceBookForm.value.validto, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
      // formatedInputData.validfrom = createdStartDate;
      // formatedInputData.validto = createdEndate;
        formatedInputData.priceBookPlanDetailList.forEach(el => {
          if(formatedInputData.pricebookType == 'Fixed'){
            //Number(el.partnerofficeprice) > Number(el.offerprice)+ Number(el.postpaidPlan.taxamount)
            // if (el.partnerPriceWithTax > (el.offerprice + el.postpaidPlan.taxamount)) old code 

            if (Number(el.partnerofficeprice) > (Number(el.offerprice) + Number(el.postpaidPlan.taxamount))) 
            {
              this.priceValid = false;
              this.tosterservice.error('Partner price can not be greater than offer price with tax');
              return;
            } else {
              this.priceValid = true;
            }
          }else{
            this.priceValid = true;
          }
          if(el.postpaidPlan.taxId){
            el.postpaidPlan.taxId =   el.postpaidPlan.taxId.value;
          }
  
        }); 
        if (this.priceValid) {
        this.saveBtn = true;
        formatedInputData.priceBookPlanDetailList = this.setPricebookOfferPrice(formatedInputData.priceBookPlanDetailList);
        this.priceBookService.insertPricebook(formatedInputData).subscribe(result => {
          if (result.responseCode === ResponseCode.OK) {
            this.saveBtn = false;
            this.tosterservice.success('PriceBook has been created successfully');
            this.backtoPriceBook();
          }
          else {
            this.saveBtn = false;
            this.tosterservice.error(result.responseMessage);
          }
        },
          err => {
            this.saveBtn = false;
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }
    }
  }

  setPricebookOfferPrice(dataList){
    dataList.forEach((ls)=>{
      
      if(this.taxCalculationOnPartnerPrice)
        //ls.partnerofficeprice = ls.partnerPriceWithTax - ls.partnerPriceTax
        ls.partnerofficeprice = ls.partnerofficeprice
      else{
        ls.partnerofficeprice = ls.partnerofficeprice
        //ls.partnerofficeprice = ls.partnerPriceWithTax - ls.postpaidPlan.taxamount;
      //  ls.partnerPriceTax = ls.partnerPriceWithTax - ls.partnerofficeprice
      }
    });
    return dataList;
  }

  updatePriceBook() {
 this.insertPriceBookForm.get('pricebookType').enable(); /**rm:vijay */
 let formatedInputData = this.insertPriceBookForm.value;
 this.tempflag = true
 formatedInputData.priceBookPlanDetailList.forEach(el=>{
  
  // if (Number(el.partnerPriceWithTax) > Number(el.offerprice)+ Number(el.postpaidPlan.taxamount)){
  //   tempflag = false
  //   return;
  // } 
  // || el.partnerofficeprice < 1 Not Allowed Zero code
  if(el.isDeleted==true){
        el.partnerofficeprice=el.offerprice
  }
  console.log("el",el.partnerofficeprice.value)
  if (Number(el.partnerofficeprice) > Number(el.offerprice)+ Number(el.postpaidPlan.taxamount) ){
    this.tempflag=false;
    return false;
  } 

  
  if(el.postpaidPlan.taxId){
    el.postpaidPlan.taxId = el.postpaidPlan.taxId.value
  }

 })
 if (this.insertPriceBookForm.valid === true && this.tempflag) {
 
      formatedInputData.id = parseInt(this.price_book_id);
      ////console.log("demo", formatedInputData.priceBookPlanDetailList)
      formatedInputData.calculteTaxOnPartnerPrice = this.insertPriceBookForm.get('calculteTaxOnPartnerPrice').value;
      formatedInputData.priceBookPlanDetailList = this.setPricebookOfferPrice(formatedInputData.priceBookPlanDetailList);
      
        this.priceBookService.updatePricebook(formatedInputData).subscribe(result => {
            if (result.responseCode !== 200 && result.responseMessage) {
              this.saveBtn = false;
             //this.tosterservice.error(result.responseMessage);
            } else {
              this.saveBtn = false;
              this.tosterservice.success('PriceBook Update successfully');
              this.backtoPriceBook();
            }
          },
            err => {
            //this.tosterservice.error(err.error.status + " " + err.error.ERROR);
            });
   }else{

    this.tosterservice.error('Partner price can not be greater than offer price with tax');
   }

  }

  openModal(id: string) {
    this.insertPriceBookForm.markAllAsTouched()
    if (this.insertPriceBookForm.valid) {
      if (id === 'addplan') {
        this.openAddPlanModel = true;
      }
    }
    else {
      this.tosterservice.error('Please fill all the details!');
    }
    setTimeout(() => {
      this.customModalService.open(id);
    }, 500);
    for (let data of this.insertPriceBookForm.get('priceBookPlanDetailList').value) {
      this.planListallocated.push(data);
    }
  }
  backtoPriceBook() {
    //this.router.navigate(['/plan-management/price_book']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/plan-management/price_book']);
    }
  }
  closeModal(id: any) {

    if (id && id.close_from === "close_button") {
      ////console.log("close_from:close_button");
    }
    this.planListallocated = [];
    this.openAddPlanModel = false;
  }
  openEdit(Item: FormGroup) {
    Item.get('isEdit').setValue(true);
    //Item.get('partnerofficeprice').setValue( Item.get('partnerofficeprice').value +  Item.get('partnerPriceTax').value)
   //this.onChangePartnerPrice(Item) new changes
  }

  onChangePartnerPrice(Item: FormGroup){   
    if(this.taxCalculationOnPartnerPrice){
      let obj = {
        amount: Item.value.partnerPriceWithTax,
        taxId: Item.value.postpaidPlan.taxId
      };
      if(typeof(obj.taxId)=='object'){
        if(obj.taxId.value!=null){
          obj.taxId = Item.value.postpaidPlan.taxId.value
        
          // this.ChangePlanService.getReverseTaxByAmount(obj).subscribe((res) => {
          //   Item.get('partnerPriceTax').setValue( Item.get('partnerPriceWithTax').value - Number(res.ReverseTaxAmount));
          // });
        } 
      }
      else{
        if(obj.taxId){
        
          //  this.ChangePlanService.getReverseTaxByAmount(obj).subscribe((res) => {
          //    Item.get('partnerPriceTax').setValue( Item.get('partnerPriceWithTax').value - Number(res.ReverseTaxAmount));
          //  });
        }
      }
    }
  }

  closeEdit(Item: FormGroup) {
    
    // if (Item.value.partnerPriceWithTax > 0) {
      // if ((Item.value.offerprice + Item.value.taxamount) < Item.value.partnerPriceWithTax) {
        if (Number(Item.value.partnerofficeprice) > Number(Item.value.offerprice + Item.value.postpaidPlan.taxamount)) {
        this.EditFlag = true;
        Item.get('isEdit').setValue(true);
      } else {
         this.EditFlag = false;
         Item.get('isEdit').setValue(false);
      }
    //}
  }

  deletePlan(Item: FormGroup, index) {

    this.tempflag=true;    
    let planName = Item.get('postpaidPlan').get('name').value;
    let id = Item.get('postpaidPlan').get('id').value;
        
    let i = this.planListSelected.findIndex(res => res.packages_id === id);
    this.planListSelected.splice(i, 1)
    
    if (this.planListAll && this.planListAll.length !== 0) {
      this.planListAll.forEach((res) => {
        if (res.packages_id == id) {
          res.isplancheck = false;
        }
      })
    }
    
    alertify.confirm("Are you sure you want to delete this plan ?", "Plan Name:" + planName,
      function () {
        Item.get('isDeleted').setValue(true);
        },
      function () {
      });

      if(this.planListSelected.length < 1 && !this.show_update ){
        this.insertPriceBookForm.get('pricebookType').enable();
      }

  }
  

  get f() {
    return this.insertPriceBookForm.controls;
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  closeDialog(){
    this.dialogRef.close();
  }

  //Flag Value For Calculate Price

    changeTaxCalculationPrice(event){
      this.insertPriceBookForm.get('calculteTaxOnPartnerPrice').setValue(event.checked);
      if(event.checked && this.planItems && this.planItems.length > 0){
        this.planItems.forEach((ls) => {
          console.log('ls =>', ls)
          let data: any = ls;
          this.onChangePartnerPrice(data);
        })
      }
    }
}
