import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
import _ from 'underscore';
import { CustomModalService } from '../../../../components/custom-modal';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { IptvDetailsService } from './iptv-details.service';

@Component({
  selector: 'app-iptv-details',
  templateUrl: './iptv-details.component.html',
  styleUrls: ['./iptv-details.component.scss']
})
export class IptvDetailsComponent implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('defaultSelect', { static: false }) defaultSelRef: TemplateRef<any>;
  @Input() custId: any;
  @Input() screenOrigin: string;
  submitted:any = null;
  taxDetailsByPlanData:any;
  insertIptvDetailsForm = new FormGroup ({
    box_type: new FormControl(null,[Validators.required]),
    iptvId: new FormControl(null),
    iptvSerialNo : new FormControl(null),
    iptvMacAddress : new FormControl(null),
    iptvEthMacAddress : new FormControl(null),
    isDefault : new FormControl(false)
  });

  iptvSaveFlage: boolean = false;
  boxTypeList = [];
  
  neoGridSettings = {};
  tableKeyEnum = TableKeyEnum;
  allColumns = [];
  DataSource = new MatTableDataSource([]);
  datagrid = [];
  public loginService: LoginService;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  constructor(
    private customModalService: CustomModalService,
    private iptvDetailsService : IptvDetailsService,
    private tosterservice : ToastrService,
    private commonListService: CommonListServicesService
  ) { }

  ngOnInit() {
    this.setGridSetting();
    this.getIptvDetails();
    this.getIPTVTypwList();
  }

  getIPTVTypwList(){
    this.commonListService.getGenericCommonList('IptvType').subscribe(res=>{
      this.boxTypeList = res.dataList;
    })
  }

  getIptvDetails(){
    this.iptvDetailsService.getIptvDetails(this.custId).subscribe(res => {
      this.refreshDataSource(res.dataList);
    })
  }

  refreshDataSource(data) {
    if (data) {
      this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onDelete(data){
    let self = this;
    alertify.confirm("Iptv Details","Are You Sure You want to Delete Iptv Details ?",
    function() {
      self.deleteApiCAll(data);
    },
    function() {

    });
  }

  deleteApiCAll(data){
      let self = this;
      this.iptvDetailsService.deleteIptvDetail(this.custId,data.id).subscribe((result) => {
        if(result.responseCode == 200){
        this.tosterservice.success('Iptv detail is deleted successfully');
        this.getIptvDetails();
        }else{
          this.tosterservice.error(result.responseMessage);
        }
      },
      err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
  }

  onClickSaveIptvForm() {
    if (this.insertIptvDetailsForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Iptv Details","Are You Sure You want to Update Iptv Details ?",
      function() {
        self.saveApiCall();
      },
      function() {

      });
    } else {
      this.submitted = true;
      this.insertIptvDetailsForm.markAllAsTouched();
      this.tosterservice.error("Please fill all the details!");
    }
  }

  onPlanSelect(data){
    this.saveApiCall(data);
  }

  saveApiCall(data?) {
    let apiInputData = Object.assign({}, this.insertIptvDetailsForm.value);
    let formatedInputData:any = apiInputData;
    formatedInputData.custId = this.custId;
    formatedInputData.box_type = undefined;
    if(data && data.id){
      formatedInputData.iptvDetailId = data.id
      formatedInputData.isDefault = true;
      formatedInputData.iptvId = data.iptvId;
      formatedInputData.iptvMacAddress = data.iptvMacAddress;
      formatedInputData.iptvEthMacAddress = data.iptvEthMacAddress;
      formatedInputData.iptvSerialNo = data.iptvSerialNo;
    }
    this.iptvDetailsService.addIptvDetails(formatedInputData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
         this.tosterservice.success('IPTV Details Update Successfully.');
         this.insertIptvDetailsForm.reset();
         this.getIptvDetails();
        }
      },err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
    this.submitted = false;
  }

  onChangeBoxType(event){
    if(event){
      if(event.value == 'Android TV'){
        this.insertIptvDetailsForm.get('iptvMacAddress').reset();
        this.insertIptvDetailsForm.get('iptvSerialNo').reset();
        this.insertIptvDetailsForm.get('iptvEthMacAddress').reset();
        this.insertIptvDetailsForm.get('iptvMacAddress').clearValidators();
        this.insertIptvDetailsForm.get('iptvMacAddress').updateValueAndValidity();
        this.insertIptvDetailsForm.get('iptvEthMacAddress').clearValidators();
        this.insertIptvDetailsForm.get('iptvEthMacAddress').updateValueAndValidity();
        this.insertIptvDetailsForm.get('iptvSerialNo').clearValidators();
        this.insertIptvDetailsForm.get('iptvSerialNo').updateValueAndValidity();
        this.insertIptvDetailsForm.get('iptvId').setValidators(Validators.required);
        this.insertIptvDetailsForm.get('iptvId').updateValueAndValidity();
        this.insertIptvDetailsForm.updateValueAndValidity();
      }else{
        this.insertIptvDetailsForm.get('iptvId').reset();
        this.insertIptvDetailsForm.get('iptvMacAddress').setValidators(Validators.required);
        this.insertIptvDetailsForm.get('iptvMacAddress').updateValueAndValidity();
        this.insertIptvDetailsForm.get('iptvEthMacAddress').setValidators(Validators.required);
        this.insertIptvDetailsForm.get('iptvEthMacAddress').updateValueAndValidity();
        this.insertIptvDetailsForm.get('iptvSerialNo').setValidators(Validators.required);
        this.insertIptvDetailsForm.get('iptvSerialNo').updateValueAndValidity();
        this.insertIptvDetailsForm.get('iptvId').clearValidators();
        this.insertIptvDetailsForm.get('iptvId').updateValueAndValidity();
        this.insertIptvDetailsForm.updateValueAndValidity();
      }
    }else{
      this.insertIptvDetailsForm.get('iptvId').clearValidators();
      this.insertIptvDetailsForm.get('iptvMacAddress').clearValidators();
      this.insertIptvDetailsForm.get('iptvEthMacAddress').clearValidators();
      this.insertIptvDetailsForm.get('iptvSerialNo').clearValidators();
      this.insertIptvDetailsForm.reset();
      this.insertIptvDetailsForm.updateValueAndValidity();
    }
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'default',
        default: 'default',
        rowKey: 'default',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'iptvId',
        default: 'iptv Id',
        rowKey: 'iptvId',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'iptvSerialNo',
        default: 'Serial No',
        rowKey: 'iptvSerialNo',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'iptvMacAddress',
        default: 'Mac Address',
        rowKey: 'iptvMacAddress',
        width: '11%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'iptvEthMacAddress',
        default: 'Ethernet Mac Address',
        rowKey: 'iptvEthMacAddress',
        width: '12%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'action',
        default: 'Action',
        rowKey: 'action',
        width: '1%',
        isStickyEnd:true,
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: false,
        enableSort: true,
      }
    ];

    this.neoGridSettings = {
      showFilter: false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.IPTV_LIST,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      tableHeight: 300,
      headerSticky: true,
      verticalScroll: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'action') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'default') {
        col.customControls = [this.defaultSelRef];
      }
      if(col.colKey == 'status'){
        col.customControls = [this.statusWrapperRef];
      }
    });
  }
}