import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, } from '@angular/core';
import { FranchiesService } from './franchies.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import _ from 'underscore';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { ServiceAreaService } from '../../network-management/service-area/service/service-area.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';
import { ResponseCode } from '../../../core/constant/response-code';
import { ToastrService } from 'ngx-toastr';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddBalance, ReverseBalance } from '../../masters/add-franchies/model/add-balance';
import { DatePipe } from '@angular/common';
import { PaymentModeService } from '../../../../app/services/payment_mode/payment_mode.service';
declare var $: any;

@Component({
    selector: 'app-franchies',
    templateUrl: './franchies.component.html',
    styleUrls: ['./franchies.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FranchiesComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
    @ViewChild('areaWrapper', { static: false }) areaWrapperRef: TemplateRef<any>;
    @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
    @ViewChild("editUserName", { static: false }) editUserNameRef: TemplateRef<any>;
    @ViewChild("franchisecontent", { static: false }) franchisecontentRef: TemplateRef<any>
    @ViewChild('dunningWrapper', { static: false }) dunningWrapperRef: TemplateRef<any>;
    AclClassConstants = AclClassConstants;
    AclConstants = AclConstants;

    franchiesDataForGrid: any = { content: [] };
    franchiesGridSettings: any = {};
    showPartnerLedger: boolean = false;
    public partnerBalance: number;
    franchies_id:any;
    closeResult: string;
    public paymentModeList: any;
    currentReverseDate: Date;
    reverseBalance: ReverseBalance = new ReverseBalance();
    partnerName:any;
    submitedFlag:boolean=false;
    public ispaymentMode: string;
    // config for n-table
    tableKeyEnum = TableKeyEnum;
    allColumns: any[] = [];
    dataSource = new MatTableDataSource([]);
    franchiseNeoGridSettings: any = {};

    // For advance search
    searchSettings = [];
    subscriptions: Subscription[] = [];
    multipleOptionsList = [];
    public loginService: LoginService;

    addBalanceForm = new FormGroup({
        amount: new FormControl(null, [Validators.required]),
        bank_name: new FormControl(null),
        branch_name: new FormControl(null),
        chequedate: new FormControl(null),
        chequenumber: new FormControl(null),
        description: new FormControl(null, [Validators.required]),
        paymentdate: new FormControl(null, [Validators.required]),
        paymentmode: new FormControl(null, [Validators.required]),
        refno: new FormControl(null)
      });

    constructor(
        private franchiesService: FranchiesService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private searchService: SearchService,
        private serviceAreaService: ServiceAreaService,
        private tosterservice: ToastrService,
        private modalService: NgbModal,
        private datePipe: DatePipe,
        private paymentModeService: PaymentModeService,
        loginservice: LoginService
    ) {
        super(searchService);
        this.loginService = loginservice;
        this.serviceAreaService.http.get('serviceArea/byStatus/active').subscribe(
            (result: any) => {
                this.areaList = result.dataList;
            }
        );
    }
    balance: AddBalance = new AddBalance();
    ngOnInit() {
        this.setDefaultPageSize(this.tableKeyEnum.FRANCHISE_LIST);
        this.setGridSetting();
        if(this.loginService.hasOperationPermission(AclClassConstants.ACL_FRANCHISE,AclConstants.OPERATION_PARTNER_ADD_BALANCE,AclConstants.OPERATION_PARTNER_ALL)){
            let addBalanceObj = {key:'addBalance',label:'Add Balance'};
            this.multipleOptionsList.push(addBalanceObj);
        }
        this.initPaginationSort();
        const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
            // if (obj && obj.filters) {
            this.applyFilter(obj);
            // }else
        });

        this.subscriptions.push(filterSubscription);
        this.getPaymentMode();
    }

    areaList: any[] = [{ id: '', name: '' }];

    ngAfterViewInit() {
        this.allColumns.forEach((col) => {
            if (col.colKey == 'status') {
                col.customControls = [this.statusWrapperRef];
            }
            if (col.colKey === 'serviceAreaIds') {
                col.customControls = [this.areaWrapperRef];
            }
            if (col.colKey == 'actionCol') {
                col.customControls = [this.actionBtnRef];
                if(this.multipleOptionsList.length == 0){
                    col.isMultipleOption = false
                  }
            }
            if (col.colKey == 'name') {
                col.customControls = [this.editUserNameRef];
            }
            if(col.colKey == 'dunningRuleName'){
                col.customControls = [this.dunningWrapperRef];
            }
        });
    }

    areaName(data) {
        if (data && data.serviceAreaIds && data.serviceAreaIds.length) {
            let areaName = '';
            data.serviceAreaIds.forEach((element, index) => {
                let findById = _.find(this.areaList, { id: element });
                if (data.serviceAreaIds.length - 1 === index) {
                    if (findById && findById.name) {
                        areaName += findById.name;
                    }

                } else {
                    if (findById && findById.name) {
                        areaName += findById.name + ', ';
                    }
                }
            });
            return areaName;
        } else {
            return '-';
        }
    }

    getFranchiesData(gridSetting: any) {
        let fetchSettingData = this.franchiseNeoGridSettings.serverSideSortPagination ? gridSetting : null;
        let params = this.getGridSettingInfo(fetchSettingData);
        this.franchiesService.getFranchiesDataWithPageing(params).subscribe((result) => {
            this.franchiesDataForGrid = result;
            this.refreshDataSource(this.franchiesDataForGrid.content);
            this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
            this.franchiseNeoGridSettings.pageTotalLength = this.totalRecords;
        });
    }

    getListWithParams() {
        if (Array.isArray(this.searchData)) {
            if (this.searchData.length > 0) {
                if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
                    this.paginationData.pageNumber = 1;
                }
            }
        }
        let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
        this.getFranchiesData(config);
    }

    applyFilter(filterObj) {
        let filteredDataForGrid = JSON.parse(JSON.stringify(this.franchiesDataForGrid.content));
        let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
        if (this.franchiseNeoGridSettings.serverSideFilter) {
            this.onFilterChange(filters);
        } else {
            if (!filters) {
                filteredDataForGrid = this.searchService.getFilteredData(filters, this.franchiseNeoGridSettings);
                this.refreshDataSource(filteredDataForGrid);
            }
        }
        this.refreshDataSource(filteredDataForGrid);
    }

    setPagination(event) {
        let config = { page: event, order: this.orderingData, search: this.searchData };
        this.getFranchiesData(config);
    }

    setGridSetting() {
        let self = this;
        this.allColumns = [
            {
                colKey: 'index',
                default: 'Id',
                rowKey: 'id',
                width: '3%',
                isFilterAllow: false,
                isNumber: true,
            },
            {
                colKey: 'name',
                default: 'Franchise Name',
                rowKey: 'name',
                width: '20%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'pricebookname',
                default: 'Associated Price Book',
                rowKey: 'pricebookname',
                width: '20%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'notifiGroupName',
                default: 'Associated Notification Group',
                rowKey: 'notifiGroupName',
                width: '20%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'commission_type',
                default: 'Commission Type',
                rowKey: 'commission_type',
                width: '20%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'dunningRuleName',
                default: 'Dunning Rule',
                rowKey: 'dunningRuleName',
                width: '20%',
                customControls: [this.dunningWrapperRef],
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: false,
            },
            {
                colKey: 'email',
                default: 'Franchise Email',
                rowKey: 'email',
                width: '20%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'mobile',
                default: 'Mobile No.',
                rowKey: 'mobile',
                width: '10%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'serviceAreaIds',
                default: 'Area',
                rowKey: 'serviceAreaIds',
                sortKey: 'serviceAreas',
                width: '10%',
                isNumber: false,
                isFilterAllow: true,
                customControls: [this.areaWrapperRef],
                customValue: (serviceAreaIds) => {
                    if (serviceAreaIds && serviceAreaIds.length) {
                        let areaName = '';
                        serviceAreaIds.forEach((element, index) => {
                            let findById = _.find(this.areaList, { id: element });
                            if (serviceAreaIds.length - 1 === index) {
                                areaName += findById.name;
                            } else {
                                areaName += findById.name + ', ';
                            }
                        });
                        return areaName;
                    } else {
                        return '-';
                    }
                },
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'status',
                default: 'STATUS',
                rowKey: 'status',
                width: '10%',
                customControls: [this.statusWrapperRef],
                customValue: (status) => {
                    if (status.equalsIgnoreCase() === 'Active') {
                        return 'Active';
                    } else {
                        return 'Inactive';
                    }
                },
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'updatedate',
                default: 'Last Modified',
                rowKey: 'updatedate',
                width: '10%',
                isNumber: false,
                isFilterAllow: true,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'actionCol',
                default: 'Action',
                rowKey: 'id',
                //enableEdit: true,
                // enableDelete: true,
                enableEdit: false,
                enableDelete: false,
                isFilterAllow: false,
                enableSort: false,
                isExpandable: false,
                isNumber: false,
                width: '1%',
                isStickyEnd: true,
                isMultipleOption: true
            },
        ];
        this.allColumns.forEach(element => {
            element.isFilterAllow = false;
        });
        this.searchSettings = [
            {
                display_field: 'Status',
                value_field: 'status',
                control_setting: [
                    {
                        control_type: SearchControlTypeEnum.SELECT,
                        display_style: '',
                    },
                ],
                data_type: SearchDataTypeEnum.BOOLEAN,
                select_items: [
                    {
                        display_field: 'All',
                        value_field: ['Y', 'N'],
                    },
                    {
                        display_field: 'True',
                        value_field: 'Y',
                    },
                    {
                        display_field: 'False',
                        value_field: 'N',
                    },
                ],
            },
            {
                display_field: 'Franchise Name',
                value_field: 'name',
                control_setting: [
                    {
                        control_type: SearchControlTypeEnum.TEXT,
                        display_style: '',
                    },
                ],
                data_type: SearchDataTypeEnum.STRING,
            },
        ];

        this.franchiseNeoGridSettings = {
            showAdvanceSearch: false,
            showFooter: false,
            showPagination: true,
            tableId: this.tableKeyEnum.FRANCHISE_LIST,
            perPage: this.paginationData.rowsPerPage,
            pageNumber:this.paginationData.pageNumber - 1,
            pageSizeOptions: [5, 10, 15, 20, 50, 100],
            sort: true,
            serverSideFilter: true,
            serverSideSortPagination: true,
            pageTotalLength: 0,
            defaultSortCol: '',
            defaultSortDirection: 'asc',
            // enableMultiDelete: true,
            columns: this.allColumns,
            deleteMsgBoxTitle: 'Are you sure to delete this Franchies ?',
            deleteMsgBoxDetail: 'Franchies Name :${name} ',
        };

        this.franchiesGridSettings = {
            showSearchBox: false,
            showPageLenghtChange: false,
            columnVisibilityForAllColumn: false,
            columns: [
                { title: 'Id', data: '', visible: true, customColumnType: 'sequence_column' },
                {
                    title: 'Status',
                    data: 'status',
                    visible: true,
                    render: function (cityData, column_name, rowIndex) {
                        let value;
                        if (cityData.status == 'Y') {
                            value = '<span class="badge badge-success">True</span>';
                        } else {
                            value = '<span class="badge badge-secondary">False</span>';
                        }
                        return self.sanitizer.bypassSecurityTrustHtml(value);
                    },
                },
                { title: 'Franchies Name', data: 'name', visible: true },
                {
                    title: 'Action',
                    data: '',
                    sortable: false,
                    visible: true,
                    customColumnType: 'editAndDeleteButton',
                    deleteButtonWithConfirmMessage: 1,
                    deleteMsgBoxTitle: 'Are you sure to delete this Franchies ?',
                    deleteMsgBoxDetail: 'Franchies Name :${name} ',
                    onEditClick: function (event, column, rowData, i) {
                        self.setUpdateValueInForm(rowData);
                    },
                    deleteYes: function (event, column, rowData, i) {
                        self.delete(rowData);
                    },
                },
            ],
        };

        if (this.franchiseNeoGridSettings.serverSideSortPagination && this.franchiseNeoGridSettings.perPage) {
            this.setPaginationPerPage(this.franchiseNeoGridSettings.perPage);
        }
    }

    refreshDataSource(data) {
        if (data) {
            this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
        }
    }

    // onEdit(event) {
    //   this.setUpdateValueInForm(event.RowData);
    // }

    onEdit(event) {
        this.setUpdateValueInForm(event);
    }

    // onDelete(event) {
    //   let self = this;
    //   if (event.deletClicked) {
    //     self.delete(event.RowData);
    //   }
    // }

    onDelete(event) {
        let self = this;
        if (event) {
            this.commonDeleteMsgBox(this.franchiseNeoGridSettings, event, this.delete.bind(this));
        }
    }

    delete(objEvent) {
        let self = this;
        let selected_franchies_data = objEvent.event;
        if (objEvent.deleteClicked) {
            this.franchiesService.deleteFranchies(selected_franchies_data.franchies_id).subscribe((result) => {
                if (result.status === ResponseCode.OK) {
                    this.tosterservice.success('Franchies is deleted successfully');
                    self.franchiesDataForGrid.content.forEach(function (item, index) {
                        if (item.franchies_id === selected_franchies_data.franchies_id) {
                            self.franchiesDataForGrid.content.splice(index, 1);
                        }
                    });
                    self.refreshDataSource(self.franchiesDataForGrid.content);
                }

            }, err => {
                this.tosterservice.error(err.error.status + ' ' + err.error.ERROR);
            });
        }
    }

    onAddFranchiseClick() {
        this.router.navigate(['/masters/franchies/add_franchies']);
    }

    setUpdateValueInForm(rowData) {
        this.router.navigate(['/masters/franchies/add_franchies', rowData.franchies_id]);
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            this.subscriptions.map((subscription) => {
                subscription.unsubscribe();
            });
        }
    }

  openModal(content,data){
      
   this.partnerName=null;
   this.franchies_id = data.franchies_id;
   this.partnerName=data.name;
   this.partnerBalance=Math.abs(data.outcomeBalance);
   this.modalService.open(content
        , { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
    }

    public getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }

    onAddBalance() {

        if(this.addBalanceForm.invalid){
            this.submitedFlag=true;
            return;
          }

        if (this.addBalanceForm.valid === true && this.submitedFlag !==true) {
          this.balance.partner_id = Number(this.franchies_id);
          this.balance.amount = Number(this.balance.amount);
          this.balance.paymentdate = this.datePipe.transform(this.balance.paymentdate, 'yyyy-MM-dd');
          this.showPartnerLedger = false;
          this.franchiesService.saveAddBalance(this.balance).subscribe((res) => {
    
            if (res.status === 200) {
              this.partnerBalance = res.addBalance.outcomeBalance;
              this.tosterservice.success("Balance Added Successfully");
              this.submitedFlag=false;
              this.addBalanceForm.reset();
    
            }
            this.showPartnerLedger = true;
          });
        }
        else {
          this.tosterservice.error("Please fill all the details!");
        }
      }

    //   closePopup(){
    //     this.modalService.dismissAll();
    //   }

    getPaymentMode() {
        ////console.log("in payment mode list ")
        this.currentReverseDate = new Date();
        this.reverseBalance.paymentdate = this.datePipe.transform(this.currentReverseDate, 'yyyy-MM-dd')
        this.paymentModeService.getPaymentModeList().subscribe((res) => {
          this.paymentModeList = res.content;
          ////console.log("P..", this.paymentModeList)
        });
    
      }
      onChangePaymentMode(mode) {
        if (mode && mode.payment_mode_id && mode.payment_mode_id === 13) {
          this.ispaymentMode = 'Cheque';
          this.addBalanceForm.get('chequenumber').setValidators([Validators.required]);
          this.addBalanceForm.get('chequedate').setValidators([Validators.required]);
          this.addBalanceForm.get('bank_name').setValidators([Validators.required]);
          this.addBalanceForm.get('branch_name').setValidators([Validators.required]);
          return;
        }
        else {
          this.addBalanceForm.get('chequenumber').clearValidators();
          this.addBalanceForm.get('chequenumber').updateValueAndValidity();
          this.addBalanceForm.get('chequedate').clearValidators();
          this.addBalanceForm.get('chequedate').updateValueAndValidity();
          this.addBalanceForm.get('bank_name').clearValidators();
          this.addBalanceForm.get('bank_name').updateValueAndValidity();
          this.addBalanceForm.get('branch_name').clearValidators();
          this.addBalanceForm.get('branch_name').updateValueAndValidity();
          this.ispaymentMode = 'Online';
          return;
        }
    
      }

      keypress(event: any) {
        const pattern = /[0-9\.]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
      }

      onMultipleOptionClick(event){
        this.partnerName=null;
        this.franchies_id = event.RowData.franchies_id;
        this.partnerName=event.RowData.name;
        this.partnerBalance=Math.abs(event.RowData.outcomeBalance);
        if(event.optionData.key == 'addBalance'){
            this.modalService.open(this.franchisecontentRef,{ ariaLabelledBy: 'modal-basic-title', size: 'xl' });
        }
      }
      
}
