import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseTreeControl } from '@angular/cdk/tree';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class ManageProfileServiceService {

  constructor(
    private http: HttpClient
  ) { }

  UserdataById(id: any): Observable<any> {
    return this.http.get<any>(baseApiUrl + "staffuser/" + id);
  }
  UpdateProfileService(data: any) {
    return this.http.post<any>(baseApiUrl + 'subscriber/updateProfile', data);
  }
  checkPassword(data: any) {
    return this.http.post<any>(baseApiUrl + 'subscriber/staff/changePassword', data);
  }
  UpdatePassword(data: any) {
    return this.http.post<any>(baseApiUrl + "subscriber/staff/updateManagePassword/", data);
  }
  AssociatePlan(data){
    return this.http.post<any>(baseApiUrl + "plan/partnerPlans", data);
  }
  
  getpartnerRoleListing(data):Observable<any>{
    const post_url = baseApiUrl + 'partnerRoleMapping';
    return this.http.post<any>(post_url,data);
  }
}
