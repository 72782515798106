import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import _ from 'underscore';
import { AccountingProfileService } from './accounting-profile.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accounting-profile',
  templateUrl: './accounting-profile.component.html',
  styleUrls: ['./accounting-profile.component.scss'],
})
export class AccountingProfileComponent extends OldAbstractBaseListComponent implements OnInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;

  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;

  @ViewChild('priority', { static: false }) priorityRef: TemplateRef<any>;
  
  @ViewChild('profileReff', { static: false }) profileReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  accountingProfileData: any = { content: [] };
  accountingGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredAccountingProfileDataForGrid: any = [];
  public loginService: LoginService;

  constructor(
    private sanitizer: DomSanitizer,
    private accountingProfileService: AccountingProfileService,
    private router: Router,
    private searchService: SearchService,
    private tosterservice:ToastrService,
    loginservice: LoginService
  ) {
    super(searchService);
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.ACCOUNTING_PROFILE_LIST)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setGridSetting();
    this.initPaginationSort();
    this.subscriptions.push(filterSubscription);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      { colKey: 'index', default: 'ID', rowKey: 'id', width: '3%', isFilterAllow: false, isNumber: true },
      {
        colKey: 'accounting_profile_name',
        default: 'Name',
        rowKey: 'accounting_profile_name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'priority',
        default: 'Priority',
        rowKey: 'priority',
        width: '10%',
        customControls: [this.priorityRef],
        customValue: (priority) => {
          let findpriority = _.find(this.accountingProfileData, { priority: priority });
          return findpriority.priority;
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'accounting_profile_status',
        default: 'STATUS',
        rowKey: 'accounting_profile_status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status == 'A') {
            return 'Active';
          } else {
            return 'In Active';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Status',
        value_field: 'accounting_profile_status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
      {
        display_field: 'Name',
        value_field: 'accounting_profile_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Policies Name',
        value_field: 'priority',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.NUMBER,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.NUMBER,
      },
    ];

    this.accountingGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.ACCOUNTING_PROFILE_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this User ?',
      deleteMsgBoxDetail: '"Accounting Profile Name :${accounting_profile_name} " ',
    };
    if (this.accountingGridSettings.serverSideSortPagination && this.accountingGridSettings.perPage) {
      this.setPaginationPerPage(this.accountingGridSettings.perPage);
    }
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.accountingProfileData.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.accountingProfileData.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'accounting_profile_status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'priority') {
        col.customControls = [this.priorityRef];
      }
      if (col.colKey == 'accounting_profile_name') {
        col.customControls = [this.profileReff];
      }

    });
  }

  delete(objEvent) {
    let self = this;
    let selected_accounting_profile_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.accountingProfileService
        .deleteAccountingProfile(selected_accounting_profile_data.accounting_profile_id)
        .subscribe((result) => {
          if(result.status !== 200){
            this.tosterservice.error(result.ERROR);
          }
          else{
            this.tosterservice.success('Accounting Profile is deleted successfully');
            self.accountingProfileData.content.forEach(function (item, index) {
              if (item.accounting_profile_id === selected_accounting_profile_data.accounting_profile_id) {
                self.accountingProfileData.content.splice(index, 1);
              }
            });
            self.refreshDataSource(self.accountingProfileData.content);
          }
 
        },
          err => {
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
    }
  }

  onAddAccountingProfileClick() {
    this.router.navigate(['/radius/accounting-profile/add-accounting-profile']);
  }

  refreshPage() {
    this.router.navigate(['/radius/accounting-profile']);
  }

  getAccountingProfileData(gridSetting: any) {
    let fetchSettingData = this.accountingGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.accountingProfileService.getAccountingProfileDataWithPageing(params).subscribe((result) => {
      this.accountingProfileData = result;
      this.refreshDataSource(this.accountingProfileData.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.accountingGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getAccountingProfileData(config);
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    if (event) {
      this.commonDeleteMsgBox(this.accountingGridSettings, event, this.delete.bind(this));
    }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/radius/accounting-profile/add-accounting-profile',rowData.accounting_profile_id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getAccountingProfileData(config);
      }
}
