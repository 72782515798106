import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PolicyModel } from '../model/policy-model';
import * as alertify from 'alertifyjs';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class QosPolicyService {

  constructor(private http: HttpClient) { }

  getPolicyById(policy_id): Observable<any> {
    const get_url = baseApiUrl + 'qosPolicy/'+policy_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((error: any) => {
          alertify.error('Something Went Wrong'+' <br>' + error.status + ' ' +  error.error.ERROR).delay(6);
          return throwError(error);
        })
      );
  }

  getPolicyList(): Observable<any> {
    const get_url = baseApiUrl + 'qosPolicy/all';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          ////console.log('Response ::- ',res);
          return this.transformPolicyList(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformPolicyList(data) {
    let formatedData = [];
    data.dataList.forEach(function(item:any) {
      if (item!=null || item.id!=null) {
        let policyData:any = {};
        policyData.id = item.id;
        policyData.name = item.name;
        policyData.description = item.description;
        policyData.baseUploadSpeed = item.baseUploadSpeed;
        policyData.baseDownloadSpeed = item.baseDownloadSpeed;
        policyData.thUploadSpeed = item.thUploadSpeed;
        policyData.thDownloadSpeed = item.thDownloadSpeed;
        policyData.thPolicyName = item.thPolicyName;
        policyData.baseParam1 = item.baseParam1;
        policyData.baseParam2 = item.baseParam2;
        policyData.baseParam3 = item.baseParam3;
        policyData.thParam1 = item.thParam1;
        policyData.thParam2 = item.thParam2;
        policyData.thParam3 = item.thParam3;
        formatedData.push(policyData);
      }
    });
    return {content:formatedData};
  }

  insertPolicy(policy: PolicyModel): Observable<any>{
    const post_url = baseApiUrl + 'qosPolicy/save';   
    ////console.log('QOS POLICY::- ' , post_url) 
    return this.http.post<any>(post_url, policy, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        ////console.log('ERROR IN ADDING ::- ',error);
        
        alertify.error('Something Went Wrong'+' <br>' + error.status + ' ' +  error.error.ERROR).delay(6);
        return throwError(error);
      })
    );
  }

  updatePolicy(policy: PolicyModel,policy_id:number): Observable<any> {
    const update_url = baseApiUrl + 'qosPolicy/update';
    return this.http.post<any>(update_url, policy, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {        
        alertify.error('Something Went Wrong'+' <br>' + error.status + ' ' +  error.error.ERROR).delay(6);
        return throwError(error);
      })
    );
  }

  deletePolicy(policy:PolicyModel): Observable<any> {
    const delete_url = baseApiUrl + 'qosPolicy/delete';
    return this.http.post<any>(delete_url, policy, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {        
        alertify.error('Something Went Wrong'+' <br>' + error.status + ' ' +  error.error.ERROR).delay(6);
        return throwError(error);
      })
    );
  }
}
