import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../clients/clients.service';
import _ from 'underscore';
import { ClientGroupService } from '../client-grp/Services/client-group.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';

@Component({
  selector: 'app-add-clients',
  templateUrl: './add-clients.component.html',
  styleUrls: ['./add-clients.component.scss']
})
export class AddClientsComponent implements OnInit {
  saveBtn:boolean=false;
  clientsDataForGrid: any = { content: [] };
  clientsGridSettings: any = {};
  public sub: any = null;
  public client_id : any = null;
  selectForUpdate: any;
  show_update: any = false;
  error_message = "This field is required.";
  isDisabled:boolean=false;
  discPolicyList:any;
  COAPolicyList:any;
  supportList:any[] = [{
    name: 'Yes',
    value:'Yes'
  },
  {
    name: 'No',
    value: 'No'
  }
];

  insertClientForm = new FormGroup({
    client_ip: new FormControl('', [Validators.required,Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]),
    shared_secret_key: new FormControl('', [Validators.required]),
    timeout: new FormControl('', [Validators.required]),
    ip_type: new FormControl('IPv4', [Validators.required]),
    client_group_id: new FormControl(null, [Validators.required]),
    discpolicy:new FormControl(null),
    dmPort:new FormControl(null),
    coaSupport:new FormControl(null),
    coaPolicy:new FormControl(null),
    coaPort:new FormControl(null),
  });
  public clientGroupList: any;

  ipTypeList:any[] = [
    {
      name: 'IPv4',
      value: 'IPv4',
    },
    {
      name: 'IPv6',
      value: 'IPv6',
    }
  ]

  constructor(
    private clientsService: ClientsService,
    private router: Router,
    private route : ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private clientGroupService : ClientGroupService,
    private tosterservice:ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddClientsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { }

  ngOnInit() {
    
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.client_id = id;
      this.show_update = true;
      this.getClientsData();
    }

    // if(this.data && this.data.id){
      
    //   this.isDisabled=true;
    //   this.client_id = this.data.id;
    //   this.show_update = false;
    //   this.getClientsData();
    // }

    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.client_id = params['id'];
    //       this.show_update = false;
    //       this.getClientsData();
    //     }
    //   });
      this.clientsService.getClientGroup().subscribe((result) => {
        this.clientGroupList = result.dataList;
        ////console.log("client group ==> ",result)
      });
      this.getDiscPolicyList();
      this.getCOAPolicyList();
  }

  validateForm() {
    if (!this.insertClientForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertClientForm.controls).forEach(field => {
        const control = this.insertClientForm.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.client_id === null) {
        this.insertClient();
      } else {
        this.updateClient();
      }
    }
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }
  getClientsData() {
    let client_id = this.client_id;
    this.clientsService.getCountryListById(client_id).subscribe(
      result => {
        this.setUpdateValueInForm(result);
      });
  }

  transformDataForInsertApi(apiInputData: any) {
    let formatedInputData: any = {};
    formatedInputData.clientip = apiInputData.client_ip;
    formatedInputData.sharedkey = apiInputData.shared_secret_key;
    formatedInputData.timeout = apiInputData.timeout;
    formatedInputData.iptype = apiInputData.ip_type;
    formatedInputData.clientgroupid = apiInputData.client_group_id;
    formatedInputData.coaDynaAuthId = apiInputData.coaPolicy;
    formatedInputData.coaPort = apiInputData.coaPort;
    formatedInputData.dmDynaAuthId = apiInputData.discpolicy;
    formatedInputData.dmPort = apiInputData.dmPort;
    formatedInputData.coaSupport = apiInputData.coaSupport;
    return formatedInputData;
  }

  onChnageSuppPolicy(event){
    this.insertClientForm.get('coaPolicy').reset()
    this.insertClientForm.get('coaPolicy').updateValueAndValidity();
    this.insertClientForm.get('coaPort').reset()
    this.insertClientForm.get('coaPort').updateValueAndValidity();
    if(event.value == 'Yes'){
      this.insertClientForm.get('coaPolicy').setValidators([Validators.required])
      this.insertClientForm.get('coaPolicy').updateValueAndValidity();
      this.insertClientForm.get('coaPort').setValidators([Validators.required])
      this.insertClientForm.get('coaPort').updateValueAndValidity();
    }
    else{
      this.insertClientForm.get('coaPolicy').clearValidators();
      this.insertClientForm.get('coaPolicy').updateValueAndValidity();
      this.insertClientForm.get('coaPort').clearValidators();
      this.insertClientForm.get('coaPort').updateValueAndValidity();
    }
  }

  getDiscPolicyList(){
    let type = 'DM'
    this.clientsService.getdiscPolicyList(type).subscribe((result)=>{
      this.discPolicyList = result.radiusprofileList;
    })
  }

  getCOAPolicyList(){
    let type = 'COA'
    this.clientsService.getdiscPolicyList(type).subscribe((result) => {
      this.COAPolicyList = result.radiusprofileList;
    })
  }

  insertClient() {
    if (this.insertClientForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertClientForm.value);
      let formatedInputData = this.transformDataForInsertApi(apiInputData);
      this.clientsService.insertClient(formatedInputData).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status !== 200){
            this.tosterservice.error(result.ERROR);
          }
          else{
            let data: any = {};
            let clientData: any = result.clients;
  
            data.client_id = clientData.id;
            data.client_ip = clientData.clientip;
            data.shared_secret_key = clientData.sharedkey;
            data.timeout = clientData.timeout;
            data.create_date = clientData.createdate;
            data.ip_type = clientData.iptype;
            data.client_group_id = clientData.clientgroupid;
            this.cdRef.detectChanges();
            this.backToViewClients();
            this.tosterservice.success('Client has been created successfully');
          }
          
        },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  updateClient() {
    if (this.insertClientForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertClientForm.value);
      let client_id = this.client_id;
      let formatedInputData = this.transformDataForInsertApi(apiInputData);
      this.clientsService.updateClient(formatedInputData, client_id).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status !== 200){
            this.tosterservice.error(result.ERROR);
          }
          else{
            this.clientsDataForGrid.content.forEach((item, index) => {
              if (item.client_id === client_id) {
                this.clientsDataForGrid.content[index].client_ip = formatedInputData.clientip;
                this.clientsDataForGrid.content[index].shared_secret_key = formatedInputData.sharedkey;
                this.clientsDataForGrid.content[index].timeout = formatedInputData.timeout;
                this.clientsDataForGrid.content[index].ip_type = formatedInputData.iptype;
                this.clientsDataForGrid.content[index].client_group_id = formatedInputData.clientgroupid;
              }
            });
            this.cdRef.detectChanges();
            this.show_update = false;
            this.backToViewClients();
            this.tosterservice.success('Client Update successfully');
          }

        },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  setUpdateValueInForm(clientData: any) {
    ////console.log('client data =>',clientData);
    this.show_update = true;
    this.selectForUpdate = clientData;
    let clientFormData: any = {
      client_ip: clientData.clientip,
      shared_secret_key: clientData.sharedkey,
      timeout: clientData.timeout,
      ip_type: clientData.iptype,
      client_group_id: clientData.clientgroupid,
      coaPolicy: clientData.coaDynaAuthId,
      coaPort: clientData.coaPort,
      discpolicy: clientData.dmDynaAuthId,
      dmPort: clientData.dmPort,
      coaSupport: clientData.coaSupport
    };
    ////console.log('form group=>', clientFormData);
    this.insertClientForm.patchValue(clientFormData);
  }

  backToViewClients() {
    // this.router.navigate(['/radius/clients']); /**Old code */

    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
      this.router.navigate(['/radius/clients']);
      }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  spaceNotAllow(event) {
    if (event.which == 32) {
     // alertify.error("Space Not Allowed");
     return true;
    }
  }

  validateIPAddress(ip){
  }
  
  closeDialog(){
    this.dialogRef.close()
  }
}
