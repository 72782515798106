import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../services/base-service';
import { PincodeModel } from './pincode-model';

@Injectable({
  providedIn: 'root'
})
export class PincodeServiceService extends BaseService<PincodeModel> {
  
  constructor(http: HttpClient) {
    super(http);
  }
  getModuleName(): string {
    return 'pincode';
  }
  validate(): boolean {
    return false;
  }
}
