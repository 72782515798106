export class Property{
    id:number;
    proname:string;
    proaddress:string;
    techconname:string;
    techconphone:string;
    subscriberLeasedLine:number;
    propertyContactDetails : Array<any>;
    // no_of_circuits:number;
    countryId:number;
    country:string;
    stateId:number;
    state:string;
    cityId:number;
    city:string;
    talukaId:number;
    taluka:string;
    districtId:number;
    district:string;
    constituencyId:number;
    constituency:string;
    building:String;
    buildingId:number;
    pincodeId:number;
    pincode:number;
    connectionType:string;
    routerType:string;
    approvedBy:string;
    reason:string;
    bridgeType:string;
    blockNo:string;
    landmark:string;
    area:String;
    opticalPower:string
    propertyType:string;
    serviceareaId:number;
    areaId:number;
    addressType:string;
    latitude:string;
    longitude:string;
    gis_code1:string;
    gis_code2:string;
    feasibilityDistance: string;
    url: string;
    unit:any;
}