import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { EditorModule } from "@tinymce/tinymce-angular";
import { TextMaskModule } from "angular2-text-mask";
import { Ng2TableModule } from "ng2-table";
import { AlertModule, BsDropdownModule, PaginationModule, TooltipModule } from "ngx-bootstrap";
import {SharedModule} from '../../layout/shared.module';
import { AclComponent } from './acl/acl.component';
import { StaffComponent } from './staff/staff.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { AddUserComponent } from './add-user/add-user.component';
import { UtilsModule } from '../../layout/utils/utils.module';
import { JqSparklineModule } from '../../components/sparkline/sparkline.module';
import { WidgetModule } from '../../layout/widget/widget.module';
import { BootstrapWizardModule } from '../../components/wizard/wizard.module';
import { MatDatepickerModule, MatNativeDateModule, MatTabsModule } from "@angular/material";
import { AppGuard } from '../../app.guard';
import { AclConstants } from '../aclOperationConstants';
import { AclClassConstants } from '../aclClassConstants';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TeamListComponent } from "./teams/team-list/team-list.component";
import { AddUpdateTeamsComponent } from "./teams/add-update-teams/add-update-teams.component";
import { AclGernericComponentComponent } from "../generic-component/acl/acl-gerneric-component/acl-gerneric-component.component";
import { AuditLogGenericComponent } from "../generic-component/audit-log-generic/audit-log-generic.component";
import { GeoLocationStaffComponent } from './staff/geo-location-staff/geo-location-staff.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { StaffAvailableComponent } from './staff/staff-available/staff-available.component';
import { AddUpdateGroupComponent } from './groups/add-update-group/add-update-group.component';
import { GroupListComponent } from './groups/group-list/group-list.component';


export const routes =[
    {
      path : 'acl',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_ROLE_VIEW,
        classId:AclClassConstants.ACL_ACL,
        accessIdForAllOpreation :AclConstants.OPERATION_ROLE_ALL,
        operation:'acl'
      },
      component : AclComponent
    },
    {
       path : 'add-role',
       canActivate: [AppGuard],
       data: {
         operationId: AclConstants.OPERATION_ROLE_ADD,
         classId:AclClassConstants.ACL_ACL,
         accessIdForAllOpreation :AclConstants.OPERATION_ROLE_ALL,
         operation:'add new role'
       },
       component : AddRoleComponent
    },
    {
      path : 'add-role/:id',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_ROLE_EDIT,
        classId:AclClassConstants.ACL_ACL,
        accessIdForAllOpreation :AclConstants.OPERATION_ROLE_ALL,
        operation:'Edit role'
      },
      component : AddRoleComponent
   },
    {
      path : 'staff',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_STAFF_USER_VIEW,
        classId:AclClassConstants.ACL_STAFF,
        accessIdForAllOpreation :AclConstants.OPERATION_STAFF_USER_ALL,
        operation:'staff'
      },
      component : StaffComponent
    },
    {
      path : 'add-user',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_STAFF_USER_ADD,
        classId:AclClassConstants.ACL_STAFF,
        accessIdForAllOpreation :AclConstants.OPERATION_STAFF_USER_ALL,
        operation:'Add new staff'
      },
      component : AddUserComponent
    },
    {
      path : 'add-user/:id',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_STAFF_USER_EDIT,
        classId:AclClassConstants.ACL_STAFF,
        accessIdForAllOpreation :AclConstants.OPERATION_STAFF_USER_ALL,
        operation:'Edit user'
      },
      component : AddUserComponent
    },
    {
      path: 'team-list', 
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_TEAMS_VIEW,
        classId:AclClassConstants.ACL_TEAM,
        accessIdForAllOpreation :AclConstants.OPERATION_TEAMS_ALL,
        operation:'team'
      },
      component: TeamListComponent
    },
    {
      path: 'add-team', canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_TEAMS_ADD,
        classId:AclClassConstants.ACL_TEAM,
        accessIdForAllOpreation :AclConstants.OPERATION_TEAMS_ALL,
        operation:'add new team'
      },
      component: AddUpdateTeamsComponent
    },
    {
      path: 'add-team/:id', 
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_TEAMS_EDIT,
        classId:AclClassConstants.ACL_TEAM,
        accessIdForAllOpreation :AclConstants.OPERATION_TEAMS_ALL,
        operation:'Edit team'
      },
      component: AddUpdateTeamsComponent
    },
    {
      path: 'group-list', 
      canActivate: [AppGuard],
       data: {
         operationId: AclConstants.OPERATION_GROUP_VIEW,
         classId:AclClassConstants.ACL_GROUP,
         accessIdForAllOpreation :AclConstants.OPERATION_GROUP_ALL,
         operation:'group'
       },
      component: GroupListComponent
    },
    {
     path: 'add-group', 
     canActivate: [AppGuard],
       data: {
         operationId: AclConstants.OPERATION_GROUP_ADD,
         classId:AclClassConstants.ACL_GROUP,
         accessIdForAllOpreation :AclConstants.OPERATION_GROUP_ALL,
         operation:'add new group'
       },
      component:AddUpdateGroupComponent 
     },
     {
       path: 'add-group/:id', 
       canActivate: [AppGuard],
       data: {
         operationId: AclConstants.OPERATION_GROUP_EDIT,
         classId:AclClassConstants.ACL_GROUP,
         accessIdForAllOpreation :AclConstants.OPERATION_GROUP_ALL,
         operation:'Edit group'
       },
       component:AddUpdateGroupComponent 
     },
    {
      path: 'staffgeolocation/:id', 
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_STAFF_GET_TRACK,
        classId:AclClassConstants.ACL_STAFF,
        accessIdForAllOpreation :AclConstants.OPERATION_STAFF_USER_ALL,
        operation:'Edit team'
      },
      component: GeoLocationStaffComponent
    }, 
    {
      path: 'staffAvailable/:event', 
      // canActivate: [AppGuard],
      // data: {
      //   operationId: AclConstants.OPERATION_STAFF_GET_TRACK,
      //   classId:AclClassConstants.ACL_STAFF,
      //   accessIdForAllOpreation :AclConstants.OPERATION_STAFF_USER_ALL,
      //   operation:'Edit team'
      // },
      component: StaffAvailableComponent
    }
];

@NgModule({
    declarations: [
    AclComponent,
    StaffComponent,
    AddRoleComponent,
    AddUserComponent,
    TeamListComponent,
    AddUpdateTeamsComponent,
    AclGernericComponentComponent,
    GeoLocationStaffComponent,
    StaffAvailableComponent,
    AddUpdateGroupComponent,
    GroupListComponent
    ],
    imports: [
      SharedModule,
      MatDatepickerModule,
      MatNativeDateModule,
      CommonModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      FormsModule,
      MatTabsModule,
      ReactiveFormsModule,
      TextMaskModule,
      TooltipModule.forRoot(),
      AlertModule.forRoot(),
      BsDropdownModule.forRoot(),
      WidgetModule,
      BootstrapWizardModule,
      RouterModule.forChild(routes),
      EditorModule,
      JqSparklineModule,
      PaginationModule.forRoot(),
      UtilsModule,
      Ng2TableModule,
      NgxDatatableModule,
      NgSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatSlideToggleModule,
      AngularEditorModule

    ]
  })
  export class HRModule {
    static routes = routes;
  }
