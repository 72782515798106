import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { CommonListModel } from '../model/commonList.model';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class QuotaTypeDataService {
  constructor(private http: HttpClient) {}

  getQuotaTypeData(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/quotaTypeData';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as CommonListModel[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
