
export class PolicyModel {
    id: number;
    name:string;
    description:string;
    basepolicyname:string;
    baseuploadspeed: string;
    basedownloadspeed: string;
    thuploadspeed: string;
    thdownloadspeed: string;
    thpolicyname: string;
    baseparam1: string;
    baseparam2: string;
    baseparam3: string;
    thparam1: string;
    thparam2: string;
    thparam3: string;
    qosPolicyType:string;
    normalspeed:number;
    normalUpTo:boolean = true;
    fupspeed:number;
    fupUpTo:boolean = true;

    constructor(){
        this.name="";
        this.description="";
        this.basepolicyname="";
        this.baseuploadspeed="";
        this.basedownloadspeed="";
        this.thuploadspeed="";
        this.thdownloadspeed="";
        this.thpolicyname="";
        this.baseparam1="";
        this.baseparam2="";
        this.baseparam3="";
        this.thparam1="";
        this.thparam2="";
        this.thparam3="";
    }
}
