import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import * as alertify from 'alertifyjs';
import { SelfcarePasswordService } from '../change-password/selfcare-password.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  enableAutoGenPass: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  @Input() custId : any;
  confirmPasswordForm = new FormGroup({
    remarks : new FormControl('', [Validators.required]),
    autoGeneratePassword:new FormControl(false),
  })

  constructor(
    private customModalService : CustomModalService,
    private selfcareService : SelfcarePasswordService,
    private tosterservice : ToastrService,
    public loginService: LoginService,

  ) { }

  ngOnInit() {
  }
  submitted : boolean;
  onClickSaveForm() {
    if (this.confirmPasswordForm.valid === true) {
      let self = this;
      alertify.confirm("Reset Selfcare Password","Are You Sure You want to Reset Selfcare Password ?",
      function() {
        self.resetSelfcarePasswprd();
        self.closeModal('reset-password');
      },
      function() {
  
      });
    } else {
      this.submitted = true;
      this.confirmPasswordForm.markAllAsTouched();
    }
  }

  resetSelfcarePasswprd() {
    let apiInputData = Object.assign({}, this.confirmPasswordForm.value);
    apiInputData.custId = this.custId;
    this.selfcareService.resetSelfPassword(apiInputData).subscribe(result => {
      this.tosterservice.success('Selfcare Password Reset Successfully.');
    },err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    })
  }

  onChangeEnableAutoGenPass(el){
    this.enableAutoGenPass = el.target.checked;
    this.confirmPasswordForm.get('autoGeneratePassword').setValue(this.enableAutoGenPass)
  }
  

  closeModal(id:string){
    this.customModalService.close(id);
    this.submitted = false;
    this.confirmPasswordForm.reset()
  }

}
