import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class ChangeVoiceDetailsService {

  constructor(private http: HttpClient) {}

  getChangeVoiceDetail(custId) : Observable<any> {
    const get_url = baseApiUrl + 'subscriber/voiceDetails/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertChangeVoiceDetails(changeVoice_data : any) : Observable<any> {
    const post_url = baseApiUrl + 'subscriber/voiceDetails';
    return this.http.post<any>(post_url, changeVoice_data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }
}
