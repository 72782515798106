import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class CommonChargecategoryService {
  constructor( private http: HttpClient) {
    
  }
 

  public getCommonListChargeCategory(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/chargeCategory';
    return this.http.get<any>(get_url);
  }

}
