import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, Optional, Input, Output, EventEmitter } from '@angular/core';
import { CasesModel } from '../model/cases-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { CasesService } from '../service/cases.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { SubscriberService } from '../../../masters/subscriber/subscriber.service';
import { CaseReasonService } from '../../case-reason/service/case-reason.service';
import { StaffService } from '../../../hr/staff/staff.service';
import { CaseReasonModel } from '../../case-reason/model/case-reason-model';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Updatelistmodel } from '../model/updatelistmodel';
import { HostListener } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { SearchAutocompleteComponent } from '../../../../components/search-autocomplete/search-autocomplete.component';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { InventoryService } from '../../../../../../src/app/pages/inventory/inventory/service/inventory.service';

@Component({
  selector: 'app-case-update-popup',
  templateUrl: './case-update-popup.component.html',
  styleUrls: ['./case-update-popup.component.scss']
})
export class CaseUpdatePopupComponent {
  custId: any;
  checkResolved: boolean;
  @Input() entity: CasesModel;
  @Input() updateList: Updatelistmodel;

  @Output() saveData: EventEmitter<any> = new EventEmitter();
  formupdate: FormGroup = new FormGroup({
    case_message_update: new FormControl('', [Validators.required]),
    //resolution_update: new FormControl(''),
    casestatus_update: new FormControl(''),
    case_reason_update: new FormControl(''),
    etr:new FormControl(null),
    etrUnit:new FormControl(null),
    case_priority_update: new FormControl(null),
    case_suspendtilldate_update: new FormControl(''),
    notified_enable:new FormControl(false),
    title: new FormControl(''),
    amount: new FormControl(null, [Validators.required]),
    issue: new FormControl(null, [Validators.required]),
    solution: new FormControl(null, [Validators.required]),
    receipt_no: new FormControl(null, [Validators.required]),
    serviceAreaId: new FormControl(null),
    teamType: new FormControl(null),
    teamId: new FormControl(null),
    groupId: new FormControl(null),
    staffId: new FormControl(null),
    allocationType: new FormControl(null),
    algorithmType: new FormControl(null),
    chargableType: new FormControl(null)
    
 
  });
  url: string;
  public subscriberList: string;
  casesData: any;
  default_value: any
  autoField: any = ['name', 'username', 'phone', 'email']
  UtrUnitArray:any[]=[
    {
      text:'Hours',
      value:'Hours',
      
    },
    {
      text:'Days',
      value:'Days',
    }
  ];
  isdisabled: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  disableflag: boolean = false;
  public customer: any;
  public staffList: string;
  public staffAsId: any
  caseReasonList=[];
  public caseType: any;
  public caseStatus: any;
  public caseFor: any;
  public priority: any;
  //public resolution: any;
  public loginService: LoginService;
  dynamicdata: any;
  serviceAreaList: any;
  teamTypeList: any = [];
  TeamList: any = [];
  GroupList: any = [];
  serviceAreaId: any;
  algorithmType: any;
  allocationList: any;
  chargableType:any;
  algoTypeList: any;
  enableflag: boolean = false;
  serviceIdPass: any;
  teamName: any;
  groupName:any
  staffListArray: any = [];
  showFlag: boolean;
  submitted: boolean;
  ngbRef: NgbModalRef;
  serviceareaid:number;
  public error_remark: boolean;

  constructor(
    public CasesService: CasesService,
    private ngbModalService: NgbModal,
    public CommonListService: CommonListServicesService,
    public caseReasonService: CaseReasonService,
    public staffService: StaffService,
    public Subscriber: SubscriberService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private datePipe: DatePipe,
    private genericDataSharingService: DataSharingServiceService,
    loginService: LoginService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CaseUpdatePopupComponent>,
    private inventoryService: InventoryService
  ) {
    this.loginService = loginService;
  }
  Internalcase: boolean
  ngOnInit() {
    let URL = this.router.url.includes('casesInternal')
    if (URL) {
      this.Internalcase = true
      this.disableflag = true
    } else {
      this.Internalcase = false
      this.disableflag = false
    }
    //this.setValidation();
    this.getcaseReason();
    this.commonList();
    this.entity.etrUnit = this.entity.etrUnit? this.entity.etrUnit : 'Hr';
    this.updateList.etrUnit = this.entity.etrUnit;
    this.updateList.etr = this.entity.etr
    this.updateList.notified_enable = false
    //this.getresolution();
    //setTimeout(() => {
    this.defaultSelectionAssignment(this.entity.serviceareaid);
    //}, 500);
    //this.getupdateListData();
    // if (this.resolution && this.resolution.length > 0 && this.entity.caseStatus == "Resolved") {
    //   this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
    // }
    // console.log('entity',this.entity)
    // console.log('update list',this.updateList)
  }

  closePopup() {
    this.ngbRef.close();
  }

  getService(): IBaseService<CasesModel> {
    return this.CasesService;
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }


  public getcaseReason() {
    if( this.Internalcase == true){
      this.caseReasonService.getcaseReasonInternal().subscribe((res) => {
        this.caseReasonList = res.dataList;
      });
    }
    else{
      this.caseReasonService.getcaseReasonCustomer().subscribe((res) => {
        this.caseReasonList = res.dataList;
      });
    }
  }
  // public getresolution() {
  //   this.CasesService.getresolutionreason().subscribe((res) => {
  //     this.resolution = res.dataList;
  //     if (this.resolution && this.resolution.length > 0 && this.entity && this.entity.caseStatus == "Resolved") {
  //       this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
  //     }
  //   });
  // }

  getstaffByID(id) {
    if (id) {
      this.staffService.getStaffById(id).subscribe((res) => {
        this.staffAsId = res.Staff;
        return this.staffAsId.firstname;
      });
    }
  }
  settime(event) {
    this.entity.nextFollowupTime = event.value
  }

  setStatus() {
    this.formupdate.get('casestatus_update').setValue(this.entity.caseStatus);
  }

  setServiceToTeam(el) {
    this.serviceIdPass = el;
    this.formupdate.get('teamType').reset();
    this.formupdate.get('allocationType').reset();
    this.formupdate.get('teamId').reset();
    this.formupdate.get('groupId').reset();
    this.formupdate.get('algorithmType').reset();
    this.teamTypeList = null;
    this.algoTypeList = null;
    this.allocationList = null;
    //this.entity.teamType = null;
    this.entity.algorithmType = null;
    this.entity.teamId = null;
    this.entity.groupId = null;
     this.entity.currentAssigneeId = null;
     if(el !== null)
    this.getTeamType(el);
  }

  getTeamType(id: any) {
    this.CommonListService.getTeamTypeList(id).subscribe((res) => {
      this.teamTypeList = res.dataList;
      // this.entity.teamType = this.teamTypeList[0].name;
      // this.teamName = this.teamTypeList[0].name;
      const selectedTeamType=this.teamTypeList.find((el=>el.name==this.entity.teamType));
      if(selectedTeamType && selectedTeamType.teamTypeId){
        this.entity.teamTypeId=selectedTeamType.teamTypeId
           this.teamName = selectedTeamType.name;
            this.getGroupList();
      }
      //this.getTeamList();
    });
    //this.getallocationType();
  }

  getallocationType() {
    this.CommonListService.allocationType().subscribe((res) => {
      this.allocationList = res.dataList;
      this.entity.allocationType = this.allocationList[0].text;
    });
  }

  getalgoType() {
    this.CommonListService.algorithmType().subscribe((res) => {
      this.algoTypeList = null;
      if (this.entity.longitude == null && this.entity.latitude == null) {
        this.algoTypeList = res.dataList;
      } else {
        if (this.dynamicdata) {
          if ((this.dynamicdata['Latitude'] !== '-') || (this.dynamicdata['Longitude'] !== '-')) {
            this.algoTypeList = res.dataList;
          }
          else {
            this.algoTypeList = []
            this.algoTypeList = res.dataList.filter(res => res.text == "Least Allocation")
          }
        }
      }
    });
  }

  setTeamAPiCall(e) {
    if (e) {
      this.teamName = e;
      //this.groupName = e;
      this.TeamList = null
      this.staffList = null
      this.allocationList = null
      this.algoTypeList = null
      this.formupdate.get('allocationType').reset()
      this.formupdate.get('algorithmType').reset()
      this.formupdate.get('teamId').reset()
      this.formupdate.get('groupId').reset()
      this.formupdate.get('staffId').reset()
      this.getallocationType()
      this.getGroupList();
    }
    else if(e === null)
    {
      this.formupdate.get('allocationType').reset();
      this.formupdate.get('teamId').reset();
      this.formupdate.get('groupId').reset();
      this.formupdate.get('algorithmType').reset();
      this.algoTypeList = null;
      this.allocationList = null;
      //this.entity.teamType = null;
      this.entity.algorithmType = null;
      this.entity.teamId = null;
      this.entity.groupId = null;
       this.entity.currentAssigneeId = null;  
    }

  }

  allocationType(e) {
    this.formupdate.get('teamId').reset();
    this.formupdate.get('groupId').reset();
    this.formupdate.get('staffId').reset();
    this.formupdate.get('algorithmType').reset();
    if (e.value == 'Auto') {
      this.enableflag = true;
      this.getalgoType();
      delete this.entity.teamId
      delete this.entity.groupId
       delete this.entity.currentAssigneeId
      this.formupdate.get('algorithmType').updateValueAndValidity();
      this.formupdate.get('teamId').clearValidators();
      this.formupdate.get('teamId').updateValueAndValidity();
      this.formupdate.get('groupId').clearValidators();
      this.formupdate.get('groupId').updateValueAndValidity();
      this.formupdate.get('staffId').clearValidators();
      this.formupdate.get('staffId').updateValueAndValidity();
    }
    else if (e.value == 'Manual') {
      this.enableflag = false;
      delete this.entity.algorithmType
      //this.getalgoType();//*refresh
      //this.getTeamList();
    }
  }

  // setnewdata() {
  //   this.entity
  // }

  // getData(): CasesModel {
  //   return this.entity;
  // }

  // getSubscriberByID(id) {
  //   if (id) {
  //     this.Subscriber.getSubscriberById(id).subscribe((res) => {
  //       this.customer = res.data;
  //     });
  //   }

  // }

  public commonList() {

    this.CommonListService.getCommonListcaseFor().subscribe((res) => {
      if (this.Internalcase) {
        this.caseFor = res.dataList.filter(res => res.value === 'Internal')
      }
      else {
        this.caseFor = res.dataList.filter(res => res.value !== 'Internal')
      }
    });
    this.CommonListService.getCommonListcaseType().subscribe((res) => {
      this.caseType = res.dataList

    });
  
    this.CommonListService.getServiceAreabyTeamList().subscribe((res) => {
      this.serviceAreaList = res.dataList
    });

    this.CommonListService.algorithmType().subscribe((res) => {
      this.algorithmType = res.dataList
    });

    this.CommonListService.chargableType().subscribe((res) => {
      this.chargableType = res.dataList;
      });

    this.CommonListService.getCommonListcaseStatus().subscribe((res) => {
      this.caseStatus = res.dataList

    });
    this.CommonListService.getCommonListpriority().subscribe((res) => {
      this.priority = res.dataList

    });
  }

  

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  // public getNewModal(): CasesModel {
  //   // let abc = new CasesModel();
  //   // delete abc.caseReasonId
  //   return new CasesModel();
  // }


  // public getFormGroup(): FormGroup {
  //   return this.formupdate;
  // }

  // public getId(): number {
  //   return this.entity.caseId;

  // }

  get f() {
    return this.formupdate.controls;
  }

  calculateDiff(ms, lable: string) {
    let currentDate = new Date();
    ms = new Date(ms);
    if (currentDate && ms) {
      ms = currentDate.getTime() - ms.getTime();
      let seconds = Math.floor(ms / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      if (lable === 'caseHours') {
        return hours;
      }
      if (lable === 'caseDay') {
        return days;
      }
      if (lable === 'caseMinutes') {
        return minutes;
      }
      if (lable === 'caseSecond') {
        return seconds;
      }
    }
  }
  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  setResolution(objValue) {
    // if (objValue === "Resolved") {
    //   this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
    // }
    if (objValue === "Suspend") {
      this.formupdate.get('case_suspendtilldate_update').setValidators(Validators.required);
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
    else {
      this.formupdate.get('case_suspendtilldate_update').clearValidators()
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
  }

  updateFormSubmit() {
    
    this.submitted = true;
    if (this.formupdate.controls.case_message_update.value) {
    
      this.saveData.emit(this.entity)
    } else {
      this.toastrService.error('Please fill all details');
    }
  }

  getTeamList() {
    let obj = {
      "groupId": this.groupName,
      "serviceAreaIdList": [],
      "teamType": this.teamName
    }
    obj.serviceAreaIdList.push(this.serviceIdPass)
    this.CommonListService.teamListByManual(obj).subscribe((res) => {
      this.TeamList = res.dataList;
    const selectedTeam=this.TeamList.find((el=>el.name==this.entity.teamName));
    if(selectedTeam && selectedTeam.id){
      this.entity.teamId=selectedTeam.id
      this.selectStaff(selectedTeam)
    }
    });
  }

  getGroupList(){
    let obj={
      "serviceAreaIdList":[],
      "teamType": this.teamName
    }
    obj.serviceAreaIdList.push(this.serviceIdPass)
    this.CommonListService.groupListByManual(obj).subscribe((res) => {
      this.GroupList = res.dataList;
      const selectedGroup=this.GroupList.find((el=>el.groupName==this.entity.groupName));
      if(selectedGroup && selectedGroup.id){
        this.entity.groupId=selectedGroup.id
        this.groupName = selectedGroup.id
        this.getTeamList();
      }
    });
   }

   groupChange(e){
    this.formupdate.get('teamId').reset();
    this.formupdate.get('teamId').updateValueAndValidity();
    this.formupdate.get('staffId').reset();
    this.formupdate.get('staffId').updateValueAndValidity();
    this.TeamList = [];
    this.staffListArray = [];
    this.groupName = e
    this.getTeamList();
  }

  // getupdateListData() {
  //   if (this.entity && this.entity.caseUpdateList && this.entity.caseUpdateList.length > 0) {
  //     this.entity.caseUpdateList.forEach((res) => {
  //       res.updateDetails.forEach((x) => {
  //         if (x.entitytype === "Remarks") {
  //           res.remark = x.newvalue;
  //         }
  //         if (x.remarktype === "Public") {
  //           res.remarkType = x.remarktype;
  //         }
  //         if (x.remarktype === "Private") {
  //           res.remarkType = x.remarktype;
  //         }
  //       });
  //       let newDate = new Date(res.createDateString);
  //       res.calculatedDay = this.calculateDiff(newDate, 'caseDay');
  //       if (res.calculatedDay === 0)
  //         res.calculatedTimeH = this.calculateDiff(newDate, 'caseHours');
  //       if (res.calculatedTimeH === 0)
  //         res.calculatedTimeM = this.calculateDiff(newDate, 'caseMinutes');
  //       if (res.calculatedTimeM === 0) {
  //         res.calculatedTimeS = this.calculateDiff(newDate, 'caseSecond');
  //       }
  //     });
  //   }
  // }
  defaultSelectionAssignment(serviceareaid) {
    if(serviceareaid === null)
    {
      this.entity.serviceAreaId = -1;
      //this.entity.teamType = 'Back Office';
      this.setServiceToTeam(this.entity.serviceAreaId)
    }
    else if(serviceareaid !== null)
    {
      this.entity.serviceAreaId = serviceareaid;
      this.setServiceToTeam(this.entity.serviceAreaId)
    }
    
    setTimeout(() => {
      this.entity.allocationType = 'Manual';
    }, 100);
    // setTimeout(() => {
    //   this.getTeamList();
    // }, 200);
    // setTimeout(() => {
    //   if (this.TeamList.length > 0) {
    //     this.entity.teamId = this.TeamList[0].id
    //     this.selectStaff(this.TeamList[0]);
    //   }
    // }, 800);
  }


  cancelmodal() {
    this.CasesService.getCaseById(this.entity.caseId).subscribe((res) => {
      this.entity.priority = res.data.priority;
      this.entity.caseReasonId = res.data.caseReasonId;
      this.entity.caseStatus = res.data.caseStatus;
      this.entity.amount = res.data.amount;
      this.entity.issue = res.data.issue;
      this.entity.solution = res.data.solution;
      this.entity.receipt_no = res.data.receipt_no;
      this.entity.chargableType = res.data.chargableType;
      this.entity.suspendDate = res.data.suspendDate;
      this.entity.caseTitle = res.data.caseTitle;
      this.saveData.emit(this.entity);
    });
    this.ngbModalService.dismissAll();
  }


  selectStaff(val: any) {
    //this.formupdate.get('staffId').updateValueAndValidity();
    this.staffListArray = val.teamUserMapping.filter(v => v.isAssignable === true)
    let temp = this.staffListArray.find((st) => st.staffName == this.entity.currentAssigneeName);
    if(temp && temp.staffId)
    this.entity.currentAssigneeId = temp.staffId
    //this.entity.currentAssigneeName = this.staffListArray.find((st) => st.staffName == this.entity.currentAssigneeName).staffName;
    // s = this.entity.staffId;
  }

  onEnterRemark() {
    if (this.updateList.remark) {
      this.error_remark = false;
      return;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openResovled(resolvedpopup, event) {
    if (this.checkResolved == true)
      this.ngbRef = this.ngbModalService.open(resolvedpopup, { size: 'xl', backdrop: 'static' });
  }

  onchangeETR(){
    if(this.entity && this.entity.etr){
      this.updateList.etr = this.entity.etr;
    }
  }
  onchangeETRUnit(){
    if(this.entity&& this.entity.etrUnit){
      this.updateList.etrUnit=this.entity.etrUnit
    }
  }
  onchangenotify(){
    if(this.entity&&this.entity.notified_enable){
      this.updateList.notified_enable = this.entity.notified_enable;
    }
  }
  notified_enable(event){
    if(event.target.checked){
      this.formupdate.get('notified_enable').setValue(true);
    }else{
      this.formupdate.get('notified_enable').setValue(false);
    }
  }
  onReasonchange(event){
    this.formupdate.get('etr').reset()
    this.formupdate.get('etrUnit').reset()
    if(event){
      this.formupdate.get('etr').setValue(event.etr)
      this.formupdate.get('etrUnit').setValue(event.etrUnit)
  }
  }

}
