import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { MacDetailsService } from './mac-detail.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reset-mac',
  templateUrl: './reset-mac.component.html',
  styleUrls: ['./reset-mac.component.scss']
})
export class ResetMacComponent implements OnInit {

  submitted:boolean=false;
  @Input() custId : any;
  selectForUpdate : any;
  insertResetMac = new FormGroup({
    remarks: new FormControl('',Validators.required),
  });
  macDetail : any;
  macAddresses : any;
  constructor(
    private customModalService : CustomModalService,
    private macService : MacDetailsService,
    private cdRef:ChangeDetectorRef,
    private tosterservice : ToastrService

  ) { }

  ngOnInit() {
    this.macService.getMacDetails(this.custId).subscribe(result => {
      this.macDetail = result.data;
      this.macAddresses = result.data.macAddresses;
      this.setUpdateValueInForm(result.data);
    });
  }

  setUpdateValueInForm(reset_mac_data) {
    this.selectForUpdate = reset_mac_data;
    this.insertResetMac.patchValue(reset_mac_data);
  }

  onClickSaveForm() {
    this.submitted=true;
    if (this.insertResetMac.valid === true) {
      let self = this;
      alertify.confirm("Reset Mac","Are You Sure You want to Reset Mac ?",
      function() {
        self.insertResetMaconDb();
      },
      function() {
  
      });
    }
  }

  insertResetMaconDb() {

    if (this.insertResetMac.valid === true) {
      let resetmac = Object.assign({}, this.insertResetMac.value); 
      resetmac.remark = this.insertResetMac.get('remarks').value;
      this.macService.insertMacDetails(this.custId,resetmac).subscribe(result => {
          this.cdRef.detectChanges();
          let id : string = "reset-mac";
          this.closeModal(id);
          this.tosterservice.success('Reset Mac.');
        },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
    this.insertResetMac.reset();
   this.submitted = false;
  }
}
