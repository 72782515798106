import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ChargeService } from '../charge/charge.service';
import { TaxService } from '../tax/tax.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { CommonChargecategoryService } from '../../../services/common_chargecategory/common-chargecategory.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../data-sharing-service.service';
@Component({
  selector: 'app-add-charge',
  templateUrl: './add-charge.component.html',
  styleUrls: ['./add-charge.component.scss']
})
export class AddChargeComponent implements OnInit {
  saveBtn: boolean = false;
  incTaxAmount: number;
  chargeDataForGrid: any = { content: [] };
  chargeGridSettings: any = {};
  public sub: any = null;
  public charge_id: any = null;
  isChecked:boolean;
  IsincludingTax:boolean;
  partnerBalDeductable:boolean;
  isPriceOverride:boolean;
  show_update: any = false;
  selectForUpdate: any;
  isDisabled:boolean = false;
  public taxList: any[] = [{
    tax_name: '',
    tax_id: ''
  }];
  commonChargeCategory: any
  isHideMaxMin:boolean=false;
  chargeList: any[] = [
    { value_field: 'ADVANCE', display_field: 'Advance' },
    { value_field: 'CUSTOMER_DIRECT', display_field: 'Customer Direct' },
    { value_field: 'NON_RECURRING', display_field: 'Non Recurring' },
    { value_field: 'RECURRING', display_field: 'Recurring' },
    { value_field: 'REFUNDABLE', display_field: 'Refundable' }
  ];
  insertChargeForm = new FormGroup({
    charge_name: new FormControl('', [Validators.required]),
    charge_desc: new FormControl('', [Validators.required]),
    charge_type: new FormControl(null, [Validators.required]),
    price: new FormControl('', [Validators.required, Validators.pattern('^[0-9.]+[0-9.]*$')]),
    // actual_price: new FormControl('', Validators.pattern('^[1-9.]+[0-9.]*$')),
    tax_id: new FormControl(''),
    charge_category: new FormControl(null, [Validators.required]),
    sac_code: new FormControl('', [Validators.required]),
    isIncludeTax:new FormControl(false),
    partnerBalDeductable:new FormControl(false),

    isPriceOverride:new FormControl(false),
    maxamount:new FormControl(''),
    minamount:new FormControl('')
  });
  error_message = "This field is required.";
  constructor(
    private chargeService: ChargeService,
    private commonChargeCategoryService: CommonChargecategoryService,
    private taxService: TaxService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private tosterservice :ToastrService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddChargeComponent>,
    private sharingdataService: DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.charge_id = id;
      this.show_update = true;
      this.getCountryData();
    }
    // if(this.data && this.data.id){
      
    //   this.isDisabled = true;
    //   this.charge_id = this.data.id;
    //   this.show_update = false;
    //   this.getCountryData();
    //   this.insertChargeForm.disable();
    // }
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.charge_id = params['id'];
    //       this.show_update = false;
    //       this.getCountryData();

    //     }
    //   });
    this.commonChargeCategoryService.getCommonListChargeCategory().subscribe(
      (res) => {
        ////console.log("ADd-Chagen" + JSON.stringify(res.dataList))
        this.commonChargeCategory = res.dataList;
      }
    )
    this.getTaxList();
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }
  getCountryData() {
    let charge_id = this.charge_id;
    this.chargeService.getChargeById(charge_id).subscribe(
      result => {
        if(result.chargebyid.isIncludeTax){
          result.chargebyid.price = result.chargebyid.price + result.chargebyid.taxamount;
        }
        if(!result.chargebyid.isIncludeTax){
          result.chargebyid.price = result.chargebyid.price 
        }

        this.setUpdateValueInForm(this.transformChargeDataById(result.chargebyid));
        ////console.log("resulst:",result);
      
      });
  }
  transformChargeDataById(data) {
    
    let formatedData = [];
    let itemData: any = {};
    itemData.charge_id = data.id;
    itemData.charge_name = data.name;
    itemData.charge_desc = data.desc;
    itemData.price = data.price;
    itemData.actual_price = data.actualprice;
    itemData.tax_id = data.taxid;
    itemData.discount_id = data.discountid;
    itemData.dbr = data.dbr;
    itemData.charge_type = data.chargetype;
    itemData.isIncludeTax=data.isIncludeTax;
    this.isChecked=data.isIncludeTax;
    this.partnerBalDeductable = data.isPartnerBalDeduction;
    itemData.chargecategory = data.chargecategory;
    itemData.actual_price = data.actualprice;
    itemData.tax = data.tax;
    itemData.sac_code = data.saccode;

    /**New */
    itemData.isPriceOverride=data.isPriceOverride;
    itemData.maxamount=data.maxamount;
    itemData.minamount=data.minamount;

    //formatedData.push(itemData);
   

    return itemData;
  }
  checkIncludingTax(event: any){
    if(event === 'true'){
      this.insertChargeForm.get('isIncludeTax').setValue(true);
      // this.insertPackagesForm.get('taxId').setValidators([Validators.required]);
      this.IsincludingTax=true; 
    }else{
      this.insertChargeForm.get('isIncludeTax').setValue(false);
     
      // this.insertPackagesForm.get('taxId').clearValidators();
      // this.insertPackagesForm.get('taxId').updateValueAndValidity();
      this.IsincludingTax=false; 
    }
    ////console.log(this.insertChargeForm.value)
  }

  checkpartnerBalDeductable(event: any){
    ////console.log("event",event);
    if(event === 'true'){
      this.insertChargeForm.get('partnerBalDeductable').setValue(true);
      // this.insertPackagesForm.get('taxId').setValidators([Validators.required]);
      this.partnerBalDeductable=true; 
    }else{
      this.insertChargeForm.get('partnerBalDeductable').setValue(false);
     
      // this.insertPackagesForm.get('taxId').clearValidators();
      // this.insertPackagesForm.get('taxId').updateValueAndValidity();
      this.partnerBalDeductable=false; 
    }
    ////console.log(this.insertChargeForm.value)
  }

  changepriceOverride(event:any){
    
    if(event==='true'){
    
    this.isHideMaxMin=true;
    this.insertChargeForm.get('isPriceOverride').setValue(true);

    this.insertChargeForm.get('maxamount').setValidators([Validators.required])
    this.insertChargeForm.get('maxamount').updateValueAndValidity();
    
    this.insertChargeForm.get('minamount').setValidators([Validators.required])
    this.insertChargeForm.get('minamount').updateValueAndValidity();
    
    }else{
    
      this.isHideMaxMin=false;
      this.insertChargeForm.get('isPriceOverride').setValue(false);
      this.isPriceOverride=false; 
      this.insertChargeForm.get('maxamount').reset();
      this.insertChargeForm.get('maxamount').clearValidators();
      this.insertChargeForm.get('maxamount').updateValueAndValidity();
      this.insertChargeForm.get('minamount').reset();
      this.insertChargeForm.get('minamount').clearValidators();
      this.insertChargeForm.get('minamount').updateValueAndValidity();
    }
  }

  getTaxList(tax_id?: any) {
    this.taxService.getTaxList().subscribe(result => {
      this.taxList = result.content;
      if (tax_id) {
        this.insertChargeForm.patchValue({ tax_id: tax_id });
      }
    });
  }

  transformInsertData(apiInputData) {
    let formatedInputData: any = {};
    formatedInputData.name = apiInputData.charge_name;
    formatedInputData.desc = apiInputData.charge_desc;
    formatedInputData.chargetype = apiInputData.charge_type;
    formatedInputData.price = apiInputData.price;
    formatedInputData.actualprice = apiInputData.price;
    formatedInputData.taxid = apiInputData.tax_id;
    formatedInputData.saccode = apiInputData.sac_code;
    formatedInputData.isIncludeTax=apiInputData.isIncludeTax;
    formatedInputData.chargecategory = apiInputData.charge_category;
    formatedInputData.isPartnerBalDeduction=apiInputData.partnerBalDeductable;

    formatedInputData.isPriceOverride=apiInputData.isPriceOverride;
    formatedInputData.maxamount=apiInputData.maxamount;
    formatedInputData.minamount=apiInputData.minamount;
     return formatedInputData;
  }

  insertChargeOnDb() {
       
    if(this.isPriceOverride===false){
    
      this.insertChargeForm.get('minamount').reset();
      this.insertChargeForm.get('minamount').clearValidators();
      this.insertChargeForm.get('minamount').updateValueAndValidity();
      this.insertChargeForm.get('maxamount').reset();
      this.insertChargeForm.get('maxamount').clearAsyncValidators();
      this.insertChargeForm.get('maxamount').updateValueAndValidity();
    }
   
    if (this.insertChargeForm.valid === true) {
      
      this.saveBtn = true;
      let apiInputData = Object.assign({}, this.insertChargeForm.value);
      let formatedInputData = this.transformInsertData(apiInputData);
    
      this.chargeService.insertCharge(formatedInputData).subscribe(result => {
        if (result.status !== 200) {
          this.saveBtn = false;
          this.tosterservice.error(result.ERROR);
          return;
        }
        else {
          this.saveBtn = false;
          let data: any = {};
          let chargeData: any = result.charge;
          data.charge_name = chargeData.name;
          data.charge_desc = chargeData.desc;
          data.charge_type = chargeData.chargetype;
          data.price = chargeData.price;
          data.actual_price = chargeData.actualprice;
          data.tax_id = chargeData.taxid;
          data.charge_category = chargeData.chargecategory;
          this.insertChargeForm.reset();
          this.cdRef.detectChanges();

          this.tosterservice.success('Charge has been created successfully');
          this.backToViewCharge();

        }

      },
        err => {
          this.saveBtn = false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateChargeOnDb() {
    
   if(this.insertChargeForm.get('isPriceOverride').value===true && this.insertChargeForm.get('isPriceOverride').value === null){
    this.insertChargeForm.get('maxamount').setValidators([Validators.required])
    this.insertChargeForm.get('maxamount').updateValueAndValidity();
    
    this.insertChargeForm.get('minamount').setValidators([Validators.required])
    this.insertChargeForm.get('minamount').updateValueAndValidity();
   }else{
    this.insertChargeForm.get('minamount').clearValidators();
    this.insertChargeForm.get('minamount').updateValueAndValidity();
    this.insertChargeForm.get('maxamount').clearAsyncValidators();
    this.insertChargeForm.get('maxamount').updateValueAndValidity();
   }

   let maxamount=this.insertChargeForm.get('maxamount').value;
    let minamount=this.insertChargeForm.get('minamount').value;
   



    if (this.insertChargeForm.valid === true) {

      this.saveBtn = true;
      let apiInputData = Object.assign({}, this.insertChargeForm.value);
      let charge_id = this.selectForUpdate.charge_id;
      let formatedInputData = this.transformInsertData(apiInputData);
      this.chargeService.updateCharge(formatedInputData, charge_id).subscribe(result => {
        if (result.status !== 200) {
          this.tosterservice.error(result.ERROR);
        } else {
          this.show_update = false;
          this.tosterservice.success('Charge Update successfully');
          this.backToViewCharge();
        }

        //   this.chargeDataForGrid.content.forEach((item, index) => {
        //     if(result.status !== 200){
        //       this.saveBtn =false;
        //       this.tosterservice.error(result.ERROR);
        //       return;
        //     }
        //     else{
        //       this.saveBtn =false;
        //       let data: any = result.charge;
        //       if (item.charge_id === charge_id) {
        //         this.chargeDataForGrid.content[index].charge_name = data.name;
        //         this.chargeDataForGrid.content[index].charge_desc = data.desc;
        //         this.chargeDataForGrid.content[index].charge_type = data.chargetype;
        //         this.chargeDataForGrid.content[index].price = data.price;
        //         this.chargeDataForGrid.content[index].actual_price = data.actualprice;
        //         this.chargeDataForGrid.content[index].tax_id = data.taxid;
        //         this.chargeDataForGrid.content[index].charge_category = data.chargecategory;
        //       }
        //       this.cdRef.detectChanges();
        //       this.show_update = false;
        //       this.tosterservice.success('Charge Update successfully');
        //       this.backToViewCharge();
        //     }

        //   });
        // },
        //   err => {
        //     this.saveBtn =false;
        //     this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  setUpdateValueInForm(chargeData) {
    
    this.show_update = true;
    this.selectForUpdate = chargeData;
    
    if(chargeData.isPriceOverride ===true){
      this.isHideMaxMin=true;
    }else{
      this.isHideMaxMin=false;
    }
    
    let chargeFormData = {
      charge_name: chargeData.charge_name,
      charge_desc: chargeData.charge_desc,
      charge_type: chargeData.charge_type,
      price: chargeData.price,
      actual_price: chargeData.actual_price,
      tax_id: chargeData.tax_id,
      charge_category: chargeData.chargecategory,
      sac_code: chargeData.sac_code,
      discount_id: chargeData.discount_id,
      dbr: chargeData.dbr,

      isPriceOverride:chargeData.isPriceOverride,
      maxamount:chargeData.maxamount,
      minamount:chargeData.minamount
    };
    if (Number.isInteger(chargeData.price)) {
      chargeFormData.price = chargeData.price + ".00";
    }
    ////console.log(chargeFormData);
    this.insertChargeForm.patchValue(chargeFormData);
    this.getTaxList(chargeFormData.tax_id);
  }

  backToViewCharge() {
    //this.router.navigate(['plan-management/charge']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['plan-management/charge']);
    }
  }

  validateForm() {
    
    if (!this.insertChargeForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertChargeForm.controls).forEach(field => {
        const control = this.insertChargeForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
     
      if (this.charge_id === null) {
        this.insertChargeOnDb();

      } else {
        this.updateChargeOnDb();

      }
    }
  }

  spaceNotAllow(event) {
    if (event.which == 32) {
      // alertify.error("Space Not Allowed");
      return true;
    }
  }
  closeDialog(){
    this.dialogRef.close();
   }

   minBlur() {

   let priceCurrent= this.insertChargeForm.get('price').value;
   let minPriceCurrent=this.insertChargeForm.get('minamount').value;
   
   
    if(priceCurrent){
      if(minPriceCurrent && minPriceCurrent > priceCurrent){
        // this.tosterservice.error("Min Price Can't be greater then Price");
        this.insertChargeForm.get('minamount').setValue(priceCurrent);
        this.insertChargeForm.get('minamount').updateValueAndValidity();
      } 
   }else{
       this.tosterservice.error("Select Price")
    }
    
    }

  maxBlur() {

    let priceCurrent= this.insertChargeForm.get('price').value;
    let maxPriceCurrent=this.insertChargeForm.get('maxamount').value;
     if(priceCurrent){
       if(maxPriceCurrent && maxPriceCurrent < priceCurrent){
        this.insertChargeForm.get('maxamount').setValue(priceCurrent);
        this.insertChargeForm.get('maxamount').updateValueAndValidity();
       }
      }else{
       this.tosterservice.error("select Price");
      }
    } 
 
}
