export const TableKeyEnumPerPage = {
        CITY_LIST_PER_PAGE : 10,
        FRANCHISE_LIST_PER_PAGE : 10,
        AREA_LIST_PER_PAGE : 10,
        COUNTRY_LIST_PER_PAGE : 10,
        STATE_LIST_PER_PAGE : 10,
        TRACK_PAYMENT_LIST_PER_PAGE : 10,
        RADIUS_CLIENT_PER_PAGE : 10,
        POLICIES_LIST_PER_PAGE : 10,
        RADIUS_PROFILE_LIST_PER_PAGE : 10,
        ACCOUNTING_PROFILE_LIST_PER_PAGE : 10,
        SUBSCRIBER_CDR_LIST_PER_PAGE : 10,
        CONNECTED_SUBSCRIBER_LIST_PER_PAGE : 10,
        AUDIT_LIST_PER_PAGE : 10,
        TAX_LIST_PER_PAGE : 10,
        DISCOUNT_LIST_PER_PAGE : 10,
        BILLINGTEMPLATE_LIST_PER_PAGE : 10,
        SERVICE_LIST_PER_PAGE : 10,
        CHARGE_LIST_PER_PAGE : 10,
        STAFF_LIST_PER_PAGE : 10,
        PACKAGES_LIST_PER_PAGE : 10,
        QOS_POLICY_PER_PAGE : 10,
        NETWORK_DEVICE_LIST_PER_PAGE : 10,
        CLIENT_GROUP_PER_PAGE : 10,
        SERVICE_AREA_STATUS_PER_PAGE : 10,
        CASE_REASON_PER_PAGE : 10,
        CASE_REASON_CONFIG_PER_PAGE : 10,
        CASES_PER_PAGE : 10,
        IP_POOL_CONFIG_PER_PAGE : 10,
        NETWORK_SESSION_LIST_PER_PAGE : 10,
        ALL_CASES_OF_CUSTOMER_PER_PAGE : 10,
        ACTIVE_PLAN_LIST_PER_PAGE : 10,
        FUTURE_PLAN_LIST_PER_PAGE : 10,
        PLAN_HISTORY_LIST_PER_PAGE : 10,
        PAYMENTS_LIST_PER_PAGE : 10,
        PURCHASES_LIST_PER_PAGE : 10,
        TROUBLETICKETSDETAILS_PER_PAGE : 10,
        SUBSCRIBER_LIST_PER_PAGE : 10,
        SERVICE_GRID_LIST_PER_PAGE : 10,
        PRICE_BOOK_LIST_PER_PAGE : 10,
        PAYMENT_GATEWAY_PER_PAGE : 10,
        ACL_LIST_PER_PAGE : 10,
        CUSTOMER_DOCUMENT_LIST_PER_PAGE : 10,
        CHARGES_LIST_PER_PAGE : 10,
        OTHER_UPDATES_PER_PAGE : 10,
        OTHER_COMMUNICATION_PER_PAGE : 10,
        COMMISSION_BY_PARTNER_PER_PAGE: 10,
        PARTNER_PAYMENT_DETAILS_PER_PAGE: 10,
        Teams_PER_PAGE: 10,
        ASSOCIATED_PLAN_PER_PAGE: 10,
        PURCHASE_HISTORY_REQUEST_PER_PAGE: 10,
        PINCODE_PER_PAGE: 10,
        RECENT_RENEWAL_PER_PAGE:10,
        CHARGE_REPORT_PER_PAGE:10,
        VALUE_ADDED_SERVICE_PER_PAGE:10,
        ONU_STAT_PER_PAGE:10,
        PARTNER_CONFIGURE_PER_PAGE:10,
        QOS_POLICY_DETAIL_PER_PAGE:10,
        QOS_POLICY_DETAILLIST_PER_PAGE:10,
        PROVISION_STATUS_PER_PAGE:10,
        SMS_PROVIDER_PER_PAGE:10,
        GROUP_NOTIFICATION_PER_PAGE:10,
        NETWORK_STATUS_PER_PAGE:10,
        CUSTOMER_LIST_PER_PAGE:10,
        PROPERTY_LIST_PER_PAGE:10,
        CIRCUIT_LIST_PER_PAGE:10,
        PAYMENT_REPORT_PER_PAGE:10,
        PARTNER_PAYMENT_GATEWAY_PER_PAGE:10,
        NETWORK_SEARCH_PER_PAGE:10,
        BROADCAST_HISTORY_PER_PAGE: 10,
        CHANGE_EXPIRY_PLAN_REPORT_PER_PAGE:10,
        CUSTOMER_STATUS_REPORT_PER_PAGE:10,
        ACTIVATE_PLAN_PER_PAGE:10,
        ACTIVATE_PLAN_FUTURE_PER_PAGE: 10,
        AllOCATION_IP_ADDRESS_PER_PAGE:10,
        NEW_ACTIVATION_REGISTERED_PER_PAGE:10,
        EXPIRED_REPORT_PER_PAGE:10,
        MTTR_REPORT_PER_PAGE:10,
        IP_POOL_CONFIGURATION_PER_PAGE:10
}