import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { NetworkDevices } from '../model/network-detail.model';
import { Slot } from '../model/slot.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class NetworkDevicesService {
  constructor(private http: HttpClient) { }

  getNetworkDevicesDataWithPageing(fetch_setting_data): Observable<any> {
    let page = fetch_setting_data.page;
    let size = fetch_setting_data.size;
    let params = new HttpParams().set("page", page).set("size", size);
    const get_url = BASE_API_URL + 'NetworkDevice';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers, params: params }).pipe(
        map(res => res as NetworkDevices[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getNetworkDevice(params:HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'NetworkDevice/list';

    return this.http.post<any>(post_url, params,
      { headers: httpOptions.headers}).pipe(
        map(res => res as NetworkDevices[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
getSNMPSlotList(uuid){
  const get_url = BASE_API_URL + 'NetworkDevice/snmp/getSlotByNetworkDevice/'+ uuid;
  return this.http.get<any>(get_url)
}
getSNMPPortList(slot_name,uuid){
  const get_url = BASE_API_URL + 'NetworkDevice/snmp/getPortBySNMPSlot/'+slot_name+'/'+uuid;
  return this.http.get<any>(get_url)
}
  getNetworkDeviceByName(network_name : any): Observable<any> {
    const get_url = BASE_API_URL + 'NetworkDevice';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as NetworkDevices[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getNetworkDevicesData(id): Observable<any> {
    const get_url = BASE_API_URL + 'NetworkDevice/' + id;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as NetworkDevices[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  insertNetworkDevices(packanetwork_devicesges_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'NetworkDevice/save';
    return this.http.post<any>(post_url, packanetwork_devicesges_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateNetworkDevices(packanetwork_devicesges_data: any): Observable<any> {
    const update_url = BASE_API_URL +"NetworkDevice/updateNetwork";
    return this.http.post<any>(update_url, packanetwork_devicesges_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteNetworkDevices(packanetwork_devicesges_data: any): Observable<any> {
    const delete_url = BASE_API_URL+"NetworkDevice/delete";
    return this.http.post<any>(delete_url, packanetwork_devicesges_data, httpOptions);
  }

  getNetworkDeviceByServiceArea(partnerId : number): Observable<any> {
    const get_url = BASE_API_URL + 'NetworkDevice/byServiceId/'+partnerId+'';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as NetworkDevices[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getSlotByNetworkDevice(networkDeviceId : number): Observable<any> {
    const get_url = BASE_API_URL + 'OLTSlot/byNetworkId/'+networkDeviceId+'';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as Slot[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getOLTDevice():Observable<any>{
    const get_url=BASE_API_URL+"NetworkDevice/snmp/getOltDevices";
    return this.http.get<any>(get_url);
  }
  getSNMPFlag():Observable<any>{
    const get_url=BASE_API_URL+"commonList/generic/commonYN";
    return this.http.get<any>(get_url);
  }

  getOLTNetworkDevices(params:any){
    const post_url = BASE_API_URL +"NetworkDevice/refreshNetworkDeviceWithPagination";
    return this.http.post<any>(post_url,params, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getHardwareSpec(){
    const get_url=BASE_API_URL+'NetworkDevice/snmp/getHardwareSpec';
    return this.http.get<any>(get_url);
  }
  getLastFetchData(params:any){ 
    const post_url = BASE_API_URL +"NetworkDevice/snmp/getLastDataWithPagination";
    return this.http.post<any>(post_url,params, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
