import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class ValueAddedServiceService {
  constructor(private http: HttpClient) {}
  getValueAddedList(custId){
    
    const get_url = baseApiUrl + 'subscriber/getVAS/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  provicsionset(id){
    const get_url = baseApiUrl + 'VAS/performVAS/' + id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers })
  }
}
