import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomModalService } from '../../../../../app/components/custom-modal/custom-modal.service';
import { BasicDetailsService } from '../basic-details/basic-details.service';
import * as alertify from 'alertifyjs';
import { SubscriberDetailsComponent } from '../subscriber-details.component';
import { debug } from 'console';
import { RecentRenewalService } from '../../../reports/recent-renewal.service';
import { TokenStorageService } from '../../../../../../src/app/auth/token-storage-service';
import { ActivePlanListService } from '../subscriber-tables/active-plan/active-plan.service';


@Component({
  selector: 'app-change-partner',
  templateUrl: './change-partner.component.html',
  styleUrls: ['./change-partner.component.scss']
})
export class ChangePartnerComponent implements OnInit {

  @ViewChild(SubscriberDetailsComponent, {static: false})
  private subscriberComponent: SubscriberDetailsComponent;
  
  partnerList:any;
  submitted:any = null;
  submitedFlag:boolean=false;
  @Input() partnername: string;
  @Input() service: string;
  @Input() partnerNameChild: string;
  @Input() partnerId:number;
  @Input() custId: any;

  customerStatus:string;
  submitBtnDisable:boolean=false;
  activePlanTableData:any;

  changePartnerForm = new FormGroup({
    partnerid: new FormControl('',[Validators.required]),
    subscriberid: new FormControl(''),
    partnername: new FormControl(''),
    remark:new FormControl('',[Validators.required])
  });

  constructor(
    private basicDetailsservice:BasicDetailsService,
    private recentRemewalService: RecentRenewalService,
    private customModalService: CustomModalService,
    private toastr:ToastrService,
    private tokenservice:TokenStorageService,
    private activePlanListService:ActivePlanListService
  ) { 
   // this.customerStatus =  JSON.parse(this.tokenservice.getCustomerStatus());
    // this.submitBtnDisable= (this.customerStatus == "NewActivation") ? true : false ;
  }
  
  ngOnInit() {

  this.LoadFormControl();
  if(this.custId){
    this.getSubscrinerPartnerList(this.custId);
  }
  this.getActivePlanData()
  
}
  
  LoadFormControl(){
    
    this.changePartnerForm.get('partnername').setValue(this.partnerNameChild)
    this.changePartnerForm.get('partnername').disable();
    this.changePartnerForm.get('subscriberid').setValue(this.custId);
  
  }
  
  onClickSaveChangePartnerForm(){
    
    if(this.changePartnerForm.invalid){
      this.submitedFlag=true;
      return;
    }

    if(this.changePartnerForm.valid && this.submitedFlag!==true){
      let formValue=this.changePartnerForm.value;
      //this.insertBasicDetailsOnDb(formValue);
      if (this.changePartnerForm.valid === true) {
      let self = this;
      alertify.confirm("Change Subscriber Partner","Are You Sure You want to Change Current Partner?",
      function() {
        self.insertBasicDetailsOnDb(formValue);
        // this.closeChangePartnerPopup
      },
      function() {
  
      });
    } 
  }
    
  }

  insertBasicDetailsOnDb(formValue){
    this.basicDetailsservice.updateNewPartner(formValue).subscribe((result)=>{
      if(result.responseCode===200){
      this.toastr.success("Subscriber Partner Changed Successfully");
      this.closeModal('change-partner');
      // this.subscriberComponent.getSubscriberBasicDetails();
      this.customModalService.close('change-partner',result);
      }else{
      this.toastr.error("Some thing Went Wrong");
      }
    })
  }

  closeModal(id:any){
     this.customModalService.close(id,{close_from:"close_button"});
     this.submitted = false;
  }

  getSubscrinerPartnerList(id){
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.basicDetailsservice.getNewPartnerList(id).subscribe((result)=>{
         this.partnerList=result.data.partnerlist;
         this.changePartnerForm.get('partnername').setValue(result.data.partnername)
        })    
    }
    else{
      this.basicDetailsservice.getNewPartnerList(id).subscribe((result)=>{
         this.changePartnerForm.get('partnername').setValue(result.data.partnername)
        })    
      this.recentRemewalService.getManagablePartnerlistService().subscribe(result => {
        this.partnerList = result.dataList;
      })
    }
  }


  getActivePlanData() {
    let obj=    {
      customerId: this.custId,
      purchaseType: "plan"
    }
    this.activePlanListService.getPlanListForActivation(obj).subscribe(result => {
      this.activePlanTableData = result.data;
      
      if(this.activePlanTableData.length > 0) {
        this.submitBtnDisable= true; 
      }else{
        this.submitBtnDisable= false; 
      }
      
    });
  }

}
