import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/share';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public status: Subject<boolean> = new Subject();
  private _active: boolean = false;
  constructor() { }

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public show(): void {
    this.active = true;
  }

  public hide(): void {
    this.active = false;
  }
}
