import { ChangeDetectorRef, Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PoliciesService } from '../policies/policies.service';
import _ from 'underscore';
import { ThrowStmt } from '@angular/compiler';
import { ReplyItemService } from '../replyitem/replyitem.service';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { ToastrService } from 'ngx-toastr';
export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
import { CommonStatusService } from '../../../services/common_status/common-status.service';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
@Component({
  selector: 'app-add-policies',
  templateUrl: './add-policies.component.html',
  styleUrls: ['./add-policies.component.scss']
})
export class AddPoliciesComponent implements OnInit {
  saveBtn:boolean=false;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  radiusProfileDataForGrid:any = {content:[]};
  radiusProfileGridSettings: any = {};
  public sub: any = null;
  public policies_id: any = null;
  show_update:any = false;
  selectForUpdate: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  statusList:any;
  error_message = "This field is required.";
  isDisabled:boolean=false;
  insertPoliciesForm:any;
  dynaauthpolicies:boolean;
  policyTypeList:any;

  constructor(
    private policiesService: PoliciesService,
    private router: Router,
    private route : ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef:ChangeDetectorRef,
    private replyItemService : ReplyItemService,
    private tosterservice:ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public CommonStatus: CommonStatusService,
    private commonlistservice: CommonListServicesService,
    public dialogRef: MatDialogRef<AddPoliciesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {}

  setGridSetting() {
    let self = this;
    this.allColumns = [
      { colKey: 'index', default: 'ID', rowKey: 'id', width: '5%', isFilterAllow: false, isNumber: true },
      { colKey: 'checkitem', default: 'Check Items', rowKey: 'checkitem', width: '40%', isNumber: false, isFilterAllow: false, enableEdit: true, enableDelete: true, enableSort: true },
      {
        colKey: 'replyItems',
        default: 'Reply Items',
        rowKey: 'replyItems',
        width: '40%',
        customControls: [this.statusWrapperRef],
        customValue:(replyItems) => {
          let item = "";
          for(let value of replyItems){
            if (replyItems.length < 1) {
              item += value.attribute + ", " + value.attributevalue;
            } else {
              item += value.attribute + ", " + value.attributevalue + ", ";
            }
          }
          return item;
        },
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '7%',
        isStickyEnd: true
      }
    ];

    this.radiusProfileGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.RADIUS_PROFILE_LIST,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Radius Profile ?',
      deleteMsgBoxDetail: '"Radius Profile Name :${checkitem} " '
    };
  }

  ngOnInit() {
    let URL  = this.router.url.includes('dynaauthpolicies')
    if(URL){
      this.dynaauthpolicies = true
      this.policiesService.dynaauthpolicies = true
      
    }else{
      this.dynaauthpolicies = false
      this.policiesService.dynaauthpolicies = false
    }
   this.insertPoliciesForm = new FormGroup({
      policies_id : new FormControl(''),
      policies_name: new FormControl('', [Validators.required]),
      status: new FormControl(null,[Validators.required]),
      policyType: new FormControl(null),
      dynaAuthType: new FormControl(null)
    });
    if(this.dynaauthpolicies){
      this.insertPoliciesForm.get('dynaAuthType').setValidators([Validators.required])
    }
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.statusList = res.dataList;
    })
    this.setGridSetting();
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.policies_id = id;
      this.show_update = true;
      this.getPoliciesDataById();
    }
    // if(this.data && this.data.id){
      
    //   this.isDisabled=true;
    //   this.policies_id = this.data.id;
    //   this.show_update = false;
    //   this.getPoliciesDataById();
    // }
    // this.sub = this.route
    // .queryParams
    // .subscribe(params => {
    //   let id = params['id']
    //   if (id) {
    //     this.policies_id = params['id'];
    //     this.show_update = true;
    //     this.getPoliciesDataById();
    //   }
    // });
    
    this.getRadiusProfileData();
    this.getDynaAuthPolicyType();
  }

  validateForm() {
    if (!this.insertPoliciesForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertPoliciesForm.controls).forEach(field => {
        const control = this.insertPoliciesForm.get(field);            
        if (control instanceof FormControl) {             
            control.markAsTouched({ onlySelf: true });
        } 
      });
    } else {
      if (this.policies_id === null) {
        this.insertPoliciesOnDb();
      } else {
        this.updatePoliciesOnDb();
      }
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'replyItems') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }

  onEdit(event) {
    this.setUpdateValueInFormRaplyitem(event.RowData);
  }

  onDelete(event) {
    let self = this;
    if (event.deletClicked) {
      self.delete(event.RowData);
    }
  }

  getRadiusProfileData() {
    let policies_id = this.policies_id;
    if (this.show_update) {
      let fetchSettingData = { };
      this.replyItemService.getReplyItemDataWithPageing(fetchSettingData,  policies_id).subscribe((result) => {
        this.radiusProfileDataForGrid = result;
        this.refreshDataSource(this.radiusProfileDataForGrid.content);
      });
    }
    
  }

  getDynaAuthPolicyType(){
    this.commonlistservice.getGenericCommonList('dynaAuthType').subscribe(res=>{
      this.policyTypeList = res.dataList;
    })
  }

  getPoliciesDataById() {
    let policies_id = this.policies_id;
    this.policiesService.getPoliciesById(policies_id).subscribe(
      result => {
        let data = result;
        this.setUpdateValueInForm(data);
      }
    )
  }

  getPoliciesByName(id: number) {
    let policies_name = this.insertPoliciesForm.get('policies_name').value;
    ////console.log(this.insertPoliciesForm.get('policies_name').value);
    this.policiesService.getPoliciesById(id).subscribe(
      name => {
        ////console.log(name);
        this.show_update = true;
        let data = _.find(name.content,{policies_name :policies_name});
        let policies_id = name.policies_id;
        if(this.dynaauthpolicies){
          this.router.navigate(['/radius/policies/add-dynaauthpolicies',policies_id]);
        }else{
          this.router.navigate(['/radius/policies/add-policies',policies_id]);
        }
      }
    )
  }

  setUpdateValueInForm(policiesData) {
    this.show_update = true;
    this.selectForUpdate = policiesData;
    if (policiesData.status ==  "1") {
      policiesData.status = 'Active';
    } else if (policiesData.status == "0") {
      policiesData.status = 'Inactive';
    }
    this.insertPoliciesForm.patchValue(policiesData);
  }

  transforInsertData(formData: any) {
    let formatedInputData: any = {};
    formatedInputData.name = formData.policies_name;
    formatedInputData.status = formData.status == 'Active' ? '1' : '0';
    formatedInputData.policyType = formData.policyType;
    formatedInputData.dynaAuthType = formData.dynaAuthType;
    return formatedInputData;
  }

  insertPoliciesOnDb() {
    if (this.insertPoliciesForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertPoliciesForm.value);
      let formatedInputData:any = this.transforInsertData(apiInputData);
      if(this.dynaauthpolicies == true){
        formatedInputData.policyType = 'DynaAuth'
      }else{
        formatedInputData.policyType = 'Radius'
      }
      if(formatedInputData && formatedInputData.name){
        this.policiesService.insertPolicies(formatedInputData).subscribe(result => {
          if(result.status !== 200){
            this.saveBtn =false;
            this.tosterservice.error(result.ERROR);
          }
          else{
            this.saveBtn =false;
            this.cdRef.detectChanges();
            this.tosterservice.success('Policies has been created successfully');
            this.getPoliciesByName(result.radiusprofile.id);
          }
             
            },
            err => {
              this.saveBtn =false;
              this.tosterservice.error(err.error.ERROR);
            });
      }
    }
  }

  updatePoliciesOnDb() {
    if (this.insertPoliciesForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertPoliciesForm.value);
      let policies_id = this.selectForUpdate.policies_id;
      if(this.dynaauthpolicies == true){
        apiInputData.policyType = 'DynaAuth'
      }else{
        apiInputData.policyType = 'Radius'
      }
      let formatedInputData:any = this.transforInsertData(apiInputData);
      this.policiesService.updatePolicies(formatedInputData,policies_id).subscribe(result => {
        if(result.status !== 200){
          this.saveBtn =false;
          this.tosterservice.error(result.ERROR);
        }
        else{
          this.saveBtn =false;
          this.cdRef.detectChanges();
          this.show_update = false;
          this.tosterservice.success('Policies Update successfully');
          this.onBack();
        }
         
        },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  delete(selected_radius_data) {
    let self = this;
    this.replyItemService.deleteReplyItem(selected_radius_data.radius_id).subscribe((result) => {
      if(result.status !== 200){
        this.tosterservice.error(result.ERROR);
      }
      else{
        this.tosterservice.success('Reply Item is deleted successfully');
        this.radiusProfileDataForGrid.content.forEach(function(item, index) {
          if (item.radius_id === selected_radius_data.radius_id) {
            self.radiusProfileDataForGrid.content.splice(index, 1);
          }
        });
        self.refreshDataSource(self.radiusProfileDataForGrid.content); 
      }
      // this.tosterservice.success('Reply Item is deleted successfully');
      // this.radiusProfileDataForGrid.content.forEach(function(item, index) {
      //   if (item.radius_id === selected_radius_data.radius_id) {
      //     self.radiusProfileDataForGrid.content.splice(index, 1);
      //   }
      // });
      // self.refreshDataSource(self.radiusProfileDataForGrid.content);
    },
    err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    });
  }

  onBack() {
  //  this.router.navigate(['/radius/policies']);

    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else if(this.dynaauthpolicies){
        this.router.navigate(['/radius/dynaauthpolicies']);
      }
      else{
      this.router.navigate(['/radius/policies']);
      /** Slash use Before Radisu */
      }
  }

  onAddRadiusProfileClick() {
    if(this.dynaauthpolicies){
      this.router.navigate(['/radius/policies/add-dynaauthpolicies/add-replyitem',this.insertPoliciesForm.value.policies_id]);
    }else{
      this.router.navigate(['/radius/policies/add-policies/add-replyitem',this.insertPoliciesForm.value.policies_id]);
    }
  }

  setUpdateValueInFormRaplyitem(rowData) {
    if(this.dynaauthpolicies){
      this.router.navigate(['/radius/policies/add-dynaauthpolicies/add-replyitem',rowData.radiusProfileId,rowData.radius_id]);
    }else{
      this.router.navigate(['/radius/policies/add-policies/add-replyitem',rowData.radiusProfileId,rowData.radius_id]);
    }
  }
  closeDialog(){
    this.dialogRef.close();
   }

}
