import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class OtherUpdatesService {
  constructor(private http: HttpClient) {}

  getOtherUpdates(custId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getSubscriberUpdates/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  searchOtherUpdates(update_data): Observable<any> {
    const post_url = baseApiUrl + 'subscriberUpdate/byCustomerAndTime';
    return this.http.post<any>(post_url, update_data,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  downloadInvoice(invoiceId : any) : Observable<any> {
    const get_url = baseApiUrl + 'subscriber/invoice/download/' + invoiceId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getOprations(){ 
    const get_url = baseApiUrl + 'subscriberUpdate/download/SubscriberOperationConstants';
    return this.http.get<any>(get_url);
  }
}
