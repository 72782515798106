import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { TatConsiderationService } from '../../../../services/tat_consideration/tat-consideration.service';
import * as alertify from 'alertifyjs';
import { PincodeModel } from '../pincode-model';
import { PincodeServiceService } from '../pincode-service.service';
import { CountryService } from '../../country/country.service';
import { StateService } from '../../state/state.service';
import { CityService } from '../../city/city.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../../../../app/pages/data-sharing-service.service';

export interface DialogPassData{
  id:number;
}
@Component({
  selector: 'app-pincode-add-update',
  templateUrl: './pincode-add-update.component.html',
  styleUrls: ['./pincode-add-update.component.scss']
})
export class PincodeAddUpdateComponent  extends AbstractBaseAddUpdateComponent<PincodeModel> implements OnInit {
  public entity: PincodeModel = new PincodeModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
  commonStatus: any;
  countryList: any;
  stateList:any;
  cityList:any;
  isDisabled:boolean=false;

  constructor(
    private pincodeService: PincodeServiceService,
    public CommonStatus: CommonStatusService,
    public CommonTat: TatConsiderationService,
    public countryService:CountryService,
    public sateservice:StateService,
    public cityservice:CityService,

    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    //private loadingService: LoadingService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<PincodeAddUpdateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,

    ) {
    super(toastrService, router);
  }

  ngOnInit() {
    this.init();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    if(id){
      this.isUpdateComponent = true;
      //this.isDisabled=true;
      this.getById(Number(id));
    }
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.commonStatus = res.dataList
    })
    this.getcountryList();
  }
getcountryList(){
  this.countryService.getCountryList().subscribe((res)=>{
    this.countryList = res.content
    ////console.log(this.countryList)
  });
}
  getService(): IBaseService<PincodeModel> {
    return this.pincodeService;
  }
  getstateByCountry(id){
    if(id && id>0){
      this.sateservice.stateBycountry(id).subscribe((res)=>{
        this.stateList = res.stateList;
        if(this.isUpdateComponent){
          this.stateList.find((ls)=>{
            ////console.log("ls",ls)
          if(ls.id === this.entity.stateId){
           this.onStateChange(ls)
             return ls
            }
         });
        }
        });
       
    }
  
  }
  onCountryChange(event){
    this.entity.stateId = null;
    this.getstateByCountry(event.country_id)
  }
 
  setData(data: PincodeModel) {
    this.entity = data;
   if(this.entity.countryId){
    this.getstateByCountry(this.entity.countryId);
   }
  
  }
  onStateChange(event){
    this.cityList = event.cityList;
  }
  getData(): PincodeModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {

    this.url = 'masters/pincode'
    return this.url;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): PincodeModel {
    return new PincodeModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.id;

  }

  get f() {
    return this.form.controls;
  }

  setValidation() {
    this.form = new FormGroup({
      pincode:new FormControl('', [Validators.required]),
    countryId: new FormControl('', [Validators.required]),
    cityId: new FormControl('', [Validators.required]),
    stateId: new FormControl('', [Validators.required]),
    status:new FormControl('', [Validators.required])
    });
    ////console.log(this.form);
  }

  formSubmit() {
    let self = this;
    if (this.validateForm()) {
      alertify.confirm("Pincode", "Are you sure you want to save this record?",
        function () {
          self.onSubmit();
        },
        function () {

        });
    }
  }
  backToViewList() {
    this.router.navigate(['masters/pincode']);
  }

  closeDialog(){
    this.dialogRef.close();
  }
getGenericDataService(){
return this.genericDataSharingService
}


}
