import { Component, OnInit,Input } from '@angular/core';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { LoginService } from '../../login/login.service';
import { SearchService } from '../../../services/search.service';
import { Subscription } from 'rxjs';
import { QosPolicyService } from '../../../services/qos-policy/qos-policy.service';
import { MatTableDataSource } from '@angular/material';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TemplateRef } from '@angular/core';
import { isThisTypeNode } from 'typescript';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-qos-policy-detail',
  templateUrl: './qos-policy-detail.component.html',
  styleUrls: ['./qos-policy-detail.component.scss']
})
export class QosPolicyDetailComponent extends OldAbstractBaseListComponent implements OnInit {
  
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  loginService:any;
  @Input() QosID:number;
  @Input() QoslistFlag:boolean;
  constructor(
    loginService: LoginService,
    public searchService:SearchService,
    public qosService:QosPolicyService,
    public router:Router,
    public toaster:ToastrService,
    public aroute:ActivatedRoute
  ) { 
    super(searchService);
    this.loginService = loginService;
  }
 
  allColumns:any[]=[];
  dataSource=new MatTableDataSource([]);
  filteredDataForGrid:any={}
  NeoGridTable:any={};
  subscriptions: Subscription[] = [];
  tablekeyEnum=TableKeyEnum;
  QosDataForGrid: any = { content: [] };
  
  ngOnInit() {
    this.setDefaultPageSize(this.tablekeyEnum.QOS_POLICY_DETAILLIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  public getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getListQosPolicy(config);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.QosDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.QosDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }


  getListQosPolicy(gridSetting:any){
    let fetchSettingData = this.NeoGridTable.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.qosService.getQosPolicy(this.QosID).subscribe(result => {
      this.QosDataForGrid = result.data;
      this.refreshDataSource(result.data);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.NeoGridTable.pageTotalLength = this.totalRecords;
    });
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onEdit(event) {
    ////console.log(event);
    //this.setUpdateValueInForm(event);
    this.setUpdateValueInFormQosDetails(event);
  }
  onDelete(event) {
    let self = this;
    if (event) {
      this.commonDeleteMsgBox(this.NeoGridTable,event,this.delete.bind(this));
    }
 }

 delete(objEvent) {
   let self = this;
   let selected_tax_data = objEvent.event;
   if (objEvent.deleteClicked) {
   this.qosService.deleteQosPolicy(selected_tax_data).subscribe((result) => {
     if(result.responseCode !== 200){
       this.toaster.error(result.ERROR);
     }
     else{
       this.toaster.success('Qos is deleted successfully');
       this.getListWithParams();
     }

   },err => {
     this.toaster.error(err.error.status + " " + err.error.ERROR);
   });
  }
 }
  setUpdateValueInFormQosDetails(rowData) {
    this.router.navigate(['/plan-management/qos-policy-detail/update/',rowData.id]);
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        sortKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'radiusPolParam',
        default: 'Radius Policy Parameter',
        rowKey: 'radiusPolParam',
        sortKey: 'radiusPolParam',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'paramDesc',
        default: 'Description',
        rowKey: 'paramDesc',
        sortKey: 'paramDesc',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'baseParam',
        default: 'Base Value',
        rowKey: 'baseParam',
        sortKey: 'baseParam',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'throughtParam',
        default: 'Throttle Value',
        rowKey: 'throughtParam',
        sortKey: 'throughtParam',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'status',
      //   default: 'STATUS',
      //   rowKey: 'status',
      //   sortKey: 'status',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
       // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });

    this.NeoGridTable = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tablekeyEnum.QOS_POLICY_DETAILLIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Record ?',
      deleteMsgBoxDetail: 'Radius PolParam :${radiusPolParam} ',
    };
    if (this.NeoGridTable.serverSideSortPagination && this.NeoGridTable.perPage) {
      this.setPaginationPerPage(this.NeoGridTable.perPage);
    }

  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
 
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getListQosPolicy(config);
      }
}
