import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "../../../../services/base-service";
import { ValueAddedeServiceModel } from "../model/ValueAddedeService";
const BASE_API_URL=''

@Injectable({
  providedIn: 'root'
})
export class ValueAddedeServiceService extends BaseService<ValueAddedeServiceModel>{

  constructor(http: HttpClient) {
    super(http);
  }

  getModuleName(): string {
    return 'VAS';
  }
  validate(): boolean {
    return false;
  }

  // getDataValueAddedService(params:HttpParams) {
  //   const post_url = BASE_API_URL + 'VAS';

  //   return this.http.post<any>(post_url,params,
  //     { headers: httpOptions.headers}).pipe(
  //       map((res: any) => {
  //         return res;
  //       }),
  //       catchError((error: any) => {
  //         return throwError(error);
  //       })
  //     );
  // }
  saveValueAddedService(data): Observable<any> {
    let post_url = BASE_API_URL + "VAS/save";
    return this.http.post<any>(post_url, data);
  }
  UpdateValueAddedService(data): Observable<any> {
    let post_url = BASE_API_URL + "VAS/update";
    return this.http.post<any>(post_url, data);
  }
  findValueAddedServiceById(serviceId) {
    let get_url = BASE_API_URL + "VAS/" + serviceId;
    return this.http.get<any>(get_url);
  }
  deleteValueAddedService(data): Observable<any> {
    let post_url = BASE_API_URL + "VAS/delete";
    return this.http.post<any>(post_url, data);
  }
  getTypeList():Observable<any> {
    let get_url = BASE_API_URL + "commonList/VAS";
    return this.http.get<any>(get_url);
  }
  
  commonStatus(): Observable<any> {
    const get_url = BASE_API_URL + "commonList/commonStatus";
    return this.http.get(get_url);
  }

  getBaseByTypeAndIsBase(value):Observable<any> {
    let get_url = BASE_API_URL + "VAS/getBaseByTypeAndIsBase/" + value + "/1";
    return this.http.get<any>(get_url);
  }

  getOTTServiceTypeList():Observable<any> {
    let get_url = BASE_API_URL + "commonList/generic/ottImages";
    return this.http.get<any>(get_url);
  }
}
