import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { MacDetails, MacDetailsService } from '../reset-mac/mac-detail.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
import { SubscriberDetailsComponent } from '../subscriber-details.component';
@Component({
  selector: 'app-manage-mac',
  templateUrl: './manage-mac.component.html',
  styleUrls: ['./manage-mac.component.scss']
})
export class ManageMacComponent implements OnInit {

  @Input() custId : any;
  insertManageMac = new FormGroup({
    macTelFlag: new FormControl(''),
    macAddresses: new FormArray([]),
    remarks: new FormControl('',Validators.required)
  });
  selectForUpdate : any;
  flagTel;
  isInvalidMacMsg:boolean;
  subscriber:any;
  macDetail : MacDetails;
  submitted:boolean=false;
  constructor(
    private customModalService : CustomModalService,
    private macDetailService : MacDetailsService,
    private cdRef : ChangeDetectorRef,
    private tosterservice : ToastrService

  ) { }

  ngOnInit() {
    this.getMacDetail();
    // this.subscriber=new SubscriberDetailsComponent(this.customModalService);
  }

  getMacDetail() {
    this.macDetailService.getMacDetails(this.custId).subscribe(result => {
      this.macDetail = result.data;
      this.setUpdateValueInForm(this.macDetail);
    })
  }

  setUpdateValueInForm(manage_mac_data) {
    this.selectForUpdate = manage_mac_data;
    for (let data of manage_mac_data.macAddresses) {
      this.onAddMacItems(data);
    }
    this.insertManageMac.patchValue(manage_mac_data);
  }
  onKeyUpMacAddress(el) {
    let value = el.target.value;
    if (value) {
      let regexp = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
      let mac_address = value;
      if (regexp.test(mac_address)) {
        this.isInvalidMacMsg = false;
      } else {
        this.isInvalidMacMsg = true;
      }
    } else {
      this.isInvalidMacMsg = false;
    }
  }

  onAddMacItems(row?) {
    (<FormArray>this.insertManageMac.get('macAddresses')).push(
      new FormGroup({
        id : new FormControl(''),
        macAddress: new FormControl(row ? row.macAddress: '', [Validators.pattern(/^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i)]),
        isDeleted: new FormControl(false),
        isEdit: new FormControl(true)
      })
  );
  }

  get macItems() {
    return (this.insertManageMac.get('macAddresses') as FormArray).controls;
  }

  onDelete(index : number,Items: FormGroup) {
    alertify.confirm("Are you sure to delete this Mac address","Mac Address:"+Items.value.macAddress,
    function(){
      Items.get('isDeleted').setValue(true);
    },
    function(){
    })
  }

  onClickSaveForm() {
    this.submitted=true;
    if (this.insertManageMac.valid === true) {
      let self = this;
      alertify.confirm("Manage Mac","Are You Sure You want to Change Manage Mac ?",
      function() {
        self.insertManageMaconDb();
      },
      function() {
  
      });
    } else {
      this.insertManageMac.markAllAsTouched();
    }
  }

  insertManageMaconDb() {
    if (this.insertManageMac.valid === true) {
      let formatedInputData:any = this.insertManageMac.value;
      formatedInputData.custId = this.custId;
      this.macDetailService.updateMacDetails(formatedInputData).subscribe(result => {
          this.cdRef.detectChanges();
          if (result.responseCode !== 200 && result.responseMessage) {
            this.tosterservice.error(result.responseMessage);
          } else {
            this.tosterservice.success('Mac Details Update successfully');
            this.customModalService.close('manage-mac',result);
          }
        },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }
  editRow(Items: FormGroup) {
    Items.get('isEdit').setValue(true);
  }

  doneRow(Items: FormGroup) {
    if(Items.status ==="VALID"){
      Items.get('isEdit').setValue(false);
    }
  }

}
