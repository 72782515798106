import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplyItemService } from './replyitem.service';
import _ from 'underscore';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-replyitem',
  templateUrl: './replyitem.component.html',
  styleUrls: ['./replyitem.component.scss']
})
export class ReplyitemComponent implements OnInit {

  public sub: any = null;
  public radiusProfileId: any = null;
  public readiusId : any = null;
  show_update:any = false;
  selectForUpdate: any;
  radiusProfileForm : FormGroup;
  error_message = "This field is required.";
  dynaauthpolicies:boolean;

  constructor(
    private router: Router,
    private route : ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef:ChangeDetectorRef,
    private replyItemService : ReplyItemService,
    private toasterservice: ToastrService
  ) { }

  ngOnInit() {
    let URL  = this.router.url.includes('dynaauthpolicies')
    if(URL){
      this.dynaauthpolicies = true
      //this.policiesService.dynaauthpolicies = true
    }else{
      this.dynaauthpolicies = false
      //this.policiesService.dynaauthpolicies = false
    }
    this.radiusProfileForm = new FormGroup({
      checkitem : new FormControl('',),
      replyItems : new FormArray([], [Validators.required])
    });
    let RadiusProfileId = this.route.snapshot.paramMap.get('RadiusProfileId');
    if (RadiusProfileId) {
      this.radiusProfileId = RadiusProfileId;
      this.show_update = false;
    }

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.readiusId = id;
      this.show_update = true;
      this.getReplyItemDataById();
    }
  }

  validateForm() {
    if (!this.radiusProfileForm.valid) {
      this.toasterservice.error("Please fill all the details!");
      Object.keys(this.radiusProfileForm.controls).forEach(field => {
        const control = this.radiusProfileForm.get(field);            
        if (control instanceof FormControl) {             
            control.markAsTouched({ onlySelf: true });
        } 
      });
    } else if (this.readiusId === null) {
      this.insertRadiusProfileOnDb();
    } else {
      this.updateRadiusProfileOnDb();
    }
  }

  getReplyItemDataById() {
    if (this.readiusId != null) {
      let id = this.radiusProfileId;
      this.replyItemService.getRadiusProfile(id).subscribe(
        result => {
          let data = _.find(result.content, { radius_id: parseInt(this.readiusId) });
          this.setUpdateValueInForm(data);
        }
      );
    }
  }

  setUpdateValueInForm(reply_item_Data) {
    this.show_update = true;
    this.selectForUpdate = reply_item_Data;
    for(let data of reply_item_Data.replyItems) {
      this.onAddReplyItems(data);
    }
    this.radiusProfileForm.patchValue(reply_item_Data);
  }

  onAddReplyItems(row?) {
    (<FormArray>this.radiusProfileForm.get('replyItems')).push(
      new FormGroup({
        id : new FormControl(''),
        attribute: new FormControl(row ? row.attribute: ''),
        radiusProfileCheckItemId: new FormControl(row ? row.radiusProfileCheckItemId: ''),
        radiusprofileid: new FormControl(row ? row.radiusprofileid: this.radiusProfileId),
        attributevalue: new FormControl(row ? row.attributevalue: '')
      })
  );
  }

  get replyItems() {
    return (this.radiusProfileForm.get('replyItems') as FormArray).controls;
  }

  onDelete(index : number) {
    (<FormArray>this.radiusProfileForm.get('replyItems')).removeAt(index);
  }

  insertRadiusProfileOnDb() {
    if (this.radiusProfileForm.valid === true) {
      let apiInputData = Object.assign({}, this.radiusProfileForm.value);
      let formatedInputData:any = this.radiusProfileForm.value;
      formatedInputData.radiusProfileId = this.radiusProfileId;
      this.replyItemService.insertReplyItem(formatedInputData).subscribe(result => {
          this.cdRef.detectChanges();
          this.backToAddPolicies();
          this.toasterservice.success('Radius Profile has been created successfully');
        },
        err => {
          this.toasterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateRadiusProfileOnDb() {
    if (this.radiusProfileForm.valid === true) {
      let apiInputData = Object.assign({}, this.radiusProfileForm.value);
      let radius_id = this.selectForUpdate.radius_id;
      let formatedInputData:any = this.radiusProfileForm.value;
      formatedInputData.radiusProfileId = this.radiusProfileId;
      this.replyItemService.updateReplyItem(formatedInputData, radius_id).subscribe(result => {
          this.cdRef.detectChanges();
          this.show_update = false;
          this.backToAddPolicies();
          this.toasterservice.success('Radius Profile Update successfully');
        },
        err => {
          this.toasterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  onBack() {
      let formatedInputData:any = this.radiusProfileForm.value;
      formatedInputData.radiusProfileId = this.radiusProfileId;
      this.backToAddPolicies();
  }

  backToAddPolicies() {
    if(this.dynaauthpolicies){
      this.router.navigate(['/radius/policies/add-dynaauthpolicies',this.radiusProfileId]);
    }else{
      this.router.navigate(['/radius/policies/add-policies',this.radiusProfileId]);
    }
  }

}
