import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { SubscriberLeasedServiceService } from '../../../masters/subscriber-leased-line/service/subscriber-leased-service.service';
import { ServiceAreaModel } from '../../../network-management/service-area/model/service-area-model';
import { ServiceAreaService } from '../../../network-management/service-area/service/service-area.service';

import { DunningModel, DunningModelRule } from '../model/dunning-model';
import { DunningRuleActionService } from '../service/dunning-rule-action.service';
import { DunningServiceService } from '../service/dunning-service.service';

@Component({
  selector: 'app-dunning-rule',
  templateUrl: './dunning-rule.component.html',
  styleUrls: ['./dunning-rule.component.scss']
})
export class DunningRuleComponent  extends AbstractBaseListComponent<DunningModelRule> implements OnInit,AfterViewInit, OnDestroy {
@Input() parentId:any
@Output() event: EventEmitter<any> = new EventEmitter();
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('day', { static: false }) dayRef: TemplateRef<any>;
  @ViewChild('serviceare', { static: false }) serviceAreaReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  submitted=false
  public loginService: LoginService;
  dataGrid: DunningModelRule[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  public entity: DunningModelRule = new DunningModelRule();
  public Dunningentity: DunningModel = new DunningModel();
  public entity_id: any = null;
  form: FormGroup;
  isUpdate: boolean = false;
  notificationList:any
  DunningRuleActionNeoGridSettings:any
  constructor(public entityService : DunningRuleActionService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private ngbModalService: NgbModal,
    loginService: LoginService) { 
      super(toastrService,searchService);
      this.loginService = loginService;
    }
    actionList :any
  ngOnInit() {
        // this.init()
    this.setDefaultPageSize(this.tableKeyEnum.DUNNING_RULE_ACTION)
    this.getTableColumn();
    this.getTableSetting();
    this.getDunningAction();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
this.entityService.getcommonList('dunningrulesAction').subscribe((res)=>{
  this.actionList =  res.dataList
})
this.entityService.DunningRuleNotificationList().subscribe((res)=>{
  this.notificationList =  res.data
})
this.setValidation();

    this.subscriptions.push(filterSubscription);
  }
getDunningAction(){
  let  obj={
    id :this.parentId
  }
  this.entityService.getActionByDunning(obj).subscribe(res=>{
    this.totalRecords = res.totalRecords
    this.DunningRuleActionNeoGridSettings.pageTotalLength = res.totalRecords
    this.refreshDataSource(res.dataList)
    this.event.emit(res.dataList)
  })
 
}
keypress(event: any) {
  this.form.controls.days.touched
  const pattern = /[0-9\.]/;
  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}
public getFormGroup(): FormGroup {
  return this.form;
}
get f() {
return this.form.controls;
}



  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'days') {
        col.customControls = [this.dayRef];
      }
    });
  }

  getService(): IBaseService<any> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'days',
        default: 'Days',
        rowKey: 'days',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'notificationname',
        default: 'Notification Name',
        rowKey: 'notificationname',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'action',
        default: 'Action',
        rowKey: 'action',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'Last Modified By',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return this.allColumns ? this.allColumns : [];
  }
  
  getTableSetting() {
    this.DunningRuleActionNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.DUNNING_RULE_ACTION,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Dunning Action Area ?',
      deleteMsgBoxDetail: 'Dunning Action Name :${name} ',
    };
  }
  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }
  setValidation() {
    this.form = new FormGroup({
    days:new FormControl('', [Validators.required]),
    action:new FormControl('', [Validators.required]),
    notificationid:new FormControl(null, [Validators.required]),
    });
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }
  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  // onEditClick(event) {
  //   ////console.log(event.RowData)
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    ////console.log(event);
   
   
  }
  onDeleteClick(event) {
    ////console.log(event);
   
   
  }
  // onDeleteClick(event) {
  //    if (event) {
  //      this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
  //    }
  // }  
  open(content) {
    
    this.ngbModalService.open(content, { size: 'xl' });
  }
  validateForm() {

    this.submitted = true;
    if (this.getFormGroup().invalid) {
      return false;
    }
    return true;
  }

  onclick(){
    this.submitted = true
    if(this.validateForm()){
      if(!this.isUpdate){
        this.entity.drule = new DunningModel()
        this.entity.drule.id = this.parentId
        this.entityService.save(this.entity).subscribe((res)=>{
          if(res.responseCode != 200){
            this.toastrService.error(res.responseMessage)
          }else{
          this.toastrService.success("Data Successfully Saved")
          this.submitted = false
          this.cancel()
  this.getDunningAction()
          }
           })
      }else{
        //console.log(this.entity);
        this.entity.drule = new DunningModel()
        this.entity.drule.id = this.parentId
        this.entityService.update(this.entity).subscribe((res)=>{
        if(res.responseCode != 200){
          this.toastrService.error(res.responseMessage)
        }else{
        this.toastrService.success("Data Successfully Updated")
        this.submitted = false
        this.cancel()
        this.getDunningAction()
        }
        })
      }
    }else{

      this.toastrService.error("Please Fill all Details")
    }
  }
  cancel(){
    //console.log(this.form)
    this.form.reset();
    this.submitted = false
    this.ngbModalService.dismissAll()
  }
  ondelete(data){
    data.drule = new DunningModel()
 data.drule.id = this.parentId
this.entityService.delete(data).subscribe(res=>{
  if(res.responseCode != 200){
    this.toastrService.error(res.responseMessage)
  }else{
  this.toastrService.success("Data Deleted Successfully")
  this.getDunningAction()
  }
})
  }


  onEdit(data,content){
    this.isUpdate = true;
    this.open(content)
    this.entity = Object.assign({},data)
  }

  setList(list: Array<DunningModelRule>) {
    this.dataGrid = list;
    //console.log("list dunning rule ",this.dataGrid);
    
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  onAddClick(content) {
    this.entity = new DunningModelRule()
    this.isUpdate = false;
 this.open(content)
  }
  setUpdateValueInForm(rowData) {
    this.router.navigate(['/network-management/serviceArea/add-serviceArea',rowData.id]);
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
