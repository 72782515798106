import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { TaxService } from './tax.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchService } from '../../../services/search.service';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaxComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('taxName', { static: false }) taxNameReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  taxDataForGrid: any = { content: [] };
  taxGridSettings: any = {};
  selectForUpdate: any;
  show_update: any = false;
  insertTaxForm = new FormGroup({
    tax_name: new FormControl('', [Validators.required]),
    tax_desc: new FormControl('', [Validators.required]),
    tax_type: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required])
  });

  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  taxNeoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredTaxDataForGrid: any = [];

  constructor(
    private taxService: TaxService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private tosterservice : ToastrService,
    loginService: LoginService
  ) {
    super(searchService);
    this.loginService = loginService;
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'tax_name',
        default: 'Tax Name',
        rowKey: 'tax_name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'tax_type',
        default: 'Tax Type',
        rowKey: 'tax_type',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue:(status) => {
          if(status === 'Y'){
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Tax Name',
        value_field: 'tax_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Tax Type',
        value_field: 'tax_type',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'Active',
            value_field: 'Y',
          },
          {
            display_field: 'In Active',
            value_field: 'N',
          },
        ],
      },
    ];

    this.taxNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.TAX_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Tax?',
      deleteMsgBoxDetail: 'Tax Name :${tax_name} ',
    };

    if (this.taxNeoGridSettings.serverSideSortPagination && this.taxNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.taxNeoGridSettings.perPage);
    }

  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.TAX_LIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getTaxData(config);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.taxDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.taxDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'tax_name') {
        col.customControls = [this.taxNameReff];
      }
    });
  }

  getTaxData(gridSetting: any) {
    let fetchSettingData = this.taxNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.taxService.getTaxDataWithPageing(params).subscribe(result => {
      this.taxDataForGrid = result;
      this.refreshDataSource(this.taxDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.taxNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
     let self = this;
     if (event) {
       this.commonDeleteMsgBox(this.taxNeoGridSettings,event,this.delete.bind(this));
     }
  }

  delete(objEvent) {
    let self = this;
    let selected_tax_data = objEvent.event;
    if (objEvent.deleteClicked) {
    this.taxService.deleteTax(selected_tax_data.tax_id).subscribe((result) => {
      if(result.status !== 200){
        this.tosterservice.error(result.ERROR);
      }
      else{
        this.tosterservice.success('Tax is deleted successfully');
        this.show_update = false;
        self.taxDataForGrid.content.forEach(function(item, index) {
          if (item.tax_id === selected_tax_data.tax_id) {
            self.taxDataForGrid.content.splice(index, 1);
          }
        });
        self.refreshDataSource(self.taxDataForGrid.content);
      }

    },err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    });
   }
  }

  onAddTaxClick() {
    this.router.navigate(['/plan-management/tax/add-tax']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/plan-management/tax/update-tax',rowData.tax_id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getTaxData(config);
      }
}
