import { Component, OnInit,Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';
import { invoiceDetail } from '../../reports/invoice-report/model/invoicedetail.model';
import { InvoiceService } from '../../reports/invoice-report/service/invoice.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';


@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent extends OldAbstractBaseListComponent implements OnInit {
  // @ViewChild ('remarkWrapper',{static:false}) remarkWrapperReff:TemplateRef<any>;
  // @ViewChild ('statusWrapper',{static:false}) statusWrapperReff:TemplateRef<any>;@ViewChild('amount', { static: false }) amountRef: TemplateRef<any>;
  @ViewChild('paidamount', { static: false }) paidamountRef: TemplateRef<any>;
  @ViewChild('totalamount', { static: false }) totalamountRef: TemplateRef<any>;
  @ViewChild('dueAmount', { static: false }) dueAmountRef: TemplateRef<any>;
  @ViewChild('receivedBy', { static: false }) receivedByRef: TemplateRef<any>;
  @ViewChild('collectorName', { static: false }) collectorNameRef: TemplateRef<any>;
  @ViewChild('reference', { static: false }) referenceRef: TemplateRef<any>;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;

  bulkhistoryDetailDataForGrid: any = { content: [] };
  filteredinvoiceDetailDataForGrid:any
  invoiceDetailGridSetting: any = {};
  show_update: any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  searchSettings = [];
  subscriptions: Subscription[] = [];
  public invoiceDetailList: Array<invoiceDetail>;
  invoiceList;
  private ngbRef: NgbModalRef;
  @Input() inpUuId:any;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private searchService: SearchService,
    private tosterservice: ToastrService,
    loginService: LoginService,
    private invoiceService: InvoiceService,
    private modalService: NgbModal
  ) {
    super(searchService);
    this.loginService = loginService;
  }
  ngOnInit() {
    this.setDefaultPageSize(this.tableKey.INVOICE_DETAIL_REPORT)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setGridSetting();
    this.initPaginationSort();
    this.subscriptions.push(filterSubscription);
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'paidAmount') {
        col.customControls = [this.paidamountRef];
      }
      if (col.colKey == 'totalamount') {
        col.customControls = [this.totalamountRef];
      }
      if (col.colKey == 'dueAmount') {
        col.customControls = [this.dueAmountRef];
      }
      if (col.colKey == 'collectorname') {
        col.customControls = [this.collectorNameRef];
      }
      if (col.colKey == 'receivedBy') {
        col.customControls = [this.receivedByRef];
      }
      if (col.colKey == 'reference') {
        col.customControls = [this.referenceRef];
      }
    });
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'dueAmount',
        default: 'due amount',
        rowKey: 'dueAmount',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'paidAmount',
        default: 'paid amount',
        rowKey: 'paidAmount',
        width: '5%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'reference',
        default: 'reference',
        rowKey: 'reference',
        width: '7%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'lastModified By',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'type',
      //   default: 'type',
      //   rowKey: 'type',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'invoiceString',
      //   default: 'invoice',
      //   rowKey: 'invoiceString',
      //   width: '5%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'updatedate',
        default: 'update date',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    // this.searchSettings = [
    //   {
    //     display_field: 'Entity',
    //     value_field: 'entity',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.TEXT,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.STRING,
    //   },
    //   {
    //     display_field: 'Packages Type',
    //     value_field: 'plantype',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.TEXT,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.STRING,
    //   },
    //   {
    //     display_field: 'Status',
    //     value_field: 'status',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.SELECT,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.BOOLEAN,
    //     select_items: [
    //       {
    //         display_field: 'All',
    //         value_field: ['Y', 'N'],
    //       },
    //       {
    //         display_field: 'True',
    //         value_field: 'Y',
    //       },
    //       {
    //         display_field: 'False',
    //         value_field: 'N',
    //       },
    //     ],
    //   },
    // ];
    this.invoiceDetailGridSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      showFilter:false,
      tableId: this.tableKey.INVOICE_DETAIL_REPORT,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:0,
      pageSizeOptions: [10],
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
    };
    if (this.invoiceDetailGridSetting.serverSideSortPagination && this.invoiceDetailGridSetting.perPage) {
      this.setPaginationPerPage(this.invoiceDetailGridSetting.perPage);
    }
  }

  getinvoiceList(gridSetting:any) {
    let uuid = this.inpUuId;
    this.invoiceService.getinvoiceDetailList(uuid).subscribe((res) => {
      this.invoiceDetailList = new Array<invoiceDetail>();  
      this.invoiceDetailList = res.dataList;
      //this.filteredinvoiceDetailDataForGrid = JSON.parse(JSON.stringify(this.invoiceDetailList));
      this.refreshDataSource(this.invoiceDetailList);
      this.totalRecords = res.totalRecords;
      this.invoiceDetailGridSetting.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getinvoiceList(config);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.bulkhistoryDetailDataForGrid.content));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.invoiceDetailGridSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.invoiceDetailGridSetting);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  closeModal() {
    //this.ngbRef.close();
    this.onCancel.emit();
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getinvoiceList(config);
  }
}

