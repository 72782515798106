import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { PurchasesListService } from './purchases.service';
import {AppConfig} from '../../../../../app.config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../../../login/login.service';
import { AclClassConstants } from '../../../../aclClassConstants';
import { AclConstants } from '../../../../aclOperationConstants';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit {

  @ViewChild('downloadInvoice', { static: false }) downloadInvoiceWrapperRef: TemplateRef<any>;
  @ViewChild('downloadCreditNote',{ static: false }) downloadCreditNoteWrapperRef: TemplateRef<any>;
  @ViewChild('amount', { static: false }) amountWrapperRef: TemplateRef<any>;
  @ViewChild('remark', { static: false }) remarkWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('vasBtn', { static: false }) vasWrapperRef: TemplateRef<any>;
  @Input() custId : any;
  @Input() inpUuId:any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  ngbRef:NgbModalRef
  uuId:any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  purchasesNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  configPath;
  baseURL;
  purchasesTableData : any;
  planMapId: string;

  constructor(
    private purchasesService : PurchasesListService,
    appConfig: AppConfig,
    private modalService: NgbModal,
    public loginService: LoginService,
  ) { this.configPath = appConfig.getConfig(); }

  ngOnInit() {
    this.setGridSetting();
    this.getPurchasesData();
    this.baseURL = this.configPath.baseURLApi;
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'invoiceNo') {
        col.customControls = [this.downloadInvoiceWrapperRef];
      }
      if (col.colKey === 'credId') {
        col.customControls = [this.downloadCreditNoteWrapperRef];
      }
      if (col.colKey === 'amount') {
        col.customControls = [this.amountWrapperRef];
      }
      if(col.colKey === 'remark'){
        col.customControls = [this.remarkWrapperRef];
      }
      if(col.colKey === 'actionCol'){
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey === 'vas') {
        col.customControls = [this.vasWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'purchesdate',
        default: 'PURCHASE DATE',
        rowKey: 'purchesdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },    
      {
        colKey: 'planName',
        default: 'Plan Name',
        rowKey: 'planName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'dueAmount',
      //   default: 'due Amount',
      //   rowKey: 'dueAmount',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'paidAmount',
      //   default: 'paid Amount',
      //   rowKey: 'paidAmount',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'amountString',
        default: 'Amount',
        rowKey: 'amountString',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'collectedBy',
      //   default: 'collected By',
      //   rowKey: 'collectedBy',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'service',
      //   default: 'Service',
      //   rowKey: 'service',
      //   width: '10%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      {
        colKey: 'partnerName',
        default: 'Partner Name',
        rowKey: 'partnerName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'purchasedBy',
        default: 'Purchased By',
        rowKey: 'purchasedBy',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'remark',
        default: 'Purchase Remark',
        rowKey: 'remark',
        width: '10%',
        customControls: [this.remarkWrapperRef],
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'transid',
        default: 'transaction ID',
        rowKey: 'transid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'pgname',
        default: 'Payment Gateway Name',
        rowKey: 'pgname',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'invoiceNo',
        default: 'Invoice',
        rowKey: 'invoiceNo',
        width: '10%',
        customControls: [this.downloadInvoiceWrapperRef],
        customValue:(invoiceNo) => {
          return invoiceNo;
        },
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'credId',
        default: 'credit note',
        rowKey: 'credId',
        width: '10%',
        customControls: [this.downloadCreditNoteWrapperRef],
        customValue:(credId) => {
          return credId;
        },
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'activeDate',
        default: 'Activated Date',
        rowKey: 'activeDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'activeByName',
        default: 'Activated By',
        rowKey: 'activeByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'cancelDate',
        default: 'Cancel Date',
        rowKey: 'cancelDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
  
      {
        colKey: 'cancelByName',
        default: 'canceled BY',
        rowKey: 'cancelByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'refundAmount',
        default: 'Refund Amount',
        rowKey: 'refundAmount',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'modifiedBy',
        default: 'Last Modified By',
        rowKey: 'modifiedBy',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'vas',
      //   default: 'Associated VAS DETAILS',
      //   rowKey: 'vas',
      //   width: '10%',
      //   isNumber: false,
      //   customControls: [this.vasWrapperRef],
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
     
   
    ];

    this.purchasesNeoGridSettings = {
      showAdvanceSearch: false,
      showFilter: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PURCHASES_LIST,
      perPage: 5,
      tableHeight:300,
      headerSticky:true,
      verticalScroll:true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getPurchasesData() {
    this.purchasesService.getPurchasesList(this.custId).subscribe(result => {
      this.purchasesTableData = result.dataList;
      ////console.log(this.purchasesTableData)
      this.refreshDataSource(this.purchasesTableData);
    });
  }

  open(paydet, data: any) {
    this.ngbRef =  this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.custId = data.custid;
    this.uuId = data.uuid
    //this.planMapId = data.planmapid;
  }

  onClose(){
    this.ngbRef.close();
  }
}
