export class ValueAddedeServiceModel {
    id: number
    name: string;
    type: string;
    value: string;
    status: string;
    deleteValueAddedService: any;
    getDataValueAddedService: any;
    isBase: boolean = true;
    baseId:number;
    commonListImageIds:string;
    // angular use
    commonListImageNames:string;
    isIncludeTax :boolean = false;
    isCustomerAllowed  :boolean = false;
    offerPrice:number;
    validity:any;
    partnerPrice:number;
    taxId:number;
    offerpriceintax: number;
    taxAmount: number;
    partnerPriceInTax:number;
    partnerTaxPrice:number;
    description:any;
}