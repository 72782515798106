import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomModalService } from '../../../../../app/components/custom-modal/custom-modal.service';
import { BasicDetailsService } from '../basic-details/basic-details.service';
import * as alertify from 'alertifyjs';
import { SubscriberDetailsComponent } from '../subscriber-details.component';

@Component({
  selector: 'app-change-representative',
  templateUrl: './change-representative.component.html',
  styleUrls: ['./change-representative.component.scss']
})
export class ChangeRepresentativeComponent implements OnInit {

  @ViewChild(SubscriberDetailsComponent, {static: false})
  private subscriberComponent: SubscriberDetailsComponent;
  
  staffList:any;
  submitted:any = null;
  submitedFlag:boolean=false;
  // @Input() partnername: string;
  // @Input() service: string;
  @Input() salesrepNameChild: string;
  @Input() salesrepId:number;
  @Input() custId: any;

  changeRepresentativeForm = new FormGroup({
    staffUserId: new FormControl(null),
    subscriberId: new FormControl(''),
    salesrapname: new FormControl(''),
    remark:new FormControl('',[Validators.required]),
    newSalesRemark: new FormControl('',[Validators.required,Validators.maxLength(150)]),
  });
  salesrapname: any;
 

  constructor(
    private basicDetailsservice:BasicDetailsService,
    private customModalService: CustomModalService,
    private toastr:ToastrService
  ) { }
  
  ngOnInit() {

  this.LoadFormControl();
  if(this.custId){
    this.getSubscriberSalesRepList(this.custId);
  }
  
  
}
  
  LoadFormControl(){
    this.changeRepresentativeForm.get('salesrapname').setValue(this.salesrepNameChild)
    this.changeRepresentativeForm.get('salesrapname').disable();
    this.changeRepresentativeForm.get('subscriberId').setValue(this.custId);
  
  }
  
  onClickSaveChangeRepresentativeForm(){
    
    if(this.changeRepresentativeForm.invalid){
      this.submitedFlag=true;
    } else {
      this.submitedFlag = false;
    }

    if(this.changeRepresentativeForm.valid && this.submitedFlag!==true){
      //this.changeRepresentativeForm.get('staffUserId').setValue(this.staffuserId);
      let formValue=this.changeRepresentativeForm.value;
      //this.insertBasicDetailsOnDb(formValue);
      if (this.changeRepresentativeForm.valid === true) {
      let self = this;
      alertify.confirm("Change Representative","Are You Sure You want to Change Current Representative?",
      function() {
        self.insertBasicDetailsOnDb(formValue);
        // this.closeChangePartnerPopup
      },
      function() {
  
      });
    } 
  }
    
  }

  insertBasicDetailsOnDb(formValue){
    this.basicDetailsservice.updateNewsalesrap(formValue).subscribe((result)=>{
      if(result.responseCode===200){
      this.toastr.success("Sales Representative Changed Successfully");
      this.closeModal('change-representative');
      // this.subscriberComponent.getSubscriberBasicDetails();
      this.customModalService.close('change-representative',result);
      }else{
      this.toastr.error("Some thing Went Wrong");
      }
    })
  }

  closeModal(id:any){
     this.customModalService.close(id,{close_from:"close_button"});
     this.submitted = false;
  }

  getSubscriberSalesRepList(id){
   this.basicDetailsservice.getsalesrapList(id).subscribe((result)=>{
     this.staffList=result.data.staffList;
     this.salesrapname = result.data.staffUserName;
     this.changeRepresentativeForm.get('salesrapname').setValue(result.data.staffUserName);
     this.changeRepresentativeForm.get('newSalesRemark').setValue(result.data.oldSalesRemark);
    })
  }

}
