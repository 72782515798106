import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class BasicDetailsService {
  constructor(private http: HttpClient) {}

  getBasicDetails(custId : any): Observable<any> {
    
    const get_url = baseApiUrl + 'subscriber/getBasicDetails/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertBasicDetails(basicDeatils_data : any) : Observable<any> {
      const post_url = baseApiUrl + 'subscriber/updateBasicDetails';
      return this.http.post<any>(post_url, basicDeatils_data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }

    getNewPartnerList(subscriber_id): Observable<any> {
      
      const get_url = baseApiUrl + 'subscriber/getSubscriberPartnerDetails/'+subscriber_id;
      return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }

    getsalesrapList(subscriber_id): Observable<any> {
      
      const get_url = baseApiUrl + 'subscriber/getSalesRepForSubscriberPartner/'+subscriber_id;
      return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }

    updateNewPartner(value : any) : Observable<any> {
      const post_url = baseApiUrl + 'subscriber/updateSubcriberPartner';
      return this.http.post<any>(post_url, value, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }

    getNewCollectorList(subscriber_id): Observable<any> {
      const get_url = baseApiUrl + 'subscriber/getSubscriberCollectorDetails/'+subscriber_id;
      return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }

    updateNewCollector(value : any) : Observable<any> {
      const post_url = baseApiUrl + 'subscriber/updateSubscriberCollector';
      return this.http.post<any>(post_url, value, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }
    
    customerSuspensionExtend(value : any) : Observable<any> {
      const post_url = baseApiUrl + 'dunningRule/saveExtends';
      return this.http.post<any>(post_url, value, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }

    updateNewsalesrap(value : any) : Observable<any> {
      const post_url = baseApiUrl + 'subscriber/changeSalesRepresentative';
      return this.http.post<any>(post_url, value, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }


    checkUniqueCustomerByCafno(value,subscriber_id): Observable<any> {
      const get_url = baseApiUrl + 'subscriber/checkUniqueCustomer';
      let data:any = {
        "type": "cafno",
        "value": value,
        "subscriberId": subscriber_id
      };
      return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }
    
  
}