import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryService } from '../country/country.service';
import { StateService } from '../state/state.service';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../core/constant/response-code';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
@Component({
  selector: 'app-add-state',
  templateUrl: './add-state.component.html',
  styleUrls: ['./add-state.component.scss']
})
export class AddStateComponent implements OnInit {
  saveBtn:boolean=false;
  isDisabled:boolean=false;
  stateDataForGrid: any = { content: [] };
  stateGridSettings: any = {};
  public sub: any = null;
  public state_id: any = null;
  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  status = [
    { value: 'Active', name: "Active" },
    { value: 'InActive', name: "InActive" }
  ]
  selectForUpdate: any;
  show_update: any = false;
  req_message = "This field is required.";
  insertStateForm = new FormGroup({
    country_id: new FormControl('', [Validators.required]),
    state_name: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
  });

  constructor(
    private stateService: StateService,
    private countryService: CountryService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private tosterservice : ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddStateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,

  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.state_id = id;
      this.show_update = true;
      this.getStateData();
    }

    // if(this.data && this.data.id){
    //   this.isDisabled=true;
    //   this.state_id = this.data.id;
    //   this.show_update = false;
    //   this.getStateData();
    // }

    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.state_id = params['id'];
    //       this.show_update = false;
    //       this.getStateData();
    //     }
    //   });
    this.countryService.getCountryList().subscribe((result) => {
      this.countryList = result.content;
    });
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  validateForm() {
    if (!this.insertStateForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertStateForm.controls).forEach(field => {
        const control = this.insertStateForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.state_id === null) {
        this.insertStateOnDb();
      } else {
        this.updateStateOnDb();
      }
    }
  }

  getStateData() {
    let state_id = this.state_id;
    this.stateService.getStateListById(state_id).subscribe(
      result => {
        // this.setUpdateValueInForm(_.find(result.content, { state_id: parseInt(state_id) }));
        this.setUpdateValueInForm(result);
      });
  }

  insertStateOnDb() {
    if (this.insertStateForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertStateForm.value);
      let formatedInputData: any = { countryPojo: {} };
      formatedInputData.countryPojo.id = apiInputData.country_id;
      formatedInputData.name = apiInputData.state_name;
      formatedInputData.status = apiInputData.status;
      this.stateService.insertState(formatedInputData).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status === ResponseCode.OK){
            let data: any = {};
            let stateData: any = result.state;
            data.state_id = stateData.id;
            data.state_name = stateData.name;
            data.status = stateData.status;
            data.country_id = stateData.countryPojo.id;
            data.country_name = _.find(this.countryList, { country_id: data.country_id }).country_name;
            this.cdRef.detectChanges();
          
            this.tosterservice.success('State has been created successfully');
            this.backToViewState();
          }
       
        }, err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  updateStateOnDb() {
    if (this.insertStateForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertStateForm.value);
      let state_id = this.selectForUpdate.state_id;
      let formatedInputData: any = { countryPojo: {} };
      formatedInputData.countryPojo.id = apiInputData.country_id;
      formatedInputData.name = apiInputData.state_name;
      formatedInputData.status = apiInputData.status;
      this.stateService.updateState(formatedInputData, state_id).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status === ResponseCode.OK){
            this.stateDataForGrid.content.forEach((item, index) => {
              if (item.state_id === state_id) {
                this.stateDataForGrid.content[index].state_name = formatedInputData.name;
                this.stateDataForGrid.content[index].status = formatedInputData.status;
                let country_id = formatedInputData.countryPojo.id;
                this.stateDataForGrid.content[index].country_id = country_id;
                this.stateDataForGrid.content[index].country_name = _.find(this.countryList, {
                  country_id: country_id,
                }).country_name;
              }
            });
            this.cdRef.detectChanges();
            this.show_update = false;
            this.tosterservice.success('State Update successfully');
            this.backToViewState();
          }
       
        }, err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  setUpdateValueInForm(stateData: any) {
    this.show_update = true;
    this.selectForUpdate = stateData;
    let stateFormData: any = {
      state_name: stateData.state_name,
      status: stateData.status,
      country_id: stateData.country_id,
    };
    this.insertStateForm.patchValue(stateFormData);
  }

  backToViewState() {
    // this.router.navigate(['/masters/state']);/**Old code */

    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
      this.router.navigate(['/masters/state']);
      }

  }

  closeDialog(){
    this.dialogRef.close();
  }

}
