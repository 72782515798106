import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { IppoolConfigService } from './ippool-config.service';
import _ from 'underscore';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../../services/search.service';
import { SubscriberService } from '../../subscriber/subscriber.service';
import { AppConfig } from '../../../../app.config';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../../pages/login/login.service';
import { AclClassConstants } from '../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../pages/aclOperationConstants';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';

@Component({
  selector: 'app-ippool-config',
  templateUrl: './ippool-config.component.html',
  styleUrls: ['./ippool-config.component.scss']
})
export class IppoolConfigComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnReff: TemplateRef<any>;
  @Input() franchiesid: any;
  //@Output() docId: any = new EventEmitter();
  ippoolDataForGrid: any = [];
  iopoolGridSettings: any = {};
  AclConstants = AclConstants;
  AclClassConstants = AclClassConstants;
  show_update: any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  subscriptions: Subscription[] = [];
  openOtherDetails: boolean;
  gridConfig: any;

  constructor(
    private IppoolConfigService: IppoolConfigService,
    private searchService: SearchService,
    private subscriberService: SubscriberService,
    private tosterservice: ToastrService,
    private loginService: LoginService,
    appConfig: AppConfig
  ) {
    super(searchService)
  }

  ngOnInit() {
    
    this.setDefaultPageSize(this.tableKey.IP_POOL_CONFIGURATION)
    this.setGridSetting();
    this.getListWithParams();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }


  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
    
      if (col.colKey === 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }

    });
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    ////console.log("data ==> ",config);
    this.getIpPoolByPartner(config);
  }
 
  getIpPoolByPartner(gridSetting: any) {

    let fetchSettingData = this.iopoolGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    params['id'] =this.franchiesid
    
    ////console.log(params)
          this.IppoolConfigService.getippoolById(params).subscribe(result => {
        if (result.responseCode === 200) {
          
            this.refreshDataSource(result.dataList);
            this.totalRecords = result.totalRecords ? result.totalRecords : 0;
            this.iopoolGridSettings.pageTotalLength = this.totalRecords;
        } else {
          this.tosterservice.error(result.responseMessage);
        }
      })
  
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '2%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'displayName',
        default: 'Pool Name',
        rowKey: 'displayName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ipRange',
        default: 'Range',
        rowKey: 'ipRange',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'actionCol',
      
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];

    this.iopoolGridSettings = {
      tableId: this.tableKey.IP_POOL_CONFIGURATION,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      showFilter: false,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
    
    };
    if (this.iopoolGridSettings.serverSideSortPagination && this.iopoolGridSettings.perPage) {
      this.setPaginationPerPage(this.iopoolGridSettings.perPage);
    }
  }

 

  // documentVerification(data) {
  //   let docData: any = {
  //     custId: data.custId,
  //     docId: data.docId,
  //     docType: data.docType,
  //     docSubType: data.docSubType,
  //     docStatus: 'verified',
  //     remark: data.remark,
  //     filename: data.filename,
  //     uniquename: data.uniquename
  //   };
  //   this.IppoolConfigService.updateCustomerDocument(docData).subscribe(res => {
  //     if (res.responseCode !== 200 && res.responseMessage) {
  //       this.tosterservice.error(res.responseMessage);
  //     } else {
  //       this.tosterservice.success("Verify Successfully");
  //       this.ngOnInit();
  //     }
  //   })
  // }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.ippoolDataForGrid));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.ippoolDataForGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  onEdit(event) {
    //this.docId.emit(event.RowData.docId);
  }

  
  delete(delete_obj) {

    this.IppoolConfigService.deleteIPpoolByPartner(delete_obj).subscribe((result) => {
    
      if(result.responseCode !== 200){
        this.tosterservice.error("IP Pool is already in Use !")
      }
      else{
        this.tosterservice.success("IP Pool Deleted Successfully")
      }
    });
    this.getListWithParams()

  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getIpPoolByPartner(config);
  }
}
