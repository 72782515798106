import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomModalService } from '../../../../components/custom-modal';

import { PlanServices } from '../../../../services/plan_service/plan_service.service';

import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { LocationDetailsService } from './location-details.service';
import { ToastrService } from 'ngx-toastr';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
import { latitudeValidator, longitudeValidator } from '../../add-subscriber-details/other-details/other-details.component';
import { OpticalPowerList } from '../../model/OpticalPowerList';
@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit {
  @Input() custId: any;
  submitted:any = null;
  taxDetailsByPlanData:any;
  insertLocationDetailsForm = new FormGroup ({
    latitude : new FormControl(''),
    longitude : new FormControl(''),
    url : new FormControl(''),
    gis_code : new FormControl(''),
    feasibilityDistance:new FormControl(''),
    dataUnit:new FormControl(''),
    opticalPower:new FormControl('')
  });
  concateValue:any;
  chargeAmount: any = "";
  taxAmount: any = "";
  finalAmount: any = "";

  getOpticalpowerlist:Array<OpticalPowerList>;
  opticalPower: { isMandatory: boolean; displayname:boolean }
  locationDetailsData : any;
  feasibilityDistanceObj: any;
  bridgeTypeObj:any;
  urlObj:any;
  gisObj:any;
  latituteObj:any;
  longituteObj:any;
  dynamicrequiredArray: any;
  req_message = "This field is required.";
  feasibilityDistanArray:any[]=[
    {
      id:1,text:'Km',value:"Km"
    },
    {
      id:2,text:'Mtr',value:"Mtr"
    }
  ];
  selectedValue:any;
  fdUnit:any;
  constructor(
    private customModalService: CustomModalService,
    private locationDetailsService : LocationDetailsService,
    private planService: PlanServices,
    private tosterservice : ToastrService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,

  ) {
    this.getRequiredAllField();
   }

  ngOnInit() {
    this.getSubscriberCharges();
    this.getOpticalpowerList();
  }

  getOpticalpowerList(){
    this.addSubscriberDetailsService.getOpticalpowerList().subscribe((res)=>{
      this.getOpticalpowerlist = res.dataList;
    })
  }

  getSubscriberCharges() {
    
    this.locationDetailsService.getLocationDetails(this.custId).subscribe(
      result => {
        this.locationDetailsData = result.data;
        
        if(this.locationDetailsData.feasibilityDistance){
          var fetchValue = this.locationDetailsData.feasibilityDistance;
          fetchValue = fetchValue.substring(0, fetchValue.length-3);
         this.fdUnit=this.locationDetailsData.feasibilityDistance.slice(-3);
         this.locationDetailsData.feasibilityDistance=fetchValue.trim();
          this.insertLocationDetailsForm.patchValue(this.locationDetailsData);
          if(fetchValue && this.fdUnit===' Km'){
          this.selectedValue=1;
         //working this.insertLocationDetailsForm.get('feasibilityDistance').setValue(Number(this.locationDetailsData.feasibilityDistance));
          this.insertLocationDetailsForm.get('dataUnit').setValue(this.selectedValue);
          }
         if(fetchValue && this.fdUnit === 'Mtr'){
            this.selectedValue=2;
            this.insertLocationDetailsForm.get('dataUnit').setValue(this.selectedValue);
          }  
         
        //  this.locationDetailsData.feasibilityDistance=fetchValue;
        }else{
          this.insertLocationDetailsForm.patchValue(this.locationDetailsData);
        }
        // this.insertLocationDetailsForm.patchValue(this.locationDetailsData);
        
      }
    )
  }

  onClickSaveApplyChargeForm() {
  
    if (this.insertLocationDetailsForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Location Details","Are You Sure You want to Update Location Details ?",
      function() {
        self.saveApiCall();
      },
      function() {

      });
    } else {
      this.submitted = true;
      this.insertLocationDetailsForm.markAllAsTouched();
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertLocationDetailsForm.value);
    let formatedInputData:any = apiInputData;
    formatedInputData.custId = this.custId;
    
    if(formatedInputData.feasibilityDistance && this.concateValue){
      formatedInputData.feasibilityDistance= this.concateValue;
    }else{
      if(formatedInputData.feasibilityDistance && formatedInputData.feasibilityDistance !==""){
        formatedInputData.feasibilityDistance=formatedInputData.feasibilityDistance+' '+this.fdUnit
      }
    }
     delete formatedInputData.dataUnit;
    this.locationDetailsService.updateLocationDetails(formatedInputData).subscribe(
      (result) => {
        
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
         this.tosterservice.success('Location Details Update Successfully.');
         this.customModalService.close('location-datails',result);
        }
      },err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
    this.submitted = false;
  }

  getRequiredAllField() {
    this.addSubscriberDetailsService.getRequiredAllField().subscribe(result => {

      if (result.responseCode === 200) {
        
        this.dynamicrequiredArray = result.data;
        this.feasibilityDistanceObj = this.dynamicrequiredArray.find(e => e.fieldname === 'feasibilityDistance');
        // this.bridgeTypeObj = this.dynamicrequiredArray.find(e => e.fieldname === 'bridgeType');
        this.urlObj = this.dynamicrequiredArray.find(e => e.fieldname === 'url');
        this.gisObj = this.dynamicrequiredArray.find(e => e.fieldname === 'gis_code');
        this.latituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'latitude');
        this.longituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'longitude');
        this.opticalPower = this.dynamicrequiredArray.find(e => e.fieldname === 'opticalPower');
        


        if ((this.feasibilityDistanceObj !== null && this.feasibilityDistanceObj !== undefined) && this.feasibilityDistanceObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('feasibilityDistance').setValidators([Validators.required,Validators.maxLength(5)])
          this.insertLocationDetailsForm.get('feasibilityDistance').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('feasibilityDistance').clearValidators()
          this.insertLocationDetailsForm.get('feasibilityDistance').updateValueAndValidity();
        }
        if ((this.feasibilityDistanceObj !== null && this.feasibilityDistanceObj !== undefined) && this.feasibilityDistanceObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('dataUnit').setValidators([Validators.required])
          this.insertLocationDetailsForm.get('dataUnit').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('dataUnit').clearValidators()
          this.insertLocationDetailsForm.get('dataUnit').updateValueAndValidity();
        }

        


        if ((this.urlObj !== null && this.urlObj !== undefined) && this.urlObj.isMandatory === true) {
          // this.insertLocationDetailsForm.get('url').setValidators([Validators.required,Validators.maxLength(50)]);
          this.insertLocationDetailsForm.get('url').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(50)])
          this.insertLocationDetailsForm.get('url').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('url').clearValidators()
          this.insertLocationDetailsForm.get('url').updateValueAndValidity();
        }


        if ((this.gisObj !== null && this.gisObj !== undefined) && this.gisObj.isMandatory === true) {
          // this.insertLocationDetailsForm.get('gis_code').setValidators([Validators.required,Validators.maxLength(25)])
          this.insertLocationDetailsForm.get('gis_code').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(25)])
          this.insertLocationDetailsForm.get('gis_code').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('gis_code').clearValidators()
          this.insertLocationDetailsForm.get('gis_code').updateValueAndValidity();
        }

        if ((this.latituteObj !== null && this.latituteObj !== undefined) && this.latituteObj.isMandatory === true) {
          //this.insertLocationDetailsForm.get('latitude').setValidators([Validators.required,Validators.maxLength(15)])
          this.insertLocationDetailsForm.get('latitude').setValidators([Validators.required,latitudeValidator()])
          this.insertLocationDetailsForm.get('latitude').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('latitude').clearValidators()
          this.insertLocationDetailsForm.get('latitude').updateValueAndValidity();
        }

        if ((this.longituteObj !== null && this.longituteObj !== undefined) && this.longituteObj.isMandatory === true) {
          //this.insertLocationDetailsForm.get('longitude').setValidators(Validators.required)
          this.insertLocationDetailsForm.get('longitude').setValidators([Validators.required,longitudeValidator()]);
          this.insertLocationDetailsForm.get('longitude').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('longitude').clearValidators()
          this.insertLocationDetailsForm.get('longitude').updateValueAndValidity();
        }

        if ((this.opticalPower !== null && this.opticalPower !== undefined) && this.opticalPower.isMandatory === true) {
          this.insertLocationDetailsForm.get('opticalPower').setValidators([Validators.required])
          this.insertLocationDetailsForm.get('opticalPower').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('opticalPower').clearValidators()
          this.insertLocationDetailsForm.get('opticalPower').updateValueAndValidity();
        }
      
      }
    })
    
  }
  //Number only Changes
  numberOnly(event): boolean {
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31  && (charCode < 45 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onChangedistanceUnit(v){
    let val=this.insertLocationDetailsForm.get('feasibilityDistance').value;
    if(v){
      let data=v.value      
      this.concateValue=val+' '+v.value
    }
    else{
      this.concateValue=val
    }
   
    console.log("test",this.concateValue)    
    
  }
}
