import { Injectable } from '@angular/core';
import {jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import {stringify} from "querystring";

@Injectable({
  providedIn: 'root'
})
export class FileUtilService {

  constructor() { }

  public getCloumnAndHeader(object: Object) {
       return new Promise(resolve => {
        const map = new Map(Object.entries(object));
        ////console.log(object);

        const header =  Object.keys(object);
        ////console.log(header);

        const pdfObject = [];
        header.forEach(ele => {
              pdfObject.push({
                header : map.get(ele).title,
                dataKey : ele
              });
              if (pdfObject.length === header.length) {
                ////console.log(pdfObject);
                resolve(pdfObject);
              }
         });
       });


  }

  public getCsvObject(object: Object) {
    return new Promise (resovle => {
      const map = new Map(Object.entries(object));
      const header =  Object.keys(object);
      const csvObject = {};
      for (let i = 0; i < header.length; i++) {
      csvObject[header[i]] =  map.get(header[i]).title;
      if (i === (header.length - 1)) {
           resovle(csvObject);
         }
     }
    });
  }

  public convertIntoPdf(setting, dataSource, moduleName,headerObject ?: {},reportTitle?: string,) {
      const funKeyMap = new Map<string, Function>();
      const dataObj: [] = Object.assign([], dataSource);

      if (headerObject != undefined) {

          const colKeys: string[] = Object.keys(headerObject);
          colKeys.forEach(
              (key) => {
                  if (headerObject[key].valuePrepareFunction) {
                      funKeyMap.set(key, headerObject[key].valuePrepareFunction);
                  }
              }
          );
          dataObj.forEach(
              (data, index) => {
                  const datakeys: string[] = Object.keys(data);

                  datakeys.forEach(
                      (datakey) => {
                          if (funKeyMap.has(datakey)) {
                              const valueFun = funKeyMap.get(datakey);
                              dataSource[index][datakey] = valueFun(data[datakey]);
                          }
                      }
                  );
              }
          );

      }


      const date = new Date().toLocaleDateString('en-US');
      const doc = new jsPDF('p', 'pt');
      if(reportTitle){
          doc.text(reportTitle,40,30);
      }
      autoTable(doc, {
        styles: {
            overflow: 'linebreak',
            fontSize: 8,
        },
        columnStyles: { 2: {  cellWidth: 'auto' } },
        body: dataSource,
        columns : setting
    });
      doc.save(moduleName + '_' + date);
  }

    convertToCSV(objArray) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    ////console.log(array);
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[0]) {
            if (line != '') { line += ','; }

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

    exportCSVFile(headers, items, fileTitle, headerObject ?: {}) {
        const dataObj: [] = Object.assign([], items);

        const funKeyMap = new Map<string, Function>();

        if (headerObject != undefined) {

            const colKeys: string[] = Object.keys(headerObject);
            colKeys.forEach(
                (key) => {
                    if (headerObject[key].valuePrepareFunction) {
                        funKeyMap.set(key, headerObject[key].valuePrepareFunction);
                    }
                }
            );
            dataObj.forEach(
                (data, index) => {
                    const datakeys: string[] = Object.keys(data);

                    datakeys.forEach(
                        (datakey) => {
                            if (funKeyMap.has(datakey)) {
                                const valueFun = funKeyMap.get(datakey);
                                items[index][datakey] = valueFun(data[datakey]);
                            }
                            items[index][datakey] = ("" +  items[index][datakey]).replace(/,/gi,"");
                        }
                    );
                }
            );

        }

        if (headers) {
            items.unshift(headers);
        }

        const jsonObject = JSON.stringify(items);

        const csv = this.convertToCSV(jsonObject);

        const date = new Date().toLocaleDateString('en-US');
        const exportedFilenmae = fileTitle + date + '.csv' || 'export.csv';
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            return url;
        }
    }
}



