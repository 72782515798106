import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomModalService } from '../../../../components/custom-modal';

import { ApplyChargeService } from './apply-charge.service';

import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { DatePipe } from '@angular/common';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RecordPaymentService } from '../record-payment/record-payment.service';
import { ChangePlanService } from '../change-plan/change-plan.service';
@Component({
  selector: 'app-apply-charge',
  templateUrl: './apply-charge.component.html',
  styleUrls: ['./apply-charge.component.scss']
})
export class ApplyChargeComponent implements OnInit {
  @Input() custId: any;
  @Input() bal: any;
  submitted: any = null;
  taxDetailsByPlanData: any;
  paymentModeList: any[] = [];
  paymentMode: any = null;
  refrenceNo:any=null;
  selected_payment_mode_value: any;
  selected_tds_deducted: any;
  paymentModeValid: boolean;
  isPriceOverrideHideshow:boolean=false;
  tdsPendingPaymentsList: any[] = [];
  isIncludeTaxflag:boolean=false;
  chargeOldAmount:any;
  oldtAmount:any;
  hideShowField:boolean=false;
  oldChargeAmount:any;
  oldTaxAmount:any;
  collectorFlag:boolean = true;
  saveBtnDisable:boolean=false;
  insertApplyChargeForm = new FormGroup({
    charge_id: new FormControl(null, [Validators.required]),
    chargeAmount: new FormControl(''),//req ,[Validators.required]
    taxAmount: new FormControl(''),
    finalAmount: new FormControl(''),
    chargeDate: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required]),
    isPaymentReceived: new FormControl('no', [Validators.required]),
    overridePrice:new FormControl(null),
    isOverride :new FormControl(false),
    isTaxInclude:new FormControl(false),
    //collectorId: new FormControl(null),
    isBalanceUsed: new FormControl(false),
    balanceAmount: new FormControl(null),
    // isIncludeTax
    
    recordPaymentDTO: new FormGroup({
      amount: new FormControl(''),
      payment_date: new FormControl(''),
      payment_mode: new FormControl(''),
      referenceNo: new FormControl(''),
      chequeNo: new FormControl(''),
      bankName: new FormControl(''),
      chequeDate: new FormControl(''),
      branch: new FormControl(''),
      tdsDeducted: new FormControl(false),
      tdsAmount: new FormControl(''),
      remarks: new FormControl(''),
      credit_doc_id: new FormControl(''),

     
    })
  })

  chargeAmount: any = "";
  taxAmount: any = "";
  finalAmount: any = "";
  maxamount:any=""
  minamount:any=""
  isOverhead: boolean = false;
  selectedtaxid:any;
  chargeList: any[] = [
    {
      name: '',
      id: '',
    }
  ]
  collectorList: []
  isPriceOverrideCharge:boolean=false;
  isPriceOverrideHideshow2:boolean=false;
  constructor(
    private customModalService: CustomModalService,
    private applyChargeService: ApplyChargeService,
    private planService: PlanServices,
    private tosterservice: ToastrService,
    private datepipe: DatePipe,
    private recordPaymentService: RecordPaymentService,
    private changePlanService:ChangePlanService,
    private toaster:ToastrService
  ) { }

  ngOnInit() {
    this.getStartDateEndDate();
    this.getSubscriberCharges();
    this.getPaymentModeListFromGenericApi();
    this.getPaymentCollectorList(this.custId);
    this.insertApplyChargeForm.get('balanceAmount').setValue(this.bal)
  }

  getPaymentCollectorList(custid){
    this.applyChargeService.getPaymentCollectorList(custid).subscribe(
      result => {
        this.collectorList = result.dataList;
      }
    )
  }

  oncheckUseBalance(event){
    let oldfinalpay = this.chargeAmount + this.taxAmount;
    let amount = this.insertApplyChargeForm.get('recordPaymentDTO').get('amount').value;
    if(event && event.target.checked == true){
      if(this.bal >= oldfinalpay && amount >= oldfinalpay){
        this.collectorFlag = false;
        this.finalAmount = 0;
        this.insertApplyChargeForm.get('balanceAmount').setValue(oldfinalpay);
      }else{
        this.collectorFlag = true
        this.finalAmount = oldfinalpay - this.bal;
        this.insertApplyChargeForm.get('balanceAmount').setValue(this.bal);
      }
    }else{
      this.collectorFlag = true
      this.finalAmount = oldfinalpay
      this.insertApplyChargeForm.get('balanceAmount').reset();
    }
  }

  checkamount(){
    let oldfinalpay = this.chargeAmount + this.taxAmount;
    let amount = this.insertApplyChargeForm.get('recordPaymentDTO').get('amount').value;
    if(this.bal >= amount && this.insertApplyChargeForm.get('isBalanceUsed').value == true){
      if(amount == oldfinalpay || amount == 0){
        this.collectorFlag = false;
        this.insertApplyChargeForm.get('balanceAmount').setValue(this.bal);
        //this.insertApplyChargeForm.get('collectorId').reset();
      }else{
          this.collectorFlag = true;
          this.insertApplyChargeForm.get('balanceAmount').setValue(oldfinalpay);
      }
    }else{
        this.collectorFlag = true;
      }
  }

  getSubscriberCharges() {
    this.applyChargeService.getSubscriberCharges().subscribe(
      result => {
        this.chargeList = result.dataList;
      }
    )
  }
  getPaymentModeListFromGenericApi() {
    this.applyChargeService.getPaymentModeListFromGenericApi().subscribe(result => {
      this.paymentModeList = result.dataList;
    });
  }
  onChangeTdsDeducted(el) {
    let is_tds_deducted = el;
    this.selected_tds_deducted = is_tds_deducted;
  }
  getStartDateEndDate() {
    let newdate = new Date();
    var currentDate = this.datepipe.transform(newdate, 'yyyy-MM-dd');
    this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_date').setValue(currentDate);
  }
  onChangePaymentMode(el) {
    if (el) {
      let payment_mode_value = el.value;
      this.selected_payment_mode_value = payment_mode_value;
      if (this.selected_payment_mode_value === 'TDS') {
        this.recordPaymentService.getTdsPendingPayments(this.custId).subscribe(result => {
          this.tdsPendingPaymentsList = result.dataList;
        });
      }
    } else {
      this.selected_payment_mode_value = '';
    }
  }

  onChargeChange($event) {
    this.insertApplyChargeForm.get('isPaymentReceived').setValue('no');
    this.insertApplyChargeForm.get('balanceAmount').reset();
    if ($event) {
      let findChargeById = _.find(this.chargeList, { id: $event.id });
      this.chargeAmount = findChargeById.price;
      this.oldChargeAmount=findChargeById.price;
      this.chargeOldAmount=findChargeById.price;
      this.selectedtaxid =findChargeById.taxid;
      let data: any = {
        "chargeId": $event.id,
        "custId": parseInt(this.custId)
      };
      this.taxAmount = findChargeById.taxamount;
      this.oldTaxAmount=findChargeById.taxamount;
      this.oldtAmount=findChargeById.taxamount;
      if (findChargeById.taxamount === null) {
        this.taxAmount = 0;
      }
      this.finalAmount = this.chargeAmount + findChargeById.taxamount;
      this.maxamount=findChargeById.maxamount;
      this.minamount=findChargeById.minamount;
      // this.isPriceOverrideCharge=findChargeById.isPriceOverride;
      
      if($event.isPriceOverride===true){
        // this.isOverhead=true;
        this.isPriceOverrideHideshow2=true;
        this.hideShowField=true;
        this.isOverhead=true;
        this.isPriceOverrideHideshow=true;
        this.insertApplyChargeForm.get('isOverride').setValue(true);
        this.insertApplyChargeForm.get('overridePrice').setValidators([Validators.required])
      }else{
       // this.isOverhead=false;
       this.isPriceOverrideHideshow2=false;
        this.hideShowField=false;
        this.isOverhead=false;
        this.isPriceOverrideHideshow=false;
        this.insertApplyChargeForm.get('isOverride').setValue(false);
        this.insertApplyChargeForm.get('overridePrice').clearValidators();
        this.insertApplyChargeForm.get('overridePrice').updateValueAndValidity();
      }

      if($event.isIncludeTax===true){
        this.isIncludeTaxflag=true;
        this.insertApplyChargeForm.get('isTaxInclude').setValue(true);
      }else{
        this.isIncludeTaxflag=false;
        this.insertApplyChargeForm.get('isTaxInclude').setValue(false);
      }

      console.log("v-->",this.insertApplyChargeForm.value)
      // this.planService.getTaxDetailsByCharge(data).subscribe((result) => {
      //   this.taxDetailsByPlanData = result;
      //   ////console.log("tax",result)
      //   ////console.log(this.taxDetailsByPlanData)
      //   if (this.taxDetailsByPlanData && this.taxDetailsByPlanData.TotalAmount>= 0) {
      //     this.taxAmount = parseFloat(this.taxDetailsByPlanData.TotalAmount.toFixed(2));
      //     this.finalAmount = parseFloat(this.chargeAmount) + parseFloat(this.taxDetailsByPlanData.TotalAmount.toFixed(2));
      //   } else {

      //     this.taxAmount = null;
      //     this.finalAmount = null;
      //   }
      // });

    } else {
      this.chargeAmount = "";
      this.taxAmount = "";
      this.finalAmount = "";
      this.taxDetailsByPlanData = null;
    }
  }
  onPaymentTypeChange(event) {
    //this.insertApplyChargeForm.get('isBalanceUsed').reset();
    if (event === 'YES') {
      this.insertApplyChargeForm.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
      this.insertApplyChargeForm.get('recordPaymentDTO').get('amount').updateValueAndValidity();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_date').setValidators(Validators.required);
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_mode').setValidators(Validators.required);
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('referenceNo').setValidators(Validators.required);
      this.insertApplyChargeForm.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
    } else {
      this.insertApplyChargeForm.get('recordPaymentDTO').get('amount').clearValidators();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('amount').updateValueAndValidity();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_date').clearValidators();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_mode').clearValidators();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('referenceNo').clearValidators();
      this.insertApplyChargeForm.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
    }

  }
  checkOverHead(event: any) {
    if (event === 'true') {
       this.isOverhead=true;
       this.isPriceOverrideHideshow2=true;
       this.insertApplyChargeForm.get('isOverride').setValue(true);
       this.insertApplyChargeForm.get('overridePrice').setValidators([Validators.required]);
       } else { 
      
       this.isOverhead=true;
       this.isPriceOverrideHideshow2=false;
       this.insertApplyChargeForm.get('isOverride').setValue(false);
       this.chargeAmount=this.oldChargeAmount;
       this.taxAmount  =this.oldTaxAmount;
       this.finalAmount= this.chargeAmount+ this.taxAmount;
       this.insertApplyChargeForm.get('overridePrice').reset();
       this.insertApplyChargeForm.get('overridePrice').clearValidators();
       this.insertApplyChargeForm.get('overridePrice').updateValueAndValidity();
      }
}

  onClickSaveApplyChargeForm() {
    if (this.insertApplyChargeForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Apply Charge", "Are You Sure You want to Update Apply Charge Details ?",
        function () {
          self.saveApiCall();
        },
        function () {
         this.saveBtnDisable=false;
        });
    } else {
      this.submitted = true;
      this.insertApplyChargeForm.markAllAsTouched();
    }
  }

  transformApiIinputData(data) {
    let apiInputData = data;
    let formatedData: any = {};
    let recordPaymentDTO: any = {};
    let chargeById = _.find(this.chargeList, { id: apiInputData.charge_id });
    formatedData.custId = this.custId;
    formatedData.charge_id = apiInputData.charge_id;
    formatedData.charge_name = chargeById.name;
    formatedData.custId = this.custId;
    formatedData.charge_date = this.datepipe.transform(apiInputData.chargeDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
    formatedData.remarks = apiInputData.remarks;

    formatedData.overridePrice = apiInputData.overridePrice;
    formatedData.isOverride = apiInputData.isOverride;
    formatedData.isTaxInclude = apiInputData.isTaxInclude;

    formatedData.isPaymentReceived = false;
    // recordPaymentDTO.isBalanceUsed = apiInputData.isBalanceUsed;
    // if(recordPaymentDTO.isBalanceUsed == true){
    //   recordPaymentDTO.balanceAmount = apiInputData.balanceAmount;
    // }
    if (apiInputData.isPaymentReceived && apiInputData.isPaymentReceived === 'yes') {
      formatedData.isPaymentReceived = true;
      recordPaymentDTO.paymentAmount = apiInputData.recordPaymentDTO.amount;
      recordPaymentDTO.paymentDate = this.datepipe.transform(apiInputData.recordPaymentDTO.payment_date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
      recordPaymentDTO.referenceNo = apiInputData.recordPaymentDTO.referenceNo;
      recordPaymentDTO.paymentMode = apiInputData.recordPaymentDTO.payment_mode;
      recordPaymentDTO.isTdsDeducted = apiInputData.recordPaymentDTO.tdsDeducted;
      recordPaymentDTO.remarks = apiInputData.remarks;
      recordPaymentDTO.custId = this.custId;
      if (apiInputData.recordPaymentDTO.tdsDeducted === true) {
        recordPaymentDTO.tdsAmount = apiInputData.recordPaymentDTO.tdsAmount;
      }
      if (recordPaymentDTO.paymentMode === 'cheque') {
        recordPaymentDTO.bankName = apiInputData.recordPaymentDTO.bankName;
        recordPaymentDTO.branch = apiInputData.recordPaymentDTO.branch;
        recordPaymentDTO.chequeDate = apiInputData.recordPaymentDTO.chequeDate;
        recordPaymentDTO.chequeNo = apiInputData.recordPaymentDTO.chequeNo;
      }
      formatedData.recordPaymentDTO = recordPaymentDTO;
    }
    return formatedData;
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  saveApiCall() {
    this.saveBtnDisable=true;
    let apiInputData = this.insertApplyChargeForm.value;
    let formatedData = this.transformApiIinputData(apiInputData);
    // let formatedData: any = {};
    // let chargeById = _.find(this.chargeList, { id: apiInputData.charge_id });
    // formatedData.custId = this.custId;
    // formatedData.charge_id = apiInputData.charge_id;
    // formatedData.charge_name = chargeById.name;
    // formatedData.charge_date = this.datepipe.transform(apiInputData.chargeDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
    // formatedData.remarks = apiInputData.remarks;
    console.log("apply Charge Form-->",formatedData);
    
    this.applyChargeService.updateApplyCharge(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
          this.saveBtnDisable=false;
        } else {
          this.tosterservice.success('Apply Charge Save Successfully.');
          this.saveBtnDisable=false;
          this.customModalService.close('apply-charge', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.submitted = false;
    this.insertApplyChargeForm.reset();
  }

  clearField(){
    this.insertApplyChargeForm.reset();
    this.insertApplyChargeForm.get('isPaymentReceived').setValue('no')
  }

  isIncludeTaxChange(e){
    
   if(e==="true"){
    this.isIncludeTaxflag=true;
    this.insertApplyChargeForm.get('overridePrice').updateValueAndValidity();
    let currentValue= this.insertApplyChargeForm.get('overridePrice').value;
    
    if((this.minamount !== null) && (this.maxamount !==null) && currentValue !== null && this.isIncludeTaxflag===true){
        this.OnCheckAPICallTaxCalculation();
        }
        else
        {
        this.toaster.error("Select Override Price");
        this.insertApplyChargeForm.get('overridePrice').setErrors({'incorrect': true});
        this.insertApplyChargeForm.get('overridePrice').updateValueAndValidity();
      }
    }else{
   
      let currentValue= this.insertApplyChargeForm.get('overridePrice').value;
       if((this.minamount !== null) && (this.maxamount !==null) && currentValue !== null &&  this.isIncludeTaxflag===false){
        this.onOverridePrice();
      }
             
    }
  }

   onOverridePriceBlur(){
    //this.insertApplyChargeForm.get('isBalanceUsed').setValue(false);
    if(this.insertApplyChargeForm.get('overridePrice').value !==null && this.isIncludeTaxflag===false){
      this.onOverridePrice();
    }else{
      this.OnCheckAPICallTaxCalculation();
    }
   }

    OnCheckAPICallTaxCalculation(){
      let AmountValue=this.insertApplyChargeForm.get('overridePrice').value;
      let obj = {
          amount: AmountValue,
          taxId: this.selectedtaxid,
      };
      this.applyChargeService.getTaxByAmountOnChecked(obj).subscribe((res)=>{
        if(res.status===200){
          this.chargeAmount=res.ReverseTaxAmount;
          this.taxAmount= Number(AmountValue)-res.ReverseTaxAmount;
          this.finalAmount= Number(this.chargeAmount)+Number(this.taxAmount);

          if(this.finalAmount < this.minamount && this.minamount !==null && this.insertApplyChargeForm.get('overridePrice').value != null){
            this.toaster.error("Price Override should not less than Min Amount  "  +this.minamount);
            this.insertApplyChargeForm.get('overridePrice').setErrors({'incorrect': true});
            return;
         }else if(this.finalAmount > this.maxamount && this.maxamount !==null && this.insertApplyChargeForm.get('overridePrice').value != null)
         {
          this.toaster.error("Price Override should not be greater than Max Amount  "+ this.maxamount);
          this.insertApplyChargeForm.get('overridePrice').setErrors({'incorrect': true});
          return;
         }
        }        
          })

    }


  onOverridePrice() {
    let AmountValue=this.insertApplyChargeForm.get('overridePrice').value;
    let obj = {
        amount: AmountValue,
        taxId: this.selectedtaxid,
    };

  this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
  if(res.status===200){
    let latestFinalAmount=this.insertApplyChargeForm.get('overridePrice').value;
    if(this.isIncludeTaxflag===false){
      this.chargeAmount=latestFinalAmount;
      this.taxAmount=Number(res.TaxAmount); 
      this.finalAmount= Number(latestFinalAmount) +Number(res.TaxAmount);
    }
   
    if(this.finalAmount <= this.minamount && this.minamount !==null && this.insertApplyChargeForm.get('overridePrice').value != null){
        this.toaster.error("Price Override should not less than Min Amount  "  +this.minamount);
        this.insertApplyChargeForm.get('overridePrice').setErrors({'incorrect': true});
        return;
     }else if(this.finalAmount >= this.maxamount && this.maxamount !==null && this.insertApplyChargeForm.get('overridePrice').value != null)
     {
      this.toaster.error("Price Override should not be greater than Max Amount  "+ this.maxamount);
      this.insertApplyChargeForm.get('overridePrice').setErrors({'incorrect': true});
      return;
     }
    }
  });
    
}



}
