export class AddBalance {
    amount:number;
    bank_name:string;
    branch_name:string;
    chequedate:string;
    chequenumber:string;
    partner_id:number;
    description:string;
    paymentdate:string;
    paymentmode:string;
    refno:string;
}
export class ReverseBalance {
    amount:number;
    partner_id:number;
    description:string;
    paymentdate:string;

}
