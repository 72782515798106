import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  ElementRef,
  NgModule,
  NgZone,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as alertify from 'alertifyjs';
import { gridDefaultSettingSettings } from './gridDefaultSetting';
declare var $: any;

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})
export class GridComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: any;
  @Input() settings: any;

  @Output() fetchDataFromApi = new EventEmitter<any>();
  @Output() userRowSelect = new EventEmitter<any>();
  @Output() onDeleteClick = new EventEmitter<any>();
  @Output() onEditClick = new EventEmitter<any>();

  @Output() deleteConfirm = new EventEmitter<any>();
  @Output() editConfirm = new EventEmitter<any>();

  tableId: string;
  tableClass: string;
  tableHeadClass: string;
  tableBodyClass: string;
  tableRowClass: string;
  perPageSelect: any;
  isPagerDisplay: boolean;
  page = 1;
  total = 0;
  perPage: any;
  userChangePerPage: any = false;
  lengthChangeMenu: any[];
  columnVisibilityForAllColumn: any = false;
  showColumnVisibilityChangePopup: any = false;
  showPageLenghtChange: any;
  showSearchBox: any;
  constructor(private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.setGridSetting();
  }

  ngAfterViewInit(): void {
    let self = this;
    this.hideColumnVisibilityPopupOnOuterClick();
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  ngOnChanges(): void {
    if (!this.data) {
      return;
    }
    this.setGridSetting();
  }

  setOptionValue(propertyName) {
    if (this.settings.hasOwnProperty(propertyName)) {
      this[propertyName] = this.settings[propertyName];
    } else {
      this[propertyName] = gridDefaultSettingSettings[propertyName];
    }
  }

  setGridSetting() {
    let self = this;
    if (this.data && this.data.content) {
      this.total = this.data.totalElements || this.data.content.length;
    }

    if (this.userChangePerPage == false) {
      this.perPage = this.settings.perPage ? this.settings.perPage : gridDefaultSettingSettings.perPage;
    }
    this.lengthChangeMenu = this.settings.lengthChangeMenu
      ? this.settings.lengthChangeMenu
      : gridDefaultSettingSettings.lengthChangeMenu;

    this.setOptionValue('showPageLenghtChange');
    this.setOptionValue('showSearchBox');
    this.setOptionValue('columnVisibilityForAllColumn');

    if (this.settings.tableId) {
      this.tableId = this.settings.tableId;
    } else {
      this.tableId = 'tbl_' + new Date().getTime() + Math.random();
    }

    if (this.columnVisibilityForAllColumn == true) {
      this.settings.columnVisibilityForAllColumn = true;
      this.showColumnVisibilityChangePopup = true;
      if (this.settings.columns) {
        this.settings.columns.forEach(function (columnsItem) {
          columnsItem.column_visibility = true;
        });
      }
    } else {
      if (this.settings.columns) {
        this.settings.columns.forEach(function (columnsItem) {
          if (self.showColumnVisibilityChangePopup == true) {
            return false;
          } else if (columnsItem.column_visibility == true) {
            self.showColumnVisibilityChangePopup = true;
          }
        });
      }
    }
    this.tableClass = this.settings.tableClass ? this.settings.tableClass : gridDefaultSettingSettings.tableClass;
    this.tableHeadClass = this.settings.tableHeadClass
      ? this.settings.tableHeadClass
      : gridDefaultSettingSettings.tableHeadClass;
    this.tableBodyClass = this.settings.tableBodyClass
      ? this.settings.tableBodyClass
      : gridDefaultSettingSettings.tableBodyClass;
    this.tableRowClass = this.settings.tableRowClass
      ? this.settings.tableRowClass
      : gridDefaultSettingSettings.tableRowClass;
  }

  getGridSettingAndEmitfetchDataFromApi() {
    let pageMinusOne = this.page - 1;
    this.fetchDataFromApi.emit({ page: pageMinusOne, perPage: this.perPage });
  }

  onGridPageLenthChange(selectedValue: string) {
    this.userChangePerPage = true;
    this.perPage = selectedValue;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  goToPage(page_number: number): void {
    this.page = page_number;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  onNext(): void {
    this.page++;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  onPrev(): void {
    this.page--;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  renderCustomColumnType(columnData, rowData, rowIndex) {
    let customColumnType = columnData.customColumnType;
    let value = '';
    if (customColumnType === 'sequence_column') {
      value = this.getSequenceNumber(columnData, rowData, rowIndex);
    } else if (customColumnType === 'deleteRecordWithConfirmMessage') {
      value = '<a title="delete" class="btn"><i class="fa fa-trash-o"></i></a>';
    } else if (customColumnType === 'localeDateTime') {
      let cellValue = rowData[columnData.data];
      if (cellValue != null && cellValue != undefined) {
        value = this.getLocaleDateTime(columnData, rowData, rowIndex);
      } else if (columnData.blankCellHtml) {
        value = columnData.blankCellHtml;
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  getSequenceNumber(columnData, rowData, rowIndex) {
    let rowNumber;
    if (this.page === 1) {
      rowNumber = rowIndex + 1;
    } else {
      rowNumber = (this.page - 1) * this.perPage + rowIndex + 1;
    }
    return rowNumber;
  }

  getLocaleDateTime(columnData, rowData, rowIndex) {
    if (columnData && columnData.data && rowData[columnData.data]) {
      let columnCellDate = new Date(rowData[columnData.data]);
      // let localDate = this.convertUTCDateToLocalDate(columnCellDate);
      // return  localDate.toLocaleDateString()+' '+localDate.toLocaleTimeString();
      return columnCellDate.toLocaleDateString() + ' ' + columnCellDate.toLocaleTimeString();
    } else {
      return '';
    }
  }

  convertUTCDateToLocalDate(date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  renderDefaultContentColumnType(columnData, rowData, rowIndex) {
    let defaultContent = columnData.defaultContent;
    return this.sanitizer.bypassSecurityTrustHtml(defaultContent);
  }

  renderCustomContentCellClick(event, column, rowData, i) {
    if (column.customColumnType && column.customColumnType === 'deleteRecordWithConfirmMessage') {
    } else if (column.click) {
      column.click(event, column, rowData, i);
    }
  }

  renderDefaultContentCellClick(event, column, rowData, i) {
    if (column.customColumnType && column.customColumnType === 'deleteRecordWithConfirmMessage') {
    } else if (column.click) {
      column.click(event, column, rowData, i);
    }
  }
  editButtonClick(event, column, selectedRowsData, rowIndex): void {
    if (column.onEditClick) {
      column.onEditClick(event, column, selectedRowsData, rowIndex);
    }
  }

  onToggleColumnVisibilityPopup() {
    let columnVisibilityPopupEl = document.getElementById('columnVisibilityPopup');
    if (columnVisibilityPopupEl.style.display === 'none') {
      columnVisibilityPopupEl.style.display = 'block';
    } else {
      columnVisibilityPopupEl.style.display = 'none';
    }
  }

  hideColumnVisibilityPopupOnOuterClick() {
    $(document).ready(function () {
      $('body').on('click', function (e) {
        let clickElment = $(e.target);
        if (clickElment.hasClass('columnVisibilityInternal') === false) {
          let columnVisibilityPopupEl = document.getElementById('columnVisibilityPopup');
          if (columnVisibilityPopupEl && columnVisibilityPopupEl.style.display !== 'none') {
            columnVisibilityPopupEl.style.display = 'none';
          }
        } else {
          return true;
        }
      });
    });
  }

  onColumnVisibilityChange(columnData, event) {
    let setStatus = false;
    if (event.target.checked) {
      setStatus = true;
    }
    this.settings.columns.forEach(function (item) {
      if (item.data === event.target.value) {
        columnData.visible = setStatus;
      }
    });
  }

  fillTemplate(templateString, templateVars) {
    var parsed = templateString;
    Object.keys(templateVars).forEach((key) => {
      const value = templateVars[key];
      parsed = parsed.replace('${' + key + '}', value);
    });
    return parsed;
    // function inject(s, o) {
    //   Object.keys(o).map(k => s=s.replace(new RegExp("\\$\\{"+k+"\\}", 'g'), o[k]));
    //   return s;
    // }
    // // parameters in object
    // let t1 = 'My name is ${name}, I am ${age}. My brother name is also ${name}.';
    // let r1 = inject(t1, {name: 'JOHN',age: 23} );
  }

  deleteButtonClick(event, column, selectedRowsData, rowIndex): void {
    var self = this;
    if (column.customColumnType === 'deleteRecordWithConfirmMessage' || column.deleteButtonWithConfirmMessage == 1) {
      var msgDetail = this.fillTemplate(column.deleteMsgBoxDetail, selectedRowsData);
      alertify
        .confirm(
          column.deleteMsgBoxTitle,
          msgDetail,
          function () {
            if (column.deleteYes) {
              column.deleteYes(event, column, selectedRowsData, rowIndex);
            }
          },
          function () {
            if (column.deleteNo) {
              column.deleteNo(event, column, selectedRowsData, rowIndex);
            }
          }
        )
        .setting('labels', { ok: 'Yes', cancel: 'No' })
        .set('defaultFocus', 'cancel');
    } else {
      if (column.onDeleteClick) {
        column.onDeleteClick(event, column, selectedRowsData, rowIndex);
      }
    }
  }
}
