import { ChangeDetectorRef, AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { CasesModel } from '../model/cases-model';
import { AppConfig } from '../../../../app.config';
import { CasesService } from '../service/cases.service';
import { SubscriberService } from '../../../masters/subscriber/subscriber.service';
import { DatePipe } from '@angular/common';
import { SubscriberModel } from '../../../masters/subscriber/model/subscriber-model';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { OrderingData } from '@neoboss/core/modal/ordering-data';
import { PaginationData } from '@neoboss/core/modal/pagination-data';
import { SearchData } from '@neoboss/core/modal/search-data';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { StaffService } from '../../../hr/staff/staff.service';
import { CaseReasonService } from '../../case-reason/service/case-reason.service';
import { SearchAutocompleteComponent } from '../../../../components/search-autocomplete/search-autocomplete.component';
import { CaseReasonModel } from '../../case-reason/model/case-reason-model';
import { GenericQuickcaseComponent } from '../../../../components/generic-quickcase/generic-quickcase.component'
declare var $: any;
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver/FileSaver';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Updatelistmodel } from '../model/updatelistmodel';
import { CaseUpdatePopupComponent } from '../case-update-popup/case-update-popup.component';
import { CasesAddUpdateComponent } from '../cases-add-update/cases-add-update.component';
import { InventoryService } from '../../../inventory/inventory/service/inventory.service';


@Component({
  selector: 'app-cases-list',
  templateUrl: './cases-list.component.html',
  styleUrls: ['./cases-list.component.scss'],
})
export class CasesListComponent extends AbstractBaseListComponent<CasesModel>
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('caseForServerWrapper', { static: false }) caseForServerWrapperRef: TemplateRef<any>;
  @ViewChild('AssigneToMe', { static: false }) AssigneToMe: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('connectivity', { static: false }) livestatusRef: TemplateRef<any>;
  @ViewChild("editUserName", { static: false }) editUserNameREf: TemplateRef<any>;
  @ViewChild("editcustomerName", { static: false }) customerNameRefff: TemplateRef<any>;
  @ViewChild("ticketno", { static: false }) ticketNoRef: TemplateRef<any>;
  @ViewChild("addressWrapper", { static: false }) addressWrapperReff: TemplateRef<any>;
  @ViewChild("circuitid", { static: false }) circuitWrapperReff: TemplateRef<any>;
  @ViewChild("ticketTitle", { static: false }) ticketTitleReff: TemplateRef<any>;
  @ViewChild("update", { static: false }) updateReff: TemplateRef<any>;
  @ViewChild("bulkupdate", { static: false }) bulkupdateReff: TemplateRef<any>;
  @ViewChild(SearchAutocompleteComponent, { static: false }) autoCompleteRef: SearchAutocompleteComponent;
  @ViewChild(GenericQuickcaseComponent, { static: false }) quickcaseRef: GenericQuickcaseComponent;
  @ViewChild(CaseUpdatePopupComponent, { static: false }) caseupdatepopup: CaseUpdatePopupComponent;
  @ViewChild(CasesAddUpdateComponent, { static: false }) caseaddupdate: CasesAddUpdateComponent;
  @ViewChild("multiSelect", { static: false }) multiSelectRef: TemplateRef<any>;
  @ViewChild('viewBtnWrapper', { static: false }) viewBtnWrapperRef: TemplateRef<any>;
  
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  QuickCaseFlag: string;
  QuickCaseFlag2:string;
  dataGrid: CasesModel[] = [];
  assigneForCase: CasesModel = new CasesModel();
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  status: any;
  filterBy: any;
  consumptionData:any;
  public entity: CasesModel = new CasesModel();
  //public updateList: Updatelistmodel;
  public entity_id: any = null;
  customerId: any = null;
  form: FormGroup;
  public customerid: number;
  public assignId: number;
  isAdvancseSearch: boolean = false;
  params: any;
  public Subscriber: SubscriberModel;
  public staff: any;
  caseStatus: any;
  quickCaseType: any;
  count: number;
  teams: any;
  groupList: any;
  filterObj: any;
  allColumns: any[] = [];
  public caseReason: CaseReasonModel;
  public subscriberList: string;
  public baseSetupList: string;
  public error_customer: boolean;
  submitted: boolean = false;
  caseListNeoGridSettings: any = {};
  userRoleString: string;
  btnDisabledFlag: boolean = false;
  eventdata: any;
  quick = [];
  public leaselinesubscriberList: string;
  circuit: any;
  custId: any;
  filters = new Array<filters>()
  filtersObj = new filters();
  updateData: CasesModel;
  updateListData: Updatelistmodel;
  multipleOptionsList = [];
  editviewId: number
  multiSelectAll: boolean = false;
  selectedRecord: number;
  bulkFlag: boolean = true;
  returnstocklist:  any = [];
  consumptionItemDetails:any = [];
  backEndImage:string;
  BackendFinalurl:string;
  config:any;
  inventorySupport: boolean
  
  constructor(
    private entityService: CasesService,
    private route: ActivatedRoute,
    public CommonListService: CommonListServicesService,
    private router: Router,
    public subscriberservice: SubscriberService,
    public staffservice: StaffService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private caseService: CasesService,
    loginService: LoginService,
    public caseReasonService: CaseReasonService,
    private ngbService: NgbModal,
    private inventoryservice: InventoryService,
    private appConfig: AppConfig,
  ) {
    super(toastrService, searchService);
    this.loginService = loginService;
    this.config = appConfig.getConfig();
    this.backEndImage=this.appConfig.config.baseURLApi;
    // this.backEndImage=this.appConfig.imgbaseURL;
    this.BackendFinalurl=this.config.inventoryUrl; 
    this.config = appConfig.getConfig();
    this.backEndImage=this.appConfig.config.baseURLApi;
    // this.backEndImage=this.appConfig.imgbaseURL;
    this.BackendFinalurl=this.config.inventoryUrl; 
    this.inventorySupport = appConfig.config.inventorySupport;
    this.form = new FormGroup({
      case_title: new FormControl(''),
      caseFor: new FormControl(''),
      caseType: new FormControl(''),
      customersId: new FormControl(null),
      leaseLineId: new FormControl(null),
      baseSetupId: new FormControl(null),
      caseReasonId: new FormControl('', [Validators.required]),
      caseOrigin: new FormControl(''),
      priority: new FormControl(''),
      circuitId: new FormControl(null),
      propertyId: new FormControl(null),
      firstRemark: new FormControl('', [Validators.required])
    });
  }
  Internalcase: boolean;
  advanceFilterApplied: boolean = false;
  ngOnInit() {
    //this.init();
    this.searchService.setSearchFilterSettings('case');

    let URL = this.router.url.includes('casesInternal')

    if (URL) {
      this.Internalcase = true
      this.entityService.Internalcase = true
    } else {
      this.Internalcase = false
      this.entityService.Internalcase = false
    }
    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CASES, AclConstants.OPERATION_CASE_EDIT, AclConstants.OPERATION_CASE_ALL)) {
      let updateObj = { key: 'caseUpdate', label: 'Case Update' };
      this.multipleOptionsList.push(updateObj);
    }
    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CASES, AclConstants.OPERATION_CASE_EDIT, AclConstants.OPERATION_CASE_ALL)) {
      let privateUpdateObj = { key: 'casePrivateUpdate', label: 'Case Private Update' };
      this.multipleOptionsList.push(privateUpdateObj);
    }
    this.searchService.serverFilterObj = null
    this.setDefaultPageSize(this.tableKeyEnum.CASES)
    this.initForCaseList();
    this.getTableColumn();
    //this.getSubscriber();
    this.getcaseReason();
    this.CommonListService.getCommonListcaseStatus().subscribe((res) => {
      this.caseStatus = res.dataList;
    });
    this.setDataFromLocalStorage();
    this.getUserRoles()
    this.getquickCaseType();
    this.getTeams();
    this.getGroup();

    const dynamicFormFilter = (this.Internalcase)? JSON.parse(localStorage.getItem('CaseInternal_filter')): JSON.parse(localStorage.getItem('Case_filter'));
    if(!dynamicFormFilter) this.setFilterObj(this.entity.caseswitch);
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
        this.advanceFilterApplied = obj?true:false;
        this.searchService.serverFilterObj =  this.searchService.serverFilterObj.filter(res =>
          res.filterColumn == 'Mycases' ||
          res.filterColumn == 'AllCases' ||
          res.filterColumn == 'bulkOperation' ||
          res.filterColumn == 'caseFor' ||
          res.filterColumn == 'status' ||
          res.filterColumn == 'Internal' ||
          res.filterColumn == 'any'
        );
        this.applyFilter(obj, true);
      // }else
    });

    // this.subscriptions.push(filterSubscription);
    if(!filterSubscription.closed && dynamicFormFilter){
      this.advanceFilterApplied = true;
      let casefilters = new Array<filters>();
      for (let i = 0; i < dynamicFormFilter.length; i++) {
        casefilters.push(dynamicFormFilter[i]);
      }
      this.filtersObj = new filters();
      this.filtersObj.filterColumn = 'Internal';
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = this.Internalcase ? 'include' : 'exclude';
      casefilters.push(this.filtersObj);
      this.filtersObj = new filters();
      this.filtersObj.filterColumn = checkSwitch(this.entity);
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = null;
      casefilters.push(this.filtersObj);
      this.applyFilter(casefilters,true);
    }
    else{
      this.advanceFilterApplied = false;
    }

  }

  initForCaseList() {
    //this.paginationData = new PaginationData();
    if (this.neoTableSetting && this.neoTableSetting.perPage) {
      this.paginationData.rowsPerPage = this.neoTableSetting.perPage;
    }
    this.orderingData = new OrderingData();
    this.orderingData.column = 'id';
    this.orderingData.descending = false;
    this.neoTableSetting = this.getTableSetting();
    this.neoTableSearchSetting = this.getTableSearchSetting();
    this.authRole();
    this.form = new FormGroup({
      case_title: new FormControl(''),
      caseFor: new FormControl(''),
      caseType: new FormControl(''),
      customersId: new FormControl(''),
      leaseLineId: new FormControl(''),
      baseSetupId: new FormControl(''),
      caseReasonId: new FormControl('', [Validators.required]),
      caseOrigin: new FormControl(''),
      priority: new FormControl(''),
      circuitId: new FormControl(null),
      propertyId: new FormControl(null),
      firstRemark: new FormControl('', [Validators.required])
    });
  }

  listTemp: any = [];
  default_value: any;
  // closeForm() {
  //   this.listTemp = [];
  //   this.default_value = null;
  //   this.form.get('customersId').reset();
  //   this.form.get('leaseLineId').reset();
  //   this.form.reset();
  //   // this.form.get('caseReasonId').setValue('ffd');
  //   this.form.controls.caseReasonId.setValue('  ');
  //   this.form.controls.circuitId.setValue('  ');
  //   this.form.controls.firstRemark.setValue('  ');
  //   //////console.log("reset",this.form);

  //   this.autoCompleteRef.reset();
  //   this.autoCompleteRef.autocomplete.clearAllText;
  //   this.autoCompleteRef.dataList = [];
  //   //this.form.reset();
  // }


  setDataFromLocalStorage() {
    this.entity = new CasesModel();
    let caseSwitch = localStorage.getItem('CaseSwitch');
    if (caseSwitch == 'Mycases') { this.entity.caseswitch = 'Mycases'; }
    if (caseSwitch == 'AllCases') { this.entity.caseswitch = 'AllCases'; }
    if (caseSwitch == 'bulkOperation') {
      this.entity.caseswitch = 'bulkOperation';
      this.bulkFlag = false;
    }
    let caseStatus = localStorage.getItem('caseStatus');
    let teams = localStorage.getItem('teams');
    let group = localStorage.getItem('group');
    let caseFor
    if (!this.Internalcase) {
      caseFor = localStorage.getItem('caseFor');
    }

    if (teams && teams != 'undefined') {
      this.entity.teams = teams
    }
    if (group && group != 'undefined') {
      this.entity.group = group
    }

    if (caseStatus && caseStatus != 'undefined') {
      this.entity.caseStatus = caseStatus.split(',');
    }
    if (caseFor && caseFor != 'undefined') {
      this.entity.caseForListing = caseFor
    }

  }

  onViewClick(viewconsumption, data) {
    this.consumptionData = [];
    this.returnstocklist = [];
    this.consumptionItemDetails = [];
    
    if(data) {
      this.inventoryservice.getCaseInventoryHistory(data.caseNumber).subscribe((res) =>{
        this.consumptionData = res.data;
        this.consumptionData.forEach((res) =>{
          if(res.isFaulty == true){
            this.returnstocklist.push(res);
          } else {
            this.consumptionItemDetails.push(res);
          }
        });
      })
    }
    
    this.ngbService.open(viewconsumption, { windowClass: 'my-class1' });
   
  }
  
  getUserRoles() {
    this.userRoleString = localStorage.getItem('userRoles')
  }
  getCircuit() {
    this.caseService.getcircuitdetails(this.custId).subscribe((res) => {
      this.circuit = res.dataList;
      res.dataList.forEach((ls) => {
        this.form.get('propertyId').setValue(ls.propertyLine);
      });
    });
  }
  // validateForm() {
  //   this.btnDisabledFlag = true;
  //   this.submitted = true;
  //   // if (this.form.invalid) {
  //   //   this.toastrService.error("Please fill all the details!");
  //   //   this.btnDisabledFlag = true;
  //   //   return
  //   // }

  //   // if(!this.form.get('customersId').value){
  //   //   this.error_customer = false;
  //   // }

  //   // if(!this.form.get('leaseLineId').value){
  //   //   this.error_customer = false;
  //   // }

  //   if (this.form.invalid) {
  //     this.toastrService.error("Please fill all the details!");
  //     //this.toastrService.error("Please fill all the details!");
  //        this.btnDisabledFlag = true;
  //        return
  //     //this.error_customer = false;
  //     // Object.keys(this.form.controls).forEach(field => {
  //     //   const control = this.form.get(field);
  //     //   if (control instanceof FormControl) {
  //     //     control.markAsTouched({ onlySelf: true });
  //     //   }
  //     // });
  //   } else {
  //     this.saveQuickCase();
  //   }
  // }
  caseReasonChange(event) {
    this.form.get('case_title').setValue(event.name);
  }


  // saveQuickCase() {

  //   if (this.form.valid === true) {
  //     this.form.get('caseType').setValue('Issue')
  //     ////console.log("saveQui", this.form.value);

  //     this.caseService.saveCase(this.form.value).subscribe((res) => {
  //       if (res.responseCode === 200) {
  //         this.btnDisabledFlag = false;
  //         this.toastrService.success("Case saved successfully");
  //         this.closeForm();
  //         this.form.get('customersId').reset();
  //         this.form.get('leaseLineId').reset();
  //         this.autoCompleteRef.autocomplete.clearAllText;
  //         this.autoCompleteRef.dataList = [];

  //         $("#quickCaseModal").modal('hide');
  //         this.form.reset();
  //       }
  //       else if (res.responseCode !== 200) {
  //         this.toastrService.error(res.responseMessage);
  //         // this.btnDisabledFlag=true;
  //         this.btnDisabledFlag = false;
  //       }
  //       this.getList(this.paginationData, this.orderingData, this.searchData);
  //     });
  //   } else {
  //     this.toastrService.error("Form is not valid!");
  //   }
  // }

  getlist() {

    //this.refreshDataSource(this.dataGrid);
    this.getList(this.paginationData, this.orderingData, this.searchData);
  }

  viewlocation(data,i){ 
    let url:any;
    if(data.latitude && data.longitude){
      
      url= 'http://maps.google.com/maps?z=12&t=m&q=loc:' + data.latitude +'+' + data.longitude;
      window.open(url,'_blank');
    }
  }

  onClickDownload(referenceName: any) {
    window.open(this.BackendFinalurl+ 'file/get/' + referenceName, '_blank');
  }

  onQuickCasePopup(caseDetail, data) {
    this.QuickCaseFlag = data;
    this.QuickCaseFlag2 = data;
    ////console.log("this.QuickCaseFlag",this.QuickCaseFlag);
    this.listTemp = ['name', 'username', 'phone', 'email'];
    // if(this.QuickCaseFlag == 'Lease Line'){
    //   this.getleaselineSubscriber();
    //   this.form.get('customersId').clearValidators();
    //   this.form.get('customersId').updateValueAndValidity();
    //   this.form.get('leaseLineId').setValidators([Validators.required]);
    //   this.form.get('leaseLineId').updateValueAndValidity();
    //   this.form.get('circuitId').setValidators([Validators.required]);
    //   this.form.get('circuitId').updateValueAndValidity();
    // } else {
    //   this.getSubscriber();
    //   this.form.get('customersId').setValidators([Validators.required]);
    //   this.form.get('customersId').updateValueAndValidity();
    //   this.form.get('leaseLineId').clearValidators();
    //   this.form.get('leaseLineId').updateValueAndValidity();
    //   this.form.get('circuitId').clearValidators();
    //   this.form.get('circuitId').updateValueAndValidity();
    // }

    // this.btnDisabledFlag = false;
    // this.submitted = false;
    // this.form.reset();
    // if(this.QuickCaseFlag == 'Lease Line'){
    //   this.form.get('caseFor').setValue('Lease Line');
    // } else {
    //   this.form.get('caseFor').setValue('Customer');
    // }
    // this.form.get('caseType').setValue('Issue');
    // this.form.get('caseOrigin').setValue('Phone');
    // this.form.get('priority').setValue('Medium');
    this.ngbService.open(caseDetail, { windowClass: 'my-class' });
    // $("#quickCaseModal").modal('show');
  }
  // changecasefor() {
  //   if (this.QuickCaseFlag == 'Lease Line') {
  //     this.getleaselineSubscriber();
  //   } else {
  //     this.getSubscriber();
  //   }
  // }
  public getBaseSetup() {
    this.baseSetupList = 'baseSetup/searchBaseSetup?s=';
  }

  public getSubscriber() {
    this.subscriberList = 'subscriber/searchNew?s=';
  }
  public getleaselineSubscriber() {
    this.leaselinesubscriberList = 'leasedlinesubscriber/searchLeaseCustomer?s=';
  }

  public setSubscriber(eventcust) {
    let event = eventcust.event;
    if (event) {
      this.form.get('customersId').setValue(event.custid);
      this.error_customer = true;
    } else {
      this.error_customer = false;
      this.form.get('customersId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
  }

  public setLeaseSubscriber(eventcust) {
    let event = eventcust.event;
    this.custId = event.custid;

    if (event) {
      this.form.get('leaseLineId').setValue(event.custid);
      this.error_customer = true;
    } else {
      this.error_customer = false;
      this.form.get('leaseLineId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
  }

  public setBaseSubscriber(eventcust) {
    let event = eventcust.event;

    if (event) {
      this.form.get('baseSetupId').setValue(event.custid);
      this.error_customer = true;
    } else {
      this.error_customer = false;
      this.form.get('baseSetupId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
  }

  public getcaseReason() {
    this.caseReasonService.getAllcaseReason().subscribe((res) => {
      this.caseReason = res.dataList
    });
  }

  get f() {
    return this.form.controls;
  }

  getService(): IBaseService<CasesModel> {
    return this.entityService;
  }

  getTableColumn() {
    const self = this;
    if (this.Internalcase) {
      if(this.inventorySupport){
        this.allColumns = [
          {
            colKey: 'index',
            default: 'ID',
            rowKey: 'id',
            width: '3%',
            isFilterAllow: false,
            isNumber: true,
          },
          {
            colKey: 'multiSelect',
            default: 'Select',
            rowKey: 'multiSelect',
            width: '3%',
            isNumber: false,
            isFilterAllow: false,
          },
          {
            colKey: 'userlive',
            default: 'LIVE',
            rowKey: 'userlive',
            width: '2%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseNumber',
            default: 'Ticket NO',
            rowKey: 'caseNumber',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'ConsumptionCol',
            default: 'View Consumption',
            rowKey: 'caseId',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseTitle',
            default: 'Ticket Title',
            rowKey: 'caseTitle',
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'teamName',
            default: 'Team Name',
            rowKey: 'teamName',
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseForServer',
            default: 'ETPL ',
            rowKey: 'caseForServer',
            customControls: [this.caseForServerWrapperRef],
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'createdate',
            default: 'Created Date',
            rowKey: 'createdate',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'userName',
          //   default: 'User Name',
          //   rowKey: 'userName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'caseFor',
            default: 'Case For',
            rowKey: 'caseFor',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'customerName',
          //   default: 'Customer Name',
          //   rowKey: 'customerName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'mobile',
          //   default: 'Contact No',
          //   rowKey: 'mobile',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'fulladdress',
          //   default: 'Address',
          //   rowKey: 'fulladdress',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'circuitid',
          //   default: 'Circuit ID',
          //   rowKey: 'circuitid',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'currentAssigneeName',
            default: 'Assigned To',
            rowKey: 'currentAssigneeName',
            customControls: [this.AssigneToMe],
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseStatus',
            default: 'Status',
            rowKey: 'caseStatus',
            customControls: [this.statusWrapperRef],
            customValue: (caseStatus) => {

              if (caseStatus === 'Unassigned') {

                return 'Unassigned';

              }
              else if (caseStatus === 'Scrap') {

                return 'Scrap';

              }
              else if (caseStatus === 'Assigned') {

                return 'Assigned';

              } else if (caseStatus === 'In Progress') {

                return 'In Progress';

              } else if (caseStatus === 'Working') {

                return 'Working';

              } else if (caseStatus === 'On Hold') {

                return 'On Hold';

              } else if (caseStatus === 'Resolved') {

                return 'Resolved';

              } else if (caseStatus === 'Not Resolved') {

                return 'Not Resolved';

              } else if (caseStatus === 'Closed') {

                return 'Closed';

              }

            },
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'oltName',
          //   default: 'Olt Name',
          //   rowKey: 'oltName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'slotName',
          //   default: 'Slot Name',
          //   rowKey: 'slotName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'portName',
          //   default: 'Port Name',
          //   rowKey: 'portName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'url',
          //   default: 'Url',
          //   rowKey: 'url',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'gis_code',
          //   default: 'Gis code',
          //   rowKey: 'gis_code',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'priority',
            default: 'Priority',
            rowKey: 'priority',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'power',
          //   default: 'Power',
          //   rowKey: 'power',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'groupName',
            default: 'Group Name',
            rowKey: 'groupName',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'amount',
            default: 'Amount',
            rowKey: 'amount',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'issue',
            default: 'Issue',
            rowKey: 'issue',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'solution',
            default: 'Solution',
            rowKey: 'solution',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'receipt_no',
            default: 'Receipt No',
            rowKey: 'receipt_no',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'chargableType',
            default: 'Chargeable Type',
            rowKey: 'chargableType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'customerCity',
            default: 'Customer City',
            rowKey: 'customerCity',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'updatedate',
            default: 'Last Modified',
            rowKey: 'updatedate',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'actionCol',
            default: 'Action',
            rowKey: 'caseId',
            //enableEdit: true,
            // enableDelete: true,
            enableEdit: false,
            enableDelete: false,
            isFilterAllow: false,
            enableSort: false,
            isExpandable: false,
            isNumber: false,
            width: '8%',
            isStickyEnd: true,
            isMultipleOption: true
          },
        ];
      }else{
        this.allColumns = [
          {
            colKey: 'index',
            default: 'ID',
            rowKey: 'id',
            width: '3%',
            isFilterAllow: false,
            isNumber: true,
          },
          {
            colKey: 'multiSelect',
            default: 'Select',
            rowKey: 'multiSelect',
            width: '3%',
            isNumber: false,
            isFilterAllow: false,
          },
          {
            colKey: 'userlive',
            default: 'LIVE',
            rowKey: 'userlive',
            width: '2%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseNumber',
            default: 'Ticket NO',
            rowKey: 'caseNumber',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseTitle',
            default: 'Ticket Title',
            rowKey: 'caseTitle',
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
         
          {
            colKey: 'createdate',
            default: 'Created Date',
            rowKey: 'createdate',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'userName',
          //   default: 'User Name',
          //   rowKey: 'userName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'caseFor',
            default: 'Case For',
            rowKey: 'caseFor',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'customerName',
          //   default: 'Customer Name',
          //   rowKey: 'customerName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'mobile',
          //   default: 'Contact No',
          //   rowKey: 'mobile',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'fulladdress',
          //   default: 'Address',
          //   rowKey: 'fulladdress',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'circuitid',
          //   default: 'Circuit ID',
          //   rowKey: 'circuitid',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'currentAssigneeName',
            default: 'Assigned To',
            rowKey: 'currentAssigneeName',
            customControls: [this.AssigneToMe],
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseStatus',
            default: 'Status',
            rowKey: 'caseStatus',
            customControls: [this.statusWrapperRef],
            customValue: (caseStatus) => {

              if (caseStatus === 'Unassigned') {

                return 'Unassigned';

              }
              else if (caseStatus === 'Scrap') {

                return 'Scrap';

              }
              else if (caseStatus === 'Assigned') {

                return 'Assigned';

              } else if (caseStatus === 'In Progress') {

                return 'In Progress';
              
              } else if (caseStatus === 'Working') {

                return 'Working';

              } else if (caseStatus === 'On Hold') {

                return 'On Hold';

              } else if (caseStatus === 'Resolved') {

                return 'Resolved';

              } else if (caseStatus === 'Not Resolved') {

                return 'Not Resolved';

              } else if (caseStatus === 'Closed') {

                return 'Closed';

              }

            },
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'oltName',
          //   default: 'Olt Name',
          //   rowKey: 'oltName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'slotName',
          //   default: 'Slot Name',
          //   rowKey: 'slotName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'portName',
          //   default: 'Port Name',
          //   rowKey: 'portName',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'url',
          //   default: 'Url',
          //   rowKey: 'url',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          // {
          //   colKey: 'gis_code',
          //   default: 'Gis code',
          //   rowKey: 'gis_code',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'priority',
            default: 'Priority',
            rowKey: 'priority',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'power',
          //   default: 'Power',
          //   rowKey: 'power',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          
          {
            colKey: 'updatedate',
            default: 'Last Modified',
            rowKey: 'updatedate',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'groupName',
            default: 'Group Name',
            rowKey: 'groupName',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'teamName',
            default: 'Team Name',
            rowKey: 'teamName',
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseForServer',
            default: 'ETPL ',
            rowKey: 'caseForServer',
            customControls: [this.caseForServerWrapperRef],
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'amount',
            default: 'Amount',
            rowKey: 'amount',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'issue',
            default: 'Issue',
            rowKey: 'issue',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'solution',
            default: 'Solution',
            rowKey: 'solution',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'receipt_no',
            default: 'Receipt No',
            rowKey: 'receipt_no',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'chargableType',
            default: 'Chargeable Type',
            rowKey: 'chargableType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'actionCol',
            default: 'Action',
            rowKey: 'caseId',
            //enableEdit: true,
            // enableDelete: true,
            enableEdit: false,
            enableDelete: false,
            isFilterAllow: false,
            enableSort: false,
            isExpandable: false,
            isNumber: false,
            width: '8%',
            isStickyEnd: true,
            isMultipleOption: true
          },
        ];
      }
    }
    else {
      if(this.inventorySupport){
        this.allColumns = [
          {
            colKey: 'index',
            default: 'ID',
            rowKey: 'id',
            width: '3%',
            isFilterAllow: false,
            isNumber: true,
          },
          {
            colKey: 'multiSelect',
            default: 'Select',
            rowKey: 'multiSelect',
            width: '3%',
            isNumber: false,
            isFilterAllow: false,
          },
          {
            colKey: 'userlive',
            default: 'LIVE',
            rowKey: 'userlive',
            width: '2%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseNumber',
            default: 'Ticket NO',
            rowKey: 'caseNumber',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'ConsumptionCol',
            default: 'View Consumption',
            rowKey: 'caseId',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          // {
          //   colKey: 'ConsumptionCol',
          //   default: 'View Consumption',
          //   rowKey: 'caseId',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'caseTitle',
            default: 'Ticket Title',
            rowKey: 'caseTitle',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'teamName',
            default: 'Team Name',
            rowKey: 'teamName',
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseForServer',
            default: 'ETPL ',
            rowKey: 'caseForServer',
            customControls: [this.caseForServerWrapperRef],
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'createdate',
            default: 'Created Date',
            rowKey: 'createdate',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'userName',
            default: 'User Name',
            rowKey: 'userName',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseFor',
            default: 'Case For',
            rowKey: 'caseFor',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'customerName',
            default: 'Customer Name',
            rowKey: 'customerName',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'mobile',
            default: 'Contact No.',
            rowKey: 'mobile',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'fulladdress',
            default: 'Address',
            rowKey: 'fulladdress',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'circuitid',
            default: 'Circuit ID',
            rowKey: 'circuitid',
            width: '7%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'currentAssigneeName',
            default: 'Assigned To',
            rowKey: 'currentAssigneeName',
            customControls: [this.AssigneToMe],
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseStatus',
            default: 'Status',
            rowKey: 'caseStatus',
            customControls: [this.statusWrapperRef],
            customValue: (caseStatus) => {

              if (caseStatus === 'Unassigned') {

                return 'Unassigned';

              } else if (caseStatus === 'Assigned') {

                return 'Assigned';

              } else if (caseStatus === 'In Progress') {

                return 'In Progress';

              } else if (caseStatus === 'Working') {

                return 'Working';

              } else if (caseStatus === 'On Hold') {

                return 'On Hold';

              } else if (caseStatus === 'Resolved') {

                return 'Resolved';

              } else if (caseStatus === 'Not Resolved') {

                return 'Not Resolved';

              } else if (caseStatus === 'Closed') {

                return 'Closed';

              }

            },
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'oltName',
            default: 'Olt Name',
            rowKey: 'oltName',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'slotName',
            default: 'Slot Name',
            rowKey: 'slotName',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'portName',
            default: 'Port Name',
            rowKey: 'portName',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'url',
            default: 'Url',
            rowKey: 'url',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'gis_code',
            default: 'Gis code',
            rowKey: 'gis_code',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'priority',
            default: 'Priority',
            rowKey: 'priority',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'power',
            default: 'Power',
            rowKey: 'power',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'groupName',
            default: 'Group Name',
            rowKey: 'groupName',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'amount',
            default: 'Amount',
            rowKey: 'amount',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'issue',
            default: 'Issue',
            rowKey: 'issue',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'solution',
            default: 'Solution',
            rowKey: 'solution',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'receipt_no',
            default: 'Receipt No',
            rowKey: 'receipt_no',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'chargableType',
            default: 'Chargeable Type',
            rowKey: 'chargableType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'customerCity',
            default: 'Customer City',
            rowKey: 'customerCity',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'connectionType',
            default: 'Connection Type',
            rowKey: 'connectionType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'routerType',
            default: 'Router Type',
            rowKey: 'routerType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'bridgeType',
            default: 'Bridge Type',
            rowKey: 'bridgeType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'onuid',
            default: 'onu id',
            rowKey: 'onuid',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'reason',
            default: 'reason',
            rowKey: 'reason',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'altmobile',
            default: 'Alt Mobile',
            rowKey: 'altmobile',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'serviceArea',
            default: 'service Area',
            rowKey: 'serviceArea',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'pincode',
            default: 'Pincode',
            rowKey: 'pincode',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'serviceType',
            default: 'Service Type',
            rowKey: 'serviceType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'salesRepName',
            default: 'salesRep Name',
            rowKey: 'salesRepName',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'feasibilityDistance',
            default: 'feasibility Distance',
            rowKey: 'feasibilityDistance',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'updatedate',
            default: 'Last Modified',
            rowKey: 'updatedate',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'actionCol',
            default: 'Action',
            rowKey: 'caseId',
            //enableEdit: true,
            // enableDelete: true,
            enableEdit: false,
            enableDelete: false,
            isFilterAllow: false,
            enableSort: false,
            isExpandable: false,
            isNumber: false,
            width: '8%',
            isStickyEnd: true,
            isMultipleOption: true
          },
        ];
      }else{
        this.allColumns = [
          {
            colKey: 'index',
            default: 'ID',
            rowKey: 'id',
            width: '3%',
            isFilterAllow: false,
            isNumber: true,
          },
          {
            colKey: 'multiSelect',
            default: 'Select',
            rowKey: 'multiSelect',
            width: '3%',
            isNumber: false,
            isFilterAllow: false,
          },
          {
            colKey: 'userlive',
            default: 'LIVE',
            rowKey: 'userlive',
            width: '2%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseNumber',
            default: 'Ticket NO',
            rowKey: 'caseNumber',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          // {
          //   colKey: 'ConsumptionCol',
          //   default: 'View Consumption',
          //   rowKey: 'caseId',
          //   width: '10%',
          //   isNumber: false,
          //   isFilterAllow: true,
          //   enableEdit: true,
          //   enableDelete: true,
          //   enableSort: true,
          // },
          {
            colKey: 'caseTitle',
            default: 'Ticket Title',
            rowKey: 'caseTitle',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'teamName',
            default: 'Team Name',
            rowKey: 'teamName',
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseForServer',
            default: 'ETPL ',
            rowKey: 'caseForServer',
            customControls: [this.caseForServerWrapperRef],
            width: '6%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'createdate',
            default: 'Created Date',
            rowKey: 'createdate',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'userName',
            default: 'User Name',
            rowKey: 'userName',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'caseFor',
            default: 'Case For',
            rowKey: 'caseFor',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'customerName',
            default: 'Customer Name',
            rowKey: 'customerName',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'mobile',
            default: 'Contact No.',
            rowKey: 'mobile',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'fulladdress',
            default: 'Address',
            rowKey: 'fulladdress',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'circuitid',
            default: 'Circuit ID',
            rowKey: 'circuitid',
            width: '7%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'currentAssigneeName',
            default: 'Assigned To',
            rowKey: 'currentAssigneeName',
            customControls: [this.AssigneToMe],
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },

          {
            colKey: 'caseStatus',
            default: 'Status',
            rowKey: 'caseStatus',
            customControls: [this.statusWrapperRef],
            customValue: (caseStatus) => {

              if (caseStatus === 'Unassigned') {

                return 'Unassigned';

              } else if (caseStatus === 'Assigned') {

                return 'Assigned';

              } else if (caseStatus === 'In Progress') {

                return 'In Progress';

              } else if (caseStatus === 'Working') {

                return 'Working';

              } else if (caseStatus === 'On Hold') {

                return 'On Hold';

              } else if (caseStatus === 'Resolved') {

                return 'Resolved';

              } else if (caseStatus === 'Not Resolved') {

                return 'Not Resolved';

              } else if (caseStatus === 'Closed') {

                return 'Closed';

              }

            },
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'oltName',
            default: 'Olt Name',
            rowKey: 'oltName',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'slotName',
            default: 'Slot Name',
            rowKey: 'slotName',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'portName',
            default: 'Port Name',
            rowKey: 'portName',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'url',
            default: 'Url',
            rowKey: 'url',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'gis_code',
            default: 'Gis code',
            rowKey: 'gis_code',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'priority',
            default: 'Priority',
            rowKey: 'priority',
            width: '9%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'power',
            default: 'Power',
            rowKey: 'power',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'groupName',
            default: 'Group Name',
            rowKey: 'groupName',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'amount',
            default: 'Amount',
            rowKey: 'amount',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'issue',
            default: 'Issue',
            rowKey: 'issue',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'solution',
            default: 'Solution',
            rowKey: 'solution',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'receipt_no',
            default: 'Receipt No',
            rowKey: 'receipt_no',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'chargableType',
            default: 'Chargeable Type',
            rowKey: 'chargableType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'customerCity',
            default: 'Customer City',
            rowKey: 'customerCity',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'connectionType',
            default: 'Connection Type',
            rowKey: 'connectionType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'routerType',
            default: 'Router Type',
            rowKey: 'routerType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'bridgeType',
            default: 'Bridge Type',
            rowKey: 'bridgeType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'onuid',
            default: 'onu id',
            rowKey: 'onuid',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'reason',
            default: 'reason',
            rowKey: 'reason',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'altmobile',
            default: 'Alt Mobile',
            rowKey: 'altmobile',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'serviceArea',
            default: 'service Area',
            rowKey: 'serviceArea',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'pincode',
            default: 'Pincode',
            rowKey: 'pincode',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'serviceType',
            default: 'Service Type',
            rowKey: 'serviceType',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'salesRepName',
            default: 'salesRep Name',
            rowKey: 'salesRepName',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'feasibilityDistance',
            default: 'feasibility Distance',
            rowKey: 'feasibilityDistance',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'updatedate',
            default: 'Last Modified',
            rowKey: 'updatedate',
            width: '8%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
          {
            colKey: 'actionCol',
            default: 'Action',
            rowKey: 'caseId',
            //enableEdit: true,
            // enableDelete: true,
            enableEdit: false,
            enableDelete: false,
            isFilterAllow: false,
            enableSort: false,
            isExpandable: false,
            isNumber: false,
            width: '8%',
            isStickyEnd: true,
            isMultipleOption: true
          },
        ];
      }
    }


    this.allColumns.forEach((res) => {
      res.isFilterAllow = false
    })
    this.getTableSetting();
    this.getTableSearchSetting();
    return this.allColumns ? this.allColumns : [];
  }

  getTableSetting() {
    let caseListNeoGridSettings = {
      showAdvanceSearch: true,
      showFooter: false,
      showPagination: true,
      serverSideSortPagination: true,
      tableId: this.tableKeyEnum.CASES,
      perPage: this.paginationData.rowsPerPage,
      pageNumber: this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      // defaultSortCol: 'name',
      // defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Case ?',
      deleteMsgBoxDetail: 'Case Number :${caseNumber} ',
    };
    this.caseListNeoGridSettings = caseListNeoGridSettings;
    return caseListNeoGridSettings;
  }
  onCustomerEdit(event) {
    this.setUpdateValue(event);
  }
  setUpdateValue(rowData) {
    this.router.navigate(['/masters/subscriber/details', rowData.customersId]);
  }
  onFilterChange(filterObj, obj?, advaceSearch = false) {
    // if(filterObj){
    //  let temp = filterObj.findIndex(res=>res.filterColumn == null)
    //  filterObj.splice(temp,1)
    // }

    if (this.dataGrid.find(res => res.multiSelected === true)) {
      alert("All your selected Tickets will be removed. If you change the page");
    } 
  
    if (this.multiSelectAll) {
      this.multiSelectAll = !this.multiSelectAll
      this.entity.ticketLists = []
      this.selectedRecord = this.entity.ticketLists.length
      this.count = this.selectedRecord
    }
    if (this.multiSelectAll) {
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = false
        }
      })
    } else {
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = true
        }
      })
    }
    // if((!filterObj || filterObj.filterValue == '') && this.filters && this.filters.length > 0)
    // this.filters = this.filters.filter((ls) => ls.filterColumn != 'any');
    // if(this.filters && !obj && !Array.isArray(filterObj))
    // this.filters.push(filterObj);
    // if(this.filters && !obj && Array.isArray(filterObj))
    // this.filters.push(...filterObj);
    if (this.searchService) {

      let serverFilterObj: SearchData[] = this.searchService.getServerFilterObj();
      if (filterObj) {
        //this.filters.forEach(res=> filterObj.push(res))
        let searchData = new SearchData();
        searchData = filterObj;
        if (!Array.isArray(searchData) && searchData.filterColumn) {
          if (searchData.filterValue) {
            this.searchService.resetAdvanceSearchServerFilterObj();
            let setWhenGlobal = Object.assign([], serverFilterObj.filter(res => res.filterColumn !== 'any'))
            serverFilterObj = this.searchService.setServerFilterObj([searchData]);
            setWhenGlobal.forEach(res => serverFilterObj.push(res))
            this.searchService.serverFilterObj = serverFilterObj;
            this.filters = Object.assign([], serverFilterObj)
            this.paginationData.pageNumber = 1;
          } else if (searchData.filterValue == '') {
            let temp: any = Object.assign([], serverFilterObj.filter(res => res.filterColumn !== 'any'))
            serverFilterObj = []
            temp.forEach(res => serverFilterObj.push(res))
            this.searchService.serverFilterObj = serverFilterObj;
            this.filters = Object.assign([], serverFilterObj)
            this.paginationData.pageNumber = advaceSearch ? 1 : this.neoTableSetting.pageNumber + 1
          } else {
            let temp: any = Object.assign([], serverFilterObj.filter(res => res.filterColumn !== 'any'))
            serverFilterObj = []
            temp.forEach(res => serverFilterObj.push(res))
            this.searchService.serverFilterObj = serverFilterObj;
            this.filters = Object.assign([], serverFilterObj)
            this.paginationData.pageNumber = advaceSearch ? 1 : this.neoTableSetting.pageNumber + 1
          }

        } else {
          //this.searchService.resetGlobalSearchServerFilterObj();
          serverFilterObj = this.searchService.setServerFilterObj(filterObj);
          this.filters = Object.assign([], serverFilterObj);
          this.paginationData.pageNumber = advaceSearch ? 1 : this.neoTableSetting.pageNumber + 1
        }
      } else {
        serverFilterObj = serverFilterObj.filter(res =>
          res.filterColumn == 'Mycases' ||
          res.filterColumn == 'AllCases' ||
          res.filterColumn == 'bulkOperation' ||
          res.filterColumn == 'caseFor' ||
          res.filterColumn == 'status' ||
          res.filterColumn == 'Internal' ||
          res.filterColumn == 'any'
        );
        this.searchService.serverFilterObj = serverFilterObj;
        this.filters = Object.assign([], serverFilterObj);
        this.paginationData.pageNumber = this.neoTableSetting.pageNumber + 1
      }
      this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
      this.getList(this.paginationData, this.orderingData, this.searchData);
      return serverFilterObj;
    } else {
      return null;
    }
  }

  getTableSearchSetting() {
    const searchSettings = [
      {
        display_field: 'Case No',
        value_field: 'caseNumber',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },

      {
        display_field: 'Customer Status',
        value_field: 'custStatus',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Current Assignee Name',
        value_field: 'currentAssigneeName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      // {
      //   display_field: 'Created Date',
      //   value_field: 'creationDate',
      //   control_setting: [
      //     {
      //       control_type: SearchControlTypeEnum.TEXT,
      //       display_style: '',
      //     },
      //   ],
      //   data_type: SearchDataTypeEnum.STRING,
      // },
      {
        display_field: 'User Name',
        value_field: 'userName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Customer Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Contact No',
        value_field: 'mobile',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      // {
      //   display_field: 'Status',
      //   value_field: 'caseStatus',
      //   control_setting: [
      //     {
      //       control_type: SearchControlTypeEnum.TEXT,
      //       display_style: '',
      //     },
      //   ],
      //   data_type: SearchDataTypeEnum.STRING,
      // },
      // {
      //   display_field: 'Assigned To',
      //   value_field: 'currentAssigneeName',
      //   control_setting: [
      //     {
      //       control_type: SearchControlTypeEnum.TEXT,
      //       display_style: '',
      //     },
      //   ],
      //   data_type: SearchDataTypeEnum.STRING,
      // },
    ];
    const InternalSearchSetting = [
      {
        display_field: 'Case No',
        value_field: 'caseNumber',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Current Assignee Name',
        value_field: 'currentAssigneeName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ]
    if (this.Internalcase) {
      return InternalSearchSetting;
    } else {
      return searchSettings;
    }

  }
  onCancel() {
    this.ngbService.dismissAll();
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    if (this.dataGrid.find(res => res.multiSelected === true)) {
      alert("All your selected Tickets will be removed. If you change the page");
    }
    if (this.multiSelectAll) {
      this.multiSelectAll = !this.multiSelectAll
      this.entity.ticketLists = []
      this.selectedRecord = this.entity.ticketLists.length
      this.count = this.selectedRecord
    }
    if (this.multiSelectAll) {
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = false
        }
      })
    } else {
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = true
        }
      })
    }
    this.count= 0
    this.eventdata = event;
    let paginationData = new PaginationData();
    paginationData = event;
this.paginationData =  event;
    if (this.filterObj) {
      ////console.log("filter obj::-->", this.filterObj.filters);
      let searchData = this.filterObj.filters
      this.onPaginationChange(paginationData, searchData);
    }

    if (this.filterBy === "both") {
      this.onPaginationChange(paginationData, this.status, this.filterBy);
    }
    else {
      this.onPaginationChange(paginationData, this.status, this.filterBy);
    }

  }

  // onEditClick(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onExportToExcel() {
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();
    let params: any = {};
    let searchDataObj = this.searchData && this.searchData.length > 0 ? JSON.stringify(this.searchData) : null;
    if (searchDataObj) {
      params.filters = JSON.parse(searchDataObj);
    }
    if (this.status) {
      ////console.log("status::", status);
      params.status = this.status;
    }
    if (this.filterBy) {
      ////console.log("filter::", this.filterBy);
      params.filterBy = this.filterBy;
    }
    this.caseService.getExelDownloadByfilter(params).subscribe((blob) => {
      saveAs(blob, "[case " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
    });
  }



  onEditClick(event) {
    if(event.operationDate == null){
      this.setUpdateValueInForm(event);
    }else{
      this.toastrService.error('This Ticket is already in process.');
    }

  }
  onOpenEditView(editviewmode, data) {
    if (data.operationDate == null) {
      this.editviewId = data.caseId
      this.ngbService.open(editviewmode, { size: 'xl' });
    } else {
      this.toastrService.error('This Ticket is in process.');
    }
  }

  onDeleteClick(event) {
    if (event) {
      if (event.operationDate == null) {
        this.commonDeleteMsgBox(this.caseListNeoGridSettings, event, this.afterDeleteConfirm.bind(this));
      } else {
        this.toastrService.error('This Ticket is in process.');
      }
    }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
      this.getList(this.paginationData, this.orderingData, this.searchData);
    }
  }

  setList(list: Array<CasesModel>) {
    this.dataGrid = list;
    this.dataGrid.forEach((res) => {
      res['multiSelected'] = false
    })
    this.caseListNeoGridSettings.pageTotalLength = this.totalRecords;
    this.valuepraparation();
    this.refreshDataSource(this.dataGrid);
  }
  applyFilter(filterObj, advaceSearch = false) {
    if(filterObj && filterObj.length>0)
    {
      let filters = filterObj ? JSON.parse(JSON.stringify(filterObj)) : null;
      if (this.caseListNeoGridSettings.serverSideFilter) {
         this.onFilterChange(filters, undefined, advaceSearch);
      }
    }
    else
    {
      let filters = null ? JSON.parse(JSON.stringify(null)) : null;
      if (this.caseListNeoGridSettings.serverSideFilter) {
         this.onFilterChange(filters, undefined, advaceSearch);
      }
    }

    // filterObj.filters.forEach(res=> this.filters.push(res))
    // ////console.log("adv",filterObj)
    // ////console.log("adv2",this.filters)
    // this.onFilterChange(this.filters);
    // let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    // let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    // if (filterObj && filteredDataForGrid) {
    //   this.filterObj = filterObj;
    //   let searchData: any = {
    //     filters: filters
    //   }
    //   let params: any = {};
    //   let searchDataObj = searchData && searchData.length > 0 ? JSON.stringify(searchData) : null;
    //   if (this.paginationData) {
    //     params.page = this.paginationData.pageNumber.toString();
    //     params.pageSize = this.paginationData.rowsPerPage.toString();
    //   }
    //   if (this.orderingData) {
    //     params.sortBy = this.orderingData.column;
    //     params.sortOrder = (this.orderingData.descending ? 1 : 0) + '';
    //   }
    //   if (filters.length > 0) {
    //     params.filters = filters;
    //     // this.searchData = filters;
    //   }
    //   if (this.status) {
    //     params.status = this.status;
    //   }
    //   if (this.filterBy) {
    //     params.filterBy = this.filterBy;
    //   }
    //   this.isAdvancseSearch = true;
    //   this.params = params;
    //   this.caseService.getCaseAdvanceFilter(params).subscribe((res) => {
    //     this.refreshDataSource(res.dataList);
    //     this.caseListNeoGridSettings.pageTotalLength = res.totalRecords;
    //   });
    // }
    // else {
    //   this.getList(this.paginationData, this.orderingData, this.searchData);
    // }
  }
  onAddClick() {
    if (this.Internalcase) {
      this.router.navigate(['/customer-care/cases/add-casesInternal']);
    } else {
      this.router.navigate(['/customer-care/cases/add-cases']);
    }

  }

  setUpdateValueInForm(rowData) {
    ////console.log('caseId =>', rowData.caseId);
    if (this.Internalcase) {
      this.router.navigate(['/customer-care/cases/add-casesInternal', rowData.caseId]);
    } else {
      this.router.navigate(['/customer-care/cases/add-cases', rowData.caseId]);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('caseStatus');
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.searchService.serverFilterObj = null;
  }
  valuepraparation() {
    this.dataGrid.forEach((data) => {
      this.assigneForCase.currentAssigneeName = data.currentAssigneeName;
    });
  }
  filterdata(e) {
    ////console.log("demo", e)
    ////console.log("filter--->", this.filterBy);
    if (e) {

    } else {


    }
  }

  setFilterObj(value, filterName?) {
    // if(this.dataGrid.find(res=>res.multiSelected === true)){
    //   alert("All your selected customer will be removed. If you change the page");
    // }
    // if(this.multiSelectAll){
    //   this.multiSelectAll =  !this.multiSelectAll
    //   this.entity.ticketLists = []
    //   this.selectedRecord = this.entity.ticketLists.length
    // }
    if (!this.filters || this.filters.length == 0)
      this.filters = new Array<filters>();
    else if (!value && filterName == 'teams')
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'teams')
    else if (!value && filterName == 'group')
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'group')
    else if (!value && filterName == 'casefor')
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'caseFor')
    else if (value.length == 0 && filterName == 'status')
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'status')
    else if (value === 'Mycases' && filterName === 'switch') {
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'AllCases' && ls.filterColumn != 'bulkOperation')
      this.bulkFlag = true;
    }
    else if (value === 'AllCases' && filterName === 'switch') {
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'Mycases' && ls.filterColumn != 'bulkOperation')
      this.bulkFlag = true;
    }
    else if (value === 'bulkOperation' && filterName === 'switch') {
      this.filters = this.filters.filter((ls) => ls.filterColumn != 'AllCases' && ls.filterColumn != 'Mycases')
      // this.allColumns = this.allColumns.filter(ls=> ls.colKey != 'actionCol')
      // this.getTableColumn();
      this.bulkFlag = false;
    }

    if (!this.searchService.serverFilterObj || this.searchService.serverFilterObj.length == 0)
      this.searchService.serverFilterObj = new Array<SearchData>();
    else if (!value && filterName == 'teams')
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'teams')
    else if (!value && filterName == 'group')
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'group')
    else if (!value && filterName == 'casefor')
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'caseFor')
    else if (value.length == 0 && filterName == 'status')
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'status')
    else if (value === 'Mycases' && filterName === 'switch') {
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'AllCases' && ls.filterColumn != 'bulkOperation')
      this.bulkFlag = true;
    }
    else if (value === 'AllCases' && filterName === 'switch') {
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'Mycases' && ls.filterColumn != 'bulkOperation')
      this.bulkFlag = true;
    }
    else if (value === 'bulkOperation' && filterName === 'switch') {
      this.searchService.serverFilterObj = this.searchService.serverFilterObj.filter((ls) => ls.filterColumn != 'AllCases' && ls.filterColumn != 'Mycases')
      this.bulkFlag = false;
    }
    if (this.entity && this.entity.caseStatus && this.entity.caseStatus.length > 0) {
      let temp = Object.assign([], this.entity.caseStatus)

      localStorage.setItem('caseStatus', temp.toString());
    }
    else {

      localStorage.removeItem('caseStatus')
    }

    if (this.entity && this.entity.caseForListing && this.entity.caseForListing) {
      localStorage.setItem('caseFor', this.entity.caseForListing);
    }
    else {
      localStorage.removeItem('caseFor')
    }
    if (this.entity && this.entity.teams) {
      localStorage.setItem('teams', this.entity.teams);
    }
    else {
      localStorage.removeItem('teams')
    }
    if (this.entity && this.entity.group) {
      localStorage.setItem('group', this.entity.group);
    }
    else {
      localStorage.removeItem('group')
    }
    if (value && filterName === 'switch')
      localStorage.setItem('CaseSwitch', value);
    if (value === 'Mycases') { this.entity.caseswitch = 'Mycases'; }
    if (value === 'AllCases') { this.entity.caseswitch = 'AllCases'; }
    if (value === 'bulkOperation') { this.entity.caseswitch = 'bulkOperation'; }
    this.filtersObj = new filters();
    this.filtersObj.filterColumn = 'Internal';
    this.filtersObj.filterCondition = null;
    this.filtersObj.filterDataType = null;
    this.filtersObj.filterOperator = null;
    this.filtersObj.filterValue = this.Internalcase ? 'include' : 'exclude';
    this.filters.push(this.filtersObj);
    this.filtersObj = new filters();
    this.filtersObj.filterColumn = checkSwitch(this.entity);
    this.filtersObj.filterCondition = null;
    this.filtersObj.filterDataType = null;
    this.filtersObj.filterOperator = null;
    this.filtersObj.filterValue = null;
    this.filters.push(this.filtersObj);
    this.filtersObj = new filters();
    if (this.entity && this.entity.caseStatus && this.entity.caseStatus.length > 0) {

      let temp = Object.assign([], this.entity.caseStatus)
      this.filtersObj.filterColumn = 'status';
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = temp.toString();
      this.filters.push(this.filtersObj);
    }
    this.filtersObj = new filters();
    if (this.entity && this.entity.teams) {
      this.filtersObj.filterColumn = 'teams';
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = this.entity.teams;
      this.filters.push(this.filtersObj);
    }
    this.filtersObj = new filters();
    if (this.entity && this.entity.group) {
      this.filtersObj.filterColumn = 'group';
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = this.entity.group;
      this.filters.push(this.filtersObj);
    }
    this.filtersObj = new filters();
    ////console.log("case Listing:",this.entity.caseForListing)
    if (this.entity && this.entity.caseForListing && this.entity.caseForListing) {
      this.filtersObj.filterColumn = 'caseFor';
      this.filtersObj.filterCondition = null;
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = this.entity.caseForListing;
      this.filters.push(this.filtersObj);
    }

    this.searchService.removeCaseSpecificStatusColumn();
    this.onFilterChange(this.filters, true);
  }

  public onValChange(value: string) {
    localStorage.setItem('CaseSwitch', value);

    if (value === 'Mycases') {
      this.entity.caseswitch = 'Mycases';
    }
    if (value === 'AllCases') {
      this.entity.caseswitch = 'AllCases';
    }
    if (value === 'bulkOperation') {
      this.entity.caseswitch = 'bulkOperation';
    }

    this.filtersObj.filterColumn = value;
    this.filtersObj.filterCondition = null;
    this.filtersObj.filterDataType = null;
    this.filtersObj.filterOperator = null;
    this.filtersObj.filterValue = null;
    this.filters.push(this.filtersObj);
    ////console.log("demo",this.filters);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'userlive') {
        col.customControls = [this.livestatusRef];
      }
      if (col.colKey === 'caseStatus') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey === 'caseForServer') {
        col.customControls = [this.caseForServerWrapperRef];
      }
      if (col.colKey === 'currentAssigneeName') {
        col.customControls = [this.AssigneToMe];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
        if (this.multipleOptionsList.length == 0) {
          col.isMultipleOption = false
        }
      }
      if (col.colKey === 'userName') {
        col.customControls = [this.editUserNameREf];
      }
      
      // if (col.colKey === 'customerName') {
      //   col.customControls = [this.customerNameRefff];
      // }
      if (col.colKey === 'caseNumber') {
        col.customControls = [this.ticketNoRef];
      }
      if (col.colKey === 'fulladdress') {
        col.customControls = [this.addressWrapperReff];
      }
      if (col.colKey === 'circuitid') {
        col.customControls = [this.circuitWrapperReff];
      }
      if (col.colKey === 'caseTitle') {
        col.customControls = [this.ticketTitleReff];
      }
      if (col.colKey === 'multiSelect') {
        col.customControls = [this.multiSelectRef];
      }
      if(col.colKey === 'ConsumptionCol'){
        col.customControls = [this.viewBtnWrapperRef];
      }
    });
  }

  updateFormSubmit() {
    this.submitted = true
    this.updateListData.resolutionId = this.entity.finalResolutionId;
    this.updateListData.suspendDate = this.datePipe.transform(this.entity.suspendDate, 'yyyy-MM-dd');
    this.updateListData.filename = "abc"

    if (this.entity.caseId) {
      this.updateListData.ticketId = this.entity.caseId;
    }
   // method - 1
    if (this.entity.ticketLists) {
      this.updateListData['ticketIdList'] = this.entity.ticketLists;
    }
  // method - 2
    // if (this.entity.ticketLists) {
    //   this.updateListData['ticketIdList'] = this.entity.ticketLists.filter(x=>!x.isETPL).map(x=>x.caseId);
    // }
    this.updateListData.reasonId = this.entity.caseReasonId;
    this.updateListData.status = this.entity.caseStatus;
    this.updateListData.priority = this.entity.priority;
    this.updateListData.commentBy = 'Staff';
    this.updateListData.title = this.entity.caseTitle;
    this.updateListData.serviceAreaId = this.entity.serviceAreaId;
    this.updateListData.teamId = this.entity.teamId;
    this.updateListData.groupId = this.entity.groupId;
    this.updateListData.assignee = this.entity.currentAssigneeId;
    this.updateListData.teamType = this.entity.teamType;
    this.updateListData.amount = this.entity.amount;
    this.updateListData.receipt_no = this.entity.receipt_no;
    this.updateListData.issue = this.entity.issue;
    this.updateListData.solution = this.entity.solution;
    this.updateListData.allocationType = this.entity.allocationType;
    this.updateListData.algorithmType = this.entity.algorithmType;
    this.updateListData.chargableType = this.entity.chargableType;
    if (this.selectedRecord == this.totalRecords) {
      this.updateListData['filters'] = this.filters;
      this.updateListData['page'] = this.paginationData.pageNumber;
      this.updateListData['pageSize'] = this.selectedRecord;
    }
    if (this.entity.ticketLists) {
      //   let temp = this.paginationData
      // console.log('bnb',temp)
      this.caseService.updateBulkList(this.updateListData, this.entity.ticketLists.toString()).subscribe((res) => {
        if (res.responseCode == 200) {
          this.toastrService.success('Cases Update Successfully');
          this.getList(this.paginationData, this.orderingData, this.searchData);
          this.setDataFromLocalStorage();
          this.multiSelectAll = false;
          this.selectedRecord = 0;
          this.count = this.selectedRecord;
          this.allColumns.forEach((col) => {
            if (col.colKey == 'actionCol') {
              col.isMultipleOption = true
            }
          })
        }
        else {
          this.toastrService.error(res.responseMessage)
        }
      });
    } else {
      this.caseService.updateList(this.updateListData).subscribe((res) => {
        if (res.responseCode == 200) {
          this.toastrService.success('Case Update Successfully');
          this.getList(this.paginationData, this.orderingData, this.searchData);
          this.setDataFromLocalStorage();
        }
        else {
          this.toastrService.error(res.responseMessage)
        }
      });
    }


    this.ngbService.dismissAll();
  }

  // getupdateListData() {
  //   if (this.entity && this.entity.caseUpdateList && this.entity.caseUpdateList.length > 0) {
  //     this.entity.caseUpdateList.forEach((res) => {
  //       res.updateDetails.forEach((x) => {
  //         if (x.entitytype === "Remarks") {
  //           res.remark = x.newvalue;

  //         }
  //         if (x.remarktype === "Public") {
  //           res.remarkType = x.remarktype;
  //         }
  //         if (x.remarktype === "Private") {
  //           res.remarkType = x.remarktype;
  //         }
  //       });

  //       let newDate = new Date(res.createDateString);
  //       res.calculatedDay = this.calculateDiff(newDate, 'caseDay');
  //       if (res.calculatedDay === 0)
  //         res.calculatedTimeH = this.calculateDiff(newDate, 'caseHours');
  //       if (res.calculatedTimeH === 0)
  //         res.calculatedTimeM = this.calculateDiff(newDate, 'caseMinutes');
  //       if (res.calculatedTimeM === 0) {
  //         res.calculatedTimeS = this.calculateDiff(newDate, 'caseSecond');
  //       }
  //     });
  //   }
  // }

  // calculateDiff(ms, lable: string) {
  //   let currentDate = new Date();
  //   ms = new Date(ms);
  //   if (currentDate && ms) {
  //     ms = currentDate.getTime() - ms.getTime();
  //     let seconds = Math.floor(ms / 1000);
  //     let minutes = Math.floor(seconds / 60);
  //     let hours = Math.floor(minutes / 60);
  //     let days = Math.floor(hours / 24);
  //     if (lable === 'caseHours') {
  //       return hours;
  //     }
  //     if (lable === 'caseDay') {
  //       return days;
  //     }
  //     if (lable === 'caseMinutes') {
  //       return minutes;
  //     }
  //     if (lable === 'caseSecond') {
  //       return seconds;
  //     }
  //   }
  // }

  refreshData(event) {
    this.entity = event;
    this.updateFormSubmit();
  }

  // openUpdate(update,data) {
  //  this.getCaseById(data.caseId);
  //   this.updateListData = new Updatelistmodel();
  //   this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
  //     res.dataList.forEach((x) => {
  //       if (x.value === "external") {
  //         this.updateListData.remarkType = x.text;
  //       }
  //     });
  //   });
  //   setTimeout(() => {
  //     this.ngbService.open(update, { size: 'lg' });
  //    }, 500);
  // }
  // openUpdateprivate(update,data) {
  //   this.getCaseById(data.caseId);
  //   this.updateListData = new Updatelistmodel();
  //   this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
  //     res.dataList.forEach((x) => {
  //       if (x.value === "internal") {
  //         this.updateListData.remarkType = x.text;
  //       }
  //     });
  //   });
  //   setTimeout(() => {
  //     this.ngbService.open(update, { size: 'lg' });
  //    }, 500);
  // }

  onEdit(event) {
    this.customerId = event.customersId;
    if (event.caseFor == 'Lease Line') {
      this.viewLeaselineCustomerdetail();
    } else if ((event.caseFor == 'Customer')) {
      this.viewCustomerdetail();
    }else if ((event.caseFor == 'Base Setup')) {
      this.viewBaseSetupCustomerdetail();
    }
  }

  viewCustomerdetail() {
    this.router.navigate(['masters/subscriber/details', this.customerId]);
  }

  viewLeaselineCustomerdetail() {
    this.router.navigate(['masters/suscriberLeased-details', this.customerId]);
  }

  viewBaseSetupCustomerdetail() {
    this.router.navigate(['masters/suscriberLeased-details', this.customerId]);
  }


  onAssigneToMe(e) {
    this.entityService.caseAssigneToMe(e).subscribe((res) => {
      if (res.responseCode === 200) {
        this.getList(this.paginationData, this.orderingData, this.searchData);
      }
      else {
        this.toastrService.error(res.responseMessage);
      }
    });
  }

  getquickCaseType() {
    this.caseService.getquickCaseType().subscribe((res) => {

      // res.dataList.forEach((res) =>{
      //   this.quickCaseType = res.value;
      // })
      if (this.Internalcase) {
        this.quickCaseType = res.dataList.filter(res => res.value === 'Internal');

      } else {
        this.quickCaseType = res.dataList.filter(res => res.value !== 'Internal');
      }
      this.quickCaseType.forEach(res => {
        //this.quick = res.value;
        this.quick.push(res.value);
      });
      ////console.log("quickcase",this.quick);
    });
  }

  getTeams() {
    this.caseService.getteams().subscribe((res) => {
      this.teams = res.dataList;
    });
  }

  getGroup() {
    this.caseService.getgroup().subscribe((res) => {
      this.groupList = res.dataList
    });
  }

  onMultipleOptionClick(event) {
    this.caseService.getData(event.RowData.caseId)
    .then((response) => {
      this.updateData = response.data;
      this.updateListData = new Updatelistmodel();
      if (event.RowData.operationDate == null) {
        if (event.optionData.key == 'caseUpdate') {
          this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
            res.dataList.forEach((x) => {
              if (x.value === "external") {
                this.updateListData.remarkType = x.text;
              }
            });
            this.ngbService.open(this.updateReff, { size: 'lg' });
          });
        }
        if (event.optionData.key == 'casePrivateUpdate') {
          this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
            res.dataList.forEach((x) => {
              if (x.value === "internal") {
                this.updateListData.remarkType = x.text;
              }
            });
            this.ngbService.open(this.updateReff, { size: 'lg' });
          });
        }
      } else {
        this.toastrService.error('This Ticket is already in process.')
      }
    })
    .catch((error) => {
      console.error(error);
    });
    //console.log('date',event.RowData.operationDate);
  }


  onClickPopup(e) {
    this.updateData = new CasesModel();
    this.updateListData = new Updatelistmodel();
    if (this.selectedRecord > 0) {
      if (e == 'update') {
        this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
          res.dataList.forEach((x) => {
            if (x.value === "external") {
              this.updateListData.remarkType = x.text;
            }
          });
        });
        setTimeout(() => {
          this.ngbService.open(this.bulkupdateReff, { size: 'lg' });
        }, 500);
      }
      if (e == 'pupdate') {
        this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
          res.dataList.forEach((x) => {
            if (x.value === "internal") {
              this.updateListData.remarkType = x.text;
            }
          });
        });
        setTimeout(() => {
          this.ngbService.open(this.bulkupdateReff, { size: 'lg' });
        }, 500);
      }
    }
    else {
      this.toastrService.error("Please select atleast one ticket");
    }
  }

  onCustSelect(data){
    //this.getTableColumn();
    //console.log('s',data)
    this.dataGrid.forEach((res)=>{
      if(res.caseId === data.caseId){
        res.multiSelected = !res.multiSelected
        if(res.multiSelected === false){
          let temp = this.entity.ticketLists.indexOf(res.caseId)
          this.entity.ticketLists.splice(temp,1);
          this.multiSelectAll = !this.multiSelectAll
        }else{
          // Method -1
          // this.entity.ticketLists.push(data.caseId);
          this.entity.ticketLists.push({caseId:data.caseId, isETPL: data.caseForServer == "ETPL"});
        }
      }else{
        this.multiSelectAll = false
      }
    })
    let temp = false;
    for (let i = 0; i < this.dataGrid.length; i++) {
      if (this.dataGrid[i].multiSelected == true) {
          temp = true;
          break;
      }
    }
    if(temp){
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = false
          this.multiSelectAll = false
        }
      })
    }else{
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = true
          //this.multiSelectAll = true
        }
      })
    }
    this.count=0
    this.dataGrid.forEach((res)=>{
      
      if(res.multiSelected == true){
        this.count=this.count+1
      }
      console.log('checkboxcount',this.count)
    })
    //console.log('single',this.entity.ticketLists)
    //console.log("length:",this.expired.customerLists.length)
    this.selectedRecord = this.entity.ticketLists.length
    this.refreshDataSource(this.dataGrid)
    
  }

  onAllOnePageUpdate(){
    if(this.multiSelectAll){
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = false
        }
      })
    }else{
      this.allColumns.forEach((col) => {
        if (col.colKey == 'actionCol') {
          col.isMultipleOption = true
        }
      })
    }
    this.entity.ticketLists = []
    if(this.multiSelectAll){
      this.dataGrid.forEach(res=>{
          // Method -1
        if(res.caseForServer != "ETPL"){
          res.multiSelected = true;
          this.entity.ticketLists.push(res.caseId);
        }
           // Method -2
          // res.multiSelected = true;
          // this.entity.ticketLists.push({caseId:res.caseId, isETPL: res.caseForServer == "ETPL"});
        //console.log('rees',this.entity.ticketLists)
      })
      this.selectedRecord = this.entity.ticketLists.length
      this.refreshDataSource(this.dataGrid)
    }
    else{
      this.entity.ticketLists = []
      this.dataGrid.forEach(res=>{
        res.multiSelected = false;  
        })
        this.refreshDataSource(this.dataGrid)
      this.selectedRecord = this.entity.ticketLists.length
    }
    this.count =this.selectedRecord
  }

  onSelectAll(){
    this.dataGrid.forEach(res=>{
      // Metohd - 1
      if(res.caseForServer != "ETPL")
        res.multiSelected = true;
      //this.entity.ticketLists.push(res.caseId);
      //console.log('reesAll',this.entity.ticketLists)
    })
    this.entity.ticketLists = [];
    this.selectedRecord = this.totalRecords;
    this.count = this.selectedRecord
    //this.entity.renewBy = null; 
    //this.updateListData = new Updatelistmodel();
  }
  
  // redirectToETPL(caseId: number) {
  //   const destinationPortalUrl = `${this.appConfig.config.etplUrl}/#/customer-care/cases/add-cases/${caseId}`; 
  //   window.open(destinationPortalUrl, '_blank');
  // }
}
export class filters {
  filterColumn: string
  filterCondition: string
  filterDataType: string
  filterOperator: string
  filterValue: string
}

function checkSwitch(entity) {
  if (entity.caseswitch == 'AllCases') {
    return 'AllCases';
  }
  if (entity.caseswitch == 'Mycases') {
    return 'Mycases';
  }
  if (entity.caseswitch == 'bulkOperation') {
    return 'bulkOperation';
  }
}

