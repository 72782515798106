import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private http: HttpClient) { }

  transformCityData(data) {
    let formatedData = [];
    data.cityList.forEach(function (item: any) {
      let itemData: any = {};
      itemData.city_id = item.id;
      itemData.city_name = item.name;
      itemData.status = item.status;
      itemData.state_id = item.statePojo.id;
      itemData.state_name = item.statePojo.name;
      itemData.country_id = item.statePojo.countryPojo.id;
      itemData.country_name = item.statePojo.countryPojo.name;
      itemData.updatedate = item.updatedate; 
      formatedData.push(itemData);
    });
    return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }
  transformCityById(data) {
    let itemData: any = {};
    let item = data.cityList;
    itemData.city_id = item.id;
    itemData.city_name = item.name;
    itemData.status = item.status;
    itemData.state_id = item.statePojo.id;
    itemData.state_name = item.statePojo.name;
    itemData.country_id = item.statePojo.countryPojo.id;
    itemData.country_name = item.statePojo.countryPojo.name;
    return itemData;
  }

  getCityDataWithPageing(params: HttpParams): Observable<any> {
    const get_url = BASE_API_URL + 'city/list';

    return this.http
      .post<any>(get_url, params, { headers: httpOptions.headers })
      .pipe(
        map((res: any) => {
          return this.transformCityData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCityListById(city_id): Observable<any> {
    const get_url = BASE_API_URL + 'city/' + city_id;
    return this.http
      .get<any>(get_url, { headers: httpOptions.headers })
      .pipe(
        map((res: any) => {
          return this.transformCityById(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformCityListByStateId(data, state_id) {
    let formatedData = [];
    data.cityList.forEach(function (item: any) {
      if (item.statePojo.id == state_id) {
        let itemData: any = {};
        itemData.city_id = item.id;
        itemData.city_name = item.name;
        itemData.status = item.status;
        itemData.state_id = item.statePojo.id;
        itemData.state_name = item.statePojo.name;
        itemData.country_id = item.statePojo.countryPojo.id;
        itemData.country_name = item.statePojo.countryPojo.name;
        formatedData.push(itemData);
      }
    });
    return { content: formatedData };
  }
getcityList(){
  const get_url = BASE_API_URL + 'city/all';
  return this.http
    .get<any>(get_url, { headers: httpOptions.headers })
}
  getCityListByStateId(state_id?: any): Observable<any> {
    // const get_url = baseApiUrl + 'city/'+state_id;
    const get_url = BASE_API_URL + 'city/all';
    return this.http
      .get<any>(get_url, { headers: httpOptions.headers })
      .pipe(
        map((res: any) => {
          return this.transformCityListByStateId(res, state_id);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertCity(city_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'city';
    return this.http.post<any>(post_url, city_data, httpOptions).pipe(
      map((data) => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updateCity(city_data: any, city_id: any): Observable<any> {
    const update_url = BASE_API_URL + 'city/' + city_id;
    return this.http.put<any>(update_url, city_data, httpOptions).pipe(
      map((data) => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteCity(city_id: any): Observable<any> {
    const delete_url = BASE_API_URL + 'city/' + city_id;
    return this.http.delete(delete_url, httpOptions);
  }
}
