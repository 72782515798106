import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';

import { UpdatePaymentService } from './update-payment-details.service';
import { ReversePaymentService } from '../reverse-payment/reverse-payment.service';

import { PaymentModeService } from '../../../../services/payment_mode/payment_mode.service';

import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-update-payment-details',
  templateUrl: './update-payment-details.component.html',
  styleUrls: ['./update-payment-details.component.scss']
})
export class UpdatePaymentDetailsComponent implements OnInit {

  @Input() custId: any;
  @Input() bal : any;
  req_message = "This field is required.";
  submitted: any = null;
  tdsAmountValid: boolean;
  chequeNoValid: boolean;
  bankNameValid: boolean;
  chequeDateValid: boolean;
  branchValid: boolean;
  selected_payment_mode_value: any;
  selected_tds_deducted: any;

  public paymentModeList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  public paymentsList: any[] = [
    {
      display_text: '',
      id: '',
    },
  ];
  updatePaymentForm = new FormGroup({
    payment: new FormControl(null,[Validators.required]),
    new_payment_mode: new FormControl(null,[Validators.required]),
    payment_date: new FormControl('',[Validators.required]),
    referenceNo: new FormControl('',[Validators.required]),
    chequeNo: new FormControl(''),
    bankName: new FormControl(''),
    chequeDate: new FormControl(''),
    branch: new FormControl(''),
    remarks: new FormControl('',[Validators.required])
  })

  constructor(
    private customModalService: CustomModalService,
    private cdRef: ChangeDetectorRef,
    private updatePaymentService: UpdatePaymentService,
    private paymentModeService: PaymentModeService,
    private reversePaymentService: ReversePaymentService,
    private tosterservice : ToastrService

  ) {

  }

  ngOnInit() {
    this.getPaymentModeListFromGenericApi();
    this.getAllPaymentsByCustomerId();
  }

  getAllPaymentsByCustomerId() {
    let subscriber_id = this.custId;
    this.reversePaymentService.getAllPaymentsByCustomerId(subscriber_id).subscribe((result) => {
      this.paymentsList = result.dataList;
    });
  }

  onChangeSelectPayment(el) {
    ////console.log(el);
    
    if (el) {
      let selectedPayment = _.find(this.paymentsList,{id:el.id});
      let paymentMode = _.find(this.paymentModeList,{value:selectedPayment.paymode});
      ////console.log(paymentMode);
      let data = {
        chequeNo: selectedPayment.chequeNo,
        bankName: selectedPayment.bankName,
        chequeDate: selectedPayment.chequedate,
        branch: selectedPayment.branch,
        new_payment_mode: paymentMode.value,
        payment_date:selectedPayment.paymentdate,
        referenceNo:selectedPayment.referenceno,
      };
      this.selected_payment_mode_value = paymentMode.value;
      this.updatePaymentForm.patchValue(data);
    }
  }

  getPaymentModeListFromGenericApi() {
    this.paymentModeService.getPaymentModeListFromGenericApi().subscribe(result => {
      this.paymentModeList = result.dataList;
    });
  }

  onChangePaymentMode(el) {
    if (el) {
      let payment_mode_value = el.value;
      this.selected_payment_mode_value = payment_mode_value;
      if(payment_mode_value === 'cheque'){
        this.updatePaymentForm.get('chequeNo').setValidators(Validators.required);
        this.updatePaymentForm.get('chequeNo').updateValueAndValidity();
        this.updatePaymentForm.get('bankName').setValidators(Validators.required);
        this.updatePaymentForm.get('bankName').updateValueAndValidity();
        this.updatePaymentForm.get('chequeDate').setValidators(Validators.required);
        this.updatePaymentForm.get('chequeDate').updateValueAndValidity();
        this.updatePaymentForm.get('branch').setValidators(Validators.required);
        this.updatePaymentForm.get('branch').updateValueAndValidity();
      }
      else{
        this.updatePaymentForm.get('chequeNo').reset();
        this.updatePaymentForm.get('chequeNo').updateValueAndValidity();
        this.updatePaymentForm.get('bankName').reset();
        this.updatePaymentForm.get('bankName').updateValueAndValidity();
        this.updatePaymentForm.get('chequeDate').reset();
        this.updatePaymentForm.get('chequeDate').updateValueAndValidity();
        this.updatePaymentForm.get('branch').reset();
        this.updatePaymentForm.get('branch').updateValueAndValidity();
      }
    } else {
      this.selected_payment_mode_value = '';
    }
  }

  onClickUpdatePaymentForm() {
    
    if (this.updatePaymentForm.valid === true) {
      let formValue = Object.assign({}, this.updatePaymentForm.value);
      let payment_mode_value = formValue.new_payment_mode;
      let selected_payment_mode = _.find(this.paymentModeList, { value: payment_mode_value }) || {};
      let payment_mode = selected_payment_mode.value || '';
      if (payment_mode === '') {
        this.submitted = true;
        this.updatePaymentForm.markAllAsTouched();
        return false;
      } else {

        if (payment_mode === "cheque") {
          if (formValue.chequeNo.length === 0) {
            this.chequeNoValid = false;
          } else {
            this.chequeNoValid = true;
          }
          if (formValue.bankName.length === 0) {
            this.bankNameValid = false;
          } else {
            this.bankNameValid = true;
          }
          if (formValue.chequeDate.length === 0) {
            this.chequeDateValid = false;
          } else {
            this.chequeDateValid = true;
          }
          if (formValue.branch.length === 0) {
            this.branchValid = false;
          } else {
            this.branchValid = true;
          }
          if (formValue.chequeNo.length === 0 || formValue.bankName.length === 0 || formValue.chequeDate.length === 0 || formValue.branch.length === 0) {
            this.submitted = true;
            this.updatePaymentForm.markAllAsTouched();
            return false;
          }
        }

        this.submitted = false;
        let self = this;
        alertify.confirm("Update Payment Details", "Are You Sure You want to Update Payment Details ?",
          function() {
            self.saveApiCall();
          },
          function() {

        });

      }
    } else {
      this.submitted = true;
      this.updatePaymentForm.markAllAsTouched();
      this.tosterservice.error("Please Fill All Required Feild");

    }
  }




  saveApiCall() {
    let apiInputData = Object.assign({}, this.updatePaymentForm.value);

    let formatedData: any = {};
    formatedData.custId = this.custId;
    formatedData.paymentDate = apiInputData.payment_date;
    //formatedData.paymentDate = JSON.stringify(apiInputData.payment_date.getToJSON());
    formatedData.referenceNo = apiInputData.referenceNo;

    let selectedPaymentModeData = _.find(this.paymentModeList, { value: apiInputData.new_payment_mode });
    formatedData.paymentMode = selectedPaymentModeData.value;
    if (selectedPaymentModeData.value === 'cheque') {
      formatedData.bankName = apiInputData.bankName;
      formatedData.branch = apiInputData.branch;
      formatedData.chequeDate = apiInputData.chequeDate;
      formatedData.chequeNo = apiInputData.chequeNo;
    }

    formatedData.redit_doc_id = null;
    formatedData.tdsAmount = null;
    formatedData.isTdsDeducted = false;
    formatedData.remarks = apiInputData.remarks;

    this.updatePaymentService.updatePayment(formatedData, apiInputData.payment).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Update Payment Save Successfully.');
          this.customModalService.close('update-payment', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }
}
