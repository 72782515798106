import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { BasicDetailsService } from './basic-details.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service'
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit {

  @Input() custId: any;
  cafNo: any;
  firstname:any;
  middlename:any;
  lastname:any;
  Gst:any;
  invalidCafNoAsPerApi: boolean = false;
  invalidCafNoAsPerApiMsg = '';
  req_message = 'This field is required';
  commonYN:any;
  titleList: any[] = [{
    text: '',
    value: ''
  }];
  insertBasicDetailsForm = new FormGroup({
    aadharNumber: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")]),
    contactPerson: new FormControl(''),
    gstenabled: new FormControl(null, Validators.required),
    gst: new FormControl(null),
    firstname: new FormControl(null),
    middlename: new FormControl(null),
    lastname: new FormControl(null),
    pan: new FormControl(''),
    remarks: new FormControl('', Validators.required),
    cafno: new FormControl(null),
    title: new FormControl('', [Validators.required])
  })
  selectForUpdate: any;
  basicDetails: any;
  submitted: boolean;
  block_House_No:any;
  property_Type:any;

  constructor(
    private customModalService: CustomModalService,
    private basicDetailsService: BasicDetailsService,
    private cdRef: ChangeDetectorRef,
    private tosterservice: ToastrService,
    private addSubscriber: AddSubscriberDetailsService,
    private commonListService: CommonListServicesService,
  ) {
    this.getRequiredAllField();
  }

  ngOnInit() {
    this.getBasicDetails();
    this.getcommonYN();
  }

  getcommonYN(){
    this.commonListService.getCommonListForYN().subscribe((res)=>{
      this.commonYN=res.dataList
          });
  }

  setUpdateValueInForm(basicDetails_data) {
    this.selectForUpdate = basicDetails_data;
    
    this.insertBasicDetailsForm.patchValue(basicDetails_data);
  }

  getBasicDetails() {
    this.basicDetailsService.getBasicDetails(this.custId).subscribe(result => {
      this.titleList = result.data.titleList;
      this.basicDetails = result.data;
      this.setUpdateValueInForm(this.basicDetails);
    })
  }
  getRequiredAllField() {
    this.addSubscriber.getRequiredAllField().subscribe(res => {
      if (res.responseCode === 200) {
        let createDynamicValidation = res.data;
        this.firstname = createDynamicValidation.find((e => e.fieldname === 'firstname'));
        this.middlename = createDynamicValidation.find((e => e.fieldname === 'middlename'));
        this.lastname = createDynamicValidation.find((e => e.fieldname === 'lastname'));
        this.cafNo = createDynamicValidation.find((e => e.fieldname === 'caf_no'));
        this.Gst = createDynamicValidation.find((e => e.fieldname === 'gst'));
        
        

        if ((this.firstname !== null && this.firstname !== undefined) && this.firstname.isMandatory === true) {
          this.insertBasicDetailsForm.get('firstname').setValidators(Validators.required)
          this.insertBasicDetailsForm.get('firstname').updateValueAndValidity();
        }
        else {
          this.insertBasicDetailsForm.get('firstname').clearValidators()
          this.insertBasicDetailsForm.get('firstname').updateValueAndValidity();
        }

        if ((this.middlename !== null && this.middlename !== undefined) && this.middlename.isMandatory === true) {
          this.insertBasicDetailsForm.get('middlename').setValidators(Validators.required)
          this.insertBasicDetailsForm.get('middlename').updateValueAndValidity();
        }
        else {
          this.insertBasicDetailsForm.get('middlename').clearValidators()
          this.insertBasicDetailsForm.get('middlename').updateValueAndValidity();
        }

        if ((this.lastname !== null && this.lastname !== undefined) && this.lastname.isMandatory === true) {
          this.insertBasicDetailsForm.get('lastname').setValidators(Validators.required)
          this.insertBasicDetailsForm.get('lastname').updateValueAndValidity();
        }
        else {
          this.insertBasicDetailsForm.get('lastname').clearValidators()
          this.insertBasicDetailsForm.get('lastname').updateValueAndValidity();
        }

        if ((this.cafNo !== null && this.cafNo !== undefined) && this.cafNo.isMandatory === true) {
          this.insertBasicDetailsForm.get('cafno').setValidators([Validators.required]),
            this.insertBasicDetailsForm.get('cafno').updateValueAndValidity()
        } else {
          this.insertBasicDetailsForm.get('cafno').clearValidators(),
            this.insertBasicDetailsForm.get('cafno').updateValueAndValidity()
        }
        

      }
    })
  }

  onChangeGst(event){
    this.insertBasicDetailsForm.get('gst').reset();
    if (event.value === 'Yes' && this.Gst.isMandatory === true) {
      this.insertBasicDetailsForm.get('gst').setValidators(Validators.required);
      this.insertBasicDetailsForm.get('gst').updateValueAndValidity();
    }
    else if (event.value !== 'Yes') {
      this.insertBasicDetailsForm.get('gst').clearValidators();
      this.insertBasicDetailsForm.get('gst').updateValueAndValidity();
    }
  }

  onCLearGst() {
    this.insertBasicDetailsForm.get('gst').reset();
    this.insertBasicDetailsForm.get('gst').clearValidators();
    this.insertBasicDetailsForm.get('gst').updateValueAndValidity();
  }

  clearMsgCafNo() {
    if (this.invalidCafNoAsPerApi === true &&
      this.insertBasicDetailsForm.get('cafno').valid === false) {
      this.invalidCafNoAsPerApiMsg = '';
    }
  }
  checkUniqueCustomerByCafno(el) {
    if (this.insertBasicDetailsForm.get('cafno').value !== null && this.cafNo.allowDuplicate === true) {

      let value = el.target.value;
      if (value && value.length !== 0) {
        this.invalidCafNoAsPerApi = false;
        this.addSubscriber.checkUniqueCustomerByCafno(value, this.custId).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidCafNoAsPerApi = true;
            this.invalidCafNoAsPerApiMsg = result.responseMessage;
          } else {
            this.invalidCafNoAsPerApi = false;
            this.invalidCafNoAsPerApiMsg = '';
          }
        });
      } else {
        this.invalidCafNoAsPerApi = false;
        this.invalidCafNoAsPerApiMsg = '';
      }
    }
  }
  checkUniqueCafNo_onSave() {
    if (this.insertBasicDetailsForm.get('cafno').value !== null && this.cafNo.allowDuplicate === true) {
      let cafno = this.insertBasicDetailsForm.get('cafno').value
      this.basicDetailsService.checkUniqueCustomerByCafno(cafno,this.custId).subscribe(res => {
        if (res.responseCode !== 200) {
          this.invalidCafNoAsPerApi = true;
          this.invalidCafNoAsPerApiMsg = res.responseMessage;
        } else {
          this.invalidCafNoAsPerApi = false;
          this.invalidCafNoAsPerApiMsg = '';
        }
      })
    } else {
      this.invalidCafNoAsPerApi = false;
      this.invalidCafNoAsPerApiMsg = '';
    }
  }
  onClickSaveForm() {
    this.checkUniqueCafNo_onSave();
    setTimeout(() => {
      if (this.insertBasicDetailsForm.valid === true && !this.invalidCafNoAsPerApi) {
        let self = this;
        alertify.confirm("Basic Details", "Are You Sure You want to Change Basic Details ?",
          function () {
            self.insertBasicDetailsOnDb();
          },
          function () {

          });
      } else {
        this.submitted = true;
        this.insertBasicDetailsForm.markAllAsTouched();
      }
    }, 600);
  }

  insertBasicDetailsOnDb() {
    if (this.insertBasicDetailsForm.valid === true) {
      let formatedInputData: any = this.insertBasicDetailsForm.value;
      formatedInputData.custId = this.custId;
      this.basicDetailsService.insertBasicDetails(formatedInputData).subscribe(result => {
        this.cdRef.detectChanges();
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Basic Detail Update successfully');
          this.customModalService.close('basic-details', result);
        }
      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.submitted = false;
  }

}
