import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import _ from 'underscore';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient) {

    }
    // getAllEventList(): Observable<any> {
    //     const get_url = BASE_API_URL + "notification/getAllEvent";
    //     return this.http.get<any>(get_url);
    // }
    saveNotification(notification_data): Observable<any> {
        const post_url = BASE_API_URL + 'notification/save';
        return this.http.post<any>(post_url, notification_data, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    updateNotification(notification_data): Observable<any> {
        const post_url = BASE_API_URL + 'notification/update';
        return this.http.post<any>(post_url, notification_data, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    getNotificationGroupDataWithPageing(params: HttpParams): Observable<any> {
        const post_url = BASE_API_URL + 'notificationGroup';

        return this.http.post<any>(post_url, params, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
getAssociatedPartnerByNotificationGroup(groupid){
    const get_url = BASE_API_URL + 'notificationGroup/associateWithPartner/'+ groupid;
    return this.http.get<any>(get_url, { headers: httpOptions.headers })
}
    transformBillingTemplateData(data) {
        let formatedData = [];
        if (_.isArray(data.billRunlist) === false) {
            let itemData: any = {};
            itemData.id = data.billRunlist.id;
            itemData.templatename = data.billRunlist.templatename;
            itemData.templatetype = data.billRunlist.templatetype;
            itemData.status = data.billRunlist.status;
            itemData.jrxmlfile = data.billRunlist.jrxmlfile;
            return { content: itemData };
        } else {
            data.billRunlist.forEach(function (item: any) {
                let itemData: any = {};
                itemData.billing_id = item.id;
                itemData.templatename = item.templatename;
                itemData.templatetype = item.templatetype;
                itemData.status = item.status;
                itemData.jrxmlfile = item.jrxmlfile;
                formatedData.push(itemData);
            });
            return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
        }
    }

    getNotificationById(billing_id: any): Observable<any> {
        const get_url = BASE_API_URL + 'notification/' + billing_id;
        return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    NotificationById(notification_id: any): Observable<any> {
        const get_url = BASE_API_URL + 'notification/' + notification_id;
        return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
      commonStatus(): Observable<any> {
        const get_url = BASE_API_URL + "commonList/commonStatus";
        return this.http.get(get_url);
    }
    getAllEventList(): Observable<any> {
        const get_url = BASE_API_URL + "notification/getAllEvent";
        return this.http.get<any>(get_url);
    }

    notificationGroupAll():Observable<any>{
        const get_url=BASE_API_URL +"notificationGroup/all";
        return this.http.get(get_url)
    }
  
    
    saveGroupNotification(notification_Groupdata): Observable<any> {
        const post_url = BASE_API_URL + 'notificationGroup/save';
        return this.http.post<any>(post_url, notification_Groupdata, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    updateGroupNotificationData(notificationGroup_data): Observable<any> {
        const post_url = BASE_API_URL + 'notificationGroup/update';
        return this.http.post<any>(post_url, notificationGroup_data, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
    
    NotificationGroupgetDataByID(group_id: any): Observable<any> {
        const get_url = BASE_API_URL + 'notificationGroup/' + group_id;
        return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    NotificationGroupgetDataByParamID(group_Obj): Observable<any> {
       
        const get_url = BASE_API_URL + 'notification/notificationListFromGroup';
        return this.http.post<any>(get_url,group_Obj,{ headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    getBroadcastNotificationByGrpId(grpId): Observable<any> {

        const get_url = BASE_API_URL + 'notification/getBroadcastNotificationByGrpId/' +grpId;
        return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    NotificationGroupdeleteID(group_Obj): Observable<any> {
       const get_url = BASE_API_URL + 'notificationGroup/delete';
        return this.http.post<any>(get_url,group_Obj,{ headers: httpOptions.headers }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
    
    NotificationdeleteID(notification_Obj): Observable<any> {
        const get_url = BASE_API_URL + 'notification/delete';
         return this.http.post<any>(get_url,notification_Obj,{ headers: httpOptions.headers }).pipe(
             map((res: any) => {
                 return res;
             }),
             catchError((error: any) => {
                 return throwError(error);
             })
         );
     }

    getSMSProvider(): Observable<any> {
        const get_url = BASE_API_URL + "smsProvider/all";
        return this.http.get(get_url);
    }
  
}
