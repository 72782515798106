import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomModalService } from '../../../../components/custom-modal';
import { AllocationIpSelectService } from './allocation-ip-select.service';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { MatTableDataSource } from '@angular/material';
import { LoadingService } from '../../../../components/loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../../services/search.service';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';


@Component({
  selector: 'app-allocation-ip-select',
  templateUrl: './allocation-ip-select.component.html',
  styleUrls: ['./allocation-ip-select.component.scss']
})
export class AllocationIpSelectComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit {

  @Input() custId: any;
  @Input() ApicallChange:any;
  @Input() partnerId: any;
  
  @Output() ipSelectPopupClose = new EventEmitter<any>();
  @ViewChild("selectedIp", { static: false }) selectedIpreff: TemplateRef<any>;
  submitted: any = null;
  selectedIpPool: any;
  gridSetting: any;
  nonAllocatedIpList: any[] = [];
  filterObj: any;
  ipAllocationGrid: any = {};
  search: string = '';
  poolId: any;
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  tableKeyEnum = TableKeyEnum
  page: any = 1;
  perPage: any = 5;
  checkIfOthersAreSelected: boolean = false;
  selectedFreeIp: any;
  newCustId:any;
  paginationDetails: any = {
    totalRecords: 0
  };
  selectIpPoolList: any[] = [
    {
      poolName: '',
      poolId: '',
    }
  ];
  allocationIpSelectForm = new FormGroup({
    ip_pool_id: new FormControl('', [Validators.required])
  })
  constructor(private customModalService: CustomModalService,
    private allocationIpSelectService: AllocationIpSelectService,
    private tosterservice: ToastrService, private searchService: SearchService
    ) {
      super(searchService)
    }

  ngOnInit() {
    
    
    this.newCustId=this.custId;
    this.setDefaultPageSize(this.tableKeyEnum.AllOCATION_IP_ADDRESS)
    this.setGridSetting();
    this.getSubscriberPurchasedIp();
    this.getNonAllocatedIp();
  }

  getSubscriberPurchasedIp() {
    if(this.partnerId){
      this.custId = this.partnerId
    }
    // this.allocationIpSelectService.getIpPoolAll(this.custId).subscribe(
    //   result => {
    //     this.selectIpPoolList = result.dataList;

    //   }
    // );

     if(this.ApicallChange==="ipAllocation" || this.ApicallChange==="replaceIp"){
      this.allocationIpSelectService.getIpPoolAll(this.custId).subscribe(
        result => {
          this.selectIpPoolList = result.dataList;
        }
      );
    }else{
      //partner id
      this.allocationIpSelectService.getIpPoolAllByPartner(this.partnerId).subscribe(
        result => {
          this.selectIpPoolList = result.dataList;
          }
      );
    }
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'select') {
        col.customControls = [this.selectedIpreff];
      }
    })
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
          this.paginationData.rowsPerPage=5;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.gridSetting = config;
    ////console.log("config",config);
    this.getNonAllocatedIp();
  }
  // setPagination(event){
    
  //   let config = { page: event, order: this.orderingData, search: this.searchData };
  //   this.gridSetting=config;
  //   this.getNonAllocatedIp();
  //     }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'select',
        default: 'select',
        rowKey: 'select',
        width: '0.7%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'ipAddress',
        default: 'Ip address',
        rowKey: 'ipAddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '0.7%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });

    this.ipAllocationGrid = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.AllOCATION_IP_ADDRESS,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [25],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Tax?',
      deleteMsgBoxDetail: 'Tax Name :${tax_name} ',
    };
    if (this.ipAllocationGrid.serverSideSortPagination && this.ipAllocationGrid.perPage) {
      this.setPaginationPerPage(this.ipAllocationGrid.perPage);
    }
  }
  trasnformApiInputData(params) {
    ////console.log("params",params);
    let formatedData: any = {};
    if (params.filters instanceof Array) {
      formatedData.filters = params.filters;
    }
    if (params) {
      formatedData.page = params.page;
      formatedData.pageSize = params.pageSize;
      formatedData.sortBy = "poolDetailsId";
      formatedData.sortOrder = params.sortOrder;
      formatedData.poolId = this.poolId;
    }
    if (this.custId != null) {
      formatedData.customerId = this.custId;
    }
    return formatedData;
  }
  getNonAllocatedIp() {
    let paginationData = {
      pageNumber: this.page,
      rowsPerPage: this.perPage
    };
    let orderingData = {
      column: "poolDetailsId",
      descending: 2
    };
    let search = '';
    let poolId = 0;
    if (this.selectedIpPool) {
      poolId = this.selectedIpPool.poolId;
    };

    let fetchSettingData = this.ipAllocationGrid.serverSideSortPagination ? this.gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);

    let apiInputData = this.trasnformApiInputData(params);
    console.log("Input Data---" +apiInputData)

    this.allocationIpSelectService.getNonAllocatedIp(apiInputData).subscribe(
      result => {

        if (result.dataList) {
          result.dataList.forEach(element => {
            if (this.selectedFreeIp) {
              if (this.selectedFreeIp == element.poolDetailsId) {
                element.chkDisabledStatus = false;
              } else {
                element.chkDisabledStatus = true;
              }
            } else {
              if (element.status === "Free" || element.status === "Hibernate" || (element.status === "Allocated" && this.selectedIpPool.poolId === -2)) {
                element.chkDisabledStatus = false;
              } else {
                element.chkDisabledStatus = true;
              }
            }
            this.ipAllocationGrid.pageTotalLength = result.totalRecords;
          });
        }
        this.paginationDetails = {
          currentPageNumber: result.currentPageNumber,
          pageRecords: result.pageRecords,
          responseCode: result.responseCode,
          responseMessage: result.responseMessage,
          totalPages: result.totalPages,
          totalRecords: result.totalRecords
        };
        this.nonAllocatedIpList = result.dataList;
        this.dataSource = new MatTableDataSource(result.dataList);
        // this.loadingService.hide();
      }
    );
  }

  onSearch() {
    let nonallocatedIpList = this.nonAllocatedIpList.filter((res) => {
      if (res.ipAddress === this.search) {
        return res
      }
    });
    if (nonallocatedIpList.length === 0) {
      this.tosterservice.error("Ip not found");
    } else {
      this.nonAllocatedIpList = nonallocatedIpList;
    }
    // this.search = '';

  }

  onIpPoolListChange(el) {
    
    if (el) {
      this.selectedIpPool = el;
    } else {
      this.selectedIpPool = null;
    }
    this.poolId = el.poolId;
    this.getListWithParams();

  }

  checkSelected(e) {
    if (e.target.checked) {
      this.checkIfOthersAreSelected = true;
    }
  }
  selectIpCheckbox(el) {
   
    let poolDetailsId = el.target.value;
    if (poolDetailsId) {
      this.selectedFreeIp = poolDetailsId;
      this.nonAllocatedIpList.forEach(function (element) {
        if (poolDetailsId != element.poolDetailsId) {
          element.chkDisabledStatus = true;
        } else {
          element.chkDisabledStatus = false;
        }
      });
      this.nonAllocatedIpList = this.nonAllocatedIpList;

           
       let dataObj={
         "poolDetailsId":parseInt(poolDetailsId),
         "custId":parseInt(this.newCustId),
         "poolId":this.poolId
        }

      
      /**IP Blocking API Below */

      if(this.newCustId !== undefined){
               
        this.allocationIpSelectService.ipPoolBlockIpByPoolDetailsIdAndCustId(dataObj).subscribe(result => {
          if (result.responseCode !== 200 && result.responseMessage) {
            this.tosterservice.error(result.responseMessage);
          } else {
           // this.closeModal("from-check2box");
            this.closeModal('allocation-ip-select');
            }
        });
      } else{
        this.closeModal('allocation-ip-select');
        //this.submitted=false;
      }


    } else {
      this.selectedFreeIp = null;
     }
  }

  goToPage(page_number: number): void {
    this.page = page_number;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  onNext(): void {
    this.page++;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  onPrev(): void {
    this.page--;
    this.getGridSettingAndEmitfetchDataFromApi();
  }

  getGridSettingAndEmitfetchDataFromApi() {
    this.getNonAllocatedIp();
  }


  closeModal(id: string) {
   // this.customModalService.close(id,{close_from:"close_button"});
    this.submitted = false;

    let selectedFreeIpData = {};
    if (this.selectedFreeIp) {
      selectedFreeIpData = _.find(this.nonAllocatedIpList, { poolDetailsId: parseInt(this.selectedFreeIp) });
    }
    let data = {
      selectedIpPool: this.selectedIpPool,
      selectedFreeIpData: selectedFreeIpData
    };

    this.ipSelectPopupClose.emit(data);
   // this.customModalService.close(id,{close_from:"close_button"});
  }

}
