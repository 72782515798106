import { Injectable } from '@angular/core';
import { BaseService } from '../../../services/base-service';
import { Group } from './model/group';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';
@Injectable({
  providedIn: 'root'
})
export class GroupService  extends BaseService<Group> {
  
  constructor(http: HttpClient) {
    super(http);
   }

validate(): boolean {
  return false;
}
getModuleName(): string {
  return "group";
}

getGroupById(id) {
  const get_url = BASE_API_URL + 'group/update/'+ id;
  return this.http.get<any>(get_url,
    { headers: httpOptions.headers }).pipe(
      map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
    );
}

deleteGroup(id): Observable<any> {
  
  const delete_url = BASE_API_URL + 'group/delete/'+id ;
  return this.http.post(delete_url,id,httpOptions);
}

saveGroup(obj): Observable<any> {
  
  const saveGroup_url = BASE_API_URL + 'group/save';
  return this.http.post(saveGroup_url,obj,httpOptions);
}

updateGroup(obj): Observable<any> {
  const saveGroup_url = BASE_API_URL + 'group/update';
  return this.http.post(saveGroup_url,obj,httpOptions);
}


getGroup(data){
    const post_url = BASE_API_URL + 'group';
    return this.http.post<any>(post_url, data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
}

commonStatusActiveInactive(){
  const get_url=BASE_API_URL+"commonList/commonStatus";
  return this.http.get<any>(get_url);
}

}

