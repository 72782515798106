import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer, EventManager } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { BillingTemplateService } from './billing_template.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../login/login.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';

@Component({
  selector: 'app-billingtemplate',
  templateUrl: './billing_template.component.html',
  styleUrls: ['./billing_template.component.scss']
})
export class BillingtemplateComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('partner', { static: false }) patnerWrapperRef: TemplateRef<any>;
  @ViewChild('Billtemplate', { static: false }) billTemplateReff: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnReff: TemplateRef<any>;
  
  BillingTemplateDataForGrid: any = { content: [] };
  BillingTemplateGridSettings: any = {};
  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  billingTemplateNeoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredBillingTemplateDataForGrid: any = [];
  associatePartnerlist: any[] = [];
  public loginService: LoginService;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  constructor(
    private router: Router,
    private ngbModalService: NgbModal,
    private sanitizer: DomSanitizer,
    private billingTemplateService: BillingTemplateService,
    private searchService: SearchService,
    private tosterservice: ToastrService,
    loginservice: LoginService
  ) { super(searchService); 
    this.loginService = loginservice;}

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.BILLINGTEMPLATE_LIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }



  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.BillingTemplateDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.BillingTemplateDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'associatePartnerCount') {
        col.customControls = [this.patnerWrapperRef];
      }
      if (col.colKey == 'templatename') {
        col.customControls = [this.patnerWrapperRef];
      }
      if (col.colKey == 'templatename') {
        col.customControls = [this.billTemplateReff];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onEdit(event) {
    let RowData:any={};
    if(event.RowData){
      RowData.billing_id=event.RowData.billing_id;
    }else{
      RowData.billing_id=event.billing_id;
    }
    this.setUpdateValueInForm(RowData);
  }

  onDelete(event) {
    
    let self = this;
    // if (event.deletClicked) {
      self.delete(event);
    //}
  }
  onViewPartner(data, view, parnersCount) {
    if (parnersCount === 0) {
      this.tosterservice.error("Partner not associated");
    } else {
      this.associatePartnerlist = data;
      this.ngbModalService.open(view, { size: 'md' });
    }
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'templatename',
        default: 'Template Name',
        rowKey: 'templatename',
        width: '40%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'associatePartnerCount',
        default: 'Associate Partner Count',
        rowKey: 'associatePartnerCount',
        width: '40%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'templatetype',
        default: 'Template Type',
        rowKey: 'templatetype',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '20%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'Active') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
      
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '6%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Template Name',
        value_field: 'templatename',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Template Type',
        value_field: 'templatetype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Active', 'InActive'],
          },
          {
            display_field: 'Active',
            value_field: 'Active',
          },
          {
            display_field: 'InActive',
            value_field: 'InActive',
          },
        ],
      },
    ];

    this.billingTemplateNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.BILLINGTEMPLATE_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Template?',
      deleteMsgBoxDetail: 'Template Name :${templatename} ',
    };

    if (this.billingTemplateNeoGridSettings.serverSideSortPagination && this.billingTemplateNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.billingTemplateNeoGridSettings.perPage);
    }
  }

  getBillingTemplateData(gridSetting: any) {
    let fetchSettingData = this.billingTemplateNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.billingTemplateService.getBillingTemplateDataWithPageing(params).subscribe(result => {
      this.BillingTemplateDataForGrid = result;
      this.refreshDataSource(this.BillingTemplateDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.billingTemplateNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getBillingTemplateData(config);
  }

  delete(selected_billing_template_data) {
    let self = this;
    this.billingTemplateService.deleteBillingTemplate(selected_billing_template_data.billing_id).subscribe((result) => {
      this.tosterservice.success('Billing Template is deleted successfully');
      self.BillingTemplateDataForGrid.content.forEach(function (item, index) {
        if (item.billing_id === selected_billing_template_data.billing_id) {
          self.BillingTemplateDataForGrid.content.splice(index, 1);
        }
      });
      self.refreshDataSource(self.BillingTemplateDataForGrid.content);
    },
      err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['operations/billingtemplate/add-billingtemplate', rowData.billing_id]);
  }

  onAddPaymentGatewayClick() {
    this.router.navigate(['operations/billingtemplate/add-billingtemplate']);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getBillingTemplateData(config);
  }
}
