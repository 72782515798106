import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { OldAbstractBaseListComponent } from '../../../../../src/app/components/base/old-abstract-base-list-component';
import { SearchService } from '../../../../../src/app/services/search.service';
import { TableKeyEnum } from '../../../../../src/app/core/enum/table-key.enum';
import { NetworktreeService } from './service/networktree.service';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-network-tree',
  templateUrl: './network-tree.component.html',
  styleUrls: ['./network-tree.component.scss']
})
export class NetworkTreeComponent  extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  // @ViewChild('editName', { static: false }) editNameReff: TemplateRef<any>;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  commonYN :any[] = [];
  selectedSnmpEnable:string
  dataSource = new MatTableDataSource([]);
  networktreeGridSettings: any = {};
  dataListArray:any;
  tableKey = TableKeyEnum;
  subscriptions: Subscription[] = [];
  
   constructor(private networktreeService:NetworktreeService,
    private toaster:ToastrService,
    private router: Router,
     private searchService: SearchService) {
    super(searchService);
   }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKey.NETWORK_DEVICE_TREE__LIST)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    
    this.setGridSetting();
    this.initPaginationSort();
    
    this.subscriptions.push(filterSubscription);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'name',
        default: 'name',
        rowKey: 'name',
        width: '20%',
        customControls: [this.actionBtnRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      {
        colKey: 'devicetype',
        default: 'device type',
        rowKey: 'devicetype',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'createdByName',
        default: 'created By Name',
        rowKey: 'createdByName',
        width: '10%',
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'last Modified By Name',
        rowKey: 'lastModifiedByName',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '10%',
        // isStickyEnd: true
      },
      // {
      //   colKey: 'actionCol',
      //   default: 'Action',
      //   rowKey: 'id',
      //   //enableEdit: true,
      //   // enableDelete: true,
      //   enableEdit: false,
      //   enableDelete: false,
      //   isFilterAllow: false,
      //   enableSort: false,
      //   isExpandable: false,
      //   isNumber: false,
      //   width: '1%',
      //   isStickyEnd: true,
      // }
    ];

    this.networktreeGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.NETWORK_DEVICE_TREE__LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      // sort: false,
      // defaultSortCol: '',
      // defaultSortDirection: 'asc',
      // // enableMultiDelete: true,
      deleteMsgBoxTitle: 'Are you sure to delete this subscriber ?',
      deleteMsgBoxDetail: 'Subscriber Name : ${name} ',
    };
    
     if (this.networktreeGridSettings.serverSideSortPagination && this.networktreeGridSettings.perPage) {
       this.setPaginationPerPage(this.networktreeGridSettings.perPage);
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.actionBtnRef];
     }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

   getNetworkDeviceData(gridSetting: any) {
    let fetchSettingData = this.networktreeGridSettings.serverSideSortPagination ? gridSetting:null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.networktreeService.getNetworkDevice(params).subscribe(result => {
      if(result.responseCode===200){
          if(result.dataList){
            this.dataListArray = result.dataList;
            this.totalRecords=null;
            this.totalRecords = result.totalRecords;
            this.networktreeGridSettings.pageTotalLength = this.totalRecords;
            this.refreshDataSource(this.dataListArray);
            // this.networktreeGridSettings.perPage=result.pageRecords;
              }else{
            this.toaster.error("Data Not Found");
          }
      }else{
        this.toaster.error("Data Not Found");
        this.totalRecords = 0;
        this.networktreeGridSettings.pageTotalLength = this.totalRecords;
      }
    });
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getNetworkDeviceData(config);
  }

  applyFilter(filterObj) {

    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataListArray));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataListArray);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getNetworkDeviceData(config);
  }

  onEdit(rowData){
    if(rowData.uuid){
        this.router.navigate(['/network-management/frame',rowData.uuid]);
    }else{
      this.toaster.error("Data Not Found")
    }
  }  
      
}
