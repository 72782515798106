import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomModalService } from '../../../../../../src/app/components/custom-modal';
import { SubscriberService } from '../../subscriber/subscriber.service';
import { ManageVlanService } from './manage-vlan.service';

@Component({
  selector: 'app-manage-vlan',
  templateUrl: './manage-vlan.component.html',
  styleUrls: ['./manage-vlan.component.scss']
})

export class ManageVlanComponent implements OnInit {
  isCheckedVLan:boolean=false;
  submitted:boolean=false;
  @Input() custId: number; 
    insertManagevLan = new FormGroup({
    custid: new FormControl(''),
    vlanFlag: new FormControl(''),
    remark: new FormControl('',Validators.required),
    vlanId: new FormControl(''),
  });
  constructor(
    private subscriberDetaisservice:SubscriberService,
    private tosterservice : ToastrService,
    private customModalService : CustomModalService,
  ) { }

  ngOnInit() {
    this.getVlanDetails();
   }

  getVlanDetails(){
    this.subscriberDetaisservice.getVlan(this.custId).subscribe(result=>{
      if(result.responseCode ===200){
           this.insertManagevLan.get('vlanFlag').setValue(result.data.vlanFlag)
           this.insertManagevLan.get('remark').setValue(result.data.remark)
           this.insertManagevLan.get('vlanId').setValue(result.data.vlanId)
           this.insertManagevLan.get('custid').setValue(result.data.custid)
        }else{
          this.tosterservice.error(result.responseMessage);
        }
    })
  }

  onClickResetForm(){
  let obj={
    custid :this.insertManagevLan.get('custid').value,
    remark:this.insertManagevLan.get('remark').value,
   }
   
  this.subscriberDetaisservice.resetVlan(obj).subscribe(res=>{
      if(res.responseCode===200){
          this.tosterservice.success("Reset Vlan Successfully"); 
          this.customModalService.close('manage-vlan',res);
          }else{
          this.tosterservice.error(res.responseMessage);
        }
    })
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }

  onClickSaveForm(){
   this.submitted=true;
   let formateddata=this.insertManagevLan.value;
   
   if(this.insertManagevLan.valid){
    this.subscriberDetaisservice.updateVlan(formateddata).subscribe(res=>{
      if(res.responseCode===200){
       this.tosterservice.success("VLan Updated Successfully"); 
       this.customModalService.close('manage-vlan',res);
      }else{
       this.tosterservice.error(res.responseMessage);
      }
    })
   }else{
    this.insertManagevLan.markAllAsTouched();
   }
   
  }

  
}
