import { Component, Input, OnInit } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import { RollbackIpAddressService } from './rollback-ip-address.service';
import { ReverseChargeService } from "../reverse-charge/reverse-charge.service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
@Component({
  selector: 'app-rollback-ip-address',
  templateUrl: './rollback-ip-address.component.html',
  styleUrls: ['./rollback-ip-address.component.scss'],
  providers: [DatePipe]
})
export class RollbackIpAddressComponent implements OnInit {

  @Input() custId: any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  reversalOptionFlag: boolean = false;
  reversalOption:any
  submitted: any = null;
  chargeAmount: any = "";
  custAllocatedIp: any = "";
  proratedAmountWithTax: any = "";
  fullAmountWithoutTax:any = "";
  proratedAmountWithoutTax:any = "";
  charge: any = null;
  reversal_type = null;
  remarks: any = "";
  finalAmount: any = "";
  fullAmountWithTax: any = "";
  refundOption:any;
  calculatedReverseCharge: any = {};
  selectPurchaseList: any[] = [
    {
      display_text: '',
      id: '',
    }
  ];
  reversalTypeCommonList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  insertRollbackIpForm = new FormGroup({
    purchase_id: new FormControl(null, [Validators.required]),
    reversal_type: new FormControl(null, [Validators.required]),
    remarks: new FormControl('', [Validators.required])
  })

  constructor(
    private customModalService: CustomModalService,
    private rollbackIpAddressService: RollbackIpAddressService,
    private reverseChargeService: ReverseChargeService,
    public datepipe: DatePipe,
    private tosterservice: ToastrService,
    private planService: PlanServices,
    public loginService: LoginService


  ) {this.loginService = loginService}

  ngOnInit() {
    this.getSubscriberPurchasedIpForRollBack();
    this.getReversalType();

    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_REFUND_OPTION,AclConstants.OPERATION_CUSTOMER_ALL))
    {
      this.reversalOptionFlag = true;
    }
    else{
      this.reversalOptionFlag = false;
    }
    //console.log("SKSKSKSKSKSKSKSKSKSKKSKSK",this.reversalOptionFlag)

  }

  getSubscriberPurchasedIpForRollBack() {
    this.rollbackIpAddressService.getSubscriberPurchasedIpForRollBack(this.custId).subscribe(result => {
      
      // if (result.data && result.data.length) {
      //   result.data.forEach(element => {
      //     let price=element.actualprice+element.taxamount;
      //     element.display_text = element.chargeName+" ("+price+"-"+element.startdateString+"-"+element.enddate+" ) ";
      //   });

      // }
      this.selectPurchaseList = this.transformDetailsList(result.data);
      ////console.log("selectPurchaseList", this.selectPurchaseList);
    });
  }
  transformDetailsList(result) {
    result.forEach(element => {
      let price = element.actualprice + element.taxamount;
      element.display_text = element.chargeName + " (" + price + " " + element.startdateString + "-" + element.enddate + " )";
      ////console.log("element", element);
    });
    return result;
  }
  getReversalType() {
    this.rollbackIpAddressService.getReversalType().subscribe((result) => {
      this.reversalTypeCommonList = result.dataList;
      


      if(!this.reversalOptionFlag)
      {
        let selectedreversalOption =  this.reversalTypeCommonList;
        
        this.insertRollbackIpForm.get('reversal_type').setValue(selectedreversalOption[0].value)
        
        this.onChangeReversalType(selectedreversalOption[0]);
        this.insertRollbackIpForm.controls.reversal_type.disable();
      }

    });
  }

  onChangeReversalType(el) {
    if (el) {
      if (el.value === 'Prorated Without Tax') {
        this.finalAmount = this.proratedAmountWithoutTax;
        this.refundOption = 'Prorated Without Tax'
      } else if (el.value === 'Prorated With Tax') {
        this.finalAmount =  this.proratedAmountWithTax
        this.refundOption = 'Prorated With Tax'
      }
      else if(el.value ==='Full Without Tax'){
        this.finalAmount = this.fullAmountWithoutTax;
        this.refundOption = 'Full Without Tax'
      }
      else if(el.value ==='Full With Tax'){
        this.finalAmount =  this.fullAmountWithTax
        this.refundOption = 'Full With Tax'
      }
      else if(el.value ==='None'){
        this.finalAmount =  0
        this.refundOption = 'None'
      }
    } else {
      this.finalAmount = '';
    }
  }

  onPurchaseListChange($event) {
    if ($event) {
      let subscriber_id = this.custId;
      this.reverseChargeService.getCalculateReverseCharge($event.id).subscribe((result) => {
        ////console.log(result);
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.calculatedReverseCharge = result;
          this.fullAmountWithTax = result.data.fullAmount;
          this.proratedAmountWithTax = result.data.proratedAmount;
          this.proratedAmountWithoutTax = result.data.proratedAmountWithoutTax;
          this.fullAmountWithoutTax = result.data.fullAmountWithoutTax;
          this.custAllocatedIp = result.data.custAllocatedIp;
        }
      });
      let findById = _.find(this.selectPurchaseList, { chargeid: $event.chargeid });
      this.chargeAmount = findById.price + findById.taxamount;
    } else {
      this.custAllocatedIp = "";
      this.chargeAmount = "";
      this.proratedAmountWithTax = "";
      this.finalAmount = "";
      this.fullAmountWithTax = "";
      this.proratedAmountWithoutTax = "";
      this.fullAmountWithoutTax = "";
      this.calculatedReverseCharge = {};
    }
  }


  onClickSaveRollbackIpForm() {
    if (this.insertRollbackIpForm.valid === true) {
      for (const prop in this.insertRollbackIpForm.controls) {
        this.insertRollbackIpForm.value[prop] = this.insertRollbackIpForm.controls[prop].value;
      }
      let self = this;
      this.submitted = false;
      alertify.confirm("Rollback IP Address", "Are You Sure You want to Rollback IP Address ?", function () {
        self.saveApiCall();
      },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertRollbackIpForm.markAllAsTouched();
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertRollbackIpForm.value);
    let selectedChargeData = _.find(this.selectPurchaseList, { id: apiInputData.purchase_id });
    let custId = parseInt(this.custId);
    let formatedData: any = {
      "charge_id": apiInputData.purchase_id,
      "custId": custId,
      "rev_amt": this.finalAmount,
      "rev_date": null, // selectedChargeData.rev_date
      "rev_remarks": apiInputData.remarks,
      "refundOption":this.refundOption
    };
    this.rollbackIpAddressService.saveRollbackIpByCustId(custId, formatedData).subscribe((result) => {
      if (result.responseCode !== 200 && result.responseMessage) {
        this.tosterservice.error(result.responseMessage);
      } else {
        this.tosterservice.success('Rollback Ip Save Successfully.');
        this.customModalService.close('rollback-ip-address', result);
      }
    }, err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    });
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

}
