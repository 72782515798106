import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { CountryService } from './country.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CountryComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;

  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('editUserName', { static: false }) editUserNameReff: TemplateRef<any>;
  
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;


  countryDataForGrid: any = { content: [] };
  countryGridSettings: any = {};
  selectForUpdate: any;
  show_update: any = false;

  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  countryNeoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];

  public loginService: LoginService;

  constructor(
    private countryService: CountryService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginservice: LoginService,
    private tosterservice: ToastrService
  ) {
    super(searchService);
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.COUNTRY_LIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'country_name') {
        col.customControls = [this.editUserNameReff];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'country_name',
        default: 'Country Name',
        rowKey: 'country_name',
        width: '40%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'Active') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        // enableEdit: true,
        // enableDelete: true,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: true,
        width: '1%',
        isStickyEnd: true,
      },
    ];

    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Country Name',
        value_field: 'country_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
    ];

    this.countryNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.COUNTRY_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Country ?',
      deleteMsgBoxDetail: 'Country Name :${country_name} ',
    };



    if (this.countryNeoGridSettings.serverSideSortPagination && this.countryNeoGridSettings.perPage) {

      this.setPaginationPerPage(this.countryNeoGridSettings.perPage);

    }
  }

  getCountryData(gridSetting: any) {
   

    let fetchSettingData = this.countryNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.countryService.getCountryDataWithPageing(params).subscribe((result) => {
      this.countryDataForGrid = result;
      this.refreshDataSource(this.countryDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.countryNeoGridSettings.pageTotalLength = this.totalRecords;

    });
  }
  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getCountryData(config);
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.countryDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.countryDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    if (event) {
       this.commonDeleteMsgBox(this.countryNeoGridSettings,event,this.delete.bind(this));
    }
  }

  delete(objEvent) {
    let self = this;
    let selected_country_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.countryService.deleteCountry(selected_country_data.country_id).subscribe((result) => {
        this.tosterservice.success('Country is deleted successfully');
        this.show_update = false;
        self.countryDataForGrid.content.forEach(function (item, index) {
          if (item.country_id === selected_country_data.country_id) {
            self.countryDataForGrid.content.splice(index, 1);
          }
        });
        self.refreshDataSource(self.countryDataForGrid.content);
      },
      err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/country/add_country',rowData.country_id]);
  }

  onAddCountryClick() {
    this.router.navigate(['/masters/country/add_country']);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getCountryData(config);
      }
}
