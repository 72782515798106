import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';

import { ReverseChargeService } from './reverse-charge.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { CancelPlanService } from '../subscriber-tables/cancel-plan/cancel-plan.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { RollbackIpAddressService } from '../rollback-ip-address/rollback-ip-address.service';
@Component({
  selector: 'app-reverse-charge',
  templateUrl: './reverse-charge.component.html',
  styleUrls: ['./reverse-charge.component.scss']
})
export class ReverseChargeComponent implements OnInit {
  @Input() custId: any;
  @Input() bal: any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  reversalOptionFlag: boolean = false;
  submitted: any = null;
  insertReverseChargesForm = new FormGroup({
    charge_id: new FormControl('', [Validators.required]),
    reversal_type: new FormControl('',Validators.required),
    remark: new FormControl('',Validators.required)
  })

  chargeAmount: any = "";
  proratedAmount: any = "";
  finalAmount: any = "";
  fullAmount: any = "";
  fullAmountWithoutTax: any = "";
  proratedAmountWithoutTax: any = ""
  refundOption: any;
  reverseChargeData: any = {};
  calculatedReverseCharge: any = {};
  custChargeDetailsList: any[] = [
    {
      display_text: '',
      id: '',
    },
  ];
  reversalTypeCommonList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  saveBtnDisable:boolean=false;
  constructor(
    private customModalService: CustomModalService,
    private rollbackIpAddressService: RollbackIpAddressService,
    private reverseChargeService: ReverseChargeService,
    private tosterservice: ToastrService,
    private planService: PlanServices,
    public loginService: LoginService


  ) { this.loginService = loginService }

  ngOnInit() {
    this.getSubscriberReverseCharge();
    this.getReversalType();

    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER, AclConstants.OPERATION_CUSTOMER_REFUND_OPTION, AclConstants.OPERATION_CUSTOMER_ALL)) {
      this.reversalOptionFlag = true;
    }
    else {
      this.reversalOptionFlag = false;
    }
  }

  transformCustChargeDetailsList(result) {
    result.forEach(element => {
      let price = element.actualprice + element.taxamount;
      element.display_text = element.chargeName + " ( " + price + " " + element.startdateString + "-" + element.enddate + " ) ";
    });
    return result;
  }

  getSubscriberReverseCharge() {
    let subscriber_id = this.custId;
    this.reverseChargeService.getSubscriberReverseCharge(subscriber_id).subscribe((result) => {
      
      this.reverseChargeData = result;
      this.custChargeDetailsList = this.transformCustChargeDetailsList(result.data.custChargeDetailsList);
      // this.reversalTypeCommonList = result.data.reversalTypeCommonList;
      
    });
  }

  getReversalType() {
    this.rollbackIpAddressService.getReversalType().subscribe((result) => {
      this.reversalTypeCommonList = result.dataList;
      


      if (!this.reversalOptionFlag) {
        let selectedreversalOption = this.reversalTypeCommonList;

        this.insertReverseChargesForm.get('reversal_type').setValue(selectedreversalOption[0].value)
        
        // this.onChangeReversalType(selectedreversalOption[0]);  
        this.insertReverseChargesForm.controls.reversal_type.disable();
        this.refundOption = selectedreversalOption[0].value;
        this.finalAmount = "0.00";

      }

    });
  }

  onChargeChange($event) {

    if (this.reversalOptionFlag) {
      if ($event) {

        let subscriber_id = this.custId;

        this.reverseChargeService.getCalculateReverseCharge($event.id).subscribe((result) => {
          this.calculatedReverseCharge = result;
          this.fullAmount = result.data.fullAmount;
          this.proratedAmount = result.data.proratedAmount;
          this.fullAmountWithoutTax = result.data.fullAmountWithoutTax;
          this.proratedAmountWithoutTax = result.data.proratedAmountWithoutTax;
        });
        let findById = _.find(this.custChargeDetailsList, { chargeid: $event.chargeid });
        // this.chargeAmount = findById.price + findById.taxamount; (old code-Value Static for Charge Amount-Bug) 
        this.chargeAmount = $event.price + $event.taxamount; // *vijay: chargeAmount Updated according to Charger Selection

      } else {
        this.chargeAmount = "";
        this.proratedAmount = "";
        this.finalAmount = "";
        this.fullAmount = "";
        this.fullAmountWithoutTax = "";
        this.proratedAmountWithoutTax = "";
        this.calculatedReverseCharge = {};
      }
      this.insertReverseChargesForm.get('reversal_type').reset()
      this.insertReverseChargesForm.get('reversal_type').updateValueAndValidity();
      this.finalAmount = "";
    }

    else {
      if ($event) {

        let subscriber_id = this.custId;

        this.reverseChargeService.getCalculateReverseCharge($event.id).subscribe((result) => {
          this.calculatedReverseCharge = result;
          this.fullAmount = result.data.fullAmount;
          this.proratedAmount = result.data.proratedAmount;
          this.fullAmountWithoutTax = result.data.fullAmountWithoutTax;
          this.proratedAmountWithoutTax = result.data.proratedAmountWithoutTax;
        });
        let findById = _.find(this.custChargeDetailsList, { chargeid: $event.chargeid });
        // this.chargeAmount = findById.price + findById.taxamount; (old code-Value Static for Charge Amount-Bug) 
        this.chargeAmount = $event.price + $event.taxamount; // *vijay: chargeAmount Updated according to Charger Selection

      } else {
        this.chargeAmount = "";
        this.proratedAmount = "";
        this.finalAmount = "";
        this.fullAmount = "";
        this.fullAmountWithoutTax = "";
        this.proratedAmountWithoutTax = "";
        this.calculatedReverseCharge = {};
      }
      this.getReversalType();
    }


  }


  onChangeReversalType(el) {
    if (el) {
      if (el.value === 'Prorated Without Tax') {
        this.finalAmount = this.proratedAmountWithoutTax;
        this.refundOption = 'Prorated Without Tax'
      } else if (el.value === 'Prorated With Tax') {
        this.finalAmount = this.proratedAmount
        this.refundOption = 'Prorated With Tax'
      }
      else if (el.value === 'Full Without Tax') {
        this.finalAmount = this.fullAmountWithoutTax;
        this.refundOption = 'Full Without Tax'
      }
      else if (el.value === 'Full With Tax') {
        this.finalAmount = this.fullAmount
        this.refundOption = 'Full With Tax'
      }
      else if (el.value === 'None') {
        this.finalAmount = 0
        this.refundOption = 'None'
      }
    } else {
      this.finalAmount = '';
    }
  }

  onClickSaveReverseChargeForm() {
    if (this.insertReverseChargesForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Reverse Charge", "Are You Sure You want to Update Reverse Charge Details ?",
        function () {
          self.saveApiCall();
        },
        function () {
          this.saveBtnDisable=false;
        });
    } else {
      this.submitted = true;
      this.insertReverseChargesForm.markAllAsTouched();
      this.saveBtnDisable=false;
    }
  }

  saveApiCall() {
    this.saveBtnDisable=true;
    let apiInputData = Object.assign({}, this.insertReverseChargesForm.value);

    let formatedData: any = {};
    formatedData.custId = this.custId;
    formatedData.charge_id = apiInputData.charge_id;
    formatedData.rev_amt = this.finalAmount;
    formatedData.refundOption = this.refundOption;
    formatedData.rev_date = new Date();
    formatedData.rev_remarks = apiInputData.remark;
    
    this.reverseChargeService.saveReverseCharge(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
          this.saveBtnDisable= false;
        } else {
          this.saveBtnDisable= false;
          this.tosterservice.success('Reverse Charge Save Successfully.');
          this.customModalService.close('reverse-charge', result);
        }
      }, err => {
        this.saveBtnDisable= false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

}
