import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { Circuit } from '../../model/Circuit';
import { BaseSetupService } from '../../base-setup/service/base-setup.service';
import { BaseSetupModel } from '../../base-setup/model/base-setup-model';
declare let $;

@Component({
  selector: 'app-content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.scss']
})
export class ContentDetailsComponent extends AbstractBaseListComponent<any> implements OnInit {
  @ViewChild('quickCaseModal', { static: true }) quickCaseModalRef: ElementRef;

  closeResult: string;
  getDismissReason: any;
  
  @Input() contentList;
  setList(contentList) {
    
    this.ContentListData = contentList;
    this.refreshDataSource(this.ContentListData);
  }
  getService(): IBaseService<any> {
    return null
  }
  getTableSearchSetting() {

  }
  @ViewChild("actionBtn", { static: false }) actionBtnReff: TemplateRef<any>;
  baseSetup = new BaseSetupModel();
  isUpdate: boolean = false;
  subleasedlineneogridsetting: any = {};
  tablekeyEnum = TableKeyEnum;
  contactDetailsIndex:any;

  techObj = {
      deviceName: '',
      ip: '',
      rackSize:null,
      modelNo: '',
      modeName:'',
      fduType: '',
      noOfPort: null
    };
  ContentListData: any[] = [];

  
  @Input() BaseSetupModel : '';
  
  DataSource = new MatTableDataSource([]);
  searchSettings: any = {};
  deviceTypes: any[] = [];
  allColumns: any[] = [];
  dataGrid: any[] = [];
  isModelopen: boolean = false;
  subscriptions: Subscription[] = [];
  submitted: boolean = false
  isUpdateComponent: boolean = false;
  circuitdata: any = null;
  tableKeyenum: any = TableKeyEnum;
  contentDetail: FormGroup;
  flagforcircuit: boolean = true;
  connectionTypeflag: boolean = false;
  existValue: boolean;
  private ngbRef: NgbModalRef;
  constructor(
    private toaster: ToastrService,
    private router: Router,
    public baseSetupService: BaseSetupService,
    private searchService: SearchService,
    private ngbModalService: NgbModal,

  ) {
    super(toaster, searchService);
  }
  ngOnInit() { 
    this.getTableColumn();
    this.setFormData();
    this.setData();
    this.getDeviceType();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      this.applyFilter(obj);
    });
    this.subscriptions.push(filterSubscription);
  }

  setFormData() {
    this.contentDetail = new FormGroup({
      deviceName: new FormControl('', Validators.required),
      ip: new FormControl(''),
      rackSize:new FormControl(''),
      modelNo: new FormControl(''),
      modeName:new FormControl(''),
      fduType:new FormControl(''),
      noOfPort:new FormControl(''),
      // technical_contact_name: new FormControl(''),
      // technical_contact_phone: new FormControl('', Validators.pattern('^[0-9]{10}$')),
      // technical_alternate_number:new FormControl('')
      
    })
  }

  getDeviceType() {
    this.baseSetupService.getDeviceType().subscribe(response => {
      this.deviceTypes = response.dataList;
    })
  }

  setData(){
    this.ContentListData = this.contentList;
  }

  applyFilter(filterObj: any) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filteredDataForGrid) {
      this.baseSetupService.getAdvanceFilterSearchData(filterObj).subscribe((res) => {
        this.refreshDataSource(res.ContentListData);
      });
    }
  }

  refreshDataSource(data) {
    this.ContentListData = Object.assign([],data)
    if (data) {
      this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

   getTableColumn() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'deviceName',
        default: 'Device Name',
        rowKey: 'deviceName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'modeName',
        default: 'Model Name',
        rowKey: 'modeName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'modelNo',
        default: 'Model Number',
        rowKey: 'modelNo',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ip',
        default: 'Ip',
        rowKey: 'ip',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'rackSize',
        default: 'Rack Size',
        rowKey: 'rackSize',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'fduType',
        default: 'fdu Type',
        rowKey: 'fduType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'noOfPort',
        default: 'No Of Port',
        rowKey: 'noOfPort',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '8%',
        isStickyEnd: true,
        isMultipleOption: false
      },

    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {

    let subleasedlineneogridsetting = {
      showFooter: false,
      showPagination: false,
      showFilter: false,

      // perPage: 10,
      // pageSizeOptions: [5, 10, 15, 20, 50, 100],
      // sort: true,
      // serverSideSortPagination: true,
      // pageTotalLength: 0,
      // serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      tableId: this.tablekeyEnum.CONTENT_DETAILS,
      enableMultiDelete: true,
      columns: this.allColumns,

    };
    this.subleasedlineneogridsetting = subleasedlineneogridsetting
    return subleasedlineneogridsetting
  }


  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
    });
  }

  onclick() {
    if(this.contentDetail.invalid)
    {
      this.submitted = true;
      return this.toaster.error('Please Fill All Required Details!');
    }
    this.isUpdate = false
    this.techObj ['deviceName'] = this.baseSetup.deviceName;
    this.techObj['ip']=this.baseSetup.ip;
    this.techObj['rackSize'] = this.baseSetup.rackSize;
    this.techObj ['modelNo'] = this.baseSetup.modelNo;
    this.techObj['modeName'] = this.baseSetup.modeName;
    this.techObj['fduType']=this.baseSetup.fduType;
    this.techObj['noOfPort'] = this.baseSetup.noOfPort;
    let temp = Object.assign({}, this.techObj)
    this.ContentListData.push(temp);
    this.refreshDataSource(this.ContentListData);
    this.techObj['deviceName'] = null
    this.techObj['ip'] = null
    this.techObj['rackSize'] = null
    this.techObj['modelNo'] = null
    this.techObj['modeName'] = null
    this.techObj['fduType'] = null
    this.techObj['noOfPort'] = null
    this.closeModal();
  }

  onupdateclick(){
    if(this.contentDetail.invalid)
    {
      this.submitted = true;
      return this.toaster.warning('Please Fill Valid Data','WARNING');
    }  
     this.ContentListData[this.contactDetailsIndex].deviceName = this.baseSetup.deviceName;
     this.ContentListData[this.contactDetailsIndex].ip = this.baseSetup.ip;
     this.ContentListData[this.contactDetailsIndex].rackSize = this.baseSetup.rackSize;
     this.ContentListData[this.contactDetailsIndex].modelNo = this.baseSetup.modelNo;
     this.ContentListData[this.contactDetailsIndex].modeName = this.baseSetup.modeName;
     this.ContentListData[this.contactDetailsIndex].fduType = this.baseSetup.fduType;
     this.ContentListData[this.contactDetailsIndex].noOfPort = this.baseSetup.noOfPort;
     this.refreshDataSource(this.ContentListData);
     this.closeModal();
  }

  onDeleteClick(data) {
    let temp = this.ContentListData.findIndex(res => res.deviceName === data.deviceName)
    this.ContentListData.splice(temp, 1);
    this.refreshDataSource(this.ContentListData);
  }

  setObjectData() {
    this.openContactModal(null);
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  onEditClick(data) {
    this.isUpdate = true;
    this.contactDetailsIndex = this.ContentListData.findIndex(res => res.deviceName === data.deviceName)
    this.baseSetup.deviceName = data.deviceName;
    this.baseSetup.ip = data.ip;
    this.baseSetup.rackSize = data.rackSize;
    this.baseSetup.modelNo = data.modelNo;
    this.baseSetup.modeName = data.modeName;
    this.baseSetup.fduType = data.fduType;
    this.baseSetup.noOfPort = data.noOfPort;
    this.ngbRef = this.ngbModalService.open(this.quickCaseModalRef);
  }

  openContactModal(openContactModal) {
    this.baseSetup = new BaseSetupModel();
    this.contentDetail.reset();
    this.isUpdate = false;
    this.ngbRef = this.ngbModalService.open(openContactModal);
    // this.contentDetails.alternatemobile = "No"
  }

  get f() {
    return this.contentDetail.controls;
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  closeModal() {
   this.ngbModalService.dismissAll();
    this.isUpdate = false;
  }

  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
} 











