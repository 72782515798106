import { Component, ElementRef, OnInit ,ViewChild,ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as alertify from 'alertifyjs';
import { TestPaymentService } from './test-payment.service';
import { PlaceOrder } from './model/add-placeorder';

@Component({
  selector: '[payment]',
  templateUrl: './test-payment.component.html',
  styleUrls: ['./test-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: true,
})
export class TestPaymentComponent implements OnInit {

  public placeOrderId : any = null;
  error_message = "This field is required.";
  placeOrder: PlaceOrder = new PlaceOrder();
  public payuformSubmitUrl:string='';
  disablePaymentButton: boolean = true;

  paymentGatewayFormGroup:FormGroup;
  formTemplate:any=[];

  @ViewChild('hiddenPayuForm',{static: false}) testFormElement;

  placeOrderForm = new FormGroup({
    basicamount: new FormControl('', [Validators.required]),
    custId: new FormControl('', [Validators.required]),
    entityid: new FormControl('', [Validators.required]),
    finalamount: new FormControl('', [Validators.required]),
    orderdesc: new FormControl('', [Validators.required]),
    ordertype: new FormControl('', [Validators.required]),
    partnerId: new FormControl('', [Validators.required]),
    pgid: new FormControl('', [Validators.required]),
    taxamount: new FormControl('', [Validators.required])
  });

  constructor( 
    private router: Router,
    private route : ActivatedRoute,
    private testPaymentService: TestPaymentService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  validateForm() {
    if (!this.placeOrderForm.valid) {
      alertify.error("Please fill all the details!");
      Object.keys(this.placeOrderForm.controls).forEach(field => {
        const control = this.placeOrderForm.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.placeOrderId === null) {
        this.insertPlaceOrder();
      } else {
        //this.updatePlaceOrder();
      }
    }
  }

  insertPlaceOrder() {
    if (this.placeOrderForm.valid === true) {
      this.placeOrder = new PlaceOrder(this.placeOrderForm.value);
      this.testPaymentService.insertPlaceOrder(this.placeOrder).subscribe((result) => {
        if(result){
          ////console.log(result.data.pgDetails.suburl);
          
          if(result.responseCode == 200 && result.data){
            // this.payuformSubmitUrl = result.data.pgDetails.suburl;
            // this.payuform.txnid = result.data.pgDetails.txnid;
            // this.payuform.surl = result.data.pgDetails.surl;
            // this.payuform.furl = result.data.pgDetails.furl;
            // this.payuform.key = result.data.pgDetails.key;
            // this.payuform.salt = result.data.pgDetails.salt;
            // this.payuform.hash = result.data.pgDetails.hash;         
            // this.payuform.firstname = result.data.pgDetails.firstname;
            // this.payuform.phone = result.data.pgDetails.phone;
            // this.payuform.amount = result.data.pgDetails.amount;
            // this.payuform.productinfo = result.data.pgDetails.productinfo;
            // this.payuform.email = result.data.pgDetails.email;
            this.createDynamicFormByResponse(result.data.pgDetails);
          }
        }
        ////console.log("result",result);
        alertify.success('Place order has been created successfully');
       },err => {
         alertify.error(err.error.status + " " + err.error.ERROR);
       });
    }
  }
  executePayu(){
    setTimeout( ()=>{
      ////console.log('this.testFormElement',this.testFormElement);
      this.testFormElement.nativeElement.submit();
    }, 1000)
  }

  createDynamicFormByResponse(res:any){
    // let response = {
    //   'amount': 3000,
    //   'email': "12312@mail.com",
    //   'firstname': "Kushal",
    //   'furl': "https://apiplayground-response.herokuapp.com/",
    //   'hash': "6bbbf7a9edf63460ad4db8bd43fc02c7ef8ed284821c6607a3d89b081129a25681f3c7f9ff71e654f66c5b2be630b6047d6de0e4f58a95f43deab80491b358ea",
    //   'header': "dK4h8wO91C2K1jJULDJHOKjfCf16eA5VZbAdouAIi7A=",
    //   'key': "gtKFFx",
    //   'phone': "123123",
    //   'productinfo': "Plan purchase",
    //   'salt': "YiWepw7gfB",
    //   'suburl': "https://test.payu.in/_payment",
    //   'surl': "https://apiplayground-response.herokuapp.com/",
    //   'txnid': "cf9368f9-b887-4f5e-815d-ebf0fa3b7603",
    // }
    this.payuformSubmitUrl=res.suburl;

    let formGroup={}    
    for (var key in res) {
      let formControl = {name:'',value:''};
      if (res.hasOwnProperty(key)) {
        formControl.name = key;
        formControl.value = res[key];
          this.formTemplate.push(formControl);
      }
    }
    this.formTemplate.forEach(obj=>{
      formGroup[obj.name]=new FormControl(obj.value);  
    })
    
   this.paymentGatewayFormGroup = new FormGroup(formGroup);

   if(this.payuformSubmitUrl !==''){              
    this.executePayu();
  }
  }
}
