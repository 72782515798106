import { Component, Input, OnInit } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import { ChangeIpExpiryService } from './change-ip-expiry.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';

import _ from 'underscore';
import { DatePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-ip-expiry',
  templateUrl: './change-ip-expiry.component.html',
  styleUrls: ['./change-ip-expiry.component.scss']
})
export class ChangeIpExpiryComponent implements OnInit {

  @Input() custId: any;
  submitted: any = null;
  pool_name: any = "";
  isInputDays: boolean;
  isSelectExpiry: boolean;
  checkeventDate: any;
  activation_date: any = "";
  end_date: any = "";
  ip_address: any = "";
  remarks = "";
  errorMsg = "";
  revisedExpiryDate: any = "";
  selectPurchaseList: any[] = [
    {
      display_text: '',
      currentChargeId: '',
    }
  ];
  adjusttype: any[] = [
    { name: 'Add', value: 'add' },
    { name: 'Absolute', value: 'absolute' }
  ]

  insertChangeIpExpiryForm = new FormGroup({
    purchase_id: new FormControl(null, [Validators.required]),
    revised_expiry_date: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required])
  })

  constructor(
    private customModalService: CustomModalService,
    private changeIpExpiryService: ChangeIpExpiryService,
    public datepipe: DatePipe,
    private tosterservice: ToastrService
  ) { }

  ngOnInit() {
    this.getSubscriberAllocateIp();
  }

  getSubscriberAllocateIp() {
    this.changeIpExpiryService.getSubscriberAllocateIp(this.custId).subscribe(result => {
      if (result.dataList && result.dataList.length) {
        result.dataList.forEach(el => {
          el.display_text = el.chargeName + ", " + this.datepipe.transform(el.activationDate, 'dd-MM-yyyy') + " to " + this.datepipe.transform(el.expiryDate.slice(0, 10), 'dd-MM-yyyy');
        });
      }
      this.selectPurchaseList = result.dataList;

    });
  }

  onClickSaveChangeIpExpiryForm() {
    this.setChangeIpExpirydata();
    if (this.insertChangeIpExpiryForm.valid === true) {
      let self = this;
      this.submitted = false;
      alertify.confirm("Change Ip Expiry", "Are You Sure You want to Change Ip Expiry ?",
        function () {
          self.saveApiCall();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertChangeIpExpiryForm.markAllAsTouched();
    }
  }

  onPurchaseListChange(el) {
    if (el) {
      this.activation_date = el.activationDate;
      this.checkeventDate = el.expiryDate;
      this.end_date = this.datepipe.transform(el.expiryDate.slice(0, 10), 'dd/MM/yyyy')
      this.pool_name = el.poolName;
      this.ip_address = el.ipAddress;
    } else {
      this.activation_date = "";
      this.end_date = "";
      this.pool_name = "";
      this.ip_address = "";
    }
  }

  OnrevisedExpiryDate(event) {
    this.revisedExpiryDate = '';
    if (event.value == 'add') {
      this.isInputDays = true;
      this.isSelectExpiry = false;
    } else if (event.value == 'absolute') {
      this.isSelectExpiry = true;
      this.isInputDays = false;
    }
  }
  setChangeIpExpirydata() {
    if (this.revisedExpiryDate == '') {
      this.errorMsg = "revised expiry date is required";
    } else {
      this.errorMsg = "";
    }
    let formatedInputdate = {
      "revised_expiry_date": this.revisedExpiryDate
    }
    this.insertChangeIpExpiryForm.patchValue(formatedInputdate);
  }
  SelectedExpiry(event: any) {
    // var expirydate = new Date(this.datepipe.transform(this.end_date, 'yyyy-MM-dd')).valueOf();
    var expirydate: any = new Date(this.checkeventDate.slice(0, 10));
    var checkexpirydate = new Date(this.datepipe.transform(expirydate, 'yyyy-MM-dd')).valueOf();
    var reviseddate = new Date(this.datepipe.transform(event.value, 'yyyy-MM-dd')).valueOf();
    if (reviseddate > checkexpirydate) {
      this.revisedExpiryDate = event.value;
      this.errorMsg = "";
    } else {
      this.revisedExpiryDate = "";
      this.errorMsg = "please select revisedDate More than CurrentExpiry date";
    }

  }
  InputDaysExpiry(value) {
    let days = +value;
    if (days != 0) {
      var date: any = new Date(this.checkeventDate.slice(0, 10));
      this.revisedExpiryDate = date.setDate(date.getDate() + days);
      this.errorMsg = "";
    } else {
      this.revisedExpiryDate = '';
      this.errorMsg = "revised exiry date is required";
    }
  }
  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertChangeIpExpiryForm.value);
    let selectedChargeData = _.find(this.selectPurchaseList, { currentChargeId: apiInputData.purchase_id });
    let custId = parseInt(this.custId);
    let createDate = this.datepipe.transform(this.revisedExpiryDate, "yyyy-MM-dd");
    apiInputData.revised_expiry_date = createDate;
    let formatedData: any = {
      "currentChargeId": selectedChargeData.currentChargeId,
      "revisedExpiryDate": apiInputData.revised_expiry_date,
      "remarks": apiInputData.remarks
    };
    this.changeIpExpiryService.saveChangeIpExpiryCustId(custId, formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Change Ip Expiry Save Successfully.');
          this.customModalService.close('change-ip-expiry', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

}
