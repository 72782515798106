import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { SearchService } from '../../../../services/search.service';
import * as alertify from 'alertifyjs';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { IpPoolConfigService } from '../service/ip-pool-config.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../../core/constant/response-code';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-ip-pool-config-list',
  templateUrl: './ip-pool-config-list.component.html',
  styleUrls: ['./ip-pool-config-list.component.scss']
})
export class IpPoolConfigListComponent extends OldAbstractBaseListComponent implements OnInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('ippool', { static: false }) ippoolRef: TemplateRef<any>;
  @ViewChild('defaultipPool', { static: false }) defaultipPoolRef: TemplateRef<any>;
  @ViewChild('pooltype', { static: false }) poolTypeRef: TemplateRef<any>;
  @ViewChild('ipType', { static: false }) IpTypeRef: TemplateRef<any>;
  @ViewChild('ipppoool', { static: false }) ippoolRefff: TemplateRef<any>;
  //@ViewChild('content', { static: false }) contentRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  ipPoolConfigDataForGrid: any = { content: [] };
  ipPoolConfigGridSettings: any = {};
  show_update: any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredServiceDataForGrid: any = [];
  filteredIpPoolDataForGrid: any = [];
  data: any;
  closeResult: string;
  //multipleOptionsList = [];
  constructor(
    private ipPoolConfigService: IpPoolConfigService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private searchService: SearchService,
    private tosterservice: ToastrService,
    loginService: LoginService,
    public modalService: NgbModal,
  ) {
    super(searchService);
    this.loginService = loginService;
  }
  ngOnInit() {
    if(this.searchService.serverFilterObj && this.searchService.serverFilterObj.length > 0){
      this.searchService.resetGlobalSearchServerFilterObj();
    }
    this.setDefaultPageSize(this.tableKey.IP_POOL_CONFIG)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setGridSetting();
    // if(this.loginService.hasOperationPermission(AclClassConstants.ACL_IP_POOL,AclConstants.OPERATION_IP_POOL_DETAIL_VIEW,AclConstants.OPERATION_IP_POOL_ALL)){
    //   let ippoolObj = {key:'ipPoolDetail',label:'Ip Pool Detail'};
    //   this.multipleOptionsList.push(ippoolObj);
    // }
    this.initPaginationSort();
    this.subscriptions.push(filterSubscription);
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }

    this.dataSource.connect().subscribe((data) => {
      this.filteredData = data;
    });
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'isStaticIpPool') {
        col.customControls = [this.ippoolRef];
      }
      if (col.colKey == 'defaultPoolFlag') {
        col.customControls = [this.defaultipPoolRef];
      }
      if (col.colKey == 'poolType') {
        col.customControls = [this.poolTypeRef];
      }
      if (col.colKey == 'poolCategory') {
        col.customControls = [this.IpTypeRef];
      }
      if (col.colKey == 'displayName') {
        col.customControls = [this.ippoolRefff];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      // {
      //   colKey: 'poolName',
      //   default: 'Pool Name',
      //   rowKey: 'poolName',
      //   width: '20%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'displayName',
        default: 'poolName',
        rowKey: 'displayName',
        width: '15%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'poolCategory',
        default: 'Ip Type',
        rowKey: 'poolCategory',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'poolType',
        default: 'Pool Type',
        rowKey: 'poolType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },

      {
        colKey: 'defaultPoolFlag',
        default: 'Default ip pool',
        rowKey: 'defaultPoolFlag',
        width: '10%',
        customControls: [this.ippoolRef],
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'isStaticIpPool',
        default: 'static ip pool',
        rowKey: 'isStaticIpPool',
        width: '10%',
        customControls: [this.ippoolRef],
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },


      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'active') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
        //isMultipleOption: true
      }
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Pool Name',
        value_field: 'poolName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Ip Type',
        value_field: 'poolCategory',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Pool Type',
        value_field: 'poolType',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['active', 'inActive'],
          },
          {
            display_field: 'True',
            value_field: 'active',
          },
          {
            display_field: 'False',
            value_field: 'inActive',
          },
        ],
      }
    ];

    this.ipPoolConfigGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.IP_POOL_CONFIG,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Ip Pool Config ?',
      deleteMsgBoxDetail: '"Ip Pool Config Name : ${poolName} " ',
    };

    if (this.ipPoolConfigGridSettings.serverSideSortPagination && this.ipPoolConfigGridSettings.perPage) {
      this.setPaginationPerPage(this.ipPoolConfigGridSettings.perPage);
    }
  }

  getIpPoolConfigData(gridSetting: any) {
    let fetchSettingData = this.ipPoolConfigGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.ipPoolConfigService.getIpPoolConfig(params).subscribe(result => {
      this.ipPoolConfigDataForGrid = result.dataList;
      this.filteredIpPoolDataForGrid = JSON.parse(JSON.stringify(this.ipPoolConfigDataForGrid));
      this.refreshDataSource(this.ipPoolConfigDataForGrid);
      this.totalRecords = result.totalRecords;
      this.ipPoolConfigGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getIpPoolConfigData(config);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.ipPoolConfigDataForGrid));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.ipPoolConfigDataForGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    let self = this;
    if (event) {
      this.commonDeleteMsgBox(this.ipPoolConfigGridSettings, event, this.delete.bind(this));
    }
  }

  delete(objEvent) {
    let self = this;
    let selected_ipPool_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.ipPoolConfigService.getIpPoolConfigById(selected_ipPool_data.poolId).subscribe((result) => {
        this.ipPoolConfigService.deleteIpPoolConfig(result.data).subscribe(
          (data: any) => {
            if (data.responseCode === ResponseCode.OK) {
              this.tosterservice.success('Ip Pool Config is deleted successfully');

              self.ipPoolConfigDataForGrid.forEach(function (item, index) {
                if (item.poolId === data.data.poolId) {
                  self.ipPoolConfigDataForGrid.splice(index, 1);
                }
              });
              self.refreshDataSource(this.ipPoolConfigDataForGrid);
            }
            else {
              this.tosterservice.error(data.responseMessage)
            }

          }
        );

      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR + err.responseMessage);
      });
    }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/network-management/ip_pool_list/detail', rowData.poolId]);
  }

  onAddIpPoolConfigClick() {
    this.router.navigate(['/network-management/ip_pool_list/detail']);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getIpPoolConfigData(config);
  }

  ondataPass(content,data){
    this.data = data;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });  
}

  // onMultipleOptionClick(event){
  //   this.data = event.RowData;
  //   if(event.optionData.key == 'ipPoolDetail'){
  //     this.modalService.open(this.contentRef,{ ariaLabelledBy: 'modal-basic-title', size: 'xl' });
  //   }
  // }
}
