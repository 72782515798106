import { AfterViewInit, ViewEncapsulation, Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../services/message_service/message_service.service';

import { CityService } from '../city/city.service';
import { CountryService } from '../country/country.service';
import { StateService } from '../state/state.service';

declare let $: any;

import { PubSubService } from '../../../services/pubsub/pubsub.service';

import { AddSubscriberDetailsService } from './add-subscriber-details.service';

import { PlanServiceComponent } from './plan-service/plan-service.component';
import { PrimaryInformationComponent } from './primary-information/primary-information.component';

import _ from 'underscore';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AddPlanComponent } from '../../plan_management/price-book/add-plan/add-plan.component';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { OtherDetailsComponent } from './other-details/other-details.component';
import { AtestService } from '../../../services';
import { AddressInformationComponent } from './address-information/address-information.component';
import { InventoryAllocationComponent } from '../../inventory/inventory/inventory-allocation/inventory-allocation.component';
import { InventoryService } from '../../inventory/inventory/service/inventory.service';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-add-subscriber-details',
  templateUrl: './add-subscriber-details.component.html',
  styleUrls: ['./add-subscriber-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSubscriberDetailsComponent implements OnInit, AfterViewInit {
  $menuSection: any;
  el: ElementRef;
  currentFocus: any;
  messages: any[] = [];
  isEmailUnique: boolean;
  isUsernameUniue: boolean;
  isMobileUnique: boolean;
  isCafnoUnique: boolean;
  subscription: Subscription;
  isPrimaryTabValid: any = true;
  isotherDetailTabValid: any = true;
  isAddressTabValid: any = true;
  isPlanServiceTabValid: any = true;
  //isPlanValid:boolean=false;
  addSubscriberFormGroup: FormGroup;
  planDetailsData: any = {};
  chargeDetailsData: any = {};
  mactalListData: any = []
  partnerFlag: boolean;
  @ViewChild(PlanServiceComponent, { static: false })
  private planServiceComponent: PlanServiceComponent;
  @ViewChild(InventoryAllocationComponent, { static: true })
  private inventoryAllocationComponent: InventoryAllocationComponent;
  @ViewChild(OtherDetailsComponent, { static: false })
  private otherComp: OtherDetailsComponent;
  @ViewChild(PrimaryInformationComponent, { static: false })
  private primaryInformationComponent: PrimaryInformationComponent;
  @ViewChild(AddressInformationComponent, { static: false })
  private addressInformationComponent: AddressInformationComponent;


  saveBtn: boolean = false;

  partnerId: number;
  Stateid:number;
  showVerticalStrepper = false;
  isScrollFromTop: boolean;
  selectedVasId = [];
  constructor(el: ElementRef,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private pubsub: PubSubService,
    private datePipe: DatePipe,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private atestService: AtestService,
    private tosterservice: ToastrService,
    private sharingdataService:DataSharingServiceService,
    private inventoryService: InventoryService
  ) {
    if(window.innerWidth < 1000){
      this.showVerticalStrepper = true;
    }
    this.el = el;
    this.addSubscriberFormGroup = this.formBuilder.group({
      primaryInformation: [],
      addressInformation: [],
      planServiceInformation: [],
      otherInformation: []
    });

  }

  @HostListener('window:resize', ['$event'])
onResize(event) {
  if(window.innerWidth < 1000){
    this.showVerticalStrepper = true;
  }else{
    this.showVerticalStrepper = false;
  }
}

@HostListener('window:scroll', ['$event'])
onWindowScroll($event) {
    if(window.pageYOffset < 160){
      this.isScrollFromTop = false;
    }else{
      this.isScrollFromTop = true;
    }
}

  ngOnInit() {

    this.filedSetCollapse();

    this.atestService.getChargeTypeList().subscribe(result => {
      ////console.log(result);
    });
    ////console.log("add Form", this.addSubscriberFormGroup)

  }

  onStepChange(event){
    if(event.selectedIndex == 0){
      this.primaryInformationComponent.setFocusOnFirstElement('block1', 600);
    }
    if(event.selectedIndex == 1){
      this.addressInformationComponent.setFocusOnFirstElement('block1', 600);
    }
    if(event.selectedIndex == 2){
      this.planServiceComponent.setFocusOnFirstElement('planType', 600);
      this.onPlanAndService()
    }
    if(event.selectedIndex == 3){
      this.otherComp.setFocusOnFirstElement(null, 600);
    }
  }

  ngAfterViewInit() {

  }

  filedSetCollapse() {
    (function ($) {
      $.fn.collapsible = function (options) {
        var settings = $.extend(
          {
            collapsed: false,
            animation: true,
            speed: 'medium',
          },
          options
        );

        this.each(function () {
          var $fieldset = $(this);
          var $legend = $fieldset.children('legend');
          var isCollapsed = $fieldset.hasClass('collapsed');

          $legend.click(function () {
            collapse($fieldset, settings, !isCollapsed);
            isCollapsed = !isCollapsed;
          });

          // Perform initial collapse.
          // Don't use animation to close for initial collapse.
          if (isCollapsed) {
            collapse($fieldset, { animation: false }, isCollapsed);
          } else {
            collapse($fieldset, settings, isCollapsed);
          }
        });
      };

      /**
       * Collapse/uncollapse the specified fieldset.
       * @param {object} $fieldset
       * @param {object} options
       * @param {boolean} collapse
       */
      function collapse($fieldset, options, doCollapse) {
        let $container = $fieldset.find('div');
        if (doCollapse) {
          if (options.animation) {
            $container.slideUp(options.speed);
          } else {
            $container.hide();
          }
          $fieldset.removeClass('expanded').addClass('collapsed');
        } else {
          if (options.animation) {
            $container.slideDown(options.speed);
          } else {
            $container.show();
          }
          $fieldset.removeClass('collapsed').addClass('expanded');
        }
      }
    })($);

    $('fieldset.collapsible').collapsible();
  }

  transformInsertData(formData) {

    let formatedInputData: any = {};
    formatedInputData.custtype = 'prepaid';
    formatedInputData.failcount = 0;
    formatedInputData.billday = 0;
    formatedInputData.addressList = [];

    let primaryInformation = formData.primaryInformation;
    let planServiceInformation = formData.planServiceInformation;
    let otherInformation = formData.otherInformation;


    formatedInputData.title = primaryInformation.title;
    formatedInputData.custname = primaryInformation.name;
    formatedInputData.firstname = primaryInformation.firstname;
    formatedInputData.middlename = primaryInformation.middlename;
    formatedInputData.referralName=primaryInformation.referralName
    formatedInputData.custId=primaryInformation.custId 
    formatedInputData.customerSource=primaryInformation.customerSource;
    formatedInputData.lastname = primaryInformation.lastname;
    formatedInputData.autoGeneratePassword = primaryInformation.autoGeneratePassword;
    formatedInputData.contactperson = primaryInformation.contact_person;
    formatedInputData.gst = primaryInformation.gst;
    formatedInputData.gstenabled = primaryInformation.gstenabled;
    formatedInputData.pan = primaryInformation.pan;
    formatedInputData.aadhar = primaryInformation.aadhar;
    formatedInputData.cafno = primaryInformation.caf_no;
    formatedInputData.username = primaryInformation.username ? primaryInformation.username.trim() : null;
    formatedInputData.mactelflag = this.primaryInformationComponent.enableMactal;
    formatedInputData.phone = primaryInformation.telephone;
    formatedInputData.phone = primaryInformation.telephone;
    formatedInputData.mobile = primaryInformation.mobile_no;
    formatedInputData.email = primaryInformation.email;
    formatedInputData.altmobile = primaryInformation.alternate_mobile_no;
    formatedInputData.altphone = primaryInformation.alternate_telephone_no;
    formatedInputData.altemail = primaryInformation.alternate_email;
    formatedInputData.aadhaarImagePath = primaryInformation.imagePath;
    formatedInputData.aadhaarImageName = primaryInformation.imageName;
    formatedInputData.propertyType = primaryInformation.propertyType;
    
    formatedInputData.vlanFlag = this.primaryInformationComponent.enableVlan;
    formatedInputData.mobileverified = this.primaryInformationComponent.mobileverified;
    formatedInputData.aadharverified = this.primaryInformationComponent.aadharverified;
    formatedInputData.collectorId = primaryInformation.collectorid;
    formatedInputData.fax = primaryInformation.fax;
    formatedInputData.partnerid = this.partnerId;

    formatedInputData.bridgeType = otherInformation.bridgeType;
    if(otherInformation.feasibilityDistance && otherInformation.dataunit==1 ){
      formatedInputData.feasibilityDistance = otherInformation.feasibilityDistance +' '+ 'Km' ; 
    }else{
      if(otherInformation.feasibilityDistance){
        formatedInputData.feasibilityDistance = otherInformation.feasibilityDistance +' '+ 'Mtr' ; 
      }else{
        formatedInputData.feasibilityDistance=null;
      }
    }
    
    
    this.isUsernameUniue = true, this.isEmailUnique = true,
      this.isMobileUnique = true,
      this.isCafnoUnique = true;
    if (formatedInputData.username) {
      this.addSubscriberDetailsService.checkUniqueCustomerByUsername(formatedInputData.username).subscribe(res => {
        if (res.responseCode !== 200) {
          this.tosterservice.error(res.responseMessage);
          this.isUsernameUniue = false;
        }
      })
    }
    if ((formatedInputData.mobile !== undefined && formatedInputData.mobile !== null) && this.primaryInformationComponent.mobile.allowDuplicate === true) {
      this.addSubscriberDetailsService.checkUniqueCustomerByMobile(formatedInputData.mobile, null).subscribe(res => {
        if (res.responseCode !== 200) {
          this.tosterservice.error("Mobile number already exist");
          this.isMobileUnique = false;
        }
      })
    }
    //formatedInputData.email && (old)

    if ((formatedInputData.email !== undefined && formatedInputData.email !== null) && this.primaryInformationComponent.email.allowDuplicate === true) {
      this.addSubscriberDetailsService.checkUniqueCustomerByEmail(formatedInputData.email, null).subscribe(res => {
        if (res.responseCode !== 200) {
          this.tosterservice.error(res.responseMessage);
          this.isEmailUnique = false;
        }

      })
    }

    // //formatedInputData.cafno && (old)

    if ((formatedInputData.cafno !== undefined && formatedInputData.cafno !== null) && this.primaryInformationComponent.caf_no.allowDuplicate === true) {

      this.addSubscriberDetailsService.checkUniqueCustomerByCafno(formatedInputData.cafno).subscribe(res => {
        if (res.responseCode !== 200) {
          this.tosterservice.error(res.responseMessage);
          this.isCafnoUnique = false;
        }

      })
    }


    if (this.mactalListData && this.mactalListData.length) {
      formatedInputData.custMacMapppingList = this.mactalListData;
    }

    let presentAddressDetails: any = {
      addressType: 'Present'
    };
    presentAddressDetails.address1 = primaryInformation.address;
    presentAddressDetails.landmark = primaryInformation.landmark;
    presentAddressDetails.areaId = primaryInformation.area;
    presentAddressDetails.pincodeId = primaryInformation.pincode;
    presentAddressDetails.countryId = primaryInformation.countryId;
    presentAddressDetails.stateId = primaryInformation.stateId;
    presentAddressDetails.cityId = primaryInformation.cityId;
    presentAddressDetails.constituencyId = primaryInformation.constituencyId;
    presentAddressDetails.talukaId = primaryInformation.talukaId;
    presentAddressDetails.districtId = primaryInformation.districtId;
    presentAddressDetails.typeOfProperty = primaryInformation.typeOfProperty;
    presentAddressDetails.buildingId = primaryInformation.building;
    presentAddressDetails.blockNo = primaryInformation.block;
    presentAddressDetails.propertyType = primaryInformation.propertyType;
    

    formatedInputData.addressList.push(presentAddressDetails);

    let permanentAddressDetails: any = {
      addressType: 'Permanent'
    };

    let addressInformation = formData.addressInformation;
    permanentAddressDetails.address1 = addressInformation.address1;
    permanentAddressDetails.landmark = addressInformation.landmark1;
    permanentAddressDetails.areaId = addressInformation.area1;
    permanentAddressDetails.pincodeId = addressInformation.pincode1;
    permanentAddressDetails.countryId = addressInformation.countryId1;
    permanentAddressDetails.stateId = addressInformation.stateId1;
    permanentAddressDetails.cityId = addressInformation.cityId1;
    permanentAddressDetails.constituencyId = addressInformation.constituencyId1;
    permanentAddressDetails.talukaId = addressInformation.talukaId1;
    permanentAddressDetails.districtId = addressInformation.districtId1;
    permanentAddressDetails.buildingId = addressInformation.building1;
    permanentAddressDetails.typeOfProperty = addressInformation.typeOfProperty1;
    permanentAddressDetails.blockNo = addressInformation.block1;

    formatedInputData.addressList.push(permanentAddressDetails);

    let paymentAddressDetails: any = {
      addressType: 'Payment'
    };

    paymentAddressDetails.address1 = addressInformation.address2;
    paymentAddressDetails.landmark = addressInformation.landmark2;
    paymentAddressDetails.areaId = addressInformation.area2;
    paymentAddressDetails.pincodeId = addressInformation.pincode2;
    paymentAddressDetails.countryId = addressInformation.countryId2;
    paymentAddressDetails.stateId = addressInformation.stateId2;
    paymentAddressDetails.cityId = addressInformation.cityId2;
    paymentAddressDetails.constituencyId = addressInformation.constituencyId2;
    paymentAddressDetails.talukaId = addressInformation.talukaId2;
    paymentAddressDetails.districtId = addressInformation.districtId2;
    paymentAddressDetails.buildingId = addressInformation.building2;
    paymentAddressDetails.typeOfProperty = addressInformation.typeOfProperty2;
    paymentAddressDetails.blockNo = addressInformation.block2;

    formatedInputData.addressList.push(paymentAddressDetails);

    formatedInputData.paymentDetails = {};




    formatedInputData.salesrepid = primaryInformation.sales_representative_id;
    //formatedInputData.deposit = primaryInformation.deposit;

    formatedInputData.paymentDetails.amount = primaryInformation.payment_received;
    if (primaryInformation.payment_date && _.isDate(primaryInformation.payment_date)) {
      formatedInputData.paymentDetails.paymentdate = primaryInformation.payment_date;
    }
    formatedInputData.paymentDetails.paymode = primaryInformation.payment_mode;


    formatedInputData.paymentDetails.bank = primaryInformation.bank_name;
    formatedInputData.paymentDetails.branch = primaryInformation.bank_branch;
    if (primaryInformation.cheque_date && _.isDate(primaryInformation.cheque_date)) {
      formatedInputData.paymentDetails.chequedate = primaryInformation.cheque_date;
    }
    formatedInputData.paymentDetails.chequeno = primaryInformation.cheque_no;

    formatedInputData.paymentDetails.paymentreferenceno = primaryInformation.payment_reference_no;

    if (planServiceInformation && _.isEmpty(planServiceInformation) === false) {

      formatedInputData.voicesrvtype = planServiceInformation.voice_service_type;
      formatedInputData.didno = planServiceInformation.did_no;
      formatedInputData.childdidno = planServiceInformation.child_did_no;
      formatedInputData.didno = planServiceInformation.phoneline_did_no;
      formatedInputData.voipenableflag = planServiceInformation.voip_services;
      formatedInputData.emailenable = planServiceInformation.emailenable;
      formatedInputData.smsenable = planServiceInformation.smsenable;
      formatedInputData.onlinerenewalflag = planServiceInformation.allow_online_renewal;
      formatedInputData.intercomgrp = planServiceInformation.intercome_group;
      formatedInputData.isAutoTicketAllowed=planServiceInformation.checkEnableAutoticketing
      formatedInputData.intercomno = planServiceInformation.intercome_no;
    }



    // otherInformation set

    if (otherInformation && _.isEmpty(otherInformation) === false) {
      formatedInputData.networktype = otherInformation.network_type;
      formatedInputData.defaultpoolid = otherInformation.defult_pool;
      formatedInputData.serviceareaid = otherInformation.area;

      formatedInputData.networkdevicesid = otherInformation.olt;
      formatedInputData.oltportid = otherInformation.olt_port;
      formatedInputData.oltslotid = otherInformation.olt_slot;

      formatedInputData.strconntype = otherInformation.connection_type;
      formatedInputData.approvedBy = otherInformation.approvedBy;
      formatedInputData.reason = otherInformation.reason;
      formatedInputData.routerType = otherInformation.routerType;
      formatedInputData.connTypeFree = otherInformation.connTypeFree;
      formatedInputData.opticalPower = otherInformation.opticalPower;
      formatedInputData.onuid = otherInformation.onuid?  otherInformation.onuid.trim(): null;

      formatedInputData.latitude = otherInformation.latitude;
      formatedInputData.longitude = otherInformation.longitude;
      formatedInputData.url = otherInformation.url;
      formatedInputData.gis_code = otherInformation.gis_code;
      formatedInputData.salesremark = otherInformation.salesremark;
      formatedInputData.servicetype = otherInformation.servicetype;
      formatedInputData.ipPoolDtlsId = otherInformation.ipPoolDtlsId
      formatedInputData.poolId = otherInformation.poolId
      formatedInputData.iptvId = otherInformation.iptvId;
      formatedInputData.iptvSerialNo =  otherInformation.iptvSerialNo;
      formatedInputData.iptvMacAddress = otherInformation.iptvMacAddress;
      formatedInputData.iptvEthMacAddress = otherInformation.iptvEthMacAddress;

      // formatedInputData.stroltname = otherInformation.olt1;
      // formatedInputData.strportname = otherInformation.olt_port1;
      // formatedInputData.strslotname = otherInformation.olt_slot1;
    }


    if (this.chargeDetailsData && this.chargeDetailsData.length) {
      formatedInputData.overChargeList = []
      

      this.chargeDetailsData.forEach(chargeItem => {
        let newItem = {
          actualprice: chargeItem.actualprice,
          price: chargeItem.offerprice,
          chargeid: chargeItem.chargeid,
          chargetype: chargeItem.charge_type,
          validity: chargeItem.validity,
          charge_date: this.datePipe.transform(chargeItem.charge_date, 'yyyy-MM-dd'),
          // "id": chargeItem.actualprice,
          // "planid": 0,
          overridePrice:chargeItem.overridePrice,
          isPriceOverride:chargeItem.isPriceOverride,
          isIncludeTax:chargeItem.isIncludeTax
        };
        formatedInputData.overChargeList.push(newItem);
      });
    }

    if (this.planDetailsData && this.planDetailsData.length) {
      formatedInputData.planMappingList = [];
      this.planDetailsData.forEach(planItem => {

        let newItem = {
          "service": planItem.service_type,
          "planId": planItem.plan_id,
          "isIncludeTax": planItem.isIncludeTax,
          "isPriceOverride": planItem.isPriceOverride,
          "sellPrice": planItem.sellPrice,
          "selectedVASIds": this.selectedVasId
        };
        formatedInputData.planMappingList.push(newItem);
      });
    }
    return formatedInputData;
  }

  backToViewSubscriberList() {
    //this.router.navigate(['/masters/subscriber']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/masters/subscriber']);
    }
  }
  onPlanAndService() {
    this.planServiceComponent.ngOnInit()
  }

  onOtherDetails() {
    this.otherComp.ngOnInit()
  }

  onSubmit() {
    
    if (this.addSubscriberFormGroup.valid === true) {
      //this.saveBtn = true;
      //&& this.planServiceComponent.getPlanServiceListData().length===1
      this.isPrimaryTabValid = true;
      this.isAddressTabValid = true;
      this.isPlanServiceTabValid = true;
      this.isotherDetailTabValid = true;
    
      let planDetailsData = this.planServiceComponent.getPlanServiceListData();  
      this.selectedVasId = this.planServiceComponent.returnSelectedVasIds();
      let chargeDetailsData = this.planServiceComponent.getChargeDetailsData();
      ////console.log("charge details list", chargeDetailsData)
      let mactalListData = this.primaryInformationComponent.getMactalList();
      this.planDetailsData = planDetailsData;
      this.chargeDetailsData = chargeDetailsData;
      this.mactalListData = mactalListData;

      let formData = Object.assign({}, this.addSubscriberFormGroup.value);

      let formatedInputData = this.transformInsertData(formData);
      let title = 'Create Customer'
      let self = this
      if(this.planDetailsData && this.planDetailsData.length > 0){
        alertify.confirm(title, 'Are you sure you want to create a customer with this plan ' + '<b>'+this.planDetailsData[0].planname + '</b>' +  ' ?', function () {
          self.saveBtn = true;
          self.OnSaveApi(formatedInputData)
        },
        function () {
            
        });
      }else{
        alertify.confirm(title, 'Are you sure you want to create a customer without plan ?', function () {
          self.saveBtn = true;
          self.OnSaveApi(formatedInputData)
        },
        function () {
            
        });
      }

    } else {
      
     this.saveBtn = false;

      if (this.addSubscriberFormGroup.get('primaryInformation').valid === false) {
        this.isPrimaryTabValid = false;
        this.pubsub.$pub('primary_information_validation_fail', false);
      } else {
        this.isPrimaryTabValid = true;
      }
      if (this.addSubscriberFormGroup.get('otherInformation').valid === false) {
        this.isotherDetailTabValid = false;
        this.pubsub.$pub('other_details_validation_fail', false);
      } else {
        this.isotherDetailTabValid = true;
      }
      if (this.addSubscriberFormGroup.get('addressInformation').valid === false) {
        this.isAddressTabValid = false;
        this.pubsub.$pub('address_information_validation_fail', false);
      } else {
        this.isAddressTabValid = true;
      }
      if (this.addSubscriberFormGroup.get('planServiceInformation').valid === false) {
        this.isPlanServiceTabValid = false;
        this.pubsub.$pub('plan_service_information_validation_fail', false);
      } else {
        this.isPlanServiceTabValid = true;
      }
      this.tosterservice.error("Please fill all the details!");
      this.saveBtn=false;
    }
  }
  OnSaveApi(formatedInputData) {
     this.saveBtn=true;
    let self = this;
    setTimeout(function () {
      //&& self.isMobileUnique
      if (self.isUsernameUniue && self.isEmailUnique && self.isCafnoUnique && self.isMobileUnique) {
        self.saveBtn = true
     
        self.addSubscriberDetailsService.addSubscriberDetailsService(formatedInputData).subscribe((result) => {
          
          if (result.status !== 200) {
            self.saveBtn = false;
            if (result.ERROR) {
              self.tosterservice.error(result.ERROR);
            } else {
              self.tosterservice.error(result.responseMessage);
            }
          } else {
            self.saveBtn = false;
            self.inventoryService.saveInventoryStock(result.customer.id);
            self.tosterservice.success("Subscriber Saved Successfully.");
            self.backToViewSubscriberList();
          }

        }, err => {
          self.saveBtn = false;
          self.tosterservice.error(err.error.ERROR);
        }
        );
      }else{
        self.saveBtn=false;
        
      }
    }, 1000);
    
  }
  onPartnerIdSet(event) {
    this.partnerId = event
  }

  onstateidset(event){
    this.Stateid = event
  }
}
