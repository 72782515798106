import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ItemStockModal } from '../../inventory/modal/item-stock-modal';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';


@Component({
  selector: 'app-auto-complete-stock',
  templateUrl: './auto-complete-stock.component.html',
  styleUrls: ['./auto-complete-stock.component.scss']
})
export class AutoCompleteStockComponent implements OnInit {
  //Input Variables
  @Input() dataList: Array<ItemStockModal>;
  @Input() fields: Array<any>;
  @Input() placeHolderText: string;

  //Output Variables
  @Output() inputText: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();
  //Auto Complete Variables
  searchtype: any;

  constructor(public http: HttpClient) { }
 

  ngOnInit() {
  }

  onSearchDDL(search){
    if (search.term && search.term.length > 3) {
      setTimeout(() => {
        this.inputText.emit(search.term); 
      },700);
    }
  }

  onSelectStock(event){
    setTimeout(()=>{
      this.searchtype = undefined;
    },100);
    if(event){
      this.selectedItem.emit(event);
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      let search = item['search_label'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) != -1);
    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

}
