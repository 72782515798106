import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountingProfileService } from '../accounting-profile/accounting-profile.service';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
@Component({
  selector: 'app-add-accounting-profile',
  templateUrl: './add-accounting-profile.component.html',
  styleUrls: ['./add-accounting-profile.component.scss']
})

export class AddAccountingProfileComponent implements OnInit {
  saveBtn:boolean=false;
  show_update: any = false;
  public sub: any = null;
  public accounting_profile_id: any = null;
  selectForUpdate: any;
  error_message = "This field is required.";

  insertAccoutingProfileForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    checkitem: new FormControl('', ),
    enablecdr: new FormControl('',Validators.required),
    managesession: new FormControl('', [Validators.required]),
    priority: new FormControl('', [Validators.required]),
    mappingmasterid: new FormControl('', [Validators.required])
  });
  isDisabled:boolean=false;
  enableCDRList : any = [
    {text : "Active", value:"Y"},
    {text : "Inactive", value:"N"}
  ];
  dbmappingList : any = [
    {text : "CDR Mapping", value : 1}
  ];
  statusList : any = [
    {text : "Active", value:"A"},
    {text : "Inactive", value:"I"}
  ];
  manageSessionList : any = [
    {text : "Yes", value:"Y"},
    {text : "No", value:"N"}
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountingProfileService: AccountingProfileService,
    private cdRef: ChangeDetectorRef,
    private tosterservice:ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddAccountingProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) { 
      this.accounting_profile_id =id;
      this.show_update = true;
      this.getAccountingProfileDataById();
    }
    // if(this.data && this.data.id){
      
    //   this.isDisabled=true;
    //   this.accounting_profile_id = this.data.id;
    //   this.show_update = false;
    //   this.getAccountingProfileDataById();
    // }
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.accounting_profile_id = params['id'];
    //       this.show_update = true;
    //       this.getAccountingProfileDataById();
    //     }
    //   });
  }

  validateForm() {
    if (!this.insertAccoutingProfileForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertAccoutingProfileForm.controls).forEach(field => {
        const control = this.insertAccoutingProfileForm.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.accounting_profile_id === null) {
        this.insertNewAccountingProfile();
      } else {
        this.updateAccountingProfile();
      }
    }
  }

  setUpdateValueInForm(accountingData) {
    this.show_update = true;
    this.selectForUpdate = accountingData;

    this.insertAccoutingProfileForm.patchValue(accountingData);
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getAccountingProfileDataById() {
    let accounting_profile_id = this.accounting_profile_id;
    this.accountingProfileService.getAccountingProfile(accounting_profile_id).subscribe(
      result => {
        this.setUpdateValueInForm(result.data);
      }
    )
  }

  insertNewAccountingProfile() {
    if (this.insertAccoutingProfileForm.valid === true) {
      this.saveBtn =true;
      let formatedInputData: any = this.insertAccoutingProfileForm.value;
      this.accountingProfileService.insertAccountingProfile(formatedInputData).subscribe(result => {
        if(result.status !== 200){
          this.saveBtn =false;
          this.tosterservice.error(result.ERROR);
        }
        else{
          this.saveBtn =false;
          this.insertAccoutingProfileForm.reset({ enable_cdr: true });
          this.cdRef.detectChanges();
          this.backToViewAccountingProfile();
          this.tosterservice.success('Accounting Profile has been created successfully');
        }
       
      },
      err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  updateAccountingProfile() {
    if (this.insertAccoutingProfileForm.valid === true) {
      this.saveBtn =true;
      let formatedInputData: any = this.insertAccoutingProfileForm.value;
      let id = this.accounting_profile_id;
      this.accountingProfileService.updateAccountingProfile(formatedInputData, id).subscribe(result => {
        if(result.status !== 200){
          this.saveBtn =false;
          this.tosterservice.error(result.ERROR);
        }
        else{
          this.saveBtn =false;
          this.insertAccoutingProfileForm.reset({ status: true });
          this.cdRef.detectChanges();
          this.show_update = false;
          this.tosterservice.success('Accounting Profile Update successfully');
          this.backToViewAccountingProfile();
        }
       
      },
      err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  backToViewAccountingProfile() {
    // this.router.navigate(['radius/accounting-profile']); /**Old Code */
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
      this.router.navigate(['radius/accounting-profile']);
      /** Slash use Before Radisu */
      }

  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }
  closeDialog(){
    this.dialogRef.close();
   }
 


}
