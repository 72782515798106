import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { ValueAddedServiceService } from './value-added-service.service';
import { CustomModalService } from '../../../../../components/custom-modal';
import { debug } from 'console';
@Component({
  selector: 'app-value-added-services',
  templateUrl: './value-added-services.component.html',
  styleUrls: ['./value-added-services.component.scss']
})
export class ValueAddedServicesComponent implements OnInit {
  @Input() custId: any;
  @ViewChild('actionWrapper', { static: false }) actionWrapperRef: TemplateRef<any>;
  @ViewChild('provisionStatus', { static: false }) provisionStatusRef: TemplateRef<any>;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  valueAddedNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  openCancelPlanPopup: boolean;
  //isProvision: boolean = true;
  valueAddedTableData: any;
  provisionLogList: any[] = [];
  openProvisionStatus: boolean
  constructor(
    private valueAddedService: ValueAddedServiceService,
    private customeModalService: CustomModalService
  ) { }

  ngOnInit() {
    this.setGridSetting();
    this.getValueAddedService();
  }
  getValueAddedService() {
    this.valueAddedService.getValueAddedList(this.custId).subscribe((res) => {
      if (res.dataList && res.dataList.length > 0) {
        res.dataList.forEach((res) => {
          res.isProvision = true;
          res.isProvisionBtn = true;
        })
      }
      let isOnlyBasePlans: boolean = false;
      for (let i = 0; i < res.dataList.length; i++) {
        if (!res.dataList[i].isBase) {
          isOnlyBasePlans = true;
        }
      }

      if(isOnlyBasePlans){
        for(let i=0;i<res.dataList.length; i++)
        {
          for(let j=i;j<res.dataList.length; j++)
          {
            if(res.dataList[i].vasType == 'IPTV')
            {
              if ((res.dataList[i].vasType == res.dataList[j].vasType) && (res.dataList[i].custPackRelId == res.dataList[j].custPackRelId)) {
                res.dataList[i].isProvisionBtn = res.dataList[i].isBase;            
              }
            } 
            else {
              if(res.provisionStatus === 'Pending' || res.provisionStatus === 'Failed')
              {
                res.dataList[i].isProvisionBtn = res.isProvisionBtn;
    
              }
              else
              {
                res.dataList[i].isProvisionBtn = false;
              }
            }
          }
        } 
      }else{
        res.dataList.map((ls) => ls.isProvisionBtn = false);
      }

      this.refreshDataSource(res.dataList)
      this.valueAddedTableData = res.dataList;
      console.log("IPTV_____", res.dataList)

    });
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'provision') {
        col.customControls = [this.actionWrapperRef];
      }
      if (col.colKey === 'provisionStatus') {
        col.customControls = [this.provisionStatusRef];
      }
    });
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      // {
      //   colKey: 'service',
      //   default: 'Service',
      //   rowKey: 'service',
      //   width: '10%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      // {
      //   colKey: 'provisionId',
      //   default: 'Provison Id',
      //   rowKey: 'provisionId',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'vasName',
        default: 'VAS Name',
        rowKey: 'vasName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'vasType',
        default: 'VAS Type',
        rowKey: 'vasType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'planName',
        default: 'Plan Name',
        rowKey: 'planName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'startDate',
        default: 'Start Date',
        rowKey: 'startDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'expiryDate',
        default: 'Expiry Date',
        rowKey: 'expiryDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'provisionStatus',
        default: 'Provision Status',
        rowKey: 'provisionStatus',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'provision',
        default: 'Action',
        rowKey: 'provision',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }
    ];

    this.valueAddedNeoGridSettings = {
      showAdvanceSearch: false,
      showFilter: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.VALUE_ADDED_SERVICE_LIST,
      perPage: 5,
      tableHeight: 300,
      headerSticky: true,
      verticalScroll: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  Onprovision(id, index) {
    this.valueAddedService.provicsionset(id).subscribe((res) => {

    })
    this.valueAddedTableData[index].isProvision = false;
    this.refreshDataSource(this.valueAddedTableData);
    //this.isProvision = false
    setTimeout(() => {
      //this.isProvision = true;
      this.valueAddedTableData[index].isProvision = true;
      this.refreshDataSource(this.valueAddedTableData);
    }, 30000);

  }
  openModal(id: any, data: any) {
    if (id === 'provision-status') {
      this.openProvisionStatus = true;
      this.provisionLogList = data.provisionLogList;
      setTimeout(() => {
        this.customeModalService.open(id);
      }, 500);
    } else {
      this.openProvisionStatus = false;
    }
  }
  closeProvisionStatus(event) {
    this.openProvisionStatus = false;
  }
}

