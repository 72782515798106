import { PaginationData } from '../../core/modal/pagination-data';
import { OrderingData } from '../../core/modal/ordering-data';
import { SearchData } from '../../core/modal/search-data';
import { HttpParams } from '@angular/common/http';
import { SearchService } from '../../services/search.service';
import * as alertify from 'alertifyjs';
import { TableKeyEnum } from '../../core/enum/table-key.enum';
export abstract class OldAbstractBaseListComponent {
  public paginationData = new PaginationData();
  public orderingData = new OrderingData();
  public totalRecords: number = 0;
  public searchData = new SearchData();
  public tableName:string;
  
  constructor(private searchSrv: SearchService) {}
  public abstract getListWithParams();

  initPaginationSort() {
     
    this.setDefaultOrder();
    let GlobalName = this.tableName +' Global'
    let userId = localStorage.getItem('userId');
    let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));
    if(tableData[GlobalName] && tableData[GlobalName].filterValue){
    
    }
    else{
      this.getListWithParams();
    }
   
  }

  setDefaultPageSize(tableName: string){
    this.tableName = tableName
    let userId = localStorage.getItem('userId');
    if(localStorage.getItem('tableData_' + userId)){
      let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));
      if(tableName){
        if(tableData[tableName])
        this.paginationData.rowsPerPage = tableData[tableName];
        let name = tableName + ' Number' 
        if(tableData[name]){
          if(localStorage.getItem('partnerViewFlag') == 'true'){
            this.paginationData.pageNumber = 1
          }
          else{
            this.paginationData.pageNumber = Number(tableData[name]);
          }  
        }  
       
     
      }
    }
  }

  setPaginationPerPage(pageNumber) {
    if (pageNumber) {
     
      this.paginationData.rowsPerPage = pageNumber;
    }
  }

  setPagination(paginationData: PaginationData) {
    if (paginationData) {
      ////console.log('hiiii')
      this.paginationData = paginationData;
    }
    this.getListWithParams();
  }

  setDefaultOrder() {
    this.orderingData = new OrderingData();
    this.orderingData.column = 'id';
    this.orderingData.descending = false;
  }

  setSort(orderingData: OrderingData = null) {
    if (orderingData) {
      this.orderingData = orderingData;
    } else {
      this.setDefaultOrder();
    }
    this.getListWithParams();
  }

  getGridSettingInfo(fetch_setting_data) {
    let paginationData = fetch_setting_data ? fetch_setting_data.page : null;
    let orderingData = fetch_setting_data ? fetch_setting_data.order : null;
    let searchData =
      fetch_setting_data && fetch_setting_data.search && fetch_setting_data.search.length > 0
        ? JSON.stringify(fetch_setting_data.search)
        : null;
    let params:any = {};
    if (paginationData) {
      params.page = paginationData.pageNumber.toString();
      params.pageSize = paginationData.rowsPerPage.toString();
    }
    if (orderingData) {
      params.sortBy = orderingData.column;
      params.sortOrder = (orderingData.descending ? 1 : 0) + '';
    }
    if (searchData) {
      // let sData = JSON.parse(searchData);
      // sData.forEach(element => {
      //   let temp = JSON.parse(element.filterValue);

      //   element.filterValue = temp[element.filterColumn];
      // });
      params.filters = JSON.parse(searchData);
    }
    return params;
  }

  setHttpParams(fetch_setting_data) {
    let paginationData = fetch_setting_data ? fetch_setting_data.page : null;
    let orderingData = fetch_setting_data ? fetch_setting_data.order : null;
    let searchData =
      fetch_setting_data && fetch_setting_data.search && fetch_setting_data.search.length > 0
        ? JSON.stringify(fetch_setting_data.search)
        : null;
    let params = new HttpParams();
    if (paginationData) {
      params = params.set('page', paginationData.pageNumber.toString());
      params = params.set('pageSize', paginationData.rowsPerPage.toString());
    }
    if (orderingData) {
      params = params.set('sortBy', orderingData.column);
      params = params.set('sortOrder', (orderingData.descending ? 1 : 0) + '');
    }
    if (searchData) {
      params = params.set('search', searchData);
    }

    return params;
  }

  onFilterChange(filterObj) {
    let serverFilterObj: SearchData[] = this.searchSrv.getServerFilterObj();
    if (filterObj) {
      let searchData = new SearchData();
      searchData = filterObj;
      if (!Array.isArray(searchData) && searchData.filterColumn) {
        if (searchData.filterValue) {
          serverFilterObj = this.searchSrv.setServerFilterObj([searchData]);
        } else {
          serverFilterObj = this.searchSrv.resetGlobalSearchServerFilterObj();
        }
      } else {
        serverFilterObj = this.searchSrv.setServerFilterObj(filterObj);
      }
    } else {
      serverFilterObj = this.searchSrv.resetAdvanceSearchServerFilterObj();
    }
    this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
    this.getListWithParams();
    return serverFilterObj;
  }

  fillTemplate(templateString, templateVars) {
    var parsed = templateString;
    if (templateVars) {
      Object.keys(templateVars).forEach((key) => {
        const value = templateVars[key];
        parsed = parsed.replace('${' + key + '}', value);
      });
    }
    return parsed;
  }

  commonDeleteMsgBox(settings,event,callback): void {
    let self = this;
    let msgDetail = this.fillTemplate(settings.deleteMsgBoxDetail, event);
    alertify
      .confirm(
        settings.deleteMsgBoxTitle,
        msgDetail,
        function() {
          if (callback) {
            callback({ deleteClicked: true,event:event });
            //this.delete({ deleteClicked: true,event:event });
          }
        },
        function() {
          if (callback) {
            callback({ deleteClicked: false,event:event });
            //this.delete({ deleteClicked: false,event:event });
          }
        }
      )
      .setting('labels', { ok: 'Yes', cancel: 'No' })
      .set('defaultFocus', 'cancel');
  }

  /**Remove Trailing and Leading Space from FormControl Value */
  getTrimFormControlValue(fieldvalue:any){
    if(fieldvalue !== null){
      return fieldvalue.trim()
      } 
   }

   setAutoSelectDropDownList(data){
    if(data && data.length == 1){
      return data[0];
    }
  }
}
