import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'underscore';
import { SlotService } from '../../services/add-slot.service';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { NetworkDevicesService } from '../../services/network-devices.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../../../core/constant/response-code';

@Component({
  selector: 'app-add-slot',
  templateUrl: './add-slot.component.html',
  styleUrls: ['./add-slot.component.scss']
})
export class AddSlotComponent implements OnInit {

  public sub: any = null;
  public slotId: any = null;
  public networkId: any = null;
  show_update: any = false;
  selectForUpdate: any;
  insertSlotForm: FormGroup;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  portDataForGrid: any = { content: [] };
  portGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  portExist: boolean = false;
  existvalue: string;
  matchItems: any;
  currentIndex: number;
  touchedRows: any;
  status = [
    { label: "Active", value: "Y" },
    { label: "In Active", value: "N" },
  ]
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private slotService: SlotService,
    private networkDevicesService: NetworkDevicesService,
    private tosterservice: ToastrService
  ) { }

  ngOnInit() {
    this.insertSlotForm = new FormGroup({
      id: new FormControl({ value: '', disabled: !this.show_update }),
      name: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      oltPortDetailsList: new FormArray([])
    });

    let slotId = this.route.snapshot.paramMap.get('slot_id');
    if (slotId) {
      this.slotId = slotId;
      this.show_update = true;
      this.getSlotDataById();
    }

    let networkId = this.route.snapshot.paramMap.get('networkId');
    if (networkId) {
      this.networkId = networkId;
      this.show_update = false;
    }

    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['slot_id']
    //     if (id) {
    //       this.slotId = params['slot_id'];
    //       this.show_update = true;
    //       this.getSlotDataById();
    //     }
    //   });
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['networkId']
    //     if (id) {
    //       this.networkId = params['networkId'];
    //       this.show_update = false;
    //     }
    //   });
    this.touchedRows = [];
    //this.onAddPortItems();
  }

  onEdit(event) {
    this.setUpdateValueInForm(event.RowData);
  }

  getSlotDataById() {
    let slotId = this.slotId;
    this.slotService.getSlotDataById(slotId).subscribe(
      result => {
        let data = result.data;
        ////console.log("slot",data);
        this.setUpdateValueInForm(data);
      }
    )
  }

  setUpdateValueInForm(slot_Data) {
    this.show_update = true;
    this.selectForUpdate = slot_Data;
    for (let data of slot_Data.oltPortDetailsList) {
      this.onAddPortItems(data);
    }
    this.insertSlotForm.patchValue(slot_Data);
  }

  validateForm() {
    ////console.log(this.insertSlotForm)
    if (!this.insertSlotForm.valid) {
      Object.keys(this.insertSlotForm.controls).forEach(field => {
        const control = this.insertSlotForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.slotId === null) {
        this.insertSlotOnDb();
      } else {
        this.updateSlotOnDb();
      }
    }
  }

  onAddPortItems(row?) {

    this.portExist = false;
    (<FormArray>this.insertSlotForm.get('oltPortDetailsList')).push(
      new FormGroup({
        id: new FormControl(''),
        name: new FormControl(row ? row.port_name : '', [Validators.required]),
        status: new FormControl(row ? row.port_status : ''),
        isDeleted: new FormControl(true)
      })
    );
    ////console.log(this.insertSlotForm.get('oltPortDetailsList'));
  }

  get portItems() {
    return (this.insertSlotForm.get('oltPortDetailsList') as FormArray).controls;
  }

  onDelete(index: number) {
    (<FormArray>this.insertSlotForm.get('oltPortDetailsList')).removeAt(index);
  }

  insertSlotOnDb() {
    this.checkPort();
    if (this.insertSlotForm.valid === true && !this.portExist) {
      let apiInputData = Object.assign({}, this.insertSlotForm.value);
      let formatedInputData: any = this.insertSlotForm.value;
      formatedInputData.networkId = this.networkId;
      this.slotService.insertSlot(formatedInputData).subscribe(result => {
        if (result.responseCode === ResponseCode.OK) {
          this.cdRef.detectChanges();
          this.backToAddNetworkDevices();
          this.tosterservice.success('Slot Detail has been created successfully');
        }
        else {
          this.tosterservice.error(result.responseMessage);
        }
      },
        err => {
          this.tosterservice.error(err.responseCode + " " + err.responseMessage);
        });
    }
  }

  updateSlotOnDb() {
    this.checkPort();
    if (this.insertSlotForm.valid === true && !this.portExist) {
      let apiInputData = Object.assign({}, this.insertSlotForm.value);
      let formatedInputData: any = this.insertSlotForm.value;
      formatedInputData.id = this.slotId;
      formatedInputData.networkId = this.networkId;
      this.slotService.updatSlot(formatedInputData).subscribe(result => {
        if (result.responseCode === ResponseCode.OK) {
          this.cdRef.detectChanges();
          this.show_update = false;
          this.backToAddNetworkDevices();
          this.tosterservice.success('Slot Detail Update successfully');
        }
        else {
          this.tosterservice.error(result.responseMessage);
        }

      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERRORR);
        });
    }
  }

  onBack() {
    let formatedInputData: any = this.insertSlotForm.value;
    formatedInputData.networkId = this.networkId;
    this.backToAddNetworkDevices();
  }

  backToAddNetworkDevices() {
    this.router.navigate(['/network-management/network/add_network', this.insertSlotForm.value.networkId]);
  }

  editRow(Items: FormGroup) {
    Items.get('isDeleted').setValue(true);
  }
  checkPort(currentItems?) {
    this.portExist = false;
    if (currentItems != undefined) {
      this.portItems.forEach(item => {
        if (currentItems != item) {
          if (currentItems.get('name').value.replace(/\s/g, "") == item.get('name').value.replace(/\s/g, "")) {
            this.matchItems = currentItems.get('name').value;
            this.portExist = true;
            this.tosterservice.error("Port already exist");
          }
        }
      })
    } else {
      this.portExist = false;
      this.portItems.forEach(items => {
        this.portItems.forEach(itmes1 => {
          if (items != itmes1) {
            if (items.get('name').value.replace(/\s/g, "") === itmes1.get('name').value.replace(/\s/g, "")) {
              this.matchItems = items.get('name').value;
              this.portExist = true;
            }
          }
        })
      })
    }

  }
  doneRow(Items: FormGroup) {
    this.checkPort(Items);
    if (!this.portExist) {
      Items.get('isDeleted').setValue(false);
    }

    // this.portExist=false;
    //  if(this.insertSlotForm.value.oltPortDetailsList.length !==null  ){
    //  let PortOBJ= this.insertSlotForm.value.oltPortDetailsList.find(element => {
    //    if(element.name === Items.value.name && this.insertSlotForm.value.oltPortDetailsList.length > 1) {
    //        this.existvalue=element.name;
    //         return element;
    //       }
    //    else{
    //       this.portExist=false;
    //       this.tosterservice.success("Port Added Successfully")
    //       Items.get('isDeleted').setValue(false);
    //        return element; 
    //       }
    //     });

    //     if(PortOBJ){
    //             this.portExist = true;
    //           }
    //       else{
    //             this.portExist =  false;
    //             PortOBJ = {}
    //          }
    //    }     

  }
}
