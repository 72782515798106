import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, Optional, Inject,ViewChild, ElementRef, TemplateRef } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { CountryService } from './../country/country.service';
import { StateService } from './../state/state.service';
import { CityService } from './../city/city.service';
import { FranchiesService } from './../franchies/franchies.service';

import { TaxService } from './../../plan_management/tax/tax.service';
import { HostListener } from '@angular/core';

import { FormGroup, FormControl, Validators, FormArray, PatternValidator } from '@angular/forms';
import _ from 'underscore';
import { ActivatedRoute, Router } from '@angular/router';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchControlTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { ServiceAreaService } from '../../network-management/service-area/service/service-area.service';
// import { CommonListService } from '@neoboss/services/common_list/common_list.service';
// import { CommonListServicesService } from '@neoboss/services/common_list_service/common-list-services.service';
import { fromArray } from 'rxjs-compat/observable/fromArray';
// import { PaymentModeService } from '@neoboss/services/payment_mode/payment_mode.service';
// import { AddBalance, ReverseBalance } from '@neoboss/pages/masters/add-franchies/model/add-balance';
import { DatePipe } from '@angular/common';
// import { PricebookService } from '@neoboss/pages/plan_management/price-book/price-book.service';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../core/constant/response-code';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogPassData {
  id: number;
}

import { ValidatorFn } from '@angular/forms';
import { BillingTemplateService } from '../../billing_system/billing_template/billing_template.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material';
import { PartnerBalanceTopupService } from '../../payment-gateway/partner-balance-top-up/partner-balance-top-up.service';
import { IppoolServices } from '../../../services/ippool/ippool.service';
import { AllocationIpSelectService } from '../subscriber-details/allocation-ip-select/allocation-ip-select.service';
import { LoginService } from '../../login/login.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { IppoolConfigComponent } from './ippool-config/ippool-config.component';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
import { DunningRuleActionService } from '../../plan_management/dunning/service/dunning-rule-action.service';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { PaymentModeService } from '../../../services/payment_mode/payment_mode.service';
import { PricebookService } from '../../plan_management/price-book/price-book.service';
import { AddBalance, ReverseBalance } from './model/add-balance';
import { StaffService } from '../../hr/staff/staff.service';

declare var $: any;
@Component({
  selector: 'app-add-franchies',
  templateUrl: './add-franchies.component.html',
  styleUrls: ['./add-franchies.component.scss']
})
export class AddFranchiesComponent implements OnInit, OnDestroy {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('actionBtns', { static: false }) actionBtnsRef: TemplateRef<any>;
  @ViewChild('ipPoolConfig', { static: false }) ipPoolConfig: IppoolConfigComponent;

  franchies_operation_type: any;
  searchPincodeLink: string = '';
  parentpin:any
  default_pincode_value:string;
  openmanageentity: boolean;
  openippoolconfig: boolean = false;
  saveBtn: boolean = false;
  submitted: boolean = false;
  mobileErroeshow:boolean=false;
  error_message = "This field is required.";
  emailError = "required valid email";
  mobileError = "required 10 digit";
  pincodeError = "required 6 digit";
  public franchies_id: any = null;
  validEmail:boolean=true;
  validMobile:boolean=true
  tableKeyEnum = TableKeyEnum;
  public sub: any = null;
  filterValue:any;
  showPartnerLedger: boolean = false;
  showPartnerconfiguration:boolean=false;
  public ispaymentMode: string;
  status_list: any;
  partnerIdValue: any;
  responsemsg: any;
  isPartnerPoolConfig:any;
  paymentisUpdate:boolean = false;
  configisUpdate:boolean = false;
  notificationGroups:any[]=[]
  commision_type:any;
  dunningRuleList:any;
  errorShow: boolean = false;
  billingTemplateList:any=[];
  creditnoteTemplateList:any=[];
  responsemsgmobile:string;
  paymentReceiptTemplateList:any=[];
  PGsubmitted:boolean =false;
  poolList:any;
  isDisabled:boolean=false;
  dropdownFlag:boolean=false;
  dynabindLabel :any
  dynabindValue:any
  submitedFlag:boolean=false;
  taxCalculation:any;
  public franchiesList: any[] = [{
    franchies_name: '',
    franchies_id: ''
  }];
  public countryList: any[] = [{
    country_name: '',
    country_id: ''
  }];
  public stateList: any[] = [{
    state_name: '',
    state_id: ''
  }];
  public cityList: any[] = [{
    city_name: '',
    city_id: ''
  }];
  public areaList: any[] = [{
    area_name: '',
    area_id: ''
  }];
  public taxList: any[] = [{
    tax_name: '',
    tax_id: ''
  }];
  public tdstaxList: any[] = [];
  chargeTypeList: any;
  isSplitEnabled:boolean=false;
  chargeType:any;
  comType:boolean = false;
  public paymentModeList: any;
  data: any;
  isCommissionType: boolean;
  commonYN:any
  // commission_day_list: any;
  address_type: any;
  commission_type_list: any;
  selectForUpdate: any;
  price_Book_List: any;
  pgListCombo:any;
  show_update: any = false;
  currentReverseDate: Date;
  public partnerBalance: number;
  roleList: any[] = [
    { id: '', rolename: '' }
  ];

  franchiesOperationTypeList=[
    {label:'Primary Details'},
    {label:'Manage Entity',
      items:[
        {
          label:'Add Balance',
        },
        {
          label:'Reverse Balance',
        },
        {
          label:'Payment Configuration',
        },
        {
          label:'IP Pool Configuration',
        },
        {
          label:'View Commissione',
        },
        {
          label:'Partner Payment Details',
        },
        {
          label:'Partner Configuration',
        },
        {
          label:'ACL Configuration'   
        }

      ]
    },
    {label:'Partner ledger'},
  ]
  tax_calculation_List=[];


  reverseBalanceForm = new FormGroup({
    amount: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    paymentdate: new FormControl(null, [Validators.required]),
  });
  partnerPaymentDetails = new FormGroup({
    END_DATE: new FormControl(null),
    START_DATE: new FormControl(null)
  });
  addBalanceForm = new FormGroup({
    amount: new FormControl(null, [Validators.required]),
    bank_name: new FormControl(null),
    branch_name: new FormControl(null),
    chequedate: new FormControl(null),
    chequenumber: new FormControl(null),
    description: new FormControl(null, [Validators.required]),
    paymentdate: new FormControl(null, [Validators.required]),
    paymentmode: new FormControl(null, [Validators.required]),
    refno: new FormControl(null)
  });
  payGateway_config = new FormGroup({
    paymentGateway: new FormControl(null, [Validators.required]),
    isDefault: new FormControl(null,[Validators.required]),
    splitEnabled: new FormControl(null),
    merchentId: new FormControl(null),
    chargeType: new FormControl(null,[Validators.required]),
    percent: new FormControl(null,[Validators.required,Validators.max(100.00)]),
    pg_charges: new FormArray([])
  });
  partner_config = new FormGroup({
    configuration: new FormControl(null, [Validators.required]),
    value: new FormControl(null,[Validators.required]),
  });
  insertFranchiesForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    commission_type: new FormControl('', [Validators.required]),
    commission_value: new FormControl(null),
    // commission_day: new FormControl(null, [Validators.required]),
    address_type: new FormControl(null, [Validators.required]),
    tax_id: new FormControl(null),
    tdsTax: new FormControl(null),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    country_id: new FormControl(null, [Validators.required]),
    state_id: new FormControl(null, [Validators.required]),
    city_id: new FormControl(null, [Validators.required]),
    Country: new FormControl(null),
    State: new FormControl(null),
    City: new FormControl(null),
    pincode: new FormControl('', [Validators.required]),
    pincodeId:new FormControl(null),
    mobile: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    status: new FormControl(null, [Validators.required]),
    serviceAreaIds: new FormControl(null, [Validators.required]),
    parent_franchies_id: new FormControl(null),
    price_book: new FormControl(null,[Validators.required]),
    billingTemplate: new FormControl(null, [Validators.required]),
    creditNoteTemplate: new FormControl(null,[Validators.required]),
    receiptTemplate: new FormControl(null, [Validators.required]),
    notifiGroupId:new FormControl(null, [Validators.required]),
    
    dunningRuleId:new FormControl(null),
    merchantId:new FormControl(''),
    taxCalculation:new FormControl(null,[Validators.required])
  });
  configlist: any;
  configid: any;
  configValueList:any

  constructor(
    private datePipe: DatePipe,
    private poolService:AllocationIpSelectService,
    private priceBook: PricebookService,
    private ngbModalService: NgbModal,
    private countryService: CountryService,
    private commonListService: CommonListServicesService,
    private stateService: StateService,
    private cityService: CityService,
    private dunningRuleService: DunningRuleActionService,
    private serviceAreaService: ServiceAreaService,
    private franchiesService: FranchiesService,
    private paymentModeService: PaymentModeService,
    private taxService: TaxService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private tosterservice : ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddFranchiesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogdata: DialogPassData,
    private billingTemplateService:BillingTemplateService,
    private paymentgatewayservice :PartnerBalanceTopupService,
    public loginService: LoginService,
    private tokenservice: TokenStorageService,
    private staffService:StaffService
  ) {
  }
  balance: AddBalance = new AddBalance();
  reverseBalance: ReverseBalance = new ReverseBalance();
  allColumns:any=[];
  allColumnspr:any=[];
  allColumnRole:any=[];//new
  searchSettings = [];
  searchSettings1 = [];
  searchSettingsRole=[];//new
  neoTableSetting:any={};
  neoTableSettings:any={};
  neoTableSettingRole:any={};//new
  dataSource = new MatTableDataSource([]);
  dataSource1 = new MatTableDataSource([]);
  dataSourceRole = new MatTableDataSource([]);
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  dropdownFillURL: string;
  ngOnInit() {
    this.getTableColumn();
    this.getTableColumns();
    this.getTableColumnsRole();//new
    // this.getTableSettingsRole();
    let id = this.route.snapshot.paramMap.get('id')
    if (id) {
      this.isDisabled=false;
      this.franchies_id = id;
      this.show_update = true;
      this.showPartnerLedger = true;
      this.showPartnerconfiguration=true;
      this.getFranchiesDataById();
    }

    this.franchies_operation_type = this.franchiesOperationTypeList.find(res=>res.label == 'primary Details')
    this.onFranchiesOperationTypeChange('primary Details')

    if(this.dialogdata && this.dialogdata.id){
      this.isDisabled=true;
      this.franchies_id = this.dialogdata.id;
      // this.isClosed=true;
      this.show_update = false;
      this.showPartnerLedger = false;
      this.getFranchiesDataById();
      this.insertFranchiesForm.disable();

    }
    this.getAddressMapping();
    this.getPincode();
    this.getBillingTemplateForPartner();
    this.getCreditnoteTemplateForPartner();
    this.getPaymentReceiptTemplateForPartner();
    //this.getCountry();
    this.getAreaList();
    this.getFranchiesList();
    this.getTaxList();
    this.getTdsTaxList();
    this.getDunningRuleList();
    
    this.commonList();
    this.notificationGroup();
 
    if(this.show_update){
      this.getPGBypartner(this.franchies_id);
    }
    if(this.show_update){
      this.getConfigBypartner(this.franchies_id);
    }
   
    if (this.show_update) {
      this.getPaymentMode();
    }
    this.getChargeTypeList();
    // ////console.log(this.insertFranchiesForm);
    this.getAllRole();
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
     
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
    this.allColumnspr.forEach((col) => {
     
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnsRef];
      }
    });
  }
  cancelmodal(){
    this.payGateway_config.reset();
    this.ngbModalService.dismissAll();
  }
  getPGBypartner(id){
    this.franchiesService.getPgByPartner(id).subscribe((res)=>{
      this.neoTableSetting.pageTotalLength = res.totalRecords
  this.refreshdataSource(res.dataList)
    });
    this.paymentgatewayservice.getPaymentGatewayList().subscribe((res)=>{
this.pgListCombo = res.dataList; 
    });


  }
  getDunningRuleList(){
    this.dunningRuleService.dunningRuleGetall().subscribe((res)=>{
      this.dunningRuleList  = res.dataList;
    })
  }

  getConfigBypartner(id){
    this.franchiesService.getconfigByPartner(id).subscribe((res)=>{
      this.neoTableSettings.pageTotalLength = res.totalRecords
      this.refreshdataSource1(res.dataList)
    });
    this.franchiesService.getconfiglist().subscribe((res)=>{
    this.configlist = res.dataList; 
    });


  }
  onchangeconfig(obj,event){
    this.partner_config.get('value').reset();
    if(obj && obj.valueType && obj.valueType.toLowerCase() == 'combo'){
      this.dropdownFlag = true
      this.dynabindLabel =  obj.displayKey;
      this.dynabindValue =  obj.valueKey;
      this.onConfigValue();
    }
    else if(obj && obj.valueType && obj.valueType.toLowerCase() != 'combo')
    {
      this.partner_config.get('value').setValue(event.value)
    }
    else{
      this.dropdownFlag = false;
    }
    this.dropdownFillURL = obj.url;
    if(this.dropdownFillURL){
      this.dropdownFillURL = this.dropdownFillURL.substring(1); 
     
        this.franchiesService.getconfigValue(this.dropdownFillURL).subscribe((res)=>{
          this.configValueList  = res.dataList;
          let  temp =  this.configValueList.find(res=>res[this.dynabindValue]==Number(event.value))
          console.log(temp)
          if(temp)
            this.partner_config.get('value').setValue(temp.id);
        });
    
    }
   
   
  }

  getPincode() {
    this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
  }

  getAddressMapping(){
    let temp = this.tokenservice.getAddressLevel()
    let tempn = temp.filter(res=> (res.name.toLowerCase() == 'pincode' ))
    this.parentpin = tempn[0].levelNo
  }

  getPincodeAllAndSetValue(pincode: any,) {
    this.default_pincode_value = pincode;
  }

  onPincodeChange(elItem) {
    let el;
    let call_from_autocomplete;
    if (_.isArray(elItem.event) == true) {
      el = elItem.event[0];
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    if (el && el.name ) {
      this.insertFranchiesForm.controls.pincode.setValue(el.addressDetails.pincode);
      this.insertFranchiesForm.controls.pincodeId.setValue(el.addressDetails.pincodeId);
      this.insertFranchiesForm.patchValue({ Country: el.addressDetails.countryId});
      this.insertFranchiesForm.patchValue({ State: el.addressDetails.stateId});
      this.insertFranchiesForm.patchValue({ City: el.addressDetails.cityId});
      this.insertFranchiesForm.patchValue({ country_id: el.addressDetails.country});
      this.insertFranchiesForm.patchValue({ state_id: el.addressDetails.state});
      this.insertFranchiesForm.patchValue({ city_id: el.addressDetails.city});
    }
    else if(el == null){
      this.insertFranchiesForm.get('pincode').reset();
      this.insertFranchiesForm.get('pincodeId').reset();
      this.insertFranchiesForm.get('country_id').reset();
      this.insertFranchiesForm.get('state_id').reset();
      this.insertFranchiesForm.get('city_id').reset();
      this.insertFranchiesForm.get('Country').reset();
      this.insertFranchiesForm.get('State').reset();
      this.insertFranchiesForm.get('City').reset();
    }
  }

  onConfigValue(){
    if(this.dropdownFillURL){
      this.franchiesService.getconfigValue(this.dropdownFillURL).subscribe((res)=>{
        this.configValueList  = res.dataList
      })
    }
  }

  getChargeTypeList(){
    this.commonListService.getGenericCommonList('pgChargeType').subscribe((res)=>{
     this.chargeTypeList = res.dataList;
    })
  }

  onToggle(event){
    this.comType = false;
    this.payGateway_config.get('merchentId').reset()
    this.payGateway_config.updateValueAndValidity();
    if(event && event.checked == true){
      this.comType = true;
      this.payGateway_config.get('merchentId').setValidators([Validators.required]);
    }
  }

  onChangepymtGat(event){
    this.isSplitEnabled = false
    this.chargeType = null;
    this.payGateway_config.get('splitEnabled').clearValidators();
    this.payGateway_config.get('merchentId').clearValidators();
    this.payGateway_config.get('splitEnabled').reset();
    this.payGateway_config.get('merchentId').reset();
    this.payGateway_config.get('chargeType').reset();
    this.payGateway_config.get('percent').reset();
    if(event && event.isSplitEnabled == true){
      this.isSplitEnabled = true
    }
  }

  onChangeChargeType(event){
    this.chargeType = null;
    (<FormArray>this.payGateway_config.get('pg_charges')).reset();
    (<FormArray>this.payGateway_config.get('pg_charges')).clear();
    //(<FormArray>this.payGateway_config.get('pg_charges')).controls.reset();
    if(event && event.value){
      this.chargeType = event.value
    }
  }

  setCharges(row){
    (<FormArray>this.payGateway_config.get('pg_charges')).push(
      new FormGroup({
        amountFrom: new FormControl(row ? row.amountFrom: null,[Validators.required]),
        amountTo: new FormControl(row ? row.amountTo: null,[Validators.required]),
        percent: new FormControl(row ? row.percent:null,[Validators.required,Validators.max(100.00)]),
      })
    );
  }

  onAddCharges() {
    let length = (<FormArray>this.payGateway_config.get('pg_charges')).controls.length
    //console.log((<FormArray>this.payGateway_config.get('pg_charges')).controls.length);
    if((<FormArray>this.payGateway_config.get('pg_charges')).controls.length == 0){
      (<FormArray>this.payGateway_config.get('pg_charges')).push(
      new FormGroup({
        amountFrom: new FormControl(null,[Validators.required]),
        amountTo: new FormControl(null,[Validators.required]),
        percent: new FormControl(null,[Validators.required,Validators.max(100.00)]),
      })
      );
      (<FormArray>this.payGateway_config.get('pg_charges')).controls[0].get('amountFrom').setValue(Number(0));
    }
    else{
      // console.log('-->',(<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountFrom').value);
      // console.log('--->',(<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').value);
      if((<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').value!=null){
        if(((<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').value)>(<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountFrom').value){
          (<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').disable();
          (<FormArray>this.payGateway_config.get('pg_charges')).push(
          new FormGroup({
            amountFrom: new FormControl(null,[Validators.required]),
            amountTo: new FormControl(null,[Validators.required]),
            percent: new FormControl(null,[Validators.required,Validators.max(100.00)]),
          })
          );
          (<FormArray>this.payGateway_config.get('pg_charges')).controls[length].get('amountFrom').setValue(Number((<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').value) + 1);
        }
        else{
          this.tosterservice.error('Enter To Amount Greater Than From Amount');
        }
      }
      else{
        this.tosterservice.error('Enter To Amount');
      }
    }
  }

  get pg_charges() {
    return (this.payGateway_config.get('pg_charges') as FormArray).controls;
  }

  onDelete() {
    let length = (<FormArray>this.payGateway_config.get('pg_charges')).controls.length;
    (<FormArray>this.payGateway_config.get('pg_charges')).removeAt(length-1);
    if(length > 1){
      (<FormArray>this.payGateway_config.get('pg_charges')).controls[length-2].get('amountTo').enable();
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  keypressFloat(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getBillingTemplateForPartner(billingTemplate?: any){
    this.billingTemplateService.getBillingTemplateForPartner().subscribe((res) => {
     this.billingTemplateList = res.billrunList;
     if (billingTemplate) {
       ////console.log("billingTemplate",billingTemplate);
      this.insertFranchiesForm.patchValue({ billingTemplate: billingTemplate });
    }
    });
  }

  getCreditnoteTemplateForPartner(creditNoteTemplate?: any){
    this.billingTemplateService.getCreditnoteTemplateForPartner().subscribe((res) => {
     this.creditnoteTemplateList = res.billrunList;
     if (creditNoteTemplate) {
       ////console.log("billingTemplate",billingTemplate);
      this.insertFranchiesForm.patchValue({ creditNoteTemplate: creditNoteTemplate });
    }
    });
  }

  addNewPaymentGateWay(payment){ 
    this.Pgid = null 
    this.paymentisUpdate = false; 
    this.payGateway_config.reset();
    this.chargeType = null;
    this.isSplitEnabled = false;
    (<FormArray>this.payGateway_config.get('pg_charges')).clear();
    this.comType = false;
    setTimeout(() => {
      this.ngbModalService.open(payment, { size: 'lg' });
    }, 200); 
  }

  addNewPartnerConfig(config){ 
      this.configid = null 
      this.configisUpdate = false; 
      this.partner_config.reset();
      this.ngbModalService.open(config, { size: 'lg' });
  }
  onmanageentity() {
    this.openmanageentity = true;
    
  }
  isPartnerPoolEnable(event){
    this.isPartnerPoolConfig = event.checked
      this.updateFranchise()
  }
  onippoolconfig(){
    this.openippoolconfig = true;
  }

  onAddippoolClick() {
    //this.docId = null;
    this.openippoolconfig = true;
    //this.openmanageentity = false;
  }

  onBackToippool() {
    //this.docId = null;
    this.openippoolconfig = false;
    this.ipPoolConfig.ngOnInit();
    //this.openmanageentity = true;
  }
  savePG(){
      let data = {
        partnerid : this.franchies_id,
        paymentGateWayId : this.payGateway_config.get('paymentGateway').value,
        defaultpgflag : this.payGateway_config.get('isDefault').value,
        chargeType: this.payGateway_config.get('chargeType').value,
        percent: Number(this.payGateway_config.get('percent').value),
        isSplitEnabled:this.comType
      }
      if(this.chargeType == 'Slab'){
        data['partnerPGCharges'] = this.payGateway_config.get('pg_charges').value
      }
      if(this.payGateway_config.get('splitEnabled').value == true){
        data['merchantKey'] = this.payGateway_config.get('merchentId').value
      }
      ////console.log("before api data ===>",data)
    this.franchiesService.savePgConfig(data).subscribe((res)=>{
      ////console.log("save pg-->",res)
      if(res.responseCode !== 200){
        this.tosterservice.error(res.responseMessage)
      }
      else{
        this.neoTableSetting.pageTotalLength = res.totalRecords
        this.tosterservice.success("Payment Gateway Saved Successfully");
        this.ngbModalService.dismissAll();
      }
      setTimeout(() => {
        this.getPGBypartner(this.franchies_id);
      }, 400);
      
    })
  }

  saveconfig(){
    let data = {
      partnerid : this.franchies_id,
      clientServiceId : this.partner_config.get('configuration').value,
      value : this.partner_config.get('value').value
    }
    ////console.log("before api data ===>",data)
  this.franchiesService.savePartnerConfig(data).subscribe((res)=>{
    ////console.log("save pg-->",res)
    if(res.responseCode !== 200){
      this.tosterservice.error(res.responseMessage)
    }
    else{
      this.neoTableSettings.pageTotalLength = res.totalRecords
      this.tosterservice.success("Partner Configuration Saved Successfully");
    }
    this.ngbModalService.dismissAll();
    setTimeout(() => {
      this.getConfigBypartner(this.franchies_id);
    }, 400);
    
  })
  }


  getPaymentReceiptTemplateForPartner(receiptTemplate?: any){
    this.billingTemplateService.getPaymentReceiptTemplateForPartner().subscribe((res) => {
     this.paymentReceiptTemplateList = res.billrunList;
     if (receiptTemplate) {
      this.insertFranchiesForm.patchValue({ receiptTemplate: receiptTemplate });
    }
    });
  }
  
  
  getPaymentMode() {
    ////console.log("in payment mode list ")
    this.currentReverseDate = new Date();
    this.reverseBalance.paymentdate = this.datePipe.transform(this.currentReverseDate, 'yyyy-MM-dd')
    this.paymentModeService.getPaymentModeList().subscribe((res) => {
      this.paymentModeList = res.content;
      ////console.log("P..", this.paymentModeList)
    });

  }

  checkUniqueEmail(event) {
    if(event.which===32 && event.code==="Space"){
      event.preventDefault();
     }else{
      let emailvalue = event.target.value;
      if (this.franchies_id != null) {
        this.partnerIdValue = this.franchies_id;
      }
  
      let dataObj = {
        "partnerId": this.partnerIdValue ? this.partnerIdValue : null,
        "value": emailvalue
      }
      this.franchiesService.checkUniqueEmail(dataObj).subscribe((res) => {
        if (res.responseCode !== 200) {
          this.errorShow = true;
          this.responsemsg = res.responseMessage;
        }
      }
      )
      this.errorShow = false;
     }
    
  }
  checkUniqueMobile(event) {
    if(event.which===32 && event.code==="Space"){
      event.preventDefault();
     }else{
      let mobilevalue = event.target.value;
      if (this.franchies_id != null) {
        this.partnerIdValue = this.franchies_id;
      }
  
      let dataObj = {
        "partnerId": this.partnerIdValue ? this.partnerIdValue : null,
        "value": mobilevalue
      }
      this.franchiesService.checkUniqueMobile(dataObj).subscribe((res) => {
        if (res.responseCode !== 200) {
          this.mobileErroeshow = true;
          this.responsemsgmobile = res.responseMessage;
        }
      }
      )
      this.errorShow = false;
      this.mobileErroeshow=false;
     }
    
  }

  getPriceBookList(type) {
    this.priceBook.getActivePriceBook(type).subscribe((res) => {
      this.price_Book_List = res.dataList;
    });
  }

  validateForm() {

    if (!this.insertFranchiesForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertFranchiesForm.controls).forEach(field => {
        const control = this.insertFranchiesForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {

      if (!this.errorShow === true) {
        if (this.franchies_id === null) {
          this.insertNewFranchise();
        } else {
          this.updateFranchise();
        }
      }

    }
  }
  validateFormPg() {
    for (const prop in (<FormArray>this.payGateway_config.get('pg_charges')).controls) {
      this.payGateway_config.controls.pg_charges.value[prop]['amountTo'] = (<FormArray>this.payGateway_config.get('pg_charges')).controls[prop].get('amountTo').value
    }
    //console.log('-->',this.payGateway_config)
    if (!this.payGateway_config.valid) {
      this.submitted = true;
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.payGateway_config.controls).forEach(field => {
        const control = this.payGateway_config.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      this.submitted = false;
      if (!this.errorShow === true) {
        let charge = this.payGateway_config.get('pg_charges');
        let length = (<FormArray>this.payGateway_config.get('pg_charges')).controls.length
        if (this.Pgid === null) {
          if (this.payGateway_config.get('chargeType').value == 'Slab' && charge.value.length === 0) {
            this.tosterservice.error("Please select atleast one Charge");
            return;
          }
          else if(this.payGateway_config.get('chargeType').value == 'Slab' && ((<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').value)<=(<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountFrom').value){
            this.tosterservice.error('Enter To Amount Greater Than From Amount');
          }
          else {
            this.savePG();
          }
        } else {
          if (this.payGateway_config.get('chargeType').value == 'Slab' && charge.value.length === 0) {
            this.tosterservice.error("Please select atleast one Charge");
            return;
          }
          else if(this.payGateway_config.get('chargeType').value == 'Slab' && ((<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountTo').value)<=(<FormArray>this.payGateway_config.get('pg_charges')).controls[length-1].get('amountFrom').value){
            this.tosterservice.error('Enter To Amount Greater Than From Amount');
          }
          else {
            this.UpdatePG();
          }
        }
      }

    }
  }

  validateFormconfig() {
    if (!this.partner_config.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.partner_config.controls).forEach(field => {
        const control = this.partner_config.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {

      if (!this.errorShow === true) {
        if (this.configid === null) {
          this.saveconfig();
        } else {
          this.Updateconfig();
        }
      }

    }
  }

  getFranchiesDataById() {
    this.franchiesService.getFranchiesDataById(this.franchies_id).subscribe(res => {
      let data = res.content[0];
      this.setUpdateValueInForm(data);
      this.taxCalculation=data.taxCalculation;
      this.getPincodeAllAndSetValue(data.pincode)
    });

  }

  get f() {
    return this.insertFranchiesForm.controls;
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    
    if(ctrlValue.target.type ==='email' && ctrlValue.which === 32){
      ctrlValue.preventDefault();
    }
    if(ctrlValue.target.selectionStart===0   && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
    }
  }

  // onCountryChange($event: any) {
  //   this.insertFranchiesForm.get('state_id').reset();
  //   this.insertFranchiesForm.get('city_id').reset();
  //   if ($event) {
  //     this.getStateListByCountryIdAndSetState($event.country_id);
  //   } else {
  //     this.stateList = [];
  //     this.cityList = [];
  //     this.insertFranchiesForm.get('state_id').patchValue([]);
  //     this.insertFranchiesForm.get('city_id').patchValue([]);
  //   }
  // }

  // onStateChange($event) {
  //   this.insertFranchiesForm.get('city_id').reset();
  //   if ($event) {
  //     this.getCityListByStateId($event.state_id);
  //   } else {
  //     this.cityList = [];
  //     this.insertFranchiesForm.get('city_id').patchValue([]);
  //   }
  // }
  // onCountryClear() {
  //   this.stateList = [];
  //   this.insertFranchiesForm.get('state_id').patchValue([]);
  // }
  getTaxList(tax_id?: any) {
    this.taxService.getTaxList().subscribe(result => {
      this.taxList = result.content;
      if (tax_id) {
        this.insertFranchiesForm.patchValue({ tax_id: tax_id });
      }
    });
  }

  getTdsTaxList(tdsTax?: any) {
    this.taxService.getTaxList().subscribe(result => {
      this.tdstaxList = result.content;
      if (tdsTax) {
        this.insertFranchiesForm.patchValue({ tdsTax: tdsTax });
      }
    });
  }
  getFranchiesList(parent_franchies_id?: any) {
    this.franchiesService.getFranchiesList().subscribe(result => {
      this.franchiesList = result.content;
      if (parent_franchies_id) {
        this.insertFranchiesForm.patchValue({ parent_franchies_id: parent_franchies_id });
      }
    });
  }
  getAreaList(area_id?: any) {
    this.serviceAreaService.http.get('serviceArea/byStatus/active').subscribe(
      (result: any) => {
        this.areaList = result.dataList;
      }
    )
  }
  // getCountry(country_id?: any) {
  //   this.countryService.getCountryList().subscribe(result => {
  //     this.countryList = result.content;

  //     if (country_id) {
  //       this.insertFranchiesForm.patchValue({ country_id: country_id });
  //     }
  //   });
  // }

  // getCityListByStateId(state_id: any, city_id?: any) {
  //   this.cityService.getCityListByStateId(state_id).subscribe(result => {
  //     this.cityList = result.content;
  //     if (city_id) {
  //       this.insertFranchiesForm.patchValue({ city_id: city_id });
  //     }
  //   });
  // }

  // getStateListByCountryIdAndSetState(country_id: any, state_id?: any) {
  //   this.stateService.getStateListByCountryId(country_id).subscribe(result => {
  //     this.stateList = result.content;
  //     if (state_id) {
  //       this.insertFranchiesForm.patchValue({ state_id: state_id });
  //     }
  //   });
  // }

  transforInsertData(formData: any) {
    let formatedInputData: any = {};
    formatedInputData.name = formData.name;
    formatedInputData.commtype = formData.commission_type;
    formatedInputData.commrelvalue = formData.commission_value;
    // formatedInputData.commdueday = formData.commission_day;
    formatedInputData.addresstype = formData.address_type;
    formatedInputData.taxid = formData.tax_id;
    formatedInputData.address1 = formData.address1;
    formatedInputData.address2 = formData.address2;
    formatedInputData.country = formData.Country;
    formatedInputData.state = formData.State;
    formatedInputData.city = formData.City;
    formatedInputData.pincode = formData.pincodeId;
    formatedInputData.mobile = formData.mobile;
    formatedInputData.email = formData.email;
    formatedInputData.serviceAreaIds = formData.serviceAreaIds;
    formatedInputData.pool_id = formData.pool_id;
    formatedInputData.parentpartnerid = formData.parent_franchies_id;
    formatedInputData.status = formData.status;
    formatedInputData.pricebookId = formData.price_book;
    formatedInputData.billingTemplate=formData.billingTemplate;
    formatedInputData.creditNoteTemplate=formData.creditNoteTemplate;
    formatedInputData.receiptTemplate=formData.receiptTemplate;
    formatedInputData.tdsTax = formData.tdsTax;
    formatedInputData.taxCalculation = formData.taxCalculation;
    formatedInputData.notifiGroupId=formData.notifiGroupId;
    formatedInputData.merchantId=formData.merchantId;
    formatedInputData.isIpPoolForPartner = this.isPartnerPoolConfig;
    formatedInputData.dunningRuleId = formData.dunningRuleId;
    return formatedInputData;
  }
   checkEmailonsubmit(emailvalue?){
    this.validEmail=true;
    if (this.franchies_id != null) {
      this.partnerIdValue = this.franchies_id;
    }
    let dataObj = {
      "partnerId": this.partnerIdValue ? this.partnerIdValue : null,
      "value": emailvalue
    }
   this.franchiesService.checkUniqueEmail(dataObj).subscribe((res) => {
      if (res.responseCode !== 200) {
        this.validEmail=false;
        this.tosterservice.error(res.responseMessage); 
      }
    })
  }
  checkMobileonsubmit(mobileno?){
    this.validMobile=true;
    if (this.franchies_id != null) {
      this.partnerIdValue = this.franchies_id;
    }
    let dataObj = {
      "partnerId": this.partnerIdValue ? this.partnerIdValue : null,
      "value": mobileno
    }
   this.franchiesService.checkUniqueMobile(dataObj).subscribe((res) => {
      if (res.responseCode !== 200) {
        this.validMobile=false;
        this.tosterservice.error(res.responseMessage); 
      }
    })
  }
  insertNewFranchise() {
    let self=this;
    let checkemail=this.insertFranchiesForm.get('email').value;
    let checkmobile=this.insertFranchiesForm.get('mobile').value;
    this.checkEmailonsubmit(checkemail);
    this.checkMobileonsubmit(checkmobile);
    setTimeout(function(){
      if (self.insertFranchiesForm.valid === true && self.validEmail && self.validMobile) {
        this.saveBtn = true;
        let apiInputData = Object.assign({}, self.insertFranchiesForm.value);
        let formatedInputData = self.transforInsertData(apiInputData);
        self.franchiesService.insertFranchies(formatedInputData).subscribe(result => {
          self.saveBtn = false;
          if (result.status === ResponseCode.OK) {
            self.insertFranchiesForm.reset({ status: true });
            self.cdRef.detectChanges();
            self.franchies_id = result.partner.id;
            self.tosterservice.success('Franchies has been created successfully');
            self.onSaveNavigate(self.franchies_id);
          }
        },
          err => {
            self.saveBtn = false;
            self.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }
    },1000)
  }

  updateFranchise() {
    let self=this;
    ////console.log(self.insertFranchiesForm)
    let checkemail=this.insertFranchiesForm.get('email').value;
    let checkmobile=this.insertFranchiesForm.get('mobile').value;
    this.checkEmailonsubmit(checkemail);
    this.checkMobileonsubmit(checkmobile);
    
    setTimeout(function(){
      if (self.insertFranchiesForm.valid === true && self.validEmail && self.validMobile) {
        this.saveBtn = true;
        let apiInputData = Object.assign({}, self.insertFranchiesForm.value);
        let formatedInputData = self.transforInsertData(apiInputData);
        formatedInputData.commtype=self.commision_type
    
        self.franchiesService.updateFranchies(formatedInputData, self.franchies_id).subscribe(result => {
          self.saveBtn = false;
          if (result.status === ResponseCode.OK) {
            self.cdRef.detectChanges();
            self.tosterservice.success('Franchise Update successfully');
          }
          //this.insertFranchiesForm.reset({ status: true }); /** Refresh the Data**/
  
        },
          err => {
            self.saveBtn = false;
            self.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }
    },1000);
  } 

  backToViewFranchise() {
   // this.router.navigate(['/masters/franchies']); /**Old */
   if(this.genericDataSharingService.lastAuditUrl == this.router.url){
    this.router.navigate(['/operations/audit']);
    this.genericDataSharingService.lastAuditUrl = undefined;
    }else{
    this.router.navigate(['/masters/franchies']);
   
    }
 
  }
  onSaveNavigate(id) {
    this.router.navigate(['/masters/franchies/add_franchies', id]);
    this.getPaymentMode();
  }
  notificationGroup(){
    this.franchiesService.getNotificationGroupService().subscribe(res=>{
      this.notificationGroups=res.dataList;
    })
  }
  setUpdateValueInForm(franchiesData) {
    
    this.selectForUpdate = franchiesData;
    this.isPartnerPoolConfig = franchiesData.isIpPoolForPartner
    this.insertFranchiesForm.patchValue(franchiesData);
    this.commision_type=franchiesData.commission_type;
    this.partnerBalance = franchiesData.outcomeBalance;
     this.insertFranchiesForm.get('commission_type').disable(); 
    if(franchiesData.commission_type == 'Revenue Share'){
      this.price_Book_List = [];
      this.insertFranchiesForm.get('commission_value').setValidators([Validators.required]);
      this.insertFranchiesForm.get('commission_value').updateValueAndValidity();
      this.getPriceBookList('Revenue Share');
    }else{
      this.insertFranchiesForm.get('commission_value').clearValidators();
      this.insertFranchiesForm.get('commission_value').updateValueAndValidity();
    }
    if(franchiesData.commission_type == 'Token Amount'){
      this.price_Book_List = [];
      this.getPriceBookList('Token Amount');
    }
    if(franchiesData.commission_type == 'Fixed'){
      this.price_Book_List = [];
      this.getPriceBookList('Fixed');
    }
    
    if(franchiesData.taxCalculation){
      this.taxCalculation=franchiesData.taxCalculation;
      
    }


    // this.getCountry(franchiesData.country_id);
    // this.getStateListByCountryIdAndSetState(franchiesData.country_id, franchiesData.state_id);
    // this.getCityListByStateId(franchiesData.state_id, franchiesData.city_id);
    this.getAreaList(franchiesData.area_id);
    this.getFranchiesList(franchiesData.parent_franchies_id);
    this.getTaxList(franchiesData.tax_id);
    this.getTdsTaxList(franchiesData.tdsTax);
    this.onUpdateCommissionTypeChange(franchiesData.commission_type);
    this.getBillingTemplateForPartner(franchiesData.billingTemplate);
    this.getCreditnoteTemplateForPartner(franchiesData.creditNoteTemplate);
    this.getPaymentReceiptTemplateForPartner(franchiesData.receiptTemplate);
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

 

  private commonList() {
    if(this.show_update){
      this.commonListService.getCommonListForYN().subscribe((res)=>{
        this.commonYN=res.dataList
            });
    }
  
    this.commonListService.getCommonListCommonStatus().subscribe((res) => {
      this.status_list = res.dataList;

    });
    this.commonListService.getCommonListAddressType().subscribe((res) => {
      this.address_type = res.dataList;
    });
    this.commonListService.getCommonListCommissionType().subscribe((res) => {
      this.commission_type_list = res.dataList;
    });
    // this.commonListService.getGenericRemarkType('billingDay').subscribe((res) => {
    //   this.commission_day_list = res.dataList.sort((a, b) => a.text - b.text);
    // });

    this.commonListService.getCommonListTaxCalculation().subscribe((res) => {
      this.tax_calculation_List = res.dataList;
    });
    
  }
  onUpdateCommissionTypeChange(event) {
    if (event && event === 'pricebook' ||  event ==='Token Amount') {
      this.isCommissionType = true;
    }
    else {
      this.isCommissionType = false;
    }
  }
  onCommissionTypeChange(event) {
    if (event) {
      if (event.value === 'Revenue Share') {
        this.isCommissionType = true;
        this.insertFranchiesForm.get('price_book').setValue(null);
        this.insertFranchiesForm.get('price_book').setValidators([Validators.required]);
        this.insertFranchiesForm.get('price_book').updateValueAndValidity();
        this.insertFranchiesForm.get('commission_value').setValidators([Validators.required]);
        this.insertFranchiesForm.get('commission_value').updateValueAndValidity();
        if(event.value === 'Revenue Share'){
          this.price_Book_List = [];
          this.getPriceBookList('Revenue Share');
        }
      }else{
        this.insertFranchiesForm.get('commission_value').clearValidators();
        this.insertFranchiesForm.get('commission_value').updateValueAndValidity();
      }
      if (event.value === 'Fixed') {
        this.isCommissionType = true;
        this.insertFranchiesForm.get('price_book').setValue(null);
        this.insertFranchiesForm.get('price_book').setValidators([Validators.required]);
        this.insertFranchiesForm.get('price_book').updateValueAndValidity();
        if(event.value === 'Fixed'){
          this.price_Book_List = [];
          this.getPriceBookList('Fixed');
        }
      }
      if (event.value === 'Token Amount') {
        this.isCommissionType = true;
        this.insertFranchiesForm.get('price_book').setValue(null);
        this.insertFranchiesForm.get('price_book').setValidators([Validators.required]);
        this.insertFranchiesForm.get('price_book').updateValueAndValidity();
        if(event.value === 'Token Amount'){
          this.price_Book_List = [];
          this.getPriceBookList('Token Amount');
        }
      }
    }
  }

  onChangePaymentMode(mode) {
    
    if (mode) {
      let payment_mode_value = mode.payment_mode_value;
      if(payment_mode_value === 'cheque'){
        this.ispaymentMode = 'Cheque';
        this.addBalanceForm.get('chequenumber').setValidators(Validators.required);
        this.addBalanceForm.get('chequenumber').updateValueAndValidity();
        this.addBalanceForm.get('bank_name').setValidators(Validators.required);
        this.addBalanceForm.get('bank_name').updateValueAndValidity();
        this.addBalanceForm.get('chequedate').setValidators(Validators.required);
        this.addBalanceForm.get('chequedate').updateValueAndValidity();
        this.addBalanceForm.get('branch_name').setValidators(Validators.required);
        this.addBalanceForm.get('branch_name').updateValueAndValidity();
      }
      else{
        this.addBalanceForm.get('chequenumber').clearValidators();
        this.addBalanceForm.get('chequenumber').updateValueAndValidity();
        this.addBalanceForm.get('bank_name').clearValidators();
        this.addBalanceForm.get('bank_name').updateValueAndValidity();
        this.addBalanceForm.get('chequedate').clearValidators();
        this.addBalanceForm.get('chequedate').updateValueAndValidity();
        this.addBalanceForm.get('branch_name').clearValidators();
        this.addBalanceForm.get('branch_name').updateValueAndValidity();
        this.ispaymentMode = 'Online';
      }
    } else {
      this.ispaymentMode = '';
    }
  }
 

  // onChangePaymentMode(mode) {
    
  //   if (mode && mode.payment_mode_id && mode.payment_mode_id === 13) {
  //     this.ispaymentMode = 'Cheque';
  //     this.addBalanceForm.get('chequenumber').setValidators([Validators.required]);
  //     this.addBalanceForm.get('chequedate').setValidators([Validators.required]);
  //     this.addBalanceForm.get('bank_name').setValidators([Validators.required]);
  //     this.addBalanceForm.get('branch_name').setValidators([Validators.required]);
  //     return;
  //   }
  //   else {
  //     this.addBalanceForm.get('chequenumber').clearValidators();
  //     this.addBalanceForm.get('chequenumber').updateValueAndValidity();
  //     this.addBalanceForm.get('chequedate').clearValidators();
  //     this.addBalanceForm.get('chequedate').updateValueAndValidity();
  //     this.addBalanceForm.get('bank_name').clearValidators();
  //     this.addBalanceForm.get('bank_name').updateValueAndValidity();
  //     this.addBalanceForm.get('branch_name').clearValidators();
  //     this.addBalanceForm.get('branch_name').updateValueAndValidity();
  //     this.ispaymentMode = 'Online';
  //     return;
  //   }

  // }
  onReverseBalance() {
    if (this.reverseBalanceForm.valid === true) {
      this.reverseBalance.partner_id = Number(this.franchies_id);

      this.reverseBalance.amount = Number(this.reverseBalance.amount);
      if (this.reverseBalance.amount < this.partnerBalance) {
        this.showPartnerLedger = false;
        this.franchiesService.reverseBalance(this.reverseBalance).subscribe((res) => {

          if (res.status === 200) {
            this.partnerBalance = res.reverseBalance.outcomeBalance;
            this.tosterservice.success("Balance Reversed Successfully");
            this.reverseBalanceForm.reset();
          }
          this.showPartnerLedger = true;
        });
      }
      else {
        this.tosterservice.error("Can not reverse it due to low balance!");
      }
    }
    else {
      this.tosterservice.error("Please fill all the details!");
    }



  }

  onAddBalance() {
    if(this.addBalanceForm.invalid){
      this.addBalanceForm.markAllAsTouched();
      this.submitedFlag=true;
      return this.tosterservice.error("Please fill all the details!");
    }
    
    if (this.addBalanceForm.valid === true) {
      this.balance.partner_id = Number(this.franchies_id);
      this.balance.amount = Number(this.balance.amount);
      this.balance.paymentdate = this.datePipe.transform(this.balance.paymentdate, 'yyyy-MM-dd');
      this.showPartnerLedger = false;
      this.franchiesService.saveAddBalance(this.balance).subscribe((res) => {

        if (res.status === 200) {
          this.partnerBalance = res.addBalance.outcomeBalance;
          this.submitedFlag=false;
          this.tosterservice.success("Balance Added Successfully");
          this.addBalanceForm.reset();

        }
        this.showPartnerLedger = true;
      });
    }
    else {
      this.tosterservice.error("Please fill all the details!");
    }
  }

//table payment gateway
getTableColumn() {
  let self = this;

  this.allColumns = [
    {
      colKey: 'index',
      default: 'ID',
      rowKey: 'id',
      width: '1%',
      isFilterAllow: false,
      isNumber: true,
    },
    {
      colKey: 'paymentGateWayName',
      default: 'Payment Gateway Name',
      rowKey: 'paymentGateWayName',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
      enableSort: true,
    },
    {
      colKey: 'defaultpgflag',
      default: 'Default',
      rowKey: 'defaultpgflag',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
      enableSort: true,
    }, 
    {
      colKey: 'actionCol',
      default: 'Action',
      rowKey: 'id',
      //enableEdit: true,
      // enableDelete: true,
      enableEdit: false,
      enableDelete: false,
      isFilterAllow: false,
      enableSort: false,
      isExpandable: false,
      isNumber: false,
      width: '1%',
      isStickyEnd: true,
    },
  ];
  this.allColumns.forEach(element => {
    element.isFilterAllow = false;
  });
  this.getTableSetting();
  this.getTableSearchSetting();
}

getTableColumns(){
  let self = this;

  this.allColumnspr = [
    {
      colKey: 'index',
      default: 'ID',
      rowKey: 'id',
      width: '1%',
      isFilterAllow: false,
      isNumber: true,
    },  
    {
      colKey: 'clientServiceName',
      default: 'Configuration',
      rowKey: 'clientServiceName',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
      enableSort: true,
    },
    {
      colKey: 'displayname',
      default: 'Configuration Value',
      rowKey: 'displayname',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
      enableSort: true,
    }, 
    {
      colKey: 'actionCol',
      default: 'Action',
      rowKey: 'id',
      //enableEdit: true,
      // enableDelete: true,
      enableEdit: false,
      enableDelete: false,
      isFilterAllow: false,
      enableSort: false,
      isExpandable: false,
      isNumber: false,
      width: '1%',
      isStickyEnd: true,
    },
  ];
  this.allColumnspr.forEach(element => {
    element.isFilterAllow = false;
  });
  this.getTableSettings();
  this.getTableSearchSettings();
}
//table Role
getTableColumnsRole(){
  let self = this;

  this.allColumnRole = [
    {
      colKey: 'index',
      default: 'ID',
      rowKey: 'id',
      width: '1%',
      isFilterAllow: false,
      isNumber: true,
    },  
    {
      colKey: 'eeName',
      default: 'Role Name',
      rowKey: 'eeName',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
      enableSort: true,
    },
    {
      colKey: 'displayname',
      default: 'Configuration Value2',
      rowKey: 'displayname',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
      enableSort: true,
    }, 
    {
      colKey: 'actionCol',
      default: 'Action',
      rowKey: 'id',
      //enableEdit: true,
      // enableDelete: true,
      enableEdit: false,
      enableDelete: false,
      isFilterAllow: false,
      enableSort: false,
      isExpandable: false,
      isNumber: false,
      width: '1%',
      isStickyEnd: true,
    },
  ];
  this.allColumnRole.forEach(element => {
    element.isFilterAllow = false;
  });
  this.getTableSettingsRole();
  // this.getTableSearchSettings();
  this.getTableSearchSettingRole();
}

getTableSetting() {
  this.neoTableSetting = {
    showAdvanceSearch: false,
    showFooter: false,
    showFilter:false,
    showPagination: true,
    tableId: this.tableKeyEnum.PARTNER_PAYMENT_GATEWAY,
    perPage: 10,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    sort: true,
    serverSideSortPagination: true,
    pageTotalLength: 0,
    serverSideFilter: true,
    //defaultSortCol: '',
    //defaultSortDirection: 'desc',
    // enableMultiDelete: true,
    columns: this.allColumns,
    deleteMsgBoxTitle: 'Are you sure to delete this Payments ?',
    deleteMsgBoxDetail: 'Teams  Name :${name} ',
  };
  return this.neoTableSetting;
}
getTableSettings() {
  this.neoTableSettings = {
    showAdvanceSearch: false,
    showFooter: false,
    showFilter:false,
    showPagination: true,
    tableId: this.tableKeyEnum.PARTNER_CONFIGURATION,
    perPage: 10,
    tableHeight:300,
    headerSticky:true,
    verticalScroll:true,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    sort: true,
    serverSideSortPagination: true,
    pageTotalLength: 0,
    serverSideFilter: true,
    //defaultSortCol: '',
    //defaultSortDirection: 'desc',
    // enableMultiDelete: true,
    columns: this.allColumnspr,
    deleteMsgBoxTitle: 'Are you sure to delete this Configuration ?',
    deleteMsgBoxDetail: 'Teams  Name :${name} ',
  };
  return this.neoTableSettings;
}

getTableSettingsRole() {
  //Role
  this.neoTableSettingRole = {
    showAdvanceSearch: false,
    showFooter: false,
    showFilter:false,
    showPagination: true,
    tableId: this.tableKeyEnum.PARTNER_ACL_ROLE_CONFIGURATION,
    perPage: 10,
    tableHeight:300,
    headerSticky:true,
    verticalScroll:true,
    pageSizeOptions: [5, 10, 15, 20, 50, 100],
    sort: true,
    serverSideSortPagination: true,
    pageTotalLength: 0,
    serverSideFilter: true,
    //defaultSortCol: '',
    //defaultSortDirection: 'desc',
    // enableMultiDelete: true,
    columns: this.allColumnRole,
    deleteMsgBoxTitle: 'Are you sure to delete this ACL Configuration ?',
    deleteMsgBoxDetail: 'Role  Name :${name} ',
  };
  return this.neoTableSettingRole;
}

getTableSearchSettings() {
  this.searchSettings1 = [
    {
      display_field: 'Name',
      value_field: 'name',
      control_setting: [
        {
          control_type: SearchControlTypeEnum.TEXT,
          display_style: '',
        },
      ],
      data_type: SearchDataTypeEnum.STRING,
    },
    {
      display_field: 'STATUS',
      value_field: 'status',
      control_setting: [
        {
          control_type: SearchControlTypeEnum.TEXT,
          display_style: '',
        },
      ],
      data_type: SearchDataTypeEnum.STRING,
    },
  ];
}

getTableSearchSettingRole() {
  this.searchSettingsRole = [
    {
      display_field: 'Name',
      value_field: 'name',
      control_setting: [
        {
          control_type: SearchControlTypeEnum.TEXT,
          display_style: '',
        },
      ],
      data_type: SearchDataTypeEnum.STRING,
    },
    {
      display_field: 'STATUS',
      value_field: 'status',
      control_setting: [
        {
          control_type: SearchControlTypeEnum.TEXT,
          display_style: '',
        },
      ],
      data_type: SearchDataTypeEnum.STRING,
    },
  ];
}

getTableSearchSetting() {
  this.searchSettings = [
    {
      display_field: 'Name',
      value_field: 'name',
      control_setting: [
        {
          control_type: SearchControlTypeEnum.TEXT,
          display_style: '',
        },
      ],
      data_type: SearchDataTypeEnum.STRING,
    },
    {
      display_field: 'STATUS',
      value_field: 'status',
      control_setting: [
        {
          control_type: SearchControlTypeEnum.TEXT,
          display_style: '',
        },
      ],
      data_type: SearchDataTypeEnum.STRING,
    },
  ];
}

Pgid :any
onEditClick(event, payment) {
  // this.payGateway_config.reset();
  // (<FormArray>this.payGateway_config.get('pg_charges')).clear();
this.paymentisUpdate = true
  ////console.log("edit",event);
  this.Pgid = event.id 
    this.payGateway_config.get('paymentGateway').setValue(event.paymentGateWayId);
    let obj = this.pgListCombo.find((ls) => ls.id == event.paymentGateWayId);
    this.onChangepymtGat(obj);
    this.payGateway_config.get('isDefault').setValue(event.defaultpgflag);
    if(event.isSplitEnabled){
      this.payGateway_config.get('splitEnabled').setValue(event.isSplitEnabled);
      this.comType = event.isSplitEnabled;
    }
    this.payGateway_config.get('chargeType').setValue(event.chargeType);
    let objc = this.chargeTypeList.find((ls) => ls.value == event.chargeType);
    this.onChangeChargeType(objc);
    this.payGateway_config.get('merchentId').setValue(event.merchantKey);
    this.payGateway_config.get('percent').setValue(event.percent);
    if(event.chargeType == 'Fixed'){
      this.payGateway_config.get('percent').setValue(event.percent);
    }
    else if(event.chargeType == 'Slab'){
      for(let data of event.partnerPGCharges) {
        this.setCharges(data);
      }
    }
    let length = (<FormArray>this.payGateway_config.get('pg_charges')).controls.length;
    if(length>1){
      for(let i=length-2; i>=0;i-- ){
        (<FormArray>this.payGateway_config.get('pg_charges')).controls[i].get('amountTo').disable();
      }
    }
    setTimeout(() => {
      this.ngbModalService.open(payment, { size: 'lg' });
    }, 200);
  //console.log('-->',this.payGateway_config)
}

onEditClicks(event, config) {
  
    console.log('this.onchangeconfig(event);  =>',this.configlist);
    console.log('event =>',event);
    this.configisUpdate = true
    this.configid = event.id 
    this.partner_config.get('configuration').setValue(event.clientServiceId),
    this.ngbModalService.open(config, { size: 'lg' });
    let obj = this.configlist.find((ls) => ls.id == event.clientServiceId);
    this.dynabindLabel =  obj.displayKey;
    this.dynabindValue =  obj.valueKey;
    this.partner_config.get('value').setValue(event.displayname)
    this.onchangeconfig(obj,event);
}

UpdatePG(){
    let data = {
      id:this.Pgid,
      partnerid : this.franchies_id,
      paymentGateWayId : this.payGateway_config.get('paymentGateway').value,
      defaultpgflag : this.payGateway_config.get('isDefault').value,
      isSplitEnabled : this.comType,
      percent : Number(this.payGateway_config.get('percent').value),
      chargeType : this.payGateway_config.get('chargeType').value
    }
    if(this.chargeType == 'Slab'){
      data['partnerPGCharges'] = this.payGateway_config.get('pg_charges').value
    }
    if(this.payGateway_config.get('splitEnabled').value == true){
      data['merchantKey'] = this.payGateway_config.get('merchentId').value
    }
  this.franchiesService.updatePgConfig(data).subscribe((res)=>{
    if(res.responseCode !== 200){
      this.tosterservice.error(res.responseMessage);
      return;
    }
    else{
      this.tosterservice.success("Payment Gateway Updated successfully");
      this.ngbModalService.dismissAll();
      setTimeout(() => {
        this.getPGBypartner(this.franchies_id);
      }, 200);
  
    }
  });
  

}

Updateconfig(){
  let data = {
    id:this.configid,
    partnerid : this.franchies_id,
    clientServiceId : this.partner_config.get('configuration').value,
    value : this.partner_config.get('value').value
  }
this.franchiesService.updatePartnerConfig(data).subscribe((res)=>{
  if(res.responseCode !== 200){
    this.tosterservice.error(res.responseMessage);
    return;
  }
  else{
    this.tosterservice.success("Partner Configuration Updated successfully");
    
    setTimeout(() => {
      this.getConfigBypartner(this.franchies_id);
    }, 200);

  }
});
this.ngbModalService.dismissAll();
}
onDeleteClick(event) {
this.franchiesService.DeletePgConfig(event).subscribe((res)=>{
  if(res.responseCode !== 200){
    this.tosterservice.error(res.responseMessage)
  }
  else{
    this.tosterservice.success("Payment Gateway Deleted Successfully.")
  }
});
setTimeout(() => {
  this.getPGBypartner(this.franchies_id)
}, 200);

this.ngOnInit();


}

onDeleteClicks(event) {
  this.franchiesService.DeletePartnerConfig(event).subscribe((res)=>{
    if(res.responseCode !== 200){
      this.tosterservice.error(res.responseMessage)
    }
    else{
      this.tosterservice.success("Partner Configuration Deleted Successfully.")
    }
  });
  setTimeout(() => {
    this.getConfigBypartner(this.franchies_id)
  }, 200);
  
  this.ngOnInit();
  
  
  }

afterDeleteConfirm(objEvent) {
 
}
refreshdataSource(data){
  if(data){
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  }
}

refreshdataSource1(data){
  if(data){
    this.dataSource1 = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  }
}
refreshdataSourceRole(data){
  if(data){
    this.dataSourceRole = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  }
}
onCancel(event){
this.openippoolconfig =  event;
}

closeDialog(){
  this.dialogRef.close();
}

setNumber(controlName,index){
  if(this.pg_charges[index].get(controlName).value){
    this.pg_charges[index].get(controlName).setValue(Number(this.pg_charges[index].get(controlName).value));
  }
}

onFranchiesOperationTypeChange(event){
  this.franchies_operation_type = event;
  console.log("franchiesoperationtype:",this.franchies_operation_type)
}
scroll(prm) {
  let el=document.getElementById(prm);
  console.log(el);
  el.scrollIntoView({ behavior: 'smooth' });
}
//add user
getAllRole(){
  this.staffService.getAllRoleDataForAdmin().subscribe(result => {
    this.roleList = result.dataList;
    console.log("rr",this.roleList);
  })
}
saveOnClick(objValue){
console.log("obj",objValue)
let data={
  partnerId:Number(this.franchies_id),
  roleId:objValue.id
};
console.log(data)
this.franchiesService.aclPartnerConfig(data).subscribe((res)=>{
  if(res && res.responseCode==200){
    //Listing api call
    
  }
})
}


}
