import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { PaginationData } from '../../../core/modal/pagination-data';
import { IBaseService } from '../../../services/ibase-service';
import { SearchService } from '../../../services/search.service';
import { CircuitServiceService } from '../circuit-details/service/circuit-service.service';
import { Circuit } from '../model/Circuit';
import { Customer } from '../model/Customer';
import { SubscriberLeasedServiceService } from '../subscriber-leased-line/service/subscriber-leased-service.service';
declare let $;
@Component({
  selector: 'app-generic-contact-details',
  templateUrl: './generic-contact-details.component.html',
  styleUrls: ['./generic-contact-details.component.scss']
})
export class GenericContactDetailsComponent extends AbstractBaseListComponent<any> implements OnInit {
  @ViewChild('quickCaseModal', { static: true }) quickCaseModalRef: ElementRef;

  closeResult: string;
  getDismissReason: any;
  
  @Input() contactlist;
  setList(contactlist) {
    
    this.TechnicalContactList = contactlist;
    this.refreshDataSource(this.TechnicalContactList);
  }
  getService(): IBaseService<any> {
    return null
  }
  getTableSearchSetting() {

  }
  @ViewChild("actionBtn", { static: false }) actionBtnReff: TemplateRef<any>;
  customer = new Customer();
  isUpdate: boolean = false;
  subleasedlineneogridsetting: any = {};
  tablekeyEnum = TableKeyEnum;
  contactDetailsIndex:any;

  techObj = {
    techconname: '',
    techconphone: '',
    altnumber:'',
  };
  TechnicalContactList: any[] = [];

  
  @Input() Customer : '';
  
  DataSource = new MatTableDataSource([]);
  searchSettings: any = {};
  deviceTypes: any[] = [];
  alternateno=[
    {label:'Yes',value:'Yes'},
    {label:'No',value:'No'}
  ]
  connectionTypes: any[] = [];
  allColumns: any[] = [];
  qosPolicyTypeData: any[] = [];
  circuitTypeData: any[] = [];
  connectvityType: any[] = [];
  dataGrid: any[] = [];
  isModelopen: boolean = false;
  subscriptions: Subscription[] = [];
  propId: any;
  editCircuitId: any;
  submitted: boolean = false
  isUpdateComponent: boolean = false;
  circuitdata: any = null;
  tableKeyenum: any = TableKeyEnum;
  leasedLineCircuitNeoGridSettings: any = {};
  contactDetails: FormGroup;
  flagforcircuit: boolean = true;
  connectionTypeflag: boolean = false;
  existValue: boolean;
  private ngbRef: NgbModalRef;
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private subscriberLeasedService: SubscriberLeasedServiceService,
    private searchService: SearchService,
    private ngbModalService: NgbModal,

  ) {
    super(toaster, searchService);
  }
  ngOnInit() { 
    this.getTableColumn();
    this.setFormData();
    this.setData();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      this.applyFilter(obj);
    });
    this.subscriptions.push(filterSubscription);
  }

  setFormData() {
    this.contactDetails = new FormGroup({
      technical_contact_name: new FormControl(''),
      technical_contact_phone: new FormControl('', Validators.pattern('^[0-9]{10}$')),
      technical_alternate_number:new FormControl('')
      
    })
  }

  setData(){
    this.TechnicalContactList = this.contactlist;
  }

  applyFilter(filterObj: any) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filteredDataForGrid) {
      this.subscriberLeasedService.getAdvanceFilterSearchData(filterObj).subscribe((res) => {
        this.refreshDataSource(res.TechnicalContactList);
      });
    }
  }

  refreshDataSource(data) {
    this.TechnicalContactList = Object.assign([],data)
    if (data) {
      this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'techconname',
        default: 'Technical Contact Name',
        rowKey: 'techconname',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: 'techconphone',
        default: 'Technical Contact Phone',
        rowKey: 'techconphone',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: 'altnumber',
        default: 'Alternate Number',
        rowKey: 'altnumber',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
  }
  getTableSetting() {

    let subleasedlineneogridsetting = {
      showFooter: false,
      showPagination: false,
      showFilter: false,

      // perPage: 10,
      // pageSizeOptions: [5, 10, 15, 20, 50, 100],
      // sort: true,
      // serverSideSortPagination: true,
      // pageTotalLength: 0,
      // serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      tableId: this.tablekeyEnum.LEASE_LINE_CONTACT_DETAIS,
      enableMultiDelete: true,
      columns: this.allColumns,

    };
    this.subleasedlineneogridsetting = subleasedlineneogridsetting
    return subleasedlineneogridsetting
  }


  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
    });
  }

  onclick() {
    if(this.contactDetails.invalid)
    {
      this.submitted = true;
      return this.toaster.warning('Please Fill Valid Data','WARNING');
    }
    this.isUpdate = false
    this.techObj ['techconname'] = this.customer.techconname;
    this.techObj['altnumber']=this.customer.alternatemobile;
    this.techObj['techconphone'] = this.customer.techconphone.toString();
    let temp = Object.assign({}, this.techObj)
    this.TechnicalContactList.push(temp);
    this.refreshDataSource(this.TechnicalContactList);
    this.techObj['techconname'] = null
    this.techObj['techconphone'] = null
    this.closeModal();
  }

  onupdateclick(){
    if(this.contactDetails.invalid)
    {
      this.submitted = true;
      return this.toaster.warning('Please Fill Valid Data','WARNING');
    }  
     this.TechnicalContactList[this.contactDetailsIndex].techconname = this.customer.techconname;
     this.TechnicalContactList[this.contactDetailsIndex].techconphone = this.customer.techconphone.toString();
     this.TechnicalContactList[this.contactDetailsIndex].altnumber = this.customer.alternatemobile;
     this.refreshDataSource(this.TechnicalContactList);
     this.closeModal();
  }

  onDeleteClick(data) {
    let temp = this.TechnicalContactList.findIndex(res => res.techconphone === data.techconphone)
    this.TechnicalContactList.splice(temp, 1);
    this.refreshDataSource(this.TechnicalContactList);
  }

  setObjectData() {
    this.openContactModal(null);
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  onEditClick(data) {
    this.isUpdate = true;
    this.contactDetailsIndex = this.TechnicalContactList.findIndex(res => res.techconphone === data.techconphone)
    this.customer.techconname = data.techconname;
    this.customer.techconphone = data.techconphone;
    this.customer.alternatemobile = data.altnumber;
    this.ngbRef = this.ngbModalService.open(this.quickCaseModalRef);
  }

  openContactModal(openContactModal) {
    this.customer = new Customer();
    this.contactDetails.reset();
    this.isUpdate = false;
    this.ngbRef = this.ngbModalService.open(openContactModal);
    this.customer.alternatemobile = "No"
  }

  get f() {
    return this.contactDetails.controls;
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  closeModal() {
   this.ngbModalService.dismissAll();
    this.isUpdate = false;
  }
} 
