import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class NetworkDetailsService {
  constructor(private http: HttpClient) {

  }

  getNetworkDetailsbyCustId(subscriber_id): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getNetworkDetails/'+subscriber_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getNetworkDeviceByServiceId(service_id): Observable<any> {
    const get_url = BASE_API_URL + 'NetworkDevice/byServiceId/'+service_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  // /** New Call for  */
  // getNetworkDeviceByServiceIdList (service_id): Observable<any> {
  
  //   const get_url = BASE_API_URL + 'NetworkDevice/'+service_id;
  //   return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
  //       map((res: any) => {
  //         return res;
  //       }),
  //       catchError((error: any) => {
  //         return throwError(error);
  //       })
  //     );
  // }



  updateNetworkDetails(network_data): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/updateNetworkDetails';
    return this.http.post<any>(get_url,network_data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getAllOltDevice(): Observable<any> {
      const get_url = BASE_API_URL + 'NetworkDevice/getAllOltDevice';
      return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }


}
