import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  constructor(private http: HttpClient) {

  }

  transformDiscountData(data) {
    let formatedData = [];
    data.discountList.forEach(function(item:any){
      let itemData:any = {};
      itemData.discount_id = item.id;
      itemData.name = item.name;
      itemData.desc = item.desc;
      itemData.status = item.status;
      itemData.discoundMappingList = item.discoundMappingList;
      itemData.discoundPlanMappingList = item.discoundPlanMappingList;
      itemData.updatedate = item.updatedate;
      formatedData.push(itemData);
    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null};
  }
  
  transformDiscountByIdData(data) {
    let formatedData = [];
    data.discountList.forEach(function(item:any){
      let itemData:any = {};
      itemData.discount_id = item.id;
      itemData.name = item.name;
      itemData.desc = item.desc;
      itemData.status = item.status;
      itemData.discoundMappingList = item.discoundMappingList;
      itemData.discoundPlanMappingList = item.discoundPlanMappingList;
      formatedData.push(itemData);
    });
    return {content:formatedData};
  }

  getDiscountDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'discounts/list';

    return this.http.post<any>(post_url, params,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformDiscountData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getDiscountData(discount_id): Observable<any> {
    const get_url = BASE_API_URL + 'discounts/' + discount_id;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  insertDiscount(discount_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'discounts';
    return this.http.post<any>(post_url, discount_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateDiscount(discount_data: any,discount_id:any): Observable<any> {
    const update_url = BASE_API_URL +"discounts/"+ discount_id;
    return this.http.put<any>(update_url, discount_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteDiscount(discount_id: any): Observable<any> {
    const delete_url = BASE_API_URL+"discounts/" + discount_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
