import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { ConnectedSubscriberService } from './connected-subscriber.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import _ from 'underscore';
import * as alertify from 'alertifyjs';
import { style } from '@angular/animations';
import { state, trigger,animate,transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../components/loading/loading.service';
@Component({
  selector: 'app-connected-subscriber',
  templateUrl: './connected-subscriber.component.html',
  styleUrls: ['./connected-subscriber.component.scss'],
  animations:[
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class ConnectedSubscriberComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('checkboxWrapper', { static: false }) checkboxWrapperRef: TemplateRef<any>;
  @ViewChild('activeUser', { static: false }) activeUserRef: TemplateRef<any>;
  @ViewChild('editUserName',{static:false}) editUserNameRef:TemplateRef<any>;
  connectedSubscriberDataForGrid: any = { content: [] };
  connectedSubscriberGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  custId:number;
  dataSource = new MatTableDataSource([]);
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  selectedCheckboxList: any[] = [];
  state: string = 'default';
  ischecked=true

  autoRefreshDataForConnectedSubscriber: boolean;

  constructor(
    private connectedSubscriberService: ConnectedSubscriberService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private toaster:ToastrService,
    private loadingService: LoadingService,
  ) {
    super(searchService);
    let autoRefreshForConnectSub = localStorage.getItem('autoRefreshForConnectSub')
    autoRefreshForConnectSub == 'true' ? this.autoRefreshDataForConnectedSubscriber = true : this.autoRefreshDataForConnectedSubscriber = false;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.CONNECTED_SUBSCRIBER_LIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    ////console.log("foreach");
    this.allColumns.forEach((col) => {
      if (col.colKey == 'nasport') {
        col.customControls = [this.checkboxWrapperRef];
        ////console.log(col.customControls);
      }
      if (col.colKey == 'username') {
        col.customControls = [this.activeUserRef];
        ////console.log(col.customControls);
      }
      if (col.colKey == 'username') {
        col.customControls = [this.editUserNameRef];
      }
    });
  }

  onrefreshclick(){
    this.selectedCheckboxList.splice(0,this.selectedCheckboxList.length)
    this.getListWithParams();
    
    
    
  }
  rotate() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }


  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'nasport',
        default: 'select',
        rowKey: 'nasport',
        sortKey: 'nasport',
        width: '4%',
        customControls: [this.checkboxWrapperRef],
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'username',
        default: 'Username',
        rowKey: 'username',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'create_date',
        default: 'Session Start Time',
        rowKey: 'create_date',
        sortKey: 'create_date',
        width: '4%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'acctsessiontime',
        default: 'Session Time',
        rowKey: 'acctsessiontime',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'last_modified_date',
        default: 'Session Last modified Time',
        rowKey: 'last_modified_date',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'acctsessionid',
        default: 'Session Id',
        rowKey: 'acctsessionid',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'acctinputoctets',
        default: 'Upload Data',
        rowKey: 'acctinputoctets',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'acctoutputoctets',
        default: 'Download Data',
        rowKey: 'acctoutputoctets',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedipaddress',
        default: 'Customer IP Address',
        rowKey: 'framedipaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedipv6prefix',
        default: 'Framed IPV6 Prefix',
        rowKey: 'framedipv6prefix',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedinterfaceid',
        default: 'Framed Interface Id',
        rowKey: 'framedinterfaceid',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'nasipaddress',
        default: 'Gateway Address',
        rowKey: 'nasipaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Username',
        value_field: 'username',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Session Time',
        value_field: 'acctsessiontime',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.NUMBER,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.NUMBER,
      },
      {
        display_field: 'Upload Data',
        value_field: 'acctinputoctets',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.NUMBER,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.NUMBER,
      },
      {
        display_field: 'Download Data',
        value_field: 'acctoutputoctets',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.NUMBER,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.NUMBER,
      },
      {
        display_field: 'Customer IP Address',
        value_field: 'framedipaddress',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Gateway Address',
        value_field: 'nasipaddress',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];

    this.connectedSubscriberGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CONNECTED_SUBSCRIBER_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
    };
    if (this.connectedSubscriberGridSettings.serverSideSortPagination && this.connectedSubscriberGridSettings.perPage) {
      this.setPaginationPerPage(this.connectedSubscriberGridSettings.perPage);
    }
  }

  getConnectedSubscriberData(gridSetting: any) {
    let fetchSettingData = this.connectedSubscriberGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.connectedSubscriberService.getConnectedSubscriberDataWithPageing(params).subscribe((result) => {
      result.content.forEach(element => {
        if (this.selectedCheckboxList && this.selectedCheckboxList.length) {
          let index = _.findIndex(this.selectedCheckboxList, { id: element.id });
          if (index && index > 0) {
            element.checkboxSelected = true;
          } else {
            element.checkboxSelected = false;
          }
        } else {
          element.checkboxSelected = false;
        }
      });
      this.connectedSubscriberDataForGrid = result;
      this.refreshDataSource(this.connectedSubscriberDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.connectedSubscriberGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.connectedSubscriberDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(
        filterObj.filters,
        this.connectedSubscriberDataForGrid.content
      );
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }


  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getConnectedSubscriberData(config);

    let autoRefreshDataForConnectedSubscriber=localStorage.getItem('autoRefreshForConnectSub')
    
    if(autoRefreshDataForConnectedSubscriber==="true"){
      setTimeout(() => {
        this.getListWithParams();
      }, 5000);
      let URL = this.router.url.includes('connected-subscriber')
      if (URL) {
        this.loadingService.show();
      } else {
        this.loadingService.hide();
      }
    }
  }

  onGridCheckboxChange(el, data) {
    ////console.log(data);
    if (el.target.checked === true) {
      this.selectedCheckboxList.push(data);
    } else if (el.target.checked === false) {
      let index = _.findIndex(this.selectedCheckboxList, { id: data.id });
      this.selectedCheckboxList.splice(index,1);
    
      
    }
  }

  subscriberUserDisconnectBySession() {
    let self = this;
    alertify.prompt('Are You Sure You Want To Disconnect Selected Live User ?', 'Remarks', '', function (evt, value) {
      self.saveApiCall(value);
    }, function () {

    });
  }

  saveApiCall(remark) {
    if (this.selectedCheckboxList && this.selectedCheckboxList.length) {
      let sessionIdList = _.pluck(this.selectedCheckboxList, "acctsessionid");
      let data: any = {
        "remark": remark,
        "reqType": "session",
        "sessionId": sessionIdList
      };
      this.connectedSubscriberService.subscriberUserDisconnectBySession(data).subscribe((result) => {
        ////console.log(result);
        if (result.responseCode !== 200 && result.responseMessage) {
          alertify.error(result.responseMessage);
        } else {
          alertify.success('Select Subscriber User Disconnect Successfully.');
        }
      });
    } else {
      alertify.error("Please select atleast one Live User.");
    }
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  onEdit(data){
    
   let self=this;
      self.connectedSubscriberService.getUserdetailByUserName(data.username).subscribe(res=>{
        if (res.status !== 200) {
          if (res.ERROR) {
            self.toaster.error(res.ERROR);
          } else {
            self.toaster.error(res.responseMessage);
          }
        } 
        else if(res.status === 200)
        {
        }
        self.custId=res.customers.id;
        self.setUpdateValueInForm();
      })
  }
  
  setUpdateValueInForm() {
    this.router.navigate(['/masters/subscriber/details',this.custId]);
  }

  autoRefreshApiData(event){
    if(event===true){
      this.autoRefreshDataForConnectedSubscriber=true;
      localStorage.setItem('autoRefreshForConnectSub',JSON.parse('true'))
      this.getListWithParams(); 
    }else if(event===false  ){
      this.autoRefreshDataForConnectedSubscriber=false;
      localStorage.setItem('autoRefreshForConnectSub',JSON.parse('false'))
    }
  }
}
