import { Component, OnInit,Input } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import { ChangeQosService } from './change-qos.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
@Component({
  selector: 'app-change-qos',
  templateUrl: './change-qos.component.html',
  styleUrls: ['./change-qos.component.scss']
})
export class ChangeQosComponent implements OnInit {
  // planList:any = [{
  //   field_name:'Plan Name1',
  //   field_value:'PlanName1'
  // },
  // {
  //   field_name:'Plan Name2',
  //   field_value:'PlanName2'
  // }]
  @Input() custId: any;
  changeQosData:any = {};
  qosPolicyList:any = [{}]
  selectedCurrentQOS: any;
  selectedQosPolicyName: any;
  planList:any = [];
planmapid:number;
AclConstants = AclConstants;

 saveChangeQosForm = new FormGroup({
      planId: new FormControl(''),
      qosPolicyId: new FormControl('', [Validators.required]),
      remark: new FormControl('', [Validators.required]),
      custPackRelId:new FormControl()
  });

  constructor(private customModalService: CustomModalService,
    private changeQosService:ChangeQosService,
    private tosterservice : ToastrService

    ) { }

  ngOnInit() {
    ////console.log('innn kenil');
    this.getSubscriberChangeQos();
  }

  getSubscriberChangeQos() {
    let subscriber_id = this.custId;
    this.changeQosService.getSubscriberChangeQos(subscriber_id).subscribe((result) => {
      this.changeQosData = result;
      this.planList = result.data.planList || [];
     
      this.qosPolicyList = result.data.qosPolicyList;
      if (this.planList && this.planList.length == 1) {
        let firstPlan:any = _.first(this.planList);
        this.selectedCurrentQOS = firstPlan.planName;
        this.selectedQosPolicyName = firstPlan.qosPolicyName;
      }
    });
  }

  onChangePlanName($event) {
    if ($event) {
      ////console.log($event);
      this.planmapid = $event.planmapid
      let findById = _.find(this.planList,{planId:$event.planId});
      this.selectedCurrentQOS = findById.planName;
      this.selectedQosPolicyName = findById.qosPolicyName;
    } else {
      this.selectedCurrentQOS = '';
      this.selectedQosPolicyName = '';
    }
  }
  submitted : boolean;
  onClickSaveChangeQosForm() {
    if (this.saveChangeQosForm.valid === true) {
      let self = this;
      alertify.confirm("Change QOS","Are You Sure You want to Change QOS ?",
      function() {
        self.saveApiCall();
      },
      function() {

      });
    } else {
      this.submitted = true;
      this.saveChangeQosForm.markAllAsTouched();
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.saveChangeQosForm.value); 
    if(this.planList.length <= 1){
       apiInputData.custPackRelId = this.planList[0].planmapid;
    }
    else{
      apiInputData.custPackRelId = this.planmapid;
    }
    apiInputData.custId = this.custId;
    if (this.planList && this.planList.length == 1) {
      let firstPlan:any = _.first(this.planList);
      apiInputData.planId = firstPlan.planId;
    }
    this.changeQosService.saveChangeQos(apiInputData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('QOS Save Successfully.');
          this.customModalService.close('change-qos',result);
        }
      },err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }
}
