import { Component, ViewEncapsulation, ElementRef, Input,Output, OnInit, OnDestroy,EventEmitter } from '@angular/core';

import { CustomModalService } from './custom-modal.service';

@Component({
    selector: 'custom-modal',
    templateUrl: 'custom-modal.component.html',
    styleUrls: ['custom-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() height: string;
    @Input() width: string;
    @Input() headingName: string;
    @Output() onClose = new EventEmitter<any>();
    private element: any;

    constructor(private customModalService: CustomModalService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'custom-modal') {
                //this.close(this.id);
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.customModalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.customModalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('custom-modal-open');
    }

    // close modal
    close(data): void {
        this.element.style.display = 'none';
        document.body.classList.remove('custom-modal-open');
        this.onClose.emit(data);
    }

    remainOpen(){
        this.element.style.display = 'block';
        document.body.classList.add('custom-modal-open');
        }
}
