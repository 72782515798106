import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ReversePaymentService {
  constructor(private http: HttpClient) {

  }

  transformPaymentsByCustomerId(result) {
    result.dataList.forEach(element => {
      element.display_text = element.amount+" ,"+element.paymentdate+" ,"+element.referenceno;
    });
    return result;
  }

  getAllPaymentsByCustomerId(subscriber_id): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getAllPaymentsByCustomer/'+subscriber_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformPaymentsByCustomerId(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformReversiblePaymentCustomerId(result) {
    if (result && result.data && result.data.creditDocumentPojo && result.data.creditDocumentPojo.length) {
      result.data.creditDocumentPojo.forEach(element => {
        element.display_text = element.amount+" ,"+element.paymentdate+" ,"+element.referenceno;
      });
      return result;
    } else {
      return result;
    }
  }

  getReversiblePaymentCustomerId(subscriber_id): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getReversiblePayment/'+subscriber_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformReversiblePaymentCustomerId(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  updateReversePayment(reverse_payment_data): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/reversePayment';
    return this.http.post<any>(get_url,reverse_payment_data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
