const gridDefaultSettingSettings = {
  'perPage':5,
  'tableClass':'table dataTable table-striped table-bordered',
  'tableHeadClass':'',
  'tableBodyClass':'',
  'tableRowClass':'',
  'columnVisibilityForAllColumn':true,
  'showColumnVisibilityChangePopup':false,
  'showSearchBox':true,
  'showPageLenghtChange':true,
  'lengthChangeMenu':[
    {
      displayField:5,
      valueField:5
    },
    {
      displayField:10,
      valueField:10
    },
    {
      displayField:25,
      valueField:25
    },
    {
      displayField:50,
      valueField:50
    },
    {
      displayField:100,
      valueField:100
    }
  ],
  'searchFilter':true,
  'sort':true
}
export  {gridDefaultSettingSettings};
