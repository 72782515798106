import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
import { BlockSubscriberService } from './block-subscriber.service';


@Component({
  selector: 'app-block-subscriber',
  templateUrl: './block-subscriber.component.html',
  styleUrls: ['./block-subscriber.component.scss']
})
export class BlockSubscriberComponent implements OnInit {
  @Input()custId : any;
  changeStatus:any = [{
    text:'',
    value:''
  }]
  public changeStatusData : any;
  currentStatus : any;
  customerStatus:string;
  submitBtnDisable:boolean=false;
  activePlanTableData:any;
  insertChangeBlockStatusForm= new FormGroup({
    changedStatus : new FormControl('', [Validators.required]),
    currentStatus : new FormControl(''),
    remarks : new FormControl('', [Validators.required])
  });
  
  constructor(
    private customModalService: CustomModalService,
    private cdRef : ChangeDetectorRef,
    private tosterservice : ToastrService,
    private blockSubscriberService:BlockSubscriberService

  ) { }

  ngOnInit() {
    this.getChangeStatus();
  }

  getChangeStatus() {
    //API NEED TO BE CHANGE
    this.blockSubscriberService.getChnageStatus(this.custId).subscribe(result => {
      this.changeStatusData = result.data;
      this.currentStatus = result.data.currentStatus;
      this.changeStatus = result.data.changedStatus;
    })
  }
  onClickSaveForm() {
    if (this.insertChangeBlockStatusForm.valid === true) {
      let self = this;
      alertify.confirm("Change Block Status","Are You Sure You want to Change Block Status ?",
      function() {
        self.insertChangeStatusOnDb();
      },
      function() {
        
      });
    } else {
      this.insertChangeBlockStatusForm.markAllAsTouched();
    }
  }

  insertChangeStatusOnDb() {
     //API NEED TO BE CHANGE
    if (this.insertChangeBlockStatusForm.valid === true) {
      let formatedInputData:any = this.insertChangeBlockStatusForm.value;
      formatedInputData.custId = this.custId;
      this.blockSubscriberService.insertChangestatus(formatedInputData).subscribe(result => {
          this.cdRef.detectChanges();
          if (result.responseCode !== 200 && result.responseMessage) {
            this.tosterservice.error(result.responseMessage);
          } else {
            this.tosterservice.success('Block Status has been Updated successfully');
            this.customModalService.close('block-subscriber',result);
          }
        },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id:string){
    this.customModalService.close(id);
    this.insertChangeBlockStatusForm.reset();
  }
 
}
