import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class CommonStatusService   {
  
  constructor( private http: HttpClient) {
    
  }
 

  public getCommonList(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/commonStatus';
    return this.http.get<any>(get_url);
  }

 
}
