import { Component, OnInit ,Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as alertify from 'alertifyjs';
import { BasicDetailsService } from '../basic-details/basic-details.service';
import { CustomModalService } from '../../../../../../src/app/components/custom-modal';

@Component({
  selector: 'app-extend-suspension',
  templateUrl: './extend-suspension.component.html',
  styleUrls: ['./extend-suspension.component.scss']
})
export class ExtendSuspensionComponent implements OnInit {
 
 @Input() custId : any;
 @Input() ui_id: string;
 submitedFlag:boolean=false;
 submitted:any = null;
 errorMsg="* This Field is Required";
  constructor(
    private toastr:ToastrService,
    private basicDetailsservice:BasicDetailsService,
    private customModalService: CustomModalService,
  ) { }

  extendCustForm = new FormGroup({
    days: new FormControl('',[Validators.required]),
    remark:new FormControl('',[Validators.required])
  });

  ngOnInit() {
    
  }

  onClickSaveSuspensionForm(){
   
    if(this.extendCustForm.invalid){
      this.submitedFlag=true;
      return;
    }else{
      this.submitedFlag=false;
    }
        
    if(this.extendCustForm.valid && this.submitedFlag === false){
      let formValue=this.extendCustForm.value;
        
      if (this.extendCustForm.valid === true) {
      let self = this;
      alertify.confirm("Extend Customer Suspension","Are You Sure You want to Extend Customer Suspension?",
      function() {
        self.updateSuspensionOnDb(formValue);
      },
      function() {
  
      });
    } 
  }
    
  }

  updateSuspensionOnDb(formValue){
    formValue.custid=this.custId;
    
    this.basicDetailsservice.customerSuspensionExtend(formValue).subscribe((result)=>{
      if(result.responseCode===200){
      this.toastr.success("Extend Customer Suspension Successfully");
      this.closeModal('extend-suspension');
      this.customModalService.close('extend-suspension',result);
      }else{
      this.toastr.error(result.responseMessage);
      }
    })
  }

  closeModal(id:any){
     this.customModalService.close(id,{close_from:"close_button"});
     this.submitted = false;
  }

}
