import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { IBaseService } from '../../../../services/ibase-service';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { ServiceAreaModel } from '../../../network-management/service-area/model/service-area-model';
import { ServiceAreaService } from '../../../network-management/service-area/service/service-area.service';
import { DunningRuleComponent } from '../dunning-rule/dunning-rule.component';

import { DunningModel } from '../model/dunning-model';
import { DunningServiceService } from '../service/dunning-service.service';

@Component({
  selector: 'app-dunning-add-update',
  templateUrl: './dunning-add-update.component.html',
  styleUrls: ['./dunning-add-update.component.scss']
})
export class DunningAddUpdateComponent extends AbstractBaseAddUpdateComponent<DunningModel> implements OnInit {
  @ViewChild('DunningRuleComponent',{static: false}) dunningchild: DunningRuleComponent;

  public entity: DunningModel = new DunningModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
isUpdateComponent= false;
show_update:boolean = false
  commonStatus: any = [
    {text: 'Active', value: 'Active'},
    {text: 'InActive', value: 'InActive'},
  ];
  creditClassList: any
  notificationForList:any
  dunningType:any=[{text:'Genric',value:'Genric'}]
  constructor(
    private dunningService: DunningServiceService,
   
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    ) {
    super(toastrService, router);
  }
  GetId:any

  ngOnInit() {
    this.init();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    this.GetId = Number(id)
    if(id){
      this.isUpdateComponent = true;
      this.getById(Number(id));
      
    //  let data =  this.dunningService.data.find(res=> res.id === Number(id))
    //  this.entity = data;
    //  this.dunningchild.ngOnInit();
    //  this.dunningchild.refreshDataSource(this.entity.dunningRule)
    }
  this.getCreditClass('dunningCreditClass')
  this.getNotificationFor('dunningrulesFor')
  } 
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

getCreditClass(event){
  this.dunningService.getcommonList(event).subscribe((res) => {
    this.creditClassList = res.dataList
  })
}

getNotificationFor(event){
  this.dunningService.getcommonList(event).subscribe((res) => {
    this.notificationForList = res.dataList
  })
}
  getService(): IBaseService<any> {
    return this.dunningService;
  }

  onupdate(){
    if(this.show_update){
      
    //  let index =  this.dunningService.data.findIndex(res=> res.id === this.GetId)
    //  this.dunningService.data.splice(index,1,this.entity)
     this.router.navigate(['plan-management/dunningList'])
    
    }
  
  }
  setData(data: DunningModel) {
    this.entity = data;
  }

  getData(): DunningModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {
    this.url = 'plan-management/dunningList'
    return this.url;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): DunningModel {
    return new DunningModel();
  }



  public getId(): number {
    return this.entity.id;
  }
  getGenericDataService(){
    return this.genericDataSharingService
    }
    public getFormGroup(): FormGroup {
      return this.form;
    }
  get f() {
    return this.form.controls;
  }

  setValidation() {
    this.form = new FormGroup({
    name:new FormControl('', [Validators.required]),
    bccemail:new FormControl('', [Validators.required]),
    internalpayemail:new FormControl('', [Validators.required]),
    escstaffemail:new FormControl('', [Validators.required]),
    collagencyemail:new FormControl('', [Validators.required]),
    creditclass:new FormControl(null, [Validators.required]),
    status:new FormControl(null, [Validators.required]),
    notificationfor:new FormControl(null, [Validators.required]),
    type:new FormControl('Genric', [Validators.required]),
    });
  }

  formSubmit() {
    if (this.validateForm()) {
      this.onSubmit();
    }
    else{
      this.toastrService.error("Please fill reduired details")
    }
  }
  // onAdd(){
    
  //   this.entity.id =+ 1
  //   // this.dunningService.data.push(this.entity)
  //   this.router.navigate(['plan-management/dunningList']);
  // }
  closeDialog(){
    //
  }
  onDunningRuleADD(event){
    
    // this.entity.dunningRule = Object.assign([],event)
  }
  onevent(data){
    //console.log("smit",data)
this.entity.dunningRulesActionPojoList=Object.assign([],data)
  }
}
