import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseSetupModel } from '../model/base-setup-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { BaseSetupService } from '../service/base-setup.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CircuitServiceService } from '../../circuit-details/service/circuit-service.service';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { TokenStorageService } from '../../../../auth/token-storage-service';
import { IBaseService } from '../../../../services/ibase-service';
import { latitudeValidator, longitudeValidator } from '../../add-subscriber-details/other-details/other-details.component';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
import { ServiceAreaServices } from '../../../../services/service_area/service_area.service';
import _ from 'underscore';
import { ContentDetailsComponent } from '../../content-details/content-details/content-details.component';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { DatePipe } from '@angular/common';
import { SearchService } from '../../../../services/search.service';
import { TroubleTicketDetails } from '../trouble-ticket-details/trouble-ticket-details.service';

@Component({
  selector: 'app-base-setup-add-update',
  templateUrl: './base-setup-add-update.component.html',
  styleUrls: ['./base-setup-add-update.component.scss']
})
export class BaseSetupAddUpdateComponent extends AbstractBaseAddUpdateComponent<BaseSetupModel> implements OnInit {
  @ViewChild(ContentDetailsComponent, { static: false })
  private contactDetailsComponent: ContentDetailsComponent;
  baseSetup= new BaseSetupModel();
  baseSetupForm: FormGroup;
  neoTableSetting: any = {};
  allColumns: any[] = [];
  tablekeyEnum: any = TableKeyEnum;
  updatebtnHideInDoc:Boolean=false;
  // propertyId: number;
  // propertyIds: any;
  // editPropertyId: any;
  req_message = 'This field is required';
  searchPincodeLink: string = '';
  addressMapArray:any;
  addressMapArray2:any;
  // addressMapArray3:any;
  baseSetupId:number;
  parentId: number;
  parentpin: number
  areaAllList = [];
  default_pincode_value:string;
  serviceAreaList = [];
  propertyTypeList = [];
  baseTypeList = [];
  commonStatusList: any;
  dynamicrequiredArray: any;
  latitude:any;
  longitude:any;
  url:any;
  feasibilityDistance:any;
  name: any;
  serviceareaid:number;
  isRentProperty:boolean;
  openTroubleTicketsDetails: boolean;
  caseDetail: any;
  contentList: any[] = [];
  constructor(
    private toaster: ToastrService,
    private searchService: SearchService,
    private router: Router,
    public baseSetupService: BaseSetupService,
    public circuitService: CircuitServiceService,
    private activate: ActivatedRoute,
    private genericDataSharingService: DataSharingServiceService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private serviceAreaServices: ServiceAreaServices,
    private tokenservice: TokenStorageService,
    private commonService: CommonStatusService,
    private troubleTicketDetails: TroubleTicketDetails,
    private datepipe: DatePipe,

  ) {
    super(toaster, router)
    let id = +this.activate.snapshot.paramMap.get('id');
    if(id)
    this.baseSetupId = id;
    this.name = this.baseSetup.name;
  }

  ngOnInit() {
    this.init();
    this.getAddressMapping();
    this.getPincode();
    this.getRequiredAllField();
    this.getServiceAreaList();
    this.getpropertyTypeList(); 
    this.getBaseTypeList();
    this.commonService.getCommonList().subscribe((res) => {
      this.commonStatusList = res.dataList;
    });
  }
  init() {
    let id = +this.activate.snapshot.paramMap.get('id');
    this.isUpdateComponent = false;
    if (id) {
      this.isUpdateComponent = true;
      let catModal = this.getNewModal();
      catModal.id = id;
      this.getById(id);
      this.setData(this.getNewModal());
      this.setValidation();
      this.setId();
      return;
    }
    
    this.authRole();
    this.setData(this.getNewModal());
    this.setValidation();
    this.setId();
  }
  setId() {
  }
  getService(): IBaseService<BaseSetupModel> {
    return this.baseSetupService;
  }
  setData(data: BaseSetupModel) {
    this.baseSetup = data;
    this.name = this.baseSetup.name;
    this.serviceareaid = this.baseSetup.serviceareaid;
    let temp:any={};
    if(this.baseSetup.contentDetailList){   
      this.contentList = this.baseSetup.contentDetailList;
      if(this.contentList.length>0)
      this.contactDetailsComponent.refreshDataSource(this.contentList)
    }
    this.baseSetup= data;
    if(data && data.id){
      this.default_pincode_value = this.baseSetup.addressList[0].pincode.toString();
      let adata = {
        country:data.addressList[0].country,
        area:data.addressList[0].areaId,
        state:data.addressList[0].state,
        pincodeId:data.addressList[0].pincodeId,
        pincode:data.addressList[0].pincode,
        city:data.addressList[0].city,
        district:data.addressList[0].district,
        taluka:data.addressList[0].taluka,
        counstituency:data.addressList[0].constituency,
        countryId:data.addressList[0].countryId,
        stateId:data.addressList[0].stateId,
        cityId:data.addressList[0].cityId,
        districtId:data.addressList[0].districtId,
        talukaId:data.addressList[0].talukaId,
        counstituencyId:data.addressList[0].constituencyId,
        address:data.addressList[0].address,
        landmark:data.addressList[0].landmark,
        propertyType:data.addressList[0].propertyType,
        rentAmount:data.addressList[0].rentAmount
      }
      this.isRentProperty = data.addressList[0].propertyType == 'Rent';
      setTimeout(() => {
        this.baseSetupForm.patchValue(adata);
      }, 800);
    }
  }
  getData(): BaseSetupModel {
    return this.baseSetup;
  }
  getModuleHomeUrl() {
    return 'network-management/baseSetup/';
  }
  getActiveRoute() {
    this.activate;
  }
  getNewModal() {
    return new BaseSetupModel();
  }
  getFormGroup(): FormGroup {
    return this.baseSetupForm;
  }
  get f() {
    return this.baseSetupForm.controls;
  }
  onEditUrl() {
    this.router.navigate(['network-management/base-setup/', this.responseData.data.id])
  }
  setValidation() {
    this.baseSetupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      serviceareaid: new FormControl(null,Validators.required),
      pincode: new FormControl(null,Validators.required),
      pincodeId: new FormControl(null,Validators.required),
      area: new FormControl(null,Validators.required),
      Country: new FormControl(null,Validators.required),
      State: new FormControl(null,Validators.required),
      City: new FormControl(null,Validators.required),
      District: new FormControl(null),
      Taluka: new FormControl(null),
      Constituency: new FormControl(null),
      rentAmount: new FormControl(null),
      countryId: new FormControl(null),
      stateId: new FormControl(null),
      cityId: new FormControl(null),
      districtId: new FormControl(null),
      talukaId: new FormControl(null),
      remarks: new FormControl(null,Validators.required),
      propertyType: new FormControl(null,Validators.required),
      landmark: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      url: new FormControl(null,[Validators.minLength(1),Validators.maxLength(50)]),
     // feasibilityDistance: new FormControl(null,Validators.maxLength(5)),
      contactperson: new FormControl(null),
      contactnumber: new FormControl(null,Validators.pattern('^[0-9]{10}$')),
      basebroughtby: new FormControl(null),
      baseType: new FormControl(null),
      status: new FormControl(null),
      installationDate: new FormControl(null, Validators.required),
    })
  }
  getId(): number {
    this.baseSetupService.getByproperty(this.baseSetup.id);
    return this.baseSetup.id;
  }

  formSubmit() {
    if(this.contactDetailsComponent.ContentListData)
    this.baseSetup.contentDetailList = this.contactDetailsComponent.ContentListData;

     //---content Details reduired 
    // if (this.validateForm() && this.baseSetup.contentDetailList.length > 0) { 
    if (this.validateForm()) {
      let installationDate = this.datepipe.transform(this.baseSetupForm.get('installationDate').value, 'yyyy-MM-dd');
      this.baseSetup.installationDate = installationDate;
      this.baseSetup.addressList = [];
      let addressList: any = {};
      addressList.address = this.baseSetupForm.get('address').value;
      addressList.landmark = this.baseSetupForm.get('landmark').value;
      addressList.areaId = this.baseSetupForm.get('area').value;
      addressList.pincodeId = this.baseSetupForm.get('pincodeId').value;
      addressList.countryId = this.baseSetupForm.get('countryId').value;
      addressList.stateId = this.baseSetupForm.get('stateId').value;
      addressList.cityId = this.baseSetupForm.get('cityId').value;
      addressList.talukaId = this.baseSetupForm.get('talukaId').value;
      addressList.districtId = this.baseSetupForm.get('districtId').value;
      addressList.propertyType = this.baseSetupForm.get('propertyType').value;
      addressList.rentAmount = (this.isRentProperty)? this.baseSetupForm.get('rentAmount').value:null;
      this.baseSetup.addressList.push(addressList);
      this.onSubmit();
    }else{
      this.toaster.error("Please Fill All Required Details!");
    }
  }


  onPropertyChange(event){
    if(event){
      this.isRentProperty = event.value == 'Rent';
    }
    else{
      this.isRentProperty = false;
    }
  }

  onBack() {
    this.router.navigate(['network-management/baseSetup/']);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31  && (charCode < 45 || charCode > 57)) {
      return false;
    }
    return true;
  }

getGenericDataService(){
return this.genericDataSharingService
}

getAddressMapping(){
  let temp = this.tokenservice.getAddressLevel()
  this.addressMapArray = temp.filter(res=> res.name.toLowerCase()!='pincode' && res.name.toLowerCase()!='area' && res.name.toLowerCase()!= 'building')
  this.addressMapArray2 = temp.filter(res=> (res.name.toLowerCase() == 'area'&& res.name.toLowerCase()!= 'building' ))
  let tempn = temp.filter(res=> (res.name.toLowerCase() == 'pincode' ))
  this.parentpin = tempn[0].levelNo
  if(this.addressMapArray.find(res=> res.name.toLowerCase()=='district')){
    this.baseSetupForm.get('District').setValidators([Validators.required]);
    this.baseSetupForm.get('District').updateValueAndValidity();
  }
  if(this.addressMapArray.find(res=> res.name.toLowerCase()=='taluka')){
    this.baseSetupForm.get('Taluka').setValidators([Validators.required]);
    this.baseSetupForm.get('Taluka').updateValueAndValidity();
  }
  if(this.addressMapArray.find(res=> res.name.toLowerCase()=='constituency')){
     this.baseSetupForm.get('Constituency').setValidators([Validators.required]);
    this.baseSetupForm.get('Constituency').updateValueAndValidity();
  }
}
onPincodeChange(elItem) {
  let el;
  let call_from_autocomplete;
  if (_.isArray(elItem.event) == true) {
    el = _.find(elItem.event, (ev => ev.parentId === this.baseSetup.addressList[0].pincodeId));
  } else {
      this.baseSetupForm.get('area').reset();
    el = elItem.event;
    call_from_autocomplete = elItem.call_from_autocomplete;
  }
  if (el ) {
    this.baseSetupForm.controls.pincodeId.setValue(el.addressDetails.pincodeId);
    this.baseSetupForm.controls.pincode.setValue(el.addressDetails.pincode);
    this.baseSetupForm.patchValue({ Country: el.addressDetails.country});
    this.baseSetupForm.patchValue({ State: el.addressDetails.state});
    this.baseSetupForm.patchValue({ City: el.addressDetails.city});
    this.baseSetupForm.patchValue({ District: el.addressDetails.district});
    this.baseSetupForm.patchValue({ Taluka: el.addressDetails.taluka});
    this.baseSetupForm.patchValue({ Constituency: el.addressDetails.constituency});
    this.baseSetupForm.patchValue({ countryId: el.addressDetails.countryId});
    this.baseSetupForm.patchValue({ stateId: el.addressDetails.stateId});
    this.baseSetupForm.patchValue({ cityId: el.addressDetails.cityId});
    this.baseSetupForm.patchValue({ districtId: el.addressDetails.districtId});
    this.baseSetupForm.patchValue({ talukaId: el.addressDetails.talukaId});
    this.baseSetupForm.patchValue({ constituencyId: el.addressDetails.constituencyId});
    for (const prop in this.baseSetupForm.controls) {
      this.baseSetupForm.value[prop] = this.baseSetupForm.controls[prop].value;
    }
    this.parentId = el.parentId;
    this.addSubscriberDetailsService.getDetailsByPincode(el.parentId).subscribe(res=>{
      this.areaAllList = res.dataList;
      let area
      if(this.baseSetup.addressList[0].areaId)
      {
        this.baseSetupForm.patchValue({ areaId: this.baseSetup.addressList[0].areaId});
        area = this.areaAllList.filter(res=>res.id == this.baseSetup.addressList[0].areaId)
      }
    })
  }
  else if(el == null){
    this.baseSetupForm.get('Country').reset();
    this.baseSetupForm.get('countryId').reset();
    this.baseSetupForm.get('State').reset();
    this.baseSetupForm.get('stateId').reset();
    this.baseSetupForm.get('Taluka').reset();
    this.baseSetupForm.get('talukaId').reset();
    this.baseSetupForm.get('District').reset();
    this.baseSetupForm.get('districtId').reset();
    this.baseSetupForm.get('Constituency').reset();
    this.baseSetupForm.get('constituencyId').reset();
    this.baseSetupForm.get('City').reset();
    this.baseSetupForm.get('cityId').reset();
    this.baseSetupForm.get('area').reset();
    this.areaAllList = [];
  }
  if(elItem.event == null){
    this.baseSetupForm.get('pincodeId').reset();
    this.baseSetupForm.get('pincode').reset();
  }
}

getPincode() {
  this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
}

getServiceAreaList() {
  this.serviceAreaServices.getServiceAreaList().subscribe((result) => {
    this.serviceAreaList = result.dataList;
  });
}

getpropertyTypeList(){
  this.addSubscriberDetailsService.getCommonListPropertyType().subscribe((res)=>{
    this.propertyTypeList = res.dataList;
  })
}

getBaseTypeList(){
  this.baseSetupService.getBaseTypeList().subscribe((res)=>{
    this.baseTypeList = res.dataList;
  })
}

getRequiredAllField() {
  
  this.baseSetupService.getRequiredAllField('leasedline property').subscribe(result => {
     console.log("res===>",result)
     
    if (result.responseCode === 200) {
      this.dynamicrequiredArray = result.data;
      this.latitude = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_latitude'));
      this.longitude = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_longitude'));
      this.url = this.dynamicrequiredArray.find((e => e.fieldname === 'lp_url'));

      if (this.latitude && this.latitude !== null && this.latitude.isMandatory === true) {
        this.baseSetupForm.get('latitude').setValidators([Validators.required, latitudeValidator()])
        this.baseSetupForm.get('latitude').updateValueAndValidity();
      }
      else {
        this.baseSetupForm.get('latitude').clearValidators()
        this.baseSetupForm.get('latitude').updateValueAndValidity();
      }

      if (this.longitude && this.longitude !== null && this.longitude.isMandatory === true) {
        this.baseSetupForm.get('longitude').setValidators([Validators.required, longitudeValidator()])
        this.baseSetupForm.get('longitude').updateValueAndValidity();
      }
      else {
        this.baseSetupForm.get('longitude').clearValidators()
        this.baseSetupForm.get('longitude').updateValueAndValidity();
      }

      if (this.url && this.url !== null && this.url.isMandatory === true) {
        this.baseSetupForm.get('url').setValidators([Validators.required,Validators.minLength(1),Validators.maxLength(50)])
        this.baseSetupForm.get('url').updateValueAndValidity();
      }
      else {
        this.baseSetupForm.get('url').clearValidators()
        this.baseSetupForm.get('url').setValidators([Validators.minLength(1),Validators.maxLength(50)])
        this.baseSetupForm.get('url').updateValueAndValidity();
      }
    }
  })
}

tabClick(event){
  if(event.index===3){
    this.updatebtnHideInDoc=true;
    //this.PropertyDocumentListComponentRef.ngOnInit();
  }else{
    this.updatebtnHideInDoc=false;
    this.searchService.resetGlobalSearchServerFilterObjIfAny();
  }
}

OnCaseRefresh(data) {
  this.caseDetail = data
}

getTroubleTicketsDetailsData() {
  let caseData:any = {};
  caseData.id = this.baseSetupId;
  caseData.name = this.name;
  this.troubleTicketDetails.getTroubleTicketsDetailsList(caseData).subscribe(result => {
    this.caseDetail = result.data;
    //console.log("leased data",this.caseDetail);
  });
  ////console.log(this.caseDetail);
}

onTroubleTicketDetails() {
  this.openTroubleTicketsDetails = true;
}




 }


