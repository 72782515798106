import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base-service';
import { ServiceAreaModel } from '../../../network-management/service-area/model/service-area-model';
import { DunningModel } from '../model/dunning-model';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';
@Injectable({
  providedIn: 'root'
})

export class DunningServiceService  extends BaseService<DunningModel> {
  
  constructor(http: HttpClient) {
    super(http);
  }
  getModuleName(): string {
    return 'dunningRule';
  }
getcommonList(type):any{
  const get_url = BASE_API_URL+"commonList/generic/"+ type;
  return this.http.get(get_url);
}

  validate(): boolean {
    return false;
  }
}
