import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { PolicyModel } from '../../pages/plan_management/model/policy-model';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';
import { QosPolicy } from '../../pages/network-management/network-device/model/QosPolicy.model';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root',
})
export class QosPolicyService extends BaseService<PolicyModel> {
  constructor(http: HttpClient) {
    super(http);
  }

  getModuleName(): string {
    return 'qosPolicy';
  }

  validate(): boolean {
    return false;
  }

  saveQosDetail(data:any):Observable<any>{
    let post_url=baseApiUrl+"qosPolicyParam/save";
    return this.http.post<any>(post_url,data);
  }
  updateQosPolicy(data:any){
    let post_url=baseApiUrl+"qosPolicyParam/update";
    return this.http.post<any>(post_url,data);
  }
  deleteQosPolicy(data:any){
    let post_url=baseApiUrl+"qosPolicyParam/delete";
    return this.http.post<any>(post_url,data);
  }
  getQosPolicy(qosPolicyId:any):Observable<any>{
    const get_url = baseApiUrl + 'qosPolicyParam/byQosPolicy/'+qosPolicyId;
    return this.http.get<any>(get_url);
  }

  getQosByID(qosId):Observable<any>{
    const get_url = baseApiUrl + 'qosPolicyParam/' + qosId;
    return this.http.get(get_url).pipe(map(res => res as QosPolicy[]));
  }
  getQosPolicytype():Observable<any>{    
    const get_url = baseApiUrl + 'commonList/generic/qosPolicyType';
    return this.http.get<any>(get_url);
  }
}
