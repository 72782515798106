export class PartnerConfiguration{
    
            id:number;
            key:string;
            value:string;
            displayname:string;
            valuetype:string;
            commonlisttype:string;
            modulevalueflag:boolean;
            custommoduleid:number
            partnerid:number;
}