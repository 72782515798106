import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { PlanHistoryListService } from './plan-history.service';
import _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plan-history',
  templateUrl: './plan-history.component.html',
  styleUrls: ['./plan-history.component.scss']
})
export class PlanHistoryComponent implements OnInit {

  @ViewChild('totalQuota', { static: false }) totalQuotaWrapperRef: TemplateRef<any>;
  @ViewChild('usedQuota', { static: false }) usedQuotaWrapperRef: TemplateRef<any>;
  @ViewChild('validity', { static: false }) validityWrapperRef: TemplateRef<any>;
  @ViewChild('expiryDate', { static: false }) expiryDateWrapperRef: TemplateRef<any>;
  @ViewChild('vasBtn', { static: false }) vasWrapperRef: TemplateRef<any>;
  @Input() custId: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  planHistoryNeoGridSettings = { };
  subscriptions: Subscription[] = [];
  planMapId: string;

  planHistoryTableData: any;

  constructor(
    private planHistoryService: PlanHistoryListService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.setGridSetting();
    this.getFuturePlansData();
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'totalquotaformate') {
        col.customControls = [this.totalQuotaWrapperRef];
      }
      if (col.colKey === 'usedquotaformate') {
        col.customControls = [this.usedQuotaWrapperRef];
      }
      if (col.colKey === 'validity') {
        col.customControls = [this.validityWrapperRef];
      }
      if (col.colKey === 'expiryDate') {
        col.customControls = [this.expiryDateWrapperRef];
      }
      if (col.colKey === 'vas') {
        col.customControls = [this.vasWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      // {
      //   colKey: 'service',
      //   default: 'Service',
      //   rowKey: 'service',
      //   width: '10%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      {
        colKey: 'planName',
        default: 'Plan Name',
        rowKey: 'planName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validityType',
        default: 'Validity Type ',
        rowKey: 'validityType',
        width: '10%',
        isNumber: false,

        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validity',
        default: 'Validity ',
        rowKey: 'validity',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.validityWrapperRef],
        customValue: (validity) => {
          if (validity != 0) {
            let findvalidity = _.find(this.planHistoryTableData, { validity: validity });
            return findvalidity.findvalidity;
          } else {
            return 0;
          }
        },
      },
  
      {
        colKey: 'startDate',
        default: 'START DATE',
        rowKey: 'startDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'expiryDate',
        default: 'End Date',
        rowKey: 'expiryDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.expiryDateWrapperRef],
        customValue: (expiryDate) => {
          if (expiryDate != null) {
            let ExpiryDate = _.find(this.planHistoryTableData, { expiryDate: expiryDate });
            return ExpiryDate.expiryDate;
          } else {
            return "-"
          }
        },
      },
      {
        colKey: 'totalquotaformate',
        default: 'Total Quota',
        rowKey: 'totalquotaformate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        customControls: [this.totalQuotaWrapperRef],
        customValue: (volTotalQuota) => {
          if (volTotalQuota != null) {
            let findByTotalQouta = _.find(this.planHistoryTableData, { volTotalQuota: volTotalQuota });
            return findByTotalQouta.volTotalQuota + " " + findByTotalQouta.volQuotaUnit;
          } else {
            return "-"
          }
        },
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usedquotaformate',
        default: 'Used Quota',
        rowKey: 'usedquotaformate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        customControls: [this.usedQuotaWrapperRef],
        customValue: (volUsedQuota) => {
          if (volUsedQuota != null) {
            let findByUsedQouta = _.find(this.planHistoryTableData, { volUsedQuota: volUsedQuota });
            return findByUsedQouta.volUsedQuota + " " + findByUsedQouta.volQuotaUnit;
          } else {
            return "-"
          }
        },
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'vas',
        default: 'Associated VAS DETAILS',
        rowKey: 'vas',
        width: '10%',
        isNumber: false,
        customControls: [this.vasWrapperRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
     
      // {
      //   colKey: 'plangroup',
      //   default: 'Plan Type',
      //   rowKey: 'plangroup',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
    
  
      // {
      //   colKey: 'qosPolicyName',
      //   default: 'QOS Details',
      //   rowKey: 'qosPolicyName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // }
    ];

    this.planHistoryNeoGridSettings = {
      showFilter: false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PLAN_HISTORY_LIST,
      perPage: 5,
      tableHeight:300,
      headerSticky:true,
      verticalScroll:true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getFuturePlansData() {
    this.planHistoryService.getPlanHistoryList(this.custId).subscribe(result => {
      this.planHistoryTableData = result.dataList;
      ////console.log(this.planHistoryTableData);
      this.refreshDataSource(this.planHistoryTableData);
    });
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }
}
