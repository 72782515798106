import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PartnerLedgerService} from './partner-ledger.service';
import { saveAs } from 'file-saver/FileSaver';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-partner-ledger',
    templateUrl: './partner-ledger.component.html',
    styleUrls: ['./partner-ledger.component.scss']
})
export class PartnerLedgerComponent implements OnInit {

    @Input()
    franchise_id: any;
    public sub: any = null;
    partnerId: any;
    ledgerData: any;
    Credit : number = 0;
    Debit:number = 0;
    partnername: any;
    custUsername: any;
    custlastrenewDate: any;
    custAddress: any;
    accountStatus: any;
    custAmountDetails: any;
    openingBalance: any;
    closeBalance: any;
    exceldownload:any;
    balAmount:any
    partnerLedgerForm = new FormGroup({
        START_DATE : new FormControl(''),
        END_DATE : new FormControl(''),
        partner_id:new FormControl('')
      });
    

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private partnerLedgerService: PartnerLedgerService,
        private datepipe: DatePipe,
        private toaster:ToastrService
    ) {
    }

    ngOnInit() {
        let id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.partnerId = id;
        } else if (this.franchise_id) {
            this.partnerId = this.franchise_id;
        }
        this.getStartDateEndDate();
        let obj={
                "START_DATE": this.partnerLedgerForm.value.START_DATE,
                "END_DATE": this.partnerLedgerForm.value.END_DATE,
                "partner_id":Number(this.partnerId)
            }
       this.getLedgerDeta(obj);
             
    }
    
    exportToExcel(){
        // this.partnerLedgerService.excelServiceData(this.partnerId).subscribe(blob=>{
        //     saveAs(blob,"partnerledger.xls");
        // })
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();    
        let formvalue=this.exceldownload;
        this.partnerLedgerService.excelServiceData(formvalue).subscribe(blob=>{
          saveAs(blob, "[partner ledger " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
        })
    }

    getLedgerDeta(form) {
              
        let formatedData: any = {};
        formatedData.partner_id = Number(this.partnerId); //Change string to Number
        formatedData.START_DATE= this.datepipe.transform(form.START_DATE, 'yyyy-MM-dd');
        formatedData.END_DATE= this.datepipe.transform(form.END_DATE, 'yyyy-MM-dd');
        this.exceldownload=formatedData;
        let ddmmyyyyStartDate=new Date(formatedData.START_DATE);
        let ddmmyyyyEndDate=new Date(formatedData.END_DATE);

    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.toaster.error("Start date should be less then End date.");
    }
    else {
        this.partnerLedgerService.getPartnerLedgerData(formatedData).subscribe(result => {
            if(result.status!==200){
              this.toaster.error(result.error.ERROR)
            }else{
            this.ledgerData=null;

            this.ledgerData = result.partnerLedgerDtls;
            this.partnername = this.ledgerData.partnername;
            this.custUsername = this.ledgerData.username;
            this.custlastrenewDate = this.ledgerData.lastrenewDate;
            this.custAddress = this.ledgerData.address;
            this.accountStatus = this.ledgerData.status;
            this.custAmountDetails = this.ledgerData.partnerLedgerInfoPojo.debitCreditDetail;
            this.openingBalance = this.ledgerData.partnerLedgerInfoPojo.openingAmount;
            this.closeBalance = this.ledgerData.partnerLedgerInfoPojo.closingBalance;
            
            this.Credit=null;
            this.Debit=null;

            this.balAmount = this.ledgerData.partnerLedgerInfoPojo.balAmount;
            this.custAmountDetails.forEach(item => {
                if (item.transtype == 'CR') {
                  this.Credit += item.amount;
                } else {
                  this.Debit += item.amount;
                }
              });
            }
        },unknownerr => {
            this.toaster.error(unknownerr.error.ERROR);
          }
        );
    }
        
    }

    
    getStartDateEndDate() {
        var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        var startDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
        var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
        this.partnerLedgerForm.patchValue({ "START_DATE": startDate, "END_DATE": endDate });
      }

    reset(form){
        
        let formatedDataNew: any = {};
       
        formatedDataNew.START_DATE= new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        formatedDataNew.END_DATE= this.datepipe.transform(new Date(), 'yyyy-MM-dd');
        this.partnerLedgerForm.patchValue({ "START_DATE": formatedDataNew.START_DATE, "END_DATE": formatedDataNew.END_DATE });
        formatedDataNew.partner_id = Number(this.partnerId); //Change string to Number
        this.getLedgerDeta(formatedDataNew)
       
     }
    
}
