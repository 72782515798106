import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomModalService } from '../../../../components/custom-modal';
import { AllocationIpService } from './allocation-ip.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-allocation-ip',
  templateUrl: './allocation-ip.component.html',
  styleUrls: ['./allocation-ip.component.scss']
})
export class AllocationIpComponent implements OnInit {
  @Input() custId: any;
  
  submitted: any = null;
  openSelectIP: boolean = false;
  purchase: any = "";
  allocationIpSelectedData: any = "";
  chargeAmount: any = "";
  taxAmount: any = "";
  remarks: any = "";
  finalAmount: any = "";
  ip_pool: any = "";
  activation_date: any = "";
  end_date: any = "";
  ip_address: any = "";
  openAllocationIpSelectPopup: boolean = false;
  ApicallChange:string="ipAllocation"
  selectPurchaseList: any[] = [
    {
      chargeName: '',
      id: '',
    }
  ];
  insertAllocationIpForm = new FormGroup({
    purchase_id: new FormControl(null, [Validators.required]),
    select_ip: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required])
  })
  constructor(private customModalService: CustomModalService,
    private allocationIpService: AllocationIpService,
    private tosterservice: ToastrService,
    private ngbModalService:NgbModal,
  ) { }

  ngOnInit() {
    this.getSubscriberPurchasedIp();
   
  }

  getSubscriberPurchasedIp() {
    let custId: number = parseInt(this.custId);
    this.allocationIpService.getSubscriberPurchasedIp(custId).subscribe(
      result => {
        this.selectPurchaseList = result.data;
      }
    )
  }

  onPurchaseListChange(el) {
    if (el) {
      this.activation_date = el.startdate;
      this.end_date = el.enddateString.slice(0, 11);
      this.openSelectIP = true;
    } else {
      this.activation_date = "";
      this.end_date = "";
      this.openSelectIP = false;
    }
  }

  closeAllocationIpSelectPopup(data) {
        
    if (_.isEmpty(data.selectedFreeIpData) === false && _.isEmpty(data.selectedIpPool) === false) {
      this.allocationIpSelectedData = data;
      ////console.log("data::", this.allocationIpSelectedData)
      this.allocationIpService.getippoolnamebyid(this.allocationIpSelectedData.selectedFreeIpData.poolId).subscribe((res) => {
        ////console.log(res)
        this.ip_pool = res.data.poolName;
        this.ip_address = this.allocationIpSelectedData.selectedFreeIpData.ipAddress;
        this.insertAllocationIpForm.patchValue({ select_ip: this.ip_address });
     //   this.openAllocationIpSelectPopup = false;     
       })

    }
    this.openAllocationIpSelectPopup = false;
    
    // this.customModalService.close('allocation-ip-select', data);
    // this.closeModal('allocation-ip-select');
  }

  openAllocationIpSelectPopupClick() {
    
    if (this.openSelectIP === true) {
      this.openAllocationIpSelectPopup = true;
    }
    else {
      this.tosterservice.error("Please Select Purchase!");
    }
  }

  onClickSaveAllocationIpForm() {
    if (this.insertAllocationIpForm.valid === true) {
      let self = this;
      this.submitted = false;
      alertify.confirm("IP Allocation", "Are You Sure You want to Allocation IP Change ?",
        function () {
          self.saveApiCall();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertAllocationIpForm.markAllAsTouched();
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertAllocationIpForm.value);
    let formatedData: any = {
      "chargeId": apiInputData.purchase_id,
      "custId": parseInt(this.custId),
      "ipPoolDtlsId": this.allocationIpSelectedData.selectedFreeIpData.poolDetailsId,
      "remarks": apiInputData.remarks,
      "poolId": this.allocationIpSelectedData.selectedIpPool.poolId
    };
    this.allocationIpService.saveAllocationIp(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Allocation Ip Save Successfully.');
          this.customModalService.close('allocation-ip', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.submitted = false;
    this.insertAllocationIpForm.reset();
  }



}
