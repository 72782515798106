import { stringify } from '@angular/compiler/src/util';
import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as alertify from 'alertifyjs';
import { Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { PolicyModel } from '../model/policy-model';
import { QosPolicyService } from '../../../services/qos-policy/qos-policy.service';
import { IBaseService } from '../../../services/ibase-service';
import { ValidatorFn } from '@angular/forms';
import { convertCompilerOptionsFromJson } from 'typescript';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogPassData{
  id:number;
}

import { HostListener } from '@angular/core';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { CustomModalService } from '../../../components/custom-modal';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';
@Component({
  selector: 'app-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.scss'],
})
export class AddPolicyComponent extends AbstractBaseAddUpdateComponent<PolicyModel> implements OnInit {
  public policy: PolicyModel = new PolicyModel();
  public policy_id: any = null;
  qospolicyType: any;
  policyForm: FormGroup;
  isDisabled:boolean=false;
  allColumns: any[] = [];
  QosId: any;
  QoslistFlag: boolean;
  NeoGridTable: any;
  url: string;
  tablekeyEnum = TableKeyEnum;
  @Input() qosID: any = null;
  @Input() qosPolicyflag: boolean;
  constructor(
    private policyService: QosPolicyService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
   //private loadingService: LoadingService,
    private genericDataSharingService: DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddPolicyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    private qosService: QosPolicyService,
    private customModalService: CustomModalService
  ) {
    super(toastrService, router);
  }

  ngOnInit() {
    this.init();
    this.QosId = this.activeRoute.snapshot.paramMap.get('id');
    if (this.qosID != null) {
      // this.isUpdateComponent = true;
      // this.isDisabled=false;
      this.QosId = this.qosID;
      this.QoslistFlag = true;
      //this.getById(this.QosId);
      this.disabledfieldOnOpenPopup();
    }
    if (this.QosId) {
      this.isUpdateComponent = true;
      this.getById(Number(this.QosId));
    }
    // if(this.data && this.data.id){
    //   this.isDisabled=true;
    //   this.isUpdateComponent = false;
    //   this.getById(this.data.id);
    //   this.policyForm.disable();
    // }
    this.qosPolicy();
    //this.setId();
  }
  // setId() {
  //   this.QosId = this.activeRoute.snapshot.paramMap.get('id');
  // }
  getService(): IBaseService<PolicyModel> {
    return this.policyService;
  }

  setData(data: PolicyModel) {
    this.policy = data;
  }

  getData(): PolicyModel {
    return this.policy;
  }

  public getModuleHomeUrl() {
    if (!this.isUpdateComponent) {
      let id = this.responseData.data.id;
      this.QosId = id;
      this.onEditView(this.QosId);
    } else {
      return 'plan-management/qos-policy';
    }
  }
  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): PolicyModel {
    return new PolicyModel();
  }

  public getFormGroup(): FormGroup {
    return this.policyForm;
  }

  public getId(): number {
    return this.policy.id;
  }

  get f() {
    return this.policyForm.controls;
  }

  setValidation() {
    this.policyForm = new FormGroup({
      policy_name: new FormControl('', [Validators.required]),
      qosPolicyType: new FormControl(null, Validators.required),
      normalspeed: new FormControl('', [Validators.required, Validators.pattern('^[0-9.]+[0-9.]*$')]),
      fupspeed: new FormControl(''),
      normalUpTo: new FormControl(true),
      fupUpTo: new FormControl(true),
      // policy_name: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(6)]),
      policy_desc: new FormControl(''),
      basePolicyName: new FormControl(''),
      baseUploadSpeed: new FormControl(''),
      // baseUploadSpeed: new FormControl('', [Validators.required, Validators.min(4), Validators.max(4)]),
      baseDownloadSpeed: new FormControl(''),
      thUploadSpeed: new FormControl(''),
      thDownloadSpeed: new FormControl(''),
      thPolicyName: new FormControl(''),
      baseParam1: new FormControl(''),
      baseParam2: new FormControl(''),
      baseParam3: new FormControl(''),
      thParam1: new FormControl(''),
      thParam2: new FormControl(''),
      thParam3: new FormControl(''),
    });
  }



  // validateNumber(control: AbstractControl): { [key: string]: boolean } | null {
  //   if (control.value < 0) {
  //     return { "Invalid": true }
  //   } else {
  //     return null;
  //   }
  // }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }
  formSubmit() {
    let self = this;
    if (this.validateForm()) {
      alertify.confirm("QOS Policy", "Are you sure you want to save this record?",
        function () {
          self.onSubmit();
        },
        function () {

        });
    }
  }
  onAddQosDetailClick() {
    this.router.navigate(["/plan-management/qos-policy-detail/add", this.policy.id]);
  }
  onEditView(id) {
    this.router.navigate(['plan-management/qos-policy/add-qos-policy'], { queryParams: { id: id } });
  }
  // onCancel() {
  //   //this.router.navigate(['plan-management/qos-policy']);
  //   if(this.sharingdataService.lastAuditUrl == this.router.url){
  //     this.router.navigate(['/operations/audit']);
  //     this.sharingdataService.lastAuditUrl = undefined;
  //   }else{
  //     this.router.navigate(['plan-management/qos-policy']);
  //   }
  // }
  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }
  disabledfieldOnOpenPopup() {
    this.policyForm.get('policy_name').disable();
    this.policyForm.get('policy_desc').disable();
  }
  qosPolicy() {
    this.qosService.getQosPolicytype().subscribe(response => {
      this.qospolicyType = response.dataList;
    })
  }
  checknormalspeedupto(event) {
    if (event.target.checked) {
      this.policyForm.get('normalUpTo').setValue(true);
    } else {
      this.policyForm.get('normalUpTo').setValue(false);
    }
  }


  checkfupspeedupto(event) {
    if (event.target.checked) {
      this.policyForm.get('fupUpTo').setValue(true);
    } else {
      this.policyForm.get('fupUpTo').setValue(false);
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }


  // public getModuleHomeUrl(): string {

  //   this.url = '/network-management/serviceArea'
  //   return this.url;
  // }
  



getGenericDataService(){
return this.genericDataSharingService
}

  
}
