import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { ActivePlanListService } from './active-plan.service';
import _ from 'underscore';
import { CustomModalService } from '../../../../../components/custom-modal';
import { LoginService } from '../../../../login/login.service';
import { AclConstants } from '../../../../aclOperationConstants';
import { AclClassConstants } from '../../../../aclClassConstants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-active-plan',
  templateUrl: './active-plan.component.html',
  styleUrls: ['./active-plan.component.scss']
})
export class ActivePlanComponent implements OnInit {

  @ViewChild('totalQuota', { static: false }) totalQuotaWrapperRef: TemplateRef<any>;
  @ViewChild('usedQuota', { static: false }) usedQuotaWrapperRef: TemplateRef<any>;
  @ViewChild('cancelWrapper', { static: false }) cancelWrapperRef: TemplateRef<any>;
  @ViewChild('validity', { static: false }) validityWrapperRef: TemplateRef<any>;
  @ViewChild('expiryDate', { static: false }) expiryDateWrapperRef: TemplateRef<any>;
  @ViewChild('vasBtn', { static: false }) vasWrapperRef: TemplateRef<any>;
  @ViewChild('addOnWrapper', { static: false }) addOnRef: TemplateRef<any>;
  @ViewChild('planType', { static: false }) planTypeRef: TemplateRef<any>;

  @Input() custId: any;
  planId: any;
  custplanId: any;
  planType: any = "active";
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  activePlanNeoGridSettings = { };
  subscriptions: Subscription[] = [];
  openCancelPlanPopup: boolean;
  activePlanTableData: any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  planMapId: any;
  public loginService: LoginService;
  constructor(
    private activePlanListService: ActivePlanListService,
    private customModalService: CustomModalService,
    private modalService: NgbModal,
    loginservice: LoginService,
  ) {
    this.loginService = loginservice;
   }

  ngOnInit() {
    this.setGridSetting();
    this.getActivePlanData();
  }
  
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'totalquotaformate') {
        col.customControls = [this.totalQuotaWrapperRef];
      }
      if (col.colKey === 'usedquotaformate') {
        col.customControls = [this.usedQuotaWrapperRef];
      }
      if (col.colKey === 'cancel') {

        col.customControls = [this.cancelWrapperRef];
      }
      if (col.colKey === 'validity') {
        col.customControls = [this.validityWrapperRef];
      }
      if (col.colKey === 'expiryDate') {
        col.customControls = [this.expiryDateWrapperRef];
      }
      if (col.colKey === 'vas') {
        col.customControls = [this.vasWrapperRef];
      }
      if (col.colKey == 'isAddOn') {
        col.customControls = [this.addOnRef];
      }
      if (col.colKey == 'plangroup') {
        col.customControls = [this.planTypeRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'planName',
        default: 'Plan Name',
        rowKey: 'planName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'plangroup',
        default: 'Plan Type',
        rowKey: 'plangroup',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validityType',
        default: 'Validity Type ',
        rowKey: 'validityType',
        width: '10%',
        isNumber: false,

        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'service',
        default: 'Service Type ',
        rowKey: 'service',
        width: '10%',
        isNumber: false,

        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'isAddOn',
        default: 'AddOn',
        rowKey: 'isAddOn',
        customControls: [this.addOnRef],
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validity',
        default: 'Validity',
        rowKey: 'validity',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.validityWrapperRef],
        customValue: (validity: any) => {
          if (validity != 0) {
            let findvalidity = _.find(this.activePlanTableData, { validity: validity });
            return findvalidity.validity;
          } else {
            return 0;
          }
        },
      },
      {
        colKey: 'totalquotaformate',
        default: 'Total Quota',
        rowKey: 'totalquotaformate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        customControls: [this.totalQuotaWrapperRef],
        customValue: (volTotalQuota) => {
          if (volTotalQuota != null) {
            let findByTotalQouta = _.find(this.activePlanTableData, { volTotalQuota: volTotalQuota });
            return findByTotalQouta.volTotalQuota + " " + findByTotalQouta.volQuotaUnit;
          } else {
            return "-"
          }
        },
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usedquotaformate',
        default: 'USED QUOTA',
        rowKey: 'usedquotaformate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        customControls: [this.usedQuotaWrapperRef],
        customValue: (volUsedQuota) => {
          if (volUsedQuota != null) {
            let findByUsedQouta = _.find(this.activePlanTableData, { volUsedQuota: volUsedQuota });
            return findByUsedQouta.volUsedQuota + " " + findByUsedQouta.volQuotaUnit;
          } else {
            return "-"
          }
        },
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'exceedQuota',
        default: 'Exceed Quota',
        rowKey: 'exceedQuota',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'startDate',
        default: 'START DATE',
        rowKey: 'startDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'expiryDate',
        default: 'END DATE',
        rowKey: 'expiryDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.expiryDateWrapperRef],
        customValue: (expiryDate) => {
          if (expiryDate != null) {
            let ExpiryDate = _.find(this.activePlanTableData, { expiryDate: expiryDate });
            return ExpiryDate.expiryDate;
          } else {
            return "-"
          }
        },
      },
      {
        colKey: 'vas',
        default: 'Associated VAS DETAILS',
        rowKey: 'vas',
        width: '10%',
        isNumber: false,
        customControls: [this.vasWrapperRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'cancel',
        default: 'Action',
        rowKey: 'cancel',
        width: '10%',
        isNumber: false,
        customControls: [this.cancelWrapperRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }
    ];

    this.activePlanNeoGridSettings = {
      showFilter:false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      pageNumber:1,
      tableId: this.tableKeyEnum.ACTIVE_PLAN_LIST,
      perPage: 5,
      tableHeight:null,
      headerSticky:true,
      verticalScroll:true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  openModal(id: string, planmapid: any, planId: any) {
    if (id === 'cancel-plan') {
      this.openCancelPlanPopup = true;
      setTimeout(() => {
        this.customModalService.open(id);
      }, 500);
      let findId = _.find(this.activePlanTableData, { planmapid: planmapid, planId: planId });
      this.planId = findId.planmapid;
      this.custplanId = findId.planId;
    } else {
      this.openCancelPlanPopup = false;
      this.getActivePlanData();
    }
  }

  closeCancelPlanPopup(event) {
    this.openCancelPlanPopup = false;
    this.getActivePlanData();
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getActivePlanData() {
    this.activePlanListService.getActivePlanList(this.custId).subscribe(result => {
      this.activePlanTableData = result.dataList;
      this.refreshDataSource(this.activePlanTableData);
    });
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }
}
