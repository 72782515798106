import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  constructor(private http: HttpClient) {

  }

  transformSubscriberList(data) {
    let formatedData = [];
    data.customerList.forEach(function(item:any){
      let itemData:any = {};
      itemData.subscriber_id = item.id;
      itemData.email = item.email;
      itemData.username = item.username;
      itemData.status = item.status;
      itemData.activeuserflag = item.activeuserflag;
      itemData.name = item.name;
      itemData.mobile = item.mobile;
      itemData.acctno = item.acctno;
      itemData.partnerName = item.partnerName;
      itemData.outstanding = item.outstanding;
      itemData.connectivity = item.connectivity; 
      itemData.speeddowngradeflag = item.speeddowngradeflag;//New Key
      itemData.networkDevice = item.networkDetails.networkdevicename;
      itemData.serviceArea = item.networkDetails.serviceareaname;
      itemData.portName = item.networkDetails.portname;
      itemData.slotName = item.networkDetails.slotname;
      itemData.planDisplayName = item.planDisplayName;
      itemData.snmpportname = item.snmpportname;
      itemData.ipAddress = item.ipAddress;
      itemData.expirydate = item.expirydate;
      itemData.createdate = item.createdate;
      itemData.fulladdress=item.fulladdress;
      itemData.macaddress=item.macaddress;
      itemData.didno=item.didno;
      itemData.createdby=item.createdby;
      itemData.lastmodifiedby=item.lastmodifiedby;
      itemData.lastmodifieddate=item.lastmodifieddate;
      itemData.partnerId = item.partnerId;
      itemData.firstpaymentamount = item.firstpaymentamount;
      itemData.firstpaymentdate = item.firstpaymentdate;
      itemData.firstpaymentmode = item.firstpaymentmode;
      itemData.firstpaymentreferenceno = item.firstpaymentreferenceno;
      itemData.firstpaymentremark = item.firstpaymentremark;
      itemData.maxexpirydate = item.maxexpirydate;
      formatedData.push(itemData);
    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getSubscriberList(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'customers/list';
    return this.http.post<any>(post_url,params ,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {  
          return this.transformSubscriberList(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  deleteSubscriber(area_id: any): Observable<any> {
    const delete_url = BASE_API_URL + 'customers/' + area_id;
    return this.http.delete(delete_url, httpOptions);
  }
  
  getSubscriberById(id): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getBasicCustDetails/' + id;
    return this.http.get<any>(get_url,
        { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }



  getDocTypeForCustomerList(): Observable<any> {
    const get_url = 'subscriber/getDocTypeForCustomer';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getAdvanceFilter(obj){
    const post_url = BASE_API_URL + 'customers/list';
    return this.http.post<any>(post_url, obj, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return this.transformSubscriberList(res);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getCaseAdvanceFilter(obj){
    obj['page']=1,
    obj['pageSize']=10
    const post_url = BASE_API_URL + 'case';
    return this.http.post<any>(post_url,obj, { headers: httpOptions.headers });
  }

  findDailyNewSubscribers(): Observable<any> {
    const get_url = 'subscriber/findDailyNewSubscribers';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  findNewSubscriptionVsRenewal(): Observable<any> {
    const get_url = 'subscriber/findNewSubscriptionVsRenewal';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  // getVlan/{custid}
  getVlan(custId:any): Observable<any> {
    const get_url = 'subscriber/getVlan/'+ custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  resetVlan(data){
    const post_url = BASE_API_URL + 'subscriber/resetVlan';
    return this.http.post<any>(post_url,data)
  }

  updateVlan(data: any): Observable<any> {
    const post_url = BASE_API_URL + 'subscriber/updateVlan';
    return this.http.post<any>(post_url, data, httpOptions).pipe(
      map((data) => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}



