import { Component, OnInit ,Optional,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {formatFunction} from 'moment';
import { HostListener } from '@angular/core';
import {forEach} from '@angular-devkit/schematics';
import {ToastrService} from 'ngx-toastr';
import {isLineBreak} from 'codelyzer/angular/sourceMappingVisitor';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { AclSave } from '../../generic-component/acl/acl-gerneric-component/model/acl-save';
import { Acl } from '../../generic-component/acl/acl-gerneric-component/model/acl';
import { AclService } from '../service/acl.service';
import { CommonStatusService } from '../../../services/common_status/common-status.service';
import { Aclsaveoperationlist } from '../../generic-component/acl/acl-gerneric-component/model/aclsaveoperationlist';
import { AclOperationsList } from '../../generic-component/acl/acl-gerneric-component/model/acl-operations-list';
import { IBaseService } from '../../../services/ibase-service';
import { MatDialog ,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';



export interface DialogPassData {
  id: number;
}


interface Values {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent extends AbstractBaseAddUpdateComponent<AclSave> implements OnInit {
  public entity: AclSave = new AclSave();
  dataList: Array<Acl>;
  temp: Array<Acl>;
  saveSelectedPermission: AclSave;
  commonStatusList: any;
  url:string;
  check:boolean;
  error_message = "This field is required.";
  roleform: FormGroup;
  allOpgetFlag:boolean= false;
  isDisabled:boolean=false;

  constructor(
    private router : Router,
    private aclService : AclService,
    private toastrService: ToastrService,
    private  commonService: CommonStatusService,
    private activeRoute: ActivatedRoute,
    private genericDataSharingService: DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddRoleComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {  super(toastrService, router);}

  ngOnInit() {
    
    this.saveSelectedPermission =  new AclSave();
    ////console.log(this.saveSelectedPermission);
    this.saveSelectedPermission.aclEntryPojoList =  new Array<Aclsaveoperationlist>();
    this.init();  
    this.setValidation();
    // if(this.data && this.data.id){
    //   this.isDisabled=true;
    //   this.saveSelectedPermission =  new AclSave();
    //   ////console.log(this.saveSelectedPermission);
    //   this.getById(this.data.id)
    // }else{
    //   this.isDisabled=false;
    // }

    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
    
    
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }
  getData(): AclSave {
    return this.saveSelectedPermission;
  }


  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  getService(): IBaseService <AclSave> {
    return this.aclService;
  }
  public getNewModal(): AclSave {
    return new AclSave();
  }
  public getId(): number {
    return this.entity.id;
  }
  public getFormGroup(): FormGroup {
    return this.roleform;
  }
  public getModuleHomeUrl(): string {
    this.url ='/hr/acl'
    return this.url;
  }
    setData(data: AclSave) {
      
      if(this.allOpgetFlag === false){
        this.aclService.getAllOperation().subscribe((res) => {
          this.dataList = res.dataList;
         this.allOpgetFlag = true;
         ////console.log("dataList::",this.dataList)
        });
      }
     this.commonService.getCommonList().subscribe((res) => {
      this.commonStatusList = res.dataList;
    });
    setTimeout(() => {
      if(this.allOpgetFlag === true){
        if(this.isUpdateComponent && 
          this.saveSelectedPermission &&
           this.saveSelectedPermission.aclEntryPojoList && this.saveSelectedPermission.aclEntryPojoList.length > 0){
          if(this.dataList){
           this.dataList.forEach((res) => {
        
              res.SelectOperationsList =  new Array<AclOperationsList>();
              this.saveSelectedPermission.aclEntryPojoList.forEach((sdata)=>{
                if(res.operallid === sdata.permit){
                  ////console.log("ACL REs",res)
                  let temp =   this.dataList.findIndex(e=>e.id === sdata.classid)
      
                this.dataList[temp].fullaccess = true;
                }
                if(res.id === sdata.classid){
                  res.aclOperationsList.forEach((ls)=>{
                    if(ls.id === sdata.permit){
                      res.SelectOperationsList.push(ls);
                    }
                  });
    
                }
              });
            });
          }
    
          }
      }
    }, 1000);
   
    if(data){
      this.saveSelectedPermission = data;
      ////console.log("save per::",this.saveSelectedPermission)
    }
    
   
  }

  public onResponseError(message: string) {
    this.toastrService.error(message.replace('\n', '<br/>'), 'Error', { enableHtml: true });
  }
  get f() {
    return this.roleform.controls;
  }
  formSubmit() {
    if (this.validateForm()) {
      this.onSubmit();
    }
    else{
      this.toastrService.error("Please fill all required field");
    }
  }
  setValidation(){
    this.roleform = new FormGroup({
      role_name : new FormControl('',[Validators.required]),
      status : new FormControl(null,[Validators.required]),
    });
  }
  onDataSave(event) {
    this.dataList = event;
    }

    onAllAccessClick(event){
      this.saveSelectedPermission.isAllOperation = event;
    }

onPermitIDCheck(temp){
    this.check = true;
    this.saveSelectedPermission.aclEntryPojoList.forEach((res)=>{
      if(temp.permit === res.permit){
        this.check = false;
        return;
      }

    });

}
onRoleSave() {
       this.submitted=true;
      this.roleform.markAllAsTouched();
      if(this.roleform.invalid ){
       return   
      }

  if (this.validateForm()) {
    if (this.isUpdateComponent) {
      this.saveSelectedPermission.aclEntryPojoList = new Array<Aclsaveoperationlist>();
      if (this.dataList) {
        this.dataList.forEach((res) => {
          if (res.SelectOperationsList) {
            res.SelectOperationsList.forEach((sres) => {
              let temp = new Aclsaveoperationlist();
              temp.classid = sres.classid;
              temp.permit = sres.id;
              this.onPermitIDCheck(temp);
              if (this.check === true) {

                this.saveSelectedPermission.aclEntryPojoList.push(temp);
              }
            });
          }
        });
      }
      if (this.saveSelectedPermission.aclEntryPojoList.length > 0) {
        this.formSubmit();
      } else {
        this.toastrService.error("Please select atleat one permission");
      }
    }
    if (!this.isUpdateComponent) {
      ////console.log("innn save")
      if (this.dataList) {
        this.saveSelectedPermission.aclEntryPojoList = new Array<Aclsaveoperationlist>()
        this.dataList.forEach((res) => {
          if (res.SelectOperationsList) {
            res.SelectOperationsList.forEach((sres) => {
              let temp = new Aclsaveoperationlist();
              temp.classid = sres.classid;
              temp.permit = sres.id;
              ////console.log("temp::",temp)
              this.saveSelectedPermission.aclEntryPojoList.push(temp);
              ////console.log("save::", this.saveSelectedPermission.aclEntryPojoList)
            });
          }
        });
      }
      if (this.saveSelectedPermission.aclEntryPojoList.length > 0) {
        this.formSubmit();
      } else {
        this.toastrService.error("Please select atleat one permission");
      }

    }
  } else {
    this.toastrService.error("Please fill all required field");
  }
}



  onRoleCancel() {
    this.router.navigate(['/hr/acl']);
  }

  onBackClick() {
    this.router.navigate(['/hr/acl']);
  }

  closeDialog(){
  this.dialogRef.close();
  }

  getGenericDataService(){
    return this.genericDataSharingService
    }
}
