import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

export class PlanGroup {
  id : string;
  text : string;
  value : string;
}

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class PlanGroupService {
  constructor(private http: HttpClient) {}

  getPlanGroupList(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/planGroup';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as PlanGroup[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
