import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class IppoolConfigService {

  constructor(private http: HttpClient) { }

  getippoolById(data:any): Observable<any> {
    const post_url = BASE_API_URL + 'ipPoolForPartner/getPoolListByPartnerId';
    return this.http.post<any>(post_url,data)
  }

  getippool(): Observable<any> {
    const get_url = BASE_API_URL + 'ipPoolForPartner/getUnassignedPools';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  saveIPpoolByPartner(data){
    
    const post_url = BASE_API_URL + 'ipPoolForPartner/saveIpPoolForPartner';
    return this.http.post<any>(post_url,data)
  }
  deleteIPpoolByPartner(data){
    
    const delete_url = BASE_API_URL + 'ipPoolForPartner/delete';
    return this.http.post<any>(delete_url,data)
  }

  getIpDetailsDataWithPagination(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'ippool/poolDetailsByIpPool';
    return this.http.post<any>(post_url,params,
        { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }
}
