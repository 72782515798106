import { Component, OnInit,Input } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';

import { ReversePaymentService } from './reverse-payment.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-reverse-payment',
  templateUrl: './reverse-payment.component.html',
  styleUrls: ['./reverse-payment.component.scss']
})
export class ReversePaymentComponent implements OnInit {

  @Input() custId : any;
  @Input() bal: any;
  @Input() currentOutstanding : any;
  submitted:any = null;
  reversePaymentForm = new FormGroup({
    payment : new FormControl( null,[Validators.required]),
    reversalDate : new FormControl('',[Validators.required]),
    remarks : new FormControl('',[Validators.required]),
  })

  outstandingPost = 0;

  public paymentsList: any[] = [
    {
      display_text: '',
      id: '',
    },
  ];

  constructor(
    private customModalService : CustomModalService,
    private reversePaymentService : ReversePaymentService,
    private tosterservice : ToastrService,
    private datepipe:DatePipe

  ) { }

  ngOnInit() {
    this.getAllPaymentsByCustomerId();
  }

  getAllPaymentsByCustomerId() {
    let subscriber_id = this.custId;
    this.reversePaymentService.getReversiblePaymentCustomerId(subscriber_id).subscribe((result) => {
      this.paymentsList = result.data.creditDocumentPojo;
    });
  }

  onChangePayment(el) {
    if (el) {
      this.outstandingPost = this.currentOutstanding - el.amount;
    } else {
      this.outstandingPost = 0;
    }
  }



  onClickSaveReversePaymentForm() {
    if (this.reversePaymentForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Reverse Payment Details","Are You Sure You want to Reverse Payment ?",
      function() {
        self.saveApiCall();
      },
      function() {

      });
    } else {
      this.submitted = true;
      this.tosterservice.error("Please Fill All Required Details!")
      this.reversePaymentForm.markAllAsTouched();
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.reversePaymentForm.value);

    let formatedData:any = {};
    formatedData.custId = this.custId;
    formatedData.payment_id =  apiInputData.payment;
    formatedData.rev_amt =  this.outstandingPost;
    formatedData.rev_date =  this.datepipe.transform(apiInputData.reversalDate,'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
    formatedData.rev_remarks =  apiInputData.remarks;

    this.reversePaymentService.updateReversePayment(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Reverse Payment Details Save Successfully.');
          this.customModalService.close('reverse-payment',result);
        }
      },err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id:string){
    this.customModalService.close(id);
  }

}
