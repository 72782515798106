import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { ContectDetailsService } from './contect-details.service';
import * as alertify from 'alertifyjs';
import { AddSubscriberDetailsService } from '../../../masters/add-subscriber-details/add-subscriber-details.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {

  @Input() custId: any;
  selectForUpdate: any;
  contectDetails: any;
  mobile: any;
  email: any;
  alt_mobile:any;

  invalidEmailAsPerApi: boolean = false;
  invalidEmailAsPerApiMsg: string = '';

  invalidMobileAsPerApi: boolean = false;
  invalidMobileAsPerApiMsg: string = '';

  insertContectDetailsForm = new FormGroup({
    altEmail: new FormControl(''),
    altMobile: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    altPhone: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    email: new FormControl('', [Validators.required, Validators.email]),
    fax: new FormControl(''),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    phone: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    remarks: new FormControl('', [Validators.required])
  })

  constructor(
    private customModalService: CustomModalService,
    private contectDetailsService: ContectDetailsService,
    private cdRef: ChangeDetectorRef,
    public addSubscriberDetailsService: AddSubscriberDetailsService,
    private tosterservice: ToastrService,
    private addSubscriber: AddSubscriberDetailsService

  ) {
    this.getRequiredAllField();
  }

  ngOnInit() {
    this.getContectDetails();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getIsInvalidMobileAsPerApi() {
    return this.invalidMobileAsPerApi;
  }

  // checkUniqueCustomerByMobile(el) {
  //   let value = el.target.value;
  //   if (value && value.length) {
  //     this.invalidMobileAsPerApi = false;
  //     this.addSubscriberDetailsService.checkUniqueCustomerByMobile(value,this.custId).subscribe(result => {
  //       if (result.responseCode !== 200) {
  //         this.invalidMobileAsPerApi = true;
  //         this.invalidMobileAsPerApiMsg = result.responseMessage;
  //       } else {
  //         this.invalidMobileAsPerApi = false;
  //         this.invalidMobileAsPerApiMsg = '';
  //       }
  //     });
  //   } else {
  //     this.invalidMobileAsPerApi = false;
  //     this.invalidMobileAsPerApiMsg = '';
  //   }
  // }

  getIsInvalidEmailAsPerApi() {
    return this.invalidEmailAsPerApi;
  }

  checkUniqueCustomerByEmail(el) {
    if (this.insertContectDetailsForm.get('email').value != null && this.email.allowDuplicate === true) {
      let value = el.target.value;
      if (value && value.length) {
        this.invalidEmailAsPerApi = false;
        this.addSubscriberDetailsService.checkUniqueCustomerByEmail(value, this.custId).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidEmailAsPerApi = true;
            this.invalidEmailAsPerApiMsg = result.responseMessage;
          } else {
            this.invalidEmailAsPerApi = false;
            this.invalidEmailAsPerApiMsg = '';
          }
        });
      } else {
        this.invalidEmailAsPerApi = false;
        this.invalidEmailAsPerApiMsg = '';
      }
      this.invalidEmailAsPerApi = false;
    }

  }
  checkUniqueCustomerByMobile(el) {
    if (this.insertContectDetailsForm.get('mobile').value != null && this.mobile.allowDuplicate === true) {
      let value = el.target.value;
      if (value && value.length) {
        this.invalidMobileAsPerApi = false;
        this.addSubscriberDetailsService.checkUniqueCustomerByMobile(value, this.custId).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidMobileAsPerApi = true;
            this.invalidMobileAsPerApiMsg = result.responseMessage;
          } else {
            this.invalidMobileAsPerApi = false;
            this.invalidMobileAsPerApiMsg = '';
          }
        });
      } else {
        this.invalidMobileAsPerApi = false;
        this.invalidMobileAsPerApiMsg = '';
      }
      this.invalidMobileAsPerApi = false;
    }

  }

  setUpdateValueInForm(contectDetails_data) {
    this.selectForUpdate = contectDetails_data;
    this.insertContectDetailsForm.patchValue(contectDetails_data);
  }

  getContectDetails() {
    this.contectDetailsService.getContectDetails(this.custId).subscribe(result => {
      this.contectDetails = result.data;
      this.setUpdateValueInForm(this.contectDetails);
    })
  }
  getRequiredAllField() {
    this.addSubscriber.getRequiredAllField().subscribe(res => {
      if (res.responseCode === 200) {
        let createDynamicValidation = res.data;
        this.mobile = createDynamicValidation.find((e => e.fieldname === 'mobile_no'));
        this.email = createDynamicValidation.find((e => e.fieldname === 'email'));
        this.alt_mobile = createDynamicValidation.find((e => e.fieldname === 'alt_mobile'));
        if ((this.mobile !== null && this.mobile !== undefined) && this.mobile.isMandatory === true) {
          this.insertContectDetailsForm.get('mobile').setValidators([Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
            this.insertContectDetailsForm.get('mobile').updateValueAndValidity()
        } else {
          this.insertContectDetailsForm.get('mobile').clearValidators(),
            this.insertContectDetailsForm.get('mobile').updateValueAndValidity()
        }
        if ((this.email !== null && this.email !== undefined) && this.email.isMandatory === true) {
          this.insertContectDetailsForm.get('email').setValidators([Validators.required]),
            this.insertContectDetailsForm.get('email').updateValueAndValidity()
        } else {
          this.insertContectDetailsForm.get('email').clearValidators(),
            this.insertContectDetailsForm.get('email').updateValueAndValidity()
        }
        if ((this.alt_mobile !== null && this.alt_mobile !== undefined) && this.alt_mobile.isMandatory === true) {
          this.insertContectDetailsForm.get('altMobile').setValidators([Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
            this.insertContectDetailsForm.get('altMobile').updateValueAndValidity()
        } else {
          this.insertContectDetailsForm.get('altMobile').clearValidators(),
          this.insertContectDetailsForm.get('altMobile').setValidators(Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
            this.insertContectDetailsForm.get('altMobile').updateValueAndValidity()
        }
      }
    })
  }
  clearMsgUnique() {
    if (this.invalidMobileAsPerApi === true &&
      this.insertContectDetailsForm.get('mobile').valid === false) {
      this.invalidMobileAsPerApiMsg = '';
    }
    if (this.invalidEmailAsPerApi === true &&
      this.insertContectDetailsForm.get('email').valid === false) {
      this.invalidEmailAsPerApiMsg = '';
    }
  }
  checkUniqueMobile_onSave() {
    if (this.insertContectDetailsForm.get('mobile').value != null && this.mobile.allowDuplicate === true) {
      let mobile = this.insertContectDetailsForm.get('mobile').value;
      this.invalidMobileAsPerApi = false;
      this.addSubscriberDetailsService.checkUniqueCustomerByMobile(mobile, this.custId).subscribe(result => {
        if (result.responseCode !== 200) {
          this.invalidMobileAsPerApi = true;
          this.invalidMobileAsPerApiMsg = result.responseMessage;
        } else {
          this.invalidMobileAsPerApi = false;
          this.invalidMobileAsPerApiMsg = '';
        }
      });
    } else {
      this.invalidMobileAsPerApi = false;
      this.invalidMobileAsPerApiMsg = '';
    }
    this.invalidMobileAsPerApi = false;

  }
  checkUniqueEmail_onSave() {
    if (this.insertContectDetailsForm.get('email').value != null && this.email.allowDuplicate === true) {
      let email = this.insertContectDetailsForm.get('email').value;
      this.invalidEmailAsPerApi = false;
      this.addSubscriberDetailsService.checkUniqueCustomerByEmail(email, this.custId).subscribe(result => {
        if (result.responseCode !== 200) {
          this.invalidEmailAsPerApi = true;
          this.invalidEmailAsPerApiMsg = result.responseMessage;
        } else {
          this.invalidEmailAsPerApi = false;
          this.invalidEmailAsPerApiMsg = '';
        }
      });
    } else {
      this.invalidEmailAsPerApi = false;
      this.invalidEmailAsPerApiMsg = '';
    }
    this.invalidEmailAsPerApi = false;
  }


  onClickSaveForm() {
    this.checkUniqueEmail_onSave();
    this.checkUniqueMobile_onSave();
    setTimeout(() => {
      if (this.insertContectDetailsForm.valid === true && !this.invalidEmailAsPerApi && !this.invalidMobileAsPerApi) {
        let self = this;
        alertify.confirm("Contact Details", "Are You Sure You want to Update Contact Details ?",
          function () {
            self.insertContectDetailsOnDb();
          },
          function () {

          });
      } else {
        this.insertContectDetailsForm.markAllAsTouched();
      }
    }, 700);
  }

  insertContectDetailsOnDb() {
    if (this.insertContectDetailsForm.valid === true) {
      let formatedInputData: any = this.insertContectDetailsForm.value;
      formatedInputData.custId = this.custId;
      this.contectDetailsService.insertContectDetails(formatedInputData).subscribe(result => {
        this.cdRef.detectChanges();
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Contact Detail Update successfully');
          this.customModalService.close('contect-details', result);
        }
      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

}
