import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { TatConsiderationService } from '../../../../services/tat_consideration/tat-consideration.service';
import * as alertify from 'alertifyjs';
import { CountryService } from '../../country/country.service';
import { StateService } from '../../state/state.service';
import { CityService } from '../../city/city.service';
import { PinAreaModel, pinCodeId } from '../pin-area-model';
import { PinAreaService } from '../pin-area.service';
import { SubscriberService } from '../../subscriber/subscriber.service';
import { HostListener } from '@angular/core';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
import _ from 'underscore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../../data-sharing-service.service';

export interface DialogPassData {
  id: number;
}

@Component({
  selector: 'app-pin-area-add-update',
  templateUrl: './pin-area-add-update.component.html',
  styleUrls: ['./pin-area-add-update.component.scss']
})
export class PinAreaAddUpdateComponent extends AbstractBaseAddUpdateComponent<PinAreaModel> implements OnInit {
  public entity: PinAreaModel = new PinAreaModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
  commonStatus: any;
  countryList: any;
  areaId:any;
  default_pincode_value:any = '';
  public stateList: any[] = [{
      state_name: '',
      state_id: ''
  }];

  public cityList: any[] = [{
      city_name: '',
      city_id: ''
  }];
  pincodeAllList:any;
  pinCode:any;
  searchPincodeLink : string = '';
  ispincode:boolean=false;
  isDisabled:boolean=false;

  public area: any = null;
  constructor(
    private pincodeService: PinAreaService,
    private genericDataSharingService: DataSharingServiceService,
    public CommonStatus: CommonStatusService,
    public CommonTat: TatConsiderationService,
    public countryService:CountryService,
    public sateservice:StateService,
    public cityservice:CityService,
    public addSubscriberDetailsService:AddSubscriberDetailsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
   // public loadingService: LoadingService,
    
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<PinAreaAddUpdateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    
    ) {
    super(toastrService, router);
  }

  ngOnInit() {
    this.init();

    // if(this.data && this.data.id){
    //   //this.isUpdateComponent = false;
    //   this.isDisabled=true;
    //   this.getById(this.data.id);
    // }else{
    //   this.isDisabled=false;
    // }

    this.CommonStatus.getCommonList().subscribe((res) => {
      this.commonStatus = res.dataList
    })
    this.countryService.getCountryList().subscribe((res)=>{
      this.countryList = res.content;
    });
    this.sateservice.getallstate().subscribe((res)=>{
      this.stateList = res.stateList;
      ////console.log("state::",this.stateList);
    });
    this.cityservice.getcityList().subscribe((res)=>{
      this.cityList = res.cityList;
      ////console.log("city::",this.cityList);
    });
    this.addSubscriberDetailsService.getPincodeAll().subscribe(result => {
      this.pincodeAllList = result.dataList;
    });
    this.form.controls.countryId.disable();
    this.form.controls.stateId.disable();
    this.form.controls.cityId.disable();
    // this.getAreaAll();
   

    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.areaId = id;
      this.isUpdateComponent = true;
      this.getAreaDetails();
    }
    this.getPincode();

    if(this.data && this.data.id){
      this.isDisabled=true;
      this.areaId=this.data.id;
      this.getAreaDetails();
    }

    // this.area = this.activeRoute
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id'];
    //     if (id) {
    //       this.areaId = params['id'];
    //       this.getAreaDetails();
    //     }
    // });
  }
  setValidation() {
    this.form = new FormGroup({
      area:new FormControl(null, [Validators.required]),
      countryId: new FormControl(null),
      cityId: new FormControl(null),
      stateId: new FormControl(null),
      status:new FormControl(null, [Validators.required]),
      pincode:new FormControl(null, [Validators.required]),
    });
    ////console.log(this.form);
  }

  getAreaDetails(){
    ////console.log("innn")
    this.pincodeService.getAreaDetails(this.areaId).subscribe((res) => {
      let area_data = res.data;
      ////console.log("area::",area_data)
      this.form.patchValue({area:area_data.name});

      this.addSubscriberDetailsService.getDetailsByPincode(area_data.code).subscribe(result => {
        ////console.log(result);
        let pincode_data = result.data;

        if (pincode_data) {
          this.pinCode = pincode_data.pincode.name;
          this.default_pincode_value = {
            pincode:pincode_data.pincode.name,
            pincodeid:pincode_data.pincode.id,
          };
          if (pincode_data.country && pincode_data.country.id) {
            this.form.patchValue({countryId:pincode_data.country.id});
          }
          if (pincode_data.state && pincode_data.state.id) {
            this.getStateListByCountryIdAndSetStateById(pincode_data.country.id,pincode_data.state.id);
          }
          if (pincode_data.city && pincode_data.city.id) {
            this.getCityListByStateIdAndSetState(pincode_data.state.id,pincode_data.city.id);
          }
          this.form.patchValue({pincode:pincode_data.pincode.id});


          ////console.log("statuslis",pincode_data);
          ////console.log("status",pincode_data.status);
          this.entity.pincode = new pinCodeId();
          this.entity.pincode.pincodeid =  pincode_data.pincode.id;
          this.entity.countryId = pincode_data.country.id;
          this.entity.stateId = pincode_data.state.id;
          this.entity.cityId = pincode_data.city.id;
          this.entity.status=area_data.status;
          
          this.entity.id = this.areaId;
        }
      });

    })
  }
   @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getService(): IBaseService<PinAreaModel> {
    return this.pincodeService;
  }

  getGenericDataService(){
    return this.genericDataSharingService
  }

  // onchangePin(event){
  //
  //   this.pincodeservice.getDetailsByPincode(event.pincode).subscribe((res)=>{
  //     let data = res.data;
  //     this.entity.pincode = new pinCodeId();
  //     this.entity.pincode.pincodeid =  data.pincode.id;
  //     this.entity.countryId = data.country.id;
  //     this.entity.stateId = data.state.id;
  //     this.entity.cityId = data.city.id;
  //
  //     if (data.areaList) {
  //       this.areaAllList = data.areaList;
  //       if(data.areaList.length === 1){
  //         let area = data.areaList[0];
  //         this.form.patchValue({ area: area.id });
  //       } else {
  //         this.form.patchValue({ area: null });
  //         // if (call_from_autocomplete != true) {
  //         //   this.form.patchValue({ area: null });
  //         // }
  //       }
  //     }
  //   })
  // }

  onPincodeChange(elItem) {
    if(elItem){
      let el;
      let call_from_autocomplete;
      if (_.isArray(elItem) == true) {
        el = _.first(elItem);
      } else {
        el = elItem.event;
        call_from_autocomplete = elItem.call_from_autocomplete;
      }
      if (el && el.pincode) {
        this.form.controls.pincode.setValue(el.pincodeid);

        this.addSubscriberDetailsService.getDetailsByPincode(el.pincode).subscribe(result => {
          ////console.log(result);
          let data = result.data;
          if (data) {
            this.pinCode = result.data.pincode.name;
            if (data.country && data.country.id) {
              this.form.patchValue({countryId:data.country.id});
            }
            if (data.state && data.state.id) {
              this.getStateListByCountryIdAndSetStateById(data.country.id,data.state.id);
            }
            if (data.city && data.city.id) {
              this.getCityListByStateIdAndSetState(data.state.id,data.city.id);
            }

            this.entity.pincode = new pinCodeId();
            this.entity.pincode.pincodeid =  data.pincode.id;
            this.entity.countryId = data.country.id;
            this.entity.stateId = data.state.id;
            this.entity.cityId = data.city.id;
          }
        });
      }
    }
    else{
this.ispincode = true;
    }
     
    }

  setData(data: PinAreaModel) {
    this.entity.pincode = new pinCodeId();
    this.entity = data;
  this.pinCode=this.entity.pincodeId;
  }
  // onStateChange($event){
  //   // this.cityList = event.cityList;
  //   if ($event) {
  //     this.getCityListByStateId($event.state_id);
  //   } else {
  //     this.cityList = [];
  //     this.form.get('city').patchValue([]);
  //   }
  // }
  getData(): PinAreaModel {
    return this.entity;
  }

  getPincode() {
    this.searchPincodeLink = 'pincode/search?s=';
  }

  getStateListByCountryIdAndSetStateById(country_id: any, state_id?: any) {
    this.sateservice.getStateListByCountryId(country_id).subscribe((result) => {
      this.stateList = result.content;
      if (state_id) {
        this.form.patchValue({ stateId: state_id });
      }
    });
  }

  getCityListByStateIdAndSetState(state_id:any,city_id?:any) {
    this.cityservice.getCityListByStateId(state_id).subscribe(
      result => {
        this.cityList = result.content;
        if (city_id) {
          this.form.patchValue({ cityId: city_id });
        }
    });
  }

  public getModuleHomeUrl(): string {

    this.url = 'masters/area'
    return this.url;
  }


  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): PinAreaModel {
    return new PinAreaModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.id;

  }

  get f() {
    return this.form.controls;
  }

  formSubmit() {
    let self = this;
    this.form.addControl('code', new FormControl(''));

    ////console.log(this.form.value);
    this.form.patchValue({ code: this.pinCode });
    if (this.validateForm()) {
      alertify.confirm("Area", "Are you sure you want to save this record?",
        function () {
          self.onSubmit();
        },
        function () {

        });
    }
  }
  backToViewList() {
    this.router.navigate(['masters/area']);
  }
  
  closeDialog(){
    this.dialogRef.close();
  }

}
