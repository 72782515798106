import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AuditService } from './audit.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { ViewChild,AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationReportService } from '../../reports/communication-report/communication-report.service';
import { SearchAutocompleteComponent } from '../../../components/search-autocomplete/search-autocomplete.component';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { ToastrService } from 'ngx-toastr';
import { SearchData } from '../../../core/modal/search-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { RecentRenewalService } from '../../reports/recent-renewal.service';
@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent extends OldAbstractBaseListComponent implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild("editUserName",{static:false}) editUserNameREf:TemplateRef<any>;
  @ViewChild("clientgroupWrapper",{static:false}) clientgroupWrapperREf:TemplateRef<any>;
  @ViewChild(SearchAutocompleteComponent, { static: false }) autocompleteRef: SearchAutocompleteComponent;

  @Input() custId: any;
  @Input() auditenableflag: true;

  auditData: any 
  //= { content: [] };
  auditGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  // For advance search
  customerId:number;
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredAuditDataForGrid : any = [];
  partnerList: any;
  Localpartnerid: any;
  Localpartnerflag: any;
  customerList:any;
  packetTypeList: any;
  repMesslist: any;
  search = new SearchData()
  searchArray = [];
  startDate:any;
  endDate:any;
  firstDate: any; 
  secondDate: any;
  diffInDays: number;
  currentDate: any;
  //submitted: boolean = false;
  
  auditForm = new FormGroup({
    startdate: new FormControl(''),
    enddate: new FormControl(''),
    CustId: new FormControl(null),
    partnerid: new FormControl(null),
    replyMessage: new FormControl(null),
    packetType: new FormControl(null),
    
  });

  constructor(
    private auditService: AuditService,
    private searchService: SearchService,
    private router:Router,
    private datepipe:DatePipe,
    private CommunicationReportService: CommunicationReportService,
    private commonlistservice: CommonListServicesService,
    private tosterservice: ToastrService,
    private recentRemewalService: RecentRenewalService
  ) {super(searchService);
    this.Localpartnerid = Number(localStorage.getItem('partnerId'));
    this.Localpartnerflag = localStorage.getItem('partnerFlag');
  }
  
  
  ngAfterViewInit(): void {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'username') {
          col.customControls = [this.editUserNameREf];
      }
      if(col.colKey == 'clientgroup'){
        col.customControls = [this.clientgroupWrapperREf];
      }
    })
  }
  onEdit(event){
    // let self=this;
    // this.auditService.getUserdetailByUserName(event.username).subscribe(res=>{
    //   this.customerId=res.customers.id;
    // })
    // setTimeout(function(){
    //   self.viewCustomerdetail();
    // },500);
    if(event.custid){
      this.router.navigate(['masters/subscriber/details',event.custid]);
    }
    else{
      this.tosterservice.error('User Not Found.')
    }
  }
  // viewCustomerdetail(){
   
  // }
  ngOnInit() {
    if(this.custId && this.auditenableflag){
      this.search = new SearchData()
      this.search.filterColumn = 'customerId'
      this.search.filterValue = this.custId
      // let temp = this.searchArray.find(res=>res.filterColumn == 'customerId')
      // if(!temp){
      //   this.searchArray.push(this.search);
      // }
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'customerId');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'customerId'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    }
    this.setDefaultPageSize(this.tableKeyEnum.AUDIT_LIST)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);

    this.setGridSetting();
    this.setDefaultOrder();
    // this.initPaginationSort();
     this.getPartnerList();
     this.getPacketTypeList();
   this.getReplyMessageList();
 this.setDateTime();

    //this.searchForm(obj)

  }

  onstartDateChange(event){
    this.startDate  =  this.datepipe.transform(event,'yyy-MM-ddTHH:mm:ss');
    // var time = moment.duration("06:00:00");
    let startDatetemp = moment(event)
    // this.endDate  =  this.datepipe.transform(startDatetemp.add(time),'yyy-MM-ddTHH:mm:ss');
    console.log(this.endDate)

    if(event){
      //this.auditForm.get('replyMessage').setValue(event.value)
      this.search = new SearchData()
      this.search.filterColumn = 'startdate'
      this.search.filterValue =this.startDate
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'startdate');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'startdate'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    }

  }

  onendDateChange(event){
    this.endDate  =  this.datepipe.transform(event,'yyy-MM-ddTHH:mm:ss');
    // var time = moment.duration("06:00:00");
    let endDatetemp = moment(event)
    // this.startDate  =  this.datepipe.transform(endDatetemp.subtract(time),'yyy-MM-ddTHH:mm:ss');
    console.log(this.startDate)

    if(event){
      //this.auditForm.get('replyMessage').setValue(event.value)
      this.search = new SearchData()
      this.search.filterColumn = 'enddate'
      this.search.filterValue = this.endDate
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'enddate');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'enddate'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    }
  }

  setDateTime(){
    let startDate=moment();
    var time = moment.duration("06:00:00");
    this.startDate  =  this.datepipe.transform(startDate.subtract(time),'yyy-MM-ddTHH:mm:ss');
    console.log("start date",this.endDate)
    let endtDatetest=moment()
    this.currentDate  =  this.datepipe.transform(new Date(),'yyy-MM-ddTHH:mm:ss');
    this.endDate  =  this.currentDate;


  }
 

  getPartnerList() {
    let partnerId = localStorage.getItem('partnerId')
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.recentRemewalService.getPartnerlistService().subscribe(result => {
        this.partnerList = result.partnerlist;
        // this.auditForm.get('partnerid').clearValidators();
        // this.auditForm.get('partnerid').updateValueAndValidity();
      })
    }
    else{
      this.recentRemewalService.getManagablePartnerlistService().subscribe(result => {
        this.partnerList = result.dataList;
        //  if (partnerId) {
        //this.auditForm.get('partnerid').setValue(this.Localpartnerid);
        //this.auditForm.get('partnerid').disable();
        //  }
      })
    }
    setTimeout(() => {
      if(this.partnerList){
        let  temp = this.setAutoSelectDropDownList(this.partnerList) 
        if(temp)  
        this.auditForm.get('partnerid').setValue(temp.id)   
      }
    }, 250);
    // this.CommunicationReportService.PartnerlistService().subscribe(result => {
    //   this.partnerList = result.partnerlist;
    //   if (this.Localpartnerid !== null && this.Localpartnerflag === "true") {
    //     this.auditForm.get('partnerid').setValue(this.Localpartnerid);
    //     this.auditForm.get('partnerid').disable();
        
    //   }
    // })
  }

  getPacketTypeList(){
    this.commonlistservice.getGenericCommonList('PacketType').subscribe(res=>{
      this.packetTypeList = res.dataList;
    })
  }

  getReplyMessageList(){
    this.commonlistservice.getGenericCommonList('authReplyMesssage').subscribe(res=>{
      this.repMesslist = res.dataList;
    })
  }

  getCustomerByPartner(event: any) {
    if (event && event.id) {
      this.autocompleteRef.autocomplete.clearModel();
      this.autocompleteRef.dataList = [];
      this.auditForm.get('CustId').reset();
      let partnerId = event.id;
      this.customerList = "customers/byPartner?pid=" + partnerId + "&s="
      this.search = new SearchData()
      this.search.filterColumn = 'partnerId'
      this.search.filterValue =event.id
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'partnerId');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'partnerId'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    } else {
      this.autocompleteRef.autocomplete.clearModel();
      this.autocompleteRef.dataList = [];
      this.auditForm.get('CustId').reset();
      this.getCustomerList();
      // let temp =  this.searchArray.findIndex (res=>res.filterColumn == 'partnerId')
      // if(temp)
      //   this.searchArray.splice(temp,1)
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'partnerId'){
            this.searchArray.splice(i,1);
          }
        })
      }
    }
  }

  getCustomerList() {
    this.customerList = "subscriber/searchNew?s=";
  }

  setCustomer(data: any) {
    let customerId: any;
    if (data.event) {
      if (data.event.id) {
        customerId = data.event.id;
      } else if (data.event.custid) {
        customerId = data.event.custid;
      }
      this.auditForm.get('CustId').setValue(customerId);
      this.search = new SearchData()
      this.search.filterColumn = 'customerId'
      this.search.filterValue =customerId
      // let temp = this.searchArray.find(res=>res.filterColumn == 'customerId')
      // if(!temp){
      //   this.searchArray.push(this.search);
      // }
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'customerId');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'customerId'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    } else {
      this.auditForm.get('CustId').reset();
      this.autocompleteRef.autocomplete.clearAllText;
      this.autocompleteRef.dataList=[];
      // let temp =  this.searchArray.findIndex(res=>res.filterColumn == 'customerId')
      // if(temp)
      //   this.searchArray.splice(temp,1)
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'customerId'){
            this.searchArray.splice(i,1);
          }
        })
      }
    }
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'username',
        default: 'Username',
        rowKey: 'username',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'partnerName',
        default: 'partner',
        rowKey: 'partnerName',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'replymessage',
        default: 'Replay Message',
        rowKey: 'replymessage',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'clientgroup',
        default: 'Client Group',
        rowKey: 'clientgroup',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'packettype',
        default: 'request Type',
        rowKey: 'packettype',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'clientip',
        default: 'Client Ip',
        rowKey: 'clientip',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'createDateString',
        default: 'Create Date',
        rowKey: 'createDateString',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'updateDateString',
        default: 'Last Modified',
        rowKey: 'updateDateString',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Username',
        value_field: 'username',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Replay Message',
        value_field: 'replymessage',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Client Group',
        value_field: 'clientgroup',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Packet Type',
        value_field: 'packettype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Client Ip',
        value_field: 'clientip',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Created Date',
        value_field: 'createdate',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.DATE,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.DATE,
      },
    ];

    this.auditGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.AUDIT_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
    };
    if (this.auditGridSettings.serverSideSortPagination && this.auditGridSettings.perPage) {
      this.setPaginationPerPage(this.auditGridSettings.perPage);
    }
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.auditData.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.auditData.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getAuditData(gridSetting : any) {

    


    let fetchSettingData = this.auditGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.auditService.getAuditDataWithPageing(params).subscribe((result) => {
      if (result.dataList.length == 0) {
        this.auditData = [];
        this.tosterservice.error("No Record Found");
      }else{
        this.auditData = result.dataList;
      }
      this.filteredAuditDataForGrid = JSON.parse(JSON.stringify(this.auditData));
      this.refreshDataSource(this.auditData);
      this.totalRecords = result.totalRecords ? result.totalRecords : 0;
      this.auditGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  onPacketTypeChange(event){
    //this.auditForm.get('packetType').reset()
    if(event && event.value ){
      //this.auditForm.get('packetType').setValue(event.value)
      this.search = new SearchData()
      this.search.filterColumn = 'packetType'
      this.search.filterValue = event.value
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'packetType');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'packetType'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    }
    else{
      // let temp =  this.searchArray.findIndex(res=>res.filterColumn == 'packetType')
      // if(temp)
      //   this.searchArray.splice(temp,1)
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'packetType'){
            this.searchArray.splice(i,1);
          }
        })
      }
    }
  }

  onReplyMessChange(event){
    if(event && event.value ){
      //this.auditForm.get('replyMessage').setValue(event.value)
      this.search = new SearchData()
      this.search.filterColumn = 'replyMessage'
      this.search.filterValue = event.value
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'replyMessage');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'replyMessage'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    }
    else{
      // let temp =  this.searchArray.findIndex(res=>res.filterColumn == 'replyMessage')
      // if(temp)
      //   this.searchArray.splice(temp,1)
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'replyMessage'){
            this.searchArray.splice(i,1);
          }
        })
      } 
    }
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        let search: any = this.searchData.forEach(res=>res.filterColumn == 'any')
        if(search){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getAuditData(config);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  resetform() {
    //this.submitted = false;
    this.auditForm.reset();
    this.searchArray = [];
   
    // if (this.Localpartnerflag === 'true') {
    //   this.auditForm.get('partnerid').setValue(this.Localpartnerid);
    //   this.auditForm.get('partnerid').disable();
    // } else {
    //   this.auditForm.get('partnerid').setValue(null);
    // }
    if(!this.auditenableflag){
      this.autocompleteRef.autocomplete.clearModel();
      this.autocompleteRef.dataList = [];
    }
  
    this.refreshDataSource([]);
    this.setDateTime();
    // setTimeout(()=>{
    //   this.getListWithParams();
    // },600);
  }

  searchForm(){
    //this.submitted = true;
    this.searchService.setServerFilterObj(null);
    if(this.custId && this.auditenableflag){
      this.search = new SearchData()
      this.search.filterColumn = 'customerId'
      this.search.filterValue = this.custId
      // let temp = this.searchArray.find(res=>res.filterColumn == 'customerId')
      // if(!temp){
      //   this.searchArray.push(this.search);
      // }
      let temp: any;
      this.searchArray = this.searchArray.filter((ls) => ls.filterColumn != 'customerId');
      if(this.searchArray && this.searchArray.length > 0){
        this.searchArray.forEach((ls,i) => {
          if(ls.filterColumn == 'customerId'){
            ls = this.search;
            temp = true;
          }
        })
      }
      if(!temp){
        this.searchArray.push(this.search);
      }
    }
    //this.searchService.resetGlobalSearchServerFilterObj();
    // let objArray = []
    // if(this.auditForm.get('CustId').value != null){
    //   let obj = {
    //     filterColumn : 'customerId',
    //     filterValue : this.auditForm.get('CustId').value
    //   }
    //   objArray.push(obj)
    // }
    // if(this.auditForm.get('partnerid').value != null){
    //   let obj = {
    //     filterColumn : 'partnerId',
    //     filterValue : this.auditForm.get('partnerid').value
    //   }
    //   objArray.push(obj)
    // }
    // if(this.auditForm.get('replyMessage').value != null){
    //   let obj = {
    //     filterColumn : 'replyMessage',
    //     filterValue : this.auditForm.get('replyMessage').value
    //   }
    //   objArray.push(obj)
    // }
    // if(this.auditForm.get('packetType').value != null){
    //   let obj = {
    //     filterColumn : 'packetType',
    //     filterValue : this.auditForm.get('packetType').value
    //   }
    //   objArray.push(obj)
    // }
    this.paginationData.pageNumber = 1;
    this.onFilterChange(this.searchArray)
  }
}
