import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ChangeIpExpiryService {
  constructor(private http: HttpClient) {

  }

  getSubscriberAllocateIp(custId:number): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getSubscriberAllocatedIp/'+custId;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  saveChangeIpExpiryCustId(custId:number,data:any): Observable<any> {
    const post_url = BASE_API_URL + 'subscriber/changeIPExpiry/'+custId;
    return this.http.post<any>(post_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
