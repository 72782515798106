import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {TableKeyEnum} from '../../../../core/enum/table-key.enum';
import {FranchiesService} from '../../franchies/franchies.service';
import {PartnerPaymentDetails} from '../model/partner-payment-details';
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import { saveAs } from 'file-saver/FileSaver';

@Component({
    selector: 'app-partner-payment-dtl',
    templateUrl: './partner-payment-dtl.component.html',
    styleUrls: ['./partner-payment-dtl.component.scss']
})
export class PartnerPaymentDtlComponent implements OnInit {
    //@ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
    @ViewChild('remark_tooltips', {static: false}) remararkWrapperRef: TemplateRef<any>;
    @ViewChild('amount', {static: false}) amountWrapperRef: TemplateRef<any>;

    @Input()
    franchise_id: any;
    dataList: PartnerPaymentDetails;
    tableKeyEnum = TableKeyEnum;
    allColumns: any[] = [];
    dataSource = new MatTableDataSource([]);
    partnerPaymentDetailsNeoGridSettings = {};


    public reqObj = {
        END_DATE: '',
        START_DATE: '',
        end_DATE: '',
        start_DATE: '',
        partner_id: 0

    };
    partnerPaymentDetails = new FormGroup({
        END_DATE: new FormControl(null),
        START_DATE: new FormControl(null)
    });

    constructor(private franchise: FranchiesService,
                private datePipe: DatePipe,
                
                ) {
    }

    ngOnInit() {
        ////console.log('child =>', this.reqObj);
        this.setGridSetting();
        this.setdata();
        this.onSearch();
    }

    ngAfterViewInit() {
        this.allColumns.forEach((col) => {
            if (col.colKey == 'remarks') {
                col.customControls = [this.remararkWrapperRef];
            }
            if (col.colKey === 'amount') {
                col.customControls = [this.amountWrapperRef];
            }
        });
    }

    setdata(reqObj?) {
        reqObj = this.reqObj;
        if (reqObj.partner_id !== 0) {
            this.franchise.getPartnerPaymentDtl(reqObj).subscribe((res: any) => {
                this.dataList = res.partnerPaymentDtl;
                this.dataList.createdByName = res.partnerPaymentDtl.forEach((res) => {
                   res.createdByName = res.createdByName?res.createdByName:'-';
                });
                ////console.log('DataList', this.dataList);
                this.refreshDataSource(this.dataList);
            });
        }
        this.partnerPaymentDetails.get('START_DATE').reset();
        this.partnerPaymentDetails.get('END_DATE').reset();
    }

    refreshDataSource(data) {
        if (data) {
            this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
        }
    }


    setGridSetting() {
        let self = this;
        this.allColumns = [
            // {
            //   colKey: 'id',
            //   default: 'Id',
            //   rowKey: 'id',
            //   width: '10%',
            //   isFilterAllow: false,
            //   isNumber: false,
            // },
            {
                colKey: 'paymentdate',
                default: 'Payment Date',
                rowKey: 'paymentdate',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'paymentmode',
                default: 'Payment Mode',
                rowKey: 'paymentmode',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },

            {
                colKey: 'chequenumber',
                default: 'Cheque No',
                rowKey: 'chequenumber',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'chequedate',
                default: 'Cheque Date',
                rowKey: 'chequedate',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'amount',
                default: 'Amount',
                rowKey: 'amount',
                width: '10%',
                customControls: [this.amountWrapperRef],
                customValue: (amount) => {
                    return amount;
                },
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'transcategory',
                default: 'Transcation Category',
                rowKey: 'transcategory',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },

            // {
            //   colKey: 'refno',
            //   default: 'Commission Type',
            //   rowKey: 'refno',
            //   width: '10%',
            //   isNumber: false,
            //   isFilterAllow: false,
            //   enableEdit: true,
            //   enableDelete: true,
            //   enableSort: true,
            // },
            // {
            //   colKey: 'partnerId',
            //   default: 'Partner Id',
            //   rowKey: 'partnerId',
            //   width: '10%',
            //   isNumber: false,
            //   isFilterAllow: false,
            //   enableEdit: true,
            //   enableDelete: true,
            //   enableSort: true,
            // },


            // {
            //   colKey: 'bank_name',
            //   default: 'Bank Name',
            //   rowKey: 'bank_name',
            //   width: '10%',
            //   isNumber: false,
            //   isFilterAllow: false,
            //   enableEdit: true,
            //   enableDelete: true,
            //   enableSort: true,
            // },
            {
                colKey: 'createdByName',
                default: 'Created By',
                rowKey: 'createdByName',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
            },
            {
                colKey: 'remarks',
                default: 'Remarks',
                rowKey: 'remarks',
                width: '10%',
                isNumber: false,
                isFilterAllow: false,
                enableEdit: true,
                enableDelete: true,
                enableSort: true,
                customControls: [this.remararkWrapperRef],
            },
        ];

        this.partnerPaymentDetailsNeoGridSettings = {
            showAdvanceSearch: false,
            showFooter: false,
            showPagination: true,
            tableId: this.tableKeyEnum.PARTNER_PAYMENT_DETAILS,
            perPage: 5,
            tableHeight:300,
            headerSticky:true,
            verticalScroll:true,
            pageSizeOptions: [5, 10, 15, 20, 50, 100],
            sort: true,
            defaultSortCol: '',
            defaultSortDirection: 'asc',
            columns: this.allColumns
        };
    }

    onSearch() {
        this.reqObj.partner_id = this.franchise_id;
        this.reqObj.START_DATE = this.datePipe.transform(this.reqObj.START_DATE, 'yyyy-MM-dd');
        this.reqObj.END_DATE = this.datePipe.transform(this.reqObj.END_DATE, 'yyyy-MM-dd');
        this.setdata(this.reqObj);
    }

    onExportToExcel() {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();    
           this.franchise.excelServiceDataPartnerPayment(this.franchise_id).subscribe(blob => {
            saveAs(blob, "[partner payment " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
         })
       }
}


