import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class TroubleTicketDetailsService {

  constructor(private http: HttpClient) { }

  getTroubleTicketsDetailsList(custId: any): Observable<any> {
    const post_url = baseApiUrl + 'case/getLeasedlineCasesByCustomer';
    return this.http.post<any>(post_url, custId);
  }

  updateData(data) {
    const updateListFromdata = new FormData();
    updateListFromdata.append("caseUpdate", "" + JSON.stringify(data));
    const post_url = baseApiUrl + 'case/updateDetails';
    return this.http.post<any>(post_url, updateListFromdata);
  }
}
