import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  constructor(private http: HttpClient) {

  }

  transformPackagesData(data) {
    let formatedData = [];
    data.postpaidplanList.forEach(function(item:any){
      let itemData:any = {};
      itemData.isplancheck = false
      itemData.partnerPrice = null
      itemData.packages_id = item.id;
      itemData.name = item.name;
      itemData.displayName = item.displayName;
      itemData.code = item.code;
      itemData.desc = item.desc;
      itemData.category = item.category;
      itemData.startDate = item.startDate;
      itemData.endDate = item.endDate;
      itemData.quota = item.quota;
      itemData.quotaUnit = item.quotaUnit;
      itemData.taxId = item.taxId;
      itemData.serviceId = item.serviceId;
      itemData.plantype = item.plantype;
      itemData.status = item.status;
      itemData.uploadQOS = item.uploadQOS;
      itemData.downloadQOS = item.downloadQOS;
      itemData.uploadTs = item.uploadTs;
      itemData.downloadTs = item.downloadTs;
      itemData.childQuota = item.childQuota;
      itemData.childQuotaUnit = item.childQuotaUnit;
      itemData.slice = item.slice;
      itemData.dbr = item.dbr;
      itemData.dataCategory = item.dataCategory;
      itemData.planGroup = item.planGroup;
      itemData.validity = item.validity;
      itemData.saccode = item.saccode;
      itemData.quotaunittime = item.quotaunittime;
      itemData.quotatime = item.quotatime;
      itemData.quotatype = item.quotatype;
      itemData.offerprice = item.offerprice;
      itemData.qospolicyid = item.qospolicyid;
      itemData.radiusprofileIds = item.radiusprofileIds;
      itemData.planStatus = item.planStatus;
      itemData.childQuota = item.childQuota;
      itemData.childQuotaUnit = item.childQuotaUnit;
      itemData.taxamount = item.taxamount;
      itemData.maxconcurrentsession = item.maxconcurrentsession;
      itemData.updatedate = item.updatedate;
      itemData.isIncludeTax = item.isIncludeTax;
      itemData.tokenPrice = item.tokenPrice;
      itemData.minPrice = item.minPrice;
      itemData.maxPrice = item.maxPrice;
      itemData.validityType = item.validityType;
      itemData.cycleType = item.cycleType;
      itemData.billingCycle = item.billingCycle;
      formatedData.push(itemData);
    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }
getVASByType(name){
  const get_url = BASE_API_URL + 'VAS/getVASByType/'+ name;
  return this.http.get<any>(get_url,{ headers: httpOptions.headers});
}
  getPackagesDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'postpaidplan/list';

    return this.http.post<any>(post_url,params,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformPackagesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getPackagesData(packages_id): Observable<any> {
    const get_url = BASE_API_URL + 'postpaidplan/' + packages_id;

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getQosPolicyByType():Observable<any>{
    return this.http.get<any>(BASE_API_URL+'qosPolicy/getQosPolicyListByType/Broadband');
  }
  convertToTopUp(){
    const get_url = BASE_API_URL + 'convertVolToTopup' ;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers });
  }
  
  getPackagesList(): Observable<any> {
    const get_url = BASE_API_URL + 'postpaidplan/all';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformPackagesData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  insertPackages(packages_data: any): Observable<any> {
    ////console.log("packages_data"+packages_data);
    const post_url = BASE_API_URL + 'postpaidplan';
    return this.http.post<any>(post_url, packages_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updatePackages(packages_data: any,packages_id:any): Observable<any> {
    const update_url = BASE_API_URL +"postpaidplan/"+ packages_id;
    return this.http.put<any>(update_url, packages_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deletePackages(packages_id: any): Observable<any> {
    const delete_url = BASE_API_URL+"postpaidplan/" + packages_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
