import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { StaffService } from './staff.service';
import _ from 'underscore';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('roleIdsWrapper', { static: false }) roleWrapper: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('staffName', { static: false }) staffReff: TemplateRef<any>;
  @ViewChild('content',{ static: false }) contentReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  visible:boolean=true;
  changetype:boolean=true;
  visible2:boolean=true;
  changetype2:boolean=true;
  public loginService: LoginService;
  staffDataForGrid: any = { content: [] };
  staffGridSettings: any = {};
  show_update: any = false;
  selectForUpdate: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  form: FormGroup;
  filteredStaffDataForGrid: any = [];
  dataSource = new MatTableDataSource([]);
  // For advance search
  searchSettings = [];
  staffId:any;
  subscriptions: Subscription[] = [];
  filteredServiceDataForGrid: any = [];
  roleList: any = [
    { id: '', rolename: '' }];

  teamList: any = [
    { id: '', rolename: '' }]
  multipleOptionsList = [];

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private staffService: StaffService,
    private searchService: SearchService,
    loginService: LoginService,
    private tosterservice: ToastrService,
    private modalService: NgbModal
  ) {
    super(searchService);
    this.loginService = loginService;
    // this.staffService.getAllRoleData().subscribe(result => {
    //   this.roleList = result.rolelist;
    // }); //code remove test
     this.staffService.getAllRoleDataForAdmin().subscribe(result => {
       this.roleList = result.rolelist;
     });
  }

  ngOnInit() {
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_STAFF,AclConstants.OPERATION_STAFF_UPDATE_PASSWORD,AclConstants.OPERATION_STAFF_USER_ALL)){
      let resetPasswordObj = {key:'resetPassword',label:'Reset Password'};
      this.multipleOptionsList.push(resetPasswordObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_STAFF,AclConstants.OPERATION_STAFF_GET_TRACK,AclConstants.OPERATION_STAFF_USER_ALL)){
      let getGeoTrackingObj = {key:'getGeoTracking',label:'Get Geo Tracking'};
      this.multipleOptionsList.push(getGeoTrackingObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_STAFF,AclConstants.OPERATION_STAFF_AVAILABLE,AclConstants.OPERATION_STAFF_USER_ALL)){
      let staffAbsenceObj = {key:'staffAbsence',label:'Staff Absence'};
      this.multipleOptionsList.push(staffAbsenceObj);
    }
    this.setDefaultPageSize(this.tableKeyEnum.STAFF_LIST)
    // this.staffService.getAllRoleData().subscribe(result => {
    //   this.roleList = result.dataList;
    // });
    
    this.staffService.getAllRoleDataForAdmin().subscribe(result => {
      this.roleList = result.dataList;
    });

    this.staffService.getTeamsData().subscribe(result => {
      ////console.log("teams", result);
    });
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);

    this.form = new FormGroup({
      newpassword: new FormControl('', [Validators.required]),
      confirmpassword: new FormControl('', [Validators.required])
    });
  }

  applyFilter(filterObj) {

    let filteredDataForGrid = JSON.parse(JSON.stringify(this.staffDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.staffDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  refreshDataSource(data) {
    if (data) {
      // ////console.log("refresh", data);
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey === 'roleIds') {
        col.customControls = [this.roleWrapper];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
        if(this.multipleOptionsList.length == 0){
          col.isMultipleOption = false
        }
      }
      if (col.colKey == 'firstname') {
        col.customControls = [this.staffReff];
      }
    });
  }
  viewpass(){
    this.visible=!this.visible
    this.changetype=!this.changetype
   
  }
  viewpass2(){
    this.visible2=!this.visible2
    this.changetype2=!this.changetype2
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'firstname',
        default: 'Name',
        rowKey: 'firstname',
        sortKey: 'name',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'username',
        default: 'UserName',
        rowKey: 'username',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'partnerName',
        default: 'Partner Name',
        rowKey: 'partnerName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'email',
        default: 'Email',
        rowKey: 'email',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'roleIds',
        default: 'Role',
        rowKey: 'roleIds',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'roleIds',
      //   default: 'Role',
      //   rowKey: 'roleIds',
      //   width: '10%',
      //   customControls: [this.roleWrapper],
      //   customValue: (roleIds) => {
      //     if (roleIds && roleIds.length) {
      //       let roleNames = "";
      //       roleIds.forEach((element, index) => {
      //         let findById = _.find(this.roleList, { id: element });
      //         if (roleIds.length - 1 === index) {
      //           roleNames += findById.rolename;
      //         } else {
      //           roleNames += findById.rolename + ", ";
      //         }
      //       });
      //       return roleNames;
      //     } else {
      //       return "-";
      //     }
      //   },
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'roleIds',
      //   default: 'Team',
      //   rowKey: 'roleIds',
      //   width: '5%',
      //   customControls: [this.roleWrapper],
      //   customValue: (roleIds) => {
      //     if (roleIds && roleIds.length) {
      //       let roleNames = "";
      //       roleIds.forEach((element, index) => {
      //         let findById = _.find(this.roleList, { id: element });
      //         if (roleIds.length - 1 === index) {
      //           roleNames += findById.rolename;
      //         } else {
      //           roleNames += findById.rolename + ", ";
      //         }
      //       });
      //       return roleNames;
      //     } else {
      //       return "-";
      //     }
      //   },
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'regDate',
        default: 'Registration Date',
        rowKey: 'regDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'ACTIVE') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
          // enableEdit: false,
          // enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: true,
        width: '8%',
        isStickyEnd: true,
        isMultipleOption: true
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'firstname',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Username',
        value_field: 'username',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Email',
        value_field: 'email',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Registration Date',
        value_field: 'createdate',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.DATE,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.DATE,
      }
    ];

    this.staffGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.STAFF_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this User ?',
      deleteMsgBoxDetail: '"Staff Name : ${firstname} " ',
    };

    if (this.staffGridSettings.serverSideSortPagination && this.staffGridSettings.perPage) {
      this.setPaginationPerPage(this.staffGridSettings.perPage);
    }
  }

  getStaffData(gridSetting: any) {
    let fetchSettingData = this.staffGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.staffService.getStaffDataWithPageing(params).subscribe(result => {
      this.staffDataForGrid = result;
      this.filteredStaffDataForGrid = JSON.parse(JSON.stringify(this.staffDataForGrid.content));
      this.refreshDataSource(this.staffDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.staffGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getStaffData(config);
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    // ////console.log("event", event.RowData);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    let self = this;
    if (event) {
      this.commonDeleteMsgBox(this.staffGridSettings, event, this.delete.bind(this));
    }
  }
  // openModal(content: any,event) {
  //   //////console.log("staff data",event);
  //   this.staffId = event.staff_id;
  //   this.modalService.open(content);
  // }

  delete(objEvent) {
    let self = this;
    let selected_staff_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.staffService.deleteStaff(selected_staff_data.staff_id).subscribe((result) => {
        if (result.status !== 200) {
          this.tosterservice.error(result.ERROR);
        }
        else {
          this.tosterservice.success('User is deleted successfully');
          self.staffDataForGrid.content.forEach(function (item, index) {
            if (item.discount_id === selected_staff_data.staff_id) {
              self.staffDataForGrid.splice(index, 1);
            }
          });
          this.getListWithParams();
          // self.refreshDataSource(self.staffDataForGrid.content);
        }

      },
        err => {
          this.tosterservice.error(err.error.ERROR);
        });
    }
  }

  onAddUserClick() {
    this.router.navigate(['hr/add-user']);
  }

  setUpdateValueInForm(rowData: any) {
    this.router.navigate(['hr/add-user', rowData.staff_id]);
  }

  roleNames(data) {
    let roleNames = "";
    if (data.roleName && data.roleName !== null && data.roleName.length > 0) {
      for (let value of data.roleName) {
        if (data.roleName.length === 1) {
          roleNames += value;
        } else {
          roleNames += value + ", ";
        }
      }
    }
    return roleNames;
  }
  // teamName(data) {

  // }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getStaffData(config);
  }

  formsubmit(){
    
    if (!this.form.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });

    } else {
      this.savepassword();
    }

  }

  savepassword(){

    if (this.form.valid === true) {

      let formData:any={};
      formData.staffId=this.staffId;
      formData.newpassword = this.form.get('newpassword').value;
      if(this.form.get('newpassword').value == this.form.get('confirmpassword').value){
        this.staffService.resetpassword(formData).subscribe((res) => {
          if (res.responseCode === 200) {
            this.toastrService.success("Reset Password successfully");
            this.form.reset();
            this.modalService.dismissAll();
          }
        });
      } else {
        this.toastrService.error("Password doesn't match");
      }
    }
    
  }
  // onGetTrack(id){
  //   this.router.navigate(['hr/staffgeolocation',id]);
  // }
  closeForm(){
    this.form.reset();
    this.modalService.dismissAll();
  }
  // onStaffAvailable(event){
  //   this.router.navigate(['hr/staffAvailable',event]);
  // }

  onMultipleOptionClick(event){
    console.log('event =>',event);
    this.staffId = event.RowData.staff_id;
    if(event.optionData.key == 'resetPassword'){
      this.modalService.open(this.contentReff);
    }
    else if(event.optionData.key == 'getGeoTracking'){
      this.router.navigate(['hr/staffgeolocation',event.RowData.staff_id]);
    }
    else if(event.optionData.key == 'staffAbsence'){
      this.router.navigate(['hr/staffAvailable',event.RowData.staff_id]);
    }
  }
}
