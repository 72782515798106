import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base-service';
import { Property } from '../../model/Property';
import { HttpClient } from '@angular/common/http';
import { Circuit } from '../../model/Circuit';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { SearchData } from '../../../../core/modal/search-data';
import { RequestData } from '../../../../core/modal/base-save-update-modal';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class CircuitServiceService extends BaseService<Circuit> {

  propertyId:any;
  constructor(
    public http: HttpClient
  ) {
    super(http);
  }
  validate(): boolean {
    return false;
  }
  setId(id){
    this.propertyId=id;
  }
  getModuleName(): string {
    return "leasedlinesubscriber";
  }
  getCircuitDetail(circuitId): Observable<any> {
    return this.http.get<any>(baseApiUrl + 'leasedlinesubscriber/getCircuitById/' + circuitId);
  }
  getConnectvityType():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/generic/circuitCntType');
  }
  getciciutDeviceType():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/generic/circuitDeviceType');
  }
  getDeviceType():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/generic/circuitDeviceType');
  }
  
  getConnectionType():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/generic/connectionType');
  }

  getAuthType():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/generic/authType');
  }
  /**New API :circuitType */
  // getCircuitd():Observable<any>{
  //   return this.http.get<any>(baseApiUrl+'leasedlinesubscriber/getCircuitId');
  // }

  generateCircuitid(circuitType):Observable<any>{
    return this.http.get<any>(baseApiUrl+'leasedlinesubscriber/getCircuitId/'+circuitType);
  }

  getQosPolicyByType():Observable<any>{
    return this.http.get<any>(baseApiUrl+'qosPolicy/getQosPolicyListByType/Leaseline');
  }

  getCircuitType():Observable<any>{
   return this.http.get<any>(baseApiUrl+'commonList/generic/circuitType');
  }


  // generateCircuitid(circuitType:any):Observable<any>{
  //   return this.http.get<any>(baseApiUrl+'leasedlinesubscriber/getCircuitId/'+circuitType);
  //  }
  // save(modal: Circuit): Observable<ResponseData<Circuit>> {
      
  // }
  save(modal: Circuit): Observable<ResponseData<Circuit>> {
    let reqObj: RequestData<Circuit> = new RequestData<Circuit>();
    // reqObj.authToken = this.authService.getAuthToken();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    ////console.log('save called !');
    // return null;
    return this.http.post<ResponseData<Circuit>>(baseApiUrl + this.getModuleName() + '/saveCircuit', reqObj.data);
  }
  delete(modal: any): Observable<ResponseData<any>> {
    let reqObj: RequestData<any> = new RequestData<any>();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;
    ////console.log('save called !');
    // return null;
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/deleteCircuit', reqObj.data);
  }
  getAll(
    paginationData: PaginationData,
    orderingData: OrderingData,
    searchData?: SearchData[],
    status?: any,
    filterBy?: any
  ): Observable<ResponseData<Circuit>> {
    let searchDataObj = searchData && searchData.length > 0 ? JSON.stringify(searchData) : null;
    let params: any = {}; //new HttpParams();
    params=Object.assign({id:this.propertyId});
    if (paginationData) {
      params.page = paginationData.pageNumber.toString();
      params.pageSize = paginationData.rowsPerPage.toString();
      //params.set('page', paginationData.pageNumber.toString());
      //params.set('pageSize', paginationData.rowsPerPage.toString());
    }
    if (orderingData) {
      params.sortBy = orderingData.column;
      params.sortOrder = (orderingData.descending ? 1 : 0) + '';
      // params.set('sortBy', orderingData.column);
      // params.set('sortOrder', (orderingData.descending ? 1 : 0) + '');
    }
    // ////console.log('searchData', searchData);
    if (searchDataObj) {
      params.filters = JSON.parse(searchDataObj);
      // params.append('search', searchDataObj);
    }
    if (status) {
      ////console.log("status::", status);
      params.status = status;
    }
    if (filterBy) {
      ////console.log("filter::", filterBy);
      params.filterBy = filterBy;
    }
    // api/v1/anyModuleUrl/getAllByPagination?page=1&pageSize=1&sortBy="id"&sortOrder="1"

    // return this.http.get<ResponseData<T>>(baseApiUrl + this.getModuleName(), { headers: httpOptions.headers });

    return this.http.post<ResponseData<Circuit>>(baseApiUrl + this.getModuleName()+'/getAllCircuitByProperty', params, {
      headers: httpOptions.headers
    });
    return null;
  }
  saveProperty(data){
    return this.http.post<ResponseData<Circuit>>(baseApiUrl + this.getModuleName() + '/saveCircuit',data);
  }
  updateProperty(data){
    return this.http.post<ResponseData<Circuit>>(baseApiUrl + this.getModuleName() + '/updateCircuit',data);
  }

  updateCircuitId(data):Observable<any> {
    return this.http.post<ResponseData<Circuit>>(baseApiUrl + 'leasedlinesubscriber/UpdateLeasedLineCircuitId', data);
  }
}
