import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { DunningModel } from '../model/dunning-model';

import { DunningServiceService } from '../service/dunning-service.service';

@Component({
  selector: 'app-dunning-list',
  templateUrl: './dunning-list.component.html',
  styleUrls: ['./dunning-list.component.scss']
})
export class DunningListComponent  extends AbstractBaseListComponent<DunningModel> implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('serviceare', { static: false }) serviceAreaReff: TemplateRef<any>;
  @ViewChild('name', { static: false }) nameReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  dataGrid: DunningModel[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  public entity: DunningModel = new DunningModel();
  public entity_id: any = null;
  form: FormGroup;

  constructor(private entityService : DunningServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginService: LoginService) {
      super(toastrService,searchService);
      this.loginService = loginService;
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.DUNNING_RULE)
    this.init();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.nameReff];
      }
    });
  }

  getService(): IBaseService<DunningModel> {
    return this.entityService;
  }


  onAddDunningClick() {
    this.router.navigate(['/plan-management/dunning/add']);
}
  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue:(status) => {
          if(status === 'Active'){
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'Last Modified By',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
     
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns = allColumns;
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return allColumns ? allColumns : [];
  }

  getTableSetting() {
    let dunningRuleNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      
      tableId: this.tableKeyEnum.DUNNING_RULE,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Dunning Rule ?',
      deleteMsgBoxDetail: 'Dunning Rule Name :${name} ',
    };
    return dunningRuleNeoGridSettings;
  }

  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  // onEditClick(event) {
  //   ////console.log(event.RowData)
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  setList(list: Array<DunningModel>) {
    this.dataGrid = list;
    //console.log("dataGride",this.dataGrid);
    
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  onAddClick() {
    this.router.navigate(['plan-management/dunning/add']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['plan-management/dunning/add',rowData.id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
