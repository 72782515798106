import { ConditionalExpr } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { auditLogComponentList } from '../../auditLogComponentConstant';
import { DataSharingServiceService } from '../../data-sharing-service.service';


@Component({
  selector: 'app-audit-log-generic',
  templateUrl: './audit-log-generic.component.html',
  styleUrls: ['./audit-log-generic.component.scss']
})
export class AuditLogGenericComponent implements OnInit {
  @Input() dataList: any;
  @Input() formdataList:any; 
  moduleName:any;
  logdata:any;
  constructor( 
                private sharingdataService:DataSharingServiceService, 
                public dialog: MatDialog,
                private toaster:ToastrService,
                private router: Router,
               ) {
                 this.sharingdataService.getInfo().subscribe(info => {
                 this.logdata =  JSON.stringify(info);
                 })
              }

  ngOnInit() {
          
           }

 dymamicLoadComponent(module,entityRefId):void{
 
 this.moduleName= auditLogComponentList.find(m=>m.MODULE_NAME===module);
  
  if(this.moduleName!== undefined && this.moduleName.COMPONENT_NAME !==undefined &&
     this.moduleName!==null &&  this.moduleName.COMPONENT_NAME !==null
    ){
    
  const dynamicComponent = this.dialog.open(this.moduleName.COMPONENT_NAME, {
        height: '1000px',
        width: '1300px', 
        backdropClass: 'custom-dialog-backdrop-class',
        disableClose: true,
        panelClass: 'custom-dialog-panel-class',
        data:{
          id:entityRefId,
          moduleName:module
        }
      });
  
   dynamicComponent.afterClosed().subscribe(result => {
      });
     }
     else{
       this.toaster.error("Screen Not Available");
     }
  }
  
  setObject(event){
   
   this.moduleName= auditLogComponentList.find(m=>m.MODULE_NAME=== event.module);
   let formData=this.formdataList;
   let formatedAuditData: any = {};
   //let formatedAuditData2:any={};

   formatedAuditData = Object.assign(formData);
   //formatedAuditData2 = Object.assign(event); //** Commented Code**:K/

   formatedAuditData.fromDate=formData.fromDate;
   formatedAuditData.toDate=formData.toDate;
   formatedAuditData.auditFor=formData.auditFor;
   formatedAuditData.module=formData.module;
   formatedAuditData.operation=formData.operation;
   //formatedAuditData.lastAuditUrl=this.moduleName.URL + '/' + event.entityRefId;
    let inputCombinedObject = {
    ...formatedAuditData,
    };
   
   this.sharingdataService.lastAuditUrl = this.moduleName.URL + '/' + event.entityRefId;
   this.sharingdataService.setInfo(inputCombinedObject)
   this.router.navigate([this.moduleName.URL,event.entityRefId]);
  }

  ngOnDestroy() {
  // unsubscribe to ensure no memory leaks
  //this.logdata.unsubscribe();
}

// redirectTo(event){
//   //this.sharingdataService.sharingData.next(event);
//   this.sharingdataService.setNewUserInfo(event)
//  // this.router.navigate(['/masters/subscriber/details',event.entityRefId]);
//  // localStorage.setItem('redirect',JSON.stringify(event))
//  }
}
