import { Component, OnInit } from '@angular/core';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../services/ibase-service';
import { Property } from '../model/Property';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { Router } from '@angular/router';
import { PropertyServiceService } from '../property-detail/service/property-service.service';
import { Input } from '@angular/core';
import { PaginationData } from '../../../core/modal/pagination-data';
import { ViewChild } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchData } from '../../../core/modal/search-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { leasedpropertyservice } from './service/leased-property-service';
import { TroubleTicketsDetailsComponent } from '../subscriber-details/subscriber-tables/trouble-tickets-details/trouble-tickets-details.component';
@Component({
  selector: 'app-leased-property-detail',
  templateUrl: './leased-property-detail.component.html',
  styleUrls: ['./leased-property-detail.component.scss']
})
export class LeasedPropertyDetailComponent extends AbstractBaseListComponent<Property> implements OnInit {
  @ViewChild("actionBtn", { static: false }) actionBtnReff: TemplateRef<any>;
  @ViewChild("totalCircuit", { static: false }) totalCircuitReff: TemplateRef<any>;
  @ViewChild('editPropertyName', { static: false }) editWrapperRef: TemplateRef<any>;

  allColumns: any[] = [];
  tablekeyEnum = TableKeyEnum;
  searchSettings: any[] = [];
  dataGrid: any[] = [];
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  leasedLinePropertytNeoGridSettings: any = {};
  lieseLineId = localStorage.getItem('leasedId');
  @Input() leasedId: any;
  activecount:number = 0;
  inactivecount:number = 0;
  circuitlist: any;
  constructor(
    private toaster: ToastrService,
    private searchSerice: SearchService,
    private propertyService: PropertyServiceService,
    private leasedPropertservice: leasedpropertyservice,
    private router: Router,
    private ngbModalService: NgbModal,
  ) {
    super(toaster, searchSerice)
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tablekeyEnum.PROPERTY_LIST)
    this.propertyService.setId(this.leasedId);
    this.init();
    this.getTableColumn();

    const filterSubscription = this.searchSerice.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }
  getService(): IBaseService<Property> {
    return this.propertyService;
  }
  setList(list: Array<Property>) {

    this.dataGrid = list;
    this.leasedLinePropertytNeoGridSettings.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  onFilterChange(filterObj) {
    if (this.searchSerice) {
      let serverFilterObj: SearchData[] = this.searchSerice.getServerFilterObj();
      if (filterObj) {
        let searchData = new SearchData();
        searchData = filterObj;
        if (!Array.isArray(searchData) && searchData.filterColumn) {
          if (searchData.filterValue) {
            serverFilterObj = this.searchSerice.setServerFilterObj([searchData]);
          } else {
            serverFilterObj = this.searchSerice.resetGlobalSearchServerFilterObj();
          }
        } else {
          serverFilterObj = this.searchSerice.setServerFilterObj(filterObj);
        }
      } else {
        serverFilterObj = this.searchSerice.resetAdvanceSearchServerFilterObj();
      }
      this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
      this.paginationData.pageNumber = this.neoTableSetting.pageNumber = 1;
      if (this.searchData[0]) {
        this.searchData[0]['filterId'] = this.leasedId;
      }
      this.getList(this.paginationData, this.orderingData, this.searchData);
      return serverFilterObj;
    } else {
      return null;
    }
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchSerice.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  getTableColumn() {
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'proname',
        default: 'Property Name',
        rowKey: 'proname',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'proaddress',
        default: 'Address',
        rowKey: 'proaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'totalCircuit',
        default: 'No of Circuits',
        rowKey: 'totalCircuit',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'techconname',
      //   default: 'Technical Contact Name',
      //   rowKey: 'techconname',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },

      // {
      //   colKey: 'techconphone',
      //   default: 'Technical Contact No',
      //   rowKey: 'techconphone',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },

    ];

    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {
    let leasedLinePropertytNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter: true,
      showPagination: true,
      tableId: this.tablekeyEnum.PROPERTY_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Property ?',
      deleteMsgBoxDetail: 'Property  Name :${proname} ',
    };
    this.leasedLinePropertytNeoGridSettings = leasedLinePropertytNeoGridSettings;
    return leasedLinePropertytNeoGridSettings;
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }
  public onDeleteClick(event: Property) {
    if (event) {
      this.commonDeleteMsgBox(this.getTableSetting(), event, this.afterDeleteConfirm.bind(this));
    }
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
      if (col.colKey == 'totalCircuit') {
        col.customControls = [this.totalCircuitReff];
      }
      if(col.colKey=='proname'){
        col.customControls=[this.editWrapperRef]

      }
    });
  }
  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
      this.getList(this.paginationData, this.orderingData, this.searchData);
    }
  }
  public onEditClick(event: Property) {
    this.setUpdateValueInForm(event);
  }
  
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/subscriberLeased-property-detail/', rowData.id]);
  }
  delete(modal: Property) {
    this.leasedPropertservice
      .delete(modal)
      .subscribe(
        (response) => {
          if (response.responseCode != 200) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.onDeleteSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }
  onDeleteSuccess() {

    this.toaster.success("Property deleted Successfully")
    this.getList(this.paginationData, this.orderingData, this.searchData);
  }
  

  onViewCircuit(data, view) {
    
    this.circuitlist = data.circuitStatusDtoList;
    this.activecount = 0;
    this.inactivecount = 0;
    if (this.circuitlist.length === 0) {
      this.toaster.error("No Circuits");
    } else {
      this.circuitlist.forEach((res) =>{
        if(res.status == 'Active'){
           this.activecount ++;
        }
        
        if(res.status == 'Inactive'){
          this.inactivecount ++;
        }
      })
      // this.associatePartnerlist = data;
      this.ngbModalService.open(view, { size: 'md' });
    }
   
    

  }
}

