import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryService } from '../country/country.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../core/constant/response-code';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogPassData {
  id: number;
}

import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../data-sharing-service.service';
@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit {
  saveBtn:boolean=false;
  isDisabled:boolean=false;
  countryDataForGrid: any = { content: [] };
  countryGridSettings: any = {};
  public sub: any = null;
  public country_id: any = null;
  selectForUpdate: any;
  show_update: any = false;
  req_message = "This field is required.";

  status = [
    { value: 'Active', name: "Active" },
    { value: 'InActive', name: "In Active" }
  ]
  insertCountryForm = new FormGroup({
    country_name: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private countryService: CountryService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private tosterservice: ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddCountryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.country_id = id;
      this.show_update = true;
      this.getCountryData();
    }
  
    // if(this.data && this.data.id ){
    //   this.isDisabled=true;
    //   this.country_id = this.data.id;
    //   this.show_update = false;
    //   this.getCountryData();
    // }


  }

  validateForm() {
    if (!this.insertCountryForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertCountryForm.controls).forEach(field => {
        const control = this.insertCountryForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.country_id === null) {
        this.insertCountryOnDb();
      } else {
        this.updateCountryOnDb();
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getCountryData() {
    let country_id = this.country_id;
    this.countryService.getCountryListById(country_id).subscribe(
      result => {
        this.setUpdateValueInForm(result);
      });
  }
  insertCountryOnDb() {
    if (this.insertCountryForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertCountryForm.value);
      let formatedInputData: any = {};
      formatedInputData.name = apiInputData.country_name;
      formatedInputData.status = apiInputData.status;
      this.countryService.insertCountry(formatedInputData).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status === ResponseCode.OK){
            let data: any = {};
            let countryData: any = result.country;
            data.country_id = countryData.id;
            data.country_name = countryData.name;
            data.status = countryData.status;
            this.cdRef.detectChanges();
            this.backToViewCountry();
            this.tosterservice.success('Country has been created successfully');
          }  
        }, err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  updateCountryOnDb() {
    if (this.insertCountryForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertCountryForm.value);
      let country_id = this.selectForUpdate.country_id;
      let formatedInputData: any = {};
      formatedInputData.name = apiInputData.country_name;
      formatedInputData.status = apiInputData.status;
      this.countryService.updateCountry(formatedInputData, country_id).subscribe(
        (result) => {
          this.saveBtn =false;
          if(result.status === ResponseCode.OK){
            this.countryDataForGrid.content.forEach((item, index) => {
              if (item.country_id === country_id) {
                this.countryDataForGrid.content[index].country_name = formatedInputData.name;
                this.countryDataForGrid.content[index].status = formatedInputData.status;
              }
            });
            this.cdRef.detectChanges();
            this.show_update = false;
            this.backToViewCountry();
            this.tosterservice.success('Country Update successfully');
          }
        }, err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        }
      );
    }
  }

  setUpdateValueInForm(countryData: any) {
    // $('#updateSitesModel').modal('show');
    this.show_update = true;
    this.selectForUpdate = countryData;
    let countryFormData: any = {
      country_name: countryData.country_name,
      status: countryData.status
    };
    this.insertCountryForm.patchValue(countryFormData);
  }

  backToViewCountry() {
    // this.router.navigate(['/masters/country']); /**Old Code */
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
      this.router.navigate(['/masters/country']);
      }

  }

  closeDialog(){
    this.dialogRef.close();
  }

}
