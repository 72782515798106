import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { BaseService } from "../../../services/base-service";
import { NewSusbcriberModel } from "./new-subscriber-model";
const BASE_API_URL = '';

@Injectable({
  providedIn : 'root'
})
  export class NewSubscriberService extends BaseService<NewSusbcriberModel> {
    
    constructor(http: HttpClient) {
      super(http);
    }
    getModuleName(): string {
      return 'customers/list';
    }
    validate(): boolean {
      return false;
    }
    getAadhaarOTP(id){
      let obj = {
        id_number :id.toString()
      }
      const post_url = BASE_API_URL + 'subscriber/generateAadhaarOTP';
        return this.http.post<any>(post_url,obj)
    }
    verifyOtpAadhaar(data){
      const post_url = BASE_API_URL + 'subscriber/verifyAadhaarOTP';
        return this.http.post<any>(post_url,data)
    }
    public getCommonListPropertyType(): Observable<any> {
      const get_url = BASE_API_URL + 'commonList/generic/PropertyType';
      return this.http.get<any>(get_url);
    }
    
      MobileVerification(number){
        let obj = {
          mobile :number.toString()
        }
        const post_url = BASE_API_URL + 'subscriber/genrateMobileOtpAtSave';
        return this.http.post<any>(post_url,obj)
      }
    
      OTPverifiedServiceAtSave(mobile, Otp) {
        let data = { mobile:mobile, mobileotp: Otp }
        const post_url = BASE_API_URL + 'subscriber/verifyMobileOtpAtSave';
        return this.http.post<any>(post_url, data);
      }
    
      addSubscriberDetailsService(subscriber_data: any): Observable<any> {
        const post_url = BASE_API_URL + 'customers';
        return this.http.post<any>(post_url, subscriber_data).pipe(
          map(data => data),
          catchError((error: any) => {
            return throwError(error);
          })
        );
      }
    
      getAreaAll(): Observable<any> {
        const get_url = BASE_API_URL + 'area/all';
        return this.http.get<any>(get_url).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
      getCommonList
    
      getPincodeAll(): Observable<any> {
        const get_url = BASE_API_URL + 'pincode/all';
        return this.http.get<any>(get_url).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
    
      getDetailsByPincode(parentid:any): Observable<any> {
        const get_url = BASE_API_URL + 'address/areaAndBuildingList/'+ parentid;
        return this.http.get<any>(get_url).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
    
    
      checkUniqueCustomerByEmail(value,custId): Observable<any> {
        const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
        let data:any = {
          "type": "email",
          "value": value,
          "subscriberId": custId
        };
        return this.http.post<any>(get_url,data).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
    
      checkUniqueCustomerByUsername(value): Observable<any> {
        const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
        let data:any = {
          "type": "username",
          "value": value
        };
        return this.http.post<any>(get_url,data).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
    
      checkUniqueCustomerByMobile(value,custId): Observable<any> {
        
        const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
        let data:any = {
          "type": "mobile",
          "value": value,
          "subscriberId": custId
        };
        return this.http.post<any>(get_url,data).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
    
      checkUniqueCustomerByCafno(value,subscriber_id?): Observable<any> {
        const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
        let data:any = {
          "type": "cafno",
          "value": value,
          "subscriberId": subscriber_id
        };
        return this.http.post<any>(get_url,data).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
      
      getRequiredAllField(): Observable<any> {
        /**Only For Subscriber Create Put It Will Change per Requirement*/
        const get_url = BASE_API_URL + 'uiPageConfig/getUiConfig/registration';
        return this.http.get<any>(get_url).pipe(
            map((res: any) => {
              return res;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
      }
    
      getCollectorListByPartner(partnerId): Observable<any> {
        const get_url = BASE_API_URL + 'userByPartnerAndRole/'+ partnerId + '/' + 14;
        return this.http.get<any>(get_url).pipe(
          map((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }

    getTitleList(): Observable<any> {
      const get_url = BASE_API_URL + 'commonList/title';
      return this.http.get<any>(get_url).pipe(
          map(res => res),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }
  }
  