export class DunningModel {
    id:number;
    name:any;
    fromEmail:any;
    bccemail:any;
    communicationEmail:boolean;
    communicationSMS:boolean;
    internalpayemail:any;
    escstaffemail:any;
    collagencyemail:any;
    creditclass:any;
    status:any;
    notificationfor:string;
    type:string
    dunningRulesActionPojoList:Array<DunningModelRule> = new Array<DunningModelRule>()
}

export class DunningModelRule {
    // druleid:number
    drule:DunningModel 
    id:number
    days:any;
    notificationid:number;
    action:any;
}

    
