import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  
  const BASE_API_URL = '';
  
  @Injectable({
    providedIn: 'root'
  })
  export class ReplyItemService {
    
    constructor(private http: HttpClient) {}

    transformPoliciesData(data) {
      let formatedData = [];
      data.radiusProfileCheckItemList.forEach(function(item:any){
        let itemData:any = {};
        itemData.radius_id = item.id;
        itemData.checkitem = item.checkitem;
        itemData.replyItems = item.replyItems;
        itemData.radiusProfileId = item.radiusProfileId;
        formatedData.push(itemData);
      });
      return {content:formatedData};
    }

    getReplyItemDataWithPageing(fetch_setting_data, radius_profile_id: any): Observable<any> {
      let page = fetch_setting_data.page;
      let size = fetch_setting_data.size;
      let params = new HttpParams().set("page", page).set("size", size);
      const get_url = BASE_API_URL + 'radiusprofilecheckitem/' + radius_profile_id;
  
      return this.http.get<any>(get_url,
        { headers: httpOptions.headers, params: params }).pipe(
          map((res: any) => {
            return this.transformPoliciesData(res);
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }

    transformPoliciesByIdData(data) {
      let formatedData = [];
      data.radiusProfileCheckItemList.forEach(function(item:any){
        let itemData:any = {};
        itemData.radius_id = item.id;
        itemData.checkitem = item.checkitem;
        itemData.replyItems = item.replyItems;
        itemData.radiusProfileId = item.radiusProfileId;
        formatedData.push(itemData);
      });
      return {content:formatedData};
    }

    getRadiusProfile(radius_profile_id: any) : Observable<any> {
      const get_url = BASE_API_URL + 'radiusprofilecheckitem/' + radius_profile_id;

      return this.http.get<any>(get_url,
        { headers: httpOptions.headers }).pipe(
          map((res: any) => {
            return this.transformPoliciesByIdData(res);
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
    }

    insertReplyItem(reply_item_data: any): Observable<any> {
      const post_url = BASE_API_URL + 'radiusprofilecheckitem';
      return this.http.post<any>(post_url, reply_item_data, httpOptions).pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  
    }

    updateReplyItem(reply_item_data: any,reply_item_id:any) : Observable<any> {
      const update_url = BASE_API_URL + 'radiusprofilecheckitem/' + reply_item_id;
      return this.http.put<any>(update_url, reply_item_data, httpOptions).pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }

    deleteReplyItem(reply_item_id:any) : Observable<any> {
      const delete_url = BASE_API_URL + 'radiusprofilecheckitem/' + reply_item_id;
      return this.http.delete(delete_url, httpOptions);
    }
  
  }