import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IBaseService } from '../../../../../../src/app/services/ibase-service';
import { AbstractBaseAddUpdateComponent } from '../../../../../../src/app/components/base/abstract-base-add-update-component';
import { GroupService } from '../group.service';
import {Group} from '../model/group'

@Component({
  selector: 'app-add-update-group',
  templateUrl: './add-update-group.component.html',
  styleUrls: ['./add-update-group.component.scss']
})
export class AddUpdateGroupComponent extends AbstractBaseAddUpdateComponent<Group> implements OnInit { 
  // form:FormGroup;
  statusList:any[]=[];
  groupId:any;
  form = new FormGroup({
    groupName: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
  })
  group = new Group();
  constructor(
    private groupservice:GroupService,
    public toastservice: ToastrService,
    public router: Router,
    private tosterservice: ToastrService,
    private activatedRoute:ActivatedRoute,
  ) { 
    super(toastservice, router)
  }

  ngOnInit() {
    this.getCommonStatus();
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.groupId=id
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
  }

  delete(event) {
    let self = this;
      let obj ={
        id : event.id,
        isAssignable : event.isAssignable,
        staffId : event.staffId,
        teamId : event.teamId
      }
      this.groupservice.deleteGroup(obj).subscribe((data: any) => {
        if (data.responseCode === 200) {
          this.tosterservice.success('Group deleted successfully');
        }
        else {
          this.tosterservice.error(data.responseMessage);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
  }

  setValidation() {
    this.form = new FormGroup({
      groupName: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
    })
  }
  get f() {
    return this.form.controls;
  }
  
  getCommonStatus(){
    
    this.groupservice.commonStatusActiveInactive().subscribe(res=>{
      if(res.responseCode===200){
        this.statusList=res.dataList;
      }
      })
  }

  formSubmit() {
   if (!this.form.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } 
    else {
    this.Data();
    }
  }

  onDelete(event) {
      let self = this;
      if (event) {
        self.delete(event);
      }
  }

  onSaveStaff(){
   // this.addNewClicked = false;
  //  this.onUpdate();
    setTimeout(()=>{
 //     this.getById(this.teamId)
    },100)
    //this.getStaffList(this.partnerid);
 
  }

  
  getService(): IBaseService<Group> {
    return this.groupservice;
  }
  setData(data: Group) {
    if(data){
       this.form.get('groupName').setValue(data.groupName)
       this.form.get('status').setValue(data.status)
    }
  }
  getData(): Group {
    return this.group;
  }
  
  getModuleHomeUrl() {
    // if(this.isUpdateComponent){
    //   return '/hr/add-group/'+ this.group ;
    // }else{
    //   let id = this.responseData.data.id;
    //   return '/hr/add-group/'+ id ;
    // }
    }

  getActiveRoute() {
    return this.activatedRoute;
  }
  getNewModal() {
    return new Group;
  }
  getFormGroup(): FormGroup {
    return this.form;
  }

  getGenericDataService(){
    return this.groupservice
    }

  getId(): number {
      return this.group.id;
   }

   Data(){
    let formData=this.form.value;
    let obj={
      groupName:formData.groupName,
      status:formData.status,
      id:this.groupId
    }
    if(this.groupId){
      this.updateData(obj);
    }else{
      this.saveData(obj);
    }
   
   }

   updateData(obj){
    this.groupservice.updateGroup(obj).subscribe((res)=>{
      if(res.responseCode===200){
       this.tosterservice.success('Group Updated successfully');
       this.backToViewList();
      }else{
        this.toastservice.error(res.responseMessage)
      }
     })
   }

   saveData(obj){
    this.groupservice.saveGroup(obj).subscribe((res)=>{
      if(res.responseCode===200){
       this.tosterservice.success('Group Saved successfully');
       this.backToViewList();

      }else{
        this.toastservice.error(res.responseMessage)
      }
     })
   }

   backToViewList() {
    this.router.navigate(['/hr/group-list']);
  }
}
