import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../../components/custom-modal';
import { CommonListService } from '../../../../../services/common_list/common_list.service';
import { SubscriberService } from '../../../subscriber/subscriber.service';
import { CustomerDocumentService } from '../customer-document.service';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { AclClassConstants } from '../../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../../pages/aclOperationConstants';
import { LoginService } from '../../../../../pages/login/login.service';
@Component({
  selector: 'app-customer-document-details',
  templateUrl: './customer-document-details.component.html',
  styleUrls: ['./customer-document-details.component.scss']
})
export class CustomerDocumentDetailsComponent implements OnInit {

  @Input() custId: any;
  @Input() docId: any;
  @Output() message: any = new EventEmitter();
  req_message = "This field is required.";
  submitted: any = null;
  showUpdate: boolean;
  aclClassConstants = AclClassConstants;
  aclConstants = AclConstants
  public documentList: any[] = [];
  filesize:any;
  
  insertCustomerDocumentForm = new FormGroup({
    docId: new FormControl(''),
    docType: new FormControl(null, [Validators.required]),
    docSubType: new FormControl(null),
    docStatus: new FormControl(null),
    remark: new FormControl('',[Validators.required]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  customerDocument: any;

  public docTypeForCustomerList: any[] = [
    {
      text: '',
      id: '',
    },
  ];

  public docSubTypeList: any[] = [
    {
      text: '',
      id: '',
    },
  ];

  public documentStatusList: any[] = [
    {
      text: '',
      value: '',
    },
  ];

  constructor(
    private customModalService: CustomModalService,
    private cdRef: ChangeDetectorRef,
    private customerDocumentService: CustomerDocumentService,
    private commonListService: CommonListService,
    private subscriberService: SubscriberService,
    private tosterservice: ToastrService,
    private loginService: LoginService

  ) { }

  ngOnInit() {
    this.setDocumentStatus();
    this.getDocTypeForCustomerList();
    this.getDocStatusList();
    this.getCustomerDocumnetById();
  }

  getCustomerDocumnetById() {
    if (this.docId != null) {
      this.customerDocumentService.getCustomerDocumentById(this.docId).subscribe(res => {
        this.customerDocument = res.data;
        this.showUpdate = true;
        this.insertCustomerDocumentForm.get('file').clearValidators();
        this.insertCustomerDocumentForm.get('file').updateValueAndValidity();
        this.insertCustomerDocumentForm.get('fileSource').clearValidators();
        this.insertCustomerDocumentForm.get('fileSource').updateValueAndValidity();
        this.insertCustomerDocumentForm.patchValue(this.customerDocument);
      });
    }
  }
  setDocumentStatus() {
    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER, AclConstants.OPERATION_CUSTOMER_DOCUMENT_VERIFICATION, AclConstants.OPERATION_CUSTOMER_ALL)) {
      this.insertCustomerDocumentForm.get('docStatus').setValidators([Validators.required]);
      this.insertCustomerDocumentForm.get('docStatus').updateValueAndValidity();
      this.insertCustomerDocumentForm.get('docStatus').enable();
      this.insertCustomerDocumentForm.get('docStatus').updateValueAndValidity();
    } else {
      this.insertCustomerDocumentForm.get('docStatus').setValue('pending');
    }
  }

  setCustomerDocumentForm(data) {
    this.showUpdate = true;
    this.insertCustomerDocumentForm.patchValue(data);
  }

  getDocStatusList() {
    this.commonListService.getDocStatusList().subscribe((result) => {
      this.documentStatusList = result.dataList;
    });
    this.setDocumentStatus();
  }

  getDocTypeForCustomerList() {
    this.subscriberService.getDocTypeForCustomerList().subscribe((result) => {
      this.docTypeForCustomerList = result.dataList;
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.insertCustomerDocumentForm.patchValue({
        fileSource: file
      });
    }
  }

  onCancel() {
    this.showUpdate = false;
    this.message.emit("Close");
  }

  saveCustomerDocumentOnDb() {
    
    if (!this.insertCustomerDocumentForm.valid) {
      Object.keys(this.insertCustomerDocumentForm.controls).forEach(field => {
        let control = this.insertCustomerDocumentForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      let formData: any = new FormData();
      
      console.log("size"+formData)
      let newFormData = Object.assign({}, this.insertCustomerDocumentForm.value);
      if (localStorage.getItem('partnerFlag') === 'true') {
        newFormData.docStatus = 'pending';
      }
      

      let docData: any = [{
        custId: this.custId,
        docType: newFormData.docType,
        docSubType: newFormData.docSubType,
        docStatus: newFormData.docStatus,
        remark: newFormData.remark,
        filename: newFormData.file.split('\\').pop()
      }];
     
     if(newFormData.fileSource.size <= 10000000){
      
      formData.append("file", newFormData.fileSource);
      formData.append("docDetailsList", JSON.stringify(docData));
      this.customerDocumentService.saveCustomerDocument(formData).subscribe(res => {
        if (res.responseCode !== 200 && res.responseMessage) {
          this.tosterservice.error(res.responseMessage);
        } else {
          this.message.emit("Customer Document Save Successfully");
        }
      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        })
      
    }else{
      this.tosterservice.error("Attached  File size Greater than 10MB.Please Try Again");
       
    }
      
    }
  }
  

  updateCustomerDocumentOnDb() {
    if (!this.insertCustomerDocumentForm.valid) {
      Object.keys(this.insertCustomerDocumentForm.controls).forEach(field => {
        let control = this.insertCustomerDocumentForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      let newFormData = Object.assign({}, this.insertCustomerDocumentForm.value);
      if (localStorage.getItem('partnerFlag') === 'true') {
        newFormData.docStatus = 'pending';
      }
      let docData: any = {
        custId: this.custId,
        docId: this.docId,
        docType: newFormData.docType,
        docSubType: newFormData.docSubType,
        docStatus: newFormData.docStatus,
        remark: newFormData.remark,
        filename: this.customerDocument.filename,
        uniquename: this.customerDocument.uniquename
      };
      this.customerDocumentService.updateCustomerDocument(docData).subscribe(res => {
        if (res.responseCode !== 200 && res.responseMessage) {
          this.tosterservice.error(res.responseMessage);
        } else {
          this.showUpdate = false;
          this.message.emit("Customer Document Update Successfully");
        }
      })
    }
  }

  onChangeDocTypeForCustomerList(doc_type_for_customer_data) {
    this.insertCustomerDocumentForm.get('docSubType').reset();
    if (doc_type_for_customer_data) {
      this.docTypeForCustomerList.forEach(element => {
        if (element.id === doc_type_for_customer_data.id) {
          this.docSubTypeList = element.subTypeList;
        }

      });
    } else {
      this.docSubTypeList = [];
    }
  }

}
