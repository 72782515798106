import { Component, OnInit,Input } from '@angular/core';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
import { CustomModalService } from '../../../../components/custom-modal';
import { SearchService } from '../../../../services/search.service';
import { LoginService } from '../../../../pages/login/login.service';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { ValueAddedServiceService } from '../subscriber-tables/value-added-services/value-added-service.service';

@Component({
  selector: 'app-provision-status',
  templateUrl: './provision-status.component.html',
  styleUrls: ['./provision-status.component.scss']
})
export class ProvisionStatusComponent  implements OnInit {
  

  @Input() custId: any;
  @Input() provisionList:any;
  provisionStatusSetings:any;
  allColumns:any[]=[];
  tablekeyEnum=TableKeyEnum;
  loginService:any;
  dataSource=new MatTableDataSource([]);
  constructor(
    private valueAddedService:ValueAddedServiceService,
    private customModalService:CustomModalService,
    private searchService:SearchService,
    public loginservice:LoginService
  ) { 
  }

  ngOnInit() {
    this.getProvisionData();
    this.setGridSetting();
  }
  getProvisionData(){
    this.refereshDataSource(this.provisionList);
  }
  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'provisionDate',
        default: 'Provision Date',
        rowKey: 'provisionDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'error',
        default: 'Error',
        rowKey: 'error',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    ];
    this.provisionStatusSetings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tablekeyEnum.PROVISION_STATUS,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }
  refereshDataSource(data){
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  }

}
