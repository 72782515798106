import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../components/loading/loading.component';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { GridComponent } from '../components/grid/grid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NeoGridComponent } from '../components/neo-grid/neo-grid.component';
import { NeoTableLibModule } from 'n-neo-table-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchAutocompleteComponent } from '../components/search-autocomplete/search-autocomplete.component';
import { GenericQuickcaseComponent } from '../components/generic-quickcase/generic-quickcase.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap';
import { MatTooltipModule } from '@angular/material';
import { ChildloadingComponent } from '../components/childloading/childloading.component';
@NgModule({
  declarations: [LoadingComponent, PaginationComponent, GridComponent, NeoGridComponent,SearchAutocompleteComponent,GenericQuickcaseComponent,ChildloadingComponent],
  exports: [LoadingComponent, PaginationComponent, GridComponent, NeoGridComponent,SearchAutocompleteComponent,GenericQuickcaseComponent,ChildloadingComponent],
  imports: [CommonModule, FormsModule,ReactiveFormsModule, NeoTableLibModule, NgbModule,NgSelectModule,TooltipModule.forRoot(),MatTooltipModule
  ],
})
export class SharedModule {}
