import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import * as alertify from "alertifyjs";
import { CustomModalService } from "../../../../components/custom-modal";
import { BasicDetailsService } from "../basic-details/basic-details.service";
import { SubscriberDetailsComponent } from "../subscriber-details.component";
import { PaymentCollectionService } from "../../../reports/payment-collection-report/service/payment-collection.service";

@Component({
  selector: "app-change-payment-collector",
  templateUrl: "./change-payment-collector.component.html",
  styleUrls: ["./change-payment-collector.component.scss"],
})
export class ChangePaymentCollectorComponent implements OnInit {
  @ViewChild(SubscriberDetailsComponent, { static: false })
  private subscriberComponent: SubscriberDetailsComponent;
  collectorList: any;
  submitted: any = null;
  submitedFlag: boolean = false;
  @Input() collectorName: string;
  @Input() service: string;
  @Input() partnerNameChild: string;
  @Input() partnerId: number;
  @Input() custId: any;

  changeCollectorForm = new FormGroup({
    collectorId: new FormControl(""),
    subscriberId: new FormControl(""),
    collectorname: new FormControl(""),
    remark: new FormControl("", [Validators.required]),
  });

  constructor(
    private basicDetailsservice: BasicDetailsService,
    private customModalService: CustomModalService,
    private toastr: ToastrService,
    private payCollService: PaymentCollectionService
  ) {}

  ngOnInit() {
    this.LoadFormControl();
    if (this.custId) {
      this.getPayCollList(this.custId);
    }
  }

  LoadFormControl() {
    this.changeCollectorForm.get("collectorname").setValue(this.collectorName);
    this.changeCollectorForm.get("collectorname").disable();
    this.changeCollectorForm.get("subscriberId").setValue(this.custId);
  }

  onClickSaveChangePayCollForm() {
    if (this.changeCollectorForm.invalid) {
      this.submitedFlag = true;
      return;
    }

    if (this.changeCollectorForm.valid && this.submitedFlag !== true) {
      let formValue = this.changeCollectorForm.value;
      if (this.changeCollectorForm.valid === true) {
        let self = this;
        alertify.confirm(
          "Change Payment Collector",
          "Are You Sure You want to Change Current Collector?",
          function () {
            self.insertBasicDetailsOnDb(formValue);
          },
          function () {}
        );
      }
    }
  }

  insertBasicDetailsOnDb(formValue) {
    this.basicDetailsservice.updateNewCollector(formValue).subscribe((result) => {
      if (result.responseCode === 200) {
        this.toastr.success("Payment Collector Changed Successfully");
        this.closeModal("change-collector");
        this.customModalService.close("change-collector", result);
      } else {
        this.toastr.error("Some thing Went Wrong");
      }
    });
  }

  closeModal(id: any) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.submitted = false;
  }

  getPayCollList(id) {
    this.basicDetailsservice.getNewCollectorList(id).subscribe((result) => {
      this.collectorList = result.data.staffList;
      this.changeCollectorForm.get('collectorname').setValue(result.data.collectorName)
    });
  }
}
