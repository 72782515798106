import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { CustomModalService } from '../../../../components/custom-modal';
import { IppoolConfigService } from '../ippool-config.service';
import _ from 'underscore';
//import { PackagesService } from '../../../plan_management/packages/packages.service';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchService } from '../../../../../services/search.service';
import * as alertify from 'alertifyjs';
import { constants } from 'os';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort } from '@angular/material';
//import { PriceBookDetailsComponent } from '../price-book-details/price-book-details.component';
import { ThrowStmt } from '@angular/compiler';
import { AddFranchiesComponent } from '../../add-franchies.component';
import { FranchiesComponent } from '../../../franchies/franchies.component';



@Component({
  selector: 'app-ippool-config-details',
  templateUrl: './ippool-config-details.component.html',
  styleUrls: ['./ippool-config-details.component.scss']
})
export class IppoolConfigDetailsComponent implements OnInit {
  displayedColumns: string[] = ['select','displayName', 'ipRange', 'isStaticIpPool'];
  dataSource :any;
  @Input() franchiesid: any;
  @Output() cancel = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(AddFranchiesComponent, { static: false }) AddFranchiesComponent: AddFranchiesComponent;
  page: any = 0;
  perPage: any = 5;
  formGroup: FormGroup;
  ippooldata: any;
  selectedPoolId = new Array<any>();
  constructor(
    private IppoolConfigService: IppoolConfigService,
    private fb: FormBuilder,
    private tosterservice: ToastrService) { }
    selection = new SelectionModel<any>(true, []);
  ngOnInit() {
    this.setValidation();
    this.getIPPoolData(); 
  }
  ngAfterViewInit() {
    if(this.paginator)
    this.dataSource.paginator = this.paginator;
}
  setValidation() {
    this.formGroup = this.fb.group({

    });
  }
  selectHandler(row) {
   this.selection.toggle(row)
  }
  getIPPoolData() {
    this.IppoolConfigService.getippool().subscribe(result => {
  
      this.dataSource = new MatTableDataSource(result.dataList);
      this.dataSource.paginator = this.paginator;
    });
  }
  onSubmit(){
    this.selection.selected.forEach((res)=>{
      if(res){
        let obj = {
          partnerId : this.franchiesid,
          ipPoolId : res.poolId
        }
        this.selectedPoolId.push(obj)
      }
    })
    this.IppoolConfigService.saveIPpoolByPartner(this.selectedPoolId).subscribe((res)=>{
    if(res.responseCode !== 200){
    this.tosterservice.error(res.responseMessage);
    }
    else{
      this.tosterservice.success("IP Pool Allocation Successfully");
      this.cancel.emit(false);
    }
    });
   

  }
  closeModal(){
  this.cancel.emit(false);
  }
}

