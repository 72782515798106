import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material';
import { AlertModule, TooltipModule } from 'ngx-bootstrap';
import { ButtonsModule, BsDropdownModule, PaginationModule } from 'ngx-bootstrap';

import { WidgetModule } from '../../layout/widget/widget.module';
import { TextMaskModule } from 'angular2-text-mask';
import { DateFormat } from "../../services/date-format";
/* tslint:disable */
import { BootstrapWizardModule } from '../../components/wizard/wizard.module';
import { DropzoneDemoDirective } from '../../components/dropzone/dropzone.directive';
import { EditorModule } from '@tinymce/tinymce-angular';

/* tslint:enable */
import { AccountingProfileComponent } from './accounting-profile/accounting-profile.component';
import { AuditComponent } from './audit/audit.component';
import { ClientsComponent } from './clients/clients.component';
import { ConnectedSubscriberComponent } from './connected-subscriber/connected-subscriber.component';
import { PoliciesComponent } from './policies/policies.component';
import {MatNativeDateModule, DateAdapter } from '@angular/material';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2TableModule } from 'ng2-table';

import { UtilsModule } from '../../layout/utils/utils.module';
import { JqSparklineModule } from '../../components/sparkline/sparkline.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../layout/shared.module';
import { SubscriberCdrComponent } from './subscriber-cdr/subscriber-cdr.component';
import { AddAccountingProfileComponent } from './add-accounting-profile/add-accounting-profile.component';
import { AddPoliciesComponent } from './add-policies/add-policies.component';
import { ReplyitemComponent } from './replyitem/replyitem.component';
import { AddClientsComponent } from './add-clients/add-clients.component';
import { ClientGroupComponent } from './client-grp/client-grp-list/client-group.component';
import { AddClientGroupComponent } from './client-grp/add-client-group/add-client-group.component';
import { AppGuard } from '../../app.guard';
import { AclConstants } from '../aclOperationConstants';
import { AclClassConstants } from '../aclClassConstants';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

export const routes = [
  { path: 'audit', redirectTo: 'audit', pathMatch: 'full' },
  {
    path: 'audit',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_AUTH_RESPONSE_VIEW,
      classId: AclClassConstants.ACL_AUDIT,
      accessIdForAllOpreation: AclConstants.OPERATION_AUTH_RESPONSE_ALL,
      operation: 'audit'
    },
    component: AuditComponent
  },
  {
    path: 'accounting-profile',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_ACCOUNTING_PROFILE_VIEW,
      classId: AclClassConstants.ACL_ACCOUNTING_PROFILE,
      accessIdForAllOpreation: AclConstants.OPERATION_ACCOUNTING_PROFILE_ALL,
      operation: 'accounting profile'
    },
    component: AccountingProfileComponent
  },
  {
    path: 'accounting-profile/add-accounting-profile',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_ACCOUNTING_PROFILE_ADD,
      classId: AclClassConstants.ACL_ACCOUNTING_PROFILE,
      accessIdForAllOpreation: AclConstants.OPERATION_ACCOUNTING_PROFILE_ALL,
      operation: 'Add accounting profile'
    },
    component: AddAccountingProfileComponent
  },
  {
    path: 'accounting-profile/add-accounting-profile/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_ACCOUNTING_PROFILE_EDIT,
      classId: AclClassConstants.ACL_ACCOUNTING_PROFILE,
      accessIdForAllOpreation: AclConstants.OPERATION_ACCOUNTING_PROFILE_ALL,
      operation: 'Edit accounting profile'
    },
    component: AddAccountingProfileComponent
  },
  {
    path: 'clients',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CLIENTS_VIEW,
      classId: AclClassConstants.ACL_CLIENTS,
      accessIdForAllOpreation: AclConstants.OPERATION_CLIENTS_ALL,
      operation: 'clients'
    },
    component: ClientsComponent
  },
  {
    path: 'clients/add-clients',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CLIENTS_ADD,
      classId: AclClassConstants.ACL_CLIENTS,
      accessIdForAllOpreation: AclConstants.OPERATION_CLIENTS_ALL,
      operation: 'Add clients'
    },
    component: AddClientsComponent
  },
  {
    path: 'clients/add-clients/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CLIENTS_EDIT,
      classId: AclClassConstants.ACL_CLIENTS,
      accessIdForAllOpreation: AclConstants.OPERATION_CLIENTS_ALL,
      operation: 'Edit clients'
    },
    component: AddClientsComponent
  },
  {
    path: 'clients-group',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CLIENT_GROUP_VIEW,
      classId: AclClassConstants.ACL_CLIENT_GROUP,
      accessIdForAllOpreation: AclConstants.OPERATION_CLIENT_GROUP_ALL,
      operation: 'clients group'
    },
    component: ClientGroupComponent
  },
  {
    path: 'clients-group/add-group',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CLIENT_GROUP_ADD,
      classId: AclClassConstants.ACL_CLIENT_GROUP,
      accessIdForAllOpreation: AclConstants.OPERATION_CLIENT_GROUP_ALL,
      operation: 'Add clients group'
    },
    component: AddClientGroupComponent
  },
  { 
    path: 'clients-group/add-group/:id', 
    canActivate: [AppGuard],
    data: {
       operationId: AclConstants.OPERATION_CLIENT_GROUP_EDIT,
       classId:AclClassConstants.ACL_CLIENT_GROUP,
       accessIdForAllOpreation :AclConstants.OPERATION_CLIENT_GROUP_ALL,
       operation:'Edit clients group'
     },
    component: AddClientGroupComponent 
  },
  {
    path: 'connected-subscriber',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_LIVE_USER_VIEW,
      classId: AclClassConstants.ACL_CONNECTED_SUBSCRIBER,
      accessIdForAllOpreation: AclConstants.OPERATION_LIVE_USER_ALL,
      operation: 'connected subscriber'
    },
    component: ConnectedSubscriberComponent
  },
  {
    path: 'policies',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_VIEW,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'policies'
    },
    component: PoliciesComponent
  },
  {
    path: 'policies/add-policies',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_ADD,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'Add new policies'
    },
    component: AddPoliciesComponent
  },
  {
    path: 'policies/add-policies/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_EDIT,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'Edit policies'
    },
    component: AddPoliciesComponent
  },
  {
    path: 'policies/add-policies/add-replyitem/:RadiusProfileId',
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_EDIT,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'Add replyitem'
    },
    component: ReplyitemComponent
  },
  {
    path: 'policies/add-policies/add-replyitem/:RadiusProfileId/:id',
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_EDIT,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'Edit replyitem'
    },
    component: ReplyitemComponent
  },
  {
    path: 'dynaauthpolicies',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DYNAAUTH_POLICY_VIEW,
      classId: AclClassConstants.ACL_DYNAAUTH_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_DYNAAUTH_POLICY_ALL,
      operation: 'policies'
    },
    component: PoliciesComponent
  },
  {
    path: 'policies/add-dynaauthpolicies',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DYNAAUTH_POLICY_ADD,
      classId: AclClassConstants.ACL_DYNAAUTH_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_DYNAAUTH_POLICY_ALL,
      operation: 'Add new policies'
    },
    component: AddPoliciesComponent
  },
  {
    path: 'policies/add-dynaauthpolicies/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DYNAAUTH_POLICY_EDIT,
      classId: AclClassConstants.ACL_DYNAAUTH_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_DYNAAUTH_POLICY_ALL,
      operation: 'Edit policies'
    },
    component: AddPoliciesComponent
  },
  {
    path: 'policies/add-dynaauthpolicies/add-replyitem/:RadiusProfileId',
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_EDIT,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'Add replyitem'
    },
    component: ReplyitemComponent
  },
  {
    path: 'policies/add-dynaauthpolicies/add-replyitem/:RadiusProfileId/:id',
    data: {
      operationId: AclConstants.OPERATION_RADIUS_PROFILE_EDIT,
      classId: AclClassConstants.ACL_POLICIES,
      accessIdForAllOpreation: AclConstants.OPERATION_RADIUS_PROFILE_ALL,
      operation: 'Edit replyitem'
    },
    component: ReplyitemComponent
  },
  {
    path: 'dbcdrprocessing',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DBCDR_PROCESSING_VIEW,
      classId: AclClassConstants.ACL_SUBSCRIBER_CDRS,
      accessIdForAllOpreation: AclConstants.OPERATION_DBCDR_PROCESSING_ALL,
      operation: 'dbcdrprocessing'
    },
    component: SubscriberCdrComponent
  },
];

@NgModule({
  declarations: [
    AuditComponent,
    AccountingProfileComponent,
    ClientsComponent,
    ConnectedSubscriberComponent,
    PoliciesComponent,
    SubscriberCdrComponent,
    AddAccountingProfileComponent,
    AddPoliciesComponent,
    ReplyitemComponent,
    AddClientsComponent,
    ClientGroupComponent,
    AddClientGroupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TextMaskModule,
    SharedModule,
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    WidgetModule,
    BootstrapWizardModule,
    RouterModule.forChild(routes),
    EditorModule,
    JqSparklineModule,
    PaginationModule.forRoot(),
    UtilsModule,
    Ng2TableModule,
    NgxDatatableModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [AppGuard,{ provide: DateAdapter, useClass: DateFormat }],
  
  exports: [AuditComponent],
  entryComponents: [AuditComponent],
  
})
export class RadiusModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
  }
  static routes = routes;
}
