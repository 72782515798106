import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PartnerConfiguration } from '../model/PartnerConfiguration';
import { FranchiesService } from '../../franchies/franchies.service';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { SearchService } from '../../../../services/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
import { AddFranchiesComponent } from '../add-franchies.component';
// import { TableKeyEnum } from '../../../core/enum/table-key.enum';
@Component({
  selector: 'app-partner-configuration',
  templateUrl: './partner-configuration.component.html',
  styleUrls: ['./partner-configuration.component.scss']
})
export class PartnerConfigurationComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild(AddFranchiesComponent, { static: false }) franchiesRef: AddFranchiesComponent;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  constructor(
    private franchies: FranchiesService,
    private toaster: ToastrService,
    private searchService: SearchService,
    private route: ActivatedRoute,
  ) {
    super(searchService);
  }
  commonlistType: any[] = []
  allColumns: any[] = [];
  NeoGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  partnerId: any;
  partnerConfigId: any;
  dataSource = new MatTableDataSource([]);
  PartnerconfigDataForGrid: any = { content: [] };
  subscriptions: Subscription[] = [];
  showUpdate: boolean = false;
  error_message = "This field is required";
  insertPartnerConfig = new FormGroup({
    id: new FormControl(),
    Key: new FormControl('', Validators.required),
    Value: new FormControl('', Validators.required),
    displayName: new FormControl('', Validators.required),
    valueType: new FormControl(null, Validators.required),
    commonlistType: new FormControl(null),
    ModulevalueFlag: new FormControl(false),
  })

  partnerConfig: PartnerConfiguration = new PartnerConfiguration();
  ngOnInit() {
this.setDefaultPageSize(this.tableKeyEnum.PARTNER_CONFIGURE)
    let id = this.route.snapshot.paramMap.get('id');
    this.partnerId = id;
    this.commonlist();
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
    // ////console.log("null",this.franchiesRef.franchies_id);
  }

  public getListWithParams() {

    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getPartnerConfig(config);
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.PartnerconfigDataForGrid.content));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.PartnerconfigDataForGrid.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.PartnerconfigDataForGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  validateForm() {
    if (!this.insertPartnerConfig.valid) {
      Object.keys(this.insertPartnerConfig.controls).forEach(field => {
        const control = this.insertPartnerConfig.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
    } else {
      if (this.partnerConfigId != null) {
        this.updatePartner();
      } else {
        this.insertPartner();
      }
    }
  }
  getPartnerConfig(gridSetting: any) {
    let fetchSettingData = this.NeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.franchies.getAllConfig(params, this.partnerId).subscribe((result) => {
      ////console.log("result", result.dataList)
      this.PartnerconfigDataForGrid = result.dataList;

      this.refreshDataSource(result.dataList);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.NeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  insertPartner() {
    if (this.insertPartnerConfig.valid === true) {
      let apiInputData = this.transormFormApiInputData(this.insertPartnerConfig.value);
      this.franchies.saveParnertConfig(apiInputData).subscribe(res => {
        if (res.responseCode === 200) {
          this.toaster.success("Data has been Successfully saved");
          this.insertPartnerConfig.reset();
          this.insertPartnerConfig.get('ModulevalueFlag').setValue(false);
        } else {
          this.toaster.error(res.responseMessage);
        }
        this.getListWithParams();
      })
    }
  }
  updatePartner() {
    if (this.insertPartnerConfig.valid === true) {
      let apiInputData = this.transormFormApiUpdateData(this.insertPartnerConfig.value);
      this.franchies.updateParnertConfig(apiInputData).subscribe(res => {
        if (res.responseCode === 200) {
          this.toaster.success("Data has been Successfully Updated");
          this.partnerConfigId = null;
          this.insertPartnerConfig.reset();
          this.insertPartnerConfig.get('ModulevalueFlag').setValue(false);
        } else {
          this.toaster.error(res.responseMessage);
        }
        this.getListWithParams();
      })
    }
  }
  onEdit(event) {
    this.tranformDataById(event);
  }
  tranformDataById(data) {
    let itemData: any = {};
    itemData.id = data.id;
    this.partnerConfigId = data.id;
    itemData.Key = data.key;
    itemData.displayName = data.displayname;
    itemData.Value = data.value;
    itemData.valueType = data.valuetype;
    this.insertPartnerConfig.patchValue(itemData);
  }
  onDelete(event) {
    let self = this;
    if (event) {
      this.commonDeleteMsgBox(this.NeoGridSettings, event, this.delete.bind(this));
    }
  }
  delete(objEvent) {
    let self = this;
    let selected_franchies_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.franchies.deletePartnerConfig(selected_franchies_data).subscribe((result) => {
        if (result.responseCode === 200) {
          this.toaster.success('Data is deleted successfully');
          // self.NeoGridSettings.content.forEach(function (item, index) {
          //   if (item.franchies_id === selected_franchies_data.franchies_id) {
          //     self.NeoGridSettings.content.splice(index, 1);
          //   }
          // });
          self.getListWithParams();
        }
      }, err => {
        this.toaster.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }
  transormFormApiInputData(data) {
    let Itemdata: any = {};
    Itemdata.key = data.Key;
    Itemdata.value = data.Value;
    Itemdata.displayname = data.displayName;
    Itemdata.partnerid = this.partnerId;
    Itemdata.valuetype = data.valueType;
    Itemdata.modulevalueflag = data.ModulevalueFlag;
    return Itemdata;
  }
  transormFormApiUpdateData(data) {
    let Itemdata: any = {};
    Itemdata.id=data.id;
    Itemdata.key = data.Key;
    Itemdata.value = data.Value;
    Itemdata.displayname = data.displayName;
    Itemdata.partnerid = this.partnerId;
    Itemdata.valuetype = data.valueType;
    Itemdata.modulevalueflag = data.ModulevalueFlag;
    return Itemdata;
  }
  commonlist() {
    this.franchies.getCommonlist("valuetype").subscribe(res => {
      this.commonlistType = res.dataList;
    })
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        sortKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'key',
        default: 'Key',
        rowKey: 'key',
        sortKey: 'key',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'value',
        default: 'Value',
        rowKey: 'value',
        sortKey: 'value',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'displayname',
        default: 'Display name',
        rowKey: 'displayname',
        sortKey: 'displayname',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },

      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });


    this.NeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: TableKeyEnum.PARTNER_CONFIGURE,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Data ?',
      deleteMsgBoxDetail: 'Partner  Name :${displayname} ',
    }
    if (this.NeoGridSettings.serverSideSortPagination && this.NeoGridSettings.perPage) {
      this.setPaginationPerPage(this.NeoGridSettings.perPage);
    }


  }
}
