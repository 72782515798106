import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import _ from 'underscore';
import { QuotaTypeDataService } from '../../../../services/quotaTypeData/quotaTypeData.service';
import { QuotaTypeTimeService } from '../../../../services/quotaTypeTime/quotaTypeTime.service';
import { ChangeQuotaService } from './change-quota.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-quota',
  templateUrl: './change-quota.component.html',
  styleUrls: ['./change-quota.component.scss']
})
export class ChangeQuotaComponent implements OnInit {
  
  @Input() custId : any;
  planMapID:number;
  insertChangeQuotaForm = new FormGroup ({
    planId : new FormControl(null, [Validators.required]),
    unitDataQuota : new FormControl(''),
    unitTimeQuota : new FormControl(''),
    valueDataQuota : new FormControl(''),
    valueTimeQuota : new FormControl(''),
    operationDataQuota : new FormControl(''),
    operationTimeQuota : new FormControl(''),
    remark : new FormControl('', [Validators.required]),
    planmapid: new FormControl(),


  });


  selectedValue : any =
  {planId : '', planName : '', quotaType :'', expiryDate :'', volTotalQuota :'', volUsedQuota : '', volQuotaUnit:'',timeTotalQuota :'',timeQuotaUnit:'',timeUsedQuota :''};

  planList :any[] = [
    {planId : '', planName : '', quotaType :'', expiryDate :'', volTotalQuota :'', volUsedQuota : '', volQuotaUnit:'',timeTotalQuota :'',timeQuotaUnit:'',timeUsedQuota :''}
  ]

  DataList : any[] = [
    {text :'', value :''}
  ];
  timeList : any[] = [
    {text :'', value :''}
  ];

  constructor(
    private customModalService: CustomModalService,
    private dataQuotaService : QuotaTypeDataService,
    private timeQuotaService : QuotaTypeTimeService,
    private changeQuotaService : ChangeQuotaService,
    private cdRef:ChangeDetectorRef,
    private tosterservice : ToastrService

  ) { }

  ngOnInit() {
    this.changeQuotaService.getChangeQuota(this.custId).subscribe(
      result => this.planList = result.dataList
    );
    this.insertChangeQuotaForm.get('planId').valueChanges.subscribe(
      value => {
        this.changeQuotaService.getChangeQuotaByPlanId(this.custId, value).subscribe
        (result => 
          {
            let data = _.find(result.dataList,{planId:parseInt(value)});
            this.selectedValue = data;
            if(this.selectedValue && this.selectedValue.quotaType){
              if (this.selectedValue.quotaType === "Data") {
                this.insertChangeQuotaForm.get('operationTimeQuota').disable();
                this.insertChangeQuotaForm.get('valueTimeQuota').disable();
                this.insertChangeQuotaForm.get('unitTimeQuota').disable();
                this.insertChangeQuotaForm.get('unitDataQuota').enable();
                this.insertChangeQuotaForm.get('unitDataQuota').setValidators([Validators.required]);
                this.insertChangeQuotaForm.get('valueDataQuota').setValidators([Validators.required]);
                this.insertChangeQuotaForm.get('operationDataQuota').setValidators([Validators.required]);
                this.insertChangeQuotaForm.get('valueDataQuota').enable();
                this.insertChangeQuotaForm.get('operationDataQuota').enable();
              }
              if (this.selectedValue.quotaType === "Time") {
                this.insertChangeQuotaForm.get('operationTimeQuota').enable();
                this.insertChangeQuotaForm.get('operationTimeQuota').setValidators([Validators.required]);
                this.insertChangeQuotaForm.get('valueTimeQuota').setValidators([Validators.required]);
                this.insertChangeQuotaForm.get('unitTimeQuota').setValidators([Validators.required]);
                this.insertChangeQuotaForm.get('valueTimeQuota').enable();
                this.insertChangeQuotaForm.get('unitTimeQuota').enable();
                this.insertChangeQuotaForm.get('unitDataQuota').disable();
                this.insertChangeQuotaForm.get('valueDataQuota').disable();
                this.insertChangeQuotaForm.get('operationDataQuota').disable();
              }
              if (this.selectedValue.quotaType === "Both") {
                this.insertChangeQuotaForm.get('operationTimeQuota').enable();
                this.insertChangeQuotaForm.get('valueTimeQuota').enable();
                this.insertChangeQuotaForm.get('unitTimeQuota').enable();
                this.insertChangeQuotaForm.get('unitDataQuota').enable();
                this.insertChangeQuotaForm.get('valueDataQuota').enable();
                this.insertChangeQuotaForm.get('operationDataQuota').enable();
              }
            }
          
        });
      })
    this.dataQuotaService.getQuotaTypeData().subscribe(result => this.DataList = result.dataList);
    this.timeQuotaService.getQuotaTypeTime().subscribe(result => this.timeList = result.dataList);
  }
  onChangeChoosePlane(event){
    
    if(this.planMapID !== null && event){
      this.planMapID =  event.planmapid;
      this.insertChangeQuotaForm.get('unitDataQuota').setValidators([Validators.required]);
    }else{
      this.insertChangeQuotaForm.reset();
      this.insertChangeQuotaForm.updateValueAndValidity();
      this.selectedValue = null;
    }

  }
  onClickSaveForm() {
    if (this.insertChangeQuotaForm.valid === true) {
      let self = this;
      alertify.confirm("Change Quota","Are You Sure You want to Change Quota ?",
      function() {
        self.insertChangeQuotaOnDb();
      },
      function() {
  
      });
    } else {
      this.insertChangeQuotaForm.markAllAsTouched();
    }
  }

  insertChangeQuotaOnDb() {
    if (this.insertChangeQuotaForm.valid === true) {
      let formatedInputData:any = this.insertChangeQuotaForm.value;
      formatedInputData.custId = this.custId;
      formatedInputData.planmapid =  this.planMapID
      this.changeQuotaService.insertChangeQuota(formatedInputData).subscribe(result => {
          this.cdRef.detectChanges();
          if (result.responseCode !== 200 && result.responseMessage) {
            this.tosterservice.error(result.responseMessage);
          } else {
            this.tosterservice.success('Change Quota has been created successfully');
            this.customModalService.close('change-quota',result);
          }
        },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }

  numberOnly(event): boolean {
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
