import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  DateAdapter,
  MatTableModule,
  MatIconModule,
  MatToolbar,
  MatToolbarRow,
  MatFormFieldModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTabsModule
} from "@angular/material";

import { AlertModule, TooltipModule } from 'ngx-bootstrap';
import { ButtonsModule, BsDropdownModule, PaginationModule } from 'ngx-bootstrap';

import { WidgetModule } from '../../layout/widget/widget.module';
import { TextMaskModule } from 'angular2-text-mask';

/* tslint:disable */
import { BootstrapWizardModule } from '../../components/wizard/wizard.module';
import { DropzoneDemoDirective } from '../../components/dropzone/dropzone.directive';
import { EditorModule } from '@tinymce/tinymce-angular';

/* tslint:enable */
import { ChargeComponent } from './charge/charge.component';
import { DiscountComponent } from './discount/discount.component';
import { PackagesComponent } from './packages/packages.component';
import { ServiceComponent } from './service/service.component';
import { TaxComponent } from './tax/tax.component';
import { AddTaxComponent } from './add-tax/add-tax.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2TableModule } from 'ng2-table';

import { UtilsModule } from '../../layout/utils/utils.module';
import { JqSparklineModule } from '../../components/sparkline/sparkline.module';

import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../../layout/shared.module';
import { AddDiscountComponent } from './add-discount/add-discount.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { AddChargeComponent } from './add-charge/add-charge.component';
import { AddPackagesComponent } from './add-packages/add-packages.component';
import { PolicyComponent } from './policy/policy.component';
import { AddPolicyComponent } from '../plan_management/add-policy/add-policy.component';
import { PriceBookComponent } from './price-book/price-book.component';
import { AddPlanComponent } from './price-book/add-plan/add-plan.component';
import { PriceBookDetailsComponent } from './price-book/price-book-details/price-book-details.component';
import { SearchPipe } from './price-book/pipes/search.pipe';
import { CustomModalModule } from '../../components/custom-modal';
// import { LoadingComponent } from '../../components/loading/loading.component';
// import { PaginationComponent } from '../../components/pagination/pagination.component';
// import { GridComponent } from '../../components/grid/grid.component';
import { AppGuard } from '../../app.guard';
import { AclConstants } from '../aclOperationConstants';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AclClassConstants } from '../aclClassConstants';


import { DateFormat } from '../../services/date-format';
import { AddQosPolicyComponent } from './add-qos-policy/add-qos-policy.component';
import { QosPolicyDetailComponent } from './qos-policy-detail/qos-policy-detail.component';
import { DunningAddUpdateComponent } from './dunning/dunning-add-update/dunning-add-update.component';
import { DunningListComponent } from './dunning/dunning-list/dunning-list.component';
import { DunningRuleComponent } from './dunning/dunning-rule/dunning-rule.component';
import { ValueAddedServiceComponent } from './vas/vas-list/value-added-service.component';
import { AddValueAddedeServiceComponent } from './vas/vas-add-update/add-value-addede-service.component';
import { VasProviderDetailsComponent } from './vas-provider-details/vas-provider-details.component';
import { MasterFormsModule } from '../masters/master_forms.module';

export const routes = [
  { path: 'charge', redirectTo: 'charge', pathMatch: 'full' },
  {
    path: 'charge',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CHARGE_VIEW,
      classId: AclClassConstants.ACL_CHARGE,
      accessIdForAllOpreation: AclConstants.OPERATION_CHARGE_ALL,
      operation: 'charge'
    },
    component: ChargeComponent
  },
  {
    path: 'charge/add-charge',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CHARGE_ADD,
      classId: AclClassConstants.ACL_CHARGE,
      accessIdForAllOpreation: AclConstants.OPERATION_CHARGE_ALL,
      operation: 'Add new charge'
    },
    component: AddChargeComponent
  },
  {
    path: 'charge/add-charge/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_CHARGE_EDIT,
      classId: AclClassConstants.ACL_CHARGE,
      accessIdForAllOpreation: AclConstants.OPERATION_CHARGE_ALL,
      operation: 'Edit charge'
    },
    component: AddChargeComponent
  },
  {
    path: 'discount',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DISCOUNT_VIEW,
      classId: AclClassConstants.ACL_DISCOUNT,
      accessIdForAllOpreation: AclConstants.OPERATION_DISCOUNT_ALL,
      operation: 'discount'
    },
    component: DiscountComponent
  },
  {
    path: 'discount/add-discount',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DISCOUNT_ADD,
      classId: AclClassConstants.ACL_DISCOUNT,
      accessIdForAllOpreation: AclConstants.OPERATION_DISCOUNT_ALL,
      operation: 'Add new discount'
    },
    component: AddDiscountComponent
  },
  {
    path: 'discount/add-discount/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DISCOUNT_EDIT,
      classId: AclClassConstants.ACL_DISCOUNT,
      accessIdForAllOpreation: AclConstants.OPERATION_DISCOUNT_ALL,
      operation: 'Edit discount'
    },
    component: AddDiscountComponent
  },
  {
    path: 'packages',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_POSTPAID_PLAN_VIEW,
      classId: AclClassConstants.ACL_PACKAGES,
      accessIdForAllOpreation: AclConstants.OPERATION_POSTPAID_PLAN_ALL,
      operation: 'packages'
    },
    component: PackagesComponent
  },
  {
    path: 'packages/add-packages',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_POSTPAID_PLAN_ADD,
      classId: AclClassConstants.ACL_PACKAGES,
      accessIdForAllOpreation: AclConstants.OPERATION_POSTPAID_PLAN_ALL,
      operation: 'Add new packages'
    },
    component: AddPackagesComponent
  },
  {
    path: 'packages/add-packages/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_POSTPAID_PLAN_EDIT,
      classId: AclClassConstants.ACL_PACKAGES,
      accessIdForAllOpreation: AclConstants.OPERATION_POSTPAID_PLAN_ALL,
      operation: 'Edit packages'
    },
    component: AddPackagesComponent
  },
  {
    path: 'service',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_PLAN_SERVICE_VIEW,
      classId: AclClassConstants.ACL_SERVICE,
      accessIdForAllOpreation: AclConstants.OPERATION_PLAN_SERVICE_ALL,
      operation: 'service'
    },
    component: ServiceComponent
  },
  {
    path: 'service/add-service',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_PLAN_SERVICE_ADD,
      classId: AclClassConstants.ACL_SERVICE,
      accessIdForAllOpreation: AclConstants.OPERATION_PLAN_SERVICE_ALL,
      operation: 'Add new service'
    },
    component: AddServiceComponent
  },
  {
    path: 'service/add-service/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_PLAN_SERVICE_EDIT,
      classId: AclClassConstants.ACL_SERVICE,
      accessIdForAllOpreation: AclConstants.OPERATION_PLAN_SERVICE_ALL,
      operation: 'Edit service'
    },
    component: AddServiceComponent
  },
  {
    path: 'tax',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_TAX_VIEW,
      classId: AclClassConstants.ACL_TAX,
      accessIdForAllOpreation: AclConstants.OPERATION_TAX_ALL,
      operation: 'tax'
    },
    component: TaxComponent
  },
  {
    path: 'tax/add-tax',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_TAX_ADD,
      classId: AclClassConstants.ACL_TAX,
      accessIdForAllOpreation: AclConstants.OPERATION_TAX_ALL,
      operation: 'Add new tax'
    },
    component: AddTaxComponent
  },
  {
    path: 'tax/update-tax/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_TAX_EDIT,
      classId: AclClassConstants.ACL_TAX,
      accessIdForAllOpreation: AclConstants.OPERATION_TAX_ALL,
      operation: 'Edit  tax'
    },
    component: AddTaxComponent
  },
  {
    path: 'qos-policy',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_QOS_POLICY_VIEW,
      classId: AclClassConstants.ACL_QOS_POLICY,
      accessIdForAllOpreation: AclConstants.OPERATION_QOS_POLICY_ALL,
      operation: 'qos-policy'
    },
    component: PolicyComponent
  },
  {
    path: 'qos-policy/add-qos-policy',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_QOS_POLICY_ADD,
      classId: AclClassConstants.ACL_QOS_POLICY,
      accessIdForAllOpreation: AclConstants.OPERATION_QOS_POLICY_ALL,
      operation: 'add new qos-policy'
    },
    component: AddPolicyComponent
  },
  {
    path: 'qos-policy/add-qos-policy/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_QOS_POLICY_EDIT,
      classId: AclClassConstants.ACL_QOS_POLICY,
      accessIdForAllOpreation: AclConstants.OPERATION_QOS_POLICY_ALL,
      operation: 'Edit qos-policy'
    },
    component: AddPolicyComponent
  },
  {
    path: 'price_book',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_PRICE_BOOK_VIEW,
      classId: AclClassConstants.ACL_PRICE_BOOK,
      accessIdForAllOpreation: AclConstants.OPERATION_PRICE_BOOK_ALL,
      operation: 'price book'
    },
    component: PriceBookComponent
  },
  {
    path: 'price_book/price_book_details',
    data: {
      operationId: AclConstants.OPERATION_PRICE_BOOK_ADD,
      classId: AclClassConstants.ACL_PRICE_BOOK,
      accessIdForAllOpreation: AclConstants.OPERATION_PRICE_BOOK_ALL,
      operation: 'Add price book details'
    },
    component: PriceBookDetailsComponent
  },
  {
    path: 'price_book/price_book_details/:id',
    data: {
      operationId: AclConstants.OPERATION_PRICE_BOOK_EDIT,
      classId: AclClassConstants.ACL_PRICE_BOOK,
      accessIdForAllOpreation: AclConstants.OPERATION_PRICE_BOOK_ALL,
      operation: 'Edit price book details'
    },
    component: PriceBookDetailsComponent
  },
  {
    path: 'price_book/price_book_details/add_plan',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_PRICE_BOOK_EDIT,
      classId: AclClassConstants.ACL_PRICE_BOOK,
      operation: 'price book',
      accessIdForAllOpreation: AclConstants.OPERATION_PRICE_BOOK_ALL,
    },
    component: AddPlanComponent
  },
  {
    path: 'value-added-service',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_VAS_VIEW,
      classId: AclClassConstants.ACL_VAS,
      accessIdForAllOpreation: AclConstants.OPERATION_VAS_ALL,
      operation: 'value added ervice'
    },
    component: ValueAddedServiceComponent
  },
  {
    path: 'value-added-service/add',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_VAS_ADD,
      classId: AclClassConstants.ACL_VAS,
      accessIdForAllOpreation: AclConstants.OPERATION_VAS_ALL,
      operation: 'Add value added ervice'
    },
    component: AddValueAddedeServiceComponent
  },
  {
    path: 'value-added-service/add/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_VAS_EDIT,
      classId: AclClassConstants.ACL_VAS,
      accessIdForAllOpreation: AclConstants.OPERATION_VAS_ALL,
      operation: 'Edit value added ervice'
    },
    component: AddValueAddedeServiceComponent
  },
  {
    path:'qos-policy-detail/add/:qosid',
    canActivate: [AppGuard],
    component: AddQosPolicyComponent
  },
  {
    path: 'qos-policy-detail/update/:id',
    component: AddQosPolicyComponent
  },
  {
    path: 'dunningList',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DUNNING_VIEW,
      classId: AclClassConstants.ACL_DUNNING,
      accessIdForAllOpreation: AclConstants.OPERATION_DUNNING_ALL,
      operation: 'View dunning rule'
    },
    component: DunningListComponent

  },
  {
    path: 'dunning/add',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DUNNING_ADD,
      classId: AclClassConstants.ACL_DUNNING,
      accessIdForAllOpreation: AclConstants.OPERATION_DUNNING_ALL,
      operation: 'Add dunning rule'
    },
    component: DunningAddUpdateComponent

  },
  {
    path: 'dunning/add/:id',
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_DUNNING_EDIT,
      classId: AclClassConstants.ACL_DUNNING,
      accessIdForAllOpreation: AclConstants.OPERATION_DUNNING_ALL,
      operation: 'Edit dunning rule'
    },
    component: DunningAddUpdateComponent
  },
];

@NgModule({
  declarations: [
    ChargeComponent,
    DiscountComponent,
    PackagesComponent,
    ServiceComponent,
    TaxComponent,
    AddTaxComponent,
    AddDiscountComponent,
    AddServiceComponent,
    AddChargeComponent,
    AddPackagesComponent,
    AddPolicyComponent,
    PolicyComponent,
    PriceBookComponent,
    PriceBookDetailsComponent,
    AddPlanComponent,
    SearchPipe,
    ValueAddedServiceComponent,
    AddValueAddedeServiceComponent,
    AddQosPolicyComponent,
    QosPolicyDetailComponent,
    DunningListComponent,
    DunningAddUpdateComponent,
    DunningRuleComponent,
    VasProviderDetailsComponent  
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TextMaskModule,
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    WidgetModule,
    BootstrapWizardModule,
    RouterModule.forChild(routes),
    EditorModule,
    JqSparklineModule,
    PaginationModule.forRoot(),
    UtilsModule,
    Ng2TableModule,
    NgxDatatableModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CustomModalModule,
    MatSlideToggleModule,
    MatTableModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule ,
    MatTabsModule,
    MasterFormsModule

  ],
  providers: [{ provide: DateAdapter, useClass: DateFormat },AppGuard],
})
export class PlanManagementModule {
  static routes = routes;
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
    // Date.prototype.getToJSON = function(){
    //     const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
    //     this.setHours(hoursDiff);
    //     return this.toISOString();
    // };
  }
}