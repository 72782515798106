import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const baseApiUrl = '';
@Injectable({
  providedIn: 'root'
})
export class ChargeService {

  constructor(
    private http: HttpClient
  ) { }

  ChargeServivce(): Observable<any> {
    const get_url = baseApiUrl + "charge/all";
    return this.http.get<any>(get_url)
  }
  ChargeCategoryService(): Observable<any> {
    const get_url = baseApiUrl + "commonList/generic/chargeCategory";
    return this.http.get<any>(get_url);
  }
  ChargeTypeService(): Observable<any> {
    const get_url = baseApiUrl + "commonList/generic/chargeType";
    return this.http.get(get_url);
  }
  getPartnerlistService(): Observable<any> {
    const get_url = baseApiUrl + "partner/all";
    return this.http.get<any>(get_url);
  }
  searchChargeReportService(data): Observable<any> {
    const post_url = baseApiUrl + "report/chargeReport";
    return this.http.post<any>(post_url, data);
  }
  excelServiceData(data) {
    const post_url = baseApiUrl + "report/chargeReport/excel";
    return this.http.post<any>(post_url, data, { observe: 'body', responseType: 'blob' as 'json' });
  }
  getUserdetailByUserName(username):Observable<any>{
    const get_url=baseApiUrl+'customers/byUserName/'+username;
    return this.http.get<any>(get_url);
  }

}
