import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import _ from 'underscore';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class BillingTemplateService {
  constructor(private http: HttpClient) {

  }

  transformBillingTemplateData(data) {
    let formatedData = [];
    if (_.isArray(data.billRunlist) === false) {
      let itemData: any = {};
      itemData.billing_id = data.billRunlist.id;
      itemData.templatename = data.billRunlist.templatename;
      itemData.templatetype = data.billRunlist.templatetype;
      itemData.status = data.billRunlist.status;
      itemData.invoiceNumFormat=data.invoiceNumFormat;
      itemData.jrxmlfile = data.billRunlist.jrxmlfile;
      return { content: itemData };
    } else {
      data.billRunlist.forEach(function (item: any) {
        let itemData: any = {};
        itemData.billing_id = item.id;
        itemData.templatename = item.templatename;
        itemData.templatetype = item.templatetype;
        itemData.status = item.status;
        itemData.invoiceNumFormat=data.invoiceNumFormat;
        itemData.associatePartnerCount=item.associatePartnerCount;
        itemData.associatePartnerList=item.associatePartnerList;
        itemData.jrxmlfile = item.jrxmlfile;
        formatedData.push(itemData);
      });
      return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
    }
  }

  getBillingTemplateDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'billTemplete/list';

    return this.http.post<any>(post_url, params, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return this.transformBillingTemplateData(res);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  transformDataById(data){
    let formatedData:any={};
    let datas=data.billRunlist;
    formatedData.templatename=datas.templatename;
    formatedData.templatetype=datas.templatetype;
    formatedData.status=datas.status;
    formatedData.jrxmlfile=datas.jrxmlfile;
    formatedData.invoiceNumFormat=datas.invoiceNumFormat;
    return formatedData;
  }


  getBillingTemplateData(billing_id: any): Observable<any> {
    const get_url = BASE_API_URL + 'billTemplete/' + billing_id;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return this.transformDataById(res);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  insertBillingTemplate(billing_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'billTemplete';
    return this.http.post<any>(post_url, billing_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );

  }

  updateBillingTemplate(billing_data: any, billing_id: any): Observable<any> {
    const update_url = BASE_API_URL + "billTemplete/" + billing_id;
    return this.http.put<any>(update_url, billing_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteBillingTemplate(billing_id: any): Observable<any> {
    const delete_url = BASE_API_URL + "billTemplete/" + billing_id;
    return this.http.delete(delete_url, httpOptions);
  }
  commonStatus(): Observable<any> {
    const get_url = BASE_API_URL + "commonList/commonStatus";
    return this.http.get(get_url);
  }
  commomTemplatetype():Observable<any>{
    const get_url=BASE_API_URL+"commonList/generic/templateType";
    return this.http.get<any>(get_url); 
  }

}
