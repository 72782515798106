

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityName, isEntityName } from 'typescript';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { IBaseService } from '../../../../services/ibase-service';
import { PinAreaAddUpdateComponent } from '../../../masters/pin-area/pin-area-add-update/pin-area-add-update.component';
import { ActivePlanComponent } from '../../../masters/subscriber-details/subscriber-tables/active-plan/active-plan.component';
import { PackagesComponent } from '../../packages/packages.component';
import { TaxService } from '../../tax/tax.service';
import { ValueAddedeServiceModel } from '../model/ValueAddedeService';
import { ValueAddedeServiceService } from '../service/value-addede.service';

@Component({
  selector: 'app-add-value-addede-service',
  templateUrl: './add-value-addede-service.component.html',
  styleUrls: ['./add-value-addede-service.component.scss']
})
export class AddValueAddedeServiceComponent extends AbstractBaseAddUpdateComponent<ValueAddedeServiceModel> implements OnInit {

  @ViewChild(PackagesComponent, { static: false }) packagesPlan: PackagesComponent;
  public entity: ValueAddedeServiceModel = new ValueAddedeServiceModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
  commonStatus: any;
  commonYN
  loginService
  type: any;
  saveTimeId:number
  public taxList: any[] = [{
    tax_name: '',
    tax_id: ''
  }];
  public validityTypeList
  isBasePlanList:boolean = true;
  isOttServiceList:boolean = true;
  basePlanList:any;
  basePlanListDDL = 
  [
  { 'name': 'Yes', 'value': true },
  { 'name': 'No', 'value': false },
  ]
  ottServiceType: any;
  statusList: any

  constructor(
    private ValueAddedSevice: ValueAddedeServiceService,
    public CommonStatus: CommonStatusService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private taxService: TaxService,
    private genericDataSharingService: ValueAddedeServiceService,
    private commonservice: CommonListServicesService,
    ) {
    super(toastrService, router);
    localStorage.setItem('tabSelectionIndex','1');
  }
  ngOnInit() {
    this.setValidation();

    this.init();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
    this.getStatusList();
    this.getTypeList();
    this.getTaxList();
    this.getvalidityType();
    this.ValueAddedSevice.getBaseByTypeAndIsBase('IPTV').subscribe(result => {
      this.basePlanList = result.dataList;
    })
    this.ValueAddedSevice.getOTTServiceTypeList().subscribe(result => {
      this.ottServiceType = result.dataList;
    })
  }
  
  getStatusList(){
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.statusList = res.dataList;
    });
  }

  getTaxList(tax_id?: any) {
    this.taxService.getTaxList().subscribe(result => {
      this.taxList = result.content;
      if (tax_id) {
        this.form.patchValue({ tax_id: tax_id });
      }
    });
  }

  getvalidityType() {
    this.commonservice.getGenericCommonList('validityType').subscribe((res) => {
      this.validityTypeList = res.dataList
    });
  }

  setValidation() {
    this.form = new FormGroup({
    vas_name: new FormControl(null, [Validators.required]),
    vas_status:new FormControl(null, [Validators.required]),
    vas_Type:new FormControl(null, [Validators.required]),
    offerPrice:new FormControl(null, [Validators.required]),
    taxId:new FormControl(null),
    validity:new FormControl(null, [Validators.required, Validators.max(1000)]),
    isIncludeTax :new FormControl(false),

    isCustomerAllowed  :new FormControl(false),
    partnerPrice:new FormControl(null, [Validators.required]),
    vas_OTT_Type:new FormControl(null),
    select_plan:new FormControl(null),
    is_base:new FormControl(true),
    description:new FormControl(null)
    });
    console.log(this.form);
  }
  
    getService(): IBaseService<ValueAddedeServiceModel> {
      return this.ValueAddedSevice;
    }
   
    setData(data: ValueAddedeServiceModel) {
      this.entity = data;
      this.isBasePlanChanges();
      if (this.entity.isIncludeTax === true) {
        this.entity.offerpriceintax = this.entity.offerPrice + this.entity.taxAmount;
        this.entity.offerPrice += this.entity.taxAmount;
        this.entity.partnerPriceInTax = this.entity.partnerPrice + this.entity.partnerTaxPrice;
        this.entity.partnerPrice += this.entity.partnerTaxPrice;
        
      }
  
      if (this.entity.isIncludeTax === false) {
        this.entity.offerpriceintax = this.entity.offerPrice;
        this.entity.partnerPriceInTax = this.entity.partnerPrice;
      }
    }
    
    getData(): ValueAddedeServiceModel {
      return this.entity;
    }
  
    public getModuleHomeUrl(): string {
      if(!this.isUpdateComponent && this.saveTimeId){
        this.url = 'plan-management/value-added-service/add/'+ this.saveTimeId
      }
      else{
        this.url = 'plan-management/packages'
      }
      return this.url;
    }
  
    public getActiveRoute(): ActivatedRoute {
      return this.activeRoute;
    }
    public getNewModal(): ValueAddedeServiceModel {
      return new ValueAddedeServiceModel();
    }
  
    public getFormGroup(): FormGroup {
      return this.form;
    }
  
    public getId(): number {
      return this.entity.id;
    }
  
    get f() {
      return this.form.controls;
    }
  

    getGenericDataService(){
      return this.genericDataSharingService
    }
    
    checkIncludingTax(event)
    {
      this.entity.isIncludeTax = event.target.checked;
    }

    checkIsCustomerAllowed(event)
    {
      this.entity.isCustomerAllowed = event.target.checked;
    }

    formSubmit(rowData) {
      let self = this;
      console.log("EVENT", self)
      if (this.validateForm()) {
        if(!this.isUpdateComponent){
          this.getService().save(this.entity).subscribe((res)=>{
            if (res.responseCode !== 200) {
              this.toastrService.error(res.responseMessage)
            }
            else {
              this.saveTimeId = res.data.id;
              this.onSaveSuccess();
            }
          }),
          (error) => {
            this.onResponseError(error.message);
          }
        }
        else{
          this.getService().update(this.entity).subscribe((res)=>{
            if (res.responseCode !== 200) {
              this.toastrService.error(res.responseMessage)
            }
            else {
              this.saveTimeId = res.data.id;
              this.onUpdateSuccess();
            }
          }),
          (error) => {
            this.onResponseError(error.message);
          } 
        }
      //  this.router.navigate(['plan-management/value-added-service/add',rowData.id]);
      }
      else
      {
        this.toastrService.error("Please fill all the details!");
      }
      //this.packagesPlan.selectVAStab(1);
      // this.router.navigate(['plan-management/value-added-service/add',rowData.id]);
    }
 
    backToViewList() {
      this.router.navigate(['plan-management/packages']);
    }
    getTypeList() {
      this.ValueAddedSevice.getTypeList().subscribe(result => {
        this.type = result.dataList;
      })
    }

    isBasePlanChanges(){
      if(this.entity.type == 'IPTV' ){
        this.form.get('validity').disable();
      }
      if(!this.entity.isBase)
      {
        this.form.get('select_plan').setValidators(Validators.required);
        this.form.get('select_plan').updateValueAndValidity();  
      }
      else
      {
        this.form.get('select_plan').clearValidators();
        this.form.get('select_plan').updateValueAndValidity();
      }
    }
    
    isBasePlanChange(){
      this.form.get('validity').reset();
      this.form.get('validity').enable();
      this.form.get('select_plan').reset();
      if(!this.entity.isBase)
      {
        this.form.get('select_plan').setValidators(Validators.required);
        this.form.get('select_plan').updateValueAndValidity();  
      }
      else
      {
        this.form.get('select_plan').clearValidators();
        this.form.get('select_plan').updateValueAndValidity();
      }
    }

    typeChnage(data)
    {
      // this.form.get('vas_OTT_Type').clearValidators();
      // this.form.get('vas_OTT_Type').updateValueAndValidity();
      this.form.get('select_plan').reset();
      this.form.get('select_plan').clearValidators();
      this.form.get('select_plan').updateValueAndValidity();
      this.form.get('validity').reset();
      this.form.get('validity').enable();

      this.entity.commonListImageIds = null;
      this.isOttServiceList = data.value
      if(data.value == "IPTV")
      {
        this.form.get('is_base').setValidators(Validators.required);
        this.form.get('is_base').updateValueAndValidity();  
      }
      else
      {
        this.form.get('is_base').clearValidators();
        this.form.get('is_base').updateValueAndValidity();
      }
    }

    numberOnly(event): boolean {

      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
  
      return true;
    }

    onChangeSPlan(event){
      this.entity.validity = event.validity
      this.form.get('validity').disable(); 
    }

  // errorMessage: string = "This Field is Required";
  // serviceId: any;
  // show_update: boolean = false;
  // isdisabled:boolean=false;
  // statusList: any;
  // typeList: any;
  // isDisabled:boolean=false;
  // toaster: any;

  // constructor(
  //   private valueAddedService: ValueAddedeService,
  //   private router: Router,
  //   private route: ActivatedRoute,
  //   private toastrService: ToastrService,
  //   private sharingdataService: ValueAddedeService,
  //   private CommonStatus: CommonStatusService,

  // ) {
  //   super(toastrService, router);
  // }

  // valueAddedServicesForm = new FormGroup({
  //   id: new FormControl(''),
  //   name: new FormControl('', Validators.required),
  //   type: new FormControl('', Validators.required),
  //   value: new FormControl('', Validators.required),
  //   status: new FormControl('', Validators.required),
  // })

  // ngOnInit() {
  //   let id = this.route.snapshot.paramMap.get('id');
  //   if (id) {
  //     this.serviceId = id;
  //     this.show_update = true;
  //     this.isDisabled=false;
  //     this.getDataValueAddedeServicesById();
  //   }
  //   // if(this.data && this.data.id){
  //   //   this.isDisabled=true;
  //   //   this.serviceId = this.data.id;
      
  //   //  // this.valueAddedServicesForm.disable();
  //   //   this.show_update = true;
  //   //   this.getDataValueAddedeServicesById();
  //   //   this.valueAddedServicesForm.disable();
  //   // }
  //   this.getTypeList();
  //   this.commonStatus();
  // }
  // getTypeList() {
  //   this.valueAddedService.getTypeList().subscribe(result => {
  //     this.typeList = result.dataList;
  //   })
  // }
  // commonStatus() {
  //   this.valueAddedService.commonStatus().subscribe(result => {
  //     this.statusList = result.dataList;
  //   })
  // }
  // validateForm() {
  //   if (!this.valueAddedServicesForm.valid) {
  //     Object.keys(this.valueAddedServicesForm.controls).forEach(field => {
  //       let control = this.valueAddedServicesForm.get(field);
  //       if (control instanceof FormControl) {
  //         control.markAsTouched({ onlySelf: true });
  //       }
  //     })
  //   } else {
  //     if (this.serviceId != null) {
  //       this.UpdateValueAddedService();
  //     } else {
  //       this.insertValueAddedService();
  //     }

  //   }
  // }

  // insertValueAddedService() {
  //   if (this.valueAddedServicesForm.valid) {
  //     let apiInputData = this.transformApiInputData(this.valueAddedServicesForm.value);
  //     this.valueAddedService.saveValueAddedService(apiInputData).subscribe(result => {
  //       if (result.responseCode != 200) {
  //         this.toaster.error(result.responseMessage);
  //       } else {
  //         this.toaster.success("Data has been Successfully added");
  //         this.backtoValueAddedSrvices();
  //       }
  //     })
  //   }
  // }
  // UpdateValueAddedService() {
  //   if (this.valueAddedServicesForm.valid) {
  //     let data = this.valueAddedServicesForm.value;
  //     let apiInputData = this.transformUpdateFormData(data);
  //     this.valueAddedService.UpdateValueAddedService(apiInputData).subscribe(result => {
  //       if (result.responseCode != 200) {
  //         this.toaster.error(result.responseMessage);
  //       } else {
  //         this.toaster.success("Data has been Successfully Updated");
  //         this.backtoValueAddedSrvices();
  //       }
  //     })
  //   }
  // }
  // transformApiInputData(data) {
  //   let formatedData: any = {};
  //   formatedData.name = data.name;
  //   formatedData.type = data.type;
  //   formatedData.status = data.status;
  //   formatedData.value = data.value;
  //   return formatedData;
  // }
  // transformUpdateFormData(data) {
  //   let formatedData: any = {};
  //   formatedData.id = data.id;
  //   formatedData.name = data.name;
  //   formatedData.type = data.type;
  //   formatedData.status = data.status;
  //   formatedData.value = data.value;
  //   return formatedData;
  // }
  // @HostListener('window:keydown', ['$event'])
  // firstCharacterSpaceNotAllowed(ctrlValue:any){
  //   if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
  //     ctrlValue.preventDefault();
  //    }
  // }
  // getDataValueAddedeServicesById() {
  //   this.valueAddedService.findValueAddedServiceById(this.serviceId).subscribe(result => {
  //     let formatedData: any = {};
  //     if (result && result.data) {
  //       let data = result.data;
  //       formatedData.id = data.id;
  //       formatedData.name = data.name;
  //       formatedData.type = data.type;
  //       formatedData.status = data.status;
  //       formatedData.value = data.value;
  //     }
  //     this.valueAddedServicesForm.patchValue(formatedData);
  //   });
  // }
  // backtoValueAddedSrvices() {
  //   //this.router.navigate(['plan-management/value-added-service']);
  //   if(this.sharingdataService.lastAuditUrl == this.router.url){
  //     this.router.navigate(['/operations/audit']);
  //     this.sharingdataService.lastAuditUrl = undefined;
  //   }else{
  //     this.router.navigate(['plan-management/value-added-service']);
  //   }
  // }
  // closeDialog(){
  //   this.dialogRef.close();
  // }
}
