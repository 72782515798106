import { } from 'googlemaps';
import { Component, OnInit } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { classicNameResolver } from 'typescript';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from '../staff.service';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { LoginService } from '../../../login/login.service';
import { SearchService } from '../../../../services/search.service';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import * as moment from 'moment';
const  directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer();
@Component({
  selector: 'app-geo-location-staff',
  templateUrl: './geo-location-staff.component.html',
  styleUrls: ['./geo-location-staff.component.scss']
})

export class GeoLocationStaffComponent extends AbstractBaseListComponent <response> implements OnInit {
  public loginService: LoginService;
  dataGrid = new Array<response>();
  data = new staffGeo()
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  Coordinates:any = [];
  tableKeyEnum = TableKeyEnum;
  timeRangeList = TimeRange;
  active: boolean = false;
  constructor( private router: Router,
    private route: ActivatedRoute,
   public datePipe:DatePipe,
   private searchService: SearchService,
   private toastrService: ToastrService,
   private staffservice:StaffService,
   loginService: LoginService) { 
    super(toastrService,searchService);
    this.loginService = loginService;
   }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.GEO_LOCATION_STAFF)
    let id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.data.staffId =  id;
    }
    this.init();
    //this.initMap();
  }

  getService(): IBaseService<any> {
    return null
  }

  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'latitude',
        default: 'Latitude',
        rowKey: 'latitude',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'longitude',
        default: 'Longitude',
        rowKey: 'longitude',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'createdate',
        default: 'Create Date',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    ];
    this.allColumns = allColumns;
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return allColumns ? allColumns : [];
  }

  getTableSetting() {
    let geolocationstaff = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter:false,
      showPagination: true,
      tableId: this.tableKeyEnum.GEO_LOCATION_STAFF,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Service Area ?',
      deleteMsgBoxDetail: 'Service Area Name :${name} ',
    };
    return geolocationstaff;
  }

  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }

  onDeleteClick(event) {
    if (event) {
      this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
    }
 }

 afterDeleteConfirm(objEvent) {
   if (objEvent.deleteClicked) {
     this.delete(objEvent.event);
   }
 }

 setList(list: Array<any>) {

 }
 onEditClick(event) {
  ////console.log(event);
  
}
resetForm(){
  this.data = new staffGeo();
  this.data.staffId = this.route.snapshot.paramMap.get('id');
  this.refreshDataSource([]);
  this.Coordinates = [];
}

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onDataSubmit(paginationData)
  
  }


  onBackToStaff(){
    this.router.navigate(['hr/staff']);
  }

  onDataSubmit(pagination?){

      if(!this.data.startDate){
      return this.toastrService.error('Please Select Date');
      }
      
      if(this.data.startDate && !this.data.timeRange && !this.data.tableViewFlag)
      return this.toastrService.error('Please Select Time Range');

      if(!this.data.tableViewFlag && this.data.timeRange != 60 && (!this.data.startTimeTemp || !this.data.endTimeTemp))
      return this.toastrService.error('Please Select From Or To Time');

      if(!this.data.tableViewFlag){
      if(this.dateTimeValidationHourWise()){
        return this.toastrService.error(this.dateTimeValidationHourWise());
      } 
      }
      
      if(this.data.timeRange != 60){
        this.data.startTime = moment(this.data.startTimeTemp).format('HH:mm');
        this.data.endTime = moment(this.data.endTimeTemp).format('HH:mm');
      }else{
        this.data.startTimeTemp = undefined;
        this.data.endTimeTemp = undefined;
      }
      

      this.data.startDate  =  this.datePipe.transform(this.data.startDate,'yyy-MM-ddTHH:mm:ss');
      if(this.data.tableViewFlag){
       
        let param 
        param = Object.assign({},this.data)

        param['page'] = pagination?pagination.pageNumber.toString():'1'
        param['pageSize'] =pagination?pagination.rowsPerPage.toString():'10'
        param['sortBy'] = 'createdate'
        param['sortOrder'] = 0

        this.staffservice.getGeoLoacationStaffId(param).subscribe((res)=>{
          if(res.responseCode == 200){
            this.dataGrid = res.dataList;
            this.refreshDataSource(this.dataGrid)
            this.neoTableSetting.pageTotalLength = res.totalRecords;
          }
          else{
            this.toastrService.error(res.responseMessage)
          }
          })
    
      }
      else{
        this.active = true;
        this.staffservice.getGeoLoacationStaffId(this.data).subscribe((res)=>{
          this.active = false;
          if(res.responseCode == 200){
            this.Coordinates = []
            let obj = { lat: 0, 
              lng: 0 }
              if(res.data.locationDTOList && res.data.locationDTOList.length > 0){
                
                res.data.locationDTOList.forEach((ls)=>{
                  obj =  { lat: 0, 
                    lng: 0 }
                  obj.lat = Number(ls.latitude)
                  obj.lng = Number(ls.longitude)
                  this.Coordinates.push(obj)
                }
                );
                setTimeout(() => {
                  this.initMap();
                },1000);
              }else{
                this.toastrService.error('No data found!')
              }
          }
          else{
            this.toastrService.error(res.responseMessage)
          }
         
        })
        // Map Code
      }
  }

  dateTimeValidationHourWise(){
    let isValid = true;
    let errorMessage: string;
    switch(this.data.timeRange){
      case 60:
        break;
      case 45:
        if(this.diff_hours(this.data.startTimeTemp, this.data.endTimeTemp) > 18){
          isValid = false;
          errorMessage = "Diffrence Between Time Can't More Then 18 Hours"
        }
        break;
      case 30:
        if(this.diff_hours(this.data.startTimeTemp, this.data.endTimeTemp) > 12){
          isValid = false;
          errorMessage = "Diffrence Between Time Can't More Then 12 Hours"
        }
        break;
      case 15:
        if(this.diff_hours(this.data.startTimeTemp, this.data.endTimeTemp) > 6){
          isValid = false;
          errorMessage = "Diffrence Between Time Can't More Then 6 Hours"
        }
        break;
      case 10:
        if(this.diff_hours(this.data.startTimeTemp, this.data.endTimeTemp) > 4){
          isValid = false;
          errorMessage = "Diffrence Between Time Can't More Then 4 Hours"
        }
        break;
      case 5:
        if(this.diff_hours(this.data.startTimeTemp, this.data.endTimeTemp) > 2){
          isValid = false;
          errorMessage = "Diffrence Between Time Can't More Then 2 Hours"
        }         
        break;  
    }
    if(!isValid){
      return errorMessage
    }else{  
      return undefined
    }
  }

  diff_hours(dt2, dt1) 
  {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    let abs = Math.abs(diff);
    abs = Number(abs.toFixed(2));

    
    return abs
  }

  initMap(): void {
   
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 7,
        center: { lat: 15.2993, lng: 74.1240 },
      }
    );

    directionsRenderer.setMap(map);
    let source = {
      lat: this.Coordinates[0].lat,
      lng: this.Coordinates[0].lng
    };
    
    this.Coordinates.shift();
    var lastIndex = this.Coordinates.pop();
    let destination = {
      lat: lastIndex.lat,
      lng: lastIndex.lng
    };
    var waypts = [];
    this.Coordinates.forEach((ls) => {
      if(ls.lat && ls.lng){
        let wayObj = {
          location: { lat: ls.lat, lng: ls.lng },
          stopover: false
        }
        waypts.push(wayObj);
      }
    });

    
    // waypts.push({
    //   location: { lat: 23.04025807203509, lng: 72.53146053889314 },
    //   stopover: false
    // }, {
    //   location: { lat: 23.063923782682632, lng: 72.53126093889365 },
    //   stopover: false
    // });

    calculateAndDisplayRoute(source, destination, waypts);
  }

  isTableView(event){
    if(event.index == 0){
      this.data.tableViewFlag = false
    }
    if(event.index == 1){
      this.data.tableViewFlag = true
    }  
    this.onDataSubmit()
  }
  reset(){
    this.data.endDate = null
    this.data.startDate = null
  }
}

function calculateAndDisplayRoute(source, destination, wayPoints
) {
  directionsService
    .route({
      origin: source,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: wayPoints
    },voidTemp);
}

function voidTemp(result,status){
  if(status == 'OK'){
    directionsRenderer.setDirections(result);
  }else{
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 7,
        center: { lat: 15.2993, lng: 74.1240 },
      }
    );
    window.alert(status)
  }
}

export class staffGeo{
  startDate:any
  timeRange: number;
  endDate:any 
  staffId:any
  tableViewFlag:boolean = false;
  startTime:any;
  endTime: any;
  startTimeTemp:any;
  endTimeTemp: any;
}
export class response {

    entity: any;
    entity_id: any
    latitude:any
    longitude: any
    createdate: any
  
 }

export const TimeRange = [
  {key:5,value:'5 Minutes'},
  {key:10,value:'10 Minutes'},
  {key:15,value:'15 Minutes'},
  {key:30,value:'30 Minutes'},
  {key:45,value:'45 Minutes'},
  {key:60,value:'60 Minutes'}
]
