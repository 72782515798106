import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationService } from './notification.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import {OldAbstractBaseListComponent} from '../../../components/base/old-abstract-base-list-component';
import {TableKeyEnum} from '../../../core/enum/table-key.enum';
import {MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {BillingTemplateService} from '@neoboss/pages/operations/billing_template/billing_template.service';
import {SearchService} from '../../../services/search.service';
import { HostListener } from '@angular/core';
import {SearchControlTypeEnum, SearchDataTypeEnum} from '../../../core/enum/searchType.enum';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;

  NotificationDataForGrid:any = {content:[]};
  NotificationGridSettings: any = {};
  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  NotificationNeoGridSettings: any  = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredNotificationDataForGrid: any = [];

  constructor(
      private router: Router,
      private sanitizer: DomSanitizer,
      private notificationService : NotificationService,
      private searchService: SearchService
  ) {super(searchService); }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.BILLINGTEMPLATE_LIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }



  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.NotificationDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.NotificationDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onEdit(event) {
    this.setUpdateValueInForm(event.RowData);
  }

  onDelete(event) {
    let self = this;
    if (event.deletClicked) {
      self.delete(event.RowData);
    }
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status.equalsIgnoreCase() === 'Active') {
            return 'Active';
          } else {
            return 'Inactive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: true,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Template Name',
        value_field: 'templatename',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Template Type',
        value_field: 'templatetype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'Active',
            value_field: 'Y',
          },
          {
            display_field: 'In Active',
            value_field: 'N',
          },
        ],
      },
    ];

    this.NotificationNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.BILLINGTEMPLATE_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Template?',
      deleteMsgBoxDetail: 'Template Name :${templatename} ',
    };

    if (this.NotificationNeoGridSettings.serverSideSortPagination && this.NotificationNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.NotificationNeoGridSettings.perPage);
    }
  }

  getNotificationData(gridSetting : any) {
    let fetchSettingData = this.NotificationNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    // this.notificationService.getNotificationDataWithPageing(params).subscribe(result => {
    //   ////console.log('result =>', result);
    //   this.NotificationDataForGrid = result.dataList;
    //   this.refreshDataSource(this.NotificationDataForGrid);
    //   this.totalRecords = result.totalRecords;
    //   this.NotificationNeoGridSettings.pageTotalLength = this.totalRecords;
    
    // });
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getNotificationData(config);
  }

  delete(selected_billing_template_data) {
    let self = this;
 /*   this.billingTemplateService.deleteBillingTemplate(selected_billing_template_data.billing_id).subscribe((result) => {
          alertify.success('Billing Template is deleted successfully');
          self.NotificationDataForGrid.content.forEach(function(item, index) {
            if (item.billing_id === selected_billing_template_data.billing_id) {
              self.NotificationDataForGrid.content.splice(index, 1);
            }
          });
          self.refreshDataSource(self.NotificationDataForGrid.content);
        },
        err => {
          alertify.error(err.error.status + " " + err.error.ERROR);
        });*/
  }

  setUpdateValueInForm(rowData) {
    ////console.log('rowData=>',rowData);
    this.router.navigate(['operations/notification/add-notification',rowData.id]);
  }

  onAddNotificationClick() {
    this.router.navigate(['operations/notification/add-notification']);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getNotificationData(config);
      }
}
