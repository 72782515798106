import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../../services/ibase-service';
import { Team } from '../model/Team';
import { ToastrService } from 'ngx-toastr';
import { TeamServiceService } from '../team-service.service';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { SearchControlTypeEnum } from '../../../../core/enum/searchType.enum';
import { SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SearchService } from '../../../../services/search.service';
import { Subscription } from 'rxjs';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { OrderingData } from '../../../../core/modal/ordering-data';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent extends AbstractBaseListComponent<Team> implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('teamName', { static: false }) teamnameReff: TemplateRef<any>;
  @ViewChild('status', { static: false }) statusRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  allColumns:any=[];
  searchSettings = [];
  neoTableSetting:any={};
  dataSource = new MatTableDataSource([]);
  dataGrid: Team[] = [];
  subscriptions: Subscription[] = [];
  constructor(
    public toastService: ToastrService,
    private teamService: TeamServiceService,
    private searchService: SearchService,
    private router: Router,
    loginService: LoginService
  ) {
    super(toastService, searchService);
    this.loginService = loginService;
  }

  ngOnInit() {
    this.setDefaultPageSize(TableKeyEnum.Teams)
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
    ////console.log(this.subscriptions);
  }

  getService(): IBaseService<Team> {
    return this.teamService;
  }
  setList(list: Array<Team>) {
    // ////console.log("list",list);
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'groupName',
        default: 'Group Name',
        rowKey: 'groupName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: TableKeyEnum.Teams,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Payments ?',
      deleteMsgBoxDetail: 'Teams  Name :${name} ',
    };
    return this.neoTableSetting;
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.teamnameReff];
        } 
        if (col.colKey == 'status') {
          col.customControls = [this.statusRef];
        }
      });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }


  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  onEditClick(event) {
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }
  // onEditClick(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }
  setUpdateValueInForm(rowData) {
    this.router.navigate(['/hr/add-team',rowData.id]);
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  onAddClick() {
    this.router.navigate(['/hr/add-team']);
  }
}
