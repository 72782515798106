import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AreaService } from './area.service';
import * as alertify from 'alertifyjs';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../services/search.service';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
})
export class AreaComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;

  areaDataForGrid: any = { content: [] };
  areaGridSettings: any = {};

  // config for neo-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  areaNeoGridSettings = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredAreaDataForGrid: any = [];

  constructor(
    private areaService: AreaService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setGridSetting();
    this.getAreaData({});
    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'area_status') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }

  getAreaData(gridSetting: any) {
    let fetchSettingData = { page: gridSetting.page, size: gridSetting.perPage };
    this.areaService.getAreaDataWithPageing(fetchSettingData).subscribe((result) => {
      this.areaDataForGrid = result;
      this.refreshDataSource(this.areaDataForGrid.content);
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.areaDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.areaDataForGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'area_name',
        default: 'Area Name',
        rowKey: 'area_name',
        width: '40%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'area_status',
        default: 'STATUS',
        rowKey: 'area_status',
        width: '40%',
        customControls: [this.statusWrapperRef],
        customValue:(status) => {
          if(status === 'Y'){
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '5%',
        isStickyEnd: true,
      },
    ];

    this.searchSettings = [
      {
        display_field: 'Area Name',
        value_field: 'area_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'area_status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
    ];

    this.areaNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.AREA_LIST,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Area ?',
      deleteMsgBoxDetail: 'Area Name :${area_name} ',
    };

    this.areaGridSettings = {
      perPage: 5,
      showSearchBox: false,
      showPageLenghtChange: false,
      columnVisibilityForAllColumn: false,
      columns: [
        { title: 'Id', data: '', visible: true, customColumnType: 'sequence_column' },
        { title: 'Area Name', data: 'area_name', visible: true },
        {
          title: 'Status',
          data: 'area_status',
          visible: true,
          render: function (areaData, column_name, rowIndex) {
            let value;
            if (areaData.area_status == 'Y') {
              value = '<span class="badge badge-success">True</span>';
            } else {
              value = '<span class="badge badge-secondary">False</span>';
            }
            return self.sanitizer.bypassSecurityTrustHtml(value);
          },
        },
        {
          title: 'Action',
          data: '',
          sortable: false,
          visible: true,
          customColumnType: 'editAndDeleteButton',
          deleteButtonWithConfirmMessage: 1,
          deleteMsgBoxTitle: 'Are you sure to delete this Franchies ?',
          deleteMsgBoxDetail: 'Franchies Name :${area_name} ',
          onEditClick: function (event, column, rowData, i) {
            self.setUpdateValueInForm(rowData);
          },
          deleteYes: function (event, column, rowData, i) {
            self.delete(rowData);
          },
        },
      ],
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onEdit(event) {
    this.setUpdateValueInForm(event.RowData);
  }

  onDelete(event) {
    let self = this;
    if (event.deletClicked) {
      self.delete(event.RowData);
    }
  }

  delete(selected_area_data) {
    let self = this;
    this.areaService.deleteArea(selected_area_data.area_id).subscribe((result) => {
      alertify.success('Area is deleted successfully');
      self.areaDataForGrid.content.forEach(function (item, index) {
        if (item.area_id === selected_area_data.area_id) {
          self.areaDataForGrid.content.splice(index, 1);
        }
      });
      self.refreshDataSource(self.areaDataForGrid.content);
    },err => {
      alertify.error(err.error.status + " " + err.error.ERROR);
    });
  }

  onAddAreaClick() {
    this.router.navigate(['/masters/area/add-area']);
  }

  refreshPage() {
    this.router.navigate(['/masters/area']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/area/add-area'], { queryParams: { id: rowData.area_id } });
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
