import { Component, OnInit,Input } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'underscore';


@Component({
  selector: 'app-add-mactal',
  templateUrl: './add-mactal.component.html',
  styleUrls: ['./add-mactal.component.scss']
})
export class AddMactalComponent implements OnInit {
  mactalList:any = [];
  req_message = "This field is required.";
  submitted: any = null;
  isInvalidMacMsg:any = false;
  addMactalForm = new FormGroup({
      mactal: new FormControl('', [Validators.required])
  });

  constructor(
    private customModalService: CustomModalService,
  ) { }

  ngOnInit() {
  }

  setFocusOnMacText(){
    let el = document.getElementById('mactal');
    setTimeout(()=>{
      if(el)
      el.focus();
    });
  }

  onKeyUpMacAddress(el) {
    let value = el.target.value;
    if (value) {
      let regexp = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
      let mac_address = value;
      if (regexp.test(mac_address)) {
        this.isInvalidMacMsg = false;
      } else {
        this.isInvalidMacMsg = true;
      }
    } else {
      this.isInvalidMacMsg = false;
    }
  }

  onClickAddMactal() {
    this.submitted = true;
    if (this.addMactalForm.valid === true) {
      let apiInputData = Object.assign({}, this.addMactalForm.value);
      let regexp = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
      let mac_address = apiInputData.mactal;
      if (regexp.test(mac_address)) {
        this.isInvalidMacMsg = false;
        this.mactalList.push({macAddress:apiInputData.mactal});
        this.addMactalForm.reset();
        this.setFocusOnMacText();
        this.submitted = false;
      } else {
        this.isInvalidMacMsg = true;
      }
    } else {
      this.addMactalForm.markAllAsTouched();
    }
  }

  onClickSaveAndCloseMactalForm() {
    this.submitted = true;
    if (this.addMactalForm.valid === true) {
      let apiInputData = Object.assign({}, this.addMactalForm.value);
      this.mactalList.push({macAddress:apiInputData.mactal});
      this.customModalService.close('add-mactal',this.mactalList);
      this.addMactalForm.reset();
      this.submitted = false;
    } else {
      this.addMactalForm.markAllAsTouched();
    }
  }

  deleteSelectedMactalItem(index:number) {
    this.mactalList.splice(index,1);
  }

  getMactalListData() {
    return this.mactalList;
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
  }
}
