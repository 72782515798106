import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AclSave } from '../../generic-component/acl/acl-gerneric-component/model/acl-save';
import { BaseService } from '../../../services/base-service';
import { Acl } from '../../generic-component/acl/acl-gerneric-component/model/acl';
import { ResponseData } from '../../../core/modal/base-save-update-response';


const baseApiUrl = '';
const serviceApiUrl = 'acl';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class AclService  extends BaseService<AclSave> {
  constructor(http: HttpClient) {
    super(http);
  }
  getModuleName(): string {
    return 'role';
  }
  validate(): boolean {
    return false;
  }

  public getAllOperation(): Observable<ResponseData<Acl>> {
    const get_url = baseApiUrl + serviceApiUrl + '/getModuleOperations';
    return this.http.get<ResponseData<Acl>>(get_url, { headers: httpOptions.headers });
  }
}
