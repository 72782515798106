import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { IBaseService } from '../../../services/ibase-service';
import { vasprovidermodel } from './model/vasprovidermodel';
import * as alertify from 'alertifyjs';
import { PaginationData } from '../../../core/modal/pagination-data';
import { VasproviderserviceService } from './service/vasproviderservice.service';
import { SearchService } from '../../../services/search.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderingData } from '../../../core/modal/ordering-data';
import { SearchData } from '../../../core/modal/search-data';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vas-provider-details',
  templateUrl: './vas-provider-details.component.html',
  styleUrls: ['./vas-provider-details.component.scss']
})
export class VasProviderDetailsComponent extends AbstractBaseListComponent<vasprovidermodel> implements OnInit {
  @ViewChild('actionBtns', { static: false }) actionBtnsRef: TemplateRef<any>;
  @ViewChild('isassignable',{ static: false }) isassignableRef: TemplateRef<any>;
  @Input('type123')
  public type123: any;
  submitted: boolean = false;
  allColumns = [];
  tableKeyEnum = TableKeyEnum;
  dataGrid: vasprovidermodel[] = [];
  searchSettings = [];
  dataSource = new MatTableDataSource([]);
  vasproviderNeoGridSettings: any = {};
  subscriptions: Subscription[] = [];
  searchDataValue: SearchData = new SearchData();
  vasid:any
  VASList:any
  vasisUpdate:boolean;
  vasproviderid:any;
  combid:any;
  VASProvider: FormGroup = new FormGroup({
    provider: new FormControl(null, [Validators.required]),
    value: new FormControl(null,[Validators.required]),
    default: new FormControl(null)
  });
  public entity: vasprovidermodel 
  ngbRef: NgbModalRef;
  error_message = 'This Field is Required';
  addNewClicked:boolean = false;

  getService(): IBaseService<vasprovidermodel> {
    return this.vasproviderservice;
  }

  // setFormGroup(){
    
  // }

  setList(list: Array<vasprovidermodel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  getTableColumn() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        sortKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'integrationProviderName',
        default: 'providerName',
        rowKey: 'integrationProviderName',
        sortKey: 'providerName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'value',
        default: 'value',
        rowKey: 'value',
        sortKey: 'value',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'default',
        default: 'Default',
        rowKey: 'default',
        sortKey: 'isDefault',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.VAS_PROVIDER_DETAIL,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      showFilter: false,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this vas provider ?',
      deleteMsgBoxDetail: 'vas provider Name :${name} ',
    };
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'providerName',
        value_field: 'integrationProviderName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'value',
        value_field: 'value',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
  }
  public onDeleteClick(event) {
    let self = this;
    alertify.confirm("Are you sure to delete this VAS provider","VAS provider:" + event.integrationProviderName,
    function(){
      if (event) {
         self.delete(event);
       }},
    function(){
    })
  }

  onEditClicked(event, content) {
      this.vasisUpdate = true
      this.addNewClicked = false;
      this.vasproviderid = event.vasId;
      this.combid = event.id
      // let obj = {
      //   name: event.integrationProviderName,
      //   id: event.integrationProviderId
      // } 
      this.VASProvider.get('provider').setValue(event.integrationProviderId),
      this.VASProvider.get('value').setValue(event.value)
      this.VASProvider.get('default').setValue(event.default)
      this.ngbRef = this.ngbModalService.open(content, { size: 'lg', backdrop: 'static' });
  }

  public onEditClick(data: vasprovidermodel) {
    
  }

  onAddClick(content){
    this.vasproviderid = null 
    this.vasisUpdate = false;
    this.addNewClicked = true;
    this.submitted = false;
    //this.setFormGroup();
    this.ngbRef = this.ngbModalService.open(content, { size: 'lg',backdrop: 'static' });
  }

  constructor(
    public toastrService: ToastrService,
    private vasproviderservice: VasproviderserviceService,
    private searchService: SearchService,
    private ngbModalService: NgbModal,
    private route: ActivatedRoute
  ) {
    super(toastrService);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  ngOnInit() {
    // console.log('type => ', this.type123);
    //this.setFormGroup();
    this.vasid = this.route.snapshot.paramMap.get('id');
    this.setDefaultPageSize(this.tableKeyEnum.VAS_PROVIDER_DETAIL)
    this.filterObjForVAS()
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      this.applyFilter(obj);
    });
    this.subscriptions.push(filterSubscription);
    this.getVASByType()
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }

  filterObjForVAS()
  {
    this.searchDataValue.filterColumn = 'vasId';
    this.searchDataValue.filterValue = this.vasid;
    this.searchData = new Array<SearchData>()
    this.searchData.push(this.searchDataValue);
  }

  cancelmodal(){
    this.ngbRef.close();
    this.addNewClicked = false;
  }

  validateFormVASProvider() {
    this.submitted = true;
    if (this.VASProvider.invalid) {
      console.log('vas provider =>', this.VASProvider);
      this.toastrService.error("Please fill all the details!");
      // Object.keys(this.VASProvider.controls).forEach(field => {
      //   const control = this.VASProvider.get(field);
      //   if (control instanceof FormControl) {
      //     control.markAsTouched({ onlySelf: true });
      //   }
      // });
    } else {
        if (this.vasproviderid === null) {
          this.saveVASprovider();
        } else {
          this.UpdateVASProvider();
        }
    }
  }

  onToggle(event?,data?){
    if(this.addNewClicked == false){
      if(data && event){
        if(event.checked == true){
          data.default = true
        }
        else{
          data.default = false;
        }
        this.UpdateVASProvider(data)
        this.filterObjForVAS()
        this.getList(this.paginationData,this.orderingData,this.searchData)
      } 
    }
  }

  saveVASprovider(){
    this.addNewClicked = false;
    this.filterObjForVAS()
    let data = {
      integrationProviderId : this.VASProvider.get('provider').value,
      value:this.VASProvider.get('value').value,
      vasId: this.vasid,
      default:this.VASProvider.get('default').value
    }
    this.vasproviderservice.saveVASProvider(data).subscribe((res)=>{
      if(res.responseCode !== 200){
        this.toastrService.error(res.responseMessage)
      }
      else{
        this.neoTableSetting.pageTotalLength = res.totalRecords
        this.toastrService.success("VAS Provider Saved Successfully");
        this.getList(this.paginationData,this.orderingData,this.searchData)
        this.ngbRef.close();
      }
    })
  }

  UpdateVASProvider(dataList?){
    this.addNewClicked = false;
    let data = {
      integrationProviderId:null,
      value:null,
      vasId: null,
      default:false,
      id:null
    }
    if(dataList){
      data = dataList
    }
    else{
      data.integrationProviderId = this.VASProvider.get('provider').value;
      data.value = this.VASProvider.get('value').value;
      data.vasId = this.vasid;
      data.default = this.VASProvider.get('default').value;
      data.id = this.combid;
    }
    this.vasproviderservice.updateVASProvider(data).subscribe((res)=>{
    if(res.responseCode !== 200){
      this.toastrService.error(res.responseMessage);
      return;
    }
    else{
      this.toastrService.success("VAS Provider Updated successfully");
      this.filterObjForVAS()
      this.getList(this.paginationData,this.orderingData,this.searchData)
      this.ngbRef.close();
    }
  });
}

  getVASByType() {
    this.vasproviderservice.getVASProviderByType(this.type123).subscribe((res) => {
      this.VASList = res.dataList;
    });
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnsRef];
      }
      if (col.colKey == 'default') {
        col.customControls = [this.isassignableRef];
      }
    });
  }
}

