import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { PaymentsListService } from './payments.service';
import {AppConfig} from '../../../../../app.config';
import { SearchService } from '../../../../../services/search.service';
import { LoginService } from '../../../../../pages/login/login.service';
import { OldAbstractBaseListComponent } from '../../../../../components/base/old-abstract-base-list-component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent  extends OldAbstractBaseListComponent implements OnInit,OnDestroy {
  

  @ViewChild('downloadRecipt', { static: false }) downloadReciptWrapperRef: TemplateRef<any>;
  @ViewChild('tooltip', { static: false }) tooltipWrapperRef: TemplateRef<any>;
  @ViewChild('amount', { static: false }) amountWrapperRef: TemplateRef<any>;
  @ViewChild('relinvoice', { static: false }) relinvoiceRef: TemplateRef<any>;
  @Input() custId : any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  paymentsNeoGridSettings:any = {};
  subscriptions: Subscription[] = [];
  paymentsTableData : any;
  configPath;
  baseURL;
  paymentsDataforGrid:any={content:[]};
  loginService:any;

  constructor(
    private paymentsService : PaymentsListService,
    private searchService:SearchService,
    appConfig: AppConfig,
    public loaginService:LoginService
  ) { 
    super(searchService)
    this.configPath = appConfig.getConfig(); 
    this.loaginService=loaginService;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.PAYMENTS_LIST)
    this.setGridSetting();
    // this.getPaymentsData();
    this.baseURL = this.configPath.baseURLApi;
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.paymentsDataforGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.paymentsDataforGrid.content);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  public getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getPaymentsData(config);
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'receiptNo') {
        col.customControls = [this.downloadReciptWrapperRef];
      }
      if (col.colKey === 'remarks') {
        col.customControls = [this.tooltipWrapperRef];
      }
      if (col.colKey === 'amount') {
        col.customControls = [this.amountWrapperRef];
      }
      if (col.colKey === 'relInvoice') {
        col.customControls = [this.relinvoiceRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'paymentdate',
        default: 'Payment Date',
        rowKey: 'paymentdate',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'collectionDate',
        default: 'collection Date',
        rowKey: 'collectionDate',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'amount',
        default: 'Amount',
        rowKey: 'amount',
        width: '10%',
        customControls: [this.amountWrapperRef],
        customValue:(amount) => {
          return amount;
        },
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'relInvoice',
        default: 'against Invoice',
        rowKey: 'relInvoice',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'createdByName',
        default: 'collector Name',
        rowKey: 'createdByName',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'referenceno',
        default: 'Reference No',
        rowKey: 'referenceno',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'paymode',
        default: 'Payment Mode',
        rowKey: 'paymode',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
     
      {
        colKey: 'paymentBy',
        default: 'Payment By',
        rowKey: 'paymentBy',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'remarks',
        default: 'Remarks',
        rowKey: 'remarks',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customValue:[this.tooltipWrapperRef]

      },
      {
        colKey: 'receiptNo',
        default: 'Receipt No',
        rowKey: 'receiptNo',
        width: '10%',
        isNumber: false,
        customControls: [this.downloadReciptWrapperRef],
        customValue:(receiptNo) => {
          return receiptNo;
        },
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }
    ];

    this.paymentsNeoGridSettings = {      
      showAdvanceSearch: false,
      showFilter: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PAYMENTS_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      tableHeight:300,
      headerSticky:true,
      verticalScroll:true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
    };
    if (this.paymentsNeoGridSettings.serverSideSortPagination && this.paymentsNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.paymentsNeoGridSettings.perPage);
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
 
  getPaymentsData(gridSetting:any) {
    let fetchSettingData = this.paymentsNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    params.custId=this.custId;
    this.paymentsService.getPaymentsList(params).subscribe(result => {
      this.paymentsDataforGrid = result.dataList;
      this.refreshDataSource(this.paymentsDataforGrid);
      this.totalRecords = result ? result.totalRecords : 0;
      this.paymentsNeoGridSettings.pageTotalLength = this.totalRecords;
    });

  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData};
    this.getPaymentsData(config);
  }
}
