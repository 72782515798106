import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private http: HttpClient) { }

  transformCountryDataWithPageing(data) {
    let formatedData = [];
    data.countryList.forEach(function (item: any) {
      let itemData: any = {};
      itemData.country_id = item.id;
      itemData.country_name = item.name;
      itemData.status = item.status;
      itemData.updatedate = item.updatedate; 
      formatedData.push(itemData);
    });
    return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }
  getCountryDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'country/list';

    return this.http.post<any>(post_url, params, { headers: httpOptions.headers })
      .pipe(
        map((res: any) => {
          return this.transformCountryDataWithPageing(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformCountryList(data) {
    let formatedData = [];
    data.countryList.forEach(function (item: any) {
      let itemData: any = {};
      itemData.country_id = item.id;
      itemData.country_name = item.name;
      itemData.status = item.status;
      formatedData.push(itemData);
    });
    return { content: formatedData };
  }

  getCountryList(): Observable<any> {
    const get_url = baseApiUrl + 'country/all';
    return this.http.get<any>(get_url, { headers: httpOptions.headers })
      .pipe(
        map((res: any) => {
          return this.transformCountryList(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCountryListById(country_id): Observable<any> {
    const get_url = baseApiUrl + 'country/' + country_id;
    return this.http.get<any>(get_url, { headers: httpOptions.headers })
      .pipe(
        map((res: any) => {
          return this.transformCountryData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  transformCountryData(data) {
    let formatedData: any = {};
    let item = data.countryData;
    formatedData.country_id = item.id;
    formatedData.country_name = item.name;
    formatedData.status = item.status;
    return formatedData;
  }

  insertCountry(country_data: any): Observable<any> {
    const post_url = baseApiUrl + 'country';
    return this.http.post<any>(post_url, country_data)
      .pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  updateCountry(country_data: any, country_id: any): Observable<any> {
    const update_url = baseApiUrl + 'country/' + country_id;
    return this.http.put<any>(update_url,country_data, { headers: httpOptions.headers })
      .pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  deleteCountry(country_id: any): Observable<any> {
    const delete_url = baseApiUrl + 'country/' + country_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
