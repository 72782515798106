import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const baseApiUrl = '';
@Injectable({
  providedIn: 'root'
})
export class RecentRenewalService {
  internalCaseReport:boolean
  casePayloadDataFrmPerformanceRpt:any;
  constructor(
    private http: HttpClient
  ) { }

  findRenewal(data: any): Observable<any> {
    const post_url = baseApiUrl + "report/recentRenewalReport";
    return this.http.post<any>(post_url, data);
  }
  getPartnerlistService(): Observable<any> {
    const get_url = baseApiUrl + "partner/all";
    return this.http.get<any>(get_url);
  }

  getChildPartnerlistService(id): Observable<any> {
    const get_url = baseApiUrl + "getMultiPartnerSelectionList/" + id;
    return this.http.get<any>(get_url);
  }

  getManagablePartnerlistService(): Observable<any> {
    const get_url = baseApiUrl + "getManageablePartnerList";
    return this.http.get<any>(get_url);
  }
  
  getReportService(): Observable<any> {
    const get_url = baseApiUrl + "commonList/generic/subscriberReportFilter";
    return this.http.get<any>(get_url);
  }

  getCustomerListService(partnerid): Observable<any> {
    const get_url = baseApiUrl + "customers/byPartner/" + partnerid;
    return this.http.get<any>(get_url);
  }
  excelServiceData(data) {
    const post_url = baseApiUrl + "report/recentRenewalReport/excel";
    return this.http.post<any>(post_url, data,{ observe: 'body', responseType: 'blob' as 'json' });
  }
  excelServiceDataForNewReg(data) {
    const post_url = baseApiUrl + "report/getNewActivationSubscribers/excel";
    return this.http.post<any>(post_url, data,{ observe: 'body', responseType: 'blob' as 'json' });
  }
  
  excelServiceDataForMTTR(data) {
    let post_url = ''
    if(this.internalCaseReport){
       post_url = baseApiUrl + "report/internalCaseReport/excel";
    }else{
       post_url = baseApiUrl+ "report/mttrReport/excel";
    }
    return this.http.post<any>(post_url, data,{ observe: 'body', responseType: 'blob' as 'json' });
  }

  getUserdetailByUserName(username):Observable<any>{
    const get_url=baseApiUrl+'customers/byUserName/'+username;
    return this.http.get<any>(get_url);
  }

  findnewalRegister(data: any): Observable<any> {
    const post_url = baseApiUrl + "report/getNewActivationSubscribers";
    return this.http.post<any>(post_url, data);
  }
  
  mttrReportList(data: any): Observable<any> {
    let post_url = ''
    if(this.internalCaseReport){
       post_url = baseApiUrl + "report/internalCaseReport";
    }else{
       post_url = baseApiUrl + "report/mttrReport";   
    }
    return this.http.post<any>(post_url, data);
  }
}
