import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchService } from '../../../services/search.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { NetworkSearchService } from './service/network-search.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver/FileSaver';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
@Component({
  selector: 'app-nework-search',
  templateUrl: './nework-search.component.html',
  styleUrls: ['./nework-search.component.scss']
})
export class NeworkSearchComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;

  tablekeyEnum = TableKeyEnum;
  PowerEnabledDisabled: string = null;
  searchNetworkGridNeo: any = {};
  allColumns: any[] = [];
  networkSearchList: any;
  dateError: boolean;
  oltList: any;
  dataSource = new MatTableDataSource([]);
  networkSearchForm = new FormGroup({
    oltId: new FormControl(null),
    status:new FormControl(null),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    serialNumber: new FormControl(''),
    powercondition: new FormControl(''),
    power: new FormControl(''),
    power1: new FormControl(''),
    power2: new FormControl(''),
  })
  statuslist: any;
  constructor(
    private searchServic: SearchService,
    private datepipe: DatePipe,
    private toaster: ToastrService,
    private networkSearchService: NetworkSearchService
  ) {
    super(searchServic)
  }

  ngOnInit() {
      
    this.setDefaultPageSize(this.tablekeyEnum.NETWORK_SEARCH)
    this.getStartDateEndDate();
    this.setGridSettings();
    // this.getListWithParams();
    this.getOldData();
    this.getstatus();
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }
  getOldData() {
    this.networkSearchService.getOltData().subscribe(response => {
      this.oltList = response.dataList;
    })
  }
  getStartDateEndDate() {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var startDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.networkSearchForm.patchValue({ "startDate": startDate, "endDate": endDate });
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };

    this.getSearchData(config);
    // this.getPaymentsData(config);
  }

  getstatus(){
    this.networkSearchService.getstatus().subscribe((res) => {
      this.statuslist = res.dataList;
    })
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getSearchData(config);
  }
  changeNetworkValue(event) {
    if (event) {
      if (this.PowerEnabledDisabled == 'Between') {
        this.networkSearchForm.get('power1').reset();
        this.networkSearchForm.get('power2').reset();
      } else {
        this.networkSearchForm.get('power').reset();
      }
    } else {
      this.networkSearchForm.get('power1').reset();
      this.networkSearchForm.get('power2').reset();
      this.networkSearchForm.get('power').reset();
    }
  }
  searchNetwork() {
    this.getListWithParams();
  }

  onExportToExcel() {
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();
    let apiInputData = this.networkSearchForm.value;
    let createStartdate = this.datepipe.transform(apiInputData.startDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    let ddmmyyyyStartDate = new Date(createStartdate);
    let ddmmyyyyEndDate = new Date(createEnddate);
    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.toaster.error("Start date should be less then End date.");
    } else {
      let apiFormatedData = this.transformSearcData(apiInputData);
      this.networkSearchService.excelServiceData(apiFormatedData).subscribe(blob => {
        saveAs(blob, "[network search report " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
      })
    }
  }
  getSearchData(gridSetting: any) {
    let fetchSettingData = this.searchNetworkGridNeo.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    let apiInputData = this.networkSearchForm.value;
    let createStartdate = this.datepipe.transform(apiInputData.startDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    let ddmmyyyyStartDate = new Date(createStartdate);
    let ddmmyyyyEndDate = new Date(createEnddate);
    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.toaster.error("Start date should be less then End date.");
    } else {
      if (apiInputData.startDate != '') {
        let formatedApiData = this.transformSearcData(apiInputData, params);

        this.networkSearchService.searchNetworkdata(formatedApiData).subscribe(result => {
          if (result.responseCode === 200) {
            if (result.dataList.length === 0) {
              this.toaster.error("No Record Found");
              this.networkSearchList = [];
              this.refreshDataSource(this.networkSearchList);
              if (result.dataList === null) {
                this.networkSearchList = [];
                this.refreshDataSource(this.networkSearchList);
              }
            } else {
              this.networkSearchList = result.dataList;
              this.refreshDataSource(this.networkSearchList);
              this.totalRecords = result ? result.totalRecords : 0;
              this.searchNetworkGridNeo.pageTotalLength = this.totalRecords === 0 ? 0 : this.totalRecords;
            }
          } else {
            
            this.toaster.error("No Record Found");
            this.refreshDataSource(this.networkSearchList);
          }
        });
        this.dateError = false;
      } else {
        this.dateError = true;
      }
    }

  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 45 || charCode > 57)) {
      return false;
    }
    return true;
  }


  transformSearcData(data, params?) {
    let formatedData: any = {};
    let apiInputData = data;
    let createStartdate = this.datepipe.transform(apiInputData.startDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    if (params) {
      ////console.log(params);
      formatedData.paginationRequestDTO = Object.assign(params);
    }
    if (apiInputData.oltId) {
      formatedData.oltid = apiInputData.oltId;
    }
    if (apiInputData.serialNumber) {
      formatedData.serialNumber = apiInputData.serialNumber;
    }
    if (apiInputData.powercondition != '') {
      formatedData.powercondition = apiInputData.powercondition;
      if (apiInputData.power1) {
        formatedData.power1 = apiInputData.power1;
      }
      if (apiInputData.power2) {
        formatedData.power2 = apiInputData.power2;
      }
      if (apiInputData.power) {
        formatedData.power1 = apiInputData.power;
      }
    }
    formatedData.startdate = createStartdate;
    formatedData.enddate = createEnddate;
    formatedData.status = apiInputData.status;
    return formatedData;
  }
  setGridSettings() {
    this.allColumns = [
      {
        colKey: 'serialnumber',
        default: 'serial Number',
        rowKey: 'serialnumber',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'oltframe',
        default: 'frame',
        rowKey: 'oltframe',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'oltslot',
        default: 'slot',
        rowKey: 'oltslot',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'oltport',
        default: 'port',
        rowKey: 'oltport',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'ont',
        default: 'ont',
        rowKey: 'ont',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'power',
        default: 'power',
        rowKey: 'power',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'olt_name',
        default: 'olt',
        rowKey: 'olt_name',
        width: '15%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'lastdowncause',
        default: 'last down cause',
        rowKey: 'lastdowncause',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'lastdowntime',
        default: 'last down time',
        rowKey: 'lastdowntime',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'lastmodifieddate',
        default: 'last modified date',
        rowKey: 'lastmodifieddate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'lastuptime',
        default: 'last up time',
        rowKey: 'lastuptime',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'lastdyinggasptime',
        default: 'last dyinggasp time',
        rowKey: 'lastdyinggasptime',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      
      {
        colKey: 'status',
        default: 'status',
        rowKey: 'status',
        width: '10%',
        customValue: (status) => {
          if (status.equalsIgnoreCase() === 'Online') {
            return 'Online';
          } else {
            return 'Offline';
          }
        },
        isNumber: false,
        isFilterAllow: false,
      },
    ];
    this.searchNetworkGridNeo = {
      showFilter: false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tablekeyEnum.NETWORK_SEARCH,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: false,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,

    };
  }
  resetform() {
    this.networkSearchForm.reset();
    this.getStartDateEndDate();
    // this.getListWithParams();
  }

}
