import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { SelfcarePasswordService } from './selfcare-password.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Input() custId : any;
  customerDetails : any;
  customerName : any;
  submitted :boolean;
  constructor(
    private customModalService : CustomModalService,
    private selfcarePasswordService : SelfcarePasswordService,
    private tosterservice : ToastrService

  ) { }

  updateSelfcarePasswordForm = new FormGroup({
    newpassword : new FormControl('', [Validators.required]),
    remarks : new FormControl('', [Validators.required])
  })

  ngOnInit() {
    this.getBasicDetails();
  }

  getBasicDetails() {
    this.selfcarePasswordService.getBasicCustDetails(this.custId).subscribe(result => {
      this.customerDetails = result.data;
      this.customerName = this.customerDetails.username;
    })
  }

  onClickSaveForm() {
    if (this.updateSelfcarePasswordForm.valid === true) {
      let self = this;
      alertify.confirm("Change Selfcare Password","Are You Sure You want to Change Selfcare Password ?",
      function() {
        self.updateSelfcarePasswordOnDb();
      },
      function() {
  
      });
    } else {
      this.submitted = true;
      this.updateSelfcarePasswordForm.markAllAsTouched();
    }
  }

  updateSelfcarePasswordOnDb() {
    let apiInputData = Object.assign({}, this.updateSelfcarePasswordForm.value);
    apiInputData.custId = this.custId;
    this.selfcarePasswordService.updateSelfPassword(apiInputData).subscribe(
    (result) => {
      if (result.responseCode !== 200 && result.responseMessage) {
        this.tosterservice.error(result.responseMessage);
      } else {
        this.tosterservice.success('Selfcare Password update Successfully.');
        this.customModalService.close('change-password',result);
      }
    },err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    }
  );
  }

  closeModal(id:string){
    this.customModalService.close(id);
    this.submitted = false;
  }

}
