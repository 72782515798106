import { stringify } from '@angular/compiler/src/util';
import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { ClientGroupModel } from '../model/client-group-model';
import { ClientGroupService } from '../Services/client-group.service';
import { IBaseService } from '../../../../services/ibase-service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../../../../app/pages/data-sharing-service.service';

export interface DialogPassData{
  id:number;
}
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-add-client-group',
  templateUrl: './add-client-group.component.html',
  styleUrls: ['./add-client-group.component.scss']
})
export class AddClientGroupComponent extends AbstractBaseAddUpdateComponent<ClientGroupModel> implements OnInit {
  public entity: ClientGroupModel = new ClientGroupModel();
  public entity_id: any = null;
  form: FormGroup;
  isDisabled:boolean=false;
  clientStatusList:any[] = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    }
  ]

  constructor(private clientGrpService: ClientGroupService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
   // private loadingService: LoadingService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddClientGroupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    
    ) {
      super(toastrService, router );
    }

    ngOnInit() {
      this.init();
      let id = this.activeRoute.snapshot.paramMap.get('id');
      if (id) {
        this.isUpdateComponent = true;
        this.getById(Number(id));
      }
      // if(this.data && this.data.id){
      //   this.isUpdateComponent = true;
      //   this.isDisabled=true;
      //   this.getById(this.data.id);
      // }
      else{
        this.isDisabled=false;
      }
    }

    getService(): IBaseService<ClientGroupModel> {
      return this.clientGrpService;
    }

    setData(data: ClientGroupModel) {
      this.entity = data;
    }

    getData(): ClientGroupModel {
      return this.entity;
    }

    public getModuleHomeUrl(): string {
      return '/radius/clients-group';
    }

    public getActiveRoute(): ActivatedRoute {
      return this.activeRoute;
    }
    public getNewModal(): ClientGroupModel {
      return new ClientGroupModel();
    }

    public getFormGroup(): FormGroup {
      return this.form;
    }

    public getId(): number {
      return this.entity.id;
    }

    get f() {
      return this.form.controls;
    }

    setValidation() {
      this.form = new FormGroup({
        client_name: new FormControl('', [Validators.required]),
        client_status: new FormControl('', [Validators.required])
      });
    }

    formSubmit() {
      if (this.validateForm()) {
        this.onSubmit();
      }
    }
    closeDialog(){
      this.dialogRef.close();
     }
   
    @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getGenericDataService(){
    return this.genericDataSharingService
    }
    

}
