import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-childloading',
  templateUrl: './childloading.component.html',
  styleUrls: ['./childloading.component.scss']
})
export class ChildloadingComponent implements OnInit {

  //public active: boolean;

    public constructor(loadingService: LoadingService) {
      // loadingService.status.subscribe((status: boolean) => {
      //   this.active = status;
      // });
    }
    
    ngOnInit() {

    }

}