export class ServiceAreaModel {
    id: number;
    name:string;
    status:string;
    vlanId:string;
    staffId:number;
    isDeleted:boolean=false;
    constructor(){
        this.name="";
        this.status="";
    }
}
