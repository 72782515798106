import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import _ from 'underscore';
import { ServicesService } from '../service/service.service';
import { SearchService } from '../../../services/search.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogPassData{
  id:number;
}

import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../data-sharing-service.service';
@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent extends OldAbstractBaseListComponent implements OnInit {
  public getListWithParams() {
    throw new Error('Method not implemented.');
  }
  saveBtn:boolean=false;
  serviceDataForGrid: any = { content: [] };
  serviceGridSettings: any = {};
  public sub: any = null;
  public service_id : any = null;
  selectForUpdate: any;
  show_update: any = false;
  parentServiceList: any = false;
  error_message = "This field is required.";
  isDisabled:boolean=false;
  insertServiceForm = new FormGroup({
    name: new FormControl('', [Validators.required])
  });

  constructor(
    private serviceService: ServicesService,
    private router: Router,
    private route : ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private tosterservice :ToastrService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddServiceComponent>,
    private sharingdataService: DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { super(searchService);}

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.service_id = id;
      this.isDisabled=false;
      this.show_update = true;
      this.getCountryData();
    }
    // if(this.data && this.data.id){
    //   this.isDisabled=true;
    //   this.service_id = this.data.id;
    //   this.show_update = false;
    //   this.getCountryData();
    //   this.insertServiceForm.disable();
    // }
    
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.service_id = params['id'];
    //       this.show_update = false;
    //       this.getCountryData();
    //     }
    //   });
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  validateForm() {
    if (!this.insertServiceForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertServiceForm.controls).forEach(field => {
        const control = this.insertServiceForm.get(field);            
        if (control instanceof FormControl) {             
            control.markAsTouched({ onlySelf: true });
        } 
      });
    } else {
      if (this.service_id === null) {
        this.insertServiceOnDb();
      } else {
        this.updateServiceOnDb();
      }
    }
  }

  getCountryData() {
    let service_id = this.service_id;
    this.serviceService.getServiceListById(service_id).subscribe(
      result => {
        this.setUpdateValueInForm(result.servicebyId);
      });
  }

  insertServiceOnDb() {
    if (this.insertServiceForm.valid === true) {
      this.saveBtn =true;
      let formatedInputData: any = this.insertServiceForm.value;
      this.serviceService.insertService(formatedInputData).subscribe(result => {
        if(result.status !== 200){
          this.saveBtn =false;
          this.tosterservice.error(result.ERROR);
        }
        else{
          this.saveBtn =false;
          this.cdRef.detectChanges();
          this.backToViewService();
          this.tosterservice.success('Service has been created successfully');
        }
     
      },err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  updateServiceOnDb() {
    if (this.insertServiceForm.valid === true) {
      this.saveBtn =true;
      let formatedInputData: any = this.insertServiceForm.value;
      this.serviceService.updateService(formatedInputData, this.service_id).subscribe(result => {
        if(result.status !== 200){
          this.saveBtn =false;
          this.tosterservice.error(result.ERROR);
        }
        else{
          this.saveBtn =false;
          this.cdRef.detectChanges();
          this.show_update = false;
          this.backToViewService();
          this.tosterservice.success('Service Update successfully');

        }
       
      },err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  setUpdateValueInForm(serviceData: any) {
    this.show_update = true;
    this.selectForUpdate = serviceData;
    this.insertServiceForm.patchValue(serviceData);
  }

  backToViewService() {
    //this.router.navigate(['/plan-management/service']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/plan-management/service']);
    }
  }
  closeDialog(){
    this.dialogRef.close();
   }
   
}
