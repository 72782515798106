
import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base-service';
import { HttpClient } from '@angular/common/http';
import { sms } from './model/sms';
import { Observable } from 'rxjs';
const baseApiUrl = '';
@Injectable({
  providedIn: 'root'
})
export class SmsServiceService extends BaseService<sms> {
  
  moduleUrl:string;
  
  constructor(
    public http:HttpClient
  ) { 
    super(http)
  }
  // setChangeUrl(listurl){
  //   if(listurl === true){
  //     this.moduleUrl="smsProvider/all";
  //   }else{  
  //     this.moduleUrl="smsProvider";
  //   }
  // }
  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "smsProvider";
  }
  commonStatus(){
    const get_url=baseApiUrl+"commonList/commonStatus";
    return this.http.get<any>(get_url);
  }
  getSmsData(){
    const get_url=baseApiUrl+"smsProvider/all";
    return this.http.get<any>(get_url);
  }
  
}
