import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ConnectedSubscriberService {
  constructor(private http: HttpClient) {

  }

  transformConnectedSubscriberData(data) {
    let formatedData = [];
    data.liveuserlist.forEach(function(item:any){
      item.timestamp = data.timestamp;
      formatedData.push(item);
    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  getConnectedSubscriberDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'liveuser/list';
    return this.http.post<any>(post_url,params,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformConnectedSubscriberData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  subscriberUserDisconnectBySession(data:any): Observable<any> {
    const post_url = BASE_API_URL + 'userDisconnect/BySession';
    return this.http.post<any>(post_url,data,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getUserdetailByUserName(username):Observable<any>{
    const get_url=BASE_API_URL+'customers/byUserName/'+username;
    return this.http.get<any>(get_url);
  }

}
