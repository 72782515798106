import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(private http: HttpClient) {}

  transformServiceDataWithPageing(data) {
    let formatedData = [];
    data.serviceList.forEach(function(item:any){
      let itemData:any = {};
      itemData.service_id = item.id;
      itemData.service_name = item.name;
      itemData.updatedate = item.updatedate;
      formatedData.push(itemData);
    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }


  getServiceDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'planservice/list';

    return this.http.post<any>(post_url,params,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
         return this.transformServiceDataWithPageing(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getServiceListById(service_id): Observable<any> {
    const get_url = baseApiUrl + 'planservice/' + service_id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getServiceList(): Observable<any> {
    const get_url = baseApiUrl + 'planservice/all';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformServiceDataWithPageing(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertService(service_data: any): Observable<any> {
    const post_url = baseApiUrl + 'planservice';
    return this.http.post<any>(post_url, service_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updateService(service_data: any,service_id: any): Observable<any> {
    const update_url = baseApiUrl + 'planservice/' + service_id
    return this.http.put<any>(update_url, service_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }


  deleteService(service_id: any): Observable<any> {
    const delete_url = baseApiUrl + 'planservice/' + service_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
