import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class ReverseChargeService {
  constructor(private http: HttpClient) {

  }

  getSubscriberReverseCharge(subscriber_id): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/getReversibleCharge/'+subscriber_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  saveReverseCharge(reverse_charge_data): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/reverseCharge';
    return this.http.post<any>(get_url,reverse_charge_data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCalculateReverseCharge(subscriber_id): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/calculateReverseCharge/'+subscriber_id;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
