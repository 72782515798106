import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private http: HttpClient) {

  }

transformStateData(data) {
  let formatedData = [];
  data.stateList.forEach(function(item:any){
    let itemData:any = {};
    itemData.state_id = item.id;
    itemData.state_name = item.name;
    itemData.status = item.status;
    itemData.country_name = item.countryPojo.name;
    itemData.country_id = item.countryPojo.id;
    itemData.updatedate = item.updatedate; 
    formatedData.push(itemData);
  });
  return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
}

getStateDataWithPageing(params: HttpParams): Observable<any> {

  const post_url = baseApiUrl + 'state/list';
  return this.http.post<any>(post_url, params, { headers: httpOptions.headers}).pipe(
      map((res: any) => {
        return this.transformStateData(res);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
}

  transformStateById(data) {
    let itemData:any = {};
    itemData.state_id = data.id;
    itemData.state_name = data.name;
    itemData.status = data.status;
    itemData.country_name = data.countryPojo.name;
    itemData.country_id = data.countryPojo.id;
    return itemData;
  }

  getStateListById(state_id): Observable<any> {
    const get_url = baseApiUrl + 'state/'+state_id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformStateById(res.stateData);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  transformStateListByCountryId(data,country_id) {
    let formatedData = [];
    data.stateList.forEach(function(item:any){
      if (item.countryPojo.id == country_id) {
        let itemData:any = {};
        itemData.state_id = item.id;
        itemData.state_name = item.name;
        itemData.status = item.status;
        itemData.country_name = item.countryPojo.name;
        itemData.country_id = item.countryPojo.id;
        formatedData.push(itemData);
      }
    });
    return {content:formatedData};
  }
getallstate(){
  const get_url = baseApiUrl + 'state/all';
  return this.http.get<any>(get_url,
    { headers: httpOptions.headers})
}
  getStateListByCountryId(country_id?:any): Observable<any> {
   // const get_url = baseApiUrl + 'get_state_list_by_country_id/'+country_id;
    const get_url = baseApiUrl + 'state/all';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return this.transformStateListByCountryId(res,country_id);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertState(state_data: any): Observable<any> {
    const post_url = baseApiUrl + 'state';
    return this.http.post<any>(post_url, state_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  updateState(state_data: any,state_id: any): Observable<any> {
    const update_url = baseApiUrl + 'state/'+state_id
    return this.http.put<any>(update_url, state_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
stateBycountry(country_id){
  const get_url = baseApiUrl + 'state/searchBy/'+ country_id;
  return this.http.get<any>(get_url)
}

  deleteState(state_id: any): Observable<any> {
    const delete_url = baseApiUrl + 'state/'+state_id;
    return this.http.delete(delete_url, httpOptions);
  }

}
