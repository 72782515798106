import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, Inject, Optional,HostListener } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { TaxService } from '../tax/tax.service';
import { FormBuilder, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../data-sharing-service.service';
export interface DialogPassData{
  id:number;
}

@Component({
  selector: 'app-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.scss']
})
export class AddTaxComponent implements OnInit, OnDestroy {
  saveBtn:boolean=false;
  selectForUpdate: any;
  public tax_id: any = null;
  public selected_tax_type: any = null;
  public sub: any = null;
  show_update: any = false;
  insertTaxForm: FormGroup;
  slab_list: FormArray;
  tier_list: FormArray;
  tax_tier_group_data: any = [];
  error_message = "This field is required.";
  isDisabled:boolean=false;
  constructor(
    private taxService: TaxService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private tosterservice:ToastrService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddTaxComponent>,
    private sharingdataService: DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {

  }

  ngOnInit() {

    this.insertTaxForm = this.formBuilder.group({
      tax_name: new FormControl('', [Validators.required]),
      tax_desc: new FormControl('', [Validators.required]),
      tax_type: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      slab_list: this.formBuilder.array([]),
      tier_list: this.formBuilder.array([])
    });

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.tax_id = id;
      this.show_update = true;
      this.getTaxDataById();
    }
    // if(this.data && this.data.id){
      
    //   this.isDisabled=true;
    //   this.tax_id = this.data.id;
    //   this.show_update = false;

    //   this.getTaxDataById();
    //   this.insertTaxForm.disable();
    // }
    this.getTaxTierGroupsData();
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.tax_id = params['id'];
    //       this.show_update = true;
    //       this.getTaxDataById();
    //     }
    //     this.getTaxTierGroupsData();
    //   });
  }

  validateForm() {
    if (!this.insertTaxForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertTaxForm.controls).forEach(field => {
        const control = this.insertTaxForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.tax_id === null) {
        this.insertTaxOnDb();
      } else {
        this.updateTaxOnDb();
      }
    }
  }

  createSlabItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      range_from: '',
      range_to: '',
      rate: ''
    });
  }

  addSlabItem(): void {
    this.slab_list = this.insertTaxForm.get('slab_list') as FormArray;
    this.slab_list.push(this.createSlabItem());
  }

  createTierItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      tier_group: '',
      rate: ''
    });
  }

  addTierItem(): void {
    this.tier_list = this.insertTaxForm.get('tier_list') as FormArray;
    this.tier_list.push(this.createTierItem());
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }


  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getTaxDataById() {
    let tax_id = this.tax_id;
    this.taxService.getTaxDataById(tax_id).subscribe(result => {
      let data = result;
      this.setUpdateValueInForm(data);
    });
  }

  getTaxTierGroupsData() {
    this.taxService.getTaxTierGroupsData().subscribe(result => {
      this.tax_tier_group_data = result;
    });
  }

  backToViewTax() {
    //this.router.navigate(['/plan-management/tax']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/plan-management/tax']);
    }
  }

  tierListClearAll() {
    let tierListArray = this.insertTaxForm.get('tier_list') as FormArray;
    tierListArray.clear();
  }

  slabListClearAll() {
    let slabListArray = this.insertTaxForm.get('slab_list') as FormArray;
    slabListArray.clear();
  }

  onTaxTypeChange($event) {
    ////console.log($event);
    let tax_type = $event.currentTarget.value;
    this.selected_tax_type = tax_type;
    if (this.show_update === false) {
      if (tax_type == 'SLAB') {
        this.tier_list = new FormArray([]);
        this.insertTaxForm.patchValue({ tier_list: [] });
        this.slabListClearAll();
        this.tierListClearAll();
        this.addSlabItem();
      } else if (tax_type == 'TIER') {
        this.slab_list = new FormArray([]);
        this.slabListClearAll();
        this.tierListClearAll();
        this.addTierItem();
      } else {
        this.tier_list = new FormArray([]);
        this.slab_list = new FormArray([]);
        this.slabListClearAll();
        this.tierListClearAll();
      }
    }
  }

  transformDataForInsertUpdateApi(apiInputData) {
    let formatedInputData: any = {};
    formatedInputData.name = apiInputData.tax_name;
    formatedInputData.desc = apiInputData.tax_desc;
    formatedInputData.taxtype = apiInputData.tax_type;
    formatedInputData.status = apiInputData.status;
    if (apiInputData.tax_type === "SLAB") {
      if (this.insertTaxForm.value.slab_list && this.insertTaxForm.value.slab_list.length > 0) {
        let formatedSlabItem: any = [];
        this.insertTaxForm.value.slab_list.forEach(slab_item => {
          if (slab_item.name && slab_item.name.length &&
            slab_item.range_from &&
            slab_item.range_to &&
            slab_item.rate
          ) {
            formatedSlabItem.push({
              name: slab_item.name,
              rangeFrom: slab_item.range_from,
              rangeUpTo: slab_item.range_to,
              rate: slab_item.rate
            });
          }
        });
        formatedInputData.slabList = formatedSlabItem;
      }
    } else if (apiInputData.tax_type === "TIER") {
      if (this.insertTaxForm.value.tier_list && this.insertTaxForm.value.tier_list.length > 0) {
        let formatedTierItem: any = [];
        this.insertTaxForm.value.tier_list.forEach(tier_item => {
          if (tier_item.name && tier_item.name.length &&
            tier_item.tier_group && tier_item.tier_group.length &&
            tier_item.rate
          ) {
            formatedTierItem.push({
              name: tier_item.name,
              taxGroup: tier_item.tier_group,
              rate: tier_item.rate
            });
          }
        });
        formatedInputData.tieredList = formatedTierItem;
      }
    }
    return formatedInputData;
  }

  insertTaxOnDb() {
    if (this.insertTaxForm.valid === true) {
      let apiInputData = Object.assign({}, this.insertTaxForm.value);
      let formatedInputData = this.transformDataForInsertUpdateApi(apiInputData);
      let isValid = false;
      if (formatedInputData.taxtype == "SLAB") {
        if (formatedInputData.slabList.length < 1) {
          this.tosterservice.error('Please Add One Slab Info.');
        } else {
          isValid = true;
        }
      } else if (formatedInputData.taxtype == "TIER") {
        if (formatedInputData.tieredList.length < 1) {
          this.tosterservice.error('Please Add One Tier Info.');
        } else {
          isValid = true;
        }
      }
      if (isValid === true) {
        this.saveBtn =true;
        this.taxService.insertTax(formatedInputData).subscribe((result) => {
          if(result.status !==  200){
            this.saveBtn =false;
            this.tosterservice.success('Tax has been created successfully');
          } else{
            this.saveBtn =false;
            this.insertTaxForm.reset({ status: "" });
            this.cdRef.detectChanges();
            this.tosterservice.success('Tax has been created successfully');
            this.backToViewTax();
          }
        },
          err => {
            this.saveBtn =false;
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }
    }
  }



  updateTaxOnDb() {
    if (this.insertTaxForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.insertTaxForm.value);
      let tax_id = this.selectForUpdate.tax_id;
      let formatedInputData = this.transformDataForInsertUpdateApi(apiInputData);
      this.taxService.updateTax(formatedInputData, tax_id).subscribe(result => {
        if(result.status !==  200){
          this.saveBtn =false;
          this.tosterservice.success('Tax has been created successfully');
         }else{
          this.saveBtn =false;
          this.insertTaxForm.reset({ status: '', tax_type: '' });
          this.cdRef.detectChanges();
          this.show_update = false;
          this.tosterservice.success('Tax Update successfully');
          this.backToViewTax();
         }     
      },
        err => {
          this.saveBtn =false;
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  deleteSlabItem(i) {
    // this.slab_list.removeAt(i);
    (<FormArray>this.insertTaxForm.get('slab_list')).removeAt(i);
    this.cdRef.detectChanges();
  }

  deleteTierItem(i) {
    // this.tier_list.removeAt(i);
    (<FormArray>this.insertTaxForm.get('tier_list')).removeAt(i);
    this.cdRef.detectChanges();
  }

  insertUpdateTaxOnDbCancel() {
    this.show_update = false;
    this.insertTaxForm.reset({ status: '', tax_type: '' });
    this.cdRef.detectChanges();
  }

  setUpdateValueInForm(taxData) {
    this.selectForUpdate = taxData;
    let taxFormData: any = {
      'tax_name': taxData.tax_name,
      'status': taxData.status,
      'tax_desc': taxData.tax_desc,
      'tax_type': taxData.tax_type
    };
    if (taxData.slab_list && taxData.slab_list.length) {
      taxFormData.slab_list = taxData.slab_list;
      taxData.slab_list.forEach(element => {
        this.addSlabItem();
      });
      this.slab_list = taxData.slab_list;
    } else if (taxData.tier_list && taxData.tier_list.length) {
      taxFormData.tier_list = taxData.tier_list;
      taxData.tier_list.forEach(element => {
        this.addTierItem();
      });
      this.tier_list = taxData.tier_list;
    }
    this.selected_tax_type = taxFormData.tax_type;
    this.insertTaxForm.patchValue(taxFormData);
  }
  closeDialog(){
    this.dialogRef.close();
   }

}
