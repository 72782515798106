import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { SearchService } from '../../../services/search.service';
import { LoginService } from '../../login/login.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { SmsServiceService } from '../sms-service.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { sms } from '../model/sms';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../services/ibase-service';
import { SearchControlTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { PaginationData } from '../../../core/modal/pagination-data';
import { OrderingData } from '../../../core/modal/ordering-data';
import { SearchData } from '../../../core/modal/search-data';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-sms-providers',
  templateUrl: './sms-providers.component.html',
  styleUrls: ['./sms-providers.component.scss']
})
export class SmsProvidersComponent extends AbstractBaseListComponent<sms> implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('smsName', { static: false }) smsNameReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  tableKeyEnum = TableKeyEnum;
  loginservice: any;
  dataSource = new MatTableDataSource([]);
  smsNeoGridSettings: any = {};
  show_update: any = false;
  smsDataForGrid: any = { content: [] };
  allColumns: any[] = [];
  searchSettings: any[] = [];
  dataGrid: any;
  subscriptions: Subscription[] = [];
  loginService: LoginService;
  constructor(
    public searchService: SearchService,
    private LoginService: LoginService,
    private smsService: SmsServiceService,
    private toaster: ToastrService,
    private router: Router
  ) {

    super(toaster, searchService);
    this.loginService = LoginService;
  }
  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.SMS_PROVIDER)
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      // this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.smsNameReff];
      }
    });
  }
  getService(): IBaseService<sms> {
    return this.smsService;
  }

  setList(list: Array<sms>) {
    this.dataGrid = list;
    this.smsNeoGridSettings.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  getTableColumn() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        sortKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'name',
        rowKey: 'name',
        sortKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'className',
        default: 'class Name',
        rowKey: 'className',
        sortKey: 'className',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },

      {
        colKey: 'providerUrl',
        default: 'provider Url',
        rowKey: 'providerUrl',
        sortKey: 'providerUrl',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        sortKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },

      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {
    this.smsNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.SMS_PROVIDER,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this sms ?',
      deleteMsgBoxDetail: 'SMS  Name :${name} ',
    };
    return this.smsNeoGridSettings;
  }
  getTableSearchSetting() {

    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }
  public onDeleteClick(event) {
    if (event) {
      this.commonDeleteMsgBox(this.getTableSetting(), event, this.afterDeleteConfirm.bind(this));
    }
  }
  public onEditClick(event) {
    this.setUpdateValueInForm(event);
  }
  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }
  setUpdateValueInForm(rowData) {
    this.router.navigate(['/configuration/add-sms',rowData.id]);
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onAddClick() {
    this.router.navigate(['/configuration/add-sms']);
  }
  getList(paginationData: PaginationData, orderingData: OrderingData, searchData?: SearchData[], status?: any, filterBy?: any): void {
    this.smsService.getSmsData().subscribe(response => {
      this.totalRecords = response.totalRecords;
      this.setList(response.dataList);
    })
  }
}
