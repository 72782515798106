import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

export class NetworkDevice {
  createdById:string;
  lastModifiedById:string;
  id:string;
  name:string;
  devicetype:string;
  status:string;
  isDeleted:string;
}

export class NetworkTypeItem {
  id: string;
  text: string;
  value : string;
}
// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class NetworkDeviceServices {
  constructor(private http: HttpClient) { }

  getNetworkDeviceList(): Observable<any> {
    const get_url = baseApiUrl + 'NetworkDevice';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as NetworkDevice[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getNetworkTypeList(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/network';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as NetworkTypeItem[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getOltSlotByNetworkDeviceId(network_device_id:number): Observable<any> {
    const get_url = baseApiUrl + 'OLTSlot/byNetworkId/'+network_device_id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as NetworkTypeItem[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getNetworkDeviceByServiceId(serviceId:number): Observable<any> {
    const get_url = baseApiUrl + 'NetworkDevice/byServiceId/'+ serviceId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

   


}
