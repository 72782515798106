import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})

export class TroubleTicketDetails {
  
  constructor(private http: HttpClient) { }

  getTroubleTicketsDetailsList(custId: any): Observable<any> {
    const post_url = baseApiUrl + 'case/getBaseSetupCasesByCustomer';
    return this.http.post<any>(post_url, custId);
  }

  updateData(data) {
    const updateListFromdata = new FormData();
    updateListFromdata.append("caseUpdate", "" + JSON.stringify(data));
    const post_url = baseApiUrl + 'case/updateDetails';
    return this.http.post<any>(post_url, updateListFromdata);
  }
}
