import { Component, forwardRef, OnInit, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../../services/message_service/message_service.service';
import { PlanServices } from '../../../../services/plan_service/plan_service.service';
import { VoiceServiceType } from '../../../../services/voice_service_type/voice_service_type.service';
import { IntercomGroup } from '../../../../services/intercom_group/intercom_group.service';
import { ChargeTypeService } from '../../../../services/charge_type/charge_type.service';
import { HostListener } from '@angular/core';
import { PubSubService } from '../../../../services/pubsub/pubsub.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../../core/constant/response-code';
import _ from 'underscore';
import { selectRows } from '@swimlane/ngx-datatable/release/utils';
import { FranchiesService } from '../../franchies/franchies.service';
import { ApplyChargeService } from '../../subscriber-details/apply-charge/apply-charge.service';
import { ChangePlanService } from '../../subscriber-details/change-plan/change-plan.service';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NPackagesService } from '../../../plan_management/packages/Npackages.service';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-plan-service',
  templateUrl: './plan-service.component.html',
  styleUrls: ['./plan-service.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlanServiceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PlanServiceComponent),
      multi: true
    }
  ]
})
export class PlanServiceComponent implements ControlValueAccessor, OnInit {
  selectedVoiceStr: string;
  selectedOTTStr: string;
  selectedIPTVStr: string;
  @ViewChild('select',{static: false}) select: NgSelectComponent;
  @Input() partnerid: number;
  @Input() stateId: number;

  tempPartnerId: number;
  NEXG_VAS = []
  VAS_OTT = []
  VAS_IPTV = []
  voicePrice=0;
  iptvPrice=0;
  ottPrice=0;
  vasprice: number = 0;

  offerpricewithtax: number = 0;

  selectedVoice: string;
  selectedOtt: string;
  selectedIptv: string;
  voiceSelected:boolean = false;
  ottSelected:boolean = false;
  iptvSelected:boolean = false;
  vasFlag:boolean = false;
  private ngbRef: NgbModalRef;
  categoryList: any[] = [{ 'name': 'Premium', 'value': 'online' },
  { 'name': 'Offline', 'value': 'offline' },
  { 'name': 'Inactive', 'value': 'inactive' }
  ]
  subscriptions: Subscription[] = [];
  subscription: Subscription;
  req_message = "This field is required.";
  submitted: boolean;
  commissiontype:any;
  selected_change_voice_service_type: any;
  isPhonelineDidNoValid: boolean;
  shipTrunkDidNoValid: boolean;
  shipTrunkChildDidNoValid: boolean;
  intercomeNoValid: boolean;
  intercomGroupValid: boolean;
  presentAddressStateChange: any;
  taxDetailsByPlanData: any;
  taxDetailsByChargeData: any;
  checkOnlineRenewal: boolean = false;
  checkEmailNotification: boolean = false;
  checkEnableAutoticketing:boolean=false
  checkVOIPServices: boolean = false;
  checkSmsNotification: boolean = false;
  isIncludeTax2x:boolean=true;
  isOverrideCharge:boolean=false;
  isPriceOverrideCharge:boolean=false;
  hideLabelFlag:boolean=false;
  chargeDetails:any;
  maxChargeAmount:any;
  minChargeAmount:any;
  isPriceOverride:boolean=false;
  isPriceOverrideReadOnly:boolean=true;
  isIncludeTaxflag:boolean=false;
  selectedChargetaxid:any;
  finalAmount:any;
  chargeAmount:any;
  selectedchargeTaxId:any;
  overridePriceTaxWithTax:any;
  hideShowField:boolean=false;
  isAllowVAS:boolean = true;
  insertPlanDetailForm = new FormGroup({
    services: new FormControl(null),
    paln_details: new FormControl(null),
    vas_details: new FormControl(null),
    isPriceOverride: new FormControl(null),
    sellPrice: new FormControl(null),
    isIncludeTax: new FormControl(null),
    planType:new FormControl(null),
    selectedVASIds: new FormControl(null)
  });
  
  insertServicesDetailForm = new FormGroup({
    voice_service_type: new FormControl(null),
    phoneline_did_no: new FormControl(null),
    did_no: new FormControl(''),
    child_did_no: new FormControl(''),
    intercome_no: new FormControl(''),
    intercome_group: new FormControl(''),
    allow_online_renewal: new FormControl(false),
    voip_services: new FormControl(false),
    emailenable:new FormControl(true),
    smsenable:new FormControl(true),
    allocateInventory: new FormControl(false),
    checkEnableAutoticketing:new FormControl(false)
  });
  insertChargeDetailForm = new FormGroup({
    charge_id: new FormControl(null),
    charge_date: new FormControl(''),

    overridePrice:new FormControl(''),
    isPriceOverride: new FormControl(false),
    isIncludeTax: new FormControl(false),
      

  })
  public planServiceList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public voiceServiceTypeList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  public intercomGroupList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  public chargeForCustomerList: any[] = [
    {
      text: '',
      id: '',
    },
  ];

  public franchiesList: any[] = [{
    franchies_name: '',
    franchies_id: ''
  }];

  public planDetailsList: any[] = [{
    name: '',
    id: ''
  }];
  planTypeList : [];
  planGroup:any;
  public addChargeDetailsList: any[] = [];
  public addPlanList: any[] = [];
  dynamicrequiredArray:any[]=[];
  Plan_Details:any;
  Select_Plan_Type:any;
  Select_Service:any;
  inventorySupport: any;

  onSellPriceBlur(event){
    let minwithtax = this.planDtl.minPriceTax+this.planDtl.minPrice
    let maxwithtax = this.planDtl.maxPriceTax+this.planDtl.maxPrice
    // if(event){
    //   this.changeIncludeTax(this.isIncludeTax)
    // }
    //if(this.isSellPrice){
      //this.onClickAddPlanDetails();
    //}
  }
  isSellPrice:boolean = false;
  changeOverridePlanPrice(event){
    //this.isIncludeTax = true;
      if(event.target.checked == true){
        this.isSellPrice = true;
        //this.insertPlanDetailForm.get('isIncludeTax').enable();
      }else{
     this.isSellPrice = false;
    //  let temp = this.planDtl.offerprice + this.planDtl.taxamount;
    //  this.insertPlanDetailForm.get('sellPrice').setValue(temp)
    // this.insertPlanDetailForm.get('isIncludeTax').disable();
      }
      //this.setValidationToSellPrice(this.planDtl);
      //this.insertPlanDetailForm.get('sellPrice').markAsTouched();
  }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  taxAmount:any;
  isIncludeTax:boolean=true;
  changeIncludeTax(event){
    //this.setValidationToSellPrice(this.planDtl);
    //this.insertPlanDetailForm.get('sellPrice').markAsTouched();
    //this.taxAmount = 0;
    //this.onClickAddPlanDetails();
  }
  changeOverridePlanPriceCharge(event){
        
    if(event==="true"){
      this.isPriceOverrideReadOnly=false;
      this.isPriceOverrideCharge=true;
      this.insertChargeDetailForm.get('isPriceOverride').setValue(true);
        
    }else{
      this.isPriceOverrideReadOnly=true;
      this.isPriceOverrideCharge=false;
      this.insertChargeDetailForm.get('isPriceOverride').setValue(false);
        
    }

  }

  getStyle(){
    return this.commissiontype === 'Fixed' ? '35px' : '30px';  
  }

 // getVASByType() {
    // this.packagesService.getVASByType('VOICE').subscribe((res) => {
    //   if (res.dataList && res.dataList.length > 0) {
    //     res.dataList.map((res) => res.selected = false);
    //   }
    //   this.NEXG_VAS = res.dataList;
    // });
    // this.packagesService.getVASByType('OTT').subscribe((res) => {
    //   if (res.dataList && res.dataList.length > 0) {
    //     res.dataList.map((res) => res.selected = false);
    //   }
    //   this.VAS_OTT = res.dataList;
    // });
    // this.packagesService.getVASByType('IPTV').subscribe((res) => {
    //   if (res.dataList && res.dataList.length > 0) {
    //     res.dataList.map((res) => res.selected = false);
    //   }
    //   this.VAS_IPTV = res.dataList;
    // });
 // }

  deselectRadio(id, i){
    if(this.isAllowVAS){
      if(id == 'iptv'){
        this.VAS_IPTV.map((ls) => ls.selected =  this.isAllowVAS ? false : true);
          this.VAS_IPTV[i].selected = true;
      }
      if(id == 'voice'){
          this.NEXG_VAS.map((ls) => ls.selected =  this.isAllowVAS ? false : true);
          this.NEXG_VAS[i].selected = true;
      }
      if(id == 'ott'){
          this.VAS_OTT.map((ls) => ls.selected =  this.isAllowVAS ? false : true);
          this.VAS_OTT[i].selected = true;
      }

      let temp = []
      if(this.NEXG_VAS.length > 0){
        this.NEXG_VAS.forEach(res => {
          if(res.selected == true){
            temp.push(res.id)
          }
        })
      }
      
      if(this.VAS_OTT.length > 0){
        this.VAS_OTT.forEach(res => {
          if(res.selected == true){
            temp.push(res.id)
          }
        })
      }
      
      if(this.VAS_IPTV.length > 0){
        this.VAS_IPTV.forEach(res => {
          if(res.selected == true){
            temp.push(res.id)
          }
        })
      }
      this.insertPlanDetailForm.get('selectedVASIds').setValue(temp); 

      this.calculateVasPrice()
    }
  }

  calculateVasPrice(){
    this.vasprice = 0;
    let selectedVoiceArr = [];
    let selectedOTTArr = [];
    let selectedIPTVArr = [];
    this.NEXG_VAS.forEach((ls) =>{
        if(ls.selected){
          selectedVoiceArr.push(ls.name);
          this.vasprice += (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        }
    });

    if(selectedVoiceArr && selectedVoiceArr.length > 0){
      this.selectedVoiceStr = selectedVoiceArr.toString();
    }

    this.VAS_OTT.forEach((ls) =>{
        if(ls.selected){
          selectedOTTArr.push(ls.name);
          this.vasprice += (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        }
      }
    );

    if(selectedOTTArr && selectedOTTArr.length > 0){
      this.selectedOTTStr = selectedOTTArr.toString();
    }

    setTimeout(() => {
      this.VAS_IPTV.forEach((ls) =>{
          if(ls.selected){
            selectedIPTVArr.push(ls.name);
              this.vasprice +=  (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
          }
      });
      if(selectedIPTVArr && selectedIPTVArr.length > 0){
        this.selectedIPTVStr = selectedIPTVArr.toString();
      }
  }, 100);
    if(this.isAllowVAS)
    this.offerpricewithtax = Number(this.planDtl.offerprice) + Number(this.planDtl.taxamount); 
    else
    this.offerpricewithtax = Number(this.planDtl.offerprice) + Number(this.planDtl.taxamount) + Number(this.vasprice);

    this.insertPlanDetailForm.get('sellPrice').setValue((this.offerpricewithtax).toFixed(2));
}

  isIncludeTaxChange(e){
    
  if(e==="true"){
     this.isIncludeTaxflag=true;
     this.insertChargeDetailForm.get('overridePrice').updateValueAndValidity();
     let currentValue= this.insertChargeDetailForm.get('overridePrice').value;
    
   if((this.minChargeAmount !== null) && (this.maxChargeAmount !==null) && currentValue !== null && this.isIncludeTaxflag===true){
        this.isIncludeTaxflag=true;    
        this.OnCheckAPICallTaxCalculation();
       }
     }else{
      let currentValue= this.insertChargeDetailForm.get('overridePrice').value;
        if((this.minChargeAmount !== null) && (this.maxChargeAmount !==null) && currentValue !== null &&  this.isIncludeTaxflag===false){
         this.onOverridePrice();
      }
     }
   }

  
  onOverridePrice() {
    let AmountValue=this.insertChargeDetailForm.get('overridePrice').value;
    let obj = {
        amount: AmountValue,
        taxId: this.selectedchargeTaxId,
    };

  this.changePlanService.getTaxByAmount(obj).subscribe((res) => 
  {
  if(res.status===200){
   let latestFinalAmount=this.insertChargeDetailForm.get('overridePrice').value;
   
     if(this.isIncludeTaxflag=== false){
      this.chargeAmount=latestFinalAmount;
      this.taxAmount=Number(res.TaxAmount); 
      this.finalAmount= Number(latestFinalAmount) +Number(res.TaxAmount);
      this.hideLabelFlag=true;
    }
   
    if(this.finalAmount <= this.minChargeAmount && this.minChargeAmount !==null){
        this.tosterservice.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
        this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
        return;
     }else if(this.finalAmount >= this.maxChargeAmount && this.maxChargeAmount !==null)
     {
      this.tosterservice.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
      this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
      return;
     }
     }
  }
  );
    
}

onOverridePriceBlur(){
  
  if(this.insertChargeDetailForm.get('overridePrice').value !==null && this.isIncludeTaxflag===false){
    this.onOverridePrice();
  }else{
    this.OnCheckAPICallTaxCalculation();
  }
 }

 get value(): PlanServiceComponent {
    return this.insertPlanDetailForm.value, this.insertServicesDetailForm.value, this.insertChargeDetailForm.value;
  }

  set value(value: PlanServiceComponent) {
    this.insertPlanDetailForm.setValue(value);
    this.insertServicesDetailForm.setValue(value);
    this.insertChargeDetailForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }


  constructor(private planService: PlanServices,
    private ngbModalService: NgbModal,
    private voiceServiceType: VoiceServiceType,
    private intercomGroup: IntercomGroup,
    private chargeTypeService: ChargeTypeService,
    private messageService: MessageService,
    private pubsub: PubSubService,
    private tosterservice: ToastrService,
    private franchiesServise: FranchiesService,
    private applyChargeService:ApplyChargeService,
    private changePlanService:ChangePlanService,
    private commonlistservice:CommonListServicesService,
    private packagesService: NPackagesService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    public appConfig: AppConfig
  ) {
    this.getRequiredAllField();
    this.inventorySupport = appConfig.config.inventorySupport;
    this.subscriptions.push(
      this.insertServicesDetailForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    // this.subscriptions.push(
    //  this.insertChargeDetailForm.valueChanges.subscribe(value => {
    //    this.onChange(value);
    //    this.onTouched();
    // }));

    this.pubsub.$sub('plan_service_information_validation_fail').subscribe((data) => {
      console.log(data);
      this.submitted = true;
      this.validateAllFields(this.insertServicesDetailForm, this.insertChargeDetailForm);

      
    });

    this.pubsub.$sub('present_address_state_change').subscribe((data) => {
      ////console.log('present_address_state_change data:' + data);
      this.presentAddressStateChange = data;
    });

  }

  ngOnInit() {
    this.commissiontype = localStorage.getItem('partnerType');
    this.getPlanService();
    this.getVoiceServiceType();
    this.getIntercomGroup();
    this.getChargeForCustomer();
    if(!this.partnerid || this.tempPartnerId != this.partnerid){
      this.resetData();
    }
    if(this.partnerid!=null){
      this.getConfigBypartner(this.partnerid);
      this.tempPartnerId = this.partnerid
    }
    this.getPlanTypeList()
    //this.getVASByType()
  }

  resetData(){
    this.insertPlanDetailForm.reset();
    this.addPlanList = [];
    this.planDetailsList = [];
  }

  getPlanTypeList(){
    this.commonlistservice.getGenericCommonList('planTypeForNew').subscribe((res)=>{
      this.planTypeList = res.dataList
      .filter((ls) => {
        if (ls.value === 'All Plan' || ls.value === 'Registration') {
          return ls;
        }
      });
    })
  }

  getConfigBypartner(id){
    this.franchiesServise.getconfigByPartner(id).subscribe((res)=>{
      if(res.dataList.length>0){
        res.dataList.forEach((res)=>
        {
          if(res.clientServiceName=="Online Purchase" && res.value.toLowerCase()=='yes'){
            this.checkOnlineRenewal = true;
            this.insertServicesDetailForm.get('allow_online_renewal').setValue(true)
          }
        })
      }
      else{
        this.checkOnlineRenewal = false;
        this.insertServicesDetailForm.get('allow_online_renewal').setValue(false)
      }
    });
  }
  changedSMS(event){
    if(event.target.checked){
      this.insertServicesDetailForm.get('smsenable').setValue(true);
    }else{
      this.insertServicesDetailForm.get('smsenable').setValue(false);
    }
  }
  changedEmail(event){
    if(event.target.checked){
      this.insertServicesDetailForm.get('emailenable').setValue(true);
    }else{
      this.insertServicesDetailForm.get('emailenable').setValue(false);
    }
  }

  changeautoticket(event){
    if(event.target.checked){
      this.insertServicesDetailForm.get('checkEnableAutoticketing').setValue(true)
    }
    else{
      this.insertServicesDetailForm.get('checkEnableAutoticketing').setValue(false)
    }
  }
  changedVOIP(event){
    if(event.target.checked){
      this.insertServicesDetailForm.get('voipanble').setValue(true);
    }else{
      this.insertServicesDetailForm.get('voipanble').setValue(false);
    }
  }
  onChangeOnlineRenewal(el) {
    this.checkOnlineRenewal = el.target.checked;
    this.insertServicesDetailForm.get('allow_online_renewal').setValue(this.checkOnlineRenewal)
  }
  onChangePlanService(el) {
    this.planDtl = null;
    this.insertPlanDetailForm.get("sellPrice").clearValidators();
    this.insertPlanDetailForm.get("sellPrice").updateValueAndValidity();
    this.insertPlanDetailForm.get('paln_details').reset();
    this.offerpricewithtax = 0;
    this.planDetailsList = [];
    this.isSellPrice = false;
    this.VAS_IPTV = [];
    this.VAS_OTT = [];
    this.NEXG_VAS = [];
    let plan_id = el.id;
    
    //this.stateId &&
    if(this.partnerid) {
      this.getPostPaidPlanByService(plan_id, this.partnerid);
    }
    else if(this.partnerid == null || this.partnerid == undefined){
      this.tosterservice.error("Please Select Partner.");
    }else{
      this.tosterservice.error("");
    }
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  spaceNotAllow(event) {
    if (event.which == 32) {
      // this.tosterservice.error("Space Not Allowed");
      return true;
    }
  }

  
  onChangePlanType(event){
    this.offerpricewithtax = 0;
    this.VAS_IPTV = [];
    this.VAS_OTT = [];
    this.NEXG_VAS = [];
    this.insertPlanDetailForm.get('services').reset();
    this.insertPlanDetailForm.get('paln_details').reset();
    this.planDetailsList  = [];
    this.planDtl = null;
    this.insertPlanDetailForm.get("sellPrice").clearValidators();
    this.insertPlanDetailForm.get("sellPrice").updateValueAndValidity();
    this.planGroup = null;
    if(event && event.value){
      this.planGroup = event.value
    }
  }

  getPostPaidPlanByService(plan_id, partnerid) {
    let reqObj = {
      serviceType: plan_id,
      partnerId: partnerid,
      planGroup: this.planGroup
     }
    if(this.planGroup!=null){
      this.planService.getPostPaidPlanByServiceList(reqObj).subscribe((result) => {
        this.planDetailsList = result.dataList;
      });
    }
  }

  setValidationToSellPrice(planDtl){
    this.planDtl = planDtl;
    if(this.planDtl && this.planDtl !== null && !this.isIncludeTax){
      if(this.planDtl.minPrice && this.planDtl.maxPrice){
        this.insertPlanDetailForm.get("sellPrice").setValidators([Validators.min(this.planDtl.minPrice), Validators.max(this.planDtl.maxPrice)]);
      }else{
        this.insertPlanDetailForm.get("sellPrice").clearValidators();
        this.insertPlanDetailForm.get("sellPrice").updateValueAndValidity();
      }
      if(this.planDtl.minPrice && (this.planDtl.maxPrice === undefined || this.planDtl.maxPrice === null)){
        this.insertPlanDetailForm.get("sellPrice").setValidators([Validators.min(this.planDtl.minPrice)]);
      }
      if(this.planDtl.maxPrice && (this.planDtl.minPrice === undefined || this.planDtl.minPrice === null)){
        this.insertPlanDetailForm.get("sellPrice").setValidators([Validators.max(this.planDtl.maxPrice)]);
      }
      this.insertPlanDetailForm.get("sellPrice").updateValueAndValidity();
    }else{
      this.insertPlanDetailForm.get("sellPrice").clearValidators();
      this.insertPlanDetailForm.get("sellPrice").updateValueAndValidity();
    }
  }

  planDtl:any;
  onChangePlanDetails(el) {
    this.selectedVoiceStr = undefined;
    this.selectedOTTStr = undefined;
    this.selectedIPTVStr = undefined;
    this.planDtl = null;
    this.insertPlanDetailForm.get("sellPrice").clearValidators();
    this.insertPlanDetailForm.get("sellPrice").updateValueAndValidity();
    this.isSellPrice = false;
    this.isIncludeTax = true;
    this.offerpricewithtax = 0
    this.VAS_IPTV = [];
    this.VAS_OTT = [];
    this.NEXG_VAS = [];
    this.addPlanList = [];
    this.vasprice = 0;
    this.isAllowVAS = el.allowVasSelection;
    this.planDtl = el;
    this.offerpricewithtax = Number(this.planDtl.offerprice) + Number(this.planDtl.taxamount);
    if(el && el.vasdtos){
      if(!this.isAllowVAS)
        el.vasdtos.forEach(res=>res.selected = true);
      else
        el.vasdtos.forEach(res=>res.selected = false);

      this.VAS_IPTV = el.vasdtos.filter((ls) => ls.type == 'IPTV');
      this.VAS_OTT = el.vasdtos.filter((ls) => ls.type == 'OTT');
      this.NEXG_VAS = el.vasdtos.filter((ls) => ls.type == 'VOICE');
      if(!this.isAllowVAS){
        this.insertPlanDetailForm.get('selectedVASIds').setValue(el.vasdtos.map((ls) => ls.id)); 
        this.calculateVasPrice();
      }
    }else{
      this.VAS_IPTV = [];
      this.VAS_OTT = [];
      this.NEXG_VAS = [];
    }
   
    //+  this.vasprice;

    //this.insertPlanDetailForm.get('sellPrice').setValue((this.offerpricewithtax).toFixed(2));
    //this.setValidationToSellPrice(this.planDtl);
    let plan_id = el.id;
    // if (this.stateId) {
    //   let data: any = {
    //     "locationId": this.presentAddressStateChange.state_id,
    //     "planId": plan_id
    //   };
    // }
    if(this.partnerid == null){
      this.tosterservice.error("Please Select Partner.");
    }
    if(el !== null || undefined || 'undefind')
    {
      this.vasFlag = true;
    }
    else if(el == null || undefined || 'undefind')
    {
      this.vasFlag = false;
    }
    if(el){
      //this.onClickAddPlanDetails();
    }else{
      this.addPlanList = [];
    }
    
  }

  getPlanService() {
    this.planService.getPlanServiceList().subscribe((result) => {
      this.planServiceList = result.serviceList;
    });
  }

  getVoiceServiceType() {
    this.voiceServiceType.getVoiceServicesList().subscribe((result) => {
      this.voiceServiceTypeList = result.dataList;
    });
  }

  getIntercomGroup() {
    this.intercomGroup.getIntercomGroupList().subscribe((result) => {
      this.intercomGroupList = result.dataList;
    });
  }

  getChargeForCustomer() {
    this.chargeTypeService.getChargeForCustomerList().subscribe((result) => {
      if (result.chargelist) {
        result.chargelist.forEach(function (item) {
          let text = item.name ;
          item.text = text;
        });
        this.chargeForCustomerList = result.chargelist;
      }
    });
  }

  onChangeVoiceServiceType(el) {
    if (el && el.value) {
      let value = el.value;
      this.selected_change_voice_service_type = value; //PhoneLine , ShipTrunk , Intercom
      if (value === "PhoneLine") {
        this.insertServicesDetailForm.get('phoneline_did_no').setValidators([Validators.required]);
        this.insertServicesDetailForm.get('phoneline_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('did_no').reset();
        this.insertServicesDetailForm.get('did_no').clearValidators();
        this.insertServicesDetailForm.get('did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('child_did_no').reset();
        this.insertServicesDetailForm.get('child_did_no').clearValidators();
        this.insertServicesDetailForm.get('child_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_no').reset();
        this.insertServicesDetailForm.get('intercome_no').clearValidators();
        this.insertServicesDetailForm.get('intercome_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_group').reset();
        this.insertServicesDetailForm.get('intercome_group').clearValidators();
        this.insertServicesDetailForm.get('intercome_group').updateValueAndValidity();
      } else if (value === "ShipTrunk") {
        this.insertServicesDetailForm.get('did_no').setValidators([Validators.required]);
        this.insertServicesDetailForm.get('did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('child_did_no').setValidators([Validators.required]);
        this.insertServicesDetailForm.get('child_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('phoneline_did_no').reset();
        this.insertServicesDetailForm.get('phoneline_did_no').clearValidators();
        this.insertServicesDetailForm.get('phoneline_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_no').reset();
        this.insertServicesDetailForm.get('intercome_no').clearValidators();
        this.insertServicesDetailForm.get('intercome_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_group').reset();
        this.insertServicesDetailForm.get('intercome_group').clearValidators();
        this.insertServicesDetailForm.get('intercome_group').updateValueAndValidity();

      }
      else if (value === "Intercom") {
        this.insertServicesDetailForm.get('intercome_no').setValidators([Validators.required]);
        this.insertServicesDetailForm.get('intercome_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_group').setValidators([Validators.required]);
        this.insertServicesDetailForm.get('intercome_group').updateValueAndValidity();
        this.insertServicesDetailForm.get('phoneline_did_no').reset();
        this.insertServicesDetailForm.get('phoneline_did_no').clearValidators();
        this.insertServicesDetailForm.get('phoneline_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('did_no').reset();
        this.insertServicesDetailForm.get('did_no').clearValidators();
        this.insertServicesDetailForm.get('did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('child_did_no').reset();
        this.insertServicesDetailForm.get('child_did_no').clearValidators();
        this.insertServicesDetailForm.get('child_did_no').updateValueAndValidity();
      }
      else {
        this.insertServicesDetailForm.get('phoneline_did_no').reset();
        this.insertServicesDetailForm.get('phoneline_did_no').clearValidators();
        this.insertServicesDetailForm.get('phoneline_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('did_no').reset();
        this.insertServicesDetailForm.get('did_no').clearValidators();
        this.insertServicesDetailForm.get('did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('child_did_no').reset();
        this.insertServicesDetailForm.get('child_did_no').clearValidators();
        this.insertServicesDetailForm.get('child_did_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_no').reset();
        this.insertServicesDetailForm.get('intercome_no').clearValidators();
        this.insertServicesDetailForm.get('intercome_no').updateValueAndValidity();
        this.insertServicesDetailForm.get('intercome_group').reset();
        this.insertServicesDetailForm.get('intercome_group').clearValidators();
        this.insertServicesDetailForm.get('intercome_group').updateValueAndValidity();
      }
      ////console.log(value);
    } else {
      this.insertServicesDetailForm.get('phoneline_did_no').reset();
      this.insertServicesDetailForm.get('phoneline_did_no').clearValidators();
      this.insertServicesDetailForm.get('phoneline_did_no').updateValueAndValidity();
      this.insertServicesDetailForm.get('did_no').reset();
      this.insertServicesDetailForm.get('did_no').clearValidators();
      this.insertServicesDetailForm.get('did_no').updateValueAndValidity();
      this.insertServicesDetailForm.get('child_did_no').reset();
      this.insertServicesDetailForm.get('child_did_no').clearValidators();
      this.insertServicesDetailForm.get('child_did_no').updateValueAndValidity();
      this.insertServicesDetailForm.get('intercome_no').reset();
      this.insertServicesDetailForm.get('intercome_no').clearValidators();
      this.insertServicesDetailForm.get('intercome_no').updateValueAndValidity();
      this.insertServicesDetailForm.get('intercome_group').reset();
      this.insertServicesDetailForm.get('intercome_group').clearValidators();
      this.insertServicesDetailForm.get('intercome_group').updateValueAndValidity();
      this.selected_change_voice_service_type = '';
    }
  }

  deleteSelectedPlanDetails(index) {
    this.addPlanList.splice(index, 1);
    this.insertPlanDetailForm.get('paln_details').setValue(undefined);
    this.onChangePlanDetails(undefined);
  }

  deleteSelectedChargeDetails(index) {
    this.addChargeDetailsList.splice(index, 1);
  }
rowData:any;
 onClickAddPlanDetails() {
    let data = this.insertPlanDetailForm.value;
    if(this.insertPlanDetailForm.valid){
      if (data.paln_details && data.services) {
        let selected_plan_services = _.find(this.planServiceList, { id: data.services });
        let selected_plan_details = _.find(this.planDetailsList, { id: data.paln_details });
        let offerprice: any = parseFloat(selected_plan_details.offerprice);
        this.taxDetailsByPlanData = selected_plan_details.taxamount;
            if (this.taxDetailsByPlanData || this.taxDetailsByPlanData === 0) {
              offerprice = parseFloat(offerprice) + parseFloat(this.taxDetailsByPlanData.toFixed(2));
              this.rowData = {
                service_id: selected_plan_services.id,
                service_type: selected_plan_services.name,
                plan_id: selected_plan_details.id,
                planname: selected_plan_details.name,
                isIncludeTax:this.isIncludeTax,
                sellPrice:null,
                isPriceOverride:this.isSellPrice,
                plandetails: selected_plan_details.name,
                validity: selected_plan_details.validity,
                offerprice: offerprice + Number(this.isAllowVAS ? 0 : this.vasprice),
                selectedVASIds: this.insertPlanDetailForm.get('selectedVASIds').value,
                totalPrice: (Number(this.offerpricewithtax) + (this.isAllowVAS ? Number(this.vasprice) : 0) + Number(this.taxAmount?this.taxAmount:0)).toFixed(2)
              };
              if (this.addPlanList.length === 0) {
                this.addPlanList.push(this.rowData);
              }
              else {
                this.tosterservice.error("You can select Only One Plan.");
                // this.addPlanList = [];
                // this.addPlanList.push(this.rowData);
              }
        
            }
      } else {
        this.tosterservice.error("Please select Service and Plan Details.");
      }

    }else{
      Object.keys(this.insertPlanDetailForm.controls).forEach(field => {
        const control = this.insertPlanDetailForm.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({onlySelf: true});
        }
      });
    }
    
  }

  onClickAddChargeDetails(el) {
    if(this.isIncludeTaxflag===true){
    let AmountValue=this.insertChargeDetailForm.get('overridePrice').value;
       
    if(AmountValue < this.minChargeAmount && this.minChargeAmount !==null){
        this.tosterservice.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
        this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
        return;
    }
    else if(AmountValue > this.maxChargeAmount && this.maxChargeAmount !==null)
    {
      this.tosterservice.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
      this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
      return;
    }
     }else{
      
      this.finalAmount= this.finalAmount ? this.finalAmount: this.insertChargeDetailForm.get('overridePrice').value
      if(this.finalAmount < this.minChargeAmount && this.minChargeAmount !==null){
        this.tosterservice.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
        this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
        
        return;
        }
      else if(this.finalAmount > this.maxChargeAmount && this.maxChargeAmount !==null)
      {
        this.tosterservice.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
        this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
        return;
      }
     
     }

    let data = this.insertChargeDetailForm.value;

    if (data.charge_id && data.charge_date) {
      let selected_charge_details = _.find(this.chargeForCustomerList, { id: data.charge_id });
      this.taxDetailsByChargeData=selected_charge_details.taxamount;
      if (this.taxDetailsByChargeData === null) {
        this.taxDetailsByChargeData = 0;
      } 
      
      if(this.isIncludeTaxflag=== false){
      let rowData = {
          charge_type: selected_charge_details.chargecategory,
          charge_name: selected_charge_details.text,
          // offerprice: selected_charge_details.price + this.taxDetailsByChargeData,
          offerprice: this.finalAmount ? this.finalAmount : (selected_charge_details.price + this.taxDetailsByChargeData),
          actualprice: selected_charge_details.actualprice,
          charge_date: data.charge_date,
          chargeid: selected_charge_details.id,
         // overridePrice:selected_charge_details.overridePrice?selected_charge_details.overridePrice:null,
          overridePrice:this.insertChargeDetailForm.get('overridePrice').value ?this.insertChargeDetailForm.get('overridePrice').value:null,
          isPriceOverride:selected_charge_details.isPriceOverride,
          isIncludeTax:this.isIncludeTaxflag
          //selected_charge_details.isIncludeTax

        };
        this.addChargeDetailsList.push(rowData);
         this.finalAmount=null; 
         this.chargeAmount=null;
         this.taxAmount=null; 
         this.hideLabelFlag=false;
         this.insertChargeDetailForm.get('overridePrice').setValue(this.overridePriceTaxWithTax)
      }
      else if(this.isIncludeTaxflag===true){
        
        let rowData = {
          charge_type: selected_charge_details.chargecategory,
          charge_name: selected_charge_details.text,
          // offerprice: selected_charge_details.price + this.taxDetailsByChargeData,
          offerprice: this.insertChargeDetailForm.get('overridePrice').value,
          actualprice: selected_charge_details.actualprice,
          charge_date: data.charge_date,
          chargeid: selected_charge_details.id,
          overridePrice:this.insertChargeDetailForm.get('overridePrice').value ?this.insertChargeDetailForm.get('overridePrice').value:null,
          isPriceOverride:selected_charge_details.isPriceOverride,
          isIncludeTax:this.isIncludeTaxflag
          // selected_charge_details.isIncludeTax
        };
        this.addChargeDetailsList.push(rowData);
         this.finalAmount=null; 
         this.chargeAmount=null;
         this.taxAmount=null;
         this.hideLabelFlag=false;
        this.insertChargeDetailForm.get('overridePrice').setValue(this.overridePriceTaxWithTax)
      }
      
    } else {
      this.tosterservice.error("Please Select Charge and Charge Date");
    }
  }


  getPlanServiceListData() {
    // if(this.addPlanList.length > 0){
    //   if(!this.insertPlanDetailForm.get('sellPrice').valid){
    //     this.insertPlanDetailForm.get('sellPrice').markAsTouched();
    //     return false;
    //   }else{
    //     if(this.isSellPrice)
    //     {
    //       this.addPlanList[0].sellPrice = Number(this.insertPlanDetailForm.get('sellPrice').value);
    //     }
        
    //   }
    // }
    return this.addPlanList;
  }
  getChargeDetailsData() {
    return this.addChargeDetailsList;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  validateAllFields(insertServicesDetailForm, insertChargeDetailForm) {
    Object.keys(insertServicesDetailForm.controls).forEach(field => {
      const control = insertServicesDetailForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        // this.validateAllFields(control);
      }
    });

    Object.keys(insertChargeDetailForm.controls).forEach(fields => {
      const controls = insertChargeDetailForm.get(fields);
      if (controls instanceof FormControl) {
        controls.markAsTouched({ onlySelf: true });
      }
    });
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {

    if (this.insertServicesDetailForm.valid === true) {
      let formValue = this.insertServicesDetailForm.value;
      let voice_service_type = formValue.voice_service_type;
      if (voice_service_type === '') {
        return { planServiceInformation: { valid: false } };
      } else {
        // phone line DID No *
        // intercom   intercom no and group
        // ship trunk  / DID No ,child did no
        if (voice_service_type === "PhoneLine") {
          // if (formValue.phoneline_did_no.length === 0)  {
          //   this.isPhonelineDidNoValid = false;
          //   return { planServiceInformation: { valid: false } };
          // } else {
          //   this.isPhonelineDidNoValid = true;
          // }
        } else if (voice_service_type === "ShipTrunk") {
          if (formValue.did_no.length === 0) {
            this.shipTrunkDidNoValid = false;
          } else {
            this.shipTrunkDidNoValid = true;
          }
          if (formValue.child_did_no.length === 0) {
            this.shipTrunkChildDidNoValid = false;
          } else {
            this.shipTrunkChildDidNoValid = true;
          }
          if (formValue.did_no.length === 0) {
            return { planServiceInformation: { valid: false } };
          } else if (formValue.child_did_no.length === 0) {
            return { planServiceInformation: { valid: false } };
          }
        } else if (voice_service_type === "Intercom") {
          if (formValue.intercome_no.length === 0) {
            this.intercomeNoValid = false;
          } else {
            this.intercomeNoValid = true;
          }
          if (formValue.intercome_group.length === 0) {
            this.intercomGroupValid = false;
          } else {
            this.intercomGroupValid = true;
          }
          if (formValue.intercome_no.length === 0) {
            return { planServiceInformation: { valid: false } };
          } else if (formValue.intercome_group.length === 0) {
            return { planServiceInformation: { valid: false } };
          }
        }
      }
    } else if (this.insertServicesDetailForm.valid === false) {
      return { planServiceInformation: { valid: false } };
    } else if (this.insertChargeDetailForm.valid === false) {
      return { planServiceInformation: { valid: false } };
    } else {
      return null;
    }
  }
  get f() {
    return this.insertPlanDetailForm.controls;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  onChargeChange(charge) {
  
  this.hideLabelFlag=false;
  this.maxChargeAmount  =charge.maxamount;
  this.minChargeAmount  =charge.minamount;
  this.chargeDetails=charge;
  this.selectedchargeTaxId = charge.taxid

   if(charge.isPriceOverride===true){
    this.hideShowField=true;
    this.isPriceOverrideCharge=true;
    this.isPriceOverrideReadOnly=false;
    }else{
    this.hideShowField=false;
    this.isPriceOverrideCharge=false;
    this.isPriceOverrideReadOnly=true;
    }
  
    if(charge.isIncludeTax===true){
        this.isIncludeTaxflag=true;
      }else{
        this.isIncludeTaxflag=false;
    }
   
  this.overridePriceTaxWithTax=charge.price +charge.taxamount;
   if(charge.price && charge.taxamount){
     this.insertChargeDetailForm.get('overridePrice').setValue(this.overridePriceTaxWithTax)
      }else{
      this.insertChargeDetailForm.get('pricoverridePricee').setValue(null)
      }
   
    if (this.presentAddressStateChange && this.presentAddressStateChange.state_id) {
      let data: any = {
        "locationId": this.presentAddressStateChange.state_id,
        "chargeId": charge.id
      };
      // this.planService.getTaxDetailsByCharge(data).subscribe((result) => {
      //   this.taxDetailsByChargeData = result.TotalAmount;
      // });
    }
   }


  OnCheckAPICallTaxCalculation(){
    
    let AmountValue=this.insertChargeDetailForm.get('overridePrice').value;
    let obj = {
        amount: AmountValue,
        taxId: this.selectedchargeTaxId,
    };
    this.applyChargeService.getTaxByAmountOnChecked(obj).subscribe((res)=>{
      
    
      if(res.status===200){

        this.chargeAmount=res.ReverseTaxAmount;
        this.taxAmount= Number(AmountValue)-res.ReverseTaxAmount;
        this.finalAmount= Number(this.chargeAmount)+Number(this.taxAmount);
        this.hideLabelFlag=true;
        if(this.finalAmount < this.minChargeAmount && this.minChargeAmount !==null){
          this.tosterservice.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
          this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
          return;
       }else if(this.finalAmount > this.maxChargeAmount && this.maxChargeAmount !==null)
       {
        this.tosterservice.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
        this.insertChargeDetailForm.get('overridePrice').setErrors({'incorrect': true});
        return;
       }
      }        
        })

  }

  clearChargeChange(){

    this.chargeDetails=null;
    this.chargeAmount=null;
    this.taxAmount=null;
    this.finalAmount=null;
    this.isPriceOverrideCharge=false;
    this.isIncludeTaxflag=false;
    this.insertChargeDetailForm.get('overridePrice').setValue(null);
    this.insertChargeDetailForm.get('isPriceOverride').setValue(false);
    this.insertChargeDetailForm.get('isIncludeTax').setValue(false);

  }

  changeInventory(event){
    console.log('event =>',event);
    if(event.target.checked){
      this.insertServicesDetailForm.get('allocateInventory').setValue(true);
    }else{
      this.insertServicesDetailForm.get('allocateInventory').setValue(false);
    }
  }

  setFocusOnFirstElement(elementName: string, timeoutTime: number){
    setTimeout(()=>{
      if(!this.insertPlanDetailForm.get('planType').value)
      this.select.open();
           
    },timeoutTime)
  }

  openVasPopUpSCB(content)
  {
    this.ngbRef = this.ngbModalService.open(content, { size: 'xs'});
  }

  closeModal() {
    this.ngbModalService.dismissAll();
   }

   c(index) {
    this.addPlanList.splice(index, 1);
  }

  returnSelectedVasIds(){
    return this.insertPlanDetailForm.get('selectedVASIds').value
  }

  TotalCalc(){
    return (Number(this.addPlanList[0].totalPrice)+ Number(!this.isAllowVAS ? 0 : this.vasprice)).toFixed(2);
  }

  getRequiredAllField(){
  this.addSubscriberDetailsService.getRequiredAllField().subscribe(result=>
    {
      
      if (result.responseCode === 200) {
        this.dynamicrequiredArray = result.data;
        console.log("res==>"+result.data)
        this.Plan_Details = this.dynamicrequiredArray.find((e => e.fieldname === 'PlanDetails'));
        this.Select_Plan_Type = this.dynamicrequiredArray.find((e => e.fieldname === 'SelectPlanType'));
        this.Select_Service = this.dynamicrequiredArray.find((e => e.fieldname === 'SelectService'));
       if ((this.Select_Plan_Type !== null && this.Select_Plan_Type !== undefined) && this.Select_Plan_Type.isMandatory === true) {
          this.insertPlanDetailForm.get('planType').setValidators(Validators.required)
          this.insertPlanDetailForm.get('planType').updateValueAndValidity();
        }
        else {
          this.insertPlanDetailForm.get('planType').clearValidators()
          this.insertPlanDetailForm.get('planType').updateValueAndValidity();
        }
        
        if ((this.Select_Service !== null && this.Select_Service !== undefined) && this.Select_Service.isMandatory === true) {
          this.insertPlanDetailForm.get('services').setValidators(Validators.required)
          this.insertPlanDetailForm.get('services').updateValueAndValidity();
        }
        else {
          this.insertPlanDetailForm.get('services').clearValidators()
          this.insertPlanDetailForm.get('services').updateValueAndValidity();
        }

        if ((this.Plan_Details !== null && this.Plan_Details !== undefined) && this.Plan_Details.isMandatory === true) {
          this.insertPlanDetailForm.get('paln_details').setValidators(Validators.required)
          this.insertPlanDetailForm.get('paln_details').updateValueAndValidity();
        }
        else {
          this.insertPlanDetailForm.get('paln_details').clearValidators()
          this.insertPlanDetailForm.get('paln_details').updateValueAndValidity();
        }

    }
  }
    )}


}
