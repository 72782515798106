import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class SubscriberCdrService {
  constructor(private http: HttpClient) {

  }

  transformSubscriberCdrData(data) {
    let formatedData = [];
    data.dbcdrprocessinglist.forEach(function (item: any) {
      item.timestamp = data.timestamp;
      formatedData.push(item);
    });
    return { content: formatedData, pageDetails: data.pageDetails ? data.pageDetails : null, responseCode: data.status, responseMessage: data.ERROR ? data.ERROR:null};
  }

  getSubscriberCdrDataWithPageing(params): Observable<any> {
    const post_url = BASE_API_URL + 'dbcdrprocessing/filter';

    ////console.log("dara::", params)
    return this.http.post<any>(post_url, params,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return this.transformSubscriberCdrData(res);
        }),
        catchError((error: any) => {
          return throwError(error.error);
        })
      );
  }
  // searchSubscriberCdrService(data): Observable<any> {
  //   const post_url = BASE_API_URL + 'dbcdrprocessing/filter';
  //   return this.http.post<any>(post_url, data)
  // }
  excelServiceData(data) {
    const post_url = BASE_API_URL + "dbcdrprocessing/excelDownload";
    return this.http.post<any>(post_url, data, { observe: 'body', responseType: 'blob' as 'json' });
  }
  getUserdetailByUserName(username): Observable<any> {
    const get_url = BASE_API_URL + 'customers/byUserName/' + username;
    return this.http.get<any>(get_url);
  }

  getOldUserNameByUserName(data): Observable<any>{
    const get_url = BASE_API_URL + 'subscriber/getPreviousUsernameOfSubscriber/' + data;
    return this.http.get<any>(get_url);
  }
}
