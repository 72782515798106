export class vasselection{
    id:number;
    voiceService: any;
    ottService:any;
    iptvService:any;
}

export class vasList{
    id:number;
    voice: any;
    ott:any;
    iptv:any;
}