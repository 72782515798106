import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { ChangeExpiryService } from './change-expiry.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-expiry',
  templateUrl: './change-expiry.component.html',
  styleUrls: ['./change-expiry.component.scss']
})
export class ChangeExpiryComponent implements OnInit {

  @Input() custId: any;
  selectExpiry: boolean;
  isInputDays: boolean;
  activePlanList: any = null;
  expiryPlanList: any = null;
  activeflag: boolean = true;
  expiredflag: boolean = false;
  RevisedExpiry: any = '';
  expiry_date_picker: boolean;
  errorMsg: string;
  planList: any = [{
  }]
  ischecked:boolean=false;
  isReversed:boolean=false;
  serviceList: any[] = [
    {}];
  adjustmentType = [
    { name: 'Add', value: 'add' },
    { name: 'Absolute', value: 'absolute' }
 ]
  serviceListFlag: boolean;
  submitted: boolean;
  planMapId: any;
  isactiveplan: boolean;

  insertChangeExpiryForm: FormGroup;
  changeExpiry: any;
  expiryDate: any;

  serviceName: any;
  planName: any;
  planId: any;
  activeDate: Date;

  constructor(
    private customModalService: CustomModalService,
    private cdRef: ChangeDetectorRef,
    private changeExpiryService: ChangeExpiryService,
    private datepipe: DatePipe,
    private tosterservice: ToastrService
  ) { }
  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  ngOnInit() {
    this.setValidation();
    this.getChangeExpiry();
  }

  setValidation()
  {
    this.insertChangeExpiryForm = new FormGroup({
      plan: new FormControl(''),
      service: new FormControl(''),
      planId: new FormControl(''),
      remarks: new FormControl('', [Validators.required]),
      currentExpiryDate: new FormControl(''),
      revisedExpiryDate: new FormControl('', [Validators.required]),
      planmapid: new FormControl(),
      adjustType: new FormControl('', Validators.required)
    })
  }

  getChangeExpiry() {
    this.changeExpiryService.getChnageExpiry(this.custId).subscribe(result => {
      let data = result.data;
      if (data.activePlanList.length !== 0) {
        this.changeExpiry = result.data.activePlanList;
        if (this.changeExpiry != null) {
          for (let service of this.changeExpiry) {
            if (this.changeExpiry.length > 1) {
              this.serviceListFlag = true;
              this.planName = service.planName;
              this.planId = service.planId;
              this.activeDate=service.startDateString; /**New add */
              this.serviceName = service.service;
              this.serviceList.push(this.serviceName);
              this.planList.push(this.planName);
            } else if (this.changeExpiry.length == 1) {
              this.serviceListFlag = false;
              this.serviceName = service.service;
              this.planName = service.planName;
              this.planId = service.planId;
              this.planMapId = service.planmapid;
              this.expiryDate = service.expiryDateString;
              this.activeDate=service.startDateString; /**New add */
             
            } else {
              this.serviceListFlag = true;
            }
          }
          this.isactiveplan = false;
        }
      } else {
        this.changeExpiry = result.data.expiryPlanList;
        if (this.changeExpiry != null) {
          for (let service of this.changeExpiry) {
            if (this.changeExpiry.length > 1) {
              this.serviceListFlag = true;
              this.planName = service.planname;
              this.planId = service.planid;
              this.serviceName = service.service;
              this.activeDate=service.startDateString; /**New add */
              this.serviceList.push(this.serviceName);
              this.planList.push(this.planName);
            } else if (this.changeExpiry.length == 1) {
              this.serviceListFlag = false;
              this.serviceName = service.service;
              this.planName = service.planname;
              this.planId = service.planid;
              this.planMapId = service.planmapid;
              this.expiryDate = service.expiryDateString;
              this.activeDate=service.startDateString; /**New add */
            } else {
              this.serviceListFlag = true;
            }
          }
        }
        this.isactiveplan = true;
      }
    });
  }
  setChangePlanExpiryFormData() {
    let ExpiryPlan: any = {};
    if (this.planList.length > 1) {
      ExpiryPlan.plan = this.insertChangeExpiryForm.get('plan').value;
    } else {
      ExpiryPlan.plan = this.planName;
    }
    if (this.serviceList.length > 1) {
      ExpiryPlan.service = this.insertChangeExpiryForm.get("service").value;
    } else {
      ExpiryPlan.service = this.serviceName;
    }
    if (this.RevisedExpiry == '') {
      this.errorMsg = "revised expiry date is required";
    }

    ExpiryPlan.planmapid = this.planMapId;
    ExpiryPlan.planId = this.planId;
    ExpiryPlan.currentExpiryDate = this.expiryDate;
    ExpiryPlan.revisedExpiryDate = this.RevisedExpiry;
    this.insertChangeExpiryForm.patchValue(ExpiryPlan);
    delete this.insertChangeExpiryForm.value.adjustmentType; //Delete Key
  }
  
  SelectedExpiry(event: any) {
  
    
    this.RevisedExpiry = "";
    var expirydate = new Date(this.datepipe.transform(this.expiryDate, 'yyyy-MM-dd')).valueOf();
    var reviseddate = new Date(this.datepipe.transform(event.value, 'yyyy-MM-dd')).valueOf();
   
    var reversedate = new Date(this.datepipe.transform(event.value, 'yyyy-MM-dd')).valueOf();
    var activatedDate =new Date(this.datepipe.transform(this.activeDate, 'yyyy-MM-dd')).valueOf(); 
        
    var AddOnedayInActiveDate = new Date(this.activeDate); // One day Plus
    var currentActivePlus = AddOnedayInActiveDate.setDate(AddOnedayInActiveDate.getDate() + 1);

    var todaysDate=new Date(this.datepipe.transform(new Date(),'yyyy-MM-dd')).valueOf();
    
   
    if (reviseddate > expirydate) {
      this.RevisedExpiry = event.value;
      this.errorMsg = "";
    }
    else if(currentActivePlus > reversedate){
      this.RevisedExpiry="";
      // this.errorMsg = `Could not select less than activation date ${this.activeDate}`;
      this.errorMsg = "Could not select less than activation date";
    }
    else if(todaysDate > activatedDate){
      this.RevisedExpiry = event.value;
      this.errorMsg = "";
    }
    else 
    {
      /**Old Code */
     // this.RevisedExpiry = "";
     // this.errorMsg = "Please select revised date more than current expiry date";
     this.RevisedExpiry = event.value;
     this.errorMsg = "";
    }
       
       
    
  }
  InputDaysExpiry(value) {
    let days = +value;
    if (days != 0) {
      var date = new Date(this.expiryDate);
      this.RevisedExpiry = date.setDate(date.getDate() + days);
      this.errorMsg = "";
    } else {
      this.errorMsg = "Revised expiry date is required";
      this.RevisedExpiry = "";
    }
  }

  onChangePlan(event) {
    if (event) {
      this.planMapId = event.planmapid;
      this.expiryDate = event.expiryDate;
    }
  }
  
  adjustType(event) {

    if (event.value === 'absolute') {
      this.RevisedExpiry = "";
      this.RevisedExpiry = this.expiryDate;
      this.selectExpiry = true;
      this.isInputDays = false;
    } else {
      this.RevisedExpiry = "";
      this.selectExpiry = false;
      this.isInputDays = true;

    }
  }

  changeReverseDay(event){
  
  if (event.target.checked == true) {
    this.ischecked = true;
  }else{
    this.ischecked = false;
  }

  }

  onClickSaveForm() {
    this.setChangePlanExpiryFormData();
    if (this.insertChangeExpiryForm.valid === true) {
      delete this.insertChangeExpiryForm.value.adjustType;
      let self = this;

      alertify.confirm("Change Expiry", "Are You Sure You want to Change Expiry ?",
        function () {
          self.insertChangeExpiryOnDb();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertChangeExpiryForm.markAllAsTouched();
    }
  }

  insertChangeExpiryOnDb() {
    if (this.insertChangeExpiryForm.valid === true) {
      let createDate = new DatePipe('en-US').transform(this.RevisedExpiry, 'yyyy-MM-dd');
      var createExpiry = new DatePipe('en-US').transform(this.expiryDate, 'yyyy-MM-dd');
      let formatedInputData: any = this.insertChangeExpiryForm.value;
      formatedInputData.custId = this.custId;
      formatedInputData.currentExpiryDate = createExpiry;
      formatedInputData.revisedExpiryDate = createDate;
      this.changeExpiryService.insertChangeExpiry(formatedInputData).subscribe(result => {
        this.cdRef.detectChanges();
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Change expiry has been created successfully');
          this.customModalService.close('change-expiry', result);
          this.closeModal('change-expiry')
          
        }
      },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.submitted = false;
    this.setValidation()
    this.errorMsg = null;
    }
}
