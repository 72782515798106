import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { EditorModule } from "@tinymce/tinymce-angular";
import { TextMaskModule } from "angular2-text-mask";
import { Ng2TableModule } from "ng2-table";
import { AlertModule, BsDropdownModule, PaginationModule, TooltipModule } from "ngx-bootstrap";
import {SharedModule} from '../../layout/shared.module';
import { SystemConfigurationComponent } from './system-configuration/system-configuration.component';
import { UtilsModule } from '../../layout/utils/utils.module';
import { JqSparklineModule } from '../../components/sparkline/sparkline.module';
import { WidgetModule } from '../../layout/widget/widget.module';
import { BootstrapWizardModule } from '../../components/wizard/wizard.module';
import { BroadcastComponent } from "./broadcast/broadcast.component";
import { MatDatepickerModule, MatFormField, MatFormFieldModule, MatNativeDateModule, MatRadioButton, MatRadioModule, MAT_DATE_LOCALE } from "@angular/material";
import { AppGuard } from '../../app.guard';
import { AclConstants } from '../aclOperationConstants';
import { AclClassConstants } from '../aclClassConstants';

//import {AclGernericComponentComponent} from '@neoboss/pages/generic-component/acl/acl-gerneric-component/acl-gerneric-component.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NotificationComponent } from './notification/notification.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AuditComponent } from './audit/audit.component';
//import { AclGernericComponentComponent } from "../generic-component/acl/acl-gerneric-component/acl-gerneric-component.component";

import { AddBillingTemplateComponent } from './add-billing-template/add-billing-template.component';
import { BillingtemplateComponent } from './billing_template/billing_template.component';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { MasterFormsModule } from "../masters/master_forms.module";
import { AuditLogGenericComponent } from "../generic-component/audit-log-generic/audit-log-generic.component";
import { AddCityComponent } from "../masters/add-city/add-city.component";
import { NotificationGroupComponent } from './notification-group/notification-group.component';
import { AddNotificationGroupComponent } from './add-notification-group/add-notification-group.component';
import { BoradcastListComponent } from './broadcast/boradcast-list/boradcast-list.component';

export const routes =[
    { path: '', redirectTo: 'system_configuration', pathMatch: 'full' },
    {
      path: 'system_configuration',
      // canActivate: [AppGuard],
      // data: {
      //   operationId: AclConstants.OPERATION_SYSTEM_CONFIG_VIEW,
      //   classId:AclClassConstants.ACL_SYSTEM_CONFIG,
      //   accessIdForAllOpreation :AclConstants.OPERATION_SYSTEM_CONFIG_ALL,
      //   operation:'system configuration'
      // },
      component : SystemConfigurationComponent
    },
    {
      path: 'broadcast',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_BROAD_CAST_VIEW,
        classId:AclClassConstants.ACL_BROADCAST,
        accessIdForAllOpreation :AclConstants.OPERATION_BROAD_CAST_ALL,
        operation:'broadcast'
      },
      component: BroadcastComponent
    },
    { 
      path: 'notification', 
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_NOTIFICATION_VIEW,
        classId:AclClassConstants.ACL_NOTIFICATION,
        accessIdForAllOpreation :AclConstants.OPERATION_NOTIFICATION_ALL,
        operation:'notification'
      },
      component: NotificationComponent 
    },
    {
      path:'notification-group',
      canActivate:[AppGuard],
      data: {
        operationId: AclConstants.OPERATION_NOTIFICATION_GROUP_VIEW,
        classId:AclClassConstants.ACL_NOTIFICATION_GROUP,
        accessIdForAllOpreation :AclConstants.OPERATION_NOTIFICATION_GROUP_ALL,
        operation:'notification-group'
      },
      component:NotificationGroupComponent
    },
    {
      path:'notification-group/notification-group-add',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_NOTIFICATION_GROUP_VIEW,
        classId:AclClassConstants.ACL_NOTIFICATION_GROUP,
        accessIdForAllOpreation :AclConstants.OPERATION_NOTIFICATION_GROUP_ALL,
        operation:'Add notification-group'
      },
      component:AddNotificationGroupComponent
    },
    {
      path:'notification-group/notification-group-add/:id',
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_NOTIFICATION_GROUP_EDIT,
        classId:AclClassConstants.ACL_NOTIFICATION_GROUP,
        accessIdForAllOpreation :AclConstants.OPERATION_NOTIFICATION_GROUP_ALL,
        operation:'Edit notification-group'
      },
      component:AddNotificationGroupComponent
    },
    // {
    //   path:'notification-group/notification-group-add/:id/:id2',
    //   component:AddNotificationGroupComponent
    // },
    { 
      path: 'notification/add-notification', 
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_NOTIFICATION_ADD,
        classId:AclClassConstants.ACL_NOTIFICATION,
        accessIdForAllOpreation :AclConstants.OPERATION_NOTIFICATION_ALL,
        operation:'Add notification'
      },
      component: AddNotificationComponent 
    },
    {
      path: 'notification/add-notification', 
      component:AddNotificationComponent
    },
    { 
      path: 'notification/add-notification/:id', 
      canActivate: [AppGuard],
      data: {
        operationId: AclConstants.OPERATION_NOTIFICATION_EDIT,
        classId:AclClassConstants.ACL_NOTIFICATION,
        accessIdForAllOpreation :AclConstants.OPERATION_NOTIFICATION_ALL,
        operation:'Edit notification'
      },
      component: AddNotificationComponent 
    },
    {
      path:'notification/add-notification/:id/:id2',
      component:AddNotificationGroupComponent
    },
    { 
      path: 'billingtemplate', 
      canActivate: [AppGuard],
      data: {
      operationId: AclConstants.OPERATION_BILLING_VIEW,
      classId:AclClassConstants.ACL_TEMPLATE,
      accessIdForAllOpreation :AclConstants.OPERATION_BILLING_ALL,
      operation:'Billing Template'
      },


      component: BillingtemplateComponent 
    },
    { 
      path: 'billingtemplate/add-billingtemplate', 
      canActivate: [AppGuard],
      data: {
      operationId: AclConstants.OPERATION_BILLING_ADD,
      classId:AclClassConstants.ACL_TEMPLATE,
      accessIdForAllOpreation :AclConstants.OPERATION_BILLING_ALL,
      operation:'Billing Template'
      },
      component: AddBillingTemplateComponent 
    },
    { 
      path: 'billingtemplate/add-billingtemplate/:id', 
      canActivate: [AppGuard],
        data: {
        operationId: AclConstants.OPERATION_BILLING_EDIT,
        classId:AclClassConstants.ACL_TEMPLATE,
        accessIdForAllOpreation :AclConstants.OPERATION_BILLING_ALL,
        operation:'Billing Template'
        },
      component: AddBillingTemplateComponent 
    },
    
    {
    path: 'audit', 
    canActivate: [AppGuard],
    data: {
      operationId: AclConstants.OPERATION_AUDIT_LOG_VIEW,
      classId:AclClassConstants.ACL_AUDIT_LOG,
      accessIdForAllOpreation :AclConstants.OPERATION_AUDIT_LOG_VIEW,
      operation:'AuditLog Report'
    },
    component: AuditComponent 
  }
];

@NgModule({
    declarations: [
    SystemConfigurationComponent,
    BroadcastComponent,
   // AclGernericComponentComponent,
    NotificationComponent,
    AuditComponent,
    AddBillingTemplateComponent,
    BillingtemplateComponent,
    AddNotificationComponent,
    AuditLogGenericComponent,
    NotificationGroupComponent,
    AddNotificationGroupComponent,
    BoradcastListComponent
  ],
    imports: [
      SharedModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      TextMaskModule,
      TooltipModule.forRoot(),
      AlertModule.forRoot(),
      BsDropdownModule.forRoot(),
      WidgetModule,
      BootstrapWizardModule,
      RouterModule.forChild(routes),
      EditorModule,
      JqSparklineModule,
      PaginationModule.forRoot(),
      UtilsModule,
      Ng2TableModule,
      NgxDatatableModule,
      NgSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatSlideToggleModule,
      AngularEditorModule,
      MatRadioModule,
      MatFormFieldModule,
//      MasterFormsModule,//add new:Vijay
    ],
    providers: [
      { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }
    ]
    // entryComponents:[
    //   AddBillingTemplateComponent
    // ]
  })
  export class OperationsModule {
    static routes = routes;
  }
