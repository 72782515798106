import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IpPoolConfigService } from '../service/ip-pool-config.service';
import _ from 'underscore';
import { IpPoolConfig } from '../model/ip-pool-config';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../../core/constant/response-code';
import { HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AeSelectComponent } from '@kolkov/angular-editor/lib/ae-select/ae-select.component';
import { DataSharingServiceService } from '../../../data-sharing-service.service';

export interface DialogPassData{
  id:number;
}

@Component({
  selector: 'app-ip-pool-config-detail',
  templateUrl: './ip-pool-config-detail.component.html',
  styleUrls: ['./ip-pool-config-detail.component.scss']
})
export class IpPoolConfigDetailComponent implements OnInit {
  saveBtn: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  public sub: any = null;
  public ipPoolId: any = null;
  poolId:any;
  isDisabled:boolean=false;
  IpPrefix:boolean;
  show_update: any = false;
  selectForUpdate: any;
  defaultPool: any;
  staticPooltype: any;
  ipPoolConfig: IpPoolConfig;
  error_message = "This field is required.";
  ipprefix_error_message: string;
  selectedStaticIpPool: any = true;
  statusList: any[] = [
    { value_field: 'active', display_field: 'Active' },
    { value_field: 'inActive', display_field: 'Inactive' }
  ];
  insertIpPoolForm = new FormGroup({
    poolName: new FormControl('', [Validators.required]),
    displayName: new FormControl('', [Validators.required]),
    poolCategory: new FormControl('', [Validators.required]),
    poolType: new FormControl(''),
    defaultPoolFlag: new FormControl(false),
    isStaticIpPool: new FormControl(true, [Validators.required]),
    ipRange: new FormControl(''),
    networkIp: new FormControl(''),
    netMask: new FormControl(''),
    broadcastIp: new FormControl(''),
    firstHost: new FormControl(''),
    lastHost: new FormControl(''),
    totalHost: new FormControl(''),
    status: new FormControl('', [Validators.required]),
    remark: new FormControl('')
  });
  disabledValue: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private ipPoolConfigService: IpPoolConfigService,
    private tosterservice: ToastrService,
    private sharingdataService:DataSharingServiceService,
    loginService: LoginService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<IpPoolConfigDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {
    this.loginService = loginService;
  }

  ngOnInit() {
   
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.ipPoolId = id;
      this.show_update = true;
      this.isDisabled=false;
      this.getIpPoolData();
    }

    // if(this.data && this.data.id){
    //   this.ipPoolId=this.data.id;
    //   this.isDisabled=true;
    //   this.getIpPoolData();
    //   this.insertIpPoolForm.disable();
    // }
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.ipPoolId = params['id'];
    //       this.show_update = false;
    //       this.getIpPoolData();
    //     }
    //   });
    this.onChanges();
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  onChanges() {
    this.insertIpPoolForm.get('poolCategory').valueChanges.subscribe(
      value => {
        if (value === "ipv6") {
          this.insertIpPoolForm.controls.poolType.reset();
          this.insertIpPoolForm.controls.poolType.disable();
        } else {
          this.insertIpPoolForm.controls.poolType.enable();
        }
      }
    )
  }

  onchangeStaticIpPool(el) {
    this.selectedStaticIpPool = el;
  }

  spaceNotAllow(event) {
    if (event.which == 32) {
      // alertify.error("Space Not Allowed");
      return true;
    }
  }

  onIpRange(event: any) {
    const ipPoolId = this.ipPoolId ? this.ipPoolId : 0;
    this.ipPoolConfigService.getIpAddress(event.target.value, ipPoolId).subscribe(result => {
      if (result.responseCode != 200) {
        this.IpPrefix = true;
        this.ipprefix_error_message = result.responseMessage;
        this.tosterservice.error(result.responseMessage);
      } else {
        this.IpPrefix = false;
        this.ipprefix_error_message = '';
        let data = result.data;
        this.setValueInForm(data);
      }
    })
  }
  clearValidatorOnUpdateTime(defaultPoolFlag,staticPoolflag){
    this.selectedStaticIpPool=staticPoolflag
    this.staticPooltype=staticPoolflag;
    if(defaultPoolFlag){
      this.insertIpPoolForm.get('poolCategory').reset();
      this.insertIpPoolForm.get('poolCategory').clearValidators();
      this.insertIpPoolForm.get('poolCategory').updateValueAndValidity();
      this.insertIpPoolForm.get('poolType').reset();
      this.insertIpPoolForm.get('defaultPoolFlag').setValue(defaultPoolFlag);
      this.insertIpPoolForm.get('isStaticIpPool').setValue(staticPoolflag);
      this.insertIpPoolForm.get('isStaticIpPool').clearValidators();
      this.insertIpPoolForm.get('isStaticIpPool').updateValueAndValidity();
    }else{
      this.insertIpPoolForm.get('poolCategory').setValidators(Validators.required);
      this.insertIpPoolForm.get('poolCategory').updateValueAndValidity();
      this.insertIpPoolForm.get('defaultPoolFlag').setValue(defaultPoolFlag);
      this.insertIpPoolForm.get('isStaticIpPool').setValue(staticPoolflag);
      this.insertIpPoolForm.get('isStaticIpPool').setValidators(Validators.required);
      this.insertIpPoolForm.get('isStaticIpPool').updateValueAndValidity();
    }
  }
  clearValidatorByIpdefault(event) {
    if (event) {
      if (event === true) {
        this.selectedStaticIpPool=false;
        this.staticPooltype=false;
        this.insertIpPoolForm.get('poolCategory').reset();
        this.insertIpPoolForm.get('poolCategory').clearValidators();
        this.insertIpPoolForm.get('poolCategory').updateValueAndValidity();
        this.insertIpPoolForm.get('poolType').reset();
        this.insertIpPoolForm.get('defaultPoolFlag').setValue(true);
        this.insertIpPoolForm.get('isStaticIpPool').setValue(false);
        this.insertIpPoolForm.get('isStaticIpPool').clearValidators();
        this.insertIpPoolForm.get('isStaticIpPool').updateValueAndValidity();

      } else {
        this.selectedStaticIpPool=true;
        this.staticPooltype=true;
        this.insertIpPoolForm.get('poolCategory').setValidators(Validators.required);
        this.insertIpPoolForm.get('poolCategory').updateValueAndValidity();
        this.insertIpPoolForm.get('defaultPoolFlag').setValue(false);
        this.insertIpPoolForm.get('isStaticIpPool').setValue(true);
        this.insertIpPoolForm.get('isStaticIpPool').setValidators(Validators.required);
        this.insertIpPoolForm.get('isStaticIpPool').updateValueAndValidity();
      }
    } else {
      this.selectedStaticIpPool=true;
      this.staticPooltype=true;
      this.insertIpPoolForm.get('poolCategory').setValidators(Validators.required);
      this.insertIpPoolForm.get('poolCategory').updateValueAndValidity();
      this.insertIpPoolForm.get('defaultPoolFlag').setValue(false);
      this.insertIpPoolForm.get('isStaticIpPool').setValue(true);
      this.insertIpPoolForm.get('isStaticIpPool').setValidators(Validators.required);
      this.insertIpPoolForm.get('isStaticIpPool').updateValueAndValidity();
    }
  }

  validateForm() {
    if (!this.insertIpPoolForm.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.insertIpPoolForm.controls).forEach(field => {
        const control = this.insertIpPoolForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.ipPoolId === null) {
        this.insertIpPoolOnDb();
      } else {
        this.updateIpPoolOnDb();
      }
    }
  }

  getIpPoolData() {
    let ipPoolId = this.ipPoolId;
    this.ipPoolConfigService.getIpPoolConfigById(ipPoolId).subscribe(
      result => {
        this.ipPoolConfig = result.data;
        this.setUpdateValueInForm(this.ipPoolConfig);
      });
  }

  setValueInForm(staff_Data) {
    this.insertIpPoolForm.get('netMask').patchValue(staff_Data.netMask);
    this.insertIpPoolForm.get('networkIp').patchValue(staff_Data.networkIp);
    this.insertIpPoolForm.get('broadcastIp').patchValue(staff_Data.broadcastIp);
    this.insertIpPoolForm.get('firstHost').patchValue(staff_Data.firstHost);
    this.insertIpPoolForm.get('lastHost').patchValue(staff_Data.lastHost);
    this.insertIpPoolForm.get('totalHost').patchValue(staff_Data.totalHost);
  }

  setUpdateValueInForm(staff_Data) {
    this.show_update = true;
    this.selectForUpdate = staff_Data;
    this.clearValidatorByIpdefault(staff_Data.defaultPoolFlag);
    this.clearValidatorOnUpdateTime(staff_Data.defaultPoolFlag,staff_Data.isStaticIpPool);
    this.insertIpPoolForm.patchValue(staff_Data);
  }

  insertIpPoolOnDb() {
    if (this.insertIpPoolForm.valid === true) {
      let formatedInputData: any = this.insertIpPoolForm.value;
      let validForm: any = true;
      if (formatedInputData.isStaticIpPool === true) {
        if (formatedInputData.ipRange && formatedInputData.ipRange.trim().length == 0) {
          validForm = false;
          this.tosterservice.error("Enter Ip / PreFix");
          return false;
        }
      }
      if (validForm === true) {
        this.saveBtn = true;
        this.ipPoolConfigService.insertIpPoolConfig(formatedInputData).subscribe(result => {
          if (result.responseCode === ResponseCode.OK) {
            this.saveBtn = false;
            this.cdRef.detectChanges();
            this.onBackToIpPool();
            ////console.log("ress", result);
            this.tosterservice.success('Ip Pool has been created successfully');
          }
          else {
            this.saveBtn = false;
            this.tosterservice.error(result.responseMessage);
          }
        },
          err => {
            this.saveBtn = false;
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }
    }
  }

  updateIpPoolOnDb() {
    if (this.insertIpPoolForm.valid === true) {
      let apiInputData = Object.assign({}, this.insertIpPoolForm.value);
      let ipPoolId = this.ipPoolId;
      let formatedInputData: any = this.insertIpPoolForm.value;
      formatedInputData.poolId = ipPoolId;
      let validForm: any = true;
      if (formatedInputData.isStaticIpPool === true) {
        if (formatedInputData.ipRange && formatedInputData.ipRange.trim().length == 0) {
          validForm = false;
          this.tosterservice.error("Enter Ip / PreFix");
          return false;
        }
      }

      if (validForm === true) {
        this.saveBtn = true;
        ////console.log("fomr", formatedInputData)
        this.ipPoolConfigService.updateIpPoolConfig(formatedInputData).subscribe(result => {
          if (result.responseCode === ResponseCode.OK) {
            this.saveBtn = false;
            this.cdRef.detectChanges();
            this.show_update = false;
            this.onBackToIpPool();
            this.tosterservice.success('Ip Pool has been Updated successfully');
          }
          else {
            this.saveBtn = false;
            ////console.log(result)
            this.tosterservice.error(result.responseMessage);
          }
        },
          err => {
            this.saveBtn = false;
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          });
      }
    }
  }

  onBackToIpPool() {
    //this.router.navigate(['network-management/ip_pool_list']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['network-management/ip_pool_list']);
    }
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
