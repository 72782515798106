import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { AclClassConstants } from '../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../pages/aclOperationConstants';
import { CaseReasonModel } from '../../../../pages/customer-care/case-reason/model/case-reason-model';
import { CaseReasonService } from '../../../../pages/customer-care/case-reason/service/case-reason.service';
import { LoginService } from '../../../../pages/login/login.service';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { BroadcastModel } from '../model/BroadcastModel';
import { BroadcastService } from '../service/Broadcast.service';

@Component({
  selector: 'app-boradcast-list',
  templateUrl: './boradcast-list.component.html',
  styleUrls: ['./boradcast-list.component.scss']
})
export class BoradcastListComponent extends AbstractBaseListComponent<any> implements OnInit, OnDestroy {
  dataGrid: any[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  searchSettings = [];
  neoTableSetting:any={};
  public entity: any;
  public entity_id: any = null;
  form: FormGroup;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('caseReason', { static: false }) caseReasonReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  constructor(private entityService : BroadcastService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    loginService: LoginService,
    private searchService: SearchService) {
      super(toastrService, searchService);
      this.loginService = loginService;
    }

  ngOnInit() {
  this.setDefaultPageSize(this.tableKeyEnum.BROADCAST_HISTORY)
    this.init();
    //this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  getService(): IBaseService<BroadcastModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'createdate',
        default: 'Date',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'emailsubject',
        default: 'Subject',
        rowKey: 'emailsubject',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'type',
      //   default: 'Type',
      //   rowKey: 'type',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'templateName',
        default: 'Template Name',
        rowKey: 'templateName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'createdByName',
        default: 'Broadcast By',
        rowKey: 'createdByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    //Sthis.getTableSetting();
    this.getTableSearchSetting();
    return this.allColumns ? this.allColumns : [];
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.caseReasonReff];
      }
    });
  }

  getTableSetting() {
    const serviceAreaNeoGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.BROADCAST_HISTORY,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      showFilter:false,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Broadcast History?',
      deleteMsgBoxDetail: 'Broadcast History Name :${name} ',
    };
    return serviceAreaNeoGridSettings;
  }

  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Date',
        value_field: 'createdate',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Subject',
        value_field: 'emailsubject',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Type',
        value_field: 'type',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Template Name',
        value_field: 'templateName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  onEditClick(event) {

  }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }


  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  setList(list: Array<CaseReasonModel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }
/*
  onNeoFilterChange(filterObj) {
    let searchData = new SearchData();
    searchData = filterObj;
    if (searchData.filterColumn) {
      let serverFilterObj = this.searchService.manageServerFilterObj([searchData]);
      ////console.log('Server final filtre obj', serverFilterObj);
    }
  }*/

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
