import { Component, Input, OnInit } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';
import { ReplaceIpAddressService } from './replace-ip-address.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AllocationIpService } from '../allocation-ip/allocation-ip.service';
@Component({
  selector: 'app-replace-ip-address',
  templateUrl: './replace-ip-address.component.html',
  styleUrls: ['./replace-ip-address.component.scss']
})
export class ReplaceIpAddressComponent implements OnInit {

  @Input() custId: any;
  submitted: any = null;
  pool_name: any = "";
  purchase: any = null;
  remarks = "";
  activation_date: any = "";
  end_date: any = "";
  ip_address: any = "";
  isChecked: boolean = false;
  onselectIp: boolean = false;
  openAllocationIpSelectPopup: boolean = false;
  allocationIpSelectedData: any = "";
  ApicallChange:string="replaceIp";
  selectPurchaseList: any[] = [
    {
      display_text: '',
      currentChargeId: '',
    }
  ];

  insertReplaceIpForm = new FormGroup({
    purchase_id: new FormControl(null, [Validators.required]),
    select_ip: new FormControl('', [Validators.required]),
    remarks: new FormControl('', [Validators.required]),
    isHibernate: new FormControl(false)
  })

  constructor(
    private customModalService: CustomModalService,
    private replaceIpAddressService: ReplaceIpAddressService,
    public datepipe: DatePipe,
    private allocationIpService: AllocationIpService,
    private tosterservice: ToastrService

  ) { }

  ngOnInit() {
    this.getSubscriberAllocateIp();
  }

  getSubscriberAllocateIp() {
    this.replaceIpAddressService.getSubscriberAllocateIp(this.custId).subscribe(result => {
      if (result.dataList && result.dataList.length) {
        result.dataList.forEach(el => {
          el.display_text = el.chargeName + ", " + this.datepipe.transform(el.activationDate, 'dd-MM-yyyy') + " to " + this.datepipe.transform(el.expiryDate.slice(0, 10), 'dd-MM-yyyy');
        });
      }
      this.selectPurchaseList = result.dataList;
    });
  }

  onClickSaveReplaceIpForm() {
    if (this.insertReplaceIpForm.valid === true) {
      let self = this;
      this.submitted = false;
      alertify.confirm("Replace IP Address", "Are You Sure You want to Replace IP Address ?",
        function () {
          self.saveApiCall();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertReplaceIpForm.markAllAsTouched();
    }
  }

  onPurchaseListChange(el) {
    if (el) {
      this.activation_date = el.activationDate;
      this.end_date = this.datepipe.transform(el.expiryDate.slice(0, 10), 'dd/MM/yyyy')
      this.pool_name = el.poolName;
      this.ip_address = el.ipAddress;
      this.onselectIp = true;
    } else {
      this.activation_date = "";
      this.end_date = "";
      this.pool_name = "";
      this.ip_address = "";
      this.onselectIp = false
    }
  }

  closeAllocationIpSelectPopup(data) {
    if (_.isEmpty(data.selectedFreeIpData) === false && _.isEmpty(data.selectedIpPool) === false) {
      this.allocationIpSelectedData = data;

      this.allocationIpService.getippoolnamebyid(this.allocationIpSelectedData.selectedFreeIpData.poolId).subscribe((res) => {
        ////console.log(res)
        this.pool_name = res.data.poolName;
        this.insertReplaceIpForm.patchValue({ select_ip: this.allocationIpSelectedData.selectedFreeIpData.ipAddress });
      })
      // this.ip_address = this.allocationIpSelectedData.selectedFreeIpData.ipAddress;

    }
    this.openAllocationIpSelectPopup = false;
  }

  openAllocationIpSelectPopupClick() {
    if (this.onselectIp === true) {
      this.openAllocationIpSelectPopup = true;
    }
    else {
      this.tosterservice.error("Please Select Purchase!");
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertReplaceIpForm.value);
    let selectedChargeData = _.find(this.selectPurchaseList, { currentChargeId: apiInputData.purchase_id });
    let custId = parseInt(this.custId);
    let formatedData: any = {
      "currentAllocatedId": selectedChargeData.currentAllocatedId,
      "currentChargeId": selectedChargeData.currentChargeId,
      "currentPoolDetailsId": selectedChargeData.currentPoolDetailsId,
      "newPoolDetailsId": this.allocationIpSelectedData.selectedFreeIpData.poolDetailsId,
      "remarks": apiInputData.remarks,
      "isHibernate": apiInputData.isHibernate,
      "newPoolId": this.allocationIpSelectedData.selectedIpPool.poolId

    };
    this.replaceIpAddressService.saveReplaceIpByCustId(custId, formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Replace Ip Save Successfully.');
          this.customModalService.close('replace-ip-address', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }
  checkIsHibernate(event: any) {
    if (event === 'true') {
      this.insertReplaceIpForm.get('isHibernate').setValue(true);

    } else {
      this.insertReplaceIpForm.get('isHibernate').setValue(false);
    }

  }
  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

}
