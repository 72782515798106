import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { ChargesListService } from './charges-list.service';
import {AppConfig} from '../../../../../app.config';

@Component({
  selector: 'app-charges-list',
  templateUrl: './charges-list.component.html',
  styleUrls: ['./charges-list.component.scss']
})
export class ChargesListComponent implements OnInit {

  @ViewChild('downloadInvoice', { static: false }) downloadInvoiceWrapperRef: TemplateRef<any>;
  @ViewChild('downloadCreditNote', { static: false }) downloadCreditNoteWrapperRef: TemplateRef<any>;
  @ViewChild('chargeAmount', { static: false }) chargeAmountWrapperRef: TemplateRef<any>;

  @Input() custId : any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  chargesListNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  configPath;
  baseURL;
  chargesListTableData : any;

  constructor(
    private chargesListService : ChargesListService,
    appConfig: AppConfig
  ) { this.configPath = appConfig.getConfig(); }

  ngOnInit() {
    this.setGridSetting();
    this.getChargesListData();
    this.baseURL = this.configPath.baseURLApi;
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'invoiceNo') {
        col.customControls = [this.downloadInvoiceWrapperRef];
      }
      if (col.colKey === 'credId') {
        col.customControls = [this.downloadCreditNoteWrapperRef];
      }
      if (col.colKey === 'chargeAmount') {
        col.customControls = [this.chargeAmountWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'chargeName',
        default: 'Charge Name',
        rowKey: 'chargeName',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      // {
      //   colKey: 'paidAmount',
      //   default: 'paid Amount',
      //   rowKey: 'paidAmount',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'dueAmount',
      //   default: 'due Amount',
      //   rowKey: 'dueAmount',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'collectedBy',
      //   default: 'collected By',
      //   rowKey: 'collectedBy',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },    
      {
        colKey: 'chargeAmount',
        default: 'Amount',
        rowKey: 'chargeAmount',
        width: '10%',
        customControls: [this.chargeAmountWrapperRef],
        customValue:(chargeAmount) => {
          return chargeAmount;
        },
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'remarks',
        default: 'Remarks',
        rowKey: 'remarks',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'createdDate',
        default: 'Create Date',
        rowKey: 'createdDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'chargeDate',
        default: 'Start Date',
        rowKey: 'chargeDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'expiryDate',
        default: 'End Date',
        rowKey: 'expiryDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'reversed',
        default: 'Reversed',
        rowKey: 'reversed',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'reversedamount',
        default: 'Reversed Amount',
        rowKey: 'reversedamount',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'reversedby',
        default: 'Reversed by',
        rowKey: 'reversedby',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'invoiceNo',
        default: 'Invoice',
        rowKey: 'invoiceNo',
        width: '10%',
        customControls: [this.downloadInvoiceWrapperRef],
        customValue:(invoiceNo) => {
          return invoiceNo;
        },
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'credId',
        default: 'credit note',
        rowKey: 'credId',
        width: '10%',
        customControls: [this.downloadCreditNoteWrapperRef],
        customValue:(credId) => {
          return credId;
        },
        isNumber: false,
        isFilterAllow: false,
      },
   
    ];

    this.chargesListNeoGridSettings = {
      showAdvanceSearch: false,
      showFilter: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CHARGES_LIST,
      perPage: 5,
      tableHeight:300,
      headerSticky:true,
      verticalScroll:true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getChargesListData() {
    this.chargesListService.getChargesList(this.custId).subscribe(result => {
      this.chargesListTableData = result.data;
      this.valuePreparation(this.chargesListTableData);
      this.refreshDataSource(this.chargesListTableData);
    });
  }
  valuePreparation(data){
    data.forEach(element => {
    element.expiryDate = element.expiryDate ? element.expiryDate : '-'
    if(element.reversedamount){
      if(element.reversedamount === 0)
        element.reversedamount ='0';
    }
    else{
      element.reversedamount = '-'
    }
    });
  }
}
