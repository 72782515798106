import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import _ from 'underscore';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../../services/search.service';
import { SubscriberService } from '../../subscriber/subscriber.service';
import { AppConfig } from '../../../../app.config';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../../pages/login/login.service';
import { AclClassConstants } from '../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../pages/aclOperationConstants';
import { CustomerDocumentService } from './customer-document.service';
@Component({
  selector: 'app-customer-document',
  templateUrl: './customer-document.component.html',
  styleUrls: ['./customer-document.component.scss']
})
export class CustomerDocumentComponent implements OnInit {

  @ViewChild('documentStatusWrapper', { static: false }) documentStatusWrapperRef: TemplateRef<any>;
  @ViewChild('tooltip_remark', { static: false }) tooltip_remarkWrapperRef: TemplateRef<any>;
  @ViewChild('attachmentWrapper', { static: false }) attachmentWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnReff: TemplateRef<any>;

  @Input() custId: any;
  @Output() docId: any = new EventEmitter();
  customerDecumentDataForGrid: any = [];
  customerDecumentGridSettings: any = {};
  AclConstants = AclConstants;
  AclClassConstants = AclClassConstants;
  show_update: any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  subscriptions: Subscription[] = [];
  openOtherDetails: boolean;
  config;

  public docTypeForCustomerList: any[] = [
    {
      text: '',
      id: '',
    },
  ];

  constructor(
    private customerDeocumnetService: CustomerDocumentService,
    private searchService: SearchService,
    private subscriberService: SubscriberService,
    private tosterservice: ToastrService,
    private customerDocumentService: CustomerDocumentService,
    public loginService: LoginService,
    appConfig: AppConfig
  ) {
    this.config = appConfig.getConfig();
    this.subscriberService.getDocTypeForCustomerList().subscribe((result) => {
      this.docTypeForCustomerList = result.dataList;
    });
 
  }

  ngOnInit() {
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setGridSetting();
    this.getCustomerDocumentData();
    this.subscriptions.push(filterSubscription);
  }


  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'docStatus') {
        col.customControls = [this.documentStatusWrapperRef];
      }
      if (col.colKey === 'filename') {
        col.customControls = [this.attachmentWrapperRef];
      }
      if (col.colKey === 'remark') {
        col.customControls = [this.tooltip_remarkWrapperRef];
      }
      if (col.colKey === 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }

    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'docType',
        default: 'Document Type',
        rowKey: 'docType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'docSubType',
        default: 'Document SubType',
        rowKey: 'docSubType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'docStatus',
        default: 'Document Status',
        rowKey: 'docStatus',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'filename',
        default: 'Attachment',
        rowKey: 'filename',
        width: '6%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'Verified By',
        rowKey: 'lastModifiedByName',
        width: '15%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'remark',
        default: 'Remarks',
        rowKey: 'remark',
        width: '18%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.tooltip_remarkWrapperRef],
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        // enableEdit: true,
        // enableDelete: true,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '10%',
        isStickyEnd: true,
      }
    ];

    this.customerDecumentGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.CUSTOMER_DOCUMENT_LIST,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Customer Document  ?',
      deleteMsgBoxDetail: '"Customer Document  Name : ${docSubType} " ',
    };
  }
  verifiedDoc(data) {
    let docData:any = {
      custId : data.custId,
      docId : data.docId,
      docType:data.docType,
      docSubType:data.docSubType,
      docStatus:'verified',
      remark:data.remark,
      filename : data.filename,
      uniquename : data.uniquename
    };
    this.customerDocumentService.updateCustomerDocument(docData).subscribe(res => {
      if (res.responseCode !== 200 && res.responseMessage) {
        this.tosterservice.error(res.responseMessage);
      } else {
        this.tosterservice.success("Verify Successfully");
        this.ngOnInit();
      }
    })
  }

  getCustomerDocumentData() {
    this.customerDeocumnetService.getCustomerDocumentByCustId(this.custId).subscribe(result => {
      this.customerDecumentDataForGrid = result.dataList;
      this.refreshDataSource(this.customerDecumentDataForGrid);
    });
  }

  documentVerification(data) {
    let docData: any = {
      custId: data.custId,
      docId: data.docId,
      docType: data.docType,
      docSubType: data.docSubType,
      docStatus: 'verified',
      remark: data.remark,
      filename: data.filename,
      uniquename: data.uniquename
    };
    this.customerDocumentService.updateCustomerDocument(docData).subscribe(res => {
      if (res.responseCode !== 200 && res.responseMessage) {
        this.tosterservice.error(res.responseMessage);
      } else {
        this.tosterservice.success("Verify Successfully");
        this.ngOnInit();
      }
    })
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.customerDecumentDataForGrid));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.customerDecumentDataForGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  onEdit(event) {
    
    this.docId.emit(event.docId);
  }

  onDelete(event) {
    
    let self = this;
    if (event) {
      self.delete(event);
    }
  }

  delete(selected_customer_document) {
    let self = this;
    this.customerDeocumnetService.getCustomerDocumentById(selected_customer_document.docId).subscribe((result) => {
      this.customerDeocumnetService.deleteCustomerDocument(result.data).subscribe(
        (data: any) => {
          this.tosterservice.success('Customer Document  is deleted successfully');
          self.customerDecumentDataForGrid.forEach(function (item, index) {
            if (item.docId === data.data.docId) {
              self.customerDecumentDataForGrid.splice(index, 1);
            }
          });
          self.refreshDataSource(this.customerDecumentDataForGrid);
        }
      );

    }, err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR + err.responseMessage);
    });
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

}
