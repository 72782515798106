import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { OrderingData } from '../../../core/modal/ordering-data';
import { PaginationData } from '../../../core/modal/pagination-data';
import { LoginService } from '../../login/login.service';
import { IBaseService } from '../../../services/ibase-service';
import { SearchService } from '../../../services/search.service';
import { PinAreaModel } from '../pin-area/pin-area-model';
import { PinAreaService } from './pin-area.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import * as alertify from 'alertifyjs';
@Component({
  selector: 'app-pin-area',
  templateUrl: './pin-area.component.html',
  styleUrls: ['./pin-area.component.scss']
})
export class PinAreaComponent extends AbstractBaseListComponent<PinAreaModel> implements OnInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('editUserName', { static: false }) editUserNameReff: TemplateRef<any>;
  
  
  dataGrid: PinAreaModel[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for neo-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  searchSettings = [];
  neoTableSetting:any={};
  public entity: PinAreaModel = new PinAreaModel();
  public entity_id: any = null;
  form: FormGroup;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
 
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  
  constructor(private entityService : PinAreaService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    public loginService: LoginService,
    private searchService: SearchService) { 
      super(toastrService,searchService);
      this.loginService = loginService;
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.AREA_NEW)
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }

      if(col.colKey=='actionCol'){
        col.customControls = [this.actionBtnRef];
      }
      if(col.colKey=='name'){
        col.customControls = [this.editUserNameReff];
      }
    });
  }
  getService(): IBaseService<PinAreaModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
      },
      {
        colKey: 'name',
        default: 'Area',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'countryName',
        default: 'Country Name',
        rowKey: 'countryName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'stateName',
        default: 'State Name',
        rowKey: 'stateName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
       {
        colKey: 'cityName',
        default: 'City Name',
        rowKey: 'cityName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
    //return this.allColumns ? this.allColumns : [];
  }

  // ngAfterViewInit() {
  //   this.allColumns.forEach((col) => {
  //     if (col.colKey == 'actionCol') {
  //       col.customControls = [this.actionBtnRef];
  //     }
  //   });
  // }

  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.AREA_NEW,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Area ?',
      deleteMsgBoxDetail: 'Area Name :${name} ',
    };
    //return serviceAreaNeoGridSettings;
  }

  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Pincode',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Tat Consideration',
        value_field: 'tatConsideration',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  onEdit(event){
    this.setUpdateValueInForm(event);
  }
  // onEditClick(event) {
   
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
   // let index = event.RowData;
    let index = event;
    this.setUpdateValueInForm(index);
  }

  onDeleteClick(event) {
    let self = this;
    alertify.confirm("Are you sure to delete this area","Area:"+event.name,
    function(){
      if (event) {
         self.delete(event);
       }},
    function(){
    })
  }

  setList(list: Array<PinAreaModel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }
/*
  onNeoFilterChange(filterObj) {
    let searchData = new SearchData();
    searchData = filterObj;
    if (searchData.filterColumn) {
      let serverFilterObj = this.searchService.manageServerFilterObj([searchData]);
      ////console.log('Server final filtre obj', serverFilterObj);
    }
  }*/

  onAddClick() {
    this.router.navigate(['/masters/area/add-area']);
  }

  setUpdateValueInForm(rowData) {
    
    this.router.navigate(['/masters/area/add-area',rowData.id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}

