import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { ServiceAreaModel } from '../model/service-area-model';
import { ServiceAreaService } from '../service/service-area.service';
import * as alertify from 'alertifyjs';
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../../../../app/pages/data-sharing-service.service';
@Component({
  selector: 'app-service-area-add-update',
  templateUrl: './service-area-add-update.component.html',
  styleUrls: ['./service-area-add-update.component.scss']
})
export class ServiceAreaAddUpdateComponent extends AbstractBaseAddUpdateComponent<ServiceAreaModel> implements OnInit {
  public entity: ServiceAreaModel = new ServiceAreaModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
  public userList: any[] = [];

  //public userList = new Array<any>();
  commonStatus: any = [{
    text: 'Select Status', value: ''
  }];
  constructor(
    private serviceAreaService: ServiceAreaService,
    private CommonStatus: CommonStatusService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    ) {
    super(toastrService, router);
  }

  ngOnInit() {
    this.init();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.commonStatus = res.dataList
    })
    this.getStaff();
  } 
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }


  getStaff(){
    this.serviceAreaService.getStaff(12).subscribe((res) =>{
      this.userList = res.staffUserlist;
    })
  }

  getService(): IBaseService<ServiceAreaModel> {
    return this.serviceAreaService;
  }

  setData(data: ServiceAreaModel) {
    this.entity = data;
  }

  getData(): ServiceAreaModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {

    this.url = '/network-management/serviceArea'
    return this.url;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): ServiceAreaModel {
    return new ServiceAreaModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.id;
  }

  get f() {
    return this.form.controls;
  }

  setValidation() {
    this.form = new FormGroup({
      serviceArea_name: new FormControl('', [Validators.required]),
      serviceArea_status: new FormControl('', [Validators.required]),
      serviceArea_vlan: new FormControl(''),
      areaincharge: new FormControl('')
    });
  }

  formSubmit() {
    if (this.validateForm()) {
      this.onSubmit();
    }
  }

  closeDialog(){
    //
  }

getGenericDataService(){
return this.genericDataSharingService
}



}
