import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import * as alertify from 'alertifyjs';
import { PackagesService } from './packages.service';
import { NPackagesService } from './Npackages.service';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../services/ibase-service';
import { PackageModel } from './package-model';
import { PinAreaModel } from '../../masters/pin-area/pin-area-model';
import { MenuItems } from '../../../layout/menuItems';
import { PaginationData } from '../../../core/modal/pagination-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent extends AbstractBaseListComponent<any> implements OnInit, OnDestroy {
  
  
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('validity', { static: false }) validityRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('offerpriceintax', { static: false }) offerpriceinRef: TemplateRef<any>;
  @ViewChild('PackageName', { static: false }) packageNameReff: TemplateRef<any>;
  @ViewChild('taxamount',{ static: false }) taxamountReff: TemplateRef<any>;
  @ViewChild('offerprice', { static: false }) offerpriceRef: TemplateRef<any>;
  @ViewChild('vasBtn', { static: false }) vasWrapperRef: TemplateRef<any>;
  @ViewChild('offerpricewithvasintax', { static: false }) offerpricewithvasinRef: TemplateRef<any>;
  
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  menuItemService:any;
  dataGrid: PackageModel[] = [];
  packagesDataForGrid: any = { content: [] };
  packagesGridSettings: any = {};
  show_update: any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredPackagesDataForGrid: any = [];
  selectedIndex = 0;
  planMapId: string;

  constructor(
    menuConstants: MenuItems,
    private entityService: NPackagesService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private searchService: SearchService,
    private toastrService: ToastrService,
    loginService: LoginService,
    private modalService: NgbModal
  ) {
    super(toastrService,searchService);
    this.loginService = loginService;
    this.menuItemService = menuConstants;
    if(localStorage.getItem('tabSelectionIndex') == '1'){
      this.selectedIndex = 1;
    }
    localStorage.removeItem('tabSelectionIndex');
  }
  ngOnInit() {
    this.setDefaultPageSize(this.tableKey.PACKAGES_LIST)
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'validity') {
        col.customControls = [this.validityRef];
      }
      if (col.colKey == 'offerpriceintax') {
        col.customControls = [this.offerpriceinRef];
      }
      if (col.colKey == 'totalPriceWithVas') {
        col.customControls = [this.offerpricewithvasinRef];
      }
      if (col.colKey == 'offerprice') {
        col.customControls = [this.offerpriceRef];
      }
      if (col.colKey == 'displayName') {
        col.customControls = [this.packageNameReff];
      }
      if(col.colKey == 'taxamount'){
        col.customControls = [this.taxamountReff];
      }
      if (col.colKey === 'vas') {
        col.customControls = [this.vasWrapperRef];
      }
    });
  }

  getService(): IBaseService<any> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'displayName',
        default: 'Package Name',
        rowKey: 'displayName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'dataCategory',
        default: 'Data Category',
        rowKey: 'dataCategory',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'offerprice',
        default: 'Offer Price (Without. Tax)',
        rowKey: 'offerprice',
        width: '10%',
        customControls: [this.offerpriceRef],
        customValue:(offerprice) => {
          return offerprice;
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'taxamount',
        default: 'Tax',
        rowKey: 'taxamount',
        width: '10%',
        customControls: [this.taxamountReff],
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'offerpriceintax',
        default: 'Offer Price (Incl. Tax)',
        rowKey: 'offerpriceintax',
        width: '10%',
        customControls: [this.offerpriceinRef],
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'totalPriceWithVas',
        default: 'Offer Price With VAS(Incl. Tax)',
        rowKey: 'totalPriceWithVas',
        width: '10%',
        customControls: [this.offerpricewithvasinRef],
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validityType',
        default: 'Validity Type ',
        rowKey: 'validityType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validity',
        default: 'Validity',
        rowKey: 'validity',
        width: '10%',
        customControls: [this.validityRef],
        customValue: (validity) => {
          if (validity != null) {
            let findValidity = _.find(this.packagesDataForGrid, { validity: validity });
            return findValidity.validity;
          } else {
            return 0;
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'Y') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'vas',
        default: 'Associated VAS DETAILS',
        rowKey: 'vas',
        width: '10%',
        isNumber: false,
        customControls: [this.vasWrapperRef],
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
    // if (this.packagesGridSettings.serverSideSortPagination && this.packagesGridSettings.perPage) {
    //   this.setPaginationPerPage(this.packagesGridSettings.perPage);
    // }
  }


  getTableSearchSetting() {
    
    this.searchSettings = [
      {
        display_field: 'Package Name',
        value_field: 'displayName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Packages Type',
        value_field: 'plantype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
    ];
  }

  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.PACKAGES_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Packages?',
      deleteMsgBoxDetail: '"Package Name : ${name} " ',
    };
  }

  setList(list: Array<PackageModel>) {
    this.dataGrid = list;
    this.dataGrid.forEach(res => {
      res.offerpriceintax = res.offerprice + res.taxamount;
    });
    console.log("DATA",this.dataGrid)
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  // getPackagesData(gridSetting: any) {
  //   let fetchSettingData = this.packagesGridSettings.serverSideSortPagination ? gridSetting : null;
  //   let params = this.getGridSettingInfo(fetchSettingData);
  //   this.packagesService.getPackagesDataWithPageing(params).subscribe(result => {
  //     this.packagesDataForGrid = result;
  //     ////console.log("package::-->", this.packagesDataForGrid)
  //     this.packagesDataForGrid.content.forEach(res => {
  //           res.offerpriceintax = res.offerprice + res.taxamount;
  //     });
  //     this.filteredPackagesDataForGrid = JSON.parse(JSON.stringify(this.packagesDataForGrid.content));
  //     this.refreshDataSource(this.packagesDataForGrid.content);
  //     this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
  //     this.packagesGridSettings.pageTotalLength = this.totalRecords;
  //   });
  // }

  // getListWithParams() {
  //   if (Array.isArray(this.searchData)) {
  //     if (this.searchData.length > 0) {
  //       if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
  //         this.paginationData.pageNumber = 1;
  //       }
  //     }
  //   }
  //   let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
  //   this.getPackagesData(config);
  // }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.packagesDataForGrid.content));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.packagesGridSettings.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.packagesGridSettings);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  // onDelete(event) {
  //   if (event) {
  //     this.commonDeleteMsgBox(this.packagesGridSettings, event, this.delete.bind(this));
  //   }
  // }

  onDeleteClick(event) {
    let self = this;
    alertify.confirm("Are you sure to delete this package","Package:"+event.name,
    function(){
      if (event) {
         self.delete(event);
       }},
    function(){
    })
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
    //this.onFilterChange(this.obj)
  }

  // delete(objEvent) {
  //   let self = this;
  //   let selected_packages_data = objEvent.event;
  //   if (objEvent.deleteClicked) {
  //     this.packagesService.deletePackages(selected_packages_data.packages_id).subscribe((result) => {
  //       if(result.status !== 200){
  //         this.tosterservice.error(result.ERROR);
  //       }
  //       else{
  //         this.tosterservice.success('Packages is deleted successfully');
  //         self.packagesDataForGrid.content.forEach(function (item, index) {
  //           if (item.packages_id === selected_packages_data.packages_id) {
  //             self.packagesDataForGrid.content.splice(index, 1);
  //           }
  //         });
  //         self.refreshDataSource(self.packagesDataForGrid.content);
  //       }
       
  //     },
  //       err => {
  //         this.tosterservice.error(err.error.status + " " + err.error.ERROR);
  //       });
  //   }
  // }

  onEditClick(event) {
    // let index = event.RowData;
     let index = event;
     this.setUpdateValueInForm(index);
   }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['plan-management/packages/add-packages/',rowData.id]);
   // this.router.navigate(['plan-management/packages/add-packages'], { queryParams: { id: rowData.packages_id } });
  }

  onAddClick() {
    this.router.navigate(['plan-management/packages/add-packages']);
  }

  
  myTabSelected(event) {
    this.selectedIndex = event.index;
    if(this.selectedIndex == 0){
      localStorage.setItem('tabSelectionIndex','0');
    }
    if(this.selectedIndex == 1){
      localStorage.setItem('tabSelectionIndex','1');
    }
    // if (event.index == 0) {
    //   this.backButton = true;
    // }
    // else {
    //   this.backButton = false;
    // }

    // if (event.index == 1) {
    //   this.preparePrescription = true;
    //   this.refreshDataSource(this.suggestedMedicineDataList);
    // }
    // else {
    //   this.preparePrescription = false;
    // }

    // if (event.index == 2) {
    //   this.formBrand.reset();
    //   this.printPrescription = true;
    //   this.prescriptionMedicineDataList = this.suggestedMedicineDataList.filter((ls) => ls.multiSelect);
    //   this.PrintPrescription();
    //   this.refreshDataSource(this.prescriptionMedicineDataList);
    // }
    // else {
    //   this.printPrescription = false;
    // }
  }

  selectVAStab(event)
  {
    this.selectedIndex = event;
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  // setPagination(event){
  //   let config = { page: event, order: this.orderingData, search: this.searchData };
  //   this.getPackagesData(config);
  //     }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.id;
  }
}
