export class PinAreaModel {
        id:number
        cityId: number;
        countryId: number;
        name: string;
       pincode: pinCodeId;
        stateId: number;
        status: string;
        //angularuseonly
        pincodeId:number;
    
}

export class pinCodeId{
        pincodeid:number;
}
