import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  constructor(private http: HttpClient) {}

  // transformAuditData(data) {
  //   ////console.log(data);
  //   let formatedData = [];
  //   data.dataList.forEach(function(item:any){
  //     let itemData:any = {};
  //     itemData.audit_id = item.id;
  //     itemData.username = item.username;
  //     itemData.replymessage = item.replymessage;
  //     itemData.clientgroup = item.clientgroup;
  //     itemData.packettype = item.packettype;
  //     itemData.clientip = item.clientip;  
  //     itemData.createdate = item.createDateString;
  //     itemData.updateDateString = item.updateDateString;
  //     itemData.custid = item.custid;
  //     itemData.partnerName = item.partnerName;
  //     formatedData.push(itemData);
  //   });
  //   return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  // }

  getAuditDataWithPageing(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'authresponse/list';

    return this.http.post<any>(post_url,params,
      { headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getAuditDataList():Observable<any>{
    const get_url = baseApiUrl + 'acl/getModuleOperations';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
      map((res:any)=>{
           return res;
      }),
      catchError((error:any)=>{
        return throwError (error)
      })
    );
  }
  getUserdetailByUserName(username):Observable<any>{
    const get_url=baseApiUrl+'customers/byUserName/'+username;
    return this.http.get<any>(get_url);
  }
}