import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../../../src/app/components/base/old-abstract-base-list-component';
import { TableKeyEnum } from '../../../../../src/app/core/enum/table-key.enum';
import { SearchService } from '../../../../../src/app/services/search.service';
import { NetworktreeService } from '../network-tree/service/networktree.service'

@Component({
  selector: 'app-view-port',
  templateUrl: './view-port.component.html',
  styleUrls: ['./view-port.component.scss']
})
export class ViewPortComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  
  portListArray:any;
  networkportGridSettings:any={};
  tableKeyEnum = TableKeyEnum;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  commonYN :any[] = [];
  selectedSnmpEnable:string
  dataSource = new MatTableDataSource([]);
  uuid:any;
  subscriptions: Subscription[] = [];
  slotid:any;
  frameid:any;
  portId:any;
  frame:any;
  slot:any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private toaster:ToastrService,
    private networktreeService:NetworktreeService,
    private searchService: SearchService) {
    super(searchService);
   }
  
 
  ngOnInit() {
  this.setDefaultPageSize(this.tableKey.NETWORK_DEVICE_PORT__LIST)  
  

  let id = this.route.snapshot.paramMap.get('id');
  let frame = this.route.snapshot.paramMap.get('frame');
  let slot = this.route.snapshot.paramMap.get('slot');
   

   if (id) {
     this.uuid=id;
     }else{
       this.uuid=null;
     }

   if(frame) {
     this.frame=frame;
      }else{
     this.frame=null;
      }

   if (slot) {
        this.slot=slot;
         }else{
        this.slot=null;
    }   

    
  const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
    // if (obj && obj.filters) {
    this.applyFilter(obj);
    // }else
  });
  this.setGridSetting();
  this.initPaginationSort();  
  
  
 

  this.subscriptions.push(filterSubscription);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'oltPort',
        default: 'olt Port',
        rowKey: 'oltPort',
        customControls: [this.actionBtnRef],
        width: '100%',
        isFilterAllow: false,
        // enableEdit: true,
        // enableDelete: true,
        enableSort: true,
        
      }
    ];

    this.networkportGridSettings = {
      showFilter:true,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.NETWORK_DEVICE_PORT__LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Slot Detail ?',
      deleteMsgBoxDetail: '"Slot Details Name :${name} " '
    };
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      // if (col.colKey == 'actionCol') {
      //   // col.customControls = [this.actionBtnRef];
      // }
       if (col.colKey == 'oltPort') {
         col.customControls = [this.actionBtnRef];
      }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getNetworkDeviceFrameData(gridSetting: any) {
    
    let fetchSettingData = this.networkportGridSettings.serverSideSortPagination ? gridSetting :null;
    let paramsValue: any = {paginationRequestDTO:{},uuid:this.uuid,frame:this.frame,slot:this.slot};
    //let apiInpuData = this.transFormPaginationData();
    let params = this.getGridSettingInfo(fetchSettingData);
    paramsValue.paginationRequestDTO.page = params.page;
    paramsValue.paginationRequestDTO.pageSize = params.pageSize;
    paramsValue.paginationRequestDTO.sortBy = params.sortBy;
    paramsValue.paginationRequestDTO.sortOrder = params.sortOrder;
    paramsValue.uuid = this.uuid;
    paramsValue.frame = this.frame;
    paramsValue.slot = this.slot;   
    paramsValue.paginationRequestDTO.filters=params.filters; 
    this.networktreeService.getportList(paramsValue).subscribe(result => {
      if(result.responseCode===200){
        if(result.dataList){
          this.portListArray = result.dataList;
          this.refreshDataSource(this.portListArray);
          this.totalRecords = result.totalRecords;
          this.networkportGridSettings.pageTotalLength = this.totalRecords;
          this.networkportGridSettings.perPage=result.pageRecords;
        }else{
          this.toaster.error("Data Not Found");
        }
      }else{
        this.toaster.error("Data Not Found");
        this.refreshDataSource([]);
        this.totalRecords = 0;
        this.networkportGridSettings.pageTotalLength = this.totalRecords;
      }
      
    });
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getNetworkDeviceFrameData(config);
  }

  applyFilter(filterObj) {
  let filteredDataForGrid = JSON.parse(JSON.stringify(this.portListArray));
     if (filterObj && filterObj.filters) {
       filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.portListArray);
     }
    this.refreshDataSource(filteredDataForGrid);
  }

  setPagination(event){
   let config = { page: event, order: this.orderingData, search: this.searchData };
   this.getNetworkDeviceFrameData(config);
  }

  // onEdit(rowData){
  //     //console.log("RowData",rowData)
  //     this.router.navigate(['/network-management/frame',rowData.uuid]);
  // }

  onEdit(rowData){
    this.router.navigate(['/network-management/olt',this.uuid,rowData.oltframe,rowData.oltSlot,rowData.oltPort]);
  }

  onBackTo(){
    this.router.navigate(['/network-management/frame',this.uuid]);
  }
}
