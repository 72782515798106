import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class PartnerBalanceTopupService {
  constructor(private http: HttpClient) {}

  getPaymentGatewayList(): Observable<any> {
    const post_url = baseApiUrl + 'paymentGateway';

    return this.http.post<any>(post_url, { headers: httpOptions.headers})
        .pipe(map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

    getPaymentGatewayListForPartner(): Observable<any> {
        const get_url = baseApiUrl + 'paymentGateway/getPaymentGatewayForPartner';
        return this.http.get<any>(get_url, httpOptions)
            .pipe(map(res => res),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }

  insertPartnerBalanceTopUpForm(data : any) : Observable<any> {
    const post_url = baseApiUrl + 'order/place';
    return this.http.post<any>(post_url, data, httpOptions)
        .pipe(map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }

  getPartnerDetailsById(partner_id: any) : Observable<any> {
    const get_url = baseApiUrl + 'partner/'+partner_id;
    return this.http.get<any>(get_url, httpOptions)
        .pipe(map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }

  getPartnerMinimumBal(name: any) : Observable<any> {
    const get_url = baseApiUrl + 'getClientServiceByName/'+name;
    return this.http.get<any>(get_url, httpOptions)
        .pipe(map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }

}
