import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { QosPolicyService } from '../../../services/qos-policy/qos-policy.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AddPolicyComponent } from '../add-policy/add-policy.component';
import { DataSharingServiceService } from '../../data-sharing-service.service';

@Component({
  selector: 'app-add-qos-policy',
  templateUrl: './add-qos-policy.component.html',
  styleUrls: ['./add-qos-policy.component.scss']
})
export class AddQosPolicyComponent implements OnInit {
  @ViewChild(AddPolicyComponent,{static:false}) policy:AddPolicyComponent;
  show_update: boolean;
  selectForUpdate: any;
  QosdetailId: any;
  QospolicyId:any;
  isDisabled:boolean=false;
  
  constructor(
    public qosPolicyService: QosPolicyService,
    public toaster: ToastrService,
    public route: Router,
    public aroute: ActivatedRoute,
    private sharingdataService: DataSharingServiceService
  ) { }
  insertQosPilicyDetail: FormGroup;
  req_message = "This Field is Required"
  ngOnInit() {
    
    this.insertQosPilicyDetail = new FormGroup({
      radiusPolParam: new FormControl('', Validators.required),
      paramDesc: new FormControl('',),
      baseParam: new FormControl('', Validators.required),
      throughtParam: new FormControl('', Validators.required),
      QosDetailsList: new FormArray([])
    })
    this.QospolicyId = this.aroute.snapshot.paramMap.get('qosid');
    let QosdetailId = this.aroute.snapshot.paramMap.get('id');
    if (QosdetailId) {
      this.QosdetailId = QosdetailId;
      // this.isDisabled=true
      this.show_update = true;
      this.getSlotDataById();
    }else{
      this.isDisabled=false

    }
  }
  getSlotDataById() {
    let QosdetailId = this.QosdetailId;
    this.qosPolicyService.getQosByID(QosdetailId).subscribe(
      result => {
        let data = result.data;
        this.setUpdateValueInForm(data);
      }
    )
  }

  setUpdateValueInForm(qos_Data: any) {
    this.show_update = true;
    this.selectForUpdate = qos_Data;
    this.QospolicyId=qos_Data.qosPolicyId;
    this.insertQosPilicyDetail.patchValue(qos_Data);
  }

  onAddPortItems(row?) {
    (<FormArray>this.insertQosPilicyDetail.get('QosDetailsList')).push(
      new FormGroup({
        id: new FormControl(''),
        radiusPolParam: new FormControl('', row ? row.radiusPolParam : ''),
        baseParam: new FormControl('', row ? row.baseParam : ''),
        paramDesc: new FormControl('', row ? row.paramDesc : ''),
        throughtParam: new FormControl('', row ? row.throughtParam : ''),
        isDeleted: new FormControl(true)
      })
    );
  }
  get QosItems() {
    return (this.insertQosPilicyDetail.get('QosDetailsList') as FormArray).controls;
  }
  onDelete(index: number) {
    (<FormArray>this.insertQosPilicyDetail.get('QosDetailsList')).removeAt(index);
  }
  validateForm() {
    ////console.log(this.insertQosPilicyDetail);
    if (!this.insertQosPilicyDetail.valid) {
      this.toaster.error("Please fill all details")
      Object.keys(this.insertQosPilicyDetail.controls).forEach(field => {
        const control = this.insertQosPilicyDetail.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      if (this.show_update) {
        this.UpdateQosPolicyOnDb();
      } else {
        this.insertQosPolicyOnDb();
      }
    }
  }

  insertQosPolicyOnDb() {
    ////console.log(this, this.insertQosPilicyDetail.value);
    if (this.insertQosPilicyDetail.valid === true) {
      let formatedData = this.insertQosPilicyDetail.value;
      let apiInputData = this.transformInsertApiData(formatedData);
      this.qosPolicyService.saveQosDetail(apiInputData).subscribe(res => {
        if (res.responseCode === 200) {
          this.toaster.success('Record  Successfully Saved');
          this.backToView();
        } else {
          this.toaster.error(res.responseMessage);
        }
      });
    }
  }
  UpdateQosPolicyOnDb() {
    ////console.log(this, this.insertQosPilicyDetail.value);
    if (this.insertQosPilicyDetail.valid === true) {
      let formatedData = this.insertQosPilicyDetail.value;
      let apiInputData = this.transformUpdateApiData(formatedData);
      this.qosPolicyService.updateQosPolicy(apiInputData).subscribe(res => {
        if (res.responseCode === 200) {
          this.toaster.success('Record  Successfully Updated');
          this.backToView();
        } else {
          this.toaster.error(res.responseMessage);
        }
      });
    }
  }

  transformInsertApiData(data) {
    let formatedInputData: any = {};
    formatedInputData.qosPolicyId=this.QospolicyId;
    formatedInputData.radiusPolParam = data.radiusPolParam;
    formatedInputData.paramDesc = data.paramDesc;
    formatedInputData.baseParam = data.baseParam;
    formatedInputData.throughtParam = data.throughtParam;
    formatedInputData.isDelete = false;
    return formatedInputData;
  }

  transformUpdateApiData(data) {
    let formatedInputData: any = {};
    formatedInputData.id = this.QosdetailId;
    formatedInputData.qosPolicyId=this.QospolicyId;
    formatedInputData.radiusPolParam = data.radiusPolParam;
    formatedInputData.paramDesc = data.paramDesc;
    formatedInputData.baseParam = data.baseParam;
    formatedInputData.throughtParam = data.throughtParam;
    return formatedInputData;
  }
  backToView(){
    //this.route.navigate(['plan-management/qos-policy/add-qos-policy',this.QospolicyId]);
    if(this.sharingdataService.lastAuditUrl == this.route.url){
      this.route.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
        this.route.navigate(['plan-management/qos-policy/add-qos-policy',this.QospolicyId]);
      }
  }
  get f() {
    return this.insertQosPilicyDetail.controls;
  }

  closeDialog(){
    //this.dialog
  }

}
