import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../../../../../../services/base-service';
import { associatedvasdetail } from '../model/associatedvas.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class AssociatedVASService extends BaseService<associatedvasdetail>{
  constructor(http: HttpClient) {
    super(http);
   }

  getModuleName(): string {
    return '';
  }

  validate(): boolean {
    return false;
  }

  getAssociatedVasdetails(id) {
    const get_url = BASE_API_URL + 'subscriber/getActivePlanVasList/' + id;
    return this.http.get<any>(get_url);
  }

  getPlanAssociatedVasdetails(id){
    const get_url = BASE_API_URL + 'postPaidPlan/getVasAssociateWithPlan/' + id;
    return this.http.get<any>(get_url);
  }

}