import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable,throwError} from 'rxjs';
import { catchError,map } from 'rxjs/operators';

const BASE_API_URL = '';
// const httpOptions = {
//   headers: new 
//   ({ 'Content-Type': 'application/json' })
// }
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class AuditlogService {

  constructor( private http:HttpClient) { }


getAuditDataList():Observable<any>{
  const get_url = BASE_API_URL + 'acl/getModuleOperations';
  return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
    map((res:any)=>{
      return res;
    }),
    catchError((error:any)=>{
      return throwError (error)
    })
  );
}
///api/v1/auditLog/auditFor/{type} customerpass in type  (LIst get)

//getListByAuditFor/{auditFor} (value pass)

//Pending:BackEnd
getUsersList(){
  const get_url = BASE_API_URL + 'customer/....';
  return this.http.get<any>(get_url,{ headers: httpOptions.headers});
}


getAuditForDataList():Observable<any>{
  
  const get_url = BASE_API_URL + 'commonList/auditFor';
  return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
    map((res:any)=>{
      return res;
    }),
    catchError((error:any)=>{
      return throwError (error)
    })
  );
}


getListByAuditFor(objauditFor):Observable<any>{
 const get_url = BASE_API_URL + 'getListByAuditFor/' + objauditFor;
  return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
    map((res:any)=>{
      return res;
    }),
    catchError((error:any)=>{
      return throwError (error)
    })
  );
}


getAuditOperation():Observable<any>{
  const get_url = BASE_API_URL + 'acl/getModuleOperations';
  return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
    map((res:any)=>{
      return res;
    }),
    catchError((error:any)=>{
      return throwError (error)
    })
  );
}

getAuditUserList(objAuditFor):Observable<any>{


 const get_url = BASE_API_URL + 'auditLog/getListByAuditFor/'+objAuditFor;
  return this.http.get<any>(get_url,{ headers: httpOptions.headers }).pipe(
    map((res:any)=>{
      return res;
    }),
    catchError((error:any)=>{
      return throwError (error)
    })
  );
 }

 
 getGridSearchData(obj):Observable<any>{
  const post_url = BASE_API_URL + 'auditLog/searchAudit';
 
  return this.http.post<any>(post_url, obj, httpOptions).pipe(
    map(data => data),
    catchError((error: any) => {
      return throwError(error);
    })
  );

  }

  excelServiceData(data) {
    const post_url = BASE_API_URL + "auditLog/excel";
    return this.http.post<any>(post_url,data,{ observe: 'body', responseType: 'blob' as 'json' });
  }



}

