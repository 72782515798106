
export class ClientGroupModel {
    id: number;
    name:string;
    status:string;

    constructor(){
        this.name="";
        this.status="";
    }
}
