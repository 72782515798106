import { Component, Input, OnInit, TemplateRef, ViewChild,Optional,Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OtherUpdatesService } from './other-updates.service';
import { AppConfig } from '../../../../app.config';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriberDetailsComponent } from '../../subscriber-details/subscriber-details.component';

export interface DialogPassData {
  id: number;
}

declare let $: any;
@Component({
  selector: 'app-other-updates',
  templateUrl: './other-updates.component.html',
  styleUrls: ['./other-updates.component.scss']
})
export class OtherUpdatesComponent implements OnInit {

  @ViewChild('downloadInvoice', { static: false }) downloadInvoiceWrapperRef: TemplateRef<any>;
  @ViewChild('oldvalue', { static: false }) oldvalueRef: TemplateRef<any>;
  @ViewChild('newValue', { static: false }) newValueRef: TemplateRef<any>;
  @ViewChild('tooltip', { static: false }) tooltipWrapperRef: TemplateRef<any>;
  @ViewChild(SubscriberDetailsComponent, { static: false }) subscribercomp: SubscriberDetailsComponent;
  // @ViewChild(SubscriberDetailsComponent, { static: false }) subscriberdetailReff: SubscriberDetailsComponent;

  @Input() custId: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  otherUpdatesNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  configPath;
  baseURL;
  operation:any[]=[];
  isDisabled:boolean=false;
  openOtherUpdates: boolean;
  otherUpdatesTableData: any;
  searchOtherUpdateForm = new FormGroup({
    from_date: new FormControl(''),
    to_date: new FormControl(''),
    operation: new FormControl(null)
  });

  constructor(
    
    private otherUpdatesService: OtherUpdatesService,
    appConfig: AppConfig,
    private tosterservice: ToastrService,
    // public dialog:MatDialog,
    // public dialogRef: MatDialogRef<OtherUpdatesComponent>,
    // @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { this.configPath = appConfig.getConfig(); }

  ngOnInit() {
    
    
     this.setGridSetting();
     this.getOtherUpdatesData();
     this.baseURL = this.configPath.baseURLApi;
     this.getOperationsData();
    
    
    // if(this.data && this.data.id){
    
    //   this.custId = this.data.id;
    //   this.subscribercomp.onOtherUpdatesClick()
    //   this.openOtherUpdates = true;
    //   this.isDisabled=true;
     
    //   this.setGridSetting();
    //   this.getOtherUpdatesData();
    //   this.baseURL = this.configPath.baseURLApi;
    //   this.getOperationsData();
     
    // }
  
  }
  getOperationsData(){
    this.otherUpdatesService.getOprations().subscribe(res=>{
      this.operation=res.data;
    })
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'invoiceNo') {
        col.customControls = [this.downloadInvoiceWrapperRef];
      }
      if (col.colKey === 'oldval') {
        col.customControls = [this.oldvalueRef];
      }
      if (col.colKey === 'newval') {
        col.customControls = [this.newValueRef];
      }
      if (col.colKey === 'remarks') {
        col.customControls = [this.tooltipWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'createdate',
        default: 'Date',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'operation',
        default: 'Action',
        rowKey: 'operation',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'oldval',
        default: 'Old Value',
        rowKey: 'oldval',
        width: '10%',
        customControls: [this.oldvalueRef],
        customValue: (oldval) => {
          let findoldval = _.find(this.otherUpdatesTableData, { oldval: oldval });
          return findoldval.oldval;
        },
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'newval',
        default: 'New Value',
        rowKey: 'newval',
        width: '10%',
        customControls: [this.oldvalueRef],
        customValue: (newval) => {
          let findnewValue = _.find(this.otherUpdatesTableData, { newval: newval });
          return findnewValue.newval;
        },
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'remarks',
        default: 'Remarks',
        rowKey: 'remarks',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        customValue:[this.tooltipWrapperRef]
      },
      {
        colKey: 'lastModifiedByName',
        default: 'Action By',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },





      // {
      //   colKey: 'invoiceNo',
      //   default: 'Invoice No',
      //   rowKey: 'invoiceNo',
      //   width: '10%',
      //   customControls: [this.downloadInvoiceWrapperRef],
      //   customValue:(invoiceNo) => {
      //     return invoiceNo;
      //   },
      //   isNumber: false,
      //   isFilterAllow: false,
      // },

    ];

    this.otherUpdatesNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.OTHER_UPDATES,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: 'Date',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getOtherUpdatesData() {
    this.otherUpdatesService.getOtherUpdates(this.custId).subscribe(result => {
      this.otherUpdatesTableData = result.data;
      this.refreshDataSource(this.otherUpdatesTableData);
    });
  }

  searchOtherUpdate() {
    let apiInputData = this.searchOtherUpdateForm.value;
    let START_DATE = new DatePipe('en-US').transform(apiInputData.from_date, 'yyyy-MM-dd')
    let END_DATE = new DatePipe('en-US').transform(apiInputData.to_date, 'yyyy-MM-dd')
    let form_data: any = {
      "START_DATE": START_DATE,
      "END_DATE": END_DATE,
      "customer_id": this.custId,
      "operation": apiInputData.operation
    }
    this.otherUpdatesService.searchOtherUpdates(form_data).subscribe(result => {
      if (result.responseCode !== 200 && result.responseMessage) {
        this.tosterservice.error(result.responseMessage);
      } else {
        this.otherUpdatesTableData = result.dataList;
        this.refreshDataSource(this.otherUpdatesTableData);
      }
    });
  }

  resetForm() {
    this.searchOtherUpdateForm.reset();
    this.getOtherUpdatesData();
  }
}
