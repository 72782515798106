import {Component, OnInit} from '@angular/core';
import {ManageProfileServiceService} from '../service/manage-profile-service.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Profile} from '../model/Profile';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {FranchiesService} from '../../masters/franchies/franchies.service';
import { LoginService } from '../../login/login.service';
import { AclClassConstants } from '../../../../app/pages/aclClassConstants';
import { AclConstants } from '../../../../app/pages/aclOperationConstants';

@Component({
    selector: 'app-profile-detail',
    templateUrl: './profile-detail.component.html',
    styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit {

    updateProfileform: FormGroup;
    error_message = 'required field';
    profile = new Profile();
    franchise_id: number;
    partnerId: number;
    balance: number;
    commissionType: string;
    commissionValue: number;
    InvalidEmail: boolean;
    InvalidMobile:boolean;
    public loginService: LoginService;
    AclClassConstants = AclClassConstants;
    AclConstants = AclConstants;
    partnerReadonly:boolean=false;
    constructor(
        private route: ActivatedRoute,
        private franchiseService: FranchiesService,
        private mpService: ManageProfileServiceService,
        private toast: ToastrService,
        loginService: LoginService
    ) {
        this.loginService = loginService;
    }

    ngOnInit() {

         if(this.loginService.hasOperationPermission(AclClassConstants.ACL_Manage_Profile,AclConstants.OPERATION_PARNER_PROFILE_UPDATE,AclConstants.OPERATION_MANAGE_PROFILE_ALL))
         {
        this.partnerReadonly=false;
            
        }else {
        this.partnerReadonly=true;
     }
        this.route.queryParams.subscribe(parama => {
            let id = parama.id;
            this.partnerId = id;
            if (id) {
                this.getUserData(id);
            }
        });

        this.profileForm();
    }

    profileForm() {
        this.updateProfileform = new FormGroup({
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            Email: new FormControl('', [Validators.required, Validators.email]),
            Phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
        });
    }

    setValidate() {
        this.updateProfileform.get('firstName').disable();
        this.updateProfileform.get('lastName').disable();
        this.updateProfileform.get('Phone').disable();
        this.updateProfileform.get('Email').disable();
    }

    getUserData(id) {
        this.mpService.UserdataById(id).subscribe(res => {
            ////console.log('res', res.Staff);
            this.profileForm();
            this.setUpdateValueForm(res.Staff);
            this.franchise_id = res.Staff.partnerid;
            ////console.log('fran::', this.franchise_id);
        });
    }

    setUpdateValueForm(Staff: any) {
        this.profile.id = Staff.id;
        this.profile.firstname = Staff.firstname;
        this.updateProfileform.get('firstName').setValue(Staff.firstname);
        this.profile.lastname = Staff.lastname;
        this.updateProfileform.get('lastName').setValue(Staff.lastname);
        this.profile.email = Staff.email;
        this.updateProfileform.get('Email').setValue(Staff.email);
        this.profile.phone = Staff.phone;
        this.updateProfileform.get('Phone').setValue(Staff.phone);
        this.franchise_id = Staff.partnerid;
        if (this.franchise_id) {
            this.franchiseService.getFranchiseBalanceById(this.franchise_id).subscribe((res) => {
                this.balance = res.outstandamount;
                this.commissionType = res.partnerCommType;
                this.commissionValue = res.partnerCommVal;
            });
        }
    }


    validateForm() {
        if (!this.updateProfileform.valid) {
            ////console.log(this.updateProfileform);
            Object.keys(this.updateProfileform.controls).forEach(field => {
                const control = this.updateProfileform.get(field);
                if (control instanceof FormControl) {
                    control.markAsTouched({onlySelf: true});
                }
            });
        } else {
            this.UpdateprofileDb(this.profile);
        }
    }

    checkUniueEmail(event) {
        let email = event.target.value;
        let data = {'partnerId': this.franchise_id, 'value': email};
        this.franchiseService.checkUniqueEmail(data).subscribe(res => {
            if (res.responseCode != 200) {
                this.InvalidEmail = true;
                this.toast.error(res.responseMessage);
            } else {
                this.InvalidEmail = false;
            }
        });
    }
    checkUniueMobile(event) {
        let mobile = event.target.value;
        ////console.log("mobilr",mobile);
        let data = {'partnerId': this.franchise_id, 'value': mobile};
        this.franchiseService.checkUniqueMobile(data).subscribe(res => {
            if (res.responseCode != 200) {
                this.InvalidMobile = true;
                this.toast.error(res.responseMessage);
            }else{
                this.InvalidMobile=false;
            }
        });
    }
    checkEmailOnsave(email) {
        this.InvalidEmail = false;
        let data = {'partnerId': this.franchise_id, 'value': email};
        this.franchiseService.checkUniqueEmail(data).subscribe(res => {
            if (res.responseCode != 200) {
                this.InvalidEmail = true;
                this.toast.error(res.responseMessage);
            }
        });
    }
    checkMobileOnsave(mobile) {
        this.InvalidEmail = false;
        let data = {'partnerId': this.franchise_id, 'value': mobile};
        this.franchiseService.checkUniqueMobile(data).subscribe(res => {
            if (res.responseCode != 200) {
                this.InvalidEmail = true;
                this.toast.error(res.responseMessage);
            }
        });
    }

    UpdateprofileDb(data) {
        let self = this;
        this.checkEmailOnsave(data.email);
        this.checkMobileOnsave(data.phone);
        setTimeout(function () {
            if (!self.InvalidEmail && !self.InvalidMobile) {
                self.mpService.UpdateProfileService(data).subscribe(res => {
                    if (res.responseCode === 200) {
                        self.toast.success('Profile is successfully updated');
                    }
                });
            }
        }, 1500);
    }

    disEnaFirstname(event) {

        if (event.target.checked) {
            this.updateProfileform.get('firstName').disable();
        } else {
            this.updateProfileform.get('firstName').enable();
        }
    }

    disEnaLastname(event) {
        if (event.target.checked) {
            this.updateProfileform.get('lastName').disable();
        } else {
            this.updateProfileform.get('lastName').enable();
        }
    }

    disEnaEmail(event) {
        if (event.target.checked) {
            this.updateProfileform.get('Email').disable();
        } else {
            this.updateProfileform.get('Email').enable();
        }
    }

    disEnaPhone(event) {
        if (event.target.checked) {
            this.updateProfileform.get('Phone').disable();
        } else {
            this.updateProfileform.get('Phone').enable();
        }
    }
}
