import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

export class ChangeQuota {
  operationDataQuota : string;
  operationTimeQuota : string;
  remark : string;
  unitDataQuota : string;
  unitTimeQuota : string;
  valueDataQuota : string;
  valueTimeQuota : string;
}

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class ChangeQuotaService {
  constructor(private http: HttpClient) {}

  getChangeQuota(custId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getQuota/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getChangeQuotaByPlanId(custId : any, palnId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getQuota/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertChangeQuota(macDetails_data : any) : Observable<any> {
      const post_url = baseApiUrl + 'subscriber/changeQuota';
      return this.http.post<any>(post_url, macDetails_data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
    }
}