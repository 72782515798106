import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaService } from '../area/area.service';
import _ from 'underscore';
import * as alertify from 'alertifyjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSharingServiceService } from '../../../../app/pages/data-sharing-service.service';

interface Values {
  value: string;
  viewValue: string;
}

export interface DialogPassData {
  id: number;
}

@Component({
  selector: 'app-add-area',
  templateUrl: './add-area.component.html',
  styleUrls: ['./add-area.component.scss']
})
export class AddAreaComponent implements OnInit {
  saveBtn:boolean=false;

  areaDataForGrid:any = {content:[]};
  areaGridSettings: any = {};
  public area_id:any = null;
  public sub:any = null;
  selectForUpdate: any;
  show_update:any = false;
  req_message = "This field is required.";
  statusList:Values[] = [
    {value:'N', viewValue:'In Active'},
    {value:'Y', viewValue:'Active'}
  ];

  areaForm = new FormGroup({
    area_name : new FormControl('', [Validators.required]),
    area_status : new FormControl('', [Validators.required])
  });

  constructor(
    private areaService : AreaService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef:ChangeDetectorRef,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddAreaComponent>,
    private sharingdataService:DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) { }

  ngOnInit() {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        let id = params['id']
        if (id) {
          this.area_id = params['id'];
          this.show_update = true;
          this.getAreaDataById();
        }
    });
  }

  validateForm() {
    if (!this.areaForm.valid) {
      alertify.error("Please fill all the details!");
      Object.keys(this.areaForm.controls).forEach(field => {
        const control = this.areaForm.get(field);            
        if (control instanceof FormControl) {             
            control.markAsTouched({ onlySelf: true });
        } 
      });
    } else {
      if (this.area_id === null) {
        this.insertNewArea();
      } else {
        this.updateArea();
      }
    }
  }

  getAreaDataById() {
    let area_id = this.area_id;
    this.areaService.getAreaDataById(area_id).subscribe(result => {
      this.setUpdateValueInForm(_.find(result.content,{area_id:parseInt(area_id)}));
    });
  }

  transforInsertData(formData:any) {
    let formatedInputData:any = {};
    formatedInputData.area_name = formData.area_name;
    formatedInputData.area_status = formData.area_status === true ? 'Y':'N';
    return formatedInputData;
  }

  insertNewArea() {
    if (this.areaForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.areaForm.value);
      let formatedInputData: any = {};
      formatedInputData.name = apiInputData.area_name;
      formatedInputData.status = apiInputData.area_status;
      this.areaService.insertArea(formatedInputData).subscribe(result => {
          this.saveBtn =false;
          this.areaForm.reset({area_status:true});
          this.cdRef.detectChanges();
          this.backToViewArea();
          alertify.success('Area has been created successfully');
        },err => {
          this.saveBtn =false;
          alertify.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateArea() {
    if (this.areaForm.valid === true) {
      this.saveBtn =true;
      let apiInputData = Object.assign({}, this.areaForm.value);
      let area_id = this.selectForUpdate.area_id;
      let formatedInputData: any = {};
      formatedInputData.name = apiInputData.area_name;
      formatedInputData.status = apiInputData.area_status;
      ////console.log(formatedInputData);
      this.areaService.updateArea(formatedInputData, area_id).subscribe(result => {
        this.saveBtn =false;
        this.areaDataForGrid.content.forEach((item, index) => {
          if (item.area_id === area_id) {
              this.areaDataForGrid.content[index].area_name = formatedInputData.name;
              this.areaDataForGrid.content[index].area_status = formatedInputData.status;
            }
          });
          this.areaForm.reset({status:true});
          this.cdRef.detectChanges();
          this.show_update = false;
          ////console.log(result);
          this.backToViewArea();
          alertify.success('Area Update successfully');
        },err => {
          this.saveBtn =false;
          alertify.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  backToViewArea() {
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/masters/area']);
    }
  }

  setUpdateValueInForm(areaData) {
      this.show_update = true;
      this.selectForUpdate = areaData;
      this.areaForm.patchValue(areaData);

 }

 closeDialog(){
  this.dialogRef.close();
 }

}
