import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { CustomerPasswordService } from '../cpe-password/customer-password.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
@Component({
  selector: 'app-reset-cpe-password',
  templateUrl: './reset-cpe-password.component.html',
  styleUrls: ['./reset-cpe-password.component.scss']
})
export class ResetCpePasswordComponent implements OnInit {

  enableAutoGenPass: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  @Input() custId : any;

  resetCustomerPasswordForm = new FormGroup({
    remarks : new FormControl('', [Validators.required]),
    autoGeneratePassword:new FormControl(false),
  })

  constructor(
    private customModalService : CustomModalService,
    private customerPasswordService : CustomerPasswordService,
    private tosterservice : ToastrService,
    public loginService: LoginService,


  ) { }

  ngOnInit() {
  }
  submitted :boolean;
  onClickSaveForm() {
    if (this.resetCustomerPasswordForm.valid === true) {
      let self = this;
      alertify.confirm("Reset CPE Password","Are You Sure You want to Reset CPE Password ?",
      function() {
        self.resetCPEPasswprd();
        self.closeModal('reset-cpe-password');
      },
      function() {
  
      });
    } else {
      this.submitted = true;
      this.resetCustomerPasswordForm.markAllAsTouched();
    }
  }

  resetCPEPasswprd() {
    let apiInputData = Object.assign({}, this.resetCustomerPasswordForm.value);
    apiInputData.custId = this.custId;
    this.customerPasswordService.resetCustPassword(apiInputData).subscribe(result => {
      this.tosterservice.success('CPE Password Reset Successfully.');
    },err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    })
  }

  onChangeEnableAutoGenPass(el){
    this.enableAutoGenPass = el.target.checked;
    this.resetCustomerPasswordForm.get('autoGeneratePassword').setValue(this.enableAutoGenPass)
  }


  closeModal(id:string){
    this.customModalService.close(id);
    this.submitted = false;
    this.resetCustomerPasswordForm.reset()
  }

}
