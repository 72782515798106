import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { SearchData } from '../../../../core/modal/search-data';
import { BaseService } from '../../../../services/base-service';
import { ServiceAreaModel } from '../../../network-management/service-area/model/service-area-model';
import { StaffAvailableModel } from './staff-available-model';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class ServiceService  extends BaseService<StaffAvailableModel> {
  staffId:number
  paginationData:PaginationData
  constructor(http: HttpClient) {
    super(http);
  }
  getModuleName(): string {
    return '';
  }
  getAll(data?): Observable<any>{
    if(!data){
      data = {};
    }
    data['page'] = this.paginationData?this.paginationData.pageNumber.toString():'1'
    data['pageSize'] =this.paginationData?this.paginationData.rowsPerPage.toString():'10'
    data['sortBy'] = 'id'
    data['sortOrder'] = 0
    data['id']= this.staffId
    const post_url = baseApiUrl + 'staffManageAbsence/getAllByStaffId';
    return this.http.post<any>(post_url, data);
  }
 getStaffAll(){
   
 }
  saveStaffAvailable(data){
    const post_url = baseApiUrl + 'staffManageAbsence/save';
    return this.http.post<any>(post_url, data);
  }
  deleteStaffAvailable(data){
    const post_url = baseApiUrl + 'staffManageAbsence/delete';
    return this.http.post<any>(post_url, data);
  }
  editStaffAvailable(data){
    const post_url = baseApiUrl + 'staffManageAbsence/update';
    return this.http.post<any>(post_url, data);
  }
  validate(): boolean {
    return false;
  }
}