import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { BroadcastModel, Broadcastparam } from './model/BroadcastModel';
import { stringify } from '@angular/compiler/src/util';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IBaseService } from '../../../services/ibase-service';
import { BroadcastService } from './service/Broadcast.service';
import { PackagesService } from '../../plan_management/packages/packages.service';
import { NetworkDevicesService } from '../../network-management/network-device/services/network-devices.service';
import { NetworkDevices } from '../../network-management/network-device/model/network-detail.model';
import { PortDetail } from '../../network-management/network-device/model/port.model';
import { BroadcastPortModel } from './model/BroadcastPortModel';
import { ServiceAreaModel } from '../../network-management/service-area/model/service-area-model';
import { ServiceAreaService } from '../../network-management/service-area/service/service-area.service';
import { Slot } from '../../network-management/network-device/model/slot.model';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { HostListener } from '@angular/core';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { BoradcastListComponent } from './boradcast-list/boradcast-list.component';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { ChargeService } from '../../reports/charge/charge.service';
import { NotificationService } from '../notification/notification.service';
import * as moment from 'moment';
import { RecentRenewalService } from '../../reports/recent-renewal.service';
import { ServiceAreaServices } from '../../../services/service_area/service_area.service';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss']
})
export class BroadcastComponent extends AbstractBaseAddUpdateComponent<BroadcastModel> implements OnInit {

  public entity: BroadcastModel = new BroadcastModel();
  public entity_id: any = null;
  form: FormGroup;
  dynaFrom: FormGroup;
  error_message = "This field is required.";
  packagesData: any = { content: [] };
  partnerIdDDL:any;
  serviceAreaData: ServiceAreaModel[] = [];
  networkDeviceData: NetworkDevices[] = [];
  selectedNetwordevice:any
  slotData: Slot[] = [];
  portData: PortDetail[] = [];
  templateList: any;
  CommonListYN:any;
  tempPortIdList: number[] = [];
  partnerList: any[] = [];
  @ViewChild(BoradcastListComponent, {static: false})
  private broadCast: BoradcastListComponent;  typeList: string[] = [
    'SMS', 'Email','Both'
  ];
  expiryList: string[] = [
    'Between', 'Lessthan', 'Greaterthan', 'Equal'
  ];
  condList: string[] = [
    'Location', 'Expiry', 'Customer','Outstanding'
    //'Customer','Plan',
  ];
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  broadCastHistoryNeoGridSettings = { };
  custstatus:any;
  dynaArry = [];
brodcastParamObj:Broadcastparam
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  planModel: any = { start_time: new Date() };
  constructor(private broadcastService: BroadcastService,
    private packageService: PackagesService,
    private serviceArea: ServiceAreaService,
    private networkDevice: NetworkDevicesService,
    private service:ServiceAreaServices,
    private chargeService:ChargeService,
    private activeRoute: ActivatedRoute,
    private notificationservice:NotificationService,
     private router: Router,
    public toastrService: ToastrService,
    loginService: LoginService,
    public fb: FormBuilder,
    private genericDataSharingService: DataSharingServiceService,
    private recentRemewalService: RecentRenewalService
    ) {
    super(toastrService, router);
    // setTimeout(() => {
    //   let el: any = document.getElementById('datePicker');
    //    el.value = el.value + ' (Today)';
    // });
    this.loginService = loginService;
  }

  changeDate(event: any) {
    let todayDate = new Date();
    let todatDateString = moment(todayDate).format('DD-MM-YY');
    let SelectedDateString = moment(this.planModel.start_time).format('DD-MM-YY');
    let el: any = document.getElementById('datePicker');
    if (todatDateString === SelectedDateString) {
      el.value = el.value + ' (Today)';
    } else {
      el.value = el.value;
    }
  }

  ngOnInit() {
    this.init();
    this.setValidation();
    //this.getTemplates();
    this.getPartnerList();
    this.getCustStatus();
    this.getCommonListYN();
    
  }

  getService(): IBaseService<BroadcastModel> {
    return this.broadcastService;
  }

  setData(data: BroadcastModel) {
    this.entity = data;
  }

  getData(): BroadcastModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {
    return '/operations/broadcast';
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): BroadcastModel {
    return new BroadcastModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }
  getCommonListYN(){
    this.broadcastService.getCommonListYesNo().subscribe((res)=>{
    this.CommonListYN =  res.dataList ;  
    })
  }


  public getId(): number {
    return this.entity.id;
  }

  get f() {
    return this.form.controls;
  }

  public fDyna(dynaObj) {
    return this.dynaFrom.get(dynaObj);
  }

      /** No Need:Another API Used */  
  getTemplates(notification: string) {
     // this.broadcastService.getTemplates(notification).subscribe(result => {
    //    /** Remove Becoz Data comes from another way*/
    //   //this.templateList = result.data;
    // })
  }

  onSelectionChange(selection) {
  if(selection){
    if (selection === 'Location') {
      this.form.get('expiry').clearValidators();
      this.form.get('expiry').setErrors(null);
      this.form.get('expirydate1').clearValidators();
      this.form.get('expirydate1').setErrors(null);
      this.form.get('expirydate2').clearValidators();
      this.form.get('expirydate2').setErrors(null);
      this.form.get('service').setValidators(Validators.required);
      this.form.get('networkDevice').setValidators(Validators.required);
      this.form.get('slot').setValidators(Validators.required);
      this.form.get('port').setValidators(Validators.required);
      this.form.get('expiry').reset();
      this.form.get('expirydate1').reset();
      this.form.get('expirydate2').reset();
      this.form.updateValueAndValidity();
    }
     if (selection === 'Expiry'){
      this.form.get('service').clearValidators();
      this.form.get('service').setErrors(null);
      this.form.get('networkDevice').clearValidators();
      this.form.get('networkDevice').setErrors(null);
      this.form.get('slot').clearValidators();
      this.form.get('slot').setErrors(null);
      this.form.get('port').clearValidators();
      this.form.get('port').setErrors(null);
      this.form.get('expiry').setValidators(Validators.required);
      this.form.get('expirydate1').setValidators(Validators.required);
      this.form.get('expirydate2').setValidators(Validators.required);

     
      this.form.get('service').reset();
      this.form.get('networkDevice').reset();
      this.form.get('slot').reset();
      this.form.get('port').reset();
      this.form.get('expirydate1').reset();
      this.form.get('expiry').reset();
      this.form.get('expirydate2').reset();
      this.form.updateValueAndValidity();
    }
    if(selection === 'Customer'){
      this.form.get('custstatus').setValidators(Validators.required);
      this.form.get('custstatus').setErrors(null);
      this.form.updateValueAndValidity();
    }else{
      this.form.get('custstatus').reset()
      this.form.get('custstatus').setErrors(null);
      this.form.updateValueAndValidity();
    }
  }
  

  }

  getPostpaidPlan() {
    this.packageService.getPackagesList().subscribe(result => {
      this.packagesData = result;
    });
    ////console.log(this.packagesData);

  }

  resetportField(){
    this.entity.slotid=null;
    this.tempPortIdList=null;
    this.portData=null;
    this.slotData=null;
    this.form.get('slot').reset();
    this.form.get('port').reset();
  }
  slotportReset(){
    this.portData=null;
    this.slotData=null;
    this.form.get('slot').reset();
    this.form.get('port').reset();

  }

  getServiceArea(partnerId) {
    this.service.getPlanServiceArea(partnerId).subscribe(result => {
      this.serviceAreaData = result.dataList;
    });
  }

  getNetworkDevices(service) {
    this.form.get('networkDevice').reset();
    this.form.get('slot').reset();
    this.form.get('port').reset();
    this.tempPortIdList=null;
    this.entity.slotid=null;
    this.entity.networkdeviceid=null;


    this.form.updateValueAndValidity();
    if(service){
      this.entity.serviceAreaName = service.name
      this.networkDevice.getNetworkDeviceByServiceArea(service.id).subscribe(result => {
        this.networkDeviceData = result.dataList;
      })
    }
    
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  getSlotByNetworkDevices(networkDevice) {
    this.form.get('slot').reset();
    this.form.updateValueAndValidity();
    this.form.get('port').reset();
    this.form.updateValueAndValidity();
    // this.tempPortIdList=null;
    
    if(networkDevice){
      this.selectedNetwordevice =  networkDevice;
      this.entity.networkDeviceName = networkDevice.name;
      ////console.log("slected network::",networkDevice);
      
      if(networkDevice.snmpEnabled === "Yes"){
  
        this.networkDevice.getSNMPSlotList(networkDevice.uuid).subscribe((res)=>{
  
        ////console.log("res::",res.dataList)
        if(res.dataList){
          this.slotData =  res.dataList
          let tempSlotObj: Slot = new Slot();
          tempSlotObj.name = "All"
          tempSlotObj.id = "-1"
          this.slotData.push(tempSlotObj)
        //  res.dataList.forEach(element => {
        //   tempSlotObj.id = element.key;
        //   tempSlotObj.name = element.key
        //  });
        }
     
        })
      }
      else{
        this.networkDevice.getSlotByNetworkDevice(networkDevice.id).subscribe(result => {
          this.slotData = result.dataList;
          let tempSlotObj: Slot = new Slot();
          tempSlotObj.name = "All"
          tempSlotObj.id = "-1";;
          this.slotData.push(tempSlotObj);
        })
      }
      
    
      this.portData = null;
      ////console.log('Slot data :: ', this.slotData);
  
    }
 
  }

  getPortBySlot(slotId: string) {

    if(slotId){
      this.form.get('port').reset();
      this.form.updateValueAndValidity();
      this.portData=null;
      ////console.log(this.slotData)
      this.entity.slotName = slotId;
    if(this.selectedNetwordevice && this.selectedNetwordevice.snmpEnabled === "Yes"){
      this.entity.snmp_slot  = slotId
      this.networkDevice.getSNMPPortList(slotId,this.selectedNetwordevice.uuid).subscribe((res)=>{
        this.portData = res.dataList
      });
    }else{
      let tempSlot: Slot = this.slotData.find(data => data.id == slotId);
      this.portData = tempSlot.oltPortDetailsList;
    }
    if(slotId === '-1'){
    this.form.get('port').clearValidators();
    this.form.get('port').updateValueAndValidity();
    }
    }
 
  }

  selectTemplate(template) {
    if(template){
      this.dynaArry =  new Array()
      this.entity.sms_body =  removeHTML(template.sms_body.toString());
      this.entity.email_body =  removeHTML(template.email_body.toString());
      this.entity.pushNotificationBody = removeHTML(template.pushNotificationBody.toString());
      this.entity.templateName =  template.name;
      console.log("string arrray =>",this.entity.email_body);
      let Allstring = this.entity.sms_body + ' ' + this.entity.email_body + ' ' + this.entity.pushNotificationBody;
      let stringArray = Allstring.split(' ');
      console.log("string arrray =>",stringArray);
      
      stringArray.forEach((stringArrayElement)=>{
        if(stringArrayElement.startsWith('$')&& !this.dynaArry.find(res=> res == stringArrayElement.substring(1))){
          this.dynaArry.push(stringArrayElement.substring(1))
        }
      });
      console.log(this.dynaArry)
      
          if(this.dynaArry && this.dynaArry.length > 0){
            this.dynaFrom = new FormGroup({});
            this.dynaArry.forEach((ls) => {
              console.log(ls);
              this.dynaFrom.addControl(ls, this.fb.control(''));
              this.dynaFrom.get(ls).setValidators(Validators.required);
            });
          }
    }
    else{
      this.dynaArry = []
      this.entity.sms_body = ''
      this.entity.email_body = ''
      this.entity.pushNotificationBody = ''
    }


  }

  setBroadcastPort(portid: number) {
    let broadcastPort: BroadcastPortModel = new BroadcastPortModel();
    let check: boolean = false;
    broadcastPort.portid = portid;
    this.entity.broadcastPortsList.forEach(ele => {
      if (ele.portid == portid) {
        check = true;
        let index: number = this.entity.broadcastPortsList.indexOf(ele);
        this.entity.broadcastPortsList.splice(index, 1);
      }
    }) 
    if (check == false) {
      this.entity.broadcastPortsList.push(broadcastPort);
    }
  }
  selectedPort(){

    if( this.entity.custcondition ==='Location' && this.selectedNetwordevice.snmpEnabled === "Yes")
    {
      this.entity.broadcastPortsList = null
      this.entity.snmp_port = this.tempPortIdList
       this.entity.portNames = this.tempPortIdList.toString()
    }
  }

  setValidation() {
    this.form = new FormGroup({
      // broadcast_type: new FormControl('', Validators.required),
      cust_condition: new FormControl(null, Validators.required),
      email_subject: new FormControl(null, [Validators.required]),
      cust_status: new FormControl('',),
      plans: new FormControl('',),
      service: new FormControl('',),
      partnerSpecific: new FormControl(null),
      networkDevice: new FormControl('',),
      slot: new FormControl('',),
      port: new FormControl('',),
      expiry: new FormControl('',),
      expirydate1: new FormControl('',),
      expirydate2: new FormControl('',),
      template: new FormControl('', [Validators.required]), //
      sms_body: new FormControl(null),
      email_body: new FormControl(null),
      pushNotification_body: new FormControl(null),
      PartnerId:new FormControl('',[Validators.required]), //New Key Add,
      custstatus: new  FormControl(null),
      date: new FormControl('')
    });
  }

  formSubmit() {
    this.entity.broadcastParamList =  new Array<Broadcastparam>();
    this.submitted = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return
    }
    if(this.dynaArry && this.dynaArry.length > 0){
      this.dynaFrom.markAllAsTouched();
      if(this.dynaFrom.invalid){
        return
      }
    }
  
    if(this.dynaArry && this.dynaArry.length > 0){
      this.dynaArry.forEach((element)=>{
        this.brodcastParamObj =  new Broadcastparam()
        this.brodcastParamObj.paramKey = element
        this.brodcastParamObj.paramValue = this.fDyna(element).value
        this.entity.broadcastParamList.push(this.brodcastParamObj)
      
      })
    
    }
  

if(this.entity.custcondition ==='Location' && this.selectedNetwordevice.snmpEnabled !== "Yes"){
  if (this.tempPortIdList != null && this.tempPortIdList !== undefined) {
    this.tempPortIdList.forEach((data) => {
      let tempBroad = new BroadcastPortModel();
      tempBroad.portid = data;
        this.entity.broadcastPortsList.push(tempBroad);
    })
  }
}
   
    if(this.entity.custcondition ==='Location' && this.selectedNetwordevice.snmpEnabled === "Yes"){
      this.entity.slotid = null;
      this.entity.broadcastPortsList =null;
    }
    console.log("entity==>",this.entity)
  if(this.entity.custcondition == 'Customer'){
    this.entity.custcondition = 'customer'
  }
    this.onSubmit();
    this.form.reset();
    this.dynaFrom.reset();
   this.selectTemplate(null)
    ////console.log(this.broadCast)
    this.broadCast.ngOnInit();
  }


  getGenericDataService(){
    return this.genericDataSharingService
    }
    
   getCustStatus(){
    this.broadcastService.getcustomerstatus().subscribe((res)=>{
this.custstatus = res.dataList;
    })
   }

  getPartnerList() {
    let partnerId = localStorage.getItem('partnerId')
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.recentRemewalService.getPartnerlistService().subscribe(result => {
        this.partnerList = result.partnerlist;
      })
    }
    else{
      this.recentRemewalService.getManagablePartnerlistService().subscribe(result => {
        this.partnerList = result.dataList;
        // if (partnerId) {
        //   this.searchRecentRenewAlForm.get('partnerId').setValue(this.Localpartnerid);
        //   this.searchRecentRenewAlForm.get('partnerId').disable();
        // }
      })
    }
    setTimeout(() => {
      if(this.partnerList){
        let  temp = this.setAutoSelectDropDownList(this.partnerList)   
        this.form.get('PartnerId').setValue(temp.id)   
      }
    }, 250);
    // this.chargeService.getPartnerlistService().subscribe(result => {
    //   this.partnerList = result.partnerlist;
    // })
  }


  selectPartner(data)
  {
    this.partnerIdDDL = data.id;
    this.getServiceArea(this.partnerIdDDL)
  }

  getGroupNotification(event){
    this.getServiceArea(this.partnerIdDDL);
    this.entity.templateid=null;
    this.templateList=null;
    if(event.notifiGroupId){
      let NotificationGroupId=event.notifiGroupId;
      this.NotificationGroupgetDataByID(NotificationGroupId)
       }else{
        this.error_message = "This field is required.";
      }
    }
  
  NotificationGroupgetDataByID(notificationGroupId){
    this.notificationservice.getBroadcastNotificationByGrpId(notificationGroupId).subscribe(result=>{
         this.templateList = result.dataList;
    })
  }

}
  

function removeHTML(str){ 
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}