import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { CountryService } from './../country/country.service';
import { StateService } from './../state/state.service';
import { CityService } from './city.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import _ from 'underscore';

import { MatDialog, MatTableDataSource ,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import {
  SearchBooleanTypeEnum,
  SearchDataTypeEnum,
  SearchControlTypeEnum,
  SearchFilterTypeLabelEnum,
  SearchFilterTypeValueEnum,
} from '../../../core/enum/searchType.enum';
import { SearchService } from '../../../services/search.service';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CityComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;

  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  
  @ViewChild('editUserName', { static: false }) editUserNameReff: TemplateRef<any>;

  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  cityDataForGrid: any = { content: [] };
  cityGridSettings: any = {};
  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public stateList: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  selectForUpdate: any;
  show_update: any = false;
  insertCityForm = new FormGroup({
    country_id: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    city_name: new FormControl('', [Validators.required]),
    status: new FormControl(true, [Validators.required]),
  });
  tableKeyEnum = TableKeyEnum;
  // config for neo-table
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  cityNeoGridSettings: any = {};
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredCityDataForGrid: any = [];
  serverSideFilter: boolean = false;
  public loginService: LoginService;

  constructor(
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginService: LoginService,
    private tosterservice: ToastrService
  ) {
    super(searchService);
    this.loginService = loginService;


  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.CITY_LIST);
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'city_name') {
        col.customControls = [this.editUserNameReff];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        sortKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'city_name',
        default: 'City Name',
        rowKey: 'city_name',
        sortKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'state_name',
        default: 'State Name',
        rowKey: 'state_name',
        sortKey: 'stateName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'country_name',
        default: 'Country Name',
        rowKey: 'country_name',
        sortKey: 'countryName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        sortKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status === 'Active') {
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
       // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
      {
        display_field: 'City Name',
        value_field: 'city_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Country Name',
        value_field: 'country_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'State Name',
        value_field: 'state_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];

    this.cityNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CITY_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this City ?',
      deleteMsgBoxDetail: 'City Name :${city_name} ',
    };

    if (this.cityNeoGridSettings.serverSideSortPagination && this.cityNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.cityNeoGridSettings.perPage);
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getCityData(config);
      }
  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
     if (event) {
       this.commonDeleteMsgBox(this.cityNeoGridSettings,event,this.delete.bind(this));
     }
  }

  excelDownload() {
    ////console.log('excelDownload');
  }
  pdfDownload() {
    ////console.log('pdfDownload');
  }

  getCityData(gridSetting: any) {
    let fetchSettingData = this.cityNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.cityService.getCityDataWithPageing(params).subscribe((result) => {
      this.cityDataForGrid = result;
      this.filteredCityDataForGrid = JSON.parse(JSON.stringify(this.cityDataForGrid.content));
      this.refreshDataSource(this.cityDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.cityNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.cityDataForGrid.content));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.cityNeoGridSettings.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.cityDataForGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  delete(objEvent) {
    let self = this;
    let selected_city_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.cityService.deleteCity(selected_city_data.city_id).subscribe(
        (result) => {
          this.tosterservice.success('City is deleted successfully');
          this.show_update = false;
          self.cityDataForGrid.content.forEach(function (item, index) {
            if (item.city_id === selected_city_data.city_id) {
              self.cityDataForGrid.content.splice(index, 1);
            }
          });
          self.refreshDataSource(self.cityDataForGrid.content);
        },
        (err) => {
          this.tosterservice.error(err.error.status + ' ' + err.error.ERROR);
        }
      );
    }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/city/add_city',rowData.city_id]);
  }

  onAddCityClick() {
    this.router.navigate(['/masters/city/add_city']);
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getCityData(config);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
