import { Component, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  public active: boolean;

    public constructor(loadingService: LoadingService) {
      loadingService.status.subscribe((status: boolean) => {
        this.active = status;
      });
    }
    
    ngOnInit() {

    }

}
