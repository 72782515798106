import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { NetworkDevices } from '../model/networkdevice.model';

const baseApiUrl='';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class NetworktreeService {

  constructor(private http:HttpClient) { }
  // let params = new HttpParams().set("page", page).set("size", size); 
 
//  getnetworkList():Observable<any>{

//     const get_url = baseApiUrl + "NetworkDevice/list";
//    return this.http.post<any>(get_url);
//   }

  getNetworkDevice(params:HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'snmpApi/snmp/snmpEnableList';

    return this.http.post<any>(post_url, params,
      { headers: httpOptions.headers}).pipe(
        map(res => res as NetworkDevices[]),
        catchError((error: any) => {
          return throwError(error);
        })
      )
   } 

 
  getframeList(params: HttpParams): Observable<any> {
    
    const post_url = baseApiUrl + 'snmpApi/snmp/getFrameByOltUuid';
    return this.http.post<any>(post_url, params, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  // getsloteList(params: HttpParams): Observable<any> {
  //   //console.log("service -slot obj",params)
  //   const post_url = baseApiUrl + 'snmpApi/snmp/getSlotsByFrameAndDeviceuuid';
  //   return this.http.post<any>(post_url, params, httpOptions).pipe(
  //     map(data => data),
  //     catchError((error: any) => {
  //       return throwError(error);
  //     })
  //   );
  // }

  getONUList(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'snmpApi/snmp/getOnuByPortAndSlotAndFrameAndDeviceuuid';
    return this.http.post<any>(post_url, params, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getFetchONU(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'snmpApi/snmp/fetchOnuByPortAndSlotAndFrameAndDeviceuuid';
    return this.http.post<any>(post_url, params, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  
  getportList(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'snmpApi/snmp/getPortBySlotAndFrameAndDeviceuuid';
    return this.http.post<any>(post_url, params, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  }
  

