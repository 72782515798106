import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import * as alertify from 'alertifyjs';
import { NetworkDevicesService } from '../network-device/services/network-devices.service';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../../../services/search.service';
import { LoginService } from '../../login/login.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
@Component({
  selector: 'app-networkstatus',
  templateUrl: './networkstatus.component.html',
  styleUrls: ['./networkstatus.component.scss']
})
export class NetworkstatusComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('actionWrapper', { static: false }) actionWrapperRef: TemplateRef<any>;
  @ViewChild('status', { static: false }) statusRef: TemplateRef<any>;
  tableKeyEnum = TableKeyEnum;
  oltDevice: any;
  allColumns: any[] = [];
  olddeviceGrid: any = { content: [],dataList:[] };
  dataSource = new MatTableDataSource([]);
  valueAddedNeoGridSettings: any = {};
  subscriptions: Subscription[] = [];
  networkdevice: any[] = [];
  openCancelPlanPopup: boolean;
  filteredDataForGrid:any={};
  valueAddedTableData: any;
  onuhealth: any
  isfetchOnPaginaionData: boolean = false;
  onrefreshhData: boolean = false;
  fetchLastModifidData: boolean = false;
  loginservice: any;
  gridSettingData: any;
  constructor(
    private networkdevices: NetworkDevicesService,
    private tosterservice: ToastrService,
    private searchService: SearchService,
    private loginService: LoginService,
  ) {
    super(searchService);
    this.loginservice = loginService;
  }

  ngOnInit() {
this.setDefaultPageSize(this.tableKeyEnum.NETWORK_STATUS)
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
       this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
    this.OltDevices();
    this.setGridSetting();
    this.onuhealth = [{
      onu: "ZTE-SN#ZTE0QJNM4L09477",
      olt: "-",
      slot: "-",
      port: "-",
      power: "-"
    },
    {
      onu: "NOKIA-SN#avba12345bic",
      olt: "-",
      slot: "-",
      port: "-",
      power: "-"
    },
    {
      onu: "NOKIA-SN#avba1234aia",
      olt: "Satellite Cisco-OLT-1",
      slot: "SLOT-1",
      port: "1/1",
      power: "-21dbm"
    }]
    // this.refreshDataSource(this.onuhealth)
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'action') {

        col.customControls = [this.actionWrapperRef];
      }
      if (col.colKey === 'status') {

        col.customControls = [this.statusRef];
      }
    });
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.olddeviceGrid.dataList));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.valueAddedNeoGridSettings.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.olddeviceGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.gridSettingData = config;
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      // {
      //   colKey: 'service',
      //   default: 'Service',
      //   rowKey: 'service',
      //   width: '10%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      // {
      //   colKey: 'provisionId',
      //   default: 'Provison Id',
      //   rowKey: 'provisionId',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'serialnumber',
        default: 'Serial Number',
        rowKey: 'serialnumber',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'oltframe',
        default: 'frame',
        rowKey: 'oltframe',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'oltSlot',
        default: 'slot',
        rowKey: 'oltSlot',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'oltPort',
        default: 'PORT',
        rowKey: 'oltPort',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ont',
        default: 'ONT',
        rowKey: 'ont',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'power',
        default: 'Power',
        rowKey: 'power',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastDownCause',
        default: 'Last Down Cause',
        rowKey: 'lastDownCause',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastDownTime',
        default: 'Last Down Time',
        rowKey: 'lastDownTime',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastUpTime',
        default: 'Last Up Time',
        rowKey: 'lastUpTime',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastDyingGaspTime',
        default: 'Last Dying Gasp Time',
        rowKey: 'lastDyingGaspTime',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }];
    //   {
    //     colKey: 'action',
    //     default: 'Action',
    //     rowKey: 'action',
    //     width: '10%',
    //     isNumber: false,
    //     isFilterAllow: false,
    //     enableEdit: true,
    //     enableDelete: true,
    //     enableSort: true,
    //   }
    // ];

    this.valueAddedNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.NETWORK_STATUS,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this City ?',
      deleteMsgBoxDetail: 'City Name :${city_name} ',
    };
    if (this.valueAddedNeoGridSettings.serverSideSortPagination && this.valueAddedNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.valueAddedNeoGridSettings.perPage);
    }
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.gridSettingData = config;
    if (this.onrefreshhData) {
      this.isfetchOnPaginaionData=true;
      this.refreshNetworkDevices();
    }
    if (this.fetchLastModifidData) {
      this.isfetchOnPaginaionData=true;
      this.fetchLastUpdateData();
    }
  }
  refreshDataSource(data) {
    if (data) {
      ////console.log("data==>", data)
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onRefreshAllClick(event?) {
    let params: any = {};
    this.onrefreshhData = true;
    this.fetchLastModifidData = false;
    this.isfetchOnPaginaionData = false;
    if (this.oltDevice === undefined || this.oltDevice === null) {
      this.tosterservice.error("Please select network device!");
      return;
    } else {

      let self = this;
      alertify.confirm("Network Status", "This is heavy network centric operation are you sure you want to continue ?",
        function () {
          self.refreshNetworkDevices();
        },
        function () {

        });
    }
  }

  onRefreshClick() {
    alertify.confirm("Fetch ONU status", "Are you sure you want fetch ONU status ?",
      function () {

      },
      function () {

      });
  }
  OltDevices() {
    this.networkdevices.getOLTDevice().subscribe(result => {
      this.networkdevice = result.dataList;
    })
  }

  refreshNetworkDevices() {
    let apiInpuData = this.transFormPaginationData();
    this.networkdevices.getOLTNetworkDevices(apiInpuData).subscribe(response => {
      this.olddeviceGrid.content = response.data;
      ////console.log("demo",response.data)
      if(response && response.dataList){
        this.olddeviceGrid.dataList=response.dataList;
        this.olddeviceGrid.content.lastModifiedDate = this.olddeviceGrid.content.lastModifiedDate ? this.olddeviceGrid.content.lastModifiedDate : '-';
      this.olddeviceGrid.content.lastPendingReqDate = this.olddeviceGrid.content.lastPendingReqDate ? this.olddeviceGrid.content.lastPendingReqDate : '-';
      this.olddeviceGrid.content.countOfPendingReq = this.olddeviceGrid.content.countOfPendingReq ? this.olddeviceGrid.content.countOfPendingReq : '-';
        this.filteredDataForGrid = JSON.parse(JSON.stringify(this.olddeviceGrid.dataList));
        this.refreshDataSource(this.olddeviceGrid.dataList);
        this.totalRecords = response ? response.totalRecords : 0;
        this.valueAddedNeoGridSettings.pageTotalLength = this.totalRecords;
        setTimeout(() => {
          this.refreshDataSource(this.olddeviceGrid.dataList);
        }, 1000);
      }else{
        this.refreshDataSource([]);
      }
    })
    

  }
  onFetchlastData() {
    this.fetchLastModifidData = true;
    this.onrefreshhData = false;
    this.isfetchOnPaginaionData = false;
    let self = this;
    if (this.oltDevice === undefined || this.oltDevice === null) {
      if (this.fetchLastModifidData) {
        this.tosterservice.error("Please select network device!");
      }
      return;
    } else {
      this.fetchLastUpdateData();
    }
  }
  fetchLastUpdateData() {
    let apiInpuData = this.transFormPaginationData();
    this.networkdevices.getLastFetchData(apiInpuData).subscribe(response => {
      this.olddeviceGrid.content = response.data;
      ////console.log("DATA::",this.olddeviceGrid.content);
      if(response && response.dataList){
        this.olddeviceGrid.dataList=response.dataList;
      this.olddeviceGrid.content.lastModifiedDate = this.olddeviceGrid.content.lastModifiedDate ? this.olddeviceGrid.content.lastModifiedDate : '-';
      this.olddeviceGrid.content.lastPendingReqDate = this.olddeviceGrid.content.lastPendingReqDate ? this.olddeviceGrid.content.lastPendingReqDate : '-';
      this.olddeviceGrid.content.countOfPendingReq = this.olddeviceGrid.content.countOfPendingReq ? this.olddeviceGrid.content.countOfPendingReq : '-';
        this.filteredDataForGrid = JSON.parse(JSON.stringify(this.olddeviceGrid.dataList));
        this.refreshDataSource(this.olddeviceGrid.dataList);
        this.totalRecords = response ? response.totalRecords : 0;
        this.valueAddedNeoGridSettings.pageTotalLength = this.totalRecords;
        this.refreshDataSource(this.olddeviceGrid.dataList);
      }else{
        this.refreshDataSource([]);
      }
    });
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  transFormPaginationData() {
    let paramsValue: any = {paginationRequestDTO:{},deviceid:""};
    if (this.isfetchOnPaginaionData === true) {
      let fetchSettingData = this.valueAddedNeoGridSettings.serverSideSortPagination ? this.gridSettingData : null;
      let params = this.getGridSettingInfo(fetchSettingData);
      paramsValue.paginationRequestDTO.page = params.page;
      paramsValue.paginationRequestDTO.pageSize = params.pageSize;
      paramsValue.deviceid = this.oltDevice;
    } else if (this.isfetchOnPaginaionData === false) {
      let fetchSettingData = this.valueAddedNeoGridSettings.serverSideSortPagination ? this.gridSettingData : null;
      let params = this.getGridSettingInfo(fetchSettingData);
      params.page = 1;
      params.pageSize = 10;
      paramsValue.paginationRequestDTO.page = params.page;
      paramsValue.paginationRequestDTO.pageSize = params.pageSize;
      paramsValue.deviceid = this.oltDevice;
    }
    return paramsValue;
  }
}


