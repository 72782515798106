import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../../../../services/base-service';
import { BroadcastModel } from '../model/BroadcastModel';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService extends BaseService<BroadcastModel> {
  constructor(http: HttpClient) {
    super(http);
  }

  getModuleName(): string {
    return 'broadcast';
  }

  validate(): boolean {
    return false;
  }
  getcustomerstatus(){
    const get_url = BASE_API_URL + 'commonList/generic/custStatusBroadCast'
    return this.http.get<any>(get_url,{ headers: httpOptions.headers })
  }
  getCommonListYesNo(){
    const get_url=BASE_API_URL+"commonList/generic/commonYN";
    return this.http.get<any>(get_url);
  }
  getTemplates(notification:string): Observable<any> {
    
    const get_url = BASE_API_URL + 'notification/getGenricNotification/'+notification;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  // getBroadcastHistory(): Observable<any> {
  //   const get_url = BASE_API_URL + 'notification/getGenricNotification/'+notification;
  //   return this.http.get<any>(get_url,
  //     { headers: httpOptions.headers })  
  //   }

}
