import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from './pages/login/login.service';
import {Injectable} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    //return true;
    if (this.loginService.isAuthenticated()) {
      if(route && route.data && route.data.operation && route.data.classId && route.data.operationId && route.data.accessIdForAllOpreation){
        if(!this.loginService.hasOperationPermission(route.data.classId,route.data.operationId,route.data.accessIdForAllOpreation)){
          this.toastr.error("Opps! You're not authorized for " + route.data.operation, "Access denied");
          this.router.navigate([this.router.url]);
          return false;
        }
      }
      return true;
    } else {
      ////console.log(this.router.url);
      if(this.router.url === '/login' || this.router.url === '/') {
        this.router.navigate(['/login']);
        localStorage.setItem('login','/login');
      }
      if(this.router.url === '/partnerLogin'){
        this.router.navigate(['/partnerLogin']);
        localStorage.setItem('partnerlogin','/partnerLogin');
      }

    }
  }
}
