import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../services/base-service';
import { ClientGroupModel } from '../model/client-group-model';
@Injectable({
  providedIn: 'root',
})
export class ClientGroupService extends BaseService<ClientGroupModel> {
  constructor(http: HttpClient) {
    super(http);
  }

  getModuleName(): string {
    return 'clientgroup';
  }

  validate(): boolean {
    return false;
  }
}
