import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../services/base-service';
import { PinAreaModel } from './pin-area-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})

export class PinAreaService extends BaseService<PinAreaModel> {

  constructor(http: HttpClient) {
    super(http);
  }

  getAreaDetails(areaId : any): Observable<any> {
    const get_url = baseApiUrl + 'area/' + areaId;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers});
  }

  getModuleName(): string {
    return 'area';
  }
  validate(): boolean {
    return false;
  }
}
