import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { LoginService } from '../../../login/login.service';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { PincodeModel } from '../pincode-model';
import { PincodeServiceService } from '../pincode-service.service';
import { MenuItems } from '../../../../../../src/app/layout/menuItems';
import { AclClassConstants } from '../../../aclClassConstants';
import {AclConstants} from '../../../aclOperationConstants';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-pincode-list',
  templateUrl: './pincode-list.component.html',
  styleUrls: ['./pincode-list.component.scss']
})
export class PincodeListComponent extends AbstractBaseListComponent<PincodeModel> implements OnInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>; //New add
  @ViewChild('editUserName', { static: false }) editUserNameReff: TemplateRef<any>; //New add

  dataGrid: PincodeModel[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  searchSettings = [];
  neoTableSetting:any={};
  public entity: PincodeModel = new PincodeModel();
  public entity_id: any = null;
  form: FormGroup;
  menuItems: any =[];
  menuItemService:any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  // @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  // AclClassConstants = AclClassConstants;
  // AclConstants = AclConstants;
  // public loginService: LoginService;

  constructor(private entityService : PincodeServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    public loginService: LoginService,
    private searchService: SearchService,
    menuConstants: MenuItems) { 
      super(toastrService,searchService);
      // this.loginService = loginService;
      this.menuItemService = menuConstants;
      this.menuItems = menuConstants.menuItems;
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.PINCODE)
    this.init();
    this.getTableColumn();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if(col.colKey=='actionCol'){
        col.customControls = [this.actionBtnRef];
      }
      if(col.colKey=='pincode'){
        col.customControls = [this.editUserNameReff];
      }
    });
  }

  getService(): IBaseService<PincodeModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'pincode',
        default: 'Pincode',
        rowKey: 'pincode',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'countryName',
        default: 'Country Name',
        rowKey: 'countryName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'stateName',
        default: 'State Name',
        rowKey: 'stateName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
       {
        colKey: 'cityName',
        default: 'City Name',
        rowKey: 'cityName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false, //change
        isFilterAllow: false,//change
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
    //return this.allColumns ? this.allColumns : [];
  }

  // ngAfterViewInit() {
  //   this.allColumns.forEach((col) => {
  //     if (col.colKey == 'actionCol') {
  //       col.customControls = [this.actionBtnRef];
  //     }
  //   });
  // }

  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PINCODE,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Pincode ?',
      deleteMsgBoxDetail: 'Pincode Name :${pincode} ',
    };
    //return serviceAreaNeoGridSettings;
  }

  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Pincode',
        value_field: 'pincode',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Tat Consideration',
        value_field: 'tatConsideration',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  // onEditClick(event) {
   
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    // let index = event.RowData;
    let index = event;
    this.setUpdateValueInForm(index);
  }

  onDeleteClick(event) {
     let self = this;
      alertify.confirm("Are you sure to delete this pincode","Pincode:"+event.pincode,
      function(){
        if (event) {
           self.delete(event);
         }},
      function(){
      })
  }

  setList(list: Array<PincodeModel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }
/*
  onNeoFilterChange(filterObj) {
    let searchData = new SearchData();
    searchData = filterObj;
    if (searchData.filterColumn) {
      let serverFilterObj = this.searchService.manageServerFilterObj([searchData]);
      ////console.log('Server final filtre obj', serverFilterObj);
    }
  }*/

  onAddClick() {
    this.router.navigate(['/masters/pincode/add_pincode']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/pincode/add_pincode',rowData.pincodeid]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}

