import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { IpPoolConfig } from '../model/ip-pool-config';


// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class IpPoolConfigService {
  constructor(private http: HttpClient) {}

  getIpPoolConfig(params: HttpParams): Observable<any> {
    const post_url = baseApiUrl + 'ippool';
    return this.http.post<any>(post_url, params,
      { headers: httpOptions.headers}).pipe(
        map(res =>  res as IpPoolConfig[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getIpPoolConfigById(ip_pool_id : any): Observable<any> {
    const get_url = baseApiUrl + 'ippool/' + ip_pool_id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as IpPoolConfig[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getIpAddress(ip_pool_data : any,poolid:any) : Observable<any> {
    // const get_url = baseApiUrl + 'ippool/getPoolByIp?networkIp=' + ip_pool_data;

    const get_url = baseApiUrl + 'ippool/getPoolByIp?networkIp=' + ip_pool_data +'&poolId=' +poolid;
    
    return this.http.get<any>(get_url,
      httpOptions).pipe(
       map(data => data),
     catchError((error: any) => {
       return throwError(error);
       })
     );
  }

  insertIpPoolConfig(ip_pool_data : any): Observable<any> {
    const post_url = baseApiUrl + 'ippool/save';
    return this.http.post<any>(post_url,ip_pool_data,
       httpOptions).pipe(
        map(data => data),
      catchError((error: any) => {
        return throwError(error);
        })
      );
  }

  updateIpPoolConfig(ip_pool_data: any): Observable<any> {
    const update_url = baseApiUrl +"ippool/update";
    return this.http.post<any>(update_url, ip_pool_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deleteIpPoolConfig(ip_pool_data : any): Observable<any> {
    const post_url = baseApiUrl + 'ippool/delete';
    return this.http.post<any>(post_url, ip_pool_data, httpOptions).pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}