import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class  AddSubscriberDetailsService {
  constructor(private http: HttpClient) {

  }
getAadhaarOTP(id){
  let obj = {
    id_number :id.toString()
  }
  const post_url = BASE_API_URL + 'subscriber/generateAadhaarOTP';
    return this.http.post<any>(post_url,obj)
}
getCustomerSource(){
  const get_url = BASE_API_URL + 'commonList/generic/customerSource ';
  return this.http.get<any>(get_url);
}
verifyOtpAadhaar(data){
  const post_url = BASE_API_URL + 'subscriber/verifyAadhaarOTP';
    return this.http.post<any>(post_url,data)
}
public getCommonListPropertyType(): Observable<any> {
  const get_url = BASE_API_URL + 'commonList/generic/PropertyType';
  return this.http.get<any>(get_url);
}

  MobileVerification(number){
    let obj = {
      mobile :number.toString()
    }
    const post_url = BASE_API_URL + 'subscriber/genrateMobileOtpAtSave';
    return this.http.post<any>(post_url,obj)
  }

  OTPverifiedServiceAtSave(mobile, Otp) {
    let data = { mobile:mobile, mobileotp: Otp }
    const post_url = BASE_API_URL + 'subscriber/verifyMobileOtpAtSave';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers });
  }

  addSubscriberDetailsService(subscriber_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'customers';
    return this.http.post<any>(post_url, subscriber_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getAreaAll(): Observable<any> {
    const get_url = BASE_API_URL + 'area/all';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getCommonList

  getPincodeAll(): Observable<any> {
    const get_url = BASE_API_URL + 'pincode/all';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  // getDetailsByPincode(pincode:any): Observable<any> {
  //   const get_url = BASE_API_URL + 'pincode/getDetailsByPin/'+pincode;
  //   return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
  //       map((res: any) => {
  //         return res;
  //       }),
  //       catchError((error: any) => {
  //         return throwError(error);
  //       })
  //     );
  // }

  getDetailsByPincode(parentid:any): Observable<any> {
    const get_url = BASE_API_URL + 'address/areaAndBuildingList/'+ parentid;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getDetailsByProperty(areaId:any, proprtyName:string): Observable<any> {
    const get_url = BASE_API_URL + 'address/getBuildingList/'+ areaId + '/' + proprtyName;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  checkUniqueCustomerByEmail(value,custId): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
    let data:any = {
      "type": "email",
      "value": value,
      "subscriberId": custId
    };
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  checkUniqueCustomerByUsername(value): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
    let data:any = {
      "type": "username",
      "value": value
    };
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getOpticalpowerList(): Observable<any> {
    const get_url = BASE_API_URL + 'commonList/generic/opticalPower';
    return this.http.get<any>(get_url);
  }

  checkUniqueCustomerByMobile(value,custId): Observable<any> {
    
    const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
    let data:any = {
      "type": "mobile",
      "value": value,
      "subscriberId": custId
    };
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  checkUniqueCustomerByCafno(value,subscriber_id?): Observable<any> {
    const get_url = BASE_API_URL + 'subscriber/checkUniqueCustomer';
    let data:any = {
      "type": "cafno",
      "value": value,
      "subscriberId": subscriber_id
    };
    return this.http.post<any>(get_url,data,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  
  getRequiredAllField(): Observable<any> {
    /**Only For Subscriber Create Put It Will Change per Requirement*/
    const get_url = BASE_API_URL + 'uiPageConfig/getUiConfig/registration';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getCollectorListByPartner(partnerId): Observable<any> {
    const get_url = BASE_API_URL + 'userByPartnerAndRole/'+ partnerId + '/' + 14;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
}

getConnectiontypeList(type){
  const get_url=BASE_API_URL+'commonList/generic/' + type;
  return this.http.get<any>(get_url)
}
getRoutertypeList(type){
  const get_url=BASE_API_URL+'commonList/generic/' + type;
  return this.http.get<any>(get_url)
}
}
