import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../../../../app.config';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { SearchData } from '../../../../core/modal/search-data';
import { ItemStockModal } from '../modal/item-stock-modal';
import { StockAreaModal } from '../modal/stock-area-modal';




@Injectable({
  providedIn: 'root'
})
export class InventoryService{
  public getAllByPartner = '/getAllByPartner';
  config: any;
  private httpClient: HttpClient;
  private subject = new Subject<any>();

  constructor(private appconfig: AppConfig,private handler: HttpBackend) {
    this.config = appconfig.getConfig();
    this.httpClient = new HttpClient(handler);
   }

  returnCustId(): Observable<any> {
    return this.subject.asObservable();
  }

  saveInventoryStock(obj: any) {
    this.subject.next(obj);
   }


  getModuleName(){
    return 'inventory'
  }

  getAutoCompleteStock(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/getFreeStockList',searchData, {headers:header}
    );
  }

  getStaffWiseInventory(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/getStockList/v2',searchData, {headers:header}
    );
  }


  getCaseInventoryHistory(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/consumption/getMaterialAgainstCaseTicket/'+searchData, {headers:header}
    );
  }

  public onAllocateSeleced(reqObj): Observable<ResponseData<any>> {
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<any>>(this.config.inventoryUrl + 'api/v1/chemical/consumption/save',reqObj,  {headers:header});
  }

  setReservedStock(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/changeReserveStatus',searchData, {headers:header}
    );
  }

  getStockAreaList(): Observable<ResponseData<StockAreaModal>> {
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<StockAreaModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/stockArea' + this.getAllByPartner, {headers:header}
    );
  }
}
