import { ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { ClientGroupService } from '../Services/client-group.service';
import { IBaseService } from '../../../../services/ibase-service';
import { SearchService } from '../../../../services/search.service';
import { ClientGroupModel } from '../model/client-group-model';
import {SearchData} from '@neoboss/core/modal/search-data';
import {OrderingData} from '@neoboss/core/modal/ordering-data';
import {PaginationData} from '@neoboss/core/modal/pagination-data';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-client-group',
  templateUrl: './client-group.component.html',
  styleUrls: ['./client-group.component.scss']
})
export class ClientGroupComponent extends AbstractBaseListComponent<ClientGroupModel> implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('editClientGroup', { static: false }) editClientGroupReff: TemplateRef<any>;
  @ViewChild('clientGroup', { static: false }) clientGroupREff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  dataGrid: ClientGroupModel[] = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];

  public entity: ClientGroupModel = new ClientGroupModel();
  public entity_id: any = null;
  form: FormGroup;
  allColumns: any[] = [];
  public loginService: LoginService;

  constructor(private entityService : ClientGroupService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginservice: LoginService
    ) {
    super(toastrService, searchService);
    this.loginService = loginservice;
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.CLIENT_GROUP)
    this.init();
    
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.editClientGroupReff];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.clientGroupREff];
      }
    });
  }

  getService(): IBaseService<ClientGroupModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue:(status) => {
          if(status === 'Active'){
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns = allColumns;
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return allColumns ? allColumns : [];
  }

  getTableSetting() {
    let clientNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      serverSideSortPagination: true,
      tableId: this.tableKeyEnum.CLIENT_GROUP,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      // defaultSortCol: 'name',
      // defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Client Group ?',
      deleteMsgBoxDetail: 'Client Name :${name} ',
    };
    return clientNeoGridSettings;
  }

  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }


  // onEditClick(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  excelDownload() {}

  pdfDownload() {}
  setList(list: Array<ClientGroupModel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }

  /*onNeoFilterChange(filterObj) {
    let searchData = new SearchData();
    searchData = filterObj;
    if (searchData.filterColumn) {
      let serverFilterObj = this.searchService.setServerFilterObj([searchData]);
      ////console.log('Server final filtre obj', serverFilterObj);
    }
  }*/

  onAddClick() {
    this.router.navigate(['/radius/clients-group/add-group']);
  }


  setUpdateValueInForm(rowData) {
    this.router.navigate(['/radius/clients-group/add-group',rowData.id]);
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
