export class PackageModel {

  id : number;
  vasPlanIds : Array<[]>
  name : string;
  displayName : string;
  code : string;
  plantype : string;
  category : string;
  serviceId : any;  
  planGroup : string;
  offerprice : any;
  desc : string;
  saccode : string;
  quotaUnit : string;
  quota : any;
  taxId : any;
  status : string;
  validityType : string;
  validity:number = 0;
  maxconcurrentsession : number = 1 ;
  quotatype : string;
  quotaunittime : string;
  quotatime : any;
  minPrice : any;
  tokenPrice : any;
  maxPrice : any;
  dataCategory : string;
  taxamount : any;
  isIncludeTax : boolean;
  qospolicyid : number;
  billingCycle : string;
  cycleType : string;
  radiusprofileIds:any;
  
  minPriceTax : any; 
  maxPriceTax : any;


  normalspeed : string;
  fupspeed : string;
  normalupto : boolean;
  fupupto : boolean; 
  ottplan : string;
  childQuota : string;
  childQuotaUnit : string;
  allowOverUsage : boolean;
  quotaFormate : string;
  slice : any;
  sliceUnit : string;
  attachedToAllHotSpots : string;
  mvnoId : any;
  maxChild : any;
// List<PostpaidPlanChargePojo> chargeList = new ArrayList<>();
  dbr : any;
  quotadid : any;
  quotaintercom : any;
  quotaunitdid : string;
  quotaunitintercom : string;
  partnerCommType : string;
  custdescription : string;
  allowVasSelection: boolean = false;
  displayUnlimited: boolean;

  //angular perspose
  service_id:number;
  offerpriceintax:number
}
