import { Injectable } from '@angular/core';
import { BaseSetupModel } from '../model/base-setup-model';
import { BaseService } from '../../../../services/base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { RequestData } from '../../../../core/modal/base-save-update-modal';
import { catchError, map } from 'rxjs/operators';
import { propDocumentUploadModel } from '../../model/propertyDocupload';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { SearchData } from '../../../../core/modal/search-data';

const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class BaseSetupService extends BaseService<BaseSetupModel> {

  // leasedId: any;
   propertyId: any;
  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  private filterSubject = new Subject<any>();


  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "baseSetup";
  }
  numberOnly(event): boolean {
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    
    return true;
  }
  getFilterOptions(): Observable<any> {
    return this.filterSubject.asObservable();
  }
  getAdvanceFilterSearchData(obj){
    obj['page']=1,
    obj['pageSize']=10
    const post_url = baseApiUrl + 'baseSetup';
    return this.http.post<any>(post_url,obj, { headers: httpOptions.headers });
  }

  getDeviceType():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/device');
  }

  getBaseTypeList():Observable<any>{
    return this.http.get<any>(baseApiUrl + 'commonList/baseType');
  }

  


  // setId(id) {
  //   this.leasedId = id;
  // }
  getByproperty(id) {
    this.propertyId = id;
  }
 

  propertyList(leaselineId: any): Observable<ResponseData<any>> {
       return this.http.get<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/getAllPropertyByLeaseLine/'+ leaselineId,{
        headers: httpOptions.headers,
       } );
  }


  getRequiredAllField(type): Observable<any> {
    const get_url = baseApiUrl + 'uiPageConfig/getUiConfig/' + type;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

}
