import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})
export class PricebookService {
  constructor(private http: HttpClient) {

  }

  transformPricebookData(data) {
    let formatedData = [];
    data.pricebookList.forEach(function(item: any) {
      let itemData: any = {};
      itemData.pricebook_id = item.id;
      itemData.name = item.name;
      itemData.status = item.status;
      itemData.commission_type = item.commtype;
      itemData.commission_value = item.commrelvalue;
      itemData.credit_limit = item.credit_limit;
      itemData.commission_day = item.commdueday;
      itemData.address_type = item.addresstype;
      itemData.updatedate = item.updatedate;
      formatedData.push(itemData);
    });
    return { content: formatedData };
  }

  getPricebookDataWithPageing(params: HttpParams): Observable<any> {
    const get_url = BASE_API_URL + 'priceBook/getAllByPagination';

    return this.http.get<any>(get_url,
      { headers: httpOptions.headers, params: params }).pipe(
        map((res: any) => {
          return this.transformPricebookData(res);
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPricebookDataById(pricebook_id : any): Observable<any> {
    const get_url = BASE_API_URL + 'priceBook/' + pricebook_id;
    return this.http.get<any>(get_url, { headers: httpOptions.headers }).pipe(
      map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getPricebookList(params: HttpParams): Observable<any> {
    const post_url = BASE_API_URL + 'priceBook/list';
    return this.http.post<any>(post_url,params,{ headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertPricebook(pricebook_data: any): Observable<any> {
    const post_url = BASE_API_URL + 'priceBook/save';
    return this.http.post<any>(post_url, pricebook_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public getActivePriceBook(type): Observable<any> {
    const get_url = BASE_API_URL + 'priceBook/active/'+type;
    return this.http.get<any>(get_url);
  }

  updatePricebook(pricebook_data: any): Observable<any> {
    const update_url = BASE_API_URL + "priceBook/update";
    return this.http.post<any>(update_url, pricebook_data, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  deletePricebook(priceBook_data : any): Observable<any> {
    const delete_url = BASE_API_URL + "priceBook/delete";
    return this.http.post(delete_url, priceBook_data, httpOptions);
  }
  commonStatus(): Observable<any> {
    const get_url = BASE_API_URL + "commonList/commonStatus";
    return this.http.get(get_url);
  }

  getPricebookTypeList(): Observable<any> {
    const get_url = BASE_API_URL + "commonList/getPricebookByType";
    return this.http.get(get_url);
  }
}
