import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

export class Ippool {
  createdate: string;
  updatedate: string;
  createdById: string;
  lastModifiedById: string;
  poolId: string;
  poolName: string;
  poolType: string;
  poolCategory: string;
  ipRange: string;
  netMask: string;
  networkIp: string;
  broadcastIp: string;
  firstHost: string;
  lastHost: string;
  totalHost: string;
  isDelete: string;
  isStaticIpPool: string;
  status: string;
  remark: string;
}

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class IppoolServices {
  constructor(private http: HttpClient) { }

  getIppoolList(): Observable<any> {
    const get_url = baseApiUrl + 'ippool/allNonStaticIpPool';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as Ippool[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
