export class PlaceOrder {
    basicamount: number;
    custId: number;
    entityid: number;
    finalamount: number;
    orderdesc: string;
    ordertype: string;
    partnerId: number;
    pgid: number;
    taxamount: number;

    public constructor(init?: Partial<PlaceOrder>) {
        Object.assign(this, init);
    }
}
