import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { LedgerService } from './ledger.service';
import { saveAs } from 'file-saver/FileSaver';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {

  public sub: any = null;
  custId: any;
  ledgerData: any;

  customerName: any;
  custUsername: any;
  custZonename: any;
  custlastrenewDate: any;
  custPlanName: any;
  custAddress: any;
  accountStatus: any;
  duration: any;
  custAmountDetails: any;
  openingBalance: any;
  closeBalance: any;
  Credit: number = 0;
  Debit: number = 0;
  totalBalanceB: number;
  totalbalbceC: number;
  exceldownload:any;
  balAmount:any;
  customerLedgerForm = new FormGroup({
    CREATE_DATE : new FormControl(''),
    END_DATE : new FormControl(''),
    custId:new FormControl('')
  });
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ledgerService: LedgerService,
    private datepipe: DatePipe,
    private toaster:ToastrService
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.custId = id;
    }
    this.getStartDateEndDate();
    let obj={
                "CREATE_DATE": this.customerLedgerForm.value.CREATE_DATE,
                "END_DATE": this.customerLedgerForm.value.END_DATE,
                "custId":this.custId
        }
     this.getLedgerDeta(obj);
     // this.getLedgerDeta();//Original Code

  }

  getLedgerDeta(form) {

    let formatedData: any = {};
    formatedData.custId = this.custId;    
    formatedData.CREATE_DATE= this.datepipe.transform(form.CREATE_DATE, 'yyyy-MM-dd');
    formatedData.END_DATE= this.datepipe.transform(form.END_DATE, 'yyyy-MM-dd');
    this.exceldownload=formatedData;

    let ddmmyyyyStartDate=new Date(formatedData.CREATE_DATE);
    let ddmmyyyyEndDate=new Date(formatedData.END_DATE);

    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.toaster.error("Start date should be less then End date.");
    }
    else {
      if (formatedData.CREATE_DATE != '' &&  formatedData.END_DATE !='') {
      this.ledgerService.getLedgerData(formatedData).subscribe(result => {

        if(result.status!==200){
          this.toaster.error(result.error.ERROR)
        }else{
          this.ledgerData = result.customerLedgerDtls;
          this.customerName = this.ledgerData.custname;
          this.custUsername = this.ledgerData.username;
          this.custZonename = this.ledgerData.zonename;
          this.custlastrenewDate = this.ledgerData.lastrenewDate;
          this.custPlanName = this.ledgerData.plan;
          this.custAddress = this.ledgerData.address;
          this.accountStatus = this.ledgerData.status;
          this.duration = this.ledgerData.duration;
          this.custAmountDetails = this.ledgerData.customerLedgerInfoPojo.debitCreditDetail;
          this.openingBalance = this.ledgerData.customerLedgerInfoPojo.openingAmount;
          this.closeBalance = this.ledgerData.customerLedgerInfoPojo.closingBalance;
          this.Credit=null;
          this.Debit=null;
         this.balAmount = this.ledgerData.customerLedgerInfoPojo.balAmount;
          this.custAmountDetails.forEach(item => {
            if (item.transtype == 'CR') {
              this.Credit += item.amount;
            } else {
              this.Debit += item.amount;
            }
          });
        }
     },unknownerr => {
        this.toaster.error(unknownerr.error.ERROR);
      }
      )
      
      }
    }





    
  }

  OnBackToSubscriberDetails() {
    this.router.navigate(['/masters/subscriber/details',  this.custId] );
  }

  exportToExcel(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();
   let formvalue=this.exceldownload;
   this.ledgerService.excelServiceData(formvalue).subscribe(blob=>{
    saveAs(blob, "[customer ledger " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
    })
}

getStartDateEndDate() {
  var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  var startDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
  var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  this.customerLedgerForm.patchValue({ "CREATE_DATE": startDate, "END_DATE": endDate });
}

reset(){
        
  let formatedDataNew: any = {};
   formatedDataNew.CREATE_DATE= new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  formatedDataNew.END_DATE= this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  this.customerLedgerForm.patchValue({ "CREATE_DATE": formatedDataNew.CREATE_DATE, "END_DATE": formatedDataNew.END_DATE });
  formatedDataNew.custId = this.custId; //Change string to Number
  this.getLedgerDeta(formatedDataNew)
  
}


}




