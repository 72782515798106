import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

export class Title {
  id: string;
  text: string;
  value : string;
}

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class PlanServices {
  constructor(private http: HttpClient) {}

  getPlanServiceList(): Observable<any> {
    const get_url = baseApiUrl + 'planservice/all';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res as Title[]),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPostPaidPlanByServiceList(reqObj): Observable<any> {
    const post_url = baseApiUrl + 'plan/Bypartner';
    return this.http.post<any>(post_url,reqObj,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getTaxDetailsByPlan(reqObj): Observable<any> {
    const post_url = baseApiUrl + 'getTaxDetails/byPlan';
    return this.http.post<any>(post_url,reqObj,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getTaxDetailsByCharge(reqObj): Observable<any> {
    const post_url = baseApiUrl + 'getTaxDetails/byCharge';
    return this.http.post<any>(post_url,reqObj,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  taxCalculationByAmount(reqObj): Observable<any> {
    const post_url = baseApiUrl + 'taxCalculation/ByAmount';
    return this.http.post<any>(post_url,reqObj,{ headers: httpOptions.headers }).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


}
