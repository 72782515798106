import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseListComponent } from '../../../../../components/base/abstract-base-list-component';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { PaginationData } from '../../../../../core/modal/pagination-data';
import { IBaseService } from '../../../../../services/ibase-service';
import { AclClassConstants } from '../../../../aclClassConstants';
import { AclConstants } from '../../../../aclOperationConstants';
import { LoginService } from '../../../../login/login.service';
import { associatedvasdetail } from './model/associatedvas.model';
import { AssociatedVASService } from './service/associatedVas.service';

@Component({
  selector: 'app-associated-vas-detail',
  templateUrl: './associated-vas-detail.component.html',
  styleUrls: ['./associated-vas-detail.component.scss']
})
export class AssociatedVasDetailComponent extends AbstractBaseListComponent<associatedvasdetail> implements OnInit {
  @ViewChild('totalPaid', { static: false }) totalPaidRef: TemplateRef<any>;
  @ViewChild('totaldue', { static: false }) totaldueRef: TemplateRef<any>;
  @ViewChild("editUserName", { static: false }) editUserNameRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('amount', { static: false }) amountRef: TemplateRef<any>;
  @ViewChild('invoiceWrapper', { static: false }) invoiceWrapperRef: TemplateRef<any>;
  @ViewChild('validity', { static: false }) validity: TemplateRef<any>;
  @Output() onCancelPopup = new EventEmitter<any>();
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  allColumns: any[] = [];
  associatedVasDetailGridSetting: any = {};
  tableKey = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  dataGrid: associatedvasdetail[] = [];
  associatedVASData:associatedvasdetail[];
  paymentList:[];
  uuId:any;
  ngbRef: NgbModalRef;

  @Input() planmapId:number
  @Input() planID : number
  packageAssociation: boolean = false

  getService(): IBaseService<associatedvasdetail> {
    return this.entityService;
  }
  setList() {
    this.dataGrid = Object.assign([],this.associatedVASData);
    this.associatedVasDetailGridSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  getTableColumn() {
    let self = this;
    if(!this.packageAssociation){
      this.allColumns = [
        {
          colKey: 'index',
          default: 'ID',
          rowKey: 'id',
          width: '3%',
          isNumber: true,
        },
        {
          colKey: 'vasName',
          default: 'vas name',
          rowKey: 'vasName',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        {
          colKey: 'vasType',
          default: 'vas Type',
          rowKey: 'vasType',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        {
          colKey: 'providerName',
          default: 'provider Name',
          rowKey: 'providerName',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        // {
        //   colKey: 'partnername',
        //   default: 'partner name',
        //   rowKey: 'partnername',
        //   width: '10%',
        //   isNumber: false,
        //   enableEdit: true,
        //   enableDelete: true,
        //   enableSort: false,
        // },
        {
          colKey: 'validity',
          default: 'validity (Days)',
          rowKey: 'validity',
          width: '10%',
          customControls: [this.validity],
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        {
          colKey: 'startDate',
          default: 'start Date',
          rowKey: 'startDate',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        {
          colKey: 'expiryDate',
          default: 'expiry Date',
          rowKey: 'expiryDate',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
      ];
    }
    if(this.packageAssociation){
      this.allColumns = [
        {
          colKey: 'index',
          default: 'ID',
          rowKey: 'id',
          width: '3%',
          isNumber: true,
        },
        {
          colKey: 'vasName',
          default: 'vas name',
          rowKey: 'vasName',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        {
          colKey: 'vasType',
          default: 'vas Type',
          rowKey: 'vasType',
          width: '10%',
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
        {
          colKey: 'validity',
          default: 'validity (Days)',
          rowKey: 'validity',
          width: '10%',
          customControls: [this.validity],
          isNumber: false,
          enableEdit: true,
          enableDelete: true,
          enableSort: false,
        },
      ];
    }
    this.getTableSetting();
  }
  getTableSetting() {
    this.associatedVasDetailGridSetting = {
      showFooter: false,
      showPagination: true,
      showFilter:false,
      tableId: this.tableKey.ASSOCIATED_VAS_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:0,
      pageSizeOptions: [10],
      pageTotalLength: 0,
      columns: this.allColumns,
    };
  }
  getTableSearchSetting() {
    //
  }
  public onDeleteClick(data: associatedvasdetail) {
    //
  }
  public onEditClick(data: associatedvasdetail) {
    //
  }

  constructor(
    private modalService: NgbModal,
    private toasterService: ToastrService,
    private entityService: AssociatedVASService,
    private ngbModalService: NgbModal,
    private router: Router,
    public loginService: LoginService,
  ) {
    super(toasterService);
  }

  ngOnInit() {
    let URL  = this.router.url.includes('plan-management/packages')
    let ManageAct = this.router.url.includes('manage/manage-profile')
    if(URL || ManageAct){
      this.packageAssociation = true
    }
    this.setDefaultPageSize(this.tableKey.ASSOCIATED_VAS_LIST)
    this.getTableColumn();
    this.initNeoColumnvas();
    this.getVASDetailList();
   
  }

  private initNeoColumnvas() {
    this.neoTableSetting = this.getTableSetting();
    this.neoTableSearchSetting = this.getTableSearchSetting();
    this.paginationData = new PaginationData();
    if (this.neoTableSetting && this.neoTableSetting.perPage) {
      this.paginationData.rowsPerPage = this.neoTableSetting.perPage;
    }
      this.setOrderingData();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getVASDetailList() {
    if(this.planmapId){
      this.entityService.getAssociatedVasdetails(this.planmapId).subscribe(res=>{
        if(res.responseCode == 200){
          if(res.dataList && res.dataList.length > 0){
          this.associatedVASData = res.dataList;
          this.setList();
          }else{
            this.toasterService.error('No Associated VAS with this Plan')
          }
        }else{
          this.toasterService.error(res.responseMessage)
        }
      },
      (error) => {
        //console.log("ERRORN",error);
        this.toasterService.error(error.message);
      })
    }
    if(this.planID){
      this.entityService.getPlanAssociatedVasdetails(this.planID).subscribe(res=>{
        res.dataList.map(res => res.vasName = res.name);
        res.dataList.map(res => res.vasType = res.type);
        this.associatedVASData = res.dataList;
        this.setList();
      })
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'amount') {
        col.customControls = [this.amountRef];
      }
      if (col.colKey == 'totalPaid') {
        col.customControls = [this.totalPaidRef];
      }
      if (col.colKey == 'validity') {
        col.customControls = [this.validity];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
  }
}
