import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { ClientsService } from './clients.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchService } from '../../../services/search.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('editClientIP', { static: false }) editClientIPREff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  clientsDataForGrid: any = { content: [] };
  clientsGridSettings: any = {};
  selectForUpdate: any;
  show_update: any = false;

  public clientGroupList: any[] = [
    {
      client_group_name: '',
      client_group_id: '',
    },
  ];
  insertClientForm = new FormGroup({
    client_ip: new FormControl('', [Validators.required]),
    shared_secret_key: new FormControl('', [Validators.required]),
    timeout: new FormControl('', [Validators.required]),
    ip_type: new FormControl('IPv4', [Validators.required]),
    client_group_id: new FormControl('', [Validators.required]),
  });

  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  neoGridSettings: any = {};
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredClientDataForGrid: any = [];
  public loginService: LoginService;

  constructor(
    private clientsService: ClientsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private tosterservice : ToastrService,
    loginservice: LoginService

  ) {
    super(searchService);
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.RADIUS_CLIENT)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }


  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'client_ip') {
        col.customControls = [this.editClientIPREff];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'client_ip',
        default: 'CLIENT IP',
        rowKey: 'client_ip',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'clientGroupName',
        default: 'CLIENT GROUP NAME',
        rowKey: 'clientGroupName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'shared_secret_key',
        default: 'SHARED SECRET KEY',
        rowKey: 'shared_secret_key',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'timeout',
        default: 'TIMEOUT',
        rowKey: 'timeout',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'create_date',
        default: 'CREATE DATE',
        rowKey: 'create_date',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Client IP',
        value_field: 'client_ip',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Shared Secret Key',
        value_field: 'shared_secret_key',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Timeout',
        value_field: 'timeout',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.NUMBER,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.NUMBER,
      },
      {
        display_field: 'CREATE DATE',
        value_field: 'create_date',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.DATE,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.DATE,
      },
    ];

    this.neoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.RADIUS_CLIENT,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      // enablePdfExport: true,
      // enableExcelExport: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Client IP ?',
      deleteMsgBoxDetail: 'Client IP :${client_ip} ',
    };
    if (this.neoGridSettings.serverSideSortPagination && this.neoGridSettings.perPage) {
      this.setPaginationPerPage(this.neoGridSettings.perPage);
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getClientData(gridSetting: any) {
    let fetchSettingData = this.neoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.clientsService.getClientDataWithPageing(params).subscribe((result) => {
      this.clientsDataForGrid = result;
      this.filteredClientDataForGrid = JSON.parse(JSON.stringify(this.clientsDataForGrid.content));
      this.refreshDataSource(this.clientsDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.neoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
     let self = this;
     if (event) {
       this.commonDeleteMsgBox(this.neoGridSettings,event,this.delete.bind(this));
     }
  }

  applyFilter(filterObj) {
    if (filterObj && filterObj.filters) {
      this.filteredClientDataForGrid = this.searchService.getFilteredData(
        filterObj.filters,
        this.clientsDataForGrid.content
      );
    } else {
      this.filteredClientDataForGrid = JSON.parse(JSON.stringify(this.clientsDataForGrid.content));
    }
    this.refreshDataSource(this.filteredClientDataForGrid);
  }

  delete(objEvent) {
    let self = this;
    let selected_client_data = objEvent.event;
    if (objEvent.deleteClicked) {
    this.clientsService.deleteClient(selected_client_data.client_id).subscribe((result) => {
      if(result.status !== 200){
        this.tosterservice.error(result.ERROR);
      }
      else{
        this.tosterservice.success('Client is deleted successfully');
        this.show_update = false;
        self.clientsDataForGrid.content.forEach(function (item, index) {
          if (item.client_id === selected_client_data.client_id) {
            self.clientsDataForGrid.content.splice(index, 1);
          }
        });
        self.refreshDataSource(self.clientsDataForGrid.content);
      }
     
    },
    err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    });
   }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/radius/clients/add-clients',rowData.client_id]);
  }

  onAddClientsClick() {
    this.router.navigate(['/radius/clients/add-clients']);
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getClientData(config);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getClientData(config);
      }
}
