import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { BaseService } from '../../../../services/base-service';
import { DunningModel, DunningModelRule } from '../model/dunning-model';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';
@Injectable({
  providedIn: 'root'
})
export class DunningRuleActionService extends BaseService<DunningModelRule> {
  
  constructor(http: HttpClient) {
    super(http);
  }
  getModuleName(): string {
    return;
  }
getcommonList(type):any{
  const get_url = BASE_API_URL+"commonList/generic/"+ type;
  return this.http.get(get_url);
}
dunningRuleGetall():any{
  const get_url = BASE_API_URL+"dunningRule/all";
  return this.http.get(get_url);
}
DunningRuleNotificationList():any{
  const get_url = BASE_API_URL+"notification/getEventByname/DunningRules"
  return this.http.get(get_url);
}
getActionByDunning(data):any{
  const post_url = BASE_API_URL+"dunningRuleAction/fromDunning"
  return this.http.post(post_url,data);
}
save(data):any {
  const post_url = BASE_API_URL+"dunningRuleAction/save"
  return this.http.post(post_url,data);
}
update(data):any {
  const post_url = BASE_API_URL+"dunningRuleAction/update"
  return this.http.post(post_url,data);
}
delete(data):any {
  const delete_url = BASE_API_URL+"dunningRuleAction/delete"
  return this.http.post(delete_url,data);
}
  

  validate(): boolean {
    return false;
  }
}
