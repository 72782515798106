import { Router } from '@angular/router';
import { Injectable, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from './app.config';
import 'rxjs/add/operator/do';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './pages/login/login.service';
import { LoadingService } from './components/loading/loading.service';
import { LayoutComponent } from './layout/layout.component';
import { NgbActiveModal, NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  @ViewChild(LayoutComponent, { static: false })
  private layout: LayoutComponent;
  isJWTExpire: boolean = false;
  config;
  tokenfetch: any;
  constructor(
    private router: Router,
    appConfig: AppConfig,
    private toastr: ToastrService,
    private loginService: LoginService,
    private loadingService: LoadingService,
    private modalService: NgbModal,
  ) {
    this.config = appConfig.getConfig();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loginSession =  localStorage.getItem('loginSession');
    if(loginSession == 'true'){
      this.isJWTExpire = false;
      this.router.navigate(['/login']);
    }
    req = req.clone({ url: this.config.baseURLApi + req.url });

    // const token: string = localStorage.getItem('accessToken');
    this.tokenfetch = null;
    this.tokenfetch = localStorage.getItem('accessToken');
    

    if (this.tokenfetch) {
      req = req.clone({
        setHeaders: {
          authorization: this.tokenfetch
        }
      });

      // req = req.clone({
      //           setHeaders: {
      //               authorization: `Basic YWRtaW46YWRtaW5AMTIz`
      //           }
      // });


    }

    return next.handle(req).do((event: HttpEvent<any>) => {
      this.loadingService.show();
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
        this.loadingService.hide();
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 403 || err.status === 500) {

          if (err.error.message === 'Session expired please login again!') {
            this.loginService.logoutUser();
            if(!this.isJWTExpire)
              this.toastr.error(err.error.message, err.error.status);
            this.isJWTExpire = true;
            this.router.navigate(['/login']);
            this.modalService.dismissAll();

            return
            
          }

          if (err.error.message === 'JWT Expired') {
            this.loginService.logoutUser();
            if(!this.isJWTExpire)
              this.toastr.error("Session has expired Please login again!");
            this.isJWTExpire = true;
            this.router.navigate(['/login']);
            this.modalService.dismissAll();
            return
            
          }
          this.loadingService.hide();
          this.modalService.dismissAll();
        }
      }
    });
  }
}
